import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import ToggleButton from 'react-toggle-button';
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import Breadcrumb from 'components/Breadcrumb'
import MatchUpdateSeller from 'components/MatchUpdateSeller'
import WelcomeModal from 'components/Dashboard/WelcomeModal'
import Helpbutton from 'components/HelpButton'
import {getUsersBuyers} from './../Services/BuyerService'
import {getUsersSellers} from './../Services/SellerService'
import MatchesFilterSelect from 'components/Matches/MatchesFilterSelect';

import { Label, Input } from 'reactstrap';


class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            sellers: '-',
            buyers: '-',
            matchCount: '-',
            buyerMatchCount: '-',
            favorites: '-',
            loading: true,
            filter:'',
            filters: [],
            limit: 101,
            buyerLimit: 101,
            selectedMatchesIds: [],
            actionValue:'',
            includeExclude: true,
        }
    }

    onFilterChange = (filters) => {
        this.setState({filters}, () => {
            this.loadMatches();
        })
    }

    onActionChange = (event) => {
        const {value} = event.target;
        this.setState({
            actionValue: value,
        });
        if( value === 'not_interested'){
            this.notInterestedMatch();
        }
    }

    addID = (id) => {
        const {selectedMatchesIds} = this.state;
        this.setState({
            selectedMatchesIds: [...selectedMatchesIds,id],
        });
    }

    removeID = (id) => {
        const {selectedMatchesIds} = this.state;
        this.setState({
            selectedMatchesIds: selectedMatchesIds.filter(d => id !== d),
        });
    }

    notInterestedMatch = async () => {
        const { user } = this.props;

        const {selectedMatchesIds, matches} = this.state;
    
        const db = firebase.firestore();

        const promises = selectedMatchesIds.map(id => {
            return new Promise((resolve) => {
                const matchRef = db.collection("matches").doc(id);
                matchRef.get().then(snap => {
                    let {search, notIntrested} = snap.data();
                    search = search.filter((s) => s !== user.uid);
                    notIntrested = notIntrested || [];
                    notIntrested = notIntrested.filter((n) => n !== user.uid);
                    notIntrested = [...notIntrested, user.uid];
                    matchRef.set(
                        {
                          search,
                          notIntrested,
                        },
                        {
                          merge: true,
                        }
                    ).then(() => {
                        resolve({
                            id,
                            notIntrested
                        });
                    }).catch(() => {
                        resolve([]);
                    });
                }).catch((error) => {
                    console.log(error);
                    resolve([]);
                })
            });
        });

        const notIntresteds = await Promise.all(promises);

        const newMatches = matches.map((match) => {
            let notIntrested = match.notIntrested;
            notIntresteds.forEach(notInt => {
                if(notInt.id === match.id){
                    notIntrested = notInt.notIntrested;
                    console.log('new notIntrested',notIntrested);
                }
            });
            return {
                ...match,
                notIntrested,
            }
        });

        this.setState({
            matches: newMatches,
            actionValue: '',
        });

        // window.location = '/matches';
    }

    componentWillMount() {
        this.mounted = true
        window.scrollTo(0, 0);

        const {user} = this.props;
        
        Promise.all([
            getUsersBuyers(user),
            getUsersSellers(user),
        ]).then(snapshots => {
            const buyersCount = snapshots[0].length;
            const sellersCount = snapshots[1].length;
            if (this.mounted) {
                this.setState({sellers: sellersCount,buyers:buyersCount});
            }
        });

        this.loadMatches();
        
        // favorities 
        const db = firebase.firestore();
        let favorites = [];
        if(user && user.favorites){
            favorites = user.favorites;
        }
        const promises = favorites.map((v,i) => db.collection('matches').doc(v).get());
        Promise.all(promises).then(docs => {
            const existsFavorites = [];
            docs.forEach(doc => {
                if(doc.data()){
                    const {search} = doc.data();
                    if(search && doc.exists && search.includes(user.uid)){
                        existsFavorites.push(doc.id);
                    }
                }
            });
            this.setState({
                favorites:existsFavorites.length
            });
        });
    }

    loadMatches = () => {

        this.setState({
            loading:true,matchCount:0,matches:[]
        });

        const {filters, limit, includeExclude, buyerLimit} = this.state;
        
        const db = firebase.firestore()
        const realtorId = this.props.user.uid;

        let qb = db.collection('matches').where("search", "array-contains", realtorId).limit(limit);
        let buyerQb = db.collection('buyerMatches').where("search", "array-contains", realtorId).limit(buyerLimit);


        if(filters && filters.length > 0){
            filters.forEach(filter => {
                const key = filter.value.toLowerCase().replace(' ', '');
                qb = qb.where(key,'==',includeExclude);
                buyerQb = buyerQb.where(key,'==',includeExclude);
            });
        }

        qb.orderBy('score', 'desc').get().then(snap => {
            let docs = snap.docs;
            const matches = docs.map(d => { 
                const o = d.data()
                o.id = d.id
                return o
            }).sort((a, b) => b.score - a.score)

            if (this.mounted) {
                let matchCount = matches.length;
                this.setState({loading: false, matchCount, matches});
            }

        }).catch(e => {
            console.log(e);
        });

        buyerQb.get().then(snap => {
            let docs = snap.docs;
            if (this.mounted) {
                let buyerMatchCount = docs.length;
                this.setState({buyerMatchCount});
            }
        }).catch(e => {
            console.log(e);
        });
    }

    componentWillUnmount() {
        this.mounted = false
    }

    decreaseMatchCount = () => {
        let {matchCount} = this.state;
        matchCount--;
        if(matchCount < 0){
            matchCount = '-';
        }
        this.setState({matchCount});
    }
    
    renderLikedBuyersCount = () => {
        const {role,likedBuyers} = this.props.user;
        if(role === 'builder'){
            return (
                <div className={`col`}>
                    <Link to="/likedBuyers">
                    <div className="ui-item bg-primary">
                        <div className="left">
                            <h4>{(likedBuyers || []).length}</h4>
                            <p>Saved Buyers</p>
                        </div>
                        <div className="right">
                            <i className="fa fa-eye" />
                        </div>
                    </div>
                    </Link>
                </div>
            );
        }
    }

    renderBuyersCount = () => {
        const {role} = this.props.user;
        return (
            <div className={`col`}>
                <Link to="/buyers">
                <div className="ui-item bg-warning">
                    <div className="left">
                        <h4>{this.state.buyers}</h4>
                        <p>{(role === 'builder') ? 'Invited Buyers':'Buyers'}</p>
                    </div>
                    <div className="right">
                        <i className="fa fa-eye" />
                    </div>
                </div>
                </Link>
            </div>
        );
    }


    render() {
        const {matches, loading, filter, matchCount, selectedMatchesIds, actionValue, buyerMatchCount} = this.state;
        const {role, full_name, realtor_name, lender_name, builder_name, email} = this.props.user;
        const sellersLabel = role === 'builder' ? 'Builder Homes':'Sellers';
        const userName =  full_name || realtor_name || lender_name || builder_name || email;
        
        let matchesMarkup = null
        if (matches && matches.length) {
            matchesMarkup = matches.map(d => {
                console.log('matches');
                return <MatchUpdateSeller addID={this.addID} removeID={this.removeID} isChecked={selectedMatchesIds.includes(d.id)} decreaseMatchCount={this.decreaseMatchCount} functions={this.props.functions} user={this.props.user} match={d} key={d.id} />
            })
        }else if (!loading) {
            matchesMarkup = <p>No matches to show</p>
        }
         
        return (
            <div className="content-area5">
                <div className="dashboard-content">
                    <div className="dashboard-header clearfix">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <h4>Hello, {userName}</h4>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <Breadcrumb
                                    active="Dashboard"
                                    link="/"
                                    title="Home"
                                    {...this.props}
                                />
                            </div>
                        </div>
                    </div>

                    <WelcomeModal email={email}/>
                    <div className="row">
                        {this.renderLikedBuyersCount()}
                        <div className={`col`}>
                            <Link to="/sellers">
                                <div className="ui-item bg-success">
                                    <div className="left">
                                        <h4>{this.state.sellers}</h4>
                                        <p>{sellersLabel}</p>
                                    </div>
                                    <div className="right">
                                        <i className="fa fa-map-marker" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {this.renderBuyersCount()}
                        <div className={`col`}>
                            <Link to={`/matches?filter=${filter}`}>
                                <div className="ui-item bg-active">
                                    <div className="left">
                                        <h4>{matchCount > 100 ? "100+" : matchCount}</h4>
                                        <p>Property Matches</p>
                                    </div>
                                    <div className="right">
                                        <i className="fa fa-comments-o" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className={`col`}>
                            <Link to={`/matches?filter=${filter}&collection=buyerMatches`}>
                                <div className="ui-item bg-active">
                                    <div className="left">
                                        <h4>{buyerMatchCount > 100 ? "100+" : buyerMatchCount}</h4>
                                        <p>Buyer matches</p>
                                    </div>
                                    <div className="right">
                                        <i className="fa fa-comments-o" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col">
                        <Link to="/favorites">
                            <div className="ui-item bg-dark">
                                <div className="left">
                                    <h4>{this.state.favorites}</h4>
                                    <p>Favorites</p>
                                </div>
                                <div className="right">
                                    <i className="fa fa-heart-o" />
                                </div>
                            </div>
                            </Link>
                        </div>
                    </div>

                    <div className="row" style={{marginLeft: "15px",marginBottom: "20px"}}>
                        <div className="col-sm-12 col-md-6">
                        <Label check >
                                <Input style={{transform: 'scale(1.5)'}} type="checkbox" id="checkbox2" onChange={({target:{checked}})  => {
                                    if(checked){
                                        const selectedMatchesIds = (matches || []).map(match => match.id);
                                        return this.setState({
                                            selectedMatchesIds,
                                        }, this.printSelectedMatchesId);
                                    }
                                    this.setState({
                                        selectedMatchesIds:[],
                                    }, this.printSelectedMatchesId)
                                }} /> Select
                        </Label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                        <div style={{float:'right'}}>
                                        
                            <span style={{color:'#ff214f'}}>Actions</span>

                            <select
                                name="filter"
                                className="form-control custom-select"
                                required
                                onChange={this.onActionChange}
                                value={actionValue}
                            >
                                <option value="">Select Action</option>
                                <option value="not_interested">Mark as not interested</option>
                            </select>
                        </div>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="dashboard-list">
                                <div className="dashboard-message bdr clearfix">
                                    <div className="tab-box-2">
                                        <div className="clearfix mb-30 comments-tr" style={{paddingBottom:'20px'}}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <span>Top 100 Matches</span>
                                            </div>
                                            <div className="col-md-3">
                                                <div style={{float:'right', width:300}}>
                                                    <span style={{color:'#ff214f'}}>Filter By</span>
                                                    <MatchesFilterSelect
                                                        value={this.state.filters}
                                                        onFilterChange={this.onFilterChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <span style={{color:'#ff214f'}}>Exclude/Include filter</span>
                                                <div style={{marginTop:'10px'}}>
                                                    <ToggleButton
                                                        
                                                        inactiveLabel={<i className="fa fa-times"/>}
                                                        activeLabel={<i className="fa fa-check"/>}
                                                        value={this.state.includeExclude}
                                                        onToggle={(includeExclude) => {
                                                            this.setState({includeExclude:!includeExclude}, () => {
                                                                this.loadMatches();
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    

                                        </div>
                                        <div
                                            id="pills-tabContent"
                                            className="tab-content">
                                            {matchesMarkup}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Helpbutton/>
                </div>
            </div>
        )
    }
}

export default Dashboard
