import React, { Component } from "react";
import Input from "components/Input";
import Select from "components/Select";
import DatePicker from "react-date-picker";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import stringcase from "stringcase";
import loadImage from "blueimp-load-image";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { saveActivity } from "../Services/Activity";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ImageResize from "quill-image-resize-module-react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import GoogleLogin from "react-google-login";

const { sentencecase } = stringcase;
Quill.register("modules/imageResize", ImageResize);

export default class PartitionedForm extends Component {
  constructor(props) {
    super(props);
    const { sections, values, fields } = props;

    const inputs = {};

    for (const field of sections
      .map((s) => {
        const { field: singleField, additional } = s;
        const fieldDef = fields[singleField];

        if (fieldDef) {
          inputs[singleField] = values[singleField];
          if (fieldDef.multiselect) {
            inputs[singleField] = inputs[singleField] || [];
          }
        }

        return s.fields.concat(additional || []);
      })
      .flat()) {
      const fieldDef = fields[field];

      if (fieldDef) {
        inputs[field] = values[field];

        if (fieldDef.multiselect) {
          inputs[field] = inputs[field] || [];
        }
      }
    }

    this.state = {
      inputs,
      additionalFieldsVisibility: false,
      activeTab: 0,
      additionalSectionVisibility: false,
      showAgentsFields: false,
      agents: this.props.values?.agents || [],
      showAgentsEmail: this.props.values?.showAgentsEmail || false,
    };
  }

  uploadImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("image", file);

      // upload image to firebase storage and get url back to use in quill editor
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(file.name);
      await fileRef.put(file);
      const imageURL = await fileRef.getDownloadURL();

      if (imageURL) {
        const quill = this.reactQuillRef.getEditor();
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", imageURL);
      } else {
        console.error("Image upload failed.");
      }
    };
  };

  addAgent = () => {
    const { agents } = this.state;
    agents.push({
      agent_name: "",
      agent_email: "",
      agent_phone: "",
    });
    this.setState({ agents });
  };

  handleAgentsChange = (e, index, inputId) => {
    const { agents, inputs } = this.state;
    const { setValue } = this.props;
    agents[index][inputId] = e.target.value;
    this.setState({
      agents,
      inputs: {
        ...inputs,
        agents: agents,
      },
    });
    setValue("agents", agents);
  };

  handleShowAgentsEmail = (e) => {
    const { showAgentsEmail, inputs } = this.state;
    const { setValue } = this.props;
    this.setState({
      showAgentsEmail: !showAgentsEmail,
      inputs: {
        ...inputs,
        showAgentsEmail: !showAgentsEmail,
      },
    });
    setValue("showAgentsEmail", !showAgentsEmail);
  };

  toggleTab = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  componentDidMount = () => {
    const { values } = this.props;
    if (values.gallery && values.gallery.length > 0) {
      this.setState({ images: values.gallery.map((photo) => photo.url) });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { sections, values, fields } = nextProps;

    const inputs = {};

    for (const field of sections
      .map((s) => {
        const { field: singleField, additional } = s;
        const fieldDef = fields[singleField];

        if (fieldDef) {
          inputs[singleField] = values[singleField];
          if (fieldDef.multiselect) {
            inputs[singleField] = inputs[singleField] || [];
          }
        }

        return s.fields.concat(additional || []);
      })
      .flat()) {
      inputs[field] = values[field];
      const fieldDef = fields[field];
      if (fieldDef && fieldDef.multiselect) {
        inputs[field] = inputs[field] || [];
      }
    }

    this.setState({ inputs });
  }

  handleChange(e, inputId) {
    const { inputs } = this.state;

    inputs[inputId] = e.target.value;
    this.setState({ inputs });

    this.props.setValue(inputId, e.target.value);
  }

  toggleCheckbox = (name) => {
    const { inputs } = this.state;

    const value = !inputs[name];
    const newInputs = {
      ...inputs,
      [name]: value,
    };
    this.setState({
      inputs: newInputs,
    });
    this.props.setValue(name, value);
  };

  async handleDataChange(data, inputId) {
    const { inputs } = this.state;
    if (data && data.constructor === Array) {
      data = data.map((d) => (d.value ? d.value : d));
    }

    inputs[inputId] = data;
    this.setState({ inputs });
    this.props.setValue(inputId, data);
    const { user } = this.props;
    // saveActivity({
    //     text: `A file has been uploaded.`,
    //     userName: user.full_name,
    //     userId: user.uid
    //   })
  }

  sections() {
    const { sections, quickSections } = this.props;
    const { additionalSectionVisibility } = this.state;
    let finalSections = (sections || []).map((section) => {
      const { fields } = section || {};

      const fieldFilters = [
        "user_email",
        "realtor_email",
        "lender_email",
        "builder_email",
      ];

      const newFields = (fields || []).filter(
        (field) => fieldFilters.indexOf(field) < 0
      );

      return {
        ...section,
        fields: newFields,
      };
    });

    if (!additionalSectionVisibility && quickSections) {
      finalSections = sections.filter((s) =>
        (quickSections || []).includes(s.name || "")
      );
    }

    return finalSections.map((s) => <div key={s.name}>{this.section(s)}</div>);
  }

  toggleAdditionalFieldsVisibility = (e) => {
    e.preventDefault();

    this.setState({
      additionalFieldsVisibility: !this.state.additionalFieldsVisibility,
    });
  };

  renderTextForAdditionalFields = () => {
    const { additionalFieldsVisibility: visible } = this.state;

    if (visible) {
      return <i className={`fa fa-angle-double-up`}>&nbsp; Show Less</i>;
    }

    return (
      <i className={`fa fa-angle-double-down`}>&nbsp; Additional Fields</i>
    );
  };

  section({ name, tabs, description, field, fields, additional, isVisible }) {
    const { activeTab } = this.state;

    let additionalFields = null;

    const { additionalFieldsVisibility: visible } = this.state;

    if (additional && additional.length) {
      additionalFields = (
        <React.Fragment>
          <div className="col-md-12">
            <button
              type="button"
              style={{
                backgroundColor: "transparent",
                paddingLeft: 0,
                fontSize: 14,
                color: "#666",
                cursor: "pointer",
                border: 0,
              }}
              onClick={this.toggleAdditionalFieldsVisibility}
            >
              {this.renderTextForAdditionalFields()}
            </button>
          </div>
          {visible ? (
            <React.Fragment>
              <div style={{ height: 30 }}></div>
              {additional.map((f) => (
                <React.Fragment key={f}>{this.field(f)}</React.Fragment>
              ))}
            </React.Fragment>
          ) : null}
        </React.Fragment>
      );
    }

    let singleField = null;

    if (field) {
      singleField = this.field(field);
    }

    if (isVisible && !isVisible(this.state)) {
      return;
    }

    return (
      <React.Fragment>
        <h4 className="bg-grea-3">{name}</h4>
        <div className="search-contents-sidebar">
          <div className="row pad-20">
            {description ? (
              <p style={{ marginLeft: 15 }}>{description}</p>
            ) : null}

            {singleField}

            {!tabs && name === "Seller Information" && (
              <>
                {fields.map((f) => {
                  return (
                    <React.Fragment key={f}>{this.field(f)}</React.Fragment>
                  );
                })}
                {this.state.agents?.map((agent, index) => (
                  <>
                    <Input
                      className="capitalize"
                      width={4}
                      readOnly={false}
                      type={"text"}
                      label={"Seller Name"}
                      required={false}
                      onChange={(e) =>
                        this.handleAgentsChange(e, index, "agent_name")
                      }
                      value={agent.agent_name}
                    />
                    <Input
                      className="capitalize"
                      width={4}
                      readOnly={false}
                      type={"text"}
                      label={"Seller Email"}
                      required={false}
                      onChange={(e) =>
                        this.handleAgentsChange(e, index, "agent_email")
                      }
                      value={agent.agent_email}
                    />
                    <Input
                      className="capitalize"
                      width={4}
                      readOnly={false}
                      type={"text"}
                      label={"Seller Phone"}
                      required={false}
                      onChange={(e) =>
                        this.handleAgentsChange(e, index, "agent_phone")
                      }
                      value={agent.agent_phone}
                    />
                    <div className="col-12">
                      <button
                        className="btn btn-default btn-danger btn-pink-color float-right"
                        onClick={(e) => {
                          e.preventDefault();
                          const { agents } = this.state;
                          agents.splice(index, 1);
                          this.setState({ agents });
                        }}
                      >
                        Remove Seller
                      </button>
                    </div>
                  </>
                ))}
                <label className="pl-3">
                  <input
                    type="checkbox"
                    checked={this.state.showAgentsEmail}
                    name={"showAgentsEmail"}
                    onChange={(e) => {
                      this.handleShowAgentsEmail(e);
                    }}
                  />{" "}
                  Do you want to show seller email to buyer agents?
                </label>
                <div className="col">
                  <button
                    className="btn btn-sm btn-info float-right"
                    // className="btn btn-default btn-danger btn-pink-color float-right"
                    onClick={(e) => {
                      e.preventDefault();
                      this.addAgent();
                    }}
                  >
                    Add Seller
                  </button>
                </div>
              </>
            )}

            {!tabs &&
              name !== "Seller Information" &&
              fields.map((f) => (
                <React.Fragment key={f}>{this.field(f)}</React.Fragment>
              ))}

            {tabs && (
              <>
                <Nav tabs style={{ marginLeft: 10, marginTop: 10 }}>
                  {fields.map((f, index) => this.fieldTab(index, f))}
                </Nav>
                {fields.map((f, index) => (
                  <TabContent
                    key={index}
                    style={{ width: "100%" }}
                    activeTab={activeTab}
                  >
                    <TabPane tabId={index}>{this.field(f)}</TabPane>
                  </TabContent>
                ))}
              </>
            )}

            {additionalFields}
          </div>
        </div>
      </React.Fragment>
    );
  }

  fieldTab = (index, name) => {
    const { activeTab } = this.state;

    const { fields } = this.props;
    const fieldDef = fields[name];

    if (!fieldDef) {
      console.error("field not found", name);
      return;
    }

    let { label, title } = fieldDef;

    return (
      <NavItem key={index}>
        <NavLink
          className={classnames({ active: activeTab === index })}
          onClick={() => this.toggleTab(index)}
        >
          {title || label || sentencecase(name)}
        </NavLink>
      </NavItem>
    );
  };

  field(name) {
    const { fields } = this.props;
    const fieldDef = fields[name];

    if (!fieldDef) {
      return;
    }

    let {
      isVisible,
      multiselect,
      invalid,
      maxLength,
      step,
      min,
      max,
      width,
      required,
      readonly,
      type,
      options,
      async,
      label,
      placeholder,
      creatable,
      formatCreateLabel,
      jsx,
      title,
      h3,
      description,
    } = fieldDef;

    if (isVisible && !isVisible(this.state)) {
      return null;
    }

    if (!placeholder) {
      if (type === "phone") {
        placeholder = "e.g. (555) 555-5555";
      } else if (type === "email") {
        placeholder = "e.g. someone@example.com";
      }
    }

    if (type === "select" || options || async) {
      return (
        <Select
          className="capitalize"
          multiselect={multiselect}
          width={width || 3}
          formatCreateLabel={formatCreateLabel}
          name={name}
          async={async}
          placeholder={placeholder}
          onMenuOpen={(e) => {
            this.setState({ disableDropzones: true });
          }}
          onMenuClose={(e) => this.setState({ disableDropzones: false })}
          invalid={invalid}
          creatable={creatable}
          required={required || false}
          label={label || sentencecase(name)}
          onChange={(e) => this.handleDataChange(e, name)}
          value={this.state.inputs[name]}
          options={options}
        />
      );
    }

    if (type === "textblob") {
      return (
        <div className="col-lg-12">
          <div className="form-group">
            <label className="capitalize">{label || sentencecase(name)}</label>
            <textarea
              name={name}
              placeholder={placeholder || "Enter here"}
              className="input-text"
              maxLength={maxLength || undefined}
              value={this.state.inputs[name]}
              onChange={(e) => this.handleChange(e, name)}
            />
          </div>
        </div>
      );
    }

    if (type === "date") {
      return (
        <div
          className={`col-lg-${width || 3} col-md-${width * 2 || 3} col-sm-12`}
        >
          <div className="form-group">
            <label className="capitalize">{label || sentencecase(name)}</label>
            <DatePicker
              required={true}
              value={this.state.inputs[name]}
              onChange={(date) => this.handleDataChange(date, name)}
            />
          </div>
        </div>
      );
    }

    if (type === "image") {
      return <div className="col-lg-12">{this.image(name, label)}</div>;
    }

    if (type === "signature") {
      return (
        <div className="col-lg-12">
          <ReactQuill
            ref={(el) => {
              this.reactQuillRef = el;
            }}
            modules={{
              toolbar: {
                container: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["bold", "italic", "underline"],
                  ["link", "image"],
                  ["clean"],
                ],
                handlers: {
                  image: this.uploadImage,
                },
              },
              imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
              },
            }}
            theme="snow"
            value={this.state.inputs[name]}
            onChange={(e) => this.handleDataChange(e, name)}
          />
        </div>
      );
    }

    if (type === "gallery") {
      return <div className="col-lg-12">{this.gallery(name, label)}</div>;
    }

    if (type === "custom") {
      return (
        <React.Fragment>
          {h3 && (
            <h3
              style={{
                margin: "0px",
                color: "#4d4d4d",
                fontSize: "18px",
                paddingTop: "0px",
                borderBottom: "none",
                paddingLeft: "15px",
              }}
            >
              {h3}
            </h3>
          )}
          <div
            className={`col-lg-${width || 3} col-md-${
              width * 2 || 3
            } col-sm-12`}
          >
            <div className="form-group">
              <label className="capitalize">
                {label || sentencecase(name)}
              </label>
              {jsx}
            </div>
          </div>
          <hr />
        </React.Fragment>
      );
    }

    if (type === "checkbox") {
      return (
        <label className="pl-3">
          <input
            type="checkbox"
            checked={this.state.inputs[name]}
            name={name}
            onChange={(e) => this.toggleCheckbox(name)}
          />{" "}
          {label || ""}
        </label>
      );
    }

    return (
      <Input
        className="capitalize"
        title={title || ""}
        width={width || 3}
        min={min}
        max={max}
        name={name}
        step={step}
        readOnly={readonly || false}
        type={type || "text"}
        placeholder={placeholder}
        label={
          label === ""
            ? ""
            : name.startsWith("seller_")
            ? label || sentencecase(name.replace("seller_", "agent_"))
            : name.startsWith("agent_")
            ? label || sentencecase(name.replace("agent_", "seller_"))
            : label || sentencecase(name)
        }
        description={description}
        required={required || false}
        onChange={(e) => this.handleChange(e, name)}
        value={this.state.inputs[name]}
      />
    );
  }

  _crop() {
    // image in dataUrl
    this.setState({
      croppedUrl: this.refs.cropper.getCroppedCanvas()?.toDataURL(),
    });
  }

  updateBuyerImage = (photo, name) => {
    var newImageUrl = this.state.croppedUrl;
    const filename = "profileImage.png";
    const mimeType = "text/plain";
    fetch(newImageUrl).then((res) => {
      res.arrayBuffer().then((buf) => {
        const file = new File([buf], filename, { type: mimeType });
        file.preview = newImageUrl;
        this.handleDataChange(file, name);
      });
    });
  };

  onDrop(files, name, inputType) {
    if (files.length) {
      if (inputType === "image") {
        const photo = files[0];

        this.loaded = false;
        loadImage(
          photo,
          () => {
            photo.preview = URL.createObjectURL(photo);
            this.setState({ imgInput: photo.preview });
            this.handleDataChange(photo, name);
            window.$(`#realtorModal-${name}`).modal();

            // img.toBlob((blob) => {
            //   const file = photo;
            //   file.preview = URL.createObjectURL(blob);
            //   this.setState({ imgInput: file.preview });
            //   this.handleDataChange(file, name);
            //   window.$(`#realtorModal-${name}`).modal();
            // });
          },
          { orientation: true, maxWidth: 950 }
        );
      } else if (inputType === "gallery") {
        const photos = files;

        this.loaded = false;

        for (let photo of photos) {
          loadImage(
            photo,
            () => {
              photo.preview = URL.createObjectURL(photo);
              let newPhotos = [].concat(this.state.inputs[name] || []);
              newPhotos = newPhotos.filter(
                (photo) => photo.change !== "deleted"
              );
              let featured = newPhotos.length === 0;
              newPhotos.push({ image: photo, change: "added", featured });

              this.handleDataChange(newPhotos, name);

              // img.toBlob((blob) => {
              //   const file = new File([blob], "photo");
              //   file.preview = URL.createObjectURL(blob);
              //   let newPhotos = [].concat(this.state.inputs[name] || []);
              //   newPhotos = newPhotos.filter(
              //     (photo) => photo.change !== "deleted"
              //   );
              //   let featured = newPhotos.length === 0;
              //   newPhotos.push({ image: file, change: "added", featured });

              //   this.handleDataChange(newPhotos, name);
              // });
            },
            { orientation: true, maxWidth: 2280 }
          );
        }
      }
    }
  }

  photoPreview(photo) {
    if (photo) {
      if (typeof photo === "string") {
        return (
          <img
            alt="preview"
            style={{ maxWidth: "100%", height: 300 }}
            src={photo}
          />
        );
      } else {
        return (
          <img
            alt="preview"
            style={{ maxWidth: "100%", height: 300 }}
            src={photo.preview}
          />
        );
      }
    } else {
      return null;
    }
  }

  photosPreview(photos, name) {
    const { disableDropzones } = this.state;

    const dropzone = (
      <Dropzone
        onDrop={(e) => this.onDrop(e, name, "gallery")}
        maxSize={20000000}
        disabled={disableDropzones}
        multiple={true}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              id="myDropZone"
              style={{
                height: 240,
                fontSize: 12,
                padding: 10,
                marginBottom: 20,
                marginTop: 15,
              }}
              className="dropzone dropzone-design"
            >
              <div className="dz-message" style={{ fontSize: 14 }}>
                <span>
                  To Upload, Click Or Drag In The Gray Box. To Upload Multiple
                  Photos At Once, Hold The Ctrl (Command) Key Down When Clicking
                  On The Filenames. To Rearrange Your Photos Once Uploaded,
                  Click And Drag The Image.
                </span>
                {/* <span>
                    Drop files(s) less than 20 Mb each, here to upload
                </span> */}
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    );

    return dropzone;
  }

  image(name, label) {
    const { disableDropzones } = this.state;
    const photo = this.state.inputs[name];
    if (disableDropzones) {
      return null;
    }

    if (photo) {
      return (
        <div>
          <p>{this.photoPreview(photo)}</p>
          <p>
            <button
              className="btn btn-default btn-danger btn-pink-color"
              onClick={(e) => this.handleDataChange(undefined, name)}
            >
              Remove image
            </button>
          </p>
          <div
            id={`realtorModal-${name}`}
            className="modal"
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    <Cropper
                      ref="cropper"
                      src={this.state.imgInput}
                      style={{ height: 400, width: "100%" }}
                      aspectRatio={1 / 1}
                      crop={this._crop.bind(this)}
                    />
                    <a
                      href="#/"
                      data-toggle="modal"
                      data-target={`#realtorModal-${name}`}
                    >
                      <button
                        onClick={() => this.updateBuyerImage(photo, name)}
                      >
                        {" "}
                        OK{" "}
                      </button>
                    </a>
                    <a
                      href="#/"
                      data-toggle="modal"
                      data-target={`#realtorModal-${name}`}
                    >
                      <button> Cancel </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <label className="capitalize">{label && label}</label>
          <Dropzone
            onDrop={(e) => this.onDrop(e, name, "image")}
            maxSize={20000000}
            multiple={false}
            disabled={disableDropzones}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  id="myDropZone"
                  style={{ height: 300 }}
                  className="dropzone dropzone-design"
                >
                  <div className="dz-message" style={{ fontSize: 14 }}>
                    <span>
                      Drop files(s) less than 20 Mb each, here to upload
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      );
    }
  }

  onSortEnd = ({ oldIndex, newIndex }, name, photos) => {
    let images = arrayMove(photos, oldIndex, newIndex);
    this.handleDataChange(images, name);
  };

  gallery(name, label) {
    let photos = this.state.inputs[name] || [];
    let photosMarkup = null;
    let remove = null;
    if (photos && photos.length) {
      remove = (
        <div>
          <button
            className="btn btn-default btn-danger"
            style={{ marginBottom: "15px" }}
            onClick={(e) => {
              e.preventDefault();

              for (let photo of photos) {
                photo.change = "deleted";
              }
              this.handleDataChange(photos, name);
            }}
          >
            Remove all
          </button>
        </div>
      );
    }

    photosMarkup = (
      <div>
        {this.photosPreview(photos, name)}
        {remove}
      </div>
    );

    photos = photos.filter((photo) => photo.change !== "deleted");

    return (
      <React.Fragment>
        {label !== null && (
          <label
            style={{ marginTop: "15px", marginBottom: "15px" }}
            className="capitalize"
          >
            {label || sentencecase(name)}
          </label>
        )}

        <SortableList
          axis="xy"
          items={photos}
          onSortEnd={(a) => this.onSortEnd(a, name, photos)}
          photos={photos}
          handleDataChange={this.handleDataChange.bind(this)}
          name={name}
        />
        {photosMarkup}
      </React.Fragment>
    );
  }

  responseGoogle = async (googleCode) => {
    const getRefreshToken = firebase
      .functions()
      .httpsCallable("getRefreshToken");
    const refreshToken = await getRefreshToken({
      code: googleCode.code,
    });
    const db = firebase.firestore();
    await db
      .collection("users")
      .doc(this.props.user.uid)
      .update({
        googleAccount: {
          refreshToken: refreshToken.data.refresh_token,
        },
      });
    window.location.reload();
  };

  failureGoogle = (response) => {
    console.log(response);
  };

  render() {
    const { quickSections } = this.props;
    const { additionalSectionVisibility: visibilityFlag } = this.state;
    return (
      <React.Fragment>
        {this.sections()}
        <h4 class="bg-grea-3">Google Account</h4>
        <div class="search-contents-sidebar">
          <div class="row pad-20">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                {!this.props.user?.googleAccount && (
                  <GoogleLogin
                    // clientId="182635199829-g1eah9omgp0q47oprvoeaqlh1ncc7han.apps.googleusercontent.com"
                    clientId="115691394769-u2kekie8ni2nqjf0bbhikbvp9h46pe74.apps.googleusercontent.com"
                    scope="https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.insert https://www.googleapis.com/auth/gmail.labels"
                    buttonText="Connect to Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.failureGoogle}
                    accessType="offline"
                    responseType="code"
                    cookiePolicy="single_host_origin"
                  />
                )}
                {this.props.user?.googleAccount && (
                  <>
                    <button
                      className="google-signin-button"
                      onClick={async (e) => {
                        e.preventDefault();
                        const db = firebase.firestore();
                        const revokeGoogleAccount = firebase
                          .functions()
                          .httpsCallable("revokeGoogleAccount");
                        await revokeGoogleAccount({
                          refreshToken:
                            this.props.user.googleAccount.refreshToken,
                        });
                        await db.collection("users").doc(this.props.user.uid).update({
                          googleAccount: firebase.firestore.FieldValue.delete(),
                        });
                        window.location.reload();
                      }}
                    >
                      <div className="google-icon-wrapper">
                        <i className="fa fa-google google-icon"></i>
                      </div>
                      <span className="button-text">
                        Disconnect Google Account
                      </span>
                    </button>
                    {/* <button
                      className="btn btn-danger"
                      onClick={async (e) => {
                        e.preventDefault();
                        const db = firebase.firestore();
                        const revokeGoogleAccount = firebase
                          .functions()
                          .httpsCallable("revokeGoogleAccount");
                        await revokeGoogleAccount({
                          refreshToken:
                            this.props.user.googleAccount.refreshToken,
                        });
                        db.collection("users").doc(this.props.user.uid).update({
                          googleAccount: firebase.firestore.FieldValue.delete(),
                        });
                        window.location.reload();
                      }}
                    >
                      Disconnect Google Account
                    </button> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {quickSections && (
          <div className="col-md-12">
            <button
              type="button"
              style={{
                backgroundColor: "transparent",
                paddingLeft: 0,
                fontSize: 14,
                color: "#666",
                cursor: "pointer",
                border: 0,
              }}
              onClick={() =>
                this.setState({ additionalSectionVisibility: !visibilityFlag })
              }
            >
              <i
                style={{
                  marginLeft: "10px",
                  marginBottom: "15px",
                  color: "#FF214F",
                }}
                className={`fa fa-angle-double-${
                  visibilityFlag ? "up" : "down"
                }`}
              >
                &nbsp;{" "}
                {visibilityFlag ? "Show Quick Profile" : "Show Advance Profile"}
              </i>
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const SortableItem = SortableElement(
  ({ value, index, photos, handleDataChange, name }) => (
    <React.Fragment key={`item-${index}`}>
      {value.url ? (
        <React.Fragment>
          <img
            key={`item-${index}`}
            alt="thumbnail"
            src={value.url}
            className="img-thumbnail"
            style={{ maxHeight: 180, zIndex: 0 }}
          />
          <button
            key={`btn-${index}`}
            style={{
              position: "absolute",
              border: 0,
              cursor: "pointer",
              borderRadius: 15,
              height: 25,
              width: 25,
              top: 8,
              right: 8,
              zIndex: 1,
              color: "#ff214f",
            }}
            onClick={(e) => {
              e.preventDefault();
              let newPhotos = photos.map((p) => {
                if (p.url === value.url) {
                  return { ...p, change: "deleted" };
                }
                return { ...p };
              });
              handleDataChange(newPhotos, name);
            }}
          >
            x
          </button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <img
            key={`item-${index}`}
            alt="thumbnail"
            src={value.image.preview}
            className="img-thumbnail"
            style={{ maxHeight: 180, zIndex: 0 }}
          />
          <button
            key={`btn-${index}`}
            style={{
              position: "absolute",
              zIndex: 1,
              color: "#ff214f",
              border: 0,
              cursor: "pointer",
              borderRadius: 15,
              height: 25,
              width: 25,
              top: 8,
              right: 8,
            }}
            onClick={(e) => {
              e.preventDefault();
              let newPhotos = photos.filter((p) => p !== value);
              handleDataChange(newPhotos, name);
            }}
          >
            x
          </button>
        </React.Fragment>
      )}
    </React.Fragment>
  )
);

const SortableList = SortableContainer(
  ({ items, photos, handleDataChange, name }) => {
    return (
      <div style={{ overflow: "hidden" }}>
        {items.map((value, index) => (
          <div
            key={index}
            style={{ position: "relative", float: "left", marginRight: 8 }}
          >
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={value}
              photos={photos}
              handleDataChange={handleDataChange}
              name={name}
            />
          </div>
        ))}
      </div>
    );
  }
);
