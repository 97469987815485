import React, { useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { Line } from "react-chartjs-2";

import styles from "./style.module.css";

function TotalOffersByDay({ offersValues }) {
  const [labels, setLabels] = useState();
  const [selectedFilter, setSelectedFilter] = useState(6);
  const [countOffers, setCountOffers] = useState(0);
  const [xAxesValues, setXAxesValues] = useState([]);
  const [percent, setPercent] = useState(0);

  const handleFilter = (limit = 6) => {
    const currentDate = new Date();
    const lastDate = new Date();
    const prevCycleLastDate = new Date();
    lastDate.setDate(currentDate.getDate() - limit);
    prevCycleLastDate.setDate(currentDate.getDate() - limit * 2);
    const prevCycleFilterdData = offersValues.filter(
      (el) =>
        new Date(el.createdAt).getTime() * 1000 > prevCycleLastDate &&
        new Date(el.createdAt).getTime() * 1000 < lastDate
    );
    const offersFilteredData = offersValues.filter(
      (el) => new Date(el.createdAt).getTime() * 1000 > lastDate
    );

    setSelectedFilter(limit);

    const prevDateCount = {};
    for (let index = 1; index <= limit; index++) {
      const date = new Date();
      const dateString = new Date(
        date.getTime() - (limit - index) * 1000 * 24 * 60 * 60
      );
      const findPrevData = prevCycleFilterdData.filter(
        (el) =>
          new Date(dateString).toDateString() ==
          new Date(new Date(el.createdAt).getTime() * 1000).toDateString()
      );
      prevDateCount[dateString] = findPrevData.length;
    }

    const dateCount = {};
    for (let index = 1; index <= limit; index++) {
      const date = new Date();
      const dateString = new Date(
        date.getTime() - (limit - index) * 1000 * 24 * 60 * 60
      );
      const findData = offersFilteredData.filter(
        (el) =>
          new Date(dateString).toDateString() ==
          new Date(new Date(el.createdAt).getTime() * 1000).toDateString()
      );
      dateCount[dateString] = findData.length;
    }
    const values = Object.values(dateCount);
    const prevValues = Object.values(prevDateCount);
    // const lastResult = values.at(-1);
    // const firstResult = values[0] !== 0 ? values.at() : 1;
    // const percentage = ((lastResult - firstResult) / firstResult) * 100;
    // percentage === percentage && setPercent(percentage);
    const sum = values.reduce((accumulator, value) => accumulator + value, 0);
    const prevSum = prevValues.reduce(
      (accumulator, value) => accumulator + value,
      0
    );

    const percentage = ((sum - prevSum) / prevSum) * 100;
    percentage === percentage && setPercent(percentage.toFixed(2));

    !values.every((value) => value == 0)
      ? setXAxesValues(values)
      : setXAxesValues([]);
    setCountOffers(sum);
    setLabels(Object.keys(dateCount));
  };

  useEffect(() => {
    handleFilter(6);
  }, [offersValues]);

  const data = {
    labels,
    datasets: [
      {
        label: "First dataset",
        data: xAxesValues,
        fill: false,
        lineTension: 0.1,
        pointRadius: 0,
        borderColor: "#28A745",
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },

    responsive: true,
    type: "line",
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [0, 10, 5],
            lineWidth: 2,
            borderDashOffset: 6,
            color: "#D3D8DD",
            drawBorder: false,
          },
          ticks: {
            padding: 20,
            crossAlign: "start",
            callback: (value) => (value % 1 == 0 ? value : null),
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 6,
            fontSize: 12,
            fontFamily: "Raleway",
            angle: 0,
            callback: (value) => {
              const replacedDate = new Date(value)
                .toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                })
                .split(" ");
              const mounth = replacedDate[0];
              const day = replacedDate[1];

              return `${day} ${mounth}`;
            },
          },
        },
      ],
    },
  };

  return (
    <div
      style={{
        width: "auto",
        padding: "20px",
        backgroundColor: "#fff",
      }}
    >
      <h3
        style={{
          fontWeight: " 600",
          fontSize: " 16px",
          color: "#222222",
          marginLeft: "6px",
          marginBottom: "0",
        }}
      >
        Total Offers Over Time
      </h3>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <div
          style={{
            marginLeft: "6px",
            fontWeight: "500",
            fontSize: "18px",
            color: "#000000",
          }}
        >
          {countOffers}
          <img
            src={`${
              percent >= 0 ? "/images/arrow-up.svg" : "/images/arrow-down.svg"
            }`}
            style={{
              margin: "0 5px",
            }}
          />
          <span
            style={{
              fontWeight: "500",
              fontSize: "14px",
              textAlign: "right",
              color: `${percent >= 0 ? "#28A745" : "#FF0000"}`,
              fontFamily: "Poppins",
            }}
          >
            {percent == "Infinity" ? "100" : percent}%
          </span>
          <br />
          <div
            style={{
              color: "#4C5862",
              fontWeight: "400",
              fontSize: "12px",
              margin: "10px 0",
            }}
          >
            Offers
          </div>
        </div>
        <UncontrolledButtonDropdown
          direction="down"
          background="white"
          className={styles.dropdown}
        >
          <DropdownToggle caret color="light">
            {selectedFilter === 6
              ? "6 days"
              : selectedFilter === 30
              ? "1 month"
              : selectedFilter === 90 && "3 month"}
            <img
              src="/images/arrow-dropdown.svg"
              style={{
                filter: "brightness(0%)",
                width: "8px",
              }}
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => handleFilter(6)}
              disabled={selectedFilter === 6}
            >
              6 days
            </DropdownItem>
            <DropdownItem
              onClick={() => handleFilter(30)}
              disabled={selectedFilter === 30}
            >
              1 month
            </DropdownItem>
            <DropdownItem
              onClick={() => handleFilter(90)}
              disabled={selectedFilter === 90}
            >
              3 month
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        {!xAxesValues?.length && (
          <span
            style={{
              position: "absolute",
              // transform: "translate(100%, 440%)",
              top: "50%",
              left: "10%",
            }}
          >
            There is no data for {selectedFilter} days
          </span>
        )}
        <Line options={options} data={data} />
      </div>
    </div>
  );
}

export default TotalOffersByDay;
