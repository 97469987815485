import React, { Component } from 'react'
import { Link } from 'react-router-dom'


class Footer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            inputs: {
                JMNWM: ''
            },
            formsSubmitted: {}
        }
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="container footer-inner">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="footer-item clearfix">
                                    <img
                                        src="/img/logos/logo.png"
                                        alt="logo"
                                        className="f-logo"
                                    />
                                    <div className="text">
                                        <p>
                                        Homematchx is a leading data management tool designed specifically to collect and analyze offer data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className="footer-item">
                                    <h4>Contact Us</h4>
                                    <div className="f-border" />
                                    <ul className="contact-info">
                                        <li>
                                            <i className="flaticon-pin" />
                                            1881 Sylvan Ave<br></br>
                                            Ste 100<br></br>
                                            Dallas, Texas 
                                        </li>
                                        <li>
                                            <i className="flaticon-mail" />
                                            <Link to="mailto:info@homematchx.com">
                                                info@homematchx.com
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="flaticon-phone" />
                                            <Link to="tel:+55-417-634-7071">
                                            (214) 991-4147 
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="flaticon-fax" />
                                            (214) 446-5574
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                                <div className="footer-item">
                                    <h4>Useful Links</h4>
                                    <div className="f-border" />
                                    <ul className="links">
                                        {/* <li>
                                            <Link to="/homes">Search Homes</Link>
                                        </li>
                                        <li>
                                            <Link to="/search/buyers">Search Buyers</Link>
                                        </li>
                                        <li>
                                            <Link to="/pricing">Pricing</Link>
                                        </li>
                                        <li>
                                            <Link to="/realEstateServices">Limited Real Estate Services</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/about">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms_of_use">
                                                Terms of use
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms_of_use">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/dashboard">
                                                Dashboard
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/lenders">
                                                Lender
                                            </Link>
                                        </li> */}
                                        <li>
                                            <Link to="/faqs">
                                                FAQs
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/help">
                                                Help
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="floating-chat">
                        <p><i className="fas fa-headset"></i> See more steps</p>
                    </div> */}
                </footer>
            </React.Fragment>
        )
    }
}

export default Footer
