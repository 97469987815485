import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from "moment"
import { getActivities } from "../Services/Activity";

const UserActivities = (props) => {
  const [activities,  setActivities] = useState([])
  const [loading,  setLoading] = useState(false)

  useEffect(() => {
    getAllActivities()
  }, [])

  const getAllActivities = async () => {
    setLoading(true)
    const resp = await getActivities(props.user)
    setActivities(resp)
    setLoading(false)
  }

  return (
      <div className="content-area5">
        <div className="dashboard-content activities-dashboard">
          <Container>
            <Row>
              <Col>
                <h5 className="history-of-changes">User Activities</h5>
              </Col>
            </Row>
            <Row>    
              <Col className="activities-container">
                {activities.length === 0 && !loading && <p>Not activities found.</p>}
                {loading && <p>Loading activities...</p>}
                <ul>
                  {activities.map(activity => {
                    const activityData = activity.data()
                    return <li className="margin-vert-6" key={activity.id}>
                    <Row>
                    <Col lg={2} className="activities-row">
                        <p>{activityData.userName}</p>
                      </Col>
                      <Col lg={4} className="activities-row">
                        <p>{activityData.address}</p>
                      </Col>
                      <Col lg={4} className="activities-row">
                        <p><a href={`/${activityData.type}/${activityData.linkId}`} target="_blank">{activityData.text}</a></p>
                      </Col>
                      <Col lg={2} className="activities-time">
                        <p className="activity-time" title={moment(activityData.createdAt*1000).format('YYYY-MM-DD hh:mmA')}>{moment(activityData.createdAt*1000).fromNow()}</p>
                      </Col>
                    </Row>
                  </li>
                  })}
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }


export default UserActivities;
