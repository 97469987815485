import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import Alert from "components/Alert";
import "firebase/analytics";
import { update } from "lodash";
let { logEvent } = firebase.analytics();

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      inputs: {
        fullname: "",
        email: "",
        password: "",
        confirm_password: "",
        YIAOW: "",
      },
      loading: false,
      formsSubmitted: {},
      activeBtn: 0,
    };
  }

  handleChange(e, inputId) {
    const { inputs } = this.state;
    inputs[inputId] = e.target.value;
    this.setState({ inputs });
  }

  async handleSignupSubmit(e) {
    const createStripeCustomerOnSignUp = firebase
      .functions()
      .httpsCallable("createStripeCustomerOnSignUp");
    const db = firebase.firestore();
    e.preventDefault();

    const { email, password, confirm_password } = this.state.inputs;
    const context = this;

    if (password !== confirm_password) {
      context.setState({
        error: { message: "Password and confirm password should match." },
      });
    } else {
      context.setState({
        error: null,
        loading: true,
      });
      context.setState({ loading: true, showPlans: false });
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          console.log(user);
          createStripeCustomerOnSignUp({
            email,
          }).then((res) => {
            this.props.setCustomerId(res?.data?.customer.id);
            db.collection("users").doc(user.user.uid).update({
              stripeCustomerId: res?.data?.customer.id,
            });
          });
          this.props.nextStep();
          const { fullname: displayName } = this.state.inputs;
          logEvent("user_singup", {
            uid: user.user.uid,
            displayName,
          });
          user.updateProfile({ displayName });
          // this.props.history.push("/dashboard");
        })
        .catch(function (error) {
          context.setState({
            error,
            loading: false,
          });
        });
    }
  }

  render() {
    let alert = null;
    const { error, loading, showPlans, activeBtn } = this.state;

    if (error) {
      alert = <Alert>{error.message}</Alert>;
    }

    return (
      <form
        onSubmit={(e) => {
          if (!this.state.formsSubmitted.signup) {
            this.handleSignupSubmit(e);
          }
        }}
      >
        <div className="form-group">
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            className="input-text"
            onChange={(e) => this.handleChange(e, "email")}
            value={this.state.inputs["email"]}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="input-text"
            onChange={(e) => this.handleChange(e, "password")}
            value={this.state.inputs["password"]}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            name="confirm_password"
            placeholder="Confirm Password"
            className="input-text"
            onChange={(e) => this.handleChange(e, "confirm_password")}
            value={this.state.inputs["confirm_password"]}
          />
        </div>
        {alert}
        <div className="form-group mb-0">
          <button
            type="submit"
            disabled={loading}
            className="btn-md btn-block signup-btn"
          >
            Continue
          </button>
        </div>
      </form>
    );
  }
}
export default Signup;
