import React, { Component } from "react";

import {
  Label,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  FormGroup,
  Input
} from "reactstrap";

class EditMessageModal extends Component {
  state = {
    isOpen: false,
    text: this.props.text
  };

  toggle = () => {
    const state = !this.state.isOpen;

    this.setState({
      isOpen: state
    });

    this.props.onModalStateChange(state);
  };

  submit = () => {
    const { text } = this.state;
    this.props.onUpdate("edit", "text", text);
    this.toggle();
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { text } = this.state;
    return (
      <React.Fragment>
        <Button
          color="link"
          onClick={this.toggle}
          className="mr-1"
          {...this.props}
          style={{ margin: "0px", padding: "0px" }}
        >
          <span style={{ color: "blue" }} className="fa fa-pencil"></span>
        </Button>

        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className="modal-danger"
        >
          <ModalHeader toggle={this.toggle}>Edit Message</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="text">New Message</Label>
              <Input
                type="textarea"
                name="text"
                id="text"
                value={text}
                onChange={this.onChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.submit}>
              Edit
            </Button>{" "}
            <Button color="default" onClick={this.toggle}>
              Canel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default EditMessageModal;
