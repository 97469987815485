import React, { useEffect, useState, useRef } from "react";
import { Map, Marker } from "google-maps-react";
import GlobalLocationChart from "./GlobalLocationChart";
import { stateCodeMap, stateMapping } from "components/Analytics/info";
import arrowLeft from "../../../../assets/icons/arrow-left.png";
import arrowRight from "../../../../assets/icons/arrow-right.png";

import styles from "./GlobalLocation.module.css";
import { set } from "lodash";

const GlobalLocation = ({
  mobile,
  locations,
  google,
  locationsOffers,
  selectedLocation,
  unfilteredOffers,
  setSelectLocation,
  selectLocationCity,
  setSelectLocationCity,
  offers,
  user,
  setQueryParam,
  getGlobalAgents,
  globalAgents,
  allClosedGlobalSellers
}) => {
  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [center, setCenter] = useState({ lat: 32.7767, lng: -96.797 });
  const mapRef = useRef(null);
  const [showDetails, setShowDetails] = useState(false);

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const geocodeAddress = async (address) => {
    let lat = 0;
    let lng = 0;
    const geocoder = await new window.google.maps.Geocoder();
    const coordinatesValues = await geocoder.geocode(
      { address },
      (results, status) => {
        if (
          status === window.google.maps.GeocoderStatus.OK &&
          results.length > 0
        ) {
          const location = results[0].geometry.location;
          const latitude = location.lat();
          const longitude = location.lng();
          return { latitude, longitude };
        } else {
          return { latitude: 0, longitude: 0 };
        }
      }
    );
    if (coordinatesValues?.results?.length && coordinatesValues?.results[0]) {
      const res = {};
      const stateCode =
        stateCodeMap[
          coordinatesValues.results[0].address_components[0].long_name
        ];
      fetch(
        `https://api.census.gov/data/2019/pep/population?get=NAME&for=place:*&in=state:${stateCode}`
      )
        .then((response) => response.json())
        .then((data) => {
          const citiesResult = data
            .slice(1)
            .map((cityData) => cityData[0].split(" ")[0]);
          const locationsData = Object.entries(locations);
          for (let i = 0; i < locationsData.length; i++) {
            const addressParts = locationsData[i][0].split(",");
            const city = addressParts[addressParts.length - 2].trim();
            if (
              coordinatesValues.results[0].address_components[0].long_name ===
              stateMapping[addressParts[2].split(" ")[1]]
            ) {
              if (res[city]) {
                res[city] += locationsData[i][1];
              } else {
                res[city] = locationsData[i][1];
              }
            }
          }
          citiesResult.map((city) => {
            if (!res[city]) {
              res[city] = 0;
            }
          });

          // setCities(prevCities => [...prevCities, ...Object.entries(res)]);
          // setCities(prevCities => [...new Set([...prevCities, ...Object.entries(res)])]);
          setCities((prevCities) => {
            const entries = Object.entries(res);
            const merged = [...prevCities, ...entries];
            const uniqueEntries = merged.filter(
              (entry, index) =>
                merged.findIndex(([key]) => key === entry[0]) === index
            );
            return uniqueEntries;
          });
        })
        .catch(() => {});

      const location = coordinatesValues.results[0].geometry.location;
      lat = location.lat();
      lng = location.lng();
    }
    setCenter({ lat, lng });
  };

  const locationHandler = () => {
    const stateCodeMapArr = Object.keys(stateCodeMap);
    const res = {};
    const locationsData = Object.entries(locations);
    for (let i = 0; i < locationsData.length; i++) {
      const addressParts = locationsData[i][0].split(",");
      const city = addressParts[1];
      if (selectLocationCity.includes(city)) {
        setAddresses([...addresses, city]);
      }
      const state = stateMapping[addressParts[2].split(" ")[1]];
      if (res[state]) {
        res[state] += locationsData[i][1];
      } else {
        res[state] = locationsData[i][1];
      }
    }
    stateCodeMapArr.map((e) => {
      if (!res[e]) {
        res[e] = 0;
      }
    });

    if (!selectedLocation?.length) {
      setState(res);
    }
  };

  useEffect(() => {
    locationHandler();
  }, [
    unfilteredOffers,
    locations,
    locationsOffers,
    selectedLocation,
    setSelectLocation,
    selectLocationCity,
    setSelectLocationCity,
  ]);

  useEffect(() => {
    if (selectedLocation?.length) {
      for (let i = 0; i < selectedLocation.length; i++) {
        geocodeAddress(selectedLocation[i]);
      }
    }
  }, [selectedLocation, locations]);

  return (
    <>
      {mobile ? (
        <>
          {/* mobile view */}
          <button
            style={{
              marginBottom: "15px !important",
            }}
            className={`mobile-view-details-btn ${
              showDetails ? "float-left" : "float-right"
            }`}
            onClick={toggleShowDetails}
          >
            {!showDetails ? (
              <>
                View Details
                <img src={arrowRight} className="ml-2 mobile-icons" />
              </>
            ) : (
              <>
                <img src={arrowLeft} className="mr-2 mobile-icons" />
                View Map
              </>
            )}
          </button>
          <div
            className="mapWrapper row"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#ffffff",
            }}
          >
            {!showDetails ? (
              <div
                className="col-12"
                style={{
                  width: "50%",
                  height: "390px",
                  border: "1px solid #999",
                  borderRadius: "4px",
                }}
              >
                <Map
                  ref={mapRef}
                  google={google}
                  zoom={selectedLocation?.length ? 5.5 : 4}
                  zoomControl={true}
                  initialCenter={center}
                  center={center}
                  style={{ width: "100%", height: "390px" }}
                  onBoundsChanged={() => {
                    mapRef.current.map.zoom = 5.5;
                  }}
                  containerStyle={{
                    position: "relative",
                    width: "100%",
                    height: "390px",
                  }}
                >
                  {offers?.map((item) => {
                    const listing = item;
                    if (
                      !listing?.latitude ||
                      (selectedLocation?.length &&
                        !selectedLocation.includes(listing.state))
                    ) {
                      return <></>;
                    }
                    return (
                      <Marker
                        title={listing.address}
                        position={{
                          lat: listing.latitude,
                          lng: listing.longitude,
                        }}
                        icon={{
                          url: "https://i.ibb.co/8srdHmj/dot.png",
                          anchor: new window.google.maps.Point(12, 12),
                          scaledSize: new window.google.maps.Size(24, 24),
                        }}
                      />
                    );
                  })}
                </Map>
              </div>
            ) : (
              <div className={`m-0 p-0 col-12 ${styles.locationDetailsChart}`}>
                {!selectedLocation?.length || cities?.length ? (
                  <GlobalLocationChart
                    getGlobalAgents={getGlobalAgents}
                    globalAgents={globalAgents}
                    mobile={mobile}
                    user={user}
                    locationsOffers={locationsOffers}
                    selectedLocation={selectedLocation}
                    unfilteredOffers={unfilteredOffers}
                    setSelectLocation={setSelectLocation}
                    cities={cities}
                    state={state}
                    selectLocationCity={selectLocationCity}
                    setSelectLocationCity={setSelectLocationCity}
                    setQueryParam={setQueryParam}
                    allClosedGlobalSellers={allClosedGlobalSellers}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className="mapWrapper row"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#ffffff",
          }}
        >
          <div
            className="col-12 col-md-6"
            style={{
              width: "50%",
              height: "390px",
              border: "1px solid #999",
              borderRadius: "4px",
            }}
          >
            <Map
              ref={mapRef}
              google={google}
              zoom={selectedLocation?.length ? 5.5 : 4}
              zoomControl={true}
              initialCenter={center}
              center={center}
              style={{ width: "100%", height: "390px" }}
              onBoundsChanged={() => {
                mapRef.current.map.zoom = 5.5;
              }}
              containerStyle={{
                position: "relative",
                width: "100%",
                height: "390px",
              }}
            >
              {offers?.map((item) => {
                const listing = item;
                if (
                  !listing?.latitude ||
                  (selectedLocation?.length &&
                    !selectedLocation.includes(listing.state))
                ) {
                  return <></>;
                }
                return (
                  <Marker
                    title={listing.address}
                    position={{
                      lat: listing.latitude,
                      lng: listing.longitude,
                    }}
                    icon={{
                      url: "https://i.ibb.co/8srdHmj/dot.png",
                      anchor: new window.google.maps.Point(12, 12),
                      scaledSize: new window.google.maps.Size(24, 24),
                    }}
                  />
                );
              })}
            </Map>
          </div>
          <div
            className={`col-12 col-md-6 mt-4 mt-md-0 ${styles.locationDetailsChart}`}
          >
            {!selectedLocation?.length || cities?.length ? (
              <GlobalLocationChart
                getGlobalAgents={getGlobalAgents}
                globalAgents={globalAgents}
                user={user}
                locationsOffers={locationsOffers}
                selectedLocation={selectedLocation}
                unfilteredOffers={unfilteredOffers}
                setSelectLocation={setSelectLocation}
                cities={cities}
                state={state}
                selectLocationCity={selectLocationCity}
                setSelectLocationCity={setSelectLocationCity}
                setQueryParam={setQueryParam}
                allClosedGlobalSellers={allClosedGlobalSellers}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default GlobalLocation;
