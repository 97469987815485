import React, { Component } from 'react'

class Services extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: ''
        }
    }

    render() {
        return (
            <React.Fragment>
                {/* <div className="services content-area bg-grea-3">
                    <div className="container">
                        <div className="main-title text-center">
                            <h1>Put Homematchx to work for you</h1>
                            <p>Homematchx's smart matchmaking system helps close more sales with ease.</p>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="service-info">
                                    <div className="icon">
                                        <i className="flaticon-user" />
                                    </div>
                                    <h3>Benefit for Agents</h3>
                                    <p>
                                        Find the perfect match for clients that creates a win win.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="service-info">
                                    <div className="icon">
                                        <i className="flaticon-apartment-1" />
                                    </div>
                                    <h3>Benefit for Buyers</h3>
                                    <p>
                                        Locate the right home in time that meet your must haves. 
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="service-info">
                                    <div className="icon">
                                        <i className="flaticon-discount" />
                                    </div>
                                    <h3>Benefit for Sellers</h3>
                                    <p>
                                        Identify home selling options for moving comfortably.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 offset-xl-2 offset-lg-2 col-lg-4 col-md-6 col-sm-6">
                                    <div className="service-info">
                                        <div className="icon">
                                            <i className="flaticon-financial" />
                                        </div>
                                        <h3>Benefit for Lenders</h3>
                                        <p>Showcase the buyer's qualification to give sellers confidence in the process.</p>
                                    </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="service-info">
                                    <div className="icon">
                                        <i className="flaticon-apartment" />
                                    </div>
                                    <h3>Benefit for Builders</h3>
                                    <p>Connect with buyers who match well with a new home or future development.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </React.Fragment>
        )
    }
}

export default Services
