import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Badge } from "reactstrap";
import firebase from "firebase/app";
import "firebase/functions";
import * as actions from "../Store/Actions/type";
import moment from "moment";
import { sidebarPath } from "utils/sidebar";

class AdminNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      subMenuShow:
        this.props.location.pathname === "/account" ||
        this.props.location.pathname === "/my-subscriptions" ||
        this.props.location.pathname === "/my-analytics"
          ? true
          : false,
    };
  }

  dispatchPackages = (packages) => {
    const { dispatch } = this.props;
    dispatch({
      type: actions.SET_PACKAGES,
      payload: packages,
    });
  };

  async componentWillMount() {
    const { user, dispatch } = this.props;
    const { role } = user;
    const db = firebase.firestore();

    const snap = await db
      .collection("liveStreamPacakges")
      .where("isActive", "==", true)
      .where("isFree", "==", false)
      .orderBy("price")
      .get();

    const liveStreamPackages = [];
    snap.docs.forEach((d) => {
      const p = d.data();

      const { streamHours, buyerProfiles, searchLocations } = p;

      const benefits = [
        `${streamHours} Live Stream Hours`,
        `${searchLocations} Primary Search Locations`,
        `${buyerProfiles} Profiles`,
      ];

      const pPackage = {
        id: d.id,
        ...p,
        benefits: [...benefits, ...p.benefits],
      };
      liveStreamPackages.push(pPackage);
    });

    dispatch({
      type: actions.SET_LIVE_STREAM_PACKAGES,
      payload: liveStreamPackages,
    });

    let data = await db
      .collection("packages")
      .doc(role || "user")
      .get();

    if (!data.exists) {
      return this.dispatchPackages([]);
    }

    data = data.data();

    this.dispatchPackages(data.packages);

    db.collection("subscriptions")
      .doc(user.uid)
      .onSnapshot((snap) => {
        const data = snap.data();
        dispatch({
          type: actions.SET_EXPIRY,
          payload: data ? data.expiry : 0,
        });
      });
  }

  renderUndreamMessagesCount = () => {
    const { unreadMessages } = this.props;
    if (unreadMessages > 0) {
      return (
        <Badge
          pill
          color="danger"
          style={{
            position: "inherit",
            backgroundColor: "#dc3545",
            height: "auto",
            width: "auto",
          }}
        >
          {unreadMessages}
        </Badge>
      );
    }
  };

  renderExpiry = () => {
    const { packages, expiry } = this.props;
    if (packages === undefined || expiry === undefined) {
      return;
    }

    const filteredPackages = (packages || []).filter((p) => p.isActive);

    if (filteredPackages.length === 0) {
      return;
    }

    const now = moment.now();
    var dateString = moment.unix(expiry);

    let days = dateString.diff(now, "days");

    if (days <= 0) {
      days = 0;
      const className = days === 0 ? " btn-pink-color" : "success";
      const jsx =
        days !== 0 ? (
          <React.Fragment>
            <p>Subscription expires in </p>
            <h4>{days} days</h4>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>Subscription Expired</p>
            <h4>Click Here</h4>
          </React.Fragment>
        );

      return (
        <div className={`ui-item bg-${className}`}>
          <Link to="/subscriptions">
            <div className="left">{jsx}</div>
            <div className="right">
              <i className="fa fa-users" />
            </div>
          </Link>
        </div>
      );
    }

    const className = days === 0 ? " btn-pink-color" : "success";
    const jsx =
      days !== 0 ? (
        <React.Fragment>
          <p>Subscription expires in </p>
          <h4>{days} days</h4>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>Subscription Expired</p>
          <h4>Click Here</h4>
        </React.Fragment>
      );

    return (
      <div className={`ui-item bg-${className}`}>
        <Link to="/subscriptions">
          <div className="left">{jsx}</div>
          <div className="right">
            <i className="fa fa-users" />
          </div>
        </Link>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="dashboard-nav d-none d-xl-block d-lg-block">
          <div className="dashboard-inner">{this.contents()}</div>
        </div>
      </React.Fragment>
    );
  }

  contents() {
    const { user } = this.props;
    const { profileComplete, role } = user;
    const { subMenuShow } = this.state;

    if (!user) {
      return null;
    }

    if (profileComplete) {
      return (
        <div
          style={
            {
              // width: '360px',
            }
          }
          className={"leftIcons"}
        >
          {sidebarPath(role, this.props)?.map((sidebar, i) => (
            <React.Fragment>
              <h4>{sidebar?.title}</h4>
              <ul>
                {sidebar?.subAccounts?.map(
                  (bar, i) =>
                    !bar?.visible && (
                      <li
                        className="sidebarList"
                        id={`sidebar-${bar?.path?.slice(1)}`}
                      >
                        {bar?.name !== "My Account" ? (
                          <NavLink
                            activeClassName="activeNav"
                            onClick={bar?.logOut}
                            to={bar?.path}
                            style={{
                              fontSize: "18px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={bar?.img}
                              style={{ marginRight: "8px" }}
                            />
                            {bar?.name}
                          </NavLink>
                        ) : (
                          <>
                            <a
                              // activeClassName="activeNav"
                              onClick={() => {
                                this.setState({ subMenuShow: !subMenuShow });
                              }}
                              style={{
                                fontSize: "18px",
                                display: "flex",
                                alignItems: "center",
                                color: "#c5c5c5",
                              }}
                            >
                              <img
                                src={bar?.img}
                                style={{ marginRight: "8px" }}
                              />
                              {bar?.name}
                            </a>
                            {subMenuShow && (
                              <ul className="ml-4">
                                <li>
                                  <NavLink
                                    activeClassName="activeNav"
                                    onClick={bar?.logOut}
                                    to={"/account"}
                                    style={{
                                      fontSize: "18px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      class="fa fa-user"
                                      aria-hidden="true"
                                      style={{ marginRight: "8px" }}
                                    ></i>
                                    Profile
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    activeClassName="activeNav"
                                    onClick={bar?.logOut}
                                    to={"/my-subscriptions"}
                                    style={{
                                      fontSize: "18px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      class="fa fa-credit-card"
                                      aria-hidden="true"
                                      style={{ marginRight: "8px" }}
                                    ></i>
                                    Subscriptions
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    activeClassName="activeNav"
                                    onClick={bar?.logOut}
                                    to={"/my-analytics"}
                                    style={{
                                      fontSize: "18px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      class="fa fa-money"
                                      aria-hidden="true"
                                      style={{ marginRight: "8px" }}
                                    ></i>
                                    Sales Analytics
                                  </NavLink>
                                </li>
                              </ul>
                            )}
                          </>
                        )}
                      </li>
                    )
                )}
              </ul>
              {this.renderExpiry()}
            </React.Fragment>
          ))}
        </div>
      );
    }

    return (
      <React.Fragment>
        <h4>Account</h4>
        <ul>
          <li className="active">
            <Link to="/account">
              <i className="flaticon-user" />
              Complete Your Profile
            </Link>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ PaymentReducer }) => {
  return {
    ...PaymentReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNav);
