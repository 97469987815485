import React, { Component } from "react";
import ListArrowUp from "assets/icons/list-arrow-up.svg";
import ArrowDownBlack from "assets/icons/down-arrow.svg";
import ListArrowDown from "assets/icons/list-arrow-down.svg";
import Replay from "assets/icons/replay_arrow.svg";
import ReplayWhite from "assets/icons/replay_arrow_white.svg";
import Tick from "assets/icons/tick.svg";
import Cross from "assets/icons/cross.svg";
import OtherAgentOffer from "./OtherAgentOffer";
import AgentInformationModal from "./AgentInformationModal";
import ArrowDown from "assets/icons/arrowDownBlack.svg";
import SearchIcon from "assets/icons/search-icon.png";
import { stateCodeMap, stateMapping } from "./info";
import { get } from "underscore";
import firebase from "firebase/app";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { toast } from "react-toastify";
import CreateGroup from "components/Network/CreateGroup";
import threedots from "../../assets/icons/three-dots.png";

class OffersTable extends Component {
  isFirstRender = true;

  state = {
    offers: this.props.offers,
    // globalAgents: this.props.globalAgents ? this.props.globalAgents : [],
    offersToShare: [],
    groups: [],
    shareOffersModel: false,
    statesFilterArr: [],
    showAgentsNamesFilter: false,
    agentNamesOptions: [],
    agentNamesFilterArr: [],
    agentNamesFilterOptions: [],
    filteredStates: stateCodeMap,
    showStatesFilter: false,
    showCitiesFilter: false,
    showGlobalAgentFilter: false,
    cities: [],
    filteredCities: [],
    citiesFilterArr: [],
    filteredOffers: [],
    propertyFilterArr: [],
    propertyFilter: null,
    showPropertiesFilterList: false,
    showPricesFilterList: false,
    priceFilter: "",
    priceFilterArr: [],
    offerSellers: {},
    filteredSellers: [],
    showGlobal: false,
    showLocal: false,
    showBoth: true,
    priceOptions: [
      { value: "0-50000", label: "Less than $50,000" },
      { value: "50000-100000", label: "$50,000 - $150,000" },
      { value: "100000-150000", label: "$100,000 - $150,000" },
      { value: "150000-200000", label: "$150,000 - $200,000" },
      { value: "200000-250000", label: "$200,000 - $250,000" },
      { value: "250000-300000", label: "$250,000 - $300,000" },
      { value: "300000-350000", label: "$300,000 - $350,000" },
      { value: "350000-400000", label: "$350,000 - $400,000" },
      { value: "400000-450000", label: "$400,000 - $450,000" },
      { value: "450000-500000", label: "$450,000 - $500,000" },
      { value: "500000-100000000000000", label: "More than $500,000" },
    ],
    globalAgentFilterOptions: ["Global Agents", "My Agents"],
    globalAgentFilter: [],
    financeTypeFilter: "",
    showFinanceTypeFilterList: false,
    financeTypeFilterArr: [],
    financingTypeOptions: [
      { value: "Cash", label: "Cash" },
      { value: "Lease", label: "Lease" },
      { value: "Conventional", label: "Conventional" },
      { value: "FHA", label: "FHA" },
      { value: "VA", label: "VA" },
      { value: "Seller Finance", label: "Seller Finance" },
    ],
    statusFilter: "",
    statusFilterArr: [],
    showStatusFilterList: false,
    statusOptions: [
      { value: "In Review", label: "In Review" },
      { value: "Accepted", label: "Accepted" },
      { value: "Not Accepted", label: "Not Accepted" },
      { value: "Executed", label: "Executed" },
      { value: "Withdraw", label: "Withdraw" },
      { value: "Terminated", label: "Terminated" },
      { value: "Seller Review", label: "Seller Review" },
      { value: "Countered", label: "Countered" },
    ],
    otherAgentsOffers: false,
    agentInfo: false,
    modelAgent: null,
    statusMeta: {
      "In Review": {
        className: "warning",
        icon: Replay,
      },
      "Seller Review": {
        className: "seller-review",
        icon: ReplayWhite,
      },
      Countered: {
        className: "success",
        icon: ReplayWhite,
      },
      Accepted: {
        className: "success",
        icon: Tick,
      },
      "Not Accepted": {
        className: "danger",
        icon: Cross,
      },
      Deleted: {
        className: "danger",
        icon: Cross,
      },
      Executed: {
        className: "secondary",
        icon: Tick,
      },
      Withdraw: {
        className: "dark",
        icon: Tick,
      },
      Terminated: {
        className: "dark",
        icon: Tick,
      },
    },
    sendEmails: false,
    sendText: false,
    sendingOffers: false,
    messageBody: "",
    subject: "",
    selectedGroup: {},
    myGroupsModal: false,
  };

  toggleMyGroupsModal = () => {
    this.setState({ myGroupsModal: !this.state.myGroupsModal });
  };

  getCities = (state) => {
    if (!state) {
      this.setState({
        cities: [],
        citiesFilterArr: [],
      });
      return;
    }

    const stateCode = stateCodeMap[state];
    const locations = this.props.locations;
    const res = {};
    const url = `https://api.census.gov/data/2019/pep/population?get=NAME&for=place:*&in=state:${stateCode}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        const cities = data
          .slice(1)
          .map((cityData) => cityData[0].split(" ")[0]);
        const locationsData = Object.entries(locations);
        for (let i = 0; i < locationsData.length; i++) {
          const addressParts = locationsData[i][0].split(",");
          const city = addressParts[addressParts.length - 2].trim();
          if (res[city]) {
            res[city] += locationsData[i][1];
          } else {
            res[city] = locationsData[i][1];
          }
        }
        cities.map((city) => {
          if (!res[city]) {
            res[city] = 0;
          }
        });
        const entries = Object.entries(res);
        const merged = [...this.state.cities, ...entries];
        const uniqueEntries = merged.filter(
          (entry, index) =>
            merged.findIndex(([key]) => key === entry[0]) === index
        );

        this.setState({
          cities: uniqueEntries,
          filteredCities: uniqueEntries,
        });
      })
      .catch((err) => console.log(err));
  };

  setOfferAttribute = (id, key, value) => {
    const { offers } = this.state;
    this.setState({
      offers: offers.map((offer) => {
        if (offer.id === id) {
          return {
            ...offer,
            [key]: value,
          };
        }
        return offer;
      }),
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { statesFilterArr } = this.state;

    // if (prevProps.globalAgents !== this.props.globalAgents) {
    //   this.setState({
    //     globalAgents: this.props.globalAgents,
    //   });
    // }

    if (prevState.statesFilterArr !== statesFilterArr) {
      this.getCities(statesFilterArr[0]);
    }
  }

  componentDidMount() {
    const db = firebase.firestore();
    let groupsData = [];
    db.collection("users")
      .doc(this.props.user.uid)
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data.hasOwnProperty("myGlobalAgents")) {
          this.setState({
            agentNamesFilterOptions: data.myGlobalAgents.map(
              (agent) => agent.agentName
            ),
            agentNamesFilterArr: data.myGlobalAgents.map(
              (agent) => agent.agentName
            ),
          });
        }
      });

    db.collection("network")
      .doc(this.props.user?.uid)
      .collection("buyerAgentGroups")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = {
            id: doc.id,
            ...doc.data(),
          };
          groupsData.push(data);
        });
      });
    let filtered = {};
    const { offers } = this.state;
    offers.forEach((offer) => {
      filtered[offer.seller] = offer;
    });
    this.setState({
      filteredSellers: filtered,
      propertyFilterArr: [Object.keys(filtered)[0]],
      groups: groupsData,
    });
  }

  agentTypeFilterFun = (filter) => {
    const { globalAgentFilter } = this.state;
    if (filter.length > 0) {
      if (filter.includes("Global Agents") && filter.includes("My Agents")) {
        this.setState({
          showGlobal: true,
          showLocal: true,
          showBoth: true,
        });
      } else if (filter.includes("Global Agents")) {
        this.setState({
          showGlobal: true,
          showLocal: false,
          showBoth: false,
        });
      } else if (filter.includes("My Agents")) {
        this.setState({
          showGlobal: false,
          showBoth: false,
          showLocal: true,
        });
      }
    } else {
      this.setState({
        showBoth: true,
        showGlobal: false,
        showLocal: true,
      });
    }
  };

  handleAddressChangeFilter = (e) => {
    const { offers } = this.state;
    const { value } = e.target;
    let filtered = {};

    if (value === "") {
      offers.forEach((offer) => {
        filtered[offer.seller] = offer;
      });
      this.setState({
        filteredSellers: filtered,
      });
      return;
    }

    offers.forEach((offer) => {
      if (offer.address.toLowerCase().includes(value.toLowerCase())) {
        filtered[offer.seller] = offer;
      }
    });
    this.setState({
      filteredSellers: filtered,
    });
  };
  handleStateChangeFilter = (e) => {
    const { value } = e.target;
    let filtered = {};

    if (value === "") {
      Object.keys(stateCodeMap).forEach((state) => {
        filtered[state] = stateCodeMap[state];
      });
      this.setState({
        filteredStates: filtered,
      });
      return;
    }

    Object.keys(stateCodeMap).forEach((state) => {
      if (state.toLowerCase().includes(value.toLowerCase())) {
        filtered[state] = stateCodeMap[state];
      }
    });
    this.setState({
      filteredStates: filtered,
    });
  };
  handleCityChangeFilter = (e) => {
    const { value } = e.target;
    const { cities } = this.state;
    let filtered = [];

    // filter cities and cities are array of arrays
    if (value === "") {
      this.setState({
        filteredCities: cities,
      });
      return;
    }

    cities.forEach((city) => {
      if (city[0].toLowerCase().includes(value.toLowerCase())) {
        filtered.push(city);
      }
    });

    this.setState({
      filteredCities: filtered,
    });
  };

  handleShareOffers = async () => {
    const { sendEmails, sendText, offersToShare, subject, messageBody } =
      this.state;
    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
    const sendSMS = firebase.functions().httpsCallable("sendSMS");
    const filter =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({
      sendingOffers: true,
    });
    if (!offersToShare.length) {
      this.setState({
        sendingOffers: false,
      });
      toast.error("Select Agents to send.");
      return;
    }
    if (sendEmails) {
      for (const offer of offersToShare) {
        if (filter.test(offer.buyerEmail)) {
          await sendEmail({
            subject: subject,
            email: offer.buyerEmail,
            body: messageBody,
          });
        } else {
          alert("Please enter correct email address.");
        }
      }
    }
    if (sendText) {
      for (const offer of offersToShare) {
        await sendSMS({
          to: `+1${offer.phoneNumber}`,
          body: messageBody,
        });
      }
    }
    this.setState({
      sendingOffers: false,
    });
    if (sendText || sendEmails) {
      toast.success("Your message has been sent successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    this.setState({
      offersToShare: [],
    });
    this.setState({ shareOffersModel: false });
  };

  handleGroupChange = (e) => {
    const { value } = e.target;
    const { groups, offers } = this.state;
    const { globalAgents } = this.props;
    this.setState({
      selectedGroup: groups.find((g) => g.id === e.target.value),
      offersToShare: [...offers, ...globalAgents].filter((offer) => {
        return groups.some((group) => {
          return group.agents.some((agent) => agent.id === offer.id);
        });
      }),
    });
  };

  handleGroupChangeModal = (e) => {
    const { value } = e.target;
    const { groups, offers } = this.state;
    const { globalAgents } = this.props;
    this.setState({
      selectedGroup: groups.find((g) => g.id === e.target.value),
    });
  };

  render() {
    const {
      priceFilter,
      financeTypeFilter,
      statusFilter,
      statusMeta,
      priceOptions,
      financingTypeOptions,
      statusOptions,
      offers,
      showLocal,
      showBoth,
      showGlobal,
      offersToShare,
      shareOffersModel,
      groups,
      selectedGroup,
    } = this.state;

    let { propertyFilter } = this.state;
    const offerSellers = {};
    offers.forEach((offer) => {
      offerSellers[offer.seller] = offer;
    });

    if (this.isFirstRender) {
      if ((offers || []).length !== 0) {
        propertyFilter = offers[0].seller;
        this.isFirstRender = false;
        this.setState({
          // propertyFilter,
          // propertyFilterArr: [propertyFilter],
          filteredOffers: offerSellers,
        });
      }
    }

    const propertyFilterFun = (offer) => {
      const { propertyFilterArr } = this.state;
      if (propertyFilterArr.length > 0) {
        return propertyFilterArr.includes(offer.seller);
      }
      return true;
    };

    const priceFilterFun = (offer) => {
      const { priceFilterArr } = this.state;
      if (priceFilterArr.length > 0) {
        const nums = priceFilterArr.map((num) => parseInt(num.split("-")[0]));
        const min = Math.min(...nums);
        const max = Math.max(...nums);
        return offer.purchasePrice >= min && offer.purchasePrice <= max;
      }
      return true;
    };

    const financeTypeFilterFun = (offer) => {
      const { financeTypeFilterArr } = this.state;
      if (financeTypeFilterArr.length > 0) {
        return financeTypeFilterArr.includes(offer.financingType);
      }
      return true;
    };

    const statusTypeFilterFun = (offer) => {
      const { statusFilterArr } = this.state;
      if (statusFilterArr.length > 0) {
        if (statusFilterArr.includes("Executed")) {
          return offer.status === "Executed" || offer.status === "Accepted";
        } else if (statusFilterArr.includes("Accepted")) {
          return offer.status === "Executed" || offer.status === "Accepted";
        }
        return statusFilterArr.includes(offer.status);
      }
      return true;
    };

    const stateFilterFun = (offer) => {
      const { statesFilterArr } = this.state;
      if (statesFilterArr.length > 0) {
        const addressParts = offer.address.split(",");
        const state = addressParts[addressParts.length - 1].trim().slice(0, 2);
        return statesFilterArr.includes(stateMapping[state]);
      }
      return true;
    };

    const citiyFilterFun = (offer) => {
      const { citiesFilterArr } = this.state;
      if (citiesFilterArr.length > 0) {
        const addressParts = offer.address.split(",");
        const city = addressParts[addressParts.length - 2].trim();
        return citiesFilterArr.includes(city);
      }
      return true;
    };

    const agentNamesFilterFun = (offer) => {
      const { agentNamesFilterArr } = this.state;
      if (agentNamesFilterArr.length > 0) {
        return agentNamesFilterArr.includes(offer.agentName);
      }
      return true;
    };

    const options = Object.keys(offerSellers).map((seller) => ({
      value: seller,
      label: offerSellers[seller].address,
    }));

    let filteredOffers = offers
      .filter(propertyFilterFun)
      .filter(priceFilterFun)
      .filter(financeTypeFilterFun)
      .filter(statusTypeFilterFun)
      .filter(stateFilterFun)
      .filter(citiyFilterFun);

    let filteredGlobalAgents = this.props.globalAgents
      ?.filter(propertyFilterFun)
      .filter(priceFilterFun)
      .filter(financeTypeFilterFun)
      .filter(statusTypeFilterFun)
      .filter(stateFilterFun)
      .filter(citiyFilterFun)
      .filter(agentNamesFilterFun);

    return (
      <>
        <div className="all-offers">
          <div className="find-buyer-agents-filters">
            <p className="chart-title d-inline">Find Buyers’ Agent</p>
            <div className="d-inline float-right mb-4">
              <CreateGroup
                comingFrom="analytics"
                agents={[...offers, ...this.props.globalAgents]}
                selectedAgentsToCreate={offersToShare}
                {...this.props}
              />
              {groups.length > 0 && (
                <Button
                  className="follow-my-listing-btn mr-2"
                  onClick={() => this.setState({ myGroupsModal: true })}
                >
                  <i className="fa fa-users mr-2" aria-hidden="true"></i>
                  My Groups
                </Button>
              )}
            </div>
            <div
              className="analytics-row w-100 flex-wrap"
              style={
                {
                  // gap: "5%",
                }
              }
            >
              <div>
                <button
                  className="analytics-mobile-filters propertyFilterToggle"
                  type="button"
                  onClick={() =>
                    this.setState({
                      showPropertiesFilterList:
                        !this.state.showPropertiesFilterList,
                      showPricesFilterList: false,
                      showStatusFilterList: false,
                      showFinanceTypeFilterList: false,
                      showStatesFilter: false,
                      showCitiesFilter: false,
                      showGlobalAgentFilter: false,
                      showAgentsNamesFilter: false,
                    })
                  }
                >
                  {this.state.propertyFilterArr.length === 1
                    ? offerSellers[
                        this.state.propertyFilterArr[0]
                      ].address.slice(0, 20) + "..."
                    : this.state.propertyFilterArr.length > 1
                    ? this.state.propertyFilterArr.length +
                      " Properties Selected"
                    : "Select Property"}
                  <img src={ArrowDownBlack} />
                </button>
                {this.state.showPropertiesFilterList && (
                  <div className="custom-dropdownMenu-search z-index">
                    <input
                      type="text"
                      className="analytics-search-input mb-3"
                      placeholder="Search property"
                      onChange={(e) => this.handleAddressChangeFilter(e)}
                      onBlur={(e) => {
                        e.target.value = "";
                        this.handleAddressChangeFilter(e);
                      }}
                    />
                    {Object.keys(this.state.filteredSellers).length ? (
                      Object.keys(this.state.filteredSellers).map(
                        (seller, i) => {
                          const seller_id = seller.id;
                          return (
                            <div
                              className="custom-control custom-checkbox"
                              key={i}
                            >
                              <input
                                className="custom-control-input propertyFilterChkBox"
                                type="checkbox"
                                checked={this.state.propertyFilterArr.includes(
                                  seller
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({
                                    propertyFilterArr: e.target.checked
                                      ? [
                                          ...this.state.propertyFilterArr,
                                          e.target.value,
                                        ]
                                      : this.state.propertyFilterArr.filter(
                                          (val) => val !== e.target.value
                                        ),
                                  });
                                }}
                                id={"propertyFilterChkBox" + i}
                                name={"propertyFilterChkBox" + i}
                                value={seller}
                              />
                              <label
                                className="custom-control-label"
                                for={"propertyFilterChkBox" + i}
                              >
                                {" "}
                                {offerSellers[seller].address}
                              </label>
                            </div>
                            // <button
                            //   className={`analytics-price-dropdown-inner-btn ${
                            //     this.state.propertyFilterArr.includes(seller)
                            //       ? "active-bg"
                            //       : ""
                            //   }`}
                            //   key={i}
                            //   value={seller}
                            //   onClick={(e) => {
                            //     e.stopPropagation();
                            //     this.setState((prevState) => ({
                            //       propertyFilterArr:
                            //         prevState.propertyFilterArr.includes(seller)
                            //           ? prevState.propertyFilterArr.filter(
                            //               (val) => val !== seller
                            //             )
                            //           : [...prevState.propertyFilterArr, seller],
                            //     }));
                            //   }}
                            // >
                            //   <p>{`${offerSellers[seller].address}`}</p>
                            // </button>
                          );
                        }
                      )
                    ) : (
                      <button className="analytics-price-dropdown-inner-btn">
                        <p>No Properties Found</p>
                      </button>
                    )}
                    {/* {Object.keys(offerSellers).map((key, i) => (
                      <div className="custom-control custom-checkbox" key={i}>
                        <input
                          className="custom-control-input propertyFilterChkBox"
                          type="checkbox"
                          checked={this.state.propertyFilterArr.includes(key)}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                              propertyFilterArr: e.target.checked
                                ? [
                                    ...this.state.propertyFilterArr,
                                    e.target.value,
                                  ]
                                : this.state.propertyFilterArr.filter(
                                    (val) => val !== e.target.value
                                  ),
                            });
                          }}
                          id={"propertyFilterChkBox" + i}
                          name={"propertyFilterChkBox" + i}
                          value={key}
                        />
                        <label
                          className="custom-control-label"
                          for={"propertyFilterChkBox" + i}
                        >
                          {" "}
                          {offerSellers[key].address}
                        </label>
                      </div>
                    ))} */}
                  </div>
                )}
              </div>

              <div>
                <button
                  className="analytics-mobile-filters propertyFilterToggle"
                  type="button"
                  onClick={() =>
                    this.setState({
                      showPricesFilterList: !this.state.showPricesFilterList,
                      showPropertiesFilterList: false,
                      showStatusFilterList: false,
                      showFinanceTypeFilterList: false,
                      showStatesFilter: false,
                      showCitiesFilter: false,
                      showGlobalAgentFilter: false,
                      showAgentsNamesFilter: false,
                    })
                  }
                >
                  {this.state.priceFilterArr.length === 1
                    ? this.state.priceFilterArr[0]
                    : this.state.priceFilterArr.length > 1
                    ? this.state.priceFilterArr.length + " Prices Selected"
                    : "Select Price"}
                  <img src={ArrowDownBlack} />
                </button>
                {this.state.showPricesFilterList && (
                  <div className="custom-dropdownMenu z-index">
                    {priceOptions.map((m, i) => (
                      <div className="custom-control custom-checkbox" key={i}>
                        <input
                          className="custom-control-input propertyFilterChkBox"
                          type="checkbox"
                          checked={this.state.priceFilterArr.includes(m.value)}
                          onClick={(e) => {
                            this.setState({
                              priceFilterArr: e.target.checked
                                ? [...this.state.priceFilterArr, e.target.value]
                                : this.state.priceFilterArr.filter(
                                    (val) => val !== e.target.value
                                  ),
                            });
                          }}
                          id={"priceFilterChkBox" + i}
                          name={"priceFilterChkBox" + i}
                          value={m.value}
                        />
                        <label
                          className="custom-control-label"
                          for={"priceFilterChkBox" + i}
                        >
                          {" "}
                          {m.label}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div>
                <button
                  className="analytics-mobile-filters propertyFilterToggle"
                  type="button"
                  onClick={() =>
                    this.setState({
                      showFinanceTypeFilterList:
                        !this.state.showFinanceTypeFilterList,
                      showPropertiesFilterList: false,
                      showPricesFilterList: false,
                      showStatusFilterList: false,
                      showStatesFilter: false,
                      showCitiesFilter: false,
                      showGlobalAgentFilter: false,
                      showAgentsNamesFilter: false,
                    })
                  }
                >
                  {this.state.financeTypeFilterArr.length === 1
                    ? this.state.financeTypeFilterArr[0]
                    : this.state.financeTypeFilterArr.length > 1
                    ? this.state.financeTypeFilterArr.length +
                      " Financing Types Selected"
                    : "Select Financing Type"}
                  <img src={ArrowDownBlack} />
                </button>
                {this.state.showFinanceTypeFilterList && (
                  <div className="custom-dropdownMenu z-index">
                    {financingTypeOptions.map((m, i) => (
                      <div className="custom-control custom-checkbox" key={i}>
                        <input
                          className="custom-control-input propertyFilterChkBox"
                          type="checkbox"
                          checked={this.state.financeTypeFilterArr.includes(
                            m.value
                          )}
                          onClick={(e) => {
                            this.setState({
                              financeTypeFilterArr: e.target.checked
                                ? [
                                    ...this.state.financeTypeFilterArr,
                                    e.target.value,
                                  ]
                                : this.state.financeTypeFilterArr.filter(
                                    (val) => val !== e.target.value
                                  ),
                            });
                          }}
                          id={"financeTypeFilterChkBox" + i}
                          name={"financeTypeFilterChkBox" + i}
                          value={m.value}
                        />
                        <label
                          className="custom-control-label"
                          for={"financeTypeFilterChkBox" + i}
                        >
                          {" "}
                          {m.label}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div>
                <button
                  className="analytics-mobile-filters propertyFilterToggle"
                  type="button"
                  onClick={() =>
                    this.setState({
                      showStatusFilterList: !this.state.showStatusFilterList,
                      showPropertiesFilterList: false,
                      showPricesFilterList: false,
                      showFinanceTypeFilterList: false,
                      showStatesFilter: false,
                      showCitiesFilter: false,
                      showGlobalAgentFilter: false,
                      showAgentsNamesFilter: false,
                    })
                  }
                >
                  {this.state.statusFilterArr.length === 1
                    ? this.state.statusFilterArr[0]
                    : this.state.statusFilterArr.length > 1
                    ? this.state.statusFilterArr.length + " Statuses Selected"
                    : "Select Status"}
                  <img src={ArrowDownBlack} />
                </button>
                {this.state.showStatusFilterList && (
                  <div className="custom-dropdownMenu z-index">
                    {statusOptions.map((m, i) => (
                      <div className="custom-control custom-checkbox" key={i}>
                        <input
                          className="custom-control-input propertyFilterChkBox"
                          type="checkbox"
                          checked={this.state.statusFilterArr.includes(m.value)}
                          onClick={(e) => {
                            this.setState({
                              statusFilterArr: e.target.checked
                                ? [
                                    ...this.state.statusFilterArr,
                                    e.target.value,
                                  ]
                                : this.state.statusFilterArr.filter(
                                    (val) => val !== e.target.value
                                  ),
                            });
                          }}
                          id={"statusFilterChkBox" + i}
                          name={"statusFilterChkBox" + i}
                          value={m.value}
                        />
                        <label
                          className="custom-control-label"
                          for={"statusFilterChkBox" + i}
                        >
                          {" "}
                          {m.label}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div>
                <button
                  className="analytics-mobile-filters propertyFilterToggle"
                  type="button"
                  onClick={(e) => {
                    if (this.state.showStatesFilter) {
                      e.target.value = "";
                      this.handleStateChangeFilter(e);
                    }
                    this.setState({
                      showStatesFilter: !this.state.showStatesFilter,
                      showPropertiesFilterList: false,
                      showPricesFilterList: false,
                      showFinanceTypeFilterList: false,
                      showStatusFilterList: false,
                      showCitiesFilter: false,
                      showGlobalAgentFilter: false,
                      showAgentsNamesFilter: false,
                    });
                  }}
                >
                  {this.state.statesFilterArr.length === 1
                    ? this.state.statesFilterArr[0]
                    : this.state.statesFilterArr.length > 1
                    ? this.state.statesFilterArr.length + " States Selected"
                    : "Select State"}
                  <img src={ArrowDownBlack} />
                </button>
                {this.state.showStatesFilter && (
                  <div className="custom-dropdownMenu-search z-index">
                    <input
                      type="text"
                      className="analytics-search-input mb-3"
                      placeholder="Search State"
                      onChange={(e) => this.handleStateChangeFilter(e)}
                      // onBlur={(e) => {
                      //   this.handleStateChangeFilter(e);
                      // }}
                    />
                    {Object.keys(this.state.filteredStates).map((m, i) => (
                      <div className="custom-control custom-checkbox" key={i}>
                        <input
                          className="custom-control-input propertyFilterChkBox"
                          type="checkbox"
                          checked={this.state.statesFilterArr.includes(m)}
                          onClick={(e) => {
                            this.setState({
                              statesFilterArr: e.target.checked
                                ? [
                                    ...this.state.statesFilterArr,
                                    e.target.value,
                                  ]
                                : this.state.statesFilterArr.filter(
                                    (val) => val !== e.target.value
                                  ),
                            });
                          }}
                          id={"statesFilterChkBox" + i}
                          name={"statesFilterChkBox" + i}
                          value={m}
                        />
                        <label
                          className="custom-control-label"
                          for={"statesFilterChkBox" + i}
                        >
                          {" "}
                          {m}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* cities filter */}

              <div>
                <button
                  className="analytics-mobile-filters propertyFilterToggle"
                  type="button"
                  onClick={(e) => {
                    if (this.state.showCitiesFilter) {
                      e.target.value = "";
                      this.handleCityChangeFilter(e);
                    }
                    this.setState({
                      showCitiesFilter: !this.state.showCitiesFilter,
                      showPropertiesFilterList: false,
                      showPricesFilterList: false,
                      showFinanceTypeFilterList: false,
                      showStatusFilterList: false,
                      showStatesFilter: false,
                      showGlobalAgentFilter: false,
                      showAgentsNamesFilter: false,
                    });
                  }}
                >
                  {this.state.citiesFilterArr.length === 1
                    ? this.state.citiesFilterArr[0]
                    : this.state.citiesFilterArr.length > 1
                    ? this.state.citiesFilterArr.length + " Cities Selected"
                    : "Select City"}
                  <img src={ArrowDownBlack} />
                </button>
                {this.state.showCitiesFilter &&
                  (this.state.filteredCities.length ? (
                    <div className="custom-dropdownMenu-search z-index">
                      <input
                        type="text"
                        className="analytics-search-input mb-3"
                        placeholder="Search City"
                        onChange={(e) => this.handleCityChangeFilter(e)}
                      />
                      {this.state.filteredCities
                        .map((el) => el[0])
                        .map((m, i) => (
                          <div
                            className="custom-control custom-checkbox"
                            key={i}
                          >
                            <input
                              className="custom-control-input propertyFilterChkBox"
                              type="checkbox"
                              checked={this.state.citiesFilterArr.includes(m)}
                              onClick={(e) => {
                                this.setState({
                                  citiesFilterArr: e.target.checked
                                    ? [
                                        ...this.state.citiesFilterArr,
                                        e.target.value,
                                      ]
                                    : this.state.citiesFilterArr.filter(
                                        (val) => val !== e.target.value
                                      ),
                                });
                              }}
                              id={"citiesFilterChkBox" + i}
                              name={"citiesFilterChkBox" + i}
                              value={m}
                            />
                            <label
                              className="custom-control-label"
                              for={"citiesFilterChkBox" + i}
                            >
                              {" "}
                              {m}
                            </label>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <button className="analytics-price-dropdown-inner-btn">
                      <p>No Cities Found</p>
                    </button>
                  ))}
              </div>

              {/* global agent filter */}

              {this.state.agentNamesFilterOptions?.length > 0 && (
                <div>
                  <button
                    className="analytics-mobile-filters propertyFilterToggle"
                    type="button"
                    onClick={() =>
                      this.setState({
                        showGlobalAgentFilter:
                          !this.state.showGlobalAgentFilter,
                        showPropertiesFilterList: false,
                        showPricesFilterList: false,
                        showFinanceTypeFilterList: false,
                        showStatesFilter: false,
                        showCitiesFilter: false,
                        showStatusFilterList: false,
                        showAgentsNamesFilter: false,
                      })
                    }
                  >
                    {this.state.globalAgentFilter.length === 1
                      ? this.state.globalAgentFilter[0]
                      : this.state.globalAgentFilter.length > 1
                      ? this.state.globalAgentFilter.length + " Selected"
                      : "Select Agent Type"}
                    <img src={ArrowDownBlack} />
                  </button>
                  {this.state.showGlobalAgentFilter && (
                    <div className="custom-dropdownMenu z-index">
                      {this.state.globalAgentFilterOptions.map((m, i) => (
                        <div className="custom-control custom-checkbox" key={i}>
                          <input
                            className="custom-control-input propertyFilterChkBox"
                            type="checkbox"
                            checked={this.state.globalAgentFilter.includes(m)}
                            onClick={(e) => {
                              this.setState({
                                globalAgentFilter: e.target.checked
                                  ? [
                                      ...this.state.globalAgentFilter,
                                      e.target.value,
                                    ]
                                  : this.state.globalAgentFilter.filter(
                                      (val) => val !== e.target.value
                                    ),
                              });
                              this.agentTypeFilterFun(
                                e.target.checked
                                  ? [
                                      ...this.state.globalAgentFilter,
                                      e.target.value,
                                    ]
                                  : this.state.globalAgentFilter.filter(
                                      (val) => val !== e.target.value
                                    )
                              );
                            }}
                            id={"agentTypeFilter" + i}
                            name={"agentTypeFilter" + i}
                            value={m}
                          />
                          <label
                            className="custom-control-label"
                            for={"agentTypeFilter" + i}
                          >
                            {" "}
                            {m}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {/* global agent names filter */}

              {showGlobal && (
                <div>
                  <button
                    className="analytics-mobile-filters propertyFilterToggle"
                    type="button"
                    onClick={() =>
                      this.setState({
                        showAgentsNamesFilter:
                          !this.state.showAgentsNamesFilter,
                        showGlobalAgentFilter: false,
                        showPropertiesFilterList: false,
                        showPricesFilterList: false,
                        showFinanceTypeFilterList: false,
                        showStatesFilter: false,
                        showCitiesFilter: false,
                        showStatusFilterList: false,
                      })
                    }
                  >
                    {/* {filteredGlobalAgents?.length === 1
                      ? filteredGlobalAgents[0].agentName
                      : filteredGlobalAgents?.length > 1
                      ? filteredGlobalAgents?.length + " Selected"
                      : "Agents"} */}
                    {"Agents"}
                    <img src={ArrowDownBlack} />
                  </button>
                  {this.state.showAgentsNamesFilter && (
                    <div className="custom-dropdownMenu z-index">
                      {this.state.agentNamesFilterOptions.map((m, i) => (
                        <div className="custom-control custom-checkbox" key={i}>
                          <input
                            className="custom-control-input propertyFilterChkBox"
                            type="checkbox"
                            checked={
                              filteredGlobalAgents.filter(
                                (agent) => agent.agentName === m
                              ).length > 0
                            }
                            onClick={(e) => {
                              this.setState({
                                agentNamesFilterArr: e.target.checked
                                  ? [
                                      ...this.state.agentNamesFilterArr,
                                      e.target.value,
                                    ]
                                  : this.state.agentNamesFilterArr.filter(
                                      (val) => val !== e.target.value
                                    ),
                              });
                            }}
                            id={"agentName" + i}
                            name={"agentName" + i}
                            value={m}
                          />
                          <label
                            className="custom-control-label"
                            for={"agentName" + i}
                          >
                            {" "}
                            {m}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {/* <div>
                <button
                  className="analytics-price-dropdown-btn search-bar-offers-table"
                  type="button"
                  id="offerStatusFilter"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="analytics-row center-me">
                    <p className="analytics-property-address-label">
                      {this.state.propertyFilterArr.length
                        ? this.state.propertyFilterArr.length +
                          " Properties Selected"
                        : "Search Property"}
                    </p>
                    <span>
                      <img
                        alt="ArrowDown"
                        src={SearchIcon}
                        className="search-icon-offers-table"
                      />
                    </span>
                  </div>
                </button>
                <div
                  className="custom-address-options-list dropdown-menu"
                  aria-labelledby="listingPriceFilter"
                >
                  <div className="analtics-space-between padd-6">
                    <input
                      type="text"
                      className="analytics-search-input"
                      placeholder="Search property"
                      onChange={(e) => this.handleAddressChangeFilter(e)}
                    />
                  </div>
                  {Object.keys(this.state.filteredSellers).length ? (
                    Object.keys(this.state.filteredSellers).map((seller, i) => {
                      const seller_id = seller.id;
                      return (
                        <button
                          className={`analytics-price-dropdown-inner-btn ${
                            this.state.propertyFilterArr.includes(seller)
                              ? "active-bg"
                              : ""
                          }`}
                          key={i}
                          value={seller}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState((prevState) => ({
                              propertyFilterArr:
                                prevState.propertyFilterArr.includes(seller)
                                  ? prevState.propertyFilterArr.filter(
                                      (val) => val !== seller
                                    )
                                  : [...prevState.propertyFilterArr, seller],
                            }));
                          }}
                        >
                          <p>{`${offerSellers[seller].address}`}</p>
                        </button>
                      );
                    })
                  ) : (
                    <button className="analytics-price-dropdown-inner-btn">
                      <p>No Properties Found</p>
                    </button>
                  )}
                </div>
              </div> */}
              {/* <div className="w-25"> */}
              {/* <div className="d-inline-block">
                  <div> */}
              {/* on remove option */}
              {/* <Select
                  options={options}
                  isMulti={true}
                  value={options.filter((m) =>
                    this.state.propertyFilterArr.includes(m.value)
                  )}
                  onChange={(e) => {
                    e.map((m) => {
                      this.setState({
                        propertyFilterArr: [
                          ...this.state.propertyFilterArr,
                          m.value,
                        ],
                      });
                    });
                  }}
                  placeholder="Search Property"
                /> */}
              {/* <button
                      className="analytics-price-dropdown-btn"
                      type="button"
                      id="offerStatusFilter"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="analytics-row center-me">
                        <p className="analytics-property-address-label">
                          {"Search Property"}
                        </p>
                        <img
                          alt="ArrowDown"
                          src={ArrowDown}
                          className="down-arrow"
                        />
                      </div>
                    </button>
                    <div
                      className="custom-address-options-list dropdown-menu"
                      aria-labelledby="listingPriceFilter"
                    >
                      <div className="analtics-space-between padd-6">
                        <input
                          type="text"
                          className="analytics-search-input"
                          placeholder="Search property"
                          onChange={(e) => this.handleAddressChangeFilter(e)}
                        />
                      </div>
                      {Object.keys(this.state.filteredSellers)?.map(
                        (seller, i) => (
                          <button className="analytics-price-dropdown-inner-btn" key={i}>
                          
                            <p>{this.state.filteredSellers[seller].address}</p>
                          </button>
                        )
                      )}
                    </div> */}
              {/* </div>
                </div> */}
              {/* </div> */}
            </div>
          </div>
          <div className="table-responsive">
            <table className="table" style={{ margin: 0 }}>
              <thead>
                <tr>
                  <th>
                    <div className="custom-control custom-checkbox ml-6">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={"select_all"}
                        onChange={(e) => {
                          if (e.target.checked)
                            this.setState({
                              offersToShare: showLocal
                                ? [...filteredOffers]
                                : showGlobal
                                ? [...filteredGlobalAgents]
                                : [...filteredOffers, ...filteredGlobalAgents],
                            });
                          else
                            this.setState({
                              offersToShare: [],
                            });
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"select_all"}
                      ></label>
                    </div>
                  </th>
                  <th>Agent</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th style={{ width: "15%" }}>Finance Type</th>
                  <th>Price</th>
                  <th>
                    {/* {groups.length || offersToShare.length ? (
                      <Button
                        className="btn btn-theme btn btn-secondary share-btn-dashboard d-inline ml-2"
                        onClick={() =>
                          this.setState({ shareOffersModel: true })
                        }
                      >
                        Send Text/Email
                      </Button>
                    ) : null} */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {showLocal || showBoth ? (
                  filteredOffers.length ? (
                    filteredOffers.map((offer) => {
                      return (
                        <>
                          <tr
                            key={offer.id}
                            className="all-offers-table-row analytics-find-buyer-table"
                          >
                            <td>
                              <div className="custom-control custom-checkbox ml-6">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={offer.id}
                                  checked={this.state.offersToShare.includes(
                                    offer
                                  )}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      this.setState({
                                        offersToShare: [
                                          ...offersToShare,
                                          offer,
                                        ],
                                      });
                                    else
                                      this.setState({
                                        offersToShare: offersToShare.filter(
                                          (o) => o.id !== offer.id
                                        ),
                                      });
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={offer.id}
                                ></label>
                              </div>
                            </td>
                            <AgentInformationModal
                              key={offer.id}
                              offer={offer}
                              user={this.props.user}
                            />
                            <td className="address-content-width">
                              <p className="table-normal-text">
                                {offer.address}
                              </p>
                              <a
                                className="analytics-offer-details-btn"
                                target="_blank"
                                href={`/offers/${offer.id}?curItem=1&totalItems=1`}
                              >
                                Offer details page
                              </a>
                            </td>
                            <td>
                              <span
                                className={`badge badge-${
                                  statusMeta[offer.status].className
                                } status-badge`}
                              >
                                <img
                                  src={statusMeta[offer.status].icon}
                                  alt="prepend icon"
                                />{" "}
                                {offer.status === "Withdraw"
                                  ? "Withdrawn"
                                  : offer.status}
                              </span>
                            </td>
                            <td>{offer.financingType}</td>
                            <td>${offer.purchasePrice}</td>
                            <td>
                              <button
                                className="other-agents-filter"
                                onClick={() =>
                                  this.setOfferAttribute(
                                    offer.id,
                                    "otherAgentsOffers",
                                    !offer.otherAgentsOffers
                                  )
                                }
                              >
                                {offer.otherAgentsOffers
                                  ? "Hide agents offers"
                                  : "Other agents offers"}
                                <img
                                  alt="ListArrowdown"
                                  src={
                                    offer.otherAgentsOffers
                                      ? ListArrowUp
                                      : ListArrowDown
                                  }
                                  className="list-arrow"
                                />
                              </button>
                            </td>
                          </tr>
                          <OtherAgentOffer
                            propertyId={offer.seller}
                            statusMeta={statusMeta}
                            otherAgentsOffers={offer.otherAgentsOffers}
                            licenseNo={offer.licenseNo}
                            email={offer.email}
                          />
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                {showGlobal || showBoth ? (
                  filteredGlobalAgents?.length ? (
                    filteredGlobalAgents?.map((offer) => {
                      return (
                        <>
                          <tr
                            key={offer.id}
                            className="all-offers-table-row analytics-find-buyer-table"
                          >
                            <td>
                              <div className="custom-control custom-checkbox ml-6">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={offer.id}
                                  checked={this.state.offersToShare.includes(
                                    offer
                                  )}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      this.setState({
                                        offersToShare: [
                                          ...offersToShare,
                                          offer,
                                        ],
                                      });
                                    else
                                      this.setState({
                                        offersToShare: offersToShare.filter(
                                          (o) => o.id !== offer.id
                                        ),
                                      });
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={offer.id}
                                ></label>
                              </div>
                            </td>
                            <AgentInformationModal
                              key={offer.id}
                              offer={offer}
                              user={this.props.user}
                              comingFrom="globalOffers"
                              getGlobalAgents={this.props.getGlobalAgents}
                              globalAgents={this.props.globalAgents}
                            />
                            <td className="address-content-width">
                              <p className="table-normal-text">
                                {offer.address}
                              </p>
                              <a
                                className="analytics-offer-details-btn"
                                target="_blank"
                                href={`otherAgent/offer/${offer.id}?curItem=1&totalItems=1`}
                              >
                                Offer details page
                              </a>
                            </td>
                            <td>
                              <span
                                className={`badge badge-${
                                  statusMeta[offer.status].className
                                } status-badge`}
                              >
                                <img
                                  src={statusMeta[offer.status].icon}
                                  alt="prepend icon"
                                />{" "}
                                {offer.status}
                              </span>
                            </td>
                            <td>{offer.financingType}</td>
                            <td>${offer.purchasePrice}</td>
                            <td>
                              {/* <button
                              className="other-agents-filter"
                              onClick={() =>
                                this.setOfferAttribute(
                                  offer.id,
                                  "otherAgentsOffers",
                                  !offer.otherAgentsOffers
                                )
                              }
                            >
                              {offer.otherAgentsOffers
                                ? "Hide agents offers"
                                : "Other agents offers"}
                              <img
                                alt="ListArrowdown"
                                src={
                                  offer.otherAgentsOffers
                                    ? ListArrowUp
                                    : ListArrowDown
                                }
                                className="list-arrow"
                              />
                            </button> */}
                            </td>
                          </tr>
                          {/* <OtherAgentOffer
                          propertyId={offer.seller}
                          statusMeta={statusMeta}
                          otherAgentsOffers={offer.otherAgentsOffers}
                          licenseNo={offer.licenseNo}
                          email={offer.email}
                        /> */}
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </tbody>
            </table>
            {!filteredOffers.length && (
              <p
                style={{
                  textAlign: "center",
                  paddingTop: "100px",
                  paddingBottom: "100px",
                }}
              >
                No offers found
              </p>
            )}
          </div>
        </div>

        {/* Send Text/Email MOdal */}
        <Modal
          isOpen={shareOffersModel}
          toggle={() =>
            this.setState({
              shareOffersModel: !shareOffersModel,
            })
          }
          className="modal-danger"
        >
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <Label>Do you want to send an email or text message?</Label>
            <div className="custom-control custom-checkbox ml-6">
              <input
                type="checkbox"
                className="custom-control-input"
                id="email"
                checked={this.state.sendEmails}
                onChange={(e) => {
                  this.setState({ sendEmails: !this.state.sendEmails });
                }}
              />
              <label className="custom-control-label m-0 p-0" htmlFor={"email"}>
                Email
              </label>
            </div>
            <div className="custom-control custom-checkbox ml-6">
              <input
                type="checkbox"
                className="custom-control-input"
                id="text"
                checked={this.state.sendText}
                onChange={(e) => {
                  this.setState({ sendText: !this.state.sendText });
                }}
              />
              <label className="custom-control-label m-0 p-0" htmlFor={"text"}>
                Text Message
              </label>
            </div>
            <Label>Select Group</Label>
            <Input
              type="select"
              // className=""
              value={selectedGroup.id}
              onChange={(e) => {
                this.handleGroupChange(e);
              }}
            >
              <option value="">Choose...</option>
              {(groups || []).map((group, key) => {
                const { id, name } = group || {};
                return (
                  <option key={key} value={`${id}`}>
                    Group: {name}
                  </option>
                );
              })}
            </Input>

            <Label>Subject</Label>
            <Input
              type="text"
              name="subject"
              className="input mb-4"
              placeholder="Subject"
              value={this.state.subject}
              onChange={(e) => this.setState({ subject: e.target.value })}
            />
            <Label>Message</Label>
            <Input
              type="textarea"
              name="messae"
              className="input mb-4"
              placeholder="Your message"
              value={this.state.messageBody}
              onChange={(e) => this.setState({ messageBody: e.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() =>
                this.setState({
                  shareOffersModel: !shareOffersModel,
                  sendingOffers: false,
                  messageBody: "",
                  sendEmails: false,
                  sendText: false,
                })
              }
            >
              Cancel
            </Button>{" "}
            <Button
              color="success"
              disabled={this.state.sendingOffers}
              onClick={this.handleShareOffers}
            >
              {this.state.sendingOffers ? "Sending..." : "Send"}
            </Button>
          </ModalFooter>
        </Modal>

        {/* My Groups Modal */}

        <Modal
          isOpen={this.state.myGroupsModal}
          toggle={this.toggleMyGroupsModal}
          className="modal-danger"
        >
          {/* <ModalHeader>Confirmation</ModalHeader> */}
          <ModalBody className="pb-5">
            <Label>Select Group</Label>
            <Input
              type="select"
              // className=""
              value={selectedGroup.id}
              onChange={(e) => {
                this.handleGroupChangeModal(e);
              }}
            >
              <option value="default">Choose...</option>
              {(groups || []).map((group, key) => {
                const { id, name } = group || {};
                return (
                  <option key={key} value={`${id}`}>
                    Group: {name}
                  </option>
                );
              })}
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() =>
                this.setState({
                  myGroupsModal: !this.state.myGroupsModal,
                })
              }
            >
              Cancel
            </Button>{" "}
            <CreateGroup
              comingFrom="analytics"
              agents={[...offers, ...this.props.globalAgents]}
              agentsToUpdate={[...offers, ...this.props.globalAgents].filter(
                (agent) => {
                  return selectedGroup?.agents?.some((item) => {
                    return item.id === agent.id;
                  });
                }
              )}
              updateGroupName={selectedGroup?.name}
              updateGroupId={selectedGroup?.id}
              isUpdate={true}
              {...this.props}
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default OffersTable;
