import React, { Component } from "react";
import Highcharts from "highcharts";
import ArrowDown from "assets/icons/list-arrow-down-grey.png";
import { Row, Col, Table } from "reactstrap";
import _ from "lodash";
import { getUsersSellers } from "../../Services/SellerService";
import FullScreenIcon from "assets/icons/full-screen.png";
import {
  Map,
  GoogleApiWrapper,
  Circle,
  Marker,
  InfoWindow,
} from "google-maps-react";
import { firebaseConfig } from "./../../components/Firebase/firebase";
import GoogleAutoComplete from "../../components/Location/GoogleAutoComplete";
import { geohashForLocation } from "geofire-common";
import { Link } from "react-router-dom";

const { apiKey } = firebaseConfig;
class OfferMapLocation extends Component {
  state = {
    advanceFilter: false,
    statesFilter: "TX",
    searchedState: "",
    statesFilterOption: [
      {
        id: 3920,
        name: "Alaska",
        code: "AK",
        country_id: 231,
      },
      {
        id: 3921,
        name: "Arizona",
        code: "AZ",
        country_id: 231,
      },
      {
        id: 3922,
        name: "Arkansas",
        code: "AR",
        country_id: 231,
      },
      {
        id: 3924,
        name: "California",
        code: "CA",
        country_id: 231,
      },
      {
        id: 3926,
        name: "Colorado",
        code: "CO",
        country_id: 231,
      },
      {
        id: 3927,
        name: "Connecticut",
        code: "CT",
        country_id: 231,
      },
      {
        id: 3928,
        name: "Delaware",
        code: "DE",
        country_id: 231,
      },
      {
        id: 3930,
        name: "Florida",
        code: "FL",
        country_id: 231,
      },
      {
        id: 3931,
        name: "Georgia",
        code: "GA",
        country_id: 231,
      },
      {
        id: 3932,
        name: "Hawaii",
        code: "HI",
        country_id: 231,
      },
      {
        id: 3933,
        name: "Idaho",
        code: "ID",
        country_id: 231,
      },
      {
        id: 3934,
        name: "Illinois",
        code: "IL",
        country_id: 231,
      },
      {
        id: 3935,
        name: "Indiana",
        code: "IN",
        country_id: 231,
      },
      {
        id: 3936,
        name: "Iowa",
        code: "IA",
        country_id: 231,
      },
      {
        id: 3937,
        name: "Kansas",
        code: "KS",
        country_id: 231,
      },
      {
        id: 3938,
        name: "Kentucky",
        code: "KY",
        country_id: 231,
      },
      {
        id: 3939,
        name: "Louisiana",
        code: "LA",
        country_id: 231,
      },
      {
        id: 3941,
        name: "Maine",
        code: "ME",
        country_id: 231,
      },
      {
        id: 3942,
        name: "Maryland",
        code: "MD",
        country_id: 231,
      },
      {
        id: 3943,
        name: "Massachusetts",
        code: "MA",
        country_id: 231,
      },
      {
        id: 3945,
        name: "Michigan",
        code: "MI",
        country_id: 231,
      },
      {
        id: 3946,
        name: "Minnesota",
        code: "MN",
        country_id: 231,
      },
      {
        id: 3947,
        name: "Mississippi",
        code: "MS",
        country_id: 231,
      },
      {
        id: 3948,
        name: "Missouri",
        code: "MO",
        country_id: 231,
      },
      {
        id: 3949,
        name: "Montana",
        code: "MT",
        country_id: 231,
      },
      {
        id: 3950,
        name: "Nebraska",
        code: "NE",
        country_id: 231,
      },
      {
        id: 3951,
        name: "Nevada",
        code: "NV",
        country_id: 231,
      },
      {
        id: 3952,
        name: "New Hampshire",
        code: "NH",
        country_id: 231,
      },
      {
        id: 3953,
        name: "New Jersey",
        code: "NJ",
        country_id: 231,
      },
      {
        id: 3955,
        name: "New Mexico",
        code: "NM",
        country_id: 231,
      },
      {
        id: 3956,
        name: "New York",
        code: "NY",
        country_id: 231,
      },
      {
        id: 3957,
        name: "North Carolina",
        code: "NC",
        country_id: 231,
      },
      {
        id: 3958,
        name: "North Dakota",
        code: "ND",
        country_id: 231,
      },
      {
        id: 3959,
        name: "Ohio",
        code: "OH",
        country_id: 231,
      },
      {
        id: 3960,
        name: "Oklahoma",
        code: "OK",
        country_id: 231,
      },
      {
        id: 3962,
        name: "Oregon",
        code: "OR",
        country_id: 231,
      },
      {
        id: 3963,
        name: "Pennsylvania",
        code: "PA",
        country_id: 231,
      },
      {
        id: 3965,
        name: "Rhode Island",
        code: "RI",
        country_id: 231,
      },
      {
        id: 3966,
        name: "South Carolina",
        code: "SC",
        country_id: 231,
      },
      {
        id: 3967,
        name: "South Dakota",
        code: "SD",
        country_id: 231,
      },
      {
        id: 3969,
        name: "Tennessee",
        code: "TN",
        country_id: 231,
      },
      {
        id: 3970,
        name: "Texas",
        code: "TX",
        country_id: 231,
      },
      {
        id: 3972,
        name: "Utah",
        code: "UT",
        country_id: 231,
      },
      {
        id: 3973,
        name: "Vermont",
        code: "VT",
        country_id: 231,
      },
      {
        id: 3974,
        name: "Virginia",
        code: "VA",
        country_id: 231,
      },
      {
        id: 3975,
        name: "Washington",
        code: "WA",
        country_id: 231,
      },
      {
        id: 3976,
        name: "West Virginia",
        code: "WV",
        country_id: 231,
      },
      {
        id: 3977,
        name: "Wisconsin",
        code: "WI",
        country_id: 231,
      },
      {
        id: 3978,
        name: "Wyoming",
        code: "WY",
        country_id: 231,
      },
    ],
    city: "",
    zip: 0,
    radius: 0,
    subdivision: [],
    bedroomFilter: "",
    priceMin: "",
    priceMax: "",
    bedMin: "",
    bedMax: "",
    bathMin: "",
    bathMax: "",
    squareFootageMin: "",
    squareFootageMax: "",
    priceFilter: "",
    subdivisionFilter: "",
    financeTypeFilter: "Select Finance Type",
    financeTypes: [
      { value: "Cash", label: "Cash" },
      { value: "Lease", label: "Lease" },
      { value: "Conventional", label: "Conventional" },
      { value: "FHA", label: "FHA" },
      { value: "VA", label: "VA" },
      { value: "Seller Finance", label: "Seller Finance" },
    ],
    listingsFilter: "",
    listingsFilterOptions: [
      "With Offers",
      "Without Offers",
      "With & Without Offers",
    ],
    priceFilterOptions: [
      { min: 0, max: 100000 },
      { min: 100001, max: 200000 },
      { min: 200001, max: 300000 },
      { min: 300001, max: 400000 },
      { min: 400001, max: 500000 },
      { min: 500001, max: 600000 },
      { min: 600001, max: 700000 },
      { min: 700001, max: 800000 },
      { min: 800001, max: 900000 },
      { min: 900001, max: 1000000 },
    ],
    bedroomFilterOptions: [2, 4],
    bathsFilter: "",
    bathsFilterOptions: [2, 4],
    squareFootageFilter: "",
    squareFootageFilterOptions: [
      250, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000,
    ],
    hideProperties: false,
    showDrawContainer: false,
    activeMarker: null,
    selectedLocation: null,
    listings: [],
    selectedPlace: {},
  };

  componentDidMount() {
    this.getSellers();
  }

  getSellers = () => {
    getUsersSellers(this.props.user, {
      allData: this.props.allData ? "all" : false,
      location: this.state.selectedLocation,
      radius: this.state.radiusFilter,
    }).then(async (localSellers) => {
      this.setState({
        listings: localSellers,
      });
      for (const seller of localSellers) {
        const data = seller.data();

        // add unique data.subdivision to state array
        if (data.subdivision) {
          const subdivision = data.subdivision;
          if (!this.state.subdivision.includes(subdivision)) {
            this.setState({
              subdivision: [...this.state.subdivision, subdivision],
            });
          }
        }

        if (!data.Latitude || !data.Longitude || data.geohash) continue;
        const hash = geohashForLocation([
          data.Latitude || 0,
          data.Longitude || 0,
        ]);
        const payload = {
          geohash: hash,
          lat: data.Latitude || 0,
          lng: data.Longitude || 0,
        };
        await seller.ref.update(payload);
      }
    });
  };

  toggleAdvanceFilterSelection = () => {
    const { advanceFilter } = this.state;
    this.setState({
      advanceFilter: !advanceFilter,
    });
  };

  onInputChange = (e) => {
    const {
      target: { name, value },
    } = e;
    this.setState({
      [name]: value,
    });
  };

  onMapStateChange = (st) => {
    this.setState({ statesFilter: st });
  };

  handleFilterState = (e) => {
    const filterdList = this.state.statesFilterOption.filter((elm) =>
      elm.name?.toLowerCase().includes(e.target.value?.toLowerCase())
    );
    this.setState({
      statesFilterOption: [...filterdList],
    });
  };

  render() {
    Highcharts.seriesTypes.line.prototype.getPointSpline =
      Highcharts.seriesTypes.spline.prototype.getPointSpline;
    const { offers: unfilteredOffers } = this.props;
    let {
      statesFilter,
      bedroomFilter,
      bathsFilter,
      squareFootageFilter,
      city,
      subdivision,
      radiusFilter,
      zip,
      priceMin,
      priceMax,
      bedMin,
      bedMax,
      bathMin,
      bathMax,
      squareFootageMin,
      squareFootageMax,
      priceFilter,
      financeTypeFilter,
      hideProperties,
      showDrawContainer,
      priceMaxFilter,
      priceMinFilter,
      listings,
      selectedPlace,
      selectedLocation,
      listingFilter,
      subdivisionFilter,
    } = this.state;
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    listings = listings
      .filter((listing) => {
        const id = listing.id;
        const totalOffers =
          unfilteredOffers.filter((o) => o.seller === id).length || 0;
        if (listingFilter === "With Offers" && totalOffers === 0) return false;
        if (listingFilter === "Without Offers" && totalOffers > 0) return false;
        return true;
      })
      .filter((listing) => {
        listing = listing.data();
        let min = parseInt(priceFilter ? priceFilter.min : priceMinFilter);
        return min ? (listing.current_value || 0) >= min : true;
      })
      .filter((listing) => {
        listing = listing.data();
        let max = parseInt(priceFilter ? priceFilter.max : priceMaxFilter);
        return max ? (listing.current_value || 0) <= max : true;
      })
      .filter((listing) => {
        listing = listing.data();
        let min = parseInt(bedroomFilter ? bedroomFilter : bedMin);
        return min ? (listing.bedrooms || 0) >= min : true;
      })
      .filter((listing) => {
        listing = listing.data();
        let max = parseInt(bedMax);
        return max ? (listing.bedrooms || 0) <= max : true;
      })
      .filter((listing) => {
        listing = listing.data();
        let min = parseInt(bathsFilter ? bathsFilter : bathMin);
        return min ? (listing.bathrooms || 0) >= min : true;
      })
      .filter((listing) => {
        listing = listing.data();
        let max = parseInt(bathMax);
        return max ? (listing.bathrooms || 0) <= max : true;
      })
      .filter((listing) => {
        listing = listing.data();
        let min = parseInt(
          squareFootageFilter ? squareFootageFilter : squareFootageMin
        );
        return min ? (listing.sqft || 0) >= min : true;
      })
      .filter((listing) => {
        listing = listing.data();
        let max = parseInt(squareFootageMax);
        return max ? (listing.sqft || 0) <= max : true;
      })
      .filter((listing) => {
        listing = listing.data();
        if (financeTypeFilter !== "Select Finance Type") {
          return listing.financingType === financeTypeFilter;
        }
        return true;
      })
      .filter((listing) => {
        listing = listing.data();
        if (subdivisionFilter !== "") {
          return listing.subdivision === subdivisionFilter;
        }
        return true;
      });

    const offers = unfilteredOffers
      .filter((listing) => {
        const id = listing.id;
        const totalOffers =
          unfilteredOffers.filter((o) => o.seller === id).length || 0;
        if (listingFilter === "With Offers" && totalOffers === 0) return false;
        if (listingFilter === "Without Offers" && totalOffers > 0) return false;
        return true;
      })
      .filter((offer) => {
        if (statesFilter) {
          return (offer.address || "").includes(statesFilter);
        }
        return true;
      })
      .filter((offer) => {
        if (city) {
          return (offer.address || "").includes(city);
        }
        return true;
      })
      .filter((offer) => {
        if (zip) {
          return (offer.address || "").includes(zip);
        }
        return true;
      })
      .filter((offer) => {
        if (subdivisionFilter) {
          return (offer.address || "").includes(subdivisionFilter);
        }
        return true;
      })
      .filter((offer) => {
        let min = parseInt(priceFilter ? priceFilter.min : priceMinFilter);
        return min ? (offer.actual_price || 0) >= min : true;
      })
      .filter((offer) => {
        let max = parseInt(priceFilter ? priceFilter.max : priceMaxFilter);
        return max ? (offer.actual_price || 0) <= max : true;
      })
      .filter((offer) => {
        let min = parseInt(bedroomFilter ? bedroomFilter : bedMin);
        return min ? (offer.bedrooms || 0) >= min : true;
      })
      .filter((offer) => {
        let max = parseInt(bedMax);
        return max ? (offer.bedrooms || 0) <= max : true;
      })
      .filter((offer) => {
        let min = parseInt(bathsFilter ? bathsFilter : bathMin);
        return min ? (offer.bathrooms || 0) >= min : true;
      })
      .filter((offer) => {
        let max = parseInt(bathMax);
        return max ? (offer.bathrooms || 0) <= max : true;
      })
      .filter((offer) => {
        let min = parseInt(
          squareFootageFilter ? squareFootageFilter : squareFootageMin
        );
        return min ? (offer.sqft || 0) >= min : true;
      })
      .filter((offer) => {
        let max = parseInt(squareFootageMax);
        return max ? (offer.sqft || 0) <= max : true;
      })
      .filter((offer) => {
        if (financeTypeFilter !== "Select Finance Type") {
          return offer.financingType === financeTypeFilter;
        }
        return true;
      });

    //actual_price

    const groupedBySeller = _.groupBy(offers, "seller");
    const numberOfSellers = Object.keys(groupedBySeller).length;
    // const averageOffers = numberOfSellers ? Math.round(offers.length / numberOfSellers) : 0;

    let selectedPrice = "";
    if (priceFilter) {
      selectedPrice = `${currencyFormatter.format(
        priceFilter.min
      )} to ${currencyFormatter.format(priceFilter.max)}`;
    } else if (priceMin || priceMax) {
      selectedPrice = `${currencyFormatter.format(
        priceMin
      )} to ${currencyFormatter.format(priceMax)}`;
    } else {
      selectedPrice = "Select Price";
    }

    let selectedBed = ``;
    if (bedroomFilter) {
      selectedBed = `${bedroomFilter} beds`;
    } else if (bedMin || bedMax) {
      selectedBed = `${bedMin} beds to ${bedMax} beds`;
    } else {
      selectedBed = "Beds";
    }

    let selectedBath = ``;
    if (bathsFilter) {
      selectedBath = `${bathsFilter} baths`;
    } else if (bathMin || bathMax) {
      selectedBath = `${bathMin} baths to ${bathMax} baths`;
    } else {
      selectedBath = "Baths";
    }

    let selectedSqr = ``;
    if (squareFootageFilter) {
      selectedSqr = `${squareFootageFilter} sqr`;
    } else if (squareFootageMin || squareFootageMax) {
      selectedSqr = `${squareFootageMin} sqr to ${squareFootageMax} sqr`;
    } else {
      selectedSqr = "Select square Footage";
    }

    let averageCount = {
      optionFee: 0,
      earnestMoney: 0,
      optionDays: 0,
      financeAmount: 0,
      purchasePrice: 0,
      downPayment: 0,
      sellerConcessions: 0,
      residentialServiceContract: 0,
      numberOfDays: 0,
      leasebackDays: 0,
    };

    let itemsCount = {
      titlePolicyExpense: {},
      financingType: {},
      possession: {},
      saleOfOtherProperty: {},
      rightToTerminate: {},
    };
    const filteredOffers = offers.filter((o) =>
      listings.find((l) => l.id === o.seller)
    );

    filteredOffers.forEach((offer) => {
      Object.keys(averageCount).forEach((key) => {
        averageCount[key] += parseInt(offer[key] || 0);
      });

      Object.keys(itemsCount).forEach((key) => {
        if (itemsCount[key][offer[key]] === undefined)
          itemsCount[key][offer[key]] = 0;
        else itemsCount[key][offer[key]] = itemsCount[key][offer[key]] + 1;
      });
    });

    const itemAverage = {};
    Object.keys(averageCount).forEach((key) => {
      if (filteredOffers.length) {
        // check if it is Nan
        if (isNaN(averageCount[key] / filteredOffers.length)) {
          itemAverage[key] = 0;
        }
        // check if it is a number
        else if (typeof averageCount[key] === "number") {
          itemAverage[key] = Math.round(
            (averageCount[key] / filteredOffers.length) * 100
          );
        }
      } else {
        itemAverage[key] = 0;
      }
    });

    let keyItemValues = {};
    let keyItemIndex = {};
    let keyItemValue = {};

    Object.keys(itemsCount).forEach((key) => {
      keyItemValues[key] = Object.values(itemsCount[key]);
      keyItemIndex[key] = keyItemValues[key].indexOf(
        Math.max(...keyItemValues[key])
      );
      keyItemValue[key] = Object.keys(itemsCount[key])[keyItemIndex[key]];
    });

    return (
      <div className="maps-container">
        <Row>
          <Col xl={12} lg={12} sm={12}>
            <div className="padding-20">
              <div className="row-space-btwn mb-15">
                <p className="font-18-title">Offer Map Location</p>
                <button className="fullscreen-btn">
                  <img src={FullScreenIcon} className="full-screen-icon" />
                </button>
              </div>
              <div className="row">
                <div
                  className="px-2 w-100 mb-10 col-xl-3 col-lg-3 col-sm-6"
                  // style={{ position: "relative", minWidth: 200 }}
                >
                  <GoogleAutoComplete
                    {...this.props}
                    className=""
                    onLocationChange={(e) => {
                      this.setState(
                        {
                          selectedLocation: e,
                        },
                        () => {
                          this.getSellers();
                        }
                      );
                    }}
                    location={this.state.selectedLocation}
                  />
                </div>
                <div className="px-2 w-100 mb-10 col-xl-2 col-lg-3 col-sm-6">
                  <button
                    className="analytics-map-nrml-filter w-100"
                    type="button"
                    id="listingstateFilter"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <p className="analytics-input-label">
                      {this.state.subdivisionFilter
                        ? this.state.subdivisionFilter
                        : "Subdivision"}
                    </p>
                    <img
                      alt="ArrowDown"
                      src={ArrowDown}
                      className="down-arrow"
                    />
                  </button>
                  <div
                    className="nrml-filter-options-list dropdown-menu"
                    aria-labelledby="listingstateFilter"
                  >
                    {subdivision.map((value, index) => (
                      <div
                        className="analytics-price-dropdown-inner-btn"
                        key={index}
                        onClick={() => {
                          this.setState({
                            subdivisionFilter: value,
                          });
                          this.getSellers();
                        }}
                      >
                        <p>{value}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="px-2 w-100 mb-10 col-xl-1 col-lg-3 col-sm-6">
                  <button
                    className="analytics-map-large-filter w-100"
                    type="button"
                    id="listingstateFilter"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <p className="analytics-input-label">
                      {radiusFilter ? radiusFilter : "Radius"}
                    </p>
                    <img
                      alt="ArrowDown"
                      src={ArrowDown}
                      className="down-arrow"
                    />
                  </button>
                  <div
                    className="nrml-filter-options-list dropdown-menu"
                    aria-labelledby="listingstateFilter"
                  >
                    {Array.from(Array(50).keys()).map((value, index) => (
                      <div
                        className="analytics-price-dropdown-inner-btn"
                        key={index}
                        onClick={() => {
                          this.setState({ radiusFilter: value + 1 }, () => {
                            this.getSellers();
                          });
                        }}
                      >
                        <p>{value + 1}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="px-2 w-100 mb-10 col-xl-2 col-lg-3 col-sm-6">
                  <button
                    className="analytics-map-large-filter w-100"
                    type="button"
                    id="listingstateFilter"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <p className="analytics-input-label">
                      {priceMinFilter && priceMaxFilter
                        ? `${priceMinFilter} - ${priceMaxFilter}`
                        : "Price"}
                    </p>
                    <img
                      alt="ArrowDown"
                      src={ArrowDown}
                      className="down-arrow"
                    />
                  </button>
                  <div
                    className="large-filter-options-list dropdown-menu"
                    aria-labelledby="listingstateFilter"
                  >
                    <div className="analtics-space-between padd-6">
                      <input
                        type="text"
                        className="price-inputs"
                        id="bedMin"
                        name="priceMinFilter"
                        defaultValue={priceMinFilter}
                        onChange={(e) =>
                          this.onInputChange(e, "priceMinFilter")
                        }
                        placeholder="Min"
                      />
                      <input
                        type="text"
                        className="price-inputs"
                        id="bedMax"
                        name="priceMaxFilter"
                        defaultValue={priceMaxFilter}
                        onChange={(e) =>
                          this.onInputChange(e, "priceMaxFilter")
                        }
                        placeholder="Max"
                      />
                    </div>
                    {this.state.priceFilterOptions.map((price, index) => (
                      <button
                        onClick={() =>
                          this.setState({
                            priceMinFilter: price.min,
                            priceMaxFilter: price.max,
                          })
                        }
                        key={index}
                        className="analytics-price-dropdown-inner-btn"
                      >
                        <p>{currencyFormatter.format(price.min)}</p>
                        <p>to</p>
                        <p>{currencyFormatter.format(price.max)}</p>
                      </button>
                    ))}
                  </div>
                </div>
                <div className="px-2 w-100 mb-10 col-xl-1 col-lg-3 col-sm-6">
                  <button
                    className="analytics-map-large-filter w-100"
                    type="button"
                    id="listingstateFilter"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <p className="analytics-input-label">
                      {bedMin && bedMax ? `${bedMin} to ${bedMax}` : "Beds"}
                    </p>
                    <img
                      alt="ArrowDown"
                      src={ArrowDown}
                      className="down-arrow"
                    />
                  </button>
                  <div
                    className="large-filter-options-list dropdown-menu"
                    aria-labelledby="listingBedroomsFilter"
                  >
                    <div className="analtics-space-between padd-6">
                      <input
                        type="text"
                        className="price-inputs"
                        id="bedMin"
                        name="bedMin"
                        value={bedMin}
                        onChange={(e) => this.onInputChange(e, "bedMin")}
                        placeholder="Min"
                      />
                      <input
                        type="text"
                        className="price-inputs"
                        id="bedMax"
                        name="bedMax"
                        value={bedMax}
                        onChange={(e) => this.onInputChange(e, "bedMax")}
                        placeholder="Max"
                      />
                    </div>
                    {this.state.bedroomFilterOptions.map((e) => (
                      <div
                        className="analytics-price-dropdown-inner-btn"
                        key={e}
                        onClick={() => {
                          this.setState({
                            bedMin: e,
                            bedMax: e,
                          });
                        }}
                      >
                        <p>{e}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="px-2 w-100 mb-10 col-xl-1 col-lg-3 col-sm-6">
                  <button
                    className="analytics-map-large-filter w-100"
                    type="button"
                    id="listingBathsFilter"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <p className="analytics-input-label">
                      {bathMin && bathMax
                        ? `${bathMin} to ${bathMax}`
                        : "Baths"}
                    </p>
                    <img
                      alt="ArrowDown"
                      src={ArrowDown}
                      className="down-arrow"
                    />
                  </button>
                  <div
                    className="large-filter-options-list dropdown-menu"
                    aria-labelledby="listingBathsFilter"
                  >
                    <div className="analtics-space-between padd-6">
                      <input
                        type="text"
                        className="price-inputs"
                        id="bathMin"
                        name="bathMin"
                        value={bathMin}
                        onChange={(e) => this.onInputChange(e, "bathMin")}
                        placeholder="Min"
                      />
                      <input
                        type="text"
                        className="price-inputs"
                        id="bathMax"
                        name="bathMax"
                        value={bathMax}
                        onChange={(e) => this.onInputChange(e, "bathMax")}
                        placeholder="Max"
                      />
                    </div>
                    {this.state.bathsFilterOptions.map((e) => (
                      <div
                        className="analytics-price-dropdown-inner-btn"
                        key={e}
                        onClick={() => {
                          this.setState({ bathMin: e, bathMax: e });
                        }}
                      >
                        <p>{e}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="px-2 w-100 mb-10 col-xl-2 col-lg-3 col-sm-6">
                  <button
                    className="analytics-map-large-filter w-100"
                    type="button"
                    id="listingAreaFilter"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <p className="analytics-input-label">
                      {squareFootageMin && squareFootageMax
                        ? `${squareFootageMin} to ${squareFootageMax}`
                        : "Select Square Footage"}
                    </p>
                    <img
                      alt="ArrowDown"
                      src={ArrowDown}
                      className="down-arrow"
                    />
                  </button>
                  <div
                    className="large-filter-options-list dropdown-menu"
                    aria-labelledby="listingAreaFilter"
                  >
                    <div className="analtics-space-between padd-6">
                      <input
                        type="text"
                        className="price-inputs"
                        id="squareFootageMin"
                        name="squareFootageMin"
                        value={squareFootageMin}
                        onChange={(e) =>
                          this.onInputChange(e, "squareFootageMin")
                        }
                        placeholder="Min"
                      />
                      <input
                        type="text"
                        className="price-inputs"
                        id="squareFootageMax"
                        name="squareFootageMax"
                        value={squareFootageMax}
                        onChange={(e) =>
                          this.onInputChange(e, "squareFootageMax")
                        }
                        placeholder="Max"
                      />
                    </div>
                    {this.state.squareFootageFilterOptions.map((e) => (
                      <div
                        className="analytics-price-dropdown-inner-btn"
                        key={e}
                        onClick={() => {
                          this.setState({
                            squareFootageMin: e,
                            squareFootageMax: e,
                          });
                        }}
                      >
                        <p>{e}</p>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className='mr-10 mb-10'>
                  <button className='analytics-map-large-filter' type='button' id='listingsFilter' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    <p className='analytics-input-label'>{listingFilter ? listingFilter : 'With & Without Offers'}</p>
                    <img alt='ArrowDown' src={ArrowDown} className='down-arrow' />
                  </button>
                  <div className='large-filter-options-list dropdown-menu' aria-labelledby='listingsFilter'>
                    {this.state.listingsFilterOptions.map((listing, key) => <div
                      className='analytics-price-dropdown-inner-btn' key={key} onClick={() => {
                        this.setState({ listingFilter: listing }, () => {
                          this.getSellers();
                        });
                      }}>
                      <p>{listing}</p>
                    </div>)}
                  </div>
                </div> */}
              </div>
            </div>
          </Col>
          <Col
            xl={hideProperties ? 12 : 8}
            lg={12}
            md={12}
            sm={12}
            className="ease-in-n-out"
          >
            <div style={{ height: 600 }}>
              <Map
                className="analytics-google-map"
                style={{ width: hideProperties ? "97.5%" : "100%" }}
                google={this.props.google}
                zoom={11}
                initialCenter={{
                  lat: 32.7766642,
                  lng: -96.7969879,
                }}
                center={
                  selectedLocation
                    ? {
                        lat: selectedLocation?.lat,
                        lng: selectedLocation?.lng,
                      }
                    : {
                        lat: 32.7766642,
                        lng: -96.7969879,
                      }
                }
              >
                {listings.map((listing) => {
                  const id = listing.id;
                  {
                    /* if (!offers || !offers.length)  */
                  }
                  listing = listing.data();

                  const totalOffers = unfilteredOffers.filter(
                    (o) => o.seller === id
                  ).length;

                  if (listing.lat && listing.lng) {
                    return (
                      <Marker
                        key={id}
                        title={listing.UnparsedAddress}
                        onClick={(props, marker) =>
                          this.setState({
                            selectedPlace: {
                              id,
                              // totalOffersData: unfilteredOffers?.filter(o => o.seller === id),
                              totalOffers,
                              ...listing,
                            },
                            activeMarker: marker,
                            showingInfoWindow: true,
                          })
                        }
                        position={{
                          lat: listing.lat || 0,
                          lng: listing.lng || 0,
                        }}
                        icon={{
                          url: "https://i.ibb.co/zJLcm52/mark-icon.png",
                          anchor: new window.google.maps.Point(12, 12),
                          scaledSize: new window.google.maps.Size(24, 24),
                        }}
                      ></Marker>
                    );
                  }
                })}
                <InfoWindow
                  marker={this.state.activeMarker}
                  visible={this.state.showingInfoWindow}
                >
                  <div
                    className="analytics-single-property"
                  >
                    <img
                      className="analytics-property-img"
                      style={{
                        filter: "none",
                      }}
                      src={
                        selectedPlace.featured_image?.url
                          ? selectedPlace.featured_image?.url
                          : require("../../assets/icons/gallery/Pict.png")
                      }
                      alt="Home image"
                    />
                    <div className="analytics-property-inner-container">
                      <p className="property-txt">
                        {selectedPlace.UnparsedAddress}
                      </p>
                      <p className="property-purchase-price">
                        {parseInt(
                          selectedPlace.expected_sales_price ||
                            0 ||
                            selectedPlace.projectedPrice ||
                            selectedPlace.current_value ||
                            selectedPlace.selectedPlace_price
                        )
                          ? "$" +
                            parseInt(
                              selectedPlace.expected_sales_price ||
                                0 ||
                                selectedPlace.projectedPrice ||
                                selectedPlace.current_value ||
                                selectedPlace.selectedPlace_price
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "N/A"}
                      </p>
                      <div
                        className="flex-row mt-20"
                        style={{ alignItems: "center" }}
                      >
                        {selectedPlace.totalOffers ? (
                          <a
                            className="total-offers-on-property"
                            href={`/offers?offerAddressFilter=${selectedPlace.street_number} ${selectedPlace.street_name}, ${selectedPlace.city}, ${selectedPlace.state} ${selectedPlace.zip_code}`}
                            style={{ color: "black", border: 0 }}
                          >
                            <img
                              src={require("../../assets/icons/i-black.png")}
                            />
                            <p>{selectedPlace.totalOffers || 0} offers</p>
                          </a>
                        ) : (
                          <button
                            className="total-offers-on-property"
                            type="button"
                            style={{ color: "black", border: 0 }}
                          >
                            <img
                              src={require("../../assets/icons/i-black.png")}
                            />
                            <p>{selectedPlace.totalOffers || 0} offers</p>
                          </button>
                        )}
                        <span className="badge review-badge custom-badge-padding">
                          <i className="fa fa-repeat"></i>
                          {selectedPlace.MlsStatus}
                        </span>
                      </div>
                      <div className="wrapped-flex-row">
                        {selectedPlace.bedrooms ? (
                          <div className="property-main-features">
                            <img
                              src={require("../../assets/icons/bedrooms.svg")}
                            />
                            <p>{selectedPlace.bedrooms}</p>
                          </div>
                        ) : null}
                        {selectedPlace.bathrooms ? (
                          <div className="property-main-features">
                            <img
                              src={require("../../assets/icons/bathrooms.svg")}
                            />
                            <p>{selectedPlace.bathrooms}</p>
                          </div>
                        ) : null}
                        {selectedPlace.sqft ? (
                          <div className="property-main-features">
                            <img src={require("../../assets/icons/sqft.png")} />
                            <p>{selectedPlace.sqft} sqft</p>
                          </div>
                        ) : null}
                        <div className="property-main-features">
                          <img
                            src={require("../../assets/icons/pre-owned.png")}
                          />
                          <p>1985/Preowned</p>
                        </div>
                      </div>
                      <a
                        href={`/sellers/${selectedPlace.id}`}
                        className="review-property-offers-map"
                      >
                        Review Property
                      </a>
                    </div>
                  </div>
                </InfoWindow>
                {this.state.selectedPlace ? (
                  <Circle
                    radius={parseInt(radiusFilter) * 1000}
                    center={{
                      lat: this.state.selectedLocation?.lat || 0,
                      lng: this.state.selectedLocation?.lng || 0,
                    }}
                    strokeColor="transparent"
                    strokeOpacity={0}
                    strokeWeight={5}
                    fillColor="#109cf1"
                    fillOpacity={0.4}
                  />
                ) : null}
              </Map>
              {/* <button className='map-draw-btn' onClick={() => this.setState({showDrawContainer: !showDrawContainer})}>
                  Draw
                </button> */}
              {showDrawContainer && (
                <div
                  className="draw-map-container ease-in-n-out"
                  style={{
                    left: hideProperties ? 15 : "2.05%",
                    width: hideProperties ? "97.45%" : "100%",
                  }}
                >
                  <p>
                    <span className="blue-color-txt">Draw a shape</span> around
                    the region(s) you would like to live in
                  </p>
                  <div className="flex-row">
                    <button
                      className="draw-map-cancel-btn"
                      onClick={() =>
                        this.setState({ showDrawContainer: false })
                      }
                    >
                      cancel
                    </button>
                    <button className="draw-map-apply-btn">Apply</button>
                  </div>
                </div>
              )}

              {this.props.windowsWidth > 1040 && (
                <button
                  className="arrow-right-button"
                  style={{ right: hideProperties ? 0 : "-4%" }}
                  onClick={() =>
                    this.setState({
                      hideProperties: !hideProperties,
                    })
                  }
                >
                  <img
                    className={
                      hideProperties
                        ? "arrow-right-flipped"
                        : "arrow-right-unflipped"
                    }
                    src={require("../../assets/icons/list-arrow-down-green.png")}
                  />
                </button>
              )}
            </div>
          </Col>
          {!hideProperties && (
            <Col xl={4} lg={12} md={12} sm={12}>
              <div className="max-width-400">
                <div className="total-offers-received-notifier">
                  <div>
                    <img src={require("../../assets/icons/i-white.png")} />
                    <p>{listings.length} Listings</p>
                  </div>
                </div>
                <div className="properties-container">
                  {listings.length ? (
                    listings.map((listing, key) => {
                      const id = listing.id;
                      listing = listing.data();
                      const listingPrice = parseInt(
                        listing.expected_sales_price ||
                          0 ||
                          listing.projectedPrice ||
                          listing.current_value ||
                          listing.listing_price
                      );
                      const totalOffers =
                        unfilteredOffers.filter((o) => o.seller === id)
                          .length || 0;

                      return (
                        <div key={key} className="analytics-single-property">
                          <img
                            className="analytics-property-img"
                            src={
                              listing.featured_image?.url
                                ? listing.featured_image?.url
                                : require("../../assets/icons/gallery/Pict.png")
                            }
                            alt="Home image"
                          />
                          <div className="analytics-property-inner-container">
                            <p className="property-txt">
                              {listing.UnparsedAddress}
                            </p>
                            <p className="property-purchase-price">
                              {listingPrice
                                ? "$" +
                                  listingPrice
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "N/A"}
                            </p>
                            <div
                              className="flex-row mt-20"
                              style={{ alignItems: "center" }}
                            >
                              <div className="total-offers-on-property">
                                <img
                                  src={require("../../assets/icons/i-black.png")}
                                />
                                {totalOffers ? (
                                  <Link
                                    to={{
                                      pathname: "/offers",
                                      search: `?offerAddressFilter=${listing.street_number} ${listing.street_name}, ${listing.city}, ${listing.state} ${listing.zip_code}`,
                                    }}
                                    className="total-offer-link-btn"
                                  >
                                    {totalOffers} offers
                                  </Link>
                                ) : (
                                  <p>{totalOffers} offers</p>
                                )}
                              </div>
                              <span className="badge review-badge custom-badge-padding">
                                <i className="fa fa-repeat"></i>
                                {listing.MlsStatus}
                              </span>
                            </div>
                            <div className="wrapped-flex-row">
                              {listing.bedrooms ? (
                                <div className="property-main-features">
                                  <img
                                    src={require("../../assets/icons/bedrooms.svg")}
                                  />
                                  <p>{listing.bedrooms}</p>
                                </div>
                              ) : null}
                              {listing.bathrooms ? (
                                <div className="property-main-features">
                                  <img
                                    src={require("../../assets/icons/bathrooms.svg")}
                                  />
                                  <p>{listing.bathrooms}</p>
                                </div>
                              ) : null}
                              {listing.sqft ? (
                                <div className="property-main-features">
                                  <img
                                    src={require("../../assets/icons/sqft.png")}
                                  />
                                  <p>{listing.sqft} sqft</p>
                                </div>
                              ) : null}
                              <div className="property-main-features">
                                <img
                                  src={require("../../assets/icons/pre-owned.png")}
                                />
                                <p>1985/Preowned</p>
                              </div>
                            </div>
                            <button
                              onClick={() =>
                                (window.location.href = `/sellers/${id}`)
                              }
                              className="review-property-offers"
                            >
                              Review Property
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      There are no properties available.
                    </p>
                  )}
                </div>
              </div>
            </Col>
          )}
          <Col></Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p className="font-18-title avg-offers-terms">
              Average Offer Terms include
            </p>
          </Col>
          <div className="col-xl-6 col-lg-6 col-md-12 overflow">
            <div className="analytics-table-left">
              <Table striped>
                <tbody>
                  <tr>
                    <td className="analytics-table-td">Finance Type</td>
                    <td className="analytics-table-td text-right">
                      {keyItemValue["financingType"]}
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">Down Payment</td>
                    <td className="analytics-table-td text-right">
                      {currencyFormatter.format(itemAverage["downPayment"])}
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">Finance Amount</td>
                    <td className="analytics-table-td text-right">
                      {currencyFormatter.format(itemAverage["financeAmount"])}
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">Seller Concessions</td>
                    <td className="analytics-table-td text-right">
                      {currencyFormatter.format(
                        itemAverage["sellerConcessions"]
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">
                      Earnest Money Deposit
                    </td>
                    <td className="analytics-table-td text-right">
                      {currencyFormatter.format(itemAverage["earnestMoney"])}
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">Title Expense</td>
                    <td className="analytics-table-td text-right">
                      {keyItemValue["titlePolicyExpense"]}
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">Service Contract</td>
                    <td className="analytics-table-td text-right">
                      {itemAverage["residentialServiceContract"]}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 overflow">
            <div className="analytics-table-right">
              <Table striped>
                <tbody>
                  <tr>
                    <td className="analytics-table-td">Closing Date</td>
                    <td className="analytics-table-td text-right">
                      {itemAverage["numberOfDays"]} Days
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">Leaseback Days</td>
                    <td className="analytics-table-td text-right">
                      {itemAverage["leasebackDays"]}
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">Possession</td>
                    <td className="analytics-table-td text-right">
                      {keyItemValue["possession"]}
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">Option Fee and Days</td>
                    <td className="analytics-table-td text-right">
                      {currencyFormatter.format(itemAverage["optionFee"])},{" "}
                      {itemAverage["optionDays"]} Days
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">Non Realty Item</td>
                    <td className="analytics-table-td text-right">n/a</td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">
                      Sale of Other Property
                    </td>
                    <td className="analytics-table-td text-right">
                      {keyItemValue["saleOfOtherProperty"]}
                    </td>
                  </tr>
                  <tr>
                    <td className="analytics-table-td">
                      Right to Terminate Due to Lender’s Appraisal
                    </td>
                    <td className="analytics-table-td text-right">
                      {keyItemValue["rightToTerminate"]}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey,
})(OfferMapLocation);
