import dash from "../assets/icons/dash.svg";
import an from "../assets/icons/an.svg";
import list from "../assets/icons/list.svg";
import house from "../assets/icons/house.svg";
import offer from "../assets/icons/offer.svg";
import circle from "../assets/icons/person-circle.svg";
import code from "../assets/icons/code.svg";
import fill from "../assets/icons/people-fill.svg";
import box from "../assets/icons/box.svg";

export const sidebarPath = (role, props) => {
  const sellersLabel = role === "builder" ? "Builder Homes" : "My Listings";
  const isAdmin =
    props.user.authRole === "Admin" || props.user.authRole === "Owner";

  const handleLogoutClick = (e) => {
    e.preventDefault();
    props.logout();
  };

  if (props.user.role === "titleCompany") {
    return [
      {
        title: "main",
        subAccounts: [
          { path: "/account", name: "My Profile", img: circle },
          { path: "/offers", name: "Offers", img: offer },
        ],
      },
    ]
  } else {
    return [
      {
        title: "main",
        subAccounts: [
          { path: "/dashboard", name: "Dashboard", img: dash },
          { path: "/analytics", name: "Analytics", img: an },
          // { path: "/my-analytics", name: "My Analytics", img: an },
        ],
      },
      {
        title: "profiles",
        subAccounts: [
          { path: "/sellers", name: sellersLabel, img: list },
          { path: "/homes", name: "My Homes for Sale", img: house },
          { path: "/offers", name: "My Offers", img: offer },
          // { path: "/transactions", name: "My Transactions", img: offer },
        ],
      },
      {
        title: "account",
        subAccounts: [
          { path: "/account", name: "My Account", img: circle },
          { path: "/embedable-code", name: "Embedable Code", img: code },
          { path: "/users", name: "Users", img: fill, visible: !isAdmin },
          {
            path: "#",
            name: "Logout",
            img: box,
            logOut: handleLogoutClick.bind(this),
          },
        ],
      },
    ];
  }
};
