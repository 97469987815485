import React, { Component } from "react";
import "firebase/functions";
import { Helmet } from "react-helmet";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Loader from 'react-loader-spinner';
import firebase from "firebase/app";
import "firebase/functions";

class Pricing extends Component {

    state = {
        loaded: false,
        activeTab: 'month',
        packages: [],
    };

    toggle = activeTab => {
        this.setState({ activeTab });
    }

    signup = (p) => {
        localStorage.setItem('packageID',p.id);
        window.location.href = '/liveStreamPackages';
    }

    async componentDidMount() {

        const params = new URLSearchParams(this.props.location.search);
        const sourceTag = params.get('sourceTag'); // sourceTag

        console.log(sourceTag);

        if(sourceTag){
            localStorage.setItem('sourceTag',sourceTag);
        }

        window.Typekit.load({ async: false });
        setTimeout(() => {
            this.setState({ loaded: true });
        }, 500); // let messenger style load properly and then load content

        const db = firebase.firestore();

        const snap = await db.collection("liveStreamPacakges")
            .where('isActive', '==', true)
            .orderBy('price')
            .get();

        const packages = [];
        snap.docs.forEach(d => {
            const pPackage = {
                id: d.id,
                ...d.data(),
            }
            packages.push(pPackage);
        });

        this.setState({
            packages
        });
    }

    getPricingData = (tab, pPackage) => {
        let data = null;
        pPackage.period.forEach(p => {
            if (p.duration === tab) {
                data = p;
            }
        });
        return data;
    }

    render() {
        const { loaded, activeTab, packages } = this.state;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Pricing</title>
                    <link
                        href="/css/pricingTable2.css"
                        rel="stylesheet"
                        id="bootstrap-css"
                    />
                </Helmet>
                {loaded ? (

                    <>
                        <div className="price_table">
                            <section>
                                <h2 className="text-center">Homematchx Pricing Plans for Your Business</h2> <br />

                                <Nav tabs className="justify-content-center tabs-custome-style">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === 'month' })}
                                            onClick={() => { this.toggle('month'); }}
                                        >Monthly</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === 'year' })}
                                            onClick={() => { this.toggle('year'); }}
                                        >Yearly</NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="month">

                                    </TabPane>
                                    <TabPane tabId="year">

                                    </TabPane>
                                </TabContent>

                                <div className="container-fluid pricing_container">
                                    <div className="row">
                                        {packages.map(p => {
                                            const { id, name, benefits, buyerProfiles, searchLocations } = p;
                                            const data = this.getPricingData(activeTab, p);
                                            const duration = data ? data.duration : '';
                                            const price = data ? data.price : '';
                                            let classessName = id === 'a2a4bc88-0ad9-487b-8a12-45dbf787f69d' ? 'active':'' 
                                            classessName = (name !== 'Business') ? `${classessName} not-recommended` : classessName;

                                            const streamText = (data) ? data.hours < 1 ? `${data.hours * 60} minutes` : `${data.hours} Hours` : ""
                                            return (
                                                <div className="col-md-3">
                                                    <div className={`mypricing_content ${classessName} clearfix`}>
                                                        <div className="mypricing_head_price clearfix">
                                                            
                                                            {name === 'Business' && <div className="recommended">Recommended</div>}

                                                            <div className="mypricing_head_content clearfix">
                                                                <div className="head_bg"></div>
                                                                <div className="head"> <span>{name}</span> </div>
                                                            </div>

                                                            <div className="clearfix">
                                                                <img style={{ maxWidth: '120px', marginBottom: '12px' }} src={`/images/icons/${id}.svg`} alt=""/>
                                                            </div>

                                                            <div key={`${id}-${duration}`} className="mypricing_price_tag clearfix">
                                                                <span className="price">
                                                                    {price !== 0 ? <>
                                                                        <span className="sign">$</span>
                                                                        <span className="currency">{price}</span>
                                                                        <span className="month">/{duration}</span>
                                                                    </> : <span className="currency"> Free</span>}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="mypricing_feature_list">
                                                            <ul>

                                                                <li><span>{streamText}</span>  Live Stream</li>
                                                                    <li><span>✔</span>  Custom Stream Link</li>
                                                                <li><span>{searchLocations}</span> Primary Search Locations</li>
                                                                <li><span>{buyerProfiles}</span> Buyer and Seller Profiles</li>

                                                                {benefits.map((b, i) => <li key={i}><span>✔</span> {b}</li>)}
                                                            </ul>
                                                        </div>
                                                        <div className="mypricing_price_btn clearfix"> <button onClick={() => this.signup(p)} href="">Sign up</button> </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </>


                ) : <Loader type="Rings" color="#ff214f" height="80%" width="100%" />}
            </React.Fragment>
        );
    }
}


export default Pricing;