import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import Alert from "components/Alert";
import "firebase/analytics";
import { set, update } from "lodash";
import mastercard from "../assets/icons/mastercard.png";
import nextPrev from "../assets/icons/next-prev.png";
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";

function SubscriptionForm({ user, customerId, userId, ...props }) {
  const [cardEmail, setCardEmail] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardName, setCardName] = useState("");

  const [errorMessage, setErrorMessage] = useState({
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const formatCardNumber = (input) => {
    const formattedInput = input.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let formattedNumber = "";
    for (let i = 0; i < formattedInput.length; i += 4) {
      formattedNumber += formattedInput.slice(i, i + 4) + " ";
    }
    return formattedNumber.trim();
  };

  const formatExpDate = (input) => {
    const formattedInput = input.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    if (formattedInput.length > 2) {
      return `${formattedInput.slice(0, 2)}/${formattedInput.slice(2)}`;
    }
    return formattedInput;
  };

  const handleSubsription = async () => {
    if (!cardNumber) {
      return setErrorMessage({
        message: "Please enter card number",
        type: "cardNumber",
      });
    }
    if (!expDate) {
      return setErrorMessage({
        message: "Please enter card expiry date",
        type: "expDate",
      });
    }
    if (!cvv) {
      return setErrorMessage({
        message: "Please enter card cvv",
        type: "cvv",
      });
    }
    setLoading(true);
    const createStripeSubscription = firebase
      .functions()
      .httpsCallable("createStripeSubscription");

    let data = qs.stringify({
      "card[number]": cardNumber.replace(/\s/g, ""),
      "card[exp_month]": expDate.split("/")[0],
      "card[exp_year]": expDate.split("/")[1],
      "card[cvc]": cvv,
      // key: "pk_test_RO9Jp6dNdbOOLlxVx4sLOKB300KXRYY0gn",
      key: "pk_live_txUDcEIQu5Ijd8jMpMIiSjPd00QKj9yMpL",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.stripe.com/v1/tokens",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        createStripeSubscription({
          customerId: customerId,
          token: response.data.id,
        }).then((result) => {
          const db = firebase.firestore();
          db.collection("users")
            .doc(userId)
            .update({
              subscriptionId: result?.data?.subscription?.id,
              subscriptionHistory: firebase.firestore.FieldValue.arrayUnion({
                subscriptionId: result?.data?.subscription?.id,
                subscriptionStatus: result?.data?.subscription?.status,
                subscriptionPlanName:
                  result?.data?.subscription?.plan?.nickname,
                subscriptionPlanAmount:
                  result?.data?.subscription?.plan?.amount,
                subscriptionPlanCreated:
                  result?.data?.subscription?.plan?.created,
                subscriptionEndDate:
                  result?.data?.subscription?.current_period_end,
              }),
              subscriptionEndDate:
                result?.data?.subscription?.current_period_end,
              subscriptionStatus: result?.data?.subscription?.status,
            })
            .then(() => {
              setLoading(false);
              toast.success("Subscribed successfully");
              setTimeout(() => {
                window.location.href = "/dashboard";
              }, 2000);
            })
            .catch((error) => {
              toast.error("Failed to subscribe");
            });
        });
      })
      .catch((error) => {
        toast.error("Invalid card details");
      });
  };

  return (
    <>
      <div className="row mt-3 mx-auto">
        <div className="col-12 p-0 m-0">
          <p
            className="mb-1"
            style={{
              fontWeight: "700",
              fontSize: "12px",
              color: "#000000",
            }}
          >
            Email
          </p>
          <input
            type="text"
            className="w-100 thankme-textarea p-1"
            value={cardEmail}
            onChange={(e) => setCardEmail(e.target.value)}
          />
        </div>
        <div className="col-12 p-0 m-0 mt-3">
          <p
            className="mb-1"
            style={{
              fontWeight: "700",
              fontSize: "12px",
              color: "#000000",
            }}
          >
            Card Information
          </p>
          <input
            type="text"
            className="w-100 thankme-textarea p-1 pl-5"
            value={formatCardNumber(cardNumber)}
            style={{
              backgroundImage: `url(${mastercard})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left 0.5rem center",
              backgroundSize: "contain",
              paddingLeft: "55px !important",
            }}
            onChange={(e) => {
              if (e.target.value.length <= 19) setCardNumber(e.target.value);
            }}
          />
          {errorMessage.type == "cardNumber" && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                fontWeight: "600",
              }}
              className="mb-0"
            >
              {errorMessage.message}
            </p>
          )}
          {/* <img src={mastercard} className="w-50 float-right" /> */}
        </div>

        <div className="col-6 p-0 m-0 mt-3 pr-1">
          <p
            className="mb-1"
            style={{
              fontWeight: "700",
              fontSize: "12px",
              color: "#000000",
            }}
          >
            Expiry (MM/YY)
          </p>
          <input
            type="text"
            className="w-100 thankme-textarea p-1"
            value={formatExpDate(expDate)}
            onChange={(e) => {
              if (e.target.value.length <= 5) setExpDate(e.target.value);
            }}
            placeholder="MM/YY"
          />
          {errorMessage.type == "expDate" && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                fontWeight: "600",
              }}
              className="mb-0"
            >
              {errorMessage.message}
            </p>
          )}
        </div>
        <div className="col-6 p-0 m-0 mt-3 pl-1">
          <p
            className="mb-1"
            style={{
              fontWeight: "700",
              fontSize: "12px",
              color: "#000000",
            }}
          >
            CVC
          </p>
          <input
            type="text"
            className="w-100 thankme-textarea p-1"
            value={cvv}
            onChange={(e) => {
              if (e.target.value.length <= 3) {
                setCvv(e.target.value);
              }
            }}
          />
          {errorMessage.type == "cvv" && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                fontWeight: "600",
              }}
              className="mb-0"
            >
              {errorMessage.message}
            </p>
          )}
        </div>
        <div className="col-12 p-0 m-0 mt-3">
          <p
            className="mb-1"
            style={{
              fontWeight: "700",
              fontSize: "12px",
              color: "#000000",
            }}
          >
            Name on card
          </p>
          <input
            type="text"
            className="w-100 thankme-textarea p-1"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-3 justify-content-center">
        <button
          className="next-prev-btn mr-2"
          onClick={(e) => {
            props.previousStep();
          }}
        >
          <img
            src={nextPrev}
            className="backArrow"
            alt="back arrow"
            style={{ marginBottom: "2px", height: "10px" }}
          />
          Previous
        </button>
        {/* </div>
          <div className="col-12 col-md-6 col-lg-6"> */}
        <button
          className="next-prev-btn"
          onClick={(e) => {
            handleSubsription();
          }}
        >
          {loading ? "Please Wait..." : "Subscribe"}
          <img
            src={nextPrev}
            className="backArrow ml-2"
            alt="back arrow"
            style={{
              transform: "rotate(180deg)",
              marginBottom: "2px",
              height: "10px",
            }}
          />
        </button>
        {/* <button
          className="next-prev-skip-btn ml-2"
          onClick={(e) => {
            // navigate to dashboard
            window.location.href = "/dashboard";
          }}
        >
          Skip
        </button> */}
      </div>
    </>
  );
}

export default SubscriptionForm;
