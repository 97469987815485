import React from "react";
import { Table } from 'reactstrap';
import {firebaseConfig} from './../Firebase/firebase';
const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const snakeCaseToTitle = str => {
  return camelToSnakeCase(str).replace(/_/g, ' ').replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

const PartnerAndSellerRequestEmail = props => {
  const {
    heading,
    elements,
    request
  } = props;
  const services = request.services;
  delete request.services;
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Request</title>
      </head>
      <body>
        <div className="email-container">
          <img
            src={`${firebaseConfig.host}/img/realtyCarteLogo.png`}
            className="logo"
            style={{ height: "40px" }}
            alt=""
          />

          {heading && <h3 style={{ fontWeight: "200" }}>{heading}</h3>}

          {(elements || []).map((e, i) => {
            const { type } = e;
            if (type === "p") {
              return <p key={i} style={{ fontSize: "17px" }}>{e.text}</p>;
            }
            return null;
          })}

          <h3 style={{ fontWeight: "300" }}>Request Details</h3>

          <Table>
            <tbody>
              {Object.keys(request).map((k, i) => {
                let value = request[k];
                value = value.value || value;
                return <tr key={i}>
                  <th scope="row" style={{ float: 'left' }}>{snakeCaseToTitle(k)}</th>
                  <td style={{ paddingLeft: '50px' }}>{(value || '-')}</td>
                </tr>
              })}
            </tbody>
          </Table>
          
          <h3 style={{ fontWeight: "300" }}>Services</h3>
          <Table>
            <tbody>
              {services.map((service, i) => {
                return <tr key={i}>
                  <th scope="row" style={{ float: 'left' }}>Service # {i+1}</th>
                  <td style={{ paddingLeft: '50px' }}>{(service.label || '-')}</td>
                </tr>
              })}
            </tbody>
          </Table>

        </div>
      </body>
    </html>
  );
};

export default PartnerAndSellerRequestEmail;