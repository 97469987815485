import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { Link } from "react-router-dom";
import BuyerSellerNotesModal from "../../components/Buyer/BuyerSellerNotesModal";
import { TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Input } from 'reactstrap';
import classnames from 'classnames';
import CreateGroup  from 'components/Network/CreateGroup';

export default class Network extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data : {},
      groups: [],
      selectedGroup: '',
      searchTerm: '',
      activeTab: '1'
    };
  }

  toggle = activeTab => {
    this.setState({ activeTab });
  }

  onChange = async ({ target }) => {
    const { name: key, value } = target;
    this.setState({
      [key]: value
    });
  };

  async componentWillMount() {
    const db = firebase.firestore();
    const {user} = this.props;

    this.getUserResults(user);

    db.collection("network").doc(user.uid).collection('groups').onSnapshot(snaps => {
        const groups = snaps.docs.map(doc => {
            return {
                id: doc.id,
                ...doc.data(),
            }
        });
        this.setState({groups});
    });
  }  
  
  getUserResults = (user) => {
    const db = firebase.firestore();

    db.collection("network").doc(user.uid).get().then(doc => {
        const data = doc.data();
        this.setState({
            data
        });
    });
  }


  renderTabContent = (followers,type) => {
      let {searchTerm} = this.state;
      const current = (new Date().getTime()/1000 - (2*24*60*60));
      const finalList = (followers || []).filter(f => {
            if(!searchTerm){
                return true;
            }
            let flag = false;
            (f.search || []).forEach(v => {
                if(v.includes(searchTerm.toLowerCase())){
                    flag = true;
                }
            })
            return flag;
     });

      return (
            <div className="dashboard-list">
            <div className="manage-table">
                {(finalList || []).sort((a, b) => b.createdAt - a.createdAt).map(f => 
                    <>
                    <div key={f.id} className="row" style={{ padding: 20 }}>
                        <div className="col-sm-3" style={{ marginBottom: 10 }}>
                            <img
                                src={f.profile}
                                alt="buyer"
                                className="img-fluid"
                            />
                        </div>
                        
                        <div className="col-sm-9 title-container" style={{ marginBottom: 10 }}>
                            <h2>{f.name}</h2>
                            {current < f.createdAt && type && (
                                <p>
                                    <span className="badge badge-success">New {type}</span>
                                </p>
                            )}
                            {f.email && (
                                <h5 className="d-none d-xl-block d-lg-block d-md-block">
                                    <a href={`mailto:${f.email}`}><i className="fa fa-email" />{f.email}</a>
                                </h5>
                            )}
                            {(f.brokerage) && (
                                <h5 className="d-none d-xl-block d-lg-block d-md-block">
                                    <i className="fa fa-email" />{f.brokerage}
                                </h5>
                            ) }
                            {(f.company) && (
                                <h5 className="d-none d-xl-block d-lg-block d-md-block">
                                    <i className="fa fa-email" />{f.company}
                                </h5>
                            )}
                            <div className="action">
                                <Link to={`/buyers?searchId=${f.id}`} style={{marginRight:'5px'}}>
                                    <i className="fa fa-list" />
                                    View Buying
                                </Link>
                                <Link to={`/sellers?searchId=${f.id}`}>
                                    <i className="fa fa-list" />
                                    View Selling
                                </Link>
                                <BuyerSellerNotesModal {...this.props}  resourceId={f.id}/>
                            </div>
                        </div>
                    </div>
                    </>
                )}
            </div>
        </div>
      );
  }

  render() {
    let {data, groups, selectedGroup, activeTab} = this.state;
    let followers = [];
    let followings = [];

    if(data){
        followers = data.followers;
        followings = data.followings;
    }

    if(selectedGroup){
        groups.forEach(group => {
            if(group && selectedGroup === group.id){
                followers = group.followers;
            }
        })
    }

    return (
        <div className="content-area5">
        <div className="dashboard-content">
            <div className="dashboard-header clearfix">
                <div className="row">
                    <div className="col-sm-12 col-md-8">
                        <h4>My Network</h4>
                    </div>
                    
                    <div style={{textAlign: 'right'}} className="col-sm-12 col-md-2">
                        <select
                            name="selectedGroup"
                            className="form-control custom-select"
                            required
                            value={selectedGroup}
                            onChange={this.onChange}
                            
                        >
                            <option value="">All</option>
                            {groups.map((group) => {
                                return <option value={group.id}>{group.name}</option>
                            })}
                        </select>
                    </div>

                    <div style={{textAlign: 'right'}} className="col-sm-12 col-md-2">   
                        <CreateGroup {...this.props}/>
                    </div>
                </div>
            </div>
            <div className="search-network">
                <div className="search-wrapper">
                    <FormGroup>
                        <Input name="searchTerm" type="text" placeholder="Search..." onChange = { this.onChange }/>
                    </FormGroup>
                </div>
            </div>

            {(!selectedGroup) && (
                <>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                            >Followers</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                            >Following</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            {this.renderTabContent(followers,'Follower')}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.renderTabContent(followings,'Following')}
                        </TabPane>
                    </TabContent>
                </>
            )}
            {(selectedGroup) && (
                <>
                    {this.renderTabContent(followers)}
                </>
            )}

        </div>
    </div>
    );
  }
}
