import React, { Component } from "react";
import Share from "../../assets/icons/share.svg";
import Delete from "../../assets/icons/deleete.svg";
import { InlineShareButtons } from "sharethis-reactjs";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import firebase from "firebase/app";
import "firebase/firestore";
import AgentInformationModal from "../../components/Analytics/AgentInformationModal";

class DashboardAllOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareModal: false,
      deleteModal: false,
      isDeleted: false,
    };
  }
  toggle = (activeTab) => {
    this.setState({ activeTab });
  };
  shareToggle = () => {
    this.setState({
      shareModal: !this.state.shareModal,
    });
  };
  deleteToggle = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  deleteFromList = async (id) => {
    this.setState({
      deleteModal: false,
      isDeleted: true,
    });
    const db = firebase.firestore();
    await db.collection("offers").doc(id).update({
      deletedFromList: true,
    });
  };

  render() {
    const { offer, history, bestMatch, setOffersToShare, offersToShare, user } =
      this.props;
    const { isDeleted } = this.state;

    return (
      <tr
        className="all-offers-table-row"
        style={{
          display: isDeleted ? "none" : "table-row",
        }}
      >
        <td
          className={
            bestMatch && bestMatch[0] && bestMatch[0]?.id === offer?.id
              ? "first-td pr-0 first-td-width"
              : "pr-0 first-td-width"
          }
        >
          {bestMatch && bestMatch[0] && bestMatch[0]?.id === offer?.id && (
            <p className="best-match-tag-table-view">
              <i class="fa fa-heart"></i>BEST MATCH
            </p>
          )}
          <div className="custom-control custom-checkbox ml-6">
            <input
              type="checkbox"
              className="custom-control-input"
              id={offer.id}
              onChange={(e) => {
                if (e.target.checked)
                  setOffersToShare([...offersToShare, offer]);
                else
                  setOffersToShare(
                    offersToShare.filter((o) => o.id !== offer.id)
                  );
              }}
            />
            <label className="custom-control-label" htmlFor={offer.id}></label>
          </div>
        </td>
        {/* <td>1</td> */}
        <td
          className={
            bestMatch && bestMatch[0] && bestMatch[0]?.id === offer?.id
              ? "td-in-btwn offer-address-data"
              : "offer-address-data"
          }
        >
          {offer.address}
        </td>
        <td
          className={
            bestMatch &&
            bestMatch[0] &&
            bestMatch[0]?.id === offer?.id &&
            "td-in-btwn"
          }
        >
          {offer.status === "In Review" ? (
            <span className="badge review-badge">
              <i className="fa fa-repeat"></i>In Review
            </span>
          ) : offer.status === "Seller Review" ? (
            <span className="badge seller-review-badge">
              <i className="fa fa-repeat"></i>Seller Review
            </span>
          ) : offer.status === "Countered" ? (
            <span className="badge executed-badge status-badge">
              <i className="fa fa-repeat"></i>Countered
            </span>
          ) : offer.status === "Not Accepted" ? (
            <span className="badge not-accepted-badge">
              <i className="fa fa-times"></i>Not Accepted
            </span>
          ) : offer.status === "Withdraw" ? (
            <span className="badge badge-black status-badge">
              <i className="fa fa-check"></i>Withdrawn
            </span>
          ) : offer.status === "Terminated" ? (
            <span className="badge badge-black status-badge">
              <i className="fa fa-check"></i>Terminated
            </span>
          ) : offer.status === "Not Accepted" ? (
            <span className="badge not-accepted-badge status-badge">
              <i className="fa fa-times"></i>Not Accepted
            </span>
          ) : offer.status === "Executed" ? (
            <>
              <span className="badge executed-badge status-badge">
                <i className="fa fa-check"></i>Accepted
              </span>
              <span className="badge executed-badge status-badge ml-2">
                <i className="fa fa-check"></i>Executed
              </span>
            </>
          ) : (
            <span className="badge badge-success status-badge">
              <i className="fa fa-check"></i>Accepted
            </span>
          )}
          <span className="badge badge-success status-badge ml-2">
            <i className="fa fa-check"></i>
            {offer?.sellerEmail === user.email ? "Received" : "Sent"}
          </span>
        </td>
        <td
          className={
            bestMatch &&
            bestMatch[0] &&
            bestMatch[0]?.id === offer?.id &&
            "td-in-btwn"
          }
        >
          <AgentInformationModal key={offer.id} offer={offer} />
        </td>
        <td
          className={
            bestMatch &&
            bestMatch[0] &&
            bestMatch[0].id === offer.id &&
            "td-in-btwn"
          }
        >
          {offer.financingType}
        </td>
        <td
          className={
            bestMatch &&
            bestMatch[0] &&
            bestMatch[0].id === offer.id &&
            "td-in-btwn"
          }
        >
          {offer.purchasePrice
            ? "$" +
              offer.purchasePrice
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "N/A"}
        </td>
        <td
          className={
            bestMatch &&
            bestMatch[0] &&
            bestMatch[0].id === offer.id &&
            "td-in-btwn"
          }
        >
          {offer.closingDate}
        </td>
        <td
          style={{ textAlign: "center" }}
          className={
            bestMatch &&
            bestMatch[0] &&
            bestMatch[0].id === offer.id &&
            "last-td"
          }
        >
          <div className="d-flex align-items-center justify-content-center share-link">
            <button
              onClick={this.deleteToggle}
              className="link allOffers-share-btn delete-btn"
            >
              <img src={Delete} alt="Delete" className="delete-btn-icon" />
              Delete From List
            </button>
          </div>
          <Button
            onClick={() => history.push(`/offers/${this.props.offer.id}`)}
            type="button"
            className="btn btn-theme btn btn-secondary"
          >
            Review Offer
          </Button>
          <div className="d-flex align-items-center justify-content-center share-link">
            <button
              onClick={this.shareToggle}
              className="link allOffers-share-btn"
            >
              <img src={Share} alt="Share" style={{ marginRight: 10 }} />
              Share
            </button>
          </div>
          <Modal
            isOpen={this.state.shareModal}
            size="md"
            fade={false}
            toggle={this.shareToggle}
          >
            <ModalHeader
              className="modal-header"
              toggle={this.shareToggle}
            ></ModalHeader>
            <ModalBody className="modal-body">
              <Container>
                <Row>
                  <Col>
                    <div className="row-space-between">
                      <h5 className="modal-title">Share Buttons</h5>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-30">
                  <Col className="share-modal">
                    <InlineShareButtons
                      config={{
                        alignment: "center", // alignment of buttons (left, center, right)
                        color: "social", // set the color of buttons (social, white)
                        enabled: true, // show/hide buttons (true, false)
                        font_size: 16, // font size for the buttons
                        labels: "cta", // button labels (cta, counts, null)
                        language: "en", // which language to use (see LANGUAGES)
                        networks: [
                          // which networks to include (see SHARING NETWORKS)
                          "whatsapp",
                          "facebook",
                          "twitter",
                          "sms",
                          "email",
                        ],
                        padding: 5, // padding within buttons (INTEGER)
                        radius: 8, // the corner radius on each button (INTEGER)
                        show_total: true,
                        size: 30, // the size of each button (INTEGER)

                        // OPTIONAL PARAMETERS
                        url: `https://homematchx.com/share/offer/${this.props.offer.id}`, // (defaults to current url)
                        image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
                        description: `I would like to share the offer details with you for ${offer.address} https://homematchx.com/share/offer/${this.props.offer.id}`, // (defaults to og:description or twitter:description)
                        title: `I would like to share the offer details with you for ${offer.address} https://homematchx.com/share/offer/${this.props.offer.id}`, // (defaults to og:title or twitter:title)
                        message: `I would like to share the offer details with you for ${offer.address} https://homematchx.com/share/offer/${this.props.offer.id}`, // (only for email sharing)
                        subject: `I would like to share the offer details with you for ${offer.address}`, // (only for email sharing)
                        username: `I would like to share the offer details with you for ${offer.address} https://homematchx.com/share/offer/${this.props.offer.id}`, // (only for twitter sharing)
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.deleteModal}
            size="md"
            fade={false}
            toggle={this.deleteToggle}
          >
            <ModalHeader
              className="modal-header"
              toggle={this.deleteToggle}
            ></ModalHeader>
            <ModalBody className="modal-body">
              <Container>
                <Row>
                  <Col>
                    <div className="row-space-between">
                      <h5 className="delete-title">Delete Current Listing?</h5>
                    </div>
                    <div className="flex-center">
                      <button
                        className="cancel-btn"
                        onClick={this.deleteToggle}
                      >
                        Cancel
                      </button>
                      <button
                        className="confirm-delete-btn"
                        onClick={() => this.deleteFromList(offer.id)}
                      >
                        Confirm
                      </button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </td>
      </tr>
    );
  }
}

export default DashboardAllOffers;
