import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Label, Input } from 'reactstrap';
import firebase from "firebase/app";
import "firebase/database";
import ReactDOMServer from 'react-dom/server';
import buyerInviteEmail from 'components/emailTemplates/BuyerInviteEmailFromLender';

class VerifyDocumentsModal extends Component {
    state = {
      modal: false,
      selectedDocuments: "",
      isLoading: false,
      errorMessage: "",
      successMessage: "", 
    }

    toggle = () => {
        const { modal } = this.state;
        this.setState({
            modal: !modal
        });
    }


    uploadDocuments = () => {

      const { selectedDocuments } = this.state;

      const { resourceId, name } = this.props;

      if((Object.keys(selectedDocuments) || []).length <= 0) {
        this.setState({
          errorMessage: "No document selected. You need to add documents to upload."
        })
        return;
      }


      const storage = firebase.storage();
      const storageRef = storage.ref();


      (Object.keys(selectedDocuments) || []).forEach(async (document) => {
        const { name } = selectedDocuments[document] || {};
        await storageRef.child(`buyer-documents/${ resourceId }/${ name }`).put(selectedDocuments[document]);
      });

      const message = `Buyer ${name} submitted verification Request.`;

      const email = 'stephen@inkrealtygroup.com';

      const sendEmail = firebase.functions().httpsCallable('sendMatchEmail');
      const body = ReactDOMServer.renderToStaticMarkup(buyerInviteEmail({
          heading:'Buyer Verification',
          profileDescriptionTwo: message,
      }));

      sendEmail({subject :`Buyer ${name} requested to verify.`, email ,body});

      const db = firebase.firestore();
      db.collection("notifications").add({
        resourceId, name, email, message
      });

      this.setState({
        isLoading:false,
        successMessage: "Success. Your documents have been uploaded. You will hear from us after review."
      })

     
    }

  
    render () {
        const { modal, isLoading, errorMessage, successMessage } = this.state;
        return (
            <Fragment>
                 <Button
                    color="link"
                    style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}
                    onClick={this.toggle}
                    >
                      <i className="fa fa-Check" />Verify your Documents
                 </Button>
                <Modal isOpen={modal} toggle={ this.toggle} className="modal-danger modal-lg">
                    <ModalHeader toggle={ this.toggle }>Upload & Verify Document</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="12" lg="12">
                              <Label for="documents">Upload your Documents</Label>
                              <Input type="file"
                                name="documents"
                                id="documents"
                                multiple = { true }
                                onChange = { (event) => {
                                  const { target } = event || {};

                                  this.setState({
                                    selectedDocuments: target.files
                                  })
                                }}
                              />
                            </Col>
                        </Row>
                        {
                          errorMessage && <Alert color="danger" className="mt-20">
                            { errorMessage }
                          </Alert>
                        }

                        {
                          successMessage && <Alert color="success" className="mt-20">
                            { successMessage }
                          </Alert> 
                        }
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={ this.uploadDocuments } disabled = { isLoading }> { isLoading ? 'Uploading...' : 'Upload' } </Button>{' '}
                    <Button color="secondary" onClick={ this.toggle }>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
            
        )
    }
}


export default VerifyDocumentsModal;