import React, { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import SellerList from 'components/SellerList'
import {getUsersSellers} from './../Services/SellerService';
import { Button } from 'reactstrap'
import copy from 'copy-to-clipboard';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class publicSellersSharing extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            sellers: [],
            loading: true
        }
    }

    componentWillMount() {
        let uid = this.props.match.params.id;
        getUsersSellers({
            uid,
            email: 'noemail@noemail.com',
            show_on_share: true
        }).then(sellers => {
            if(this.mounted){
                const storage = firebase.storage()
                const storageRef = storage.ref()
                for (const seller of sellers) {
                    const sellerImageRef = storageRef.child(`images/properties/${seller.id}.jpg`)
                    sellerImageRef.getDownloadURL().then(url => {
                        seller.photo = url
                        if (this.mounted) {
                            this.setState({sellers})
                        }
                    }).catch(error => {})
                }
                if (this.mounted) {
                    this.setState({sellers, loading: false});
                }
            }
        }).catch(error => {
            if (this.mounted) {
                this.setState({loading: false, error: error})
                console.error(error)                    
            }
        });

        getUsersSellers({
            uid,
            email: 'noemail@noemail.com',
            show_on_share: true
        }).then(sellers => {
            if(this.mounted){
                const storage = firebase.storage()
                const storageRef = storage.ref()
                for (const seller of sellers) {
                    const sellerImageRef = storageRef.child(`images/properties/${seller.id}.jpg`)
                    sellerImageRef.getDownloadURL().then(url => {
                        seller.photo = url
                        if (this.mounted) {
                            this.setState({sellers})
                        }
                    }).catch(error => {})
                }
                if (this.mounted) {
                    this.setState({sellers, loading: false});
                }
            }
        }).catch(error => {
            if (this.mounted) {
                this.setState({loading: false, error: error})
                console.error(error)                    
            }
        });
    }

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
    }

    copyToClipboard = () => {
        let url = window.location.href;
        copy(url, {
            debug: true
          });
        toast.success('URL has been copied to your clipboard successfully.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    render() {
        const {loading, error} = this.state
        let sellerList;
        const user = null;
        
        if (loading) {
            sellerList = 
                <table className="manage-table">
                    <tbody>
                        <tr className="responsive-table"><td><p>Loading</p></td></tr>
                    </tbody>
                </table>
        }
        else {
            if (error) {
                sellerList = <table className="manage-table">
                        <tbody>
                        <tr className="responsive-table"><td><p>{error}</p></td></tr>
                        </tbody>
                    </table>
            } else {
                sellerList = <SellerList
                    remove={this.removeSeller}
                    sellers={this.state.sellers}
                    user={user}
                    showOnSharePage={this.showOnSharePage}
                />
            }
        }

        const sellerLabel = 'Sellers';
        const createSellerLabel = 'Share This Page';
        return (
            <div className="properties-section-body content-area">
                <div className="container-fuild">
                    <div style={{display: 'flex',flexWrap: 'wrap'}}>
                        <div className='col-md-10'>
                            <div className="content-area5">
                                <div className="dashboard-content">
                                    <div className="dashboard-header clearfix">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-9">
                                                <h4>{sellerLabel}</h4>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                    <Button
                                                        style={{float: 'right'}}
                                                        className="btn btn-theme btn-md"
                                                        onClick={this.copyToClipboard}
                                                        >
                                                        {createSellerLabel}
                                                    </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-list">
                                        <h3>{sellerLabel}</h3>
                                        {sellerList}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <a
                            href="https://agents.allstate.com/vincent-greene-mesquite-tx.html"
                            rel="noopener noreferrer"
                            target="_blank"
                            >
                                <img alt="" className="img-fluid" src="/img/ads/a7.png" />
                            </a>
                            <br /><br />
                            <a
                                href="https://rodsee.wvmbarlington.com"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img alt="" className="img-fluid" src="/img/ads/a4_2.png" />
                            </a>
                            <a
                                href="https://www.campbells.com/campbell-soup/chunky"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img
                                style={{ marginTop: "10px" }}
                                alt=""
                                className="img-fluid"
                                src="/img/ads/a5.png"
                                />
                            </a>
                            <a
                                href="https://www.texaco.com"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img
                                style={{ marginTop: "10px" }}
                                alt=""
                                className="img-fluid"
                                src="/img/ads/a6.png"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default publicSellersSharing
