import React, { Component } from "react";
import { connect } from 'react-redux';

import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  Form
} from 'reactstrap';

import BlogPostForm from './BlogPostForm';
import { toast } from 'react-toastify';
import {create} from './../../Store/Actions/BlogActions';

class CreateBlogPost extends Component {

  defaultPost = {
    title: '',
    body: '',
  };

  state = {
    blogPost: this.defaultPost,
    isOpen: false,
    isProcessing: false,
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  };

  onChange = (blogPost) => {
    this.setState({ blogPost });
  }

  onSuccess = () => {
    toast.success('Post added Successfully');
    this.setState({ blogPost: this.defaultPost, isProcessing: false });
    this.toggle();
  }

  onCreate = e => {
    if (e) e.preventDefault();
    const { blogPost } = this.state;
    const { user, resourceId, create } = this.props;

    

    const { uid } = user;
    // const { full_name, profilePhoto, realtor_name, lender_name, builder_name, uid } = user;
    // const profileImage = profilePhoto["64"] || profilePhoto["128"] || profilePhoto["256"] ||  profilePhoto.placeholder;
    // let name = (full_name || realtor_name || lender_name || builder_name);

    // const author = {
    //   uid,
    //   profileImage,
    //   name,
    // };

    this.setState({
      isProcessing: true,
    })

    create({
      blogPost: {
        ...blogPost,
        owner: uid,
        search: [uid],
        resourceId,
        createdOn: new Date().getTime()/1000,
        updatedOn: new Date().getTime()/1000,
      },
      onSuccess: () => {
        toast.success('Post Created Successfully');
        this.setState({
          isOpen: false,
          blogPost: {
            ...blogPost,
          },
          isProcessing:false,
        });
      },
      onError: (error) => {
        this.setState({
          isProcessing:false,
        });
        toast.error(error);
      },
    });
  }

  render() {
    const { isOpen, blogPost, isProcessing } = this.state;
    const {children, style, color, className} = this.props;

    return (
      <React.Fragment>
        <Button
          className={className}
          color={color}
          style={style}
          onClick={this.toggle}
          >
            {children}
        </Button>
        <Modal size='xl' isOpen={isOpen} toggle={this.toggle} className="modal-xl modal-success">
          <Form onSubmit={this.onCreate}>
            <ModalHeader toggle={this.toggle}><i className="fa fa-plus" /> Create Message Board</ModalHeader>
            <ModalBody>
              <BlogPostForm {...this.props} blogPost={blogPost} onStateChange={this.onChange} />
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="success">{isProcessing ? 'Creating...': 'Create'}</Button>{' '}
              <Button type="button" color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(null, {
  create,
})(CreateBlogPost);