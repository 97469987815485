import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import firebase from "firebase/app";
import "firebase/functions";
import * as actions from "../../Store/Actions/type";

class CardsList extends Component {
  dispatchSources = sources => {
    const { dispatch } = this.props;
    dispatch({
      type: actions.SET_SOURCES,
      payload: sources
    });
  };

  componentWillMount() {
    const { uid } = this.props.user;

    firebase
      .firestore()
      .collection("stripe_customers")
      .doc(`${uid}`)
      .collection("sources")
      .onSnapshot(
        ({ docs }) => {
          const sources = docs.map(doc => {
            return {
              id: doc.id,
              ...doc.data()
            };
          });
          this.dispatchSources(sources);
        },
        () => {
          this.dispatchSources([]);
        }
      );
  }

  render() {
    const { sources } = this.props;
    if(sources.length === 0) {
      return <p>Please add your card</p>
    }
    return (
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th>Card</th>
            <th>Exp Month</th>
            <th>Exp Year</th>
          </tr>
        </thead>
        <tbody>
          {sources.map((s, i) => {
            return (
              <tr key={i}>
                <td>
                  {s.brand}********{s.last4}
                </td>
                <td>{s.exp_month}</td>
                <td>{s.exp_year}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

const mapStateToProps = ({ PaymentReducer }) => {
  return {
    ...PaymentReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardsList);
