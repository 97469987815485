import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Button } from 'reactstrap';

import styles from './totalOverListingsChart.module.css';

const MONTHS = [
  {
    month: 3,
    name: '3-Months'
  },
  {
    month: 6,
    name: '6-Months'
  },
  {
    month: 12,
    name: '12-Months'
  }
]

const TotalOverListingsChart = ({ offers }) => {
  const [dataByBrokerageName, setDataByBrokerageName] = useState({});
  const [activeMonth, setActiveMonth] = useState(MONTHS[0]);
  const groupByName = (data) => {
    const dataByNames = {

    }
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (element.createdAt * 1000 > new Date().getTime() - activeMonth.month * 30 * 24 * 60 * 60 * 1000) {
        if (dataByNames[element.address]) {
          dataByNames[element.address].push(element)
        } else {
          dataByNames[element.address] = [element]
        }
      }
    }
    setDataByBrokerageName(dataByNames)
  }
  useEffect(() => {
    groupByName(offers)
  }, [offers, activeMonth])

  const data = {
    labels: Object.keys(dataByBrokerageName).map(value => {
      if (value.length > 10) {
        return value.slice(0, 10) + '...';
      }
      return value
    }),
    datasets: [
      {
        label: 'First dataset',
        data: Object.values(dataByBrokerageName).map(el => el.length),
        fill: false,
        lineTension: 0.1,
        pointRadius: 0,
        borderColor: '#28A745',
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    type: 'line',
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [0, 0, 10],
            lineWidth: 1.5,
            dashOffset: 0.5,
            borderDashOffset: 6,
            color: '#D3D8DD',
            drawBorder: false,
            zeroLineBorderDash: [0, 0, 10],
            zeroLineColor: "#D3D8DD",
            zeroLineWidth: 1.5,
          },
          ticks: {
            padding: 20,
            crossAlign: "start",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            maxRotation: 90,
            minRotation: 90,
          },
        },
      ],
    },

  };

  return (
    <div className={styles.container}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: 600,
            color: '#222222',
            fontFamily: 'Raleway',
          }}
        >
          Total Offers by Listings
        </div>
        <div
          style={{ width: '300px', fontSize: '12px', fontFamily: 'Raleway' }}
        >
          {MONTHS.map((el) =>
            <Button style={{ color: el.name == activeMonth.name ? '#8A8888' : '#222222' }} color="link" onClick={() => {
              setActiveMonth(el)
            }}>
              {el.name}
            </Button>
          )}
        </div>
      </div>
      <div
        style={{
          margin: '25px 0 10px',
          fontFamily: 'Raleway',
          fontSize: '12px',
        }}
      >
        Offers
      </div>
      <div style={{ width: '100%', overflowX: 'auto', paddingBottom: '25px' }}>
        <div style={{ width: '3000px', height: 300 }}>
          <Line options={options} data={data} height={300} width={0} />
        </div>
      </div>
    </div>
  );
};

export default TotalOverListingsChart;
