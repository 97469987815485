import React, { useEffect, useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { stateCodeMap } from "components/Analytics/info";
import { drawCustomBorderRadiusHorizontal } from "utils/Functions";
import { head, set, slice } from "lodash";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  ModalFooter,
  Label,
  Input,
  Tooltip,
  Progress,
  Table,
} from "reactstrap";
import { getAllOffers, getOffers } from "Services/SellerService";
import moment from "moment";
import { Link } from "react-router-dom";
import { all } from "lodash/fp";
import { select } from "underscore";
import firebase from "firebase";
import VerifiedListing from "../../../../assets/icons/verified-icon.svg";
import trend_icon from "../../../../assets/icons/trend-line.png";
import trendIcon from "../../../../assets/icons/trend-icon.svg";
import Person from "../../../../assets/icons/person.svg";
import thankme from "../.././../../assets/icons/thankme-usd.png";
import infoIcon from "../../../../assets/icons/info-icon.svg";
import NumberFormat from "react-number-format";
import up_arrow from "../../../../assets/icons/up-arrow.svg";
import arrowLeft from "../../../../assets/icons/arrow-left.png";
import mastercard from "../../../../assets/icons/mastercard.png";
import coffee from "../../../../assets/icons/coffee.png";
import { ModalTitle } from "react-bootstrap";
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import firebaseApp from "firebase/app";
import AgentInformationModal from "components/Analytics/AgentInformationModal";
import ShareSellerModal from "components/Seller/ShareSellerModal";
import ReactVisibilitySensor from "react-visibility-sensor";

const GlobalLocationChart = ({
  mobile,
  selectedLocation,
  setSelectLocation,
  cities,
  state,
  selectLocationCity,
  setSelectLocationCity,
  locationsOffers,
  user,
  setQueryParam,
  getGlobalAgents,
  globalAgents,
  allClosedGlobalSellers,
}) => {
  const isVerifiedUser = user?.hasOwnProperty("verifiedUser")
    ? user.verifiedUser
    : true;
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [allSellersList, setAllSellersList] = useState([]);
  const [bestMatches, setBestMatches] = useState([]);
  const [selectedSellerId, setSelectedSellerId] = useState();
  const [selectedSellers, setSelectedSellers] = useState([]);
  const [currentURL, setCurrentURL] = useState("");
  const [offerTrendsModal, setOfferTrendsModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isTooltip, setIsTooltip] = useState(false);

  const [unReadOffers, setUnReadOffers] = useState(0);
  const [totalPurchasePrice, setTotalPurchasePrice] = useState(0);
  const [financingType, setFinancingType] = useState("");
  const [totalOptionFee, setTotalOptionFee] = useState("");
  const [totalOptionDays, setTotalOptionDays] = useState("");
  const [totalExpenseType, setTotalExpenseType] = useState("");
  const [totalPosseions, setTotalPosseions] = useState("");
  const [totalSaleOfProperty, setTotalSaleOfProperty] = useState("");
  const [totalRightToTerminate, setTotalRightToTerminate] = useState("");
  const [totalEarnestMoney, setTotalEarnestMoney] = useState("");
  const [totalDownPayment, setTotalDownPayment] = useState("");
  const [closeingDate, setCloseingDate] = useState("");
  const [totalOffers, setTotalOffers] = useState(0);
  const [verifiedOffers, setVerifiedOffers] = useState(0);
  const [haveAccepted, setHaveAccepted] = useState(false);
  const [seller, setSeller] = useState({});
  const [thankMeModal, setThankMeModal] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [otherAmount, setOtherAmount] = useState(false);
  const [afterThankMeModal, setAfterThankMeModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardEmail, setCardEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [noOfOffers, setNoOfOffers] = useState(0);
  const [tippedUser, setTippedUser] = useState("");
  const [tippedProperty, setTippedProperty] = useState("");
  const [usersAllAllowedToViewProperties, setUsersAllAllowedToViewProperties] =
    useState(user?.allowedToViewProperties?.map((e) => e.property) || []);
  const [showTooltip, setShowTooltip] = useState({
    show: false,
    id: null,
  });
  const [showTooltip2, setShowTooltip2] = useState({
    show: false,
    id: null,
  });
  const [thankedPropertiesThisMonth, setThankedPropertiesThisMonth] = useState(
    user?.allowedToViewProperties?.filter(
      (e) =>
        new Date(e.boughtAt).getMonth() === new Date().getMonth() &&
        new Date(e.boughtAt).getFullYear() === new Date().getFullYear()
    ).length || 0
  );
  const [thankMeLoading, setThankMeLoading] = useState(false);
  const [thankMeAmount, setThankMeAmount] = useState(0);
  const [minThankMeAmount, setMinThankMeAmount] = useState(0);
  const [viewed, setViewed] = useState([]);

  useEffect(() => {
    setLoader(false);
    setTimeout(() => {
      setLoader(true);
    }, 1000);
    setCurrentURL(window.location.href);
  }, [
    selectedLocation,
    setSelectLocation,
    cities,
    state,
    selectLocationCity,
    setSelectLocationCity,
  ]);

  useEffect(() => {
    setSelectedSellerId(selectedSellers[0]?.seller);
    setTrends();
  }, [selectedSellers, allSellersList]);

  const listingPrice = parseInt(
    seller?.expected_sales_price ||
      0 ||
      seller?.projectedPrice ||
      seller?.current_value ||
      seller?.listing_price
  );

  const formatCardNumber = (input) => {
    // Format card number as XXXX-XXXX-XXXX-XXXX
    const formattedInput = input.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let formattedNumber = "";
    for (let i = 0; i < formattedInput.length; i += 4) {
      formattedNumber += formattedInput.slice(i, i + 4) + " ";
    }
    return formattedNumber.trim();
  };

  const formatExpDate = (input) => {
    // Format expiration date as MM/YYYY
    const formattedInput = input.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    if (formattedInput.length > 2) {
      return `${formattedInput.slice(0, 2)}/${formattedInput.slice(2)}`;
    }
    return formattedInput;
  };

  // handle thank me clicked
  const handleThankMe = (locationsOffer, sellerId) => {
    let numberOfOffers = locationsOffers.filter(
      (e) =>
        e.address === locationsOffer.address &&
        e.verified === true &&
        e.status != "In Review"
    ).length;

    const db = firebase.firestore();
    db.collection("users")
      .doc(sellerId)
      .get()
      .then((doc) => {
        setTippedUser({
          id: doc.id,
          ...doc.data(),
        });
        if (!doc.data().stripeAccountId) {
          return toast.error("This user is not connected with stripe account");
        }
        setTippedProperty(locationsOffer.seller);
        setThankMeModal(true);
      });

    setNoOfOffers(numberOfOffers);

    setSelectedAmount(
      numberOfOffers <= 6
        ? 29
        : numberOfOffers > 6 && numberOfOffers <= 24
        ? 49
        : numberOfOffers > 24 && numberOfOffers <= 48
        ? 79
        : 119
    );
    setThankMeAmount(
      numberOfOffers <= 6
        ? 29
        : numberOfOffers > 6 && numberOfOffers <= 24
        ? 49
        : numberOfOffers > 24 && numberOfOffers <= 48
        ? 79
        : 119
    );
    setMinThankMeAmount(
      numberOfOffers <= 6
        ? 29
        : numberOfOffers > 6 && numberOfOffers <= 24
        ? 49
        : numberOfOffers > 24 && numberOfOffers <= 48
        ? 79
        : 119
    );
  };

  // handle payment
  const handlePayment = async () => {
    const db = firebase.firestore();
    if (!cardNumber) {
      return setErrorMessage({
        message: "Please enter card number",
        type: "cardNumber",
      });
    }
    if (!expDate) {
      return setErrorMessage({
        message: "Please enter card expiry date",
        type: "expDate",
      });
    }
    if (!cvv) {
      return setErrorMessage({
        message: "Please enter card cvv",
        type: "cvv",
      });
    }
    setThankMeLoading(true);
    const createStripeTippingCharge = firebase
      .functions()
      .httpsCallable("createStripeTippingCharge");

    let data = qs.stringify({
      "card[number]": cardNumber.replace(/\s/g, ""),
      "card[exp_month]": expDate.split("/")[0],
      "card[exp_year]": expDate.split("/")[1],
      "card[cvc]": cvv,
      // key: "pk_test_RO9Jp6dNdbOOLlxVx4sLOKB300KXRYY0gn",
      key: "pk_live_txUDcEIQu5Ijd8jMpMIiSjPd00QKj9yMpL",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.stripe.com/v1/tokens",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        try {
          createStripeTippingCharge({
            amount: selectedAmount * 100,
            token: response.data.id,
            email: cardEmail,
            name: cardName,
            tippedUserAccountId: tippedUser?.stripeAccountId,
            isSubscribed:
              tippedUser?.subscriptionStatus === "active" &&
              tippedUser?.subscriptionEndDate >= Date.now()
                ? true
                : false,
          })
            .then((response) => {
              if (thankedPropertiesThisMonth + 1 <= 5)
                setThankedPropertiesThisMonth(thankedPropertiesThisMonth + 1);
              setUsersAllAllowedToViewProperties([
                ...usersAllAllowedToViewProperties,
                tippedProperty,
              ]);
              db.collection("users")
                .doc(user?.uid)
                .update({
                  allowedToViewProperties:
                    firebase.firestore.FieldValue.arrayUnion({
                      property: tippedProperty,
                      boughtAt: Date.now(),
                    }),
                });
              db.collection("sellers")
                .doc(tippedProperty)
                .update({
                  totalThankMePurchase:
                    firebase.firestore.FieldValue.increment(1),
                  totalThankMeRevenue:
                    tippedProperty?.subscriptionId &&
                    tippedUser?.subscriptionStatus === "active" &&
                    tippedUser?.subscriptionEndDate >= Date.now()
                      ? firebase.firestore.FieldValue.increment(selectedAmount)
                      : tippedUser?.email === "inkrealtygroup@gmail.com"
                      ? firebase.firestore.FieldValue.increment(selectedAmount)
                      : firebase.firestore.FieldValue.increment(
                          (selectedAmount * 80) / 100
                        ),
                });
              setThankMeModal(false);
              setPaymentModal(false);
              setCardEmail("");
              setCardName("");
              setCardNumber("");
              setExpDate("");
              setCvv("");
              setErrorMessage({});
              setThankMeLoading(false);
              setAfterThankMeModal(true);
            })
            .catch((err) => {
              console.log(err);
              setThankMeLoading(false);
            });
        } catch (err) {
          console.log(err);
        }

        // let chargeData = qs.stringify({
        //   amount: selectedAmount * 100,
        //   token: response.data.id,
        //   email: cardEmail,
        //   name: cardName,
        //   tippedUserAccountId: tippedUser?.stripeAccountId,
        // });

        // let chargeConfig = {
        //   method: "post",
        //   maxBodyLength: Infinity,
        //   url: "http://127.0.0.1:5001/homematchx-test/us-central1/createStripeTippingCharge",
        //   headers: {
        //     "Content-Type": "application/x-www-form-urlencoded",
        //   },
        //   data: chargeData,
        // };
        // axios
        //   .request(chargeConfig)
        //   .then((response) => {
        //     console.log(JSON.stringify(response.data));
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      })
      .catch((error) => {
        toast.error("Invalid card details");
        setThankMeLoading(false);
        console.log(error);
      });
  };

  const setTrends = async () => {
    // get sellers by id
    const db = firebase.firestore();
    const sellerRef = db.collection("sellers").doc(selectedSellers[0]?.seller);
    const seller = await sellerRef.get();
    const sellerData = seller.data();
    setSeller(sellerData);

    const offersDocs =
      allSellersList &&
      allSellersList?.filter((e) =>
        selectedSellers.map((e) => e.id).includes(e.id)
      );

    let haveAccepted = false;
    let verifiedOffers = offersDocs.filter((offer) => offer.verified);
    if (offersDocs.status === "Accepted" || offersDocs.status === "Executed")
      haveAccepted = true;

    if (!offersDocs.length) return null;

    setHaveAccepted(haveAccepted);
    setTotalOffers(offersDocs.length);
    setVerifiedOffers(verifiedOffers.length);
    setUnReadOffers(offersDocs.length);
    setTotalPurchasePrice(calculatePrice(offersDocs));
    setFinancingType(findFinancingType(offersDocs));
    setTotalOptionFee(optionFee(offersDocs));
    setTotalOptionDays(optionDays(offersDocs));
    setTotalExpenseType(getAllExpenseType(offersDocs));
    setTotalPosseions(getAllPossession(offersDocs));
    setTotalSaleOfProperty(getAllSaleOfProperty(offersDocs));
    setTotalRightToTerminate(getAllRightToTerminate(offersDocs));
    setTotalEarnestMoney(calculateEarnestMoney(offersDocs));
    setTotalDownPayment(calculateDownPayment(offersDocs));
    setCloseingDate(calculateNumberOfDays(offersDocs));
  };

  const numToFixed = (num) => Number(num).toFixed(2);

  const calculateNumberOfDays = (offersDocs) => {
    let totalNumberOfDays = 0;
    let newArr = offersDocs.map((doc) => Number(doc.numberOfDays));
    newArr.forEach((num) => {
      if (num) totalNumberOfDays += num;
    });
    return totalNumberOfDays / offersDocs.length;
  };

  const calculateDownPayment = (offersDocs) => {
    let totalDownPayment = 0;
    let newArr = offersDocs.map((doc) => Number(doc.downPayment));
    newArr.forEach((num) => {
      if (num) totalDownPayment += num;
    });
    return totalDownPayment / offersDocs.length;
  };

  const calculateEarnestMoney = (offersDocs) => {
    let totalEarnestMoney = 0;
    let newArr = offersDocs.map((doc) => Number(doc.earnestMoney));
    newArr.forEach((num) => {
      if (num) totalEarnestMoney += num;
    });
    return totalEarnestMoney / offersDocs.length;
  };

  const getAllRightToTerminate = (offersDocs) => {
    let totalRightToTerminate = [];
    offersDocs.map(({ rightToTerminate }, i) => {
      if (
        rightToTerminate !==
        totalRightToTerminate.find((elm) => elm === rightToTerminate)
      )
        totalRightToTerminate.push(rightToTerminate);
    });
    return totalRightToTerminate.join(", ");
  };

  const getAllPossession = (offersDocs) => {
    let totalTotalPossession = [];
    offersDocs.map(({ possession }, i) => {
      if (possession !== totalTotalPossession.find((elm) => elm === possession))
        totalTotalPossession.push(possession);
    });
    return totalTotalPossession.join(", ");
  };

  const optionFee = (offersDocs) => {
    let totalOptionFee = 0;
    let newArr = offersDocs.map((doc) => Number(doc.optionFee));
    newArr.forEach((num) => {
      if (num) totalOptionFee += num;
    });
    return totalOptionFee / offersDocs.length;
  };

  const optionDays = (offersDocs) => {
    const numbers = [];
    offersDocs.forEach((num) => {
      if (num.optionDays) numbers.push(parseInt(num.optionDays));
    });
    return `${Math.min(...numbers)} - ${Math.max(...numbers)}`;
  };

  const getAllSaleOfProperty = (offersDocs) => {
    let saleOfProperty = [];
    offersDocs.map(({ saleOfOtherProperty }, i) => {
      if (
        saleOfOtherProperty !==
        saleOfProperty.find((elm) => elm === saleOfOtherProperty)
      )
        saleOfProperty.push(saleOfOtherProperty);
    });
    return saleOfProperty.join(", ");
  };

  const getAllExpenseType = (offersDocs) => {
    let totalExpenseType = [];
    offersDocs.map(({ titlePolicyExpense }, i) => {
      if (
        titlePolicyExpense !==
        totalExpenseType.find((elm) => elm === titlePolicyExpense)
      )
        totalExpenseType.push(titlePolicyExpense);
    });
    return totalExpenseType.join(", ");
  };

  const findFinancingType = (offersDocs) => {
    let financeType = [];
    offersDocs.map(({ financingType }, i) => {
      if (financingType !== financeType.find((elm) => elm === financingType))
        financeType.push(financingType);
    });
    return financeType.join(", ");
  };

  const calculatePrice = (offersDocs) => {
    let totalPurchasePrice = 0;
    offersDocs.map((doc, i) => {
      totalPurchasePrice += parseInt(doc.purchasePrice);
    });
    return totalPurchasePrice / offersDocs.length;
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const offerTrendsModalToggle = () => {
    setOfferTrendsModal(!offerTrendsModal);
  };

  // unique offers by seller_id from locationsOffers and also check if seller is closed
  const db = firebase.firestore();
  let uniqueOffers = locationsOffers
    .filter((v, i, a) => a.findIndex((t) => t.seller === v.seller) === i)
    .filter((e) => {
      const seller = allClosedGlobalSellers.find((el) => el.id === e.seller);
      return (
        seller && (seller.MlsStatus == "Closed" || seller.MlsStatus == "Sold")
      );
    });

  const allClientOffers =
    allSellersList &&
    allSellersList
      // filter by checking with all ids in selectedSellers
      ?.filter((e) => selectedSellers.map((e) => e.id).includes(e.id))
      .map((e) => {
        return (
          <tr className="table-row" key={e.createdAt}>
            <td
              colSpan={3}
              className={
                bestMatches && bestMatches[0] && bestMatches[0].id === e.id
                  ? "address-td first-td"
                  : "address-td"
              }
            >
              {bestMatches && bestMatches[0] && bestMatches[0].id === e.id && (
                <p className="my-listings-best-match-tag">
                  <i class="fa fa-heart"></i>BEST MATCH
                </p>
              )}
              {e.address}
            </td>
            <td
              className={`p-0 ${
                bestMatches &&
                bestMatches[0] &&
                bestMatches[0].id === e.id &&
                `td-in-btwn`
              }`}
            >
              <div className="row">
                {e.verified && (
                  <div className="col-1 m-0 p-0">
                    <img
                      className="mr-1"
                      src={VerifiedListing}
                      style={{ width: "auto" }}
                    />
                  </div>
                )}
                <div className={`${e.verified ? "col-11" : "col-12"}`}>
                  {e.status === "In Review" ? (
                    <span className="all-client-offers-badge-width badge review-badge">
                      <i className="fa fa-repeat"></i>In Review
                    </span>
                  ) : e.status === "Seller Review" ? (
                    <span className="all-client-offers-badge-width badge seller-review-badge">
                      <i className="fa fa-repeat"></i>Seller Review
                    </span>
                  ) : e.status === "Countered" ? (
                    <span className="all-client-offers-badge-width badge badge-success status-badge">
                      <i className="fa fa-repeat"></i>Countered
                    </span>
                  ) : e.status === "Not Accepted" ? (
                    <span className="all-client-offers-badge-width badge not-accepted-badge">
                      <i className="fa fa-times"></i>Not Accepted
                    </span>
                  ) : e.status === "Withdraw" ? (
                    <span className="all-client-offers-badge-width badge badge-black status-badge">
                      <i className="fa fa-check"></i>Withdrawn
                    </span>
                  ) : e.status === "Terminated" ? (
                    <span className="all-client-offers-badge-width badge badge-black status-badge">
                      <i className="fa fa-check"></i>Terminated
                    </span>
                  ) : e.status === "Not Accepted" ? (
                    <span className="all-client-offers-badge-width badge not-accepted-badge status-badge">
                      <i className="fa fa-times"></i>Not Accepted
                    </span>
                  ) : e.status === "Executed" ? (
                    <span className="all-client-offers-badge-width badge executed-badge status-badge">
                      <i className="fa fa-check"></i>Executed
                    </span>
                  ) : (
                    <span className="all-client-offers-badge-width badge badge-success status-badge">
                      <i className="fa fa-check"></i>Accepted
                    </span>
                  )}
                </div>
              </div>
            </td>

            <td
              className={
                bestMatches &&
                bestMatches[0] &&
                bestMatches[0].id === e.id &&
                "td-in-btwn"
              }
            >
              {moment(e.createdAt * 1000).format("DD/MM/YYYY")}
            </td>
            <td
              className={
                bestMatches &&
                bestMatches[0] &&
                bestMatches[0].id === e.id &&
                "td-in-btwn"
              }
            >
              {e.purchasePrice
                ? "$" +
                  e.purchasePrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "N/A"}
            </td>
            <AgentInformationModal
              getGlobalAgents={getGlobalAgents}
              globalAgents={globalAgents}
              comingFrom="globalOffers"
              key={e.id}
              offer={e}
              user={user}
            />
            <td
              className={
                bestMatches &&
                bestMatches[0] &&
                bestMatches[0].id === e.id &&
                "last-td"
              }
            >
              <Link
                to={{
                  pathname: `${
                    e.sellerEmail === user?.email
                      ? `/offers/${e.id}`
                      : `/otherAgent/offer/${e.id}`
                  }`,
                  search: `?prevUrl=${encodeURIComponent(currentURL)}
                    `,
                  state: {
                    // globalAgents: globalAgents,
                    // getGlobalAgents: getGlobalAgents,
                    allOffers: allSellersList?.filter((e) =>
                      selectedSellers.map((e) => e.id).includes(e.id)
                    ),
                    totalItems: allSellersList?.filter((e) =>
                      selectedSellers.map((e) => e.id).includes(e.id)
                    ).length,
                    curItem:
                      allSellersList
                        ?.filter((e) =>
                          selectedSellers.map((e) => e.id).includes(e.id)
                        )
                        .findIndex((el) => el.id === e.id) + 1,
                  },
                }}
              >
                <Button className="go-to-offer-btn mb-0">Go to Offer</Button>
              </Link>
            </td>
          </tr>
        );
      });

  const sliceLimit = mobile ? 100 : window.chrome ? 1200 : 100;

  const dataValues = {
    labels: selectedLocation?.length
      ? cities
          ?.sort((a, b) => b[1] - a[1])
          .slice(0, sliceLimit)
          .map((el) => el[0])
      : Object.entries(state)?.map((el) => el[0]),
    datasets: [
      {
        label: "Dataset 1",
        data: selectedLocation?.length
          ? cities
              ?.sort((a, b) => b[1] - a[1])
              .slice(0, sliceLimit)
              .map((el) => el[1])
          : Object.entries(state)?.map((el) => el[1]),
        borderColor: "#109CF1",
        backgroundColor: "#109CF1",
        barThickness: 16,
      },
    ],
  };

  const drawCustomLabels = (chart) => {
    const ctx = chart.ctx;
    const {
      chartArea: { top, left },
      width,
    } = chart;
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.moveTo(left, top);
    ctx.lineTo(width, top);
    ctx.stroke();
    chart.scales["x-axis-0"]._labelItems.forEach((dataset) => {
      ctx.save();
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#686868";
      ctx.font = "14px Arial";
      ctx.fillText(dataset.label, dataset.x - 10, 10);
      ctx.restore();
    });
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;

      elements.forEach((element, elementIndex) => {
        const startX1 = element._view.x - element._model.height / 9;
        const startY1 = element._view.y - element._model.height / 1.5;

        const startY2 = element._view.y + element._model.height / 2.4;
        const startX2 = element._view.x + element._model.height / 6.3;
        drawCustomBorderRadiusHorizontal(
          ctx,
          startX1,
          startY1,
          startY2,
          startX2
        );
        const value = dataset.data[elementIndex];
        ctx.save();
        ctx.textBaseline = "middle";
        ctx.fillStyle = "black";
        ctx.font = "14px Arial";
        const labelX = element._view.x;
        const labelY = element._view.y;

        ctx.fillText(
          value === 0 || value < 1000 ? value : value / 1000 + "K",
          labelX + 10,
          labelY
        );
        ctx.restore();
      });
    });
  };

  const height =
    selectLocationCity?.length > 0
      ? "auto"
      : selectedLocation?.length
      ? cities?.slice(0, sliceLimit).map((el) => el[0]).length * 25 + "px"
      : Object.keys(stateCodeMap).length * 25 + "px";

  return (
    <>
      <div
        style={{
          width: `100%`,
          height: height,
          padding: mobile
            ? "0px"
            : selectLocationCity?.length > 0
            ? "10px 10px 10px 26px"
            : "10px",
        }}
      >
        {loader ? (
          <>
            {selectLocationCity?.length === 0 ? (
              <HorizontalBar
                data={dataValues}
                plugins={[
                  {
                    id: "customLabels",
                    afterDatasetsDraw: (chart) => drawCustomLabels(chart),
                  },
                ]}
                options={{
                  onClick: (_, elements) => {
                    if (elements.length > 0 && !selectedLocation?.length) {
                      const res = [...selectedLocation];
                      res.push(Object.entries(state)[elements[0]._index][0]);
                      setQueryParam("location", res);
                      setSelectLocation(res);
                    } else if (
                      elements.length > 0 &&
                      selectLocationCity?.length === 0
                    ) {
                      const res = [...selectLocationCity];
                      res.push(cities[elements[0]._index][0]);
                      setQueryParam("city", res);
                      setSelectLocationCity(res);
                    }
                  },
                  layout: {
                    padding: {
                      left: 100,
                      top: 20,
                      right: 20,
                    },
                  },
                  indexAxis: "y",
                  maintainAspectRatio: false,
                  legend: {
                    display: false,
                  },
                  elements: {
                    bar: {
                      borderWidth: 2,
                    },
                  },
                  responsive: true,

                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          borderDash: [5, 5],
                          lineWidth: 2,
                          borderDashOffset: 4,
                          color: "rgba(235, 235, 235, 1)",
                          zeroLineColor: "rgba(235, 235, 235, 1)",
                          zeroLineWidth: 2,
                          zeroLineBorderDash: [5, 5],
                        },
                        ticks: {
                          beginAtZero: true,
                          maxTicksLimit: 5,
                          fontSize: 14,
                          callback: (value) => {
                            return value;
                          },
                          backdropPaddingX: 0.1,
                          backdropPaddingY: 0.1,
                        },
                      },
                    ],

                    yAxes: [
                      {
                        gridLines: {
                          display: false,
                          drawBorder: false,
                        },
                        ticks: {
                          backdropPaddingX: 0.1,
                          backdropPaddingY: 0.1,
                          padding: 100,
                          mirror: true,
                          callback: (value) => {
                            if (value.length > 10) {
                              return value.slice(0, 10) + "...";
                            }
                            return value;
                          },
                        },
                      },
                    ],
                  },
                }}
              />
            ) : (
              uniqueOffers.map((locationsOffer, i) => {
                return (
                  <>
                    {i === 0 && (
                      <>
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="col-6 p-0">
                            <button
                              style={{
                                border: "1px solid #EBEBEB",
                                borderRadius: "6px",
                                padding: "6px 20px",
                                fontSize: "12px",
                                fontWeight: "600",
                                background: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => setSelectLocationCity([])}
                            >
                              <img src="/icons/gallery/arrow-to-left.svg" />
                              <span
                                style={{
                                  color: "#109CF1",
                                }}
                              >
                                {" "}
                                Back
                              </span>
                            </button>
                          </div>
                          <div
                            className="col-6 p-0"
                            style={{
                              textAlign: "end",
                              fontWeight: "600",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "12px",
                                textAlign: "end",
                                color: "#999999",
                              }}
                            >{`${selectLocationCity[0]}, ${
                              locationsOffer.address.split(",")[2].split(" ")[1]
                            }`}</div>
                            {uniqueOffers.length} Property Addresses
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    <ReactVisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible && !viewed.includes(locationsOffer.id)) {
                          const db = firebase.firestore();
                          db.collection("sellers")
                            .doc(locationsOffer.seller)
                            .update({
                              impressions:
                                firebaseApp.firestore.FieldValue.increment(1),
                            });
                          setViewed([...viewed, locationsOffer.id]);
                        }
                      }}
                    >
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          // maxHeight: "140px",
                          alignItems: "center",
                          // columnGap: "10px",
                          marginBottom: "12px",
                        }}
                      >
                        <div
                          className="col-12 col-xl-4 p-0"
                          style={{
                            position: "relative",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              background:
                                locationsOffer.status === "In Review" ||
                                locationsOffer.status === "Seller Review" ||
                                locationsOffer.status === "Countered"
                                  ? "rgb(255, 193, 7)"
                                  : locationsOffer.status === "Accepted"
                                  ? "green"
                                  : "red",
                              color:
                                locationsOffer.status === "In Review" ||
                                locationsOffer.status === "Seller Review" ||
                                locationsOffer.status === "Countered"
                                  ? "#000"
                                  : "#fff",
                              padding: "2px 13px",
                              textAlign: "center",
                              borderRadius: "6px",
                              fontWeight: "bold",
                              fontSize: "12px",
                              width: "fit-content",
                              position: "absolute",
                              top: "10px",
                              left: "10px",
                            }}
                          >
                            {locationsOffer.status}
                          </div>
                          <img
                            src={
                              locationsOffer.featured_image?.url
                                ? locationsOffer.featured_image?.url
                                : "/icons/gallery/Pict.png"
                            }
                            style={{
                              height: "140px",
                              // width: "170px",
                              width: "100%",
                            }}
                          />
                        </div>
                        <div
                          className="col-12 col-xl-8 mt-3 mt-xl-0 global-chart-text-padding"
                          style={{
                            width: "100%",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "16px",
                              fontWeight: "800",
                            }}
                          >
                            {locationsOffer.address}
                          </h4>
                          <img src={Person} className="d-inline"/>
                          <p className="d-inline"
                          style={{
                            fontSize: "14px",
                          }}
                          >
                            Listed by{" "}
                            <span>
                              <AgentInformationModal
                                comingFrom="globalLocationChart"
                                key={locationsOffer.id}
                                offer={locationsOffer}
                                sellerAgent={true}
                              />
                              {/* , {brokerageName} */}
                            </span>
                          </p>
                          <div
                            style={{
                              display: "flex",
                              columnGap: "7px",
                              margin: "8px 0",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                fontSize: "12px",
                              }}
                            >
                              {locationsOffer.bedrooms ? (
                                <>
                                  <img
                                    src="/icons/gallery/bedrooms.svg"
                                    style={{
                                      width: "14px",
                                    }}
                                  />{" "}
                                  {Math.floor(locationsOffer.bedrooms)}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                fontSize: "12px",
                              }}
                            >
                              {locationsOffer.bathrooms ? (
                                <>
                                  <img
                                    src="/icons/gallery/bathrooms.svg"
                                    style={{
                                      width: "14px",
                                    }}
                                  />{" "}
                                  {Math.floor(locationsOffer.bathrooms)}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                fontSize: "12px",
                              }}
                            >
                              {locationsOffer?.sqft ? (
                                <>
                                  <img
                                    src="/icons/gallery/triangle.svg"
                                    style={{
                                      width: "14px",
                                    }}
                                  />
                                  {locationsOffer?.sqft} sqft
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                fontSize: "12px",
                              }}
                            >
                              <img
                                src="/icons/gallery/pre-owned.png"
                                style={{
                                  width: "14px",
                                }}
                              />
                              1985/Preowned
                            </div>
                          </div>
                          <div
                            className="mb-3 mb-md-0"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#999999",
                                fontSize: "16px",
                                fontWeight: "800",
                              }}
                            >
                              {locationsOffer.actual_price ===
                              locationsOffer.actual_price
                                ? `$${locationsOffer.actual_price
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : ""}
                            </div>

                            {usersAllAllowedToViewProperties?.includes(
                              locationsOffer.seller
                            ) ||
                            locationsOffer.sellerEmail == user?.email ||
                            user?.email == "inkrealtygroup@gmail.com" ? (
                              <button
                                className="view-trend-offers-btn"
                                onClick={() => {
                                  const db = firebase.firestore();
                                  db.collection("sellers")
                                    .doc(locationsOffer.seller)
                                    .update({
                                      offerTrendsClickCount:
                                        firebase.firestore.FieldValue.increment(
                                          1
                                        ),
                                    });

                                  setSelectedSellers(
                                    locationsOffers.filter(
                                      (e) =>
                                        e.address === locationsOffer.address
                                    )
                                  );
                                  setAllSellersList([]);
                                  getAllOffers(user, null, {
                                    inList: true,
                                    sellerId: locationsOffers.id,
                                    getDeleted: true,
                                  })
                                    .then((offers) => {
                                      setAllSellersList(offers);
                                    })
                                    .catch((err) => {
                                      console.log(
                                        "Get All Offers Error: ",
                                        err
                                      );
                                    });
                                  offerTrendsModalToggle();
                                }}
                              >
                                {" "}
                                <img
                                  src={trendIcon}
                                  className="d-inline global-chart-trend-img"
                                />
                                <p className="trend-text d-inline">
                                  OfferTrends
                                </p>
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="row mt-0">
                            {!mobile ? (
                              <>
                                {(locationsOffer.sellerEmail == user?.email &&
                                  user?.subscriptionStatus == "active") ||
                                (user?.email == "inkrealtygroup@gmail.com" &&
                                  locationsOffer.sellerEmail == user?.email) ? (
                                  <ShareSellerModal
                                    user={user}
                                    seller={locationsOffer.seller}
                                    comingFrom="globalOffers"
                                    mobile={mobile}
                                    // followers={followers}
                                    // groups={groups}
                                  />
                                ) : (
                                  <></>
                                )}
                                {!usersAllAllowedToViewProperties.includes(
                                  locationsOffer.seller
                                ) && (
                                  <div className="info-icon-tooltip-container ml-1">
                                    <button
                                      style={{
                                        border: "1px solid #EBEBEB",
                                        borderRadius: "5px",
                                        padding: "5px 10px",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        background: "none",
                                        cursor: "pointer",
                                      }}
                                      className="mr-2 info-icon"
                                      onClick={() => {
                                        const db = firebase.firestore();
                                        const sellerRef = db
                                          .collection("sellers")
                                          .doc(locationsOffer.seller);
                                        sellerRef.update({
                                          thankMeClickCount:
                                            firebase.firestore.FieldValue.increment(
                                              1
                                            ),
                                        });

                                        if (thankedPropertiesThisMonth < 5) {
                                          handleThankMe(
                                            locationsOffer,
                                            locationsOffer.seller_id
                                          );
                                        }
                                      }}
                                      onMouseEnter={() => {
                                        setShowTooltip2({
                                          show: !showTooltip2,
                                          id: i,
                                        });
                                      }}
                                      onMouseLeave={() => {
                                        setShowTooltip2({
                                          show: !showTooltip2,
                                          id: null,
                                        });
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#109CF1",
                                        }}
                                      >
                                        {" "}
                                        $Thank Me
                                      </span>
                                    </button>
                                    {showTooltip2 && showTooltip2.id === i && (
                                      <div className="tooltip-content">
                                        You have thanked{" "}
                                        {thankedPropertiesThisMonth}/5
                                        properties this month. Subscribe to our
                                        premium plan to thank more than 5.
                                      </div>
                                    )}
                                  </div>
                                )}
                                <button
                                  className="global-chart-button-margin-bottom btn-success"
                                  style={{
                                    // color: "#28A745",
                                    border: "1px solid #EBEBEB",
                                    padding: "5px 10px",
                                    fontSize: "12px",
                                    // background: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    // width: "100%",
                                  }}
                                  onMouseLeave={() => {
                                    setShowTooltip({
                                      show: false,
                                      id: null,
                                    });
                                  }}
                                  onClick={() => {
                                    if (
                                      locationsOffer.sellerEmail ==
                                        user?.email ||
                                      usersAllAllowedToViewProperties.includes(
                                        locationsOffer.seller
                                      ) ||
                                      user?.email == "inkrealtygroup@gmail.com"
                                    ) {
                                      setSelectedSellers(
                                        locationsOffers.filter(
                                          (e) =>
                                            e.address === locationsOffer.address
                                        )
                                      );
                                      setAllSellersList([]);
                                      getAllOffers(user, null, {
                                        inList: true,
                                        sellerId: locationsOffers.id,
                                        getDeleted: true,
                                      })
                                        .then((offers) => {
                                          setAllSellersList(offers);
                                        })
                                        .catch((err) => {
                                          console.log(
                                            "Get All Offers Error: ",
                                            err
                                          );
                                        });
                                      toggle();
                                    } else {
                                      setShowTooltip({
                                        show: true,
                                        id: i,
                                      });
                                    }
                                  }}
                                >
                                  See
                                  {locationsOffers.filter(
                                    (e) => e.address === locationsOffer.address
                                  ).length > 1
                                    ? " " +
                                      "all" +
                                      " " +
                                      locationsOffers.filter(
                                        (e) =>
                                          e.address === locationsOffer.address
                                      ).length +
                                      " " +
                                      "Offers"
                                    : " " + "1 Offer"}
                                </button>
                                {showTooltip.show && showTooltip.id == i && (
                                  <div className="tooltip">
                                    $Thank Me to view offers details
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {(locationsOffer.sellerEmail == user?.email &&
                                  user?.subscriptionStatus == "active") ||
                                (user?.email == "inkrealtygroup@gmail.com" &&
                                  locationsOffer.sellerEmail == user?.email) ? (
                                  <div className="col-4 m-0 p-0 pr-1">
                                    <ShareSellerModal
                                      user={user}
                                      seller={locationsOffer.seller}
                                      comingFrom="globalOffers"
                                      mobile={mobile}
                                      // followers={followers}
                                      // groups={groups}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className="col-4 m-0 p-0 pr-1">
                                  {!usersAllAllowedToViewProperties.includes(
                                    locationsOffer.seller
                                  ) && (
                                    <div className="info-icon-tooltip-container ml-1">
                                      <button
                                        style={{
                                          border: "1px solid #EBEBEB",
                                          borderRadius: "5px",
                                          padding: "9px 17px",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          background: "none",
                                          cursor: "pointer",
                                        }}
                                        className="w-100 info-icon"
                                        onClick={() => {
                                          const db = firebase.firestore();
                                          const sellerRef = db
                                            .collection("sellers")
                                            .doc(locationsOffer.seller);
                                          sellerRef.update({
                                            thankMeClickCount:
                                              firebase.firestore.FieldValue.increment(
                                                1
                                              ),
                                          });

                                          if (thankedPropertiesThisMonth < 5) {
                                            handleThankMe(
                                              locationsOffer,
                                              locationsOffer.seller_id
                                            );
                                          }
                                        }}
                                        onMouseEnter={() => {
                                          setShowTooltip2({
                                            show: !showTooltip2,
                                            id: i,
                                          });
                                        }}
                                        onMouseLeave={() => {
                                          setShowTooltip2({
                                            show: !showTooltip2,
                                            id: null,
                                          });
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "#109CF1",
                                          }}
                                        >
                                          {" "}
                                          $Thank Me
                                        </span>
                                      </button>
                                      {showTooltip2 &&
                                        showTooltip2.id === i && (
                                          <div className="tooltip-content">
                                            You have thanked{" "}
                                            {thankedPropertiesThisMonth}/5
                                            properties this month. Subscribe to
                                            our premium plan to thank more than
                                            5.
                                          </div>
                                        )}
                                    </div>
                                  )}
                                </div>

                                <div className="col-4 m-0 p-0 pl-1">
                                  <button
                                    className="global-chart-button-margin-bottom btn-success w-100"
                                    style={{
                                      // color: "#28A745",
                                      border: "1px solid #EBEBEB",
                                      padding: "9px 10px",
                                      fontSize: "12px",
                                      // background: "none",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                      // width: "100%",
                                    }}
                                    // onMouseLeave={() => {
                                    //   setShowTooltip({
                                    //     show: false,
                                    //     id: null,
                                    //   });
                                    // }}
                                    onClick={() => {
                                      if (
                                        locationsOffer.sellerEmail ==
                                          user?.email ||
                                        usersAllAllowedToViewProperties.includes(
                                          locationsOffer.seller
                                        ) ||
                                        user?.email ==
                                          "inkrealtygroup@gmail.com"
                                      ) {
                                        setSelectedSellers(
                                          locationsOffers.filter(
                                            (e) =>
                                              e.address ===
                                              locationsOffer.address
                                          )
                                        );
                                        setAllSellersList([]);
                                        getAllOffers(user, null, {
                                          inList: true,
                                          sellerId: locationsOffers.id,
                                          getDeleted: true,
                                        })
                                          .then((offers) => {
                                            setAllSellersList(offers);
                                          })
                                          .catch((err) => {
                                            console.log(
                                              "Get All Offers Error: ",
                                              err
                                            );
                                          });
                                        toggle();
                                      } else {
                                        setShowTooltip({
                                          show: true,
                                          id: i,
                                        });
                                      }
                                    }}
                                  >
                                    See
                                    {locationsOffers.filter(
                                      (e) =>
                                        e.address === locationsOffer.address
                                    ).length > 1
                                      ? " " +
                                        "all" +
                                        " " +
                                        locationsOffers.filter(
                                          (e) =>
                                            e.address === locationsOffer.address
                                        ).length +
                                        " " +
                                        "Offers"
                                      : " " + "1 Offer"}
                                  </button>
                                  {showTooltip.show && showTooltip.id == i && (
                                    <div className="tooltip">
                                      $Thank Me to view offers details
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </ReactVisibilitySensor>
                  </>
                );
              })
            )}
          </>
        ) : (
          <img
            src="/img/loader.gif"
            style={{
              position: "absolute",
              transform: "translate(570%, 390%)",
            }}
          />
        )}
      </div>

      <Modal
        isOpen={modal}
        size="lg"
        fade={false}
        toggle={toggle}
        className="offers-modal"
      >
        <ModalHeader className="modal-header" toggle={toggle}></ModalHeader>
        <ModalBody className="modal-body">
          <Container>
            <Row>
              <Col>
                <div className="row-space-between">
                  <h5 className="modal-title">All client offers</h5>
                  {/* <button className="modal-chart-btn" onClick={chartToggle}>
                    <img src={Graph} className="pr-10" />Chart filters
                  </button> */}
                </div>
              </Col>
            </Row>
            <Row className="mb-30">
              <Col>
                <div className="table-responsive">
                  <table className="offers-table table-customized">
                    <tr className="modal-tr-header">
                      <th>Address</th>
                      <th></th>
                      <th></th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Purchase Price</th>
                      <th>Buyer Agent</th>
                      <th></th>
                    </tr>
                    {allClientOffers.length !== 0 ? (
                      allClientOffers
                    ) : (
                      <img
                        src="/img/loader.gif"
                        style={{
                          position: "absolute",
                          // top: "50%",
                          left: "50%",
                        }}
                      />
                    )}
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>

      {/* Trends Modal*/}
      <Modal
        isOpen={offerTrendsModal}
        size="lg"
        fade={false}
        toggle={offerTrendsModalToggle}
        className="offers-modal"
      >
        <ModalHeader
          className="modal-header"
          toggle={offerTrendsModalToggle}
        ></ModalHeader>
        <ModalBody className="modal-body">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h5 className="trend-modal-heading">
                  OfferTrends
                  <img src={trendIcon} />
                </h5>
                <p className="trend-modal-subheading">
                  OfferTrends provides insight into offer terms made on this
                  home.
                </p>
                <div className="row-spacebtwn">
                  <p className="verified-offers-text">
                    <img src={VerifiedListing} />
                    verified offers{" "}
                    <span>
                      <img
                        style={{ width: "1.4rem" }}
                        src={infoIcon}
                        id="Tooltip"
                        alt="info"
                      />
                    </span>{" "}
                    <small>
                      {verifiedOffers || 0} out of {totalOffers} offers are
                      verified.
                    </small>
                  </p>
                  <Tooltip
                    placement="top"
                    isOpen={isTooltip}
                    target="Tooltip"
                    toggle={toggleTooltip}
                    className="info-tooltip"
                  >
                    OfferTrends shows the percentage of offers received which
                    terms below have been verified
                  </Tooltip>
                  <p className="progress-value">
                    {totalOffers
                      ? ((verifiedOffers / totalOffers) * 100).toFixed(2)
                      : 0}
                    %
                  </p>
                </div>
                <Progress
                  value={totalOffers ? (verifiedOffers / totalOffers) * 100 : 0}
                />
                <p className="offer-trend-status">
                  <span>{verifiedOffers && verifiedOffers}</span> out of{" "}
                  <span>{totalOffers && totalOffers}</span> offers are verified
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 overflow m-vertical-32">
                <Table striped>
                  <tbody>
                    <tr>
                      <td className="normal-td light-weight">Purchase Price</td>
                      <td className="light-bold-td">
                        <img src={up_arrow} />{" "}
                        {totalPurchasePrice
                          ? numToFixed(
                              (1 - listingPrice / totalPurchasePrice) * 100
                            )
                          : 0}
                        % Average
                      </td>
                    </tr>
                    <tr>
                      <td className="normal-td light-weight">Down Payment</td>
                      <td className="light-bold-td">
                        <NumberFormat
                          value={`${Number(totalDownPayment).toFixed(0)}`}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value, props) => <>{value}</>}
                        />{" "}
                        Average
                      </td>
                    </tr>
                    <tr>
                      <td className="normal-td light-weight">finance type</td>
                      <td className="light-bold-td">{financingType}</td>
                    </tr>
                    <tr>
                      <td className="normal-td light-weight">option fee</td>
                      <td className="light-bold-td">
                        {" "}
                        <NumberFormat
                          value={`${Number(totalOptionFee).toFixed(0)}`}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value, props) => <>{value}</>}
                        />{" "}
                        Average
                      </td>
                    </tr>
                    <tr>
                      <td className="normal-td light-weight">option days</td>
                      <td className="light-bold-td">{totalOptionDays} days</td>
                    </tr>
                    <tr>
                      <td className="normal-td light-weight">
                        title policy expense
                      </td>
                      <td className="light-bold-td">{totalExpenseType}</td>
                    </tr>
                    <tr>
                      <td className="normal-td light-weight">possession</td>
                      <td className="light-bold-td">{totalPosseions}</td>
                    </tr>
                    <tr>
                      <td className="normal-td light-weight">
                        sale of other property
                      </td>
                      <td className="light-bold-td">{totalSaleOfProperty}</td>
                    </tr>
                    <tr>
                      <td className="normal-td light-weight">
                        Right to terminate due to lender’s apprasial
                      </td>
                      <td className="light-bold-td">{totalRightToTerminate}</td>
                    </tr>
                    <tr>
                      <td className="normal-td light-weight">Earnest money</td>
                      <td className="light-bold-td">
                        <NumberFormat
                          value={`${numToFixed(totalEarnestMoney)}`}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value, props) => <>{value}</>}
                        />{" "}
                        Average
                      </td>
                    </tr>

                    <tr>
                      <td className="normal-td light-weight">closing date</td>
                      <td className="light-bold-td">
                        {Math.ceil(closeingDate)} Days Average
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* Thank me Modal */}
      <Modal
        isOpen={thankMeModal}
        // isOpen={true}
        size="sm"
        style={{}}
        fade={false}
        toggle={() => {
          setSelectedAmount(0);
          setThankMeModal(!thankMeModal);
          setPaymentModal(false);
        }}
        className="thankme-modal"
      >
        <ModalHeader
          className="thankme-modal-header"
          toggle={() => {
            setSelectedAmount(0);
            setThankMeModal(!thankMeModal);
            setPaymentModal(false);
          }}
        >
          <p className="">
            {paymentModal && (
              <img
                src={arrowLeft}
                height={"24px"}
                className="d-inline"
                onClick={() => {
                  setPaymentModal(false);
                }}
                style={{
                  height: "24px !important",
                  marginRight: "5px",
                  marginBottom: "3px",
                  cursor: "pointer",
                }}
              />
            )}
            $Thank Me
          </p>
        </ModalHeader>

        <ModalBody className="modal-body pt-2 px-3 px-lg-0 pb-0 pb-md-4">
          {!paymentModal ? (
            <>
              <div className="row mx-auto mt-3">
                <div className="thankme-price-btn col-6 m-0 p-1">
                  <button
                    className={`btn ${
                      selectedAmount == thankMeAmount
                        ? "thank-me-buttons-active"
                        : "thank-me-buttons"
                    } w-100`}
                    onClick={() => {
                      setOtherAmount(false);
                      setSelectedAmount(thankMeAmount);
                      setErrorMessage({});
                    }}
                  >
                    $ {thankMeAmount}
                  </button>
                </div>

                {/* <div className="thankme-price-btn p-1 col-3">
                  <button
                    className={`btn ${
                      selectedAmount == 40
                        ? "thank-me-buttons-active"
                        : "thank-me-buttons"
                    } w-100`}
                    onClick={() => {
                      setOtherAmount(false);
                      setSelectedAmount(40);
                    }}
                  >
                    $40
                  </button>
                </div>
                <div className="thankme-price-btn p-1 col-3">
                  <button
                    className={`btn ${
                      selectedAmount == 69
                        ? "thank-me-buttons-active"
                        : "thank-me-buttons"
                    } w-100`}
                    onClick={() => {
                      setOtherAmount(false);
                      setSelectedAmount(69);
                    }}
                  >
                    $69
                  </button>
                  </div>*/}

                <div className="thankme-price-btn col-6 m-0 p-1">
                  <button
                    className={`btn ${
                      otherAmount
                        ? "thank-me-buttons-active"
                        : "thank-me-buttons"
                    } w-100`}
                    onClick={() => {
                      setOtherAmount(true);
                    }}
                  >
                    Other
                  </button>
                </div>
              </div>
              <div className="row mx-auto mt-3">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                  className="m-0 p-0 mb-3"
                >
                  What You Will Get:
                </p>
                <ul
                  style={{
                    listStyle: "disc",
                    fontSize: "12px",
                    fontWeight: "600",
                    marginLeft: "12px",
                  }}
                >
                  <li>OfferTrends View of Combined Offers</li>
                  <li>All Offer Details and Terms on Listing</li>
                  <li>Buyer's Agent Contact Information</li>
                  <li>Follow My Listing SMS Notification</li>
                </ul>
              </div>
              {otherAmount && (
                <div className="row mt-3 mx-auto">
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "16px",
                      color: "#000000",
                    }}
                  >
                    Custom amount
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-2"
                    value={selectedAmount}
                    onChange={(e) => {
                      setErrorMessage({});
                      setSelectedAmount(e.target.value);
                    }}
                  />
                  {errorMessage.type === "minAmount" && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                      className="mb-0"
                    >
                      {errorMessage.message}
                    </p>
                  )}
                </div>
              )}
              <div className="row mt-3 mx-auto">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  Leave a note
                </p>
                <textarea rows={3} className="w-100 thankme-textarea p-2" />
              </div>
            </>
          ) : (
            <>
              <div className="row mt-3 mx-auto">
                <div className="col-12 p-0 m-0">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    Email
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-1"
                    value={cardEmail}
                    onChange={(e) => setCardEmail(e.target.value)}
                  />
                </div>
                <div className="col-12 p-0 m-0 mt-3">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    Card Information
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-1 pl-5"
                    value={formatCardNumber(cardNumber)}
                    style={{
                      backgroundImage: `url(${mastercard})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "left 0.5rem center",
                      backgroundSize: "contain",
                      paddingLeft: "55px !important",
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 19)
                        setCardNumber(e.target.value);
                    }}
                  />
                  {errorMessage.type == "cardNumber" && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                      className="mb-0"
                    >
                      {errorMessage.message}
                    </p>
                  )}
                  {/* <img src={mastercard} className="w-50 float-right" /> */}
                </div>

                <div className="col-6 p-0 m-0 mt-3 pr-1">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    Expiry (MM/YY)
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-1"
                    value={formatExpDate(expDate)}
                    onChange={(e) => {
                      if (e.target.value.length <= 5)
                        setExpDate(e.target.value);
                    }}
                    placeholder="MM/YY"
                  />
                  {errorMessage.type == "expDate" && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                      className="mb-0"
                    >
                      {errorMessage.message}
                    </p>
                  )}
                </div>
                <div className="col-6 p-0 m-0 mt-3 pl-1">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    CVC
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-1"
                    value={cvv}
                    onChange={(e) => {
                      if (e.target.value.length <= 3) {
                        setCvv(e.target.value);
                      }
                    }}
                  />
                  {errorMessage.type == "cvv" && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                      className="mb-0"
                    >
                      {errorMessage.message}
                    </p>
                  )}
                </div>
                <div className="col-12 p-0 m-0 mt-3">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    Name on card
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-1"
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="row mt-3">
            <div className="col-md-12 p-0">
              <Button
                className="btn thank-me-continue w-100"
                onClick={() => {
                  {
                    paymentModal
                      ? handlePayment()
                      : selectedAmount < minThankMeAmount
                      ? setErrorMessage({
                          type: "minAmount",
                          message: `Amount must be at least $${minThankMeAmount}`,
                        })
                      : user?.uid === tippedUser?.id
                      ? toast.error("You can't tip yourself")
                      : setPaymentModal(true);
                  }
                }}
              >
                {paymentModal
                  ? thankMeLoading
                    ? "Sending..."
                    : `Support ${
                        selectedAmount != 0 ? "$" + selectedAmount : ""
                      }`
                  : "Continue"}{" "}
                {/* {selectedAmount != 0 ? "$" + selectedAmount : ""} */}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* after thank me modal */}
      <Modal
        isOpen={afterThankMeModal}
        // centered={true}
        size="sm"
        fade={false}
        toggle={() => {
          setAfterThankMeModal(!afterThankMeModal);
        }}
        className="thankme-modal"
      >
        <ModalBody className="modal-body thank-you-modal">
          <div className="row mx-auto">
            <div className="col-md-12 text-center">
              <img src={thankme} />
              <p
                className="p-0 m-0 mt-4"
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  color: "#000000",
                }}
              >
                Thank you for your support!
              </p>
              <div
                className="p-1 mt-4"
                style={{
                  width: "100% !important",
                }}
              >
                <button
                  className={`btn thank-you-btn w-100 mb-0`}
                  style={{ height: "40px !important" }}
                  onClick={() => {
                    setAfterThankMeModal(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default GlobalLocationChart;
