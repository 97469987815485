import React, { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/analytics';
import ReactDOMServer from 'react-dom/server'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PartitionedForm from 'components/PartitionedForm'
import LoadingOverlay from 'react-loading-overlay'
import { sendBuyerSellerEmail } from '../helpers/EmailHelper';
import LocationModal from 'components/Location/LocationModal'
import TagSelect from '../components/Common/TagSelect';
import { firebaseConfig } from './../components/Firebase/firebase';

let { logEvent } = firebase.analytics();

export const buyerFields = {
    purpose: {
        width: 4,
        multiselect: true,
        required: true,
        options: ['Partner with other buyers', 'Purchase a home']
    },
    front_door_faces: {
        options: ['North', 'South', 'East', 'West']
    },
    garage_location: {
        options: ['Rear Entry', 'Front Entry']
    },
    purchase_type: {
        width: 4,
        multiselect: true,
        required: true,
        options: ['Primary Residence', 'Vacation', 'Second Home', 'Investment']
    },
    financing_type: {
        required: true,
        options: ['Cash', 'Home Loan', 'Seller Financing']
    },
    loan_type: {
        required: false,
        options: ['FHA', 'Conventional', 'USDA', 'VA', 'Unknown'],
        isVisible: ({ inputs: { financing_type } }) => financing_type === 'Home Loan'
    },
    property_type: {
        width: 4,
        multiselect: true,
        required: true,
        options: [
            'Attached',
            'Attached or Half-duplex',
            'Condo/Townhome',
            'Designated Historical Home',
            'Doublewide Mobile w/Land',
            'Farm/Ranch House',
            'Garden/Zero Lot Line',
            'Hi Rise',
            'Historical/Conservation Dist.',
            'Interval Ownership',
            'Lake House',
            'Log Cabin',
            'Manufactured (certificate exch)',
            'Resort Property', 'Single Detached',
            'Singlewide Mobile w/Land',
            'Underground',
            'Vacation Home',
            'MUL-Apartment/5Plex+',
            'MUL-Fourplex',
            'MUL-Full Duplex',
            'MUL-Multiple Single Units',
            'MUL-Triplex',
            'LND-Commercial',
            'LND-Farm/Ranch',
            'LND-Residential',
        ],
    },
    purchase_month: {
        width: 2,
        // placeholder:'Any',
        options: ['Now', 'Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec']
    },
    purchase_year: {
        width: 2,
        options: [2020, 2021, 2022, 2023],
        isVisible: ({ inputs }) => inputs.purchase_month !== "Now",
    },
    is_date_flexible: {
        required: true,
        options: ['Yes', 'No']
    },
    is_price_flexible: {
        required: true,
        options: ['Yes', 'No']
    },
    min_price: {
        width: 2,
        required: true,
        type: 'number',
    },
    max_price: {
        width: 2,
        required: true,
        type: 'number',
    },
    subdivision: {},
    cities: {
        multiselect: true,
        creatable: true,
        options: ['Toronto', 'New York']
    },
    states: {
        multiselect: true,
        options: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']
    },
    zipcodes: {
        creatable: true,
        multiselect: true,
        options: []
    },
    bedrooms: {
        width: 2,
        options: ['1+', '2+', '3+', '4+', '5+']
    },
    bathrooms: {
        width: 2,
        options: ['1+', '2+', '3+', '4+', '5+']
    },
    expected_updates: {},
    recent_updates: {},
    year_built_min: {
        label: 'Built after',
        type: 'number',
        width: 2,
        placeholder: `e.g. 1900`,
        min: 1900,
    },
    year_built_max: {
        label: 'Built before',
        type: 'number',
        width: 2,
        placeholder: `e.g. 2020`,
        max: new Date().getFullYear() + 5,
    },
    is_lender_available: {
        options: ['Yes', 'No'],
        width: 2,
    },
    lender_nmls_id: {
        isVisible: (state) => state.inputs.is_lender_available === 'Yes',
        label: 'Lender NMLS Id'
    },
    home_hmxid: {
        isVisible: ({ inputs }) => inputs.selling_current_home === 'Yes',
        label: 'Home HMXID'
    },
    home_mlsid: {
        isVisible: ({ inputs }) => inputs.selling_current_home === 'Yes',
        label: 'Home MLSID'
    },
    selling_current_home: {
        options: ['Yes', 'No'],
        width: 2,
    },
    leaseback_allowed: {
        options: ['Yes', 'No'],
        width: 2,
    },
    hoa: {
        options: ['Yes', 'No', 'Not Required'],
        width: 2,
    },
    study: {
        options: ['Yes', 'No', 'Not Required'],
        required: false,
        width: 2,
    },
    leaseback_length: {
        isVisible: (state) => state.inputs.leaseback_allowed === 'Yes',
        options: ['1', '2', '3', '4', '5', '6+'],
        label: 'Leaseback length (weeks)'
    },
    foundation_type: {},
    address: {},
    zip: {},
    game_room: {
        options: ['Not Required', 'Yes', 'No'],
        width: 2,
    },
    media_room: {
        options: ['Not Required', 'Yes', 'No'],
        width: 2,
    },
    story: {
        width: 2,
        options: ['1', '1+', '2', '2+']
    },
    pool: {
        options: ['Not Required', 'Yes', 'No'],
        width: 2,
    },
    fireplace: {
        options: ['Not Required', 'Yes', 'No'],
        width: 2,
    },
    patio: {
        options: ['Not Required', 'Uncovered', 'Covered'],
        width: 2,
    },
    school_district: {
        width: 4
    },
    elementary_school: {},
    middle_school: {},
    high_school: {},
    division: {},
    buyer_email: {
        width: 4,
        required: true,
        type: 'email',
    },
    photo: {
        type: 'image',
    },
    buyer_phone: {
        width: 4,
        required: true,
        type: 'phone',
    },
    buyer_name: {
        width: 4,
        required: true
    },
    buyer_video: {
        width: 12,
        label: 'Youtube Video URL',
        placeholder: 'E.g https://www.youtube.com/watch?v=gznuqMpFJ7E',
        description: 'Upload a video describing your home buying needs, interest, and inspirations to share with others.',
    },
    about_buyer: {
        type: 'textblob',
        label: 'About the buyer'
    },
    sqft_min: {
        type: 'number',
        required: true,
    },
    sqft_max: {
        type: 'number',
        required: true,
    },
    acres: {
        options: [
            { value: 'Less than .5 acres (not zero)', key: '0.000009-0.499' },
            { value: '0.5-0.99 acres', key: '0.5-0.99' },
            { value: '1-2.99 acres', key: '1-2.99' },
            { value: '3-4.99 acres', key: '3-4.99' },
            { value: '5-9.99 acres', key: '5-9.99' },
            { value: '10-49.99 acres', key: '10-49.99' },
            { value: '50-99.99 acres', key: '50-99.99' },
            { value: 'Over 100 acres', key: '100-' }, // from 100 to infinity
            { value: 'Condo/Townhome Lot', key: '0.000009-0.10' },
            { value: 'Zero Lot', key: '0-0' }
        ],
    },
    style_of_house: {
        multiselect: true,
        options: ['A-Frame', 'Barndominium', 'Colonial', 'Contemporary/Modern', 'Craftsman', 'Early American', 'English', 'French', 'Geo/Dome', 'Loft', 'Mediterranean', 'Mid-Century Modern', 'Oriental', 'Other', 'Prairie', 'Ranch', 'Southwestern', 'Spanish', 'Split Level', 'Studio', 'Traditional', 'Tudor', 'Victorian']
    },
    galleryCheckbox: {
        label: 'Single Gallery',
        type: 'checkbox',
    },
    gallery: {
        title: 'Other Gallery',
        label: null,
        type: 'gallery'
    },
    homeGallery: {
        title: 'Home Gallery',
        label: null,
        type: 'gallery'
    },
    bedroomsGallery: {
        title: 'Bedroom Gallery',
        label: null,
        type: 'gallery'
    },
    kitchenGallery: {
        title: 'Kitchen Gallery',
        label: null,
        type: 'gallery'
    },
    bathroomGallery: {
        title: 'Bathroom Gallery',
        label: null,
        type: 'gallery'
    },
    backyardGallery: {
        title: 'Backyard Gallery',
        label: null,
        type: 'gallery'
    },
    familyRoomGallery: {
        title: 'Family Room Gallery',
        label: null,
        type: 'gallery'
    },
    diningRoomGallery: {
        title: 'Dining Room Gallery',
        label: null,
        type: 'gallery'
    },
    laundryRoomGallery: {
        title: 'Laundry Room Gallery',
        label: null,
        type: 'gallery'
    },
    mediaRoomGallery: {
        title: 'Media Room Gallery',
        label: null,
        type: 'gallery'
    },
    gameRoomGallery: {
        title: 'Game Room Gallery',
        label: null,
        type: 'gallery'
    },
    garageGallery: {
        title: 'Garage Gallery',
        label: null,
        type: 'gallery'
    },

    interested_in_flip: {
        title: "Interested in Fix and Flip Properties?",
        label: "Interested in Fix and Flip Properties?",
        options: ["Yes", "No"]
    },
    interested_in_buy_and_whole: {
        title: "Interested in Buy and Hold Properties?",
        label: "Interested in Buy and Hold Properties?",
        options: ["Yes", "No"]
    }
}

const sections = [{
    name: 'Basic Information',
    fields: ['purpose', 'purchase_type', 'property_type', 'financing_type', 'loan_type', 'is_lender_available', 'lender_nmls_id', 'style_of_house', 'purchase_month', 'purchase_year', 'is_date_flexible', 'is_price_flexible', 'min_price', 'max_price', 'acres', 'sqft_min', 'sqft_max', 'year_built_min', 'year_built_max', 'bedrooms', 'bathrooms', 'subdivision', 'cities', 'states', 'zipcodes', 'location', 'location_2', 'location_3', 'location_4', 'location_5']
},
{
    name: 'Buyer Information',
    fields: ['buyer_name', 'buyer_email', 'buyer_phone', 'represendtedBy', 'tags', "interested_in_flip", "interested_in_buy_and_whole"]
},
{
    name: 'Home Buyer Video',
    fields: ['buyer_video']
},
{
    name: 'Buyer Image',
    fields: ['photo']
},
{
    name: 'Dream Board.',
    isVisible: (state) => state.inputs.galleryCheckbox,
    description: 'Upload home inspirations images below. Use single gallery for upload or upload images to each category for the best result.',
    field: 'galleryCheckbox',
    fields: ['gallery']
},
{
    name: 'Dream Board',
    isVisible: (state) => !state.inputs.galleryCheckbox,
    description: 'Upload home inspirations images below. Use single gallery for upload or upload images to each category for the best result.',
    tabs: true,
    field: 'galleryCheckbox',
    fields: [
        'homeGallery', 'bedroomsGallery', 'kitchenGallery', 'bathroomGallery',
        'backyardGallery',
        'familyRoomGallery', 'diningRoomGallery', 'laundryRoomGallery',
        'mediaRoomGallery', 'gameRoomGallery', 'garageGallery', 'gallery'
    ]
},
{
    name: 'Detailed Information',
    fields: ['school_district', 'elementary_school', 'middle_school', 'high_school', 'about_buyer']
}, {
    name: 'Other Features',
    fields: ['study', 'game_room', 'media_room', 'story', 'pool', 'fireplace', 'patio', 'selling_current_home', 'home_hmxid', 'home_mlsid', 'leaseback_allowed', 'leaseback_length', 'front_door_faces', 'garage_location']
},
{
    name: 'HOA',
    fields: ['hoa']
},
]

class BuyerProfile extends Component {
    constructor(props) {
        super(props)

        const inputs = {}

        for (const field of sections.map(s => s.fields).flat()) {
            inputs[field] = ''
        }

        this.state = {
            error: '',
            busy: false,
            loading: false,
            showLoading: false,
            lenderChanged: false,
            inputs: {
                ...inputs,
                dontDisplayContactInfo: false,
                userInvited: false,
                hiddenProfile: false,
                galleryCheckbox: true,
            },
            location: undefined,
            location_2: undefined,
            location_3: undefined,
            location_4: undefined,
            location_5: undefined,
            formsSubmitted: {},
            fields: buyerFields,
        }

        this.mainForm = React.createRef();
    }

    id() {
        const { match } = this.props
        const { params } = match
        const { id } = params
        return id
    }

    componentWillMount() {
        if (this.newRecord()) {
            return
        }

        this.mounted = true

        const id = this.id()

        if (!id) {
            this.props.history.push('/buyers')
            return
        }

        const db = firebase.firestore()
        const storage = firebase.storage()
        const storageRef = storage.ref()
        const buyerRef = db.collection('buyers').doc(id)
        const buyerImageRef = storageRef.child(`images/users/${id}.jpg`)
        this.setState({ loading: true })

        buyerRef.get().then(doc => {
            const { inputs } = this.state
            const data = doc.data()
            buyerImageRef.getDownloadURL()
                .then(url => {
                    inputs.photo = url
                    if (this.mounted) {
                        this.setState({ inputs })
                    }
                }).catch(error => { })
            Object.assign(inputs, data)
            inputs.userInvited = false
            if (this.mounted) {
                let processedData = {};
                Object.keys(inputs).forEach(k => {
                    let v = inputs[k];
                    if (typeof v == 'number' && !(v)) {
                        processedData[k] = 0;
                    } else {
                        processedData[k] = v;
                    }
                });

                this.setState({
                    inputs: processedData,
                    tags: inputs.tags || [],
                    loading: false,
                    location: processedData.location || { lat: 40.6971494, lng: -73.9586004 },
                    location_2: processedData.location_2 || { lat: 40.6971494, lng: -73.9586004 },
                    location_3: processedData.location_3 || { lat: 40.6971494, lng: -73.9586004 },
                    location_4: processedData.location_4 || { lat: 40.6971494, lng: -73.9586004 },
                    location_5: processedData.location_5 || { lat: 40.6971494, lng: -73.9586004 }
                });
            }
        }).catch(error => console.error(error))

        this.timer = setTimeout(() => {
            const { loading } = this.state

            if (loading) {
                // if still loading, show loading indicator
                this.setState({ showLoading: true })
            }
        }, 500)
    }

    componentWillUnmount() {
        this.mounted = false
        clearTimeout(this.timer)
    }

    newRecord() {
        return this.id() === 'new'
    }

    validate(inputs) {
        let isValid = true
        const { fields } = this.state
        for (const fieldName of sections.map(s => s.fields).flat()) {
            const field = fields[fieldName]
            if (field && field.required) {
                const value = inputs[fieldName]
                const missing = typeof (value) === 'undefined' || value === ''

                if (field.multiselect && !inputs[fieldName].length) {
                    fields[fieldName].invalid = true
                    isValid = false
                    console.log(fieldName, isValid);
                }
                else if (missing) {
                    fields[fieldName].invalid = true
                    isValid = false
                    console.log(fieldName, isValid);
                }
                else {
                    fields[fieldName].invalid = false
                }
            }
        }

        if (!isValid) {
            this.setState({ fields })
        }

        return isValid
    }

    lendersEmail = (inputs, leadid, buyerImageUrl) => {

        const { host, storageBucket } = firebaseConfig;

        return <html>
            <head>
                <meta content="text/html; charset=utf-8" httpEquiv="Content-Type" />
                <meta content="width=device-width" name="viewport" />
                <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
                <style type="text/css">
                    {`
                    body {
                        margin: 0;
                        padding: 0;
                        }
                        table,
                        td,
                        tr {
                        vertical-align: top;
                        border-collapse: collapse;
                        }
                        * {
                        line-height: inherit;
                        }
                        a[x-apple-data-detectors=true] {
                        color: inherit !important;
                        text-decoration: none !important;
                        }`}
                </style>
                <style id="media-query" type="text/css">{`
                    @media (max-Width: 500px) {
                    .block-grid,
                    .col {
                    min-width: 320px !important;
                    max-width: 100% !important;
                    display: block !important;
                    }
                    .block-grid {
                    width: 100% !important;
                    }
                    .col {
                    width: 100% !important;
                    }
                    .col>div {
                    margin: 0 auto;
                    }
                    img.fullwidth,
                    img.fullwidthOnMobile {
                    max-width: 100% !important;
                    }
                    .no-stack .col {
                    min-width: 0 !important;
                    display: table-cell !important;
                    }
                    .no-stack.two-up .col {
                    width: 50% !important;
                    }
                    .no-stack .col.num4 {
                    width: 33% !important;
                    }
                    .no-stack .col.num8 {
                    width: 66% !important;
                    }
                    .no-stack .col.num4 {
                    width: 33% !important;
                    }
                    .no-stack .col.num3 {
                    width: 25% !important;
                    }
                    .no-stack .col.num6 {
                    width: 40% !important;
                    }
                    .no-stack .col.num9 {
                    width: 75% !important;
                    }
                    .video-block {
                    max-width: none !important;
                    }
                    .mobile_hide {
                    min-height: 0px;
                    max-height: 0px;
                    max-width: 0px;
                    display: none;
                    overflow: hidden;
                    font-size: 0px;
                    }
                    .desktop_hide {
                    display: block !important;
                    max-height: none !important;
                    }
                    }`}
                </style>
            </head>
            <body bgcolor="#d9d9d9" className="clean-body" style={{ "margin": "0", "padding": "0", "-webkitTextSizeAdjust": "100%", "backgroundColor": "#d9d9d9" }}>
                <center>
                    <table bgcolor="#d9d9d9" cellPadding="0" cellSpacing="0" className="nl-container" role="presentation" style={{ "tableLayout": "fixed", "verticalAlign": "top", "minWidth": "320px", "Margin": "0 auto", "borderSpacing": "0", "borderCollapse": "collapse", "msoTableLspace": "0pt", "msoTableRspace": "0pt", "backgroundColor": "#d9d9d9", "width": "540px", "max-width": "540px", "margin": "auto" }} valign="top">
                        <tbody>
                            <tr style={{ "verticalAlign": "top" }} valign="top">
                                <td style={{ "wordBreak": "breakWord", "verticalAlign": "top", "background": "#ddd" }} valign="top">
                                    <div style={{ "backgroundColor": "#ddd" }}>
                                        <div style={{ "backgroundColor": "transparent" }}>
                                            <div className="block-grid" style={{ "Margin": "0 auto", "minWidth": "320px", "maxWidth": "490px", "overflowWrap": "break-word", "wordWrap": "break-word", "wordBreak": "breakWord", "backgroundColor": "#F6F6F4" }}>
                                                <div style={{ "borderCollapse": "collapse", "display": "table", "width": "100%", "backgroundColor": "#F6F6F4" }}>

                                                    <div className="col num12" style={{ "minWidth": "320px", "maxWidth": "490px", "display": "table-cell", "verticalAlign": "top", "width": "490px" }}>
                                                        <div style={{ "width": "100% !important" }}>
                                                            <div style={{ "borderTop": "0px solid transparent", "background": "#d9d9d9", "borderLeft": "0px solid transparent", "borderBottom": "0px solid transparent", "borderRight": "0px solid transparent", "paddingTop": "5px", "paddingBottom": "5px", "paddingRight": "0px", "paddingLeft": "0px" }}>

                                                                {/* <div style={{"color":"#555555",  "fontFamily":"Arial, 'Helvetica Neue', Helvetica, sans-serif","lineHeight":"150%", "paddingTop":"10px", "paddingRight":"10px", "paddingBottom":"10px","paddingLeft":"10px"}}>
                                                                                    <div style={{"fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "fontSize": "12px", "lineHeight": "18px", "color": "#555555"}}>
                                                                                       <p style={{"fontSize": "14px", "lineHeight": "21px", "margin": "0"}}><strong><span style={{"fontSize": "18px", "lineHeight": "27px"}}>Subject Line &lt;Email&gt;</span><br/><span style={{"fontSize": "18px", "lineHeight": "27px"}}>See why John Smith wants a lender on </span><br/><span style={{"fontSize": "18px", "lineHeight": "27px"}}>Homematchx</span><br/></strong></p>
                                                                                    </div>
                                                                                 </div> */}

                                                                <div align="left" className="img-container left fixedwidth" style={{ "paddingRight": "0px", "paddingLeft": "0px" }}>
                                                                    <a href={`${host}/`} target="_blank" rel="noopener noreferrer"> <img alt="logo" border="0" className="left fixedwidth" src={`https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/images%2Flogos%2Flogo.png?alt=media&token=c078ea4a-100a-47af-9356-eaebac6346c3`} style={{ "textDecoration": "none", "-msInterpolationMode": "bicubic", "height": "auto", "border": "none", "width": "100%", "maxWidth": "208px", "display": "block" }} title="Image" width="208" /></a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ "backgroundColor": "transparent" }}>
                                            <div className="block-grid" style={{ "Margin": "0 auto", "minWidth": "320px", "maxWidth": "490px", "overflowWrap": "break-word", "wordWrap": "break-word", "wordBreak": "break-word", "backgroundColor": "#F6F6F4" }}>
                                                <div style={{ "borderCollapse": "collapse", "display": "table", "width": "100%", "backgroundColor": "#F6F6F4" }}>

                                                    <div className="col num12" style={{ "minWidth": "320px", "maxWidth": "490px", "display": "table-cell", "verticalAlign": "top", "width": "490px" }}>
                                                        <div style={{ "backgroundColor": "#ed635e", "width": "100% !important" }}>

                                                            <div style={{ "borderTop": "0px solid transparent", "borderLeft": "0px solid transparent", "borderBottom": "0px solid transparent", "borderRight": "0px solid transparent", "paddingTop": "5px", "paddingBottom": "5px", "paddingRight": "0px", "paddingLeft": "0px" }}>

                                                                <div style={{ "color": "#555555", "fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "lineHeight": "150%", "paddingTop": "10px", "paddingRight": "10px", "paddingBottom": "10px", "paddingLeft": "10px" }}>
                                                                    <div style={{ "fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "fontSize": "12px", "lineHeight": "18px", "color": "#555555" }}>
                                                                        <p style={{ "fontSize": "14px", "lineHeight": "21px", "margin": "0" }}><strong><span style={{ "color": "#ffffff", "fontSize": "18px", "lineHeight": "27px" }}>Great news! {inputs.buyer_name} has been added as a Buyer and requested you to be their representing Lender.</span></strong></p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ "backgroundColor": "transparent" }}>
                                            <div className="block-grid two-up" style={{ "Margin": "0 auto", "minWidth": "320px", "maxWidth": "490px", "overflowWrap": "break-word", "wordWrap": "break-word", "wordBreak": "break-word", "backgroundColor": "#FFFFFF" }}>
                                                <div style={{ "borderCollapse": "collapse", "display": "table", "width": "100%", "backgroundColor": "#FFFFFF", "maxWidth": "490px" }}>
                                                    <table style={{ "background": "#fff" }}>
                                                        <tr>
                                                            <td>
                                                                <div className="col num6" style={{ "maxWidth": "320px", "minWidth": "240px", "display": "table-cell", "verticalAlign": "top", "width": "240px" }}>
                                                                    <div style={{ "width": "100% !important" }}>

                                                                        <div style={{ "borderTop": "0px solid transparent", "borderLeft": "0px solid transparent", "borderBottom": "0px solid transparent", "borderRight": "0px solid transparent", "paddingTop": "55px", "paddingBottom": "55px", "paddingRight": "0px", "paddingLeft": "0px" }}>

                                                                            <div align="center" className="img-container center autowidth fullwidth" style={{ "paddingRight": "0px", "paddingLeft": "0px" }}>
                                                                                <img align="center" alt="buyer" border="0" className="center autowidth fullwidth" src={buyerImageUrl} style={{ "textDecoration": "none", "-msInterpolationMode": "bicubic", "border": "0", "height": "auto", "width": " 100%", "maxWidth": "194px", "display": "block" }} title="Image" width="194" />
                                                                            </div>

                                                                            <div style={{ "color": "#555555", "fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "lineHeight": "180%", "paddingTop": "10px", "paddingRight": "10px", "paddingBottom": "10px", "paddingLeft": "22px" }}>
                                                                                <div style={{ "fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "fontSize": "12px", "lineHeight": "21px", "marginTop": "10px", "color": "#555555" }}>
                                                                                    <p style={{ "fontSize": "14px", "lineHeight": "32px", "margin": "0" }}><span style={{ "fontSize": "18px", "color": "#000000" }}>When you confirm a<br /> home buyers lending qualification you are <br />telling every seller <br />they are in great <br />hands.</span></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="col num6" style={{ "maxWidth": "320px", "minWidth": "240px", "display": "table-cell", "verticalAlign": "top", "width": "240px" }}>
                                                                    <div style={{ "backgroundColor": "#ffffff", "width": "100% !important" }}>
                                                                        <div style={{ "borderTop": "0px solid transparent", "borderLeft": "0px solid transparent", "borderBottom": "0px solid transparent", "borderRight": "0px solid transparent", "paddingTop": "25px", "paddingBottom": "25px", "paddingRight": "5px", "paddingLeft": "5px" }}>

                                                                            <div style={{ "color": "#555555", "fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "lineHeight": "150%", "paddingTop": "10px", "paddingRight": "10px", "paddingBottom": "10px", "paddingLeft": "10px" }}>
                                                                                <div style={{ "fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "fontSize": "12px", "lineHeight": "18px", "color": "#555555" }}>
                                                                                    <div style={{ "background": "#d9d9d9", "padding": "25px 10px" }}>
                                                                                        <p style={{ "fontSize": "14px", "lineHeight": "27px", "margin": "0" }}><span style={{ "color": "#000000", "fontSize": "21px" }}>Would you like to <br />add your stamp of<br /> approval to their <br />profile? <br /><br /><a href={`${host}/lenders/${leadid}/approval`} rel="noopener noreferrer" style={{ "textDecoration": "none", "color": "#103fd6" }} target="_blank">Click here</a> to<br /> confirm their <br /> lender qualification<br /> for a home loan.<br /><br />Your validation <br />makes a buyer standout.</span></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div style={{ "backgroundColor": "transparent" }}>
                                                <div className="block-grid" style={{ "Margin": "0 auto", "minWidth": "320px", "maxWidth": "490px", "overflowWrap": "break-word", "wordWrap": "break-word", "wordBreak": "break-word", "backgroundColor": "#F6F6F4" }}>
                                                    <div style={{ "borderCollapse": "collapse", "display": "table", "width": "100%", "backgroundColor": "#F6F6F4" }}>

                                                        <div className="col num12" style={{ "minWidth": "320px", "maxWidth": "490px", "display": "table-cell", "verticalAlign": "top", "width": "490px" }}>
                                                            <div style={{ "width": "100% !important" }}>

                                                                <div style={{ "borderTop": "0px solid transparent", "borderLeft": "0px solid transparent", "borderBottom": "0px solid transparent", "borderRight": "0px solid transparent", "paddingTop": "5px", "paddingBottom": "5px", "paddingRight": "0px", "paddingLeft": "0px" }}>

                                                                    <div style={{ "color": "#555555", "fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "lineHeight": "120%", "paddingTop": "22px", "paddingRight": "10px", "paddingBottom": "22px", "background": "#d9d9d9", "paddingLeft": "10px" }}>
                                                                        <div style={{ "fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "fontSize": "18px", "lineHeight": "14px", "color": "#555555" }}>
                                                                            <p style={{ "fontSize": "14px", "color": "#000000", "lineHeight": "22px", "fontWeight": "bold", "margin": "11px 0" }}>
                                                                                By receiving this email and/or utilizing the contents. you
                                                                                agree to our <a href={`${host}/terms_of_use`} style={{ "color": "#103fd6", "textDecoration": "none" }}> Terms of Services.</a>

                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </center>
            </body>
        </html>
    }

    searchLenderAndSendMail = (inputs, buyerId, hasPhoto) => {
        const { lenderChanged } = this.state;
        // exit if lender is not changed.
        if (!this.newRecord()) {
            if (!lenderChanged) {
                return this.props.history.push('/buyers');
            }
        }
        const db = firebase.firestore()
        let lenderDetails
        let buyerImage;
        db.collection('lenders').where('nmls_id', '==', inputs.lender_nmls_id).get()
            .then(snapshot => {
                let { docs } = snapshot
                if (docs.length) {
                    lenderDetails = docs[0].data()
                    this.setState({ lender: lenderDetails })
                    if (hasPhoto) {
                        const storageRef = firebase.storage().ref()
                        const buyerImageRef = storageRef.child(`images/users/${buyerId}.jpg`)
                        buyerImageRef.getDownloadURL()
                            .then(url => {
                                buyerImage = url

                                let leadDataObject = {
                                    requestor_email: inputs.buyer_email,
                                    requestor_name: inputs.buyer_name,
                                    requestor_id: buyerId,
                                    lender_id: docs[0].id,
                                    lender_nmls_id: lenderDetails.nmls_id,
                                    lender_email: lenderDetails.lender_email,
                                    lender_name: lenderDetails.lender_name,
                                    lender_company: lenderDetails.lender_company,
                                    has_lender_responded: false
                                }

                                db.collection('buyers').doc(buyerId).update({
                                    lender_email: lenderDetails.lender_email
                                });

                                db.collection('lenderleads').add(leadDataObject).then(leadRef => {
                                    const sendEmailToLender = firebase.functions().httpsCallable('sendEmailToLender');

                                    logEvent('lender_represent_email', {
                                        leadid: leadRef.id,
                                        buyerName: inputs.buyer_name,
                                        lenderEmail: lenderDetails.lender_email,
                                    });

                                    const body = ReactDOMServer.renderToStaticMarkup(this.lendersEmail(inputs, leadRef.id, buyerImage))
                                    sendEmailToLender({ email: lenderDetails.lender_email, body }).then((result) => {
                                        // Read result of the Cloud Function.
                                        this.props.history.push('/buyers')
                                        toast.success("Mail to lender sent successfuly", {
                                            position: toast.POSITION.TOP_RIGHT
                                        });
                                    });
                                }).catch(error => {
                                    if (this.mounted) {
                                        this.setState({ busy: false })
                                    }
                                    console.error(error)
                                })
                            }).catch(error => { })
                    } else {
                        let leadDataObject = {
                            requestor_email: inputs.buyer_email,
                            requestor_name: inputs.buyer_name,
                            requestor_id: buyerId,
                            lender_id: docs[0].id,
                            lender_nmls_id: lenderDetails.nmls_id,
                            lender_email: lenderDetails.lender_email,
                            lender_name: lenderDetails.lender_name,
                            lender_company: lenderDetails.lender_company,
                            has_lender_responded: false
                        }

                        db.collection('buyers').doc(buyerId).update({
                            lender_email: lenderDetails.lender_email
                        });

                        db.collection('lenderleads').add(leadDataObject).then(leadRef => {
                            const sendEmailToLender = firebase.functions().httpsCallable('sendEmailToLender');
                            const { storageBucket } = firebaseConfig;
                            const image = inputs.photo || `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/images%2Fusers%2Fface-placeholder.gif?alt=media&token=2adbb35c-806c-4cd3-b15a-5e306e5b824b`;

                            logEvent('lender_represent_email', {
                                leadid: leadRef.id,
                                buyerName: inputs.buyer_name,
                                lenderEmail: lenderDetails.lender_email,
                            });

                            const body = ReactDOMServer.renderToStaticMarkup(this.lendersEmail(inputs, leadRef.id, image))
                            sendEmailToLender({ email: lenderDetails.lender_email, body }).then((result) => {
                                // Read result of the Cloud Function.
                                this.props.history.push('/buyers')
                                toast.success("Mail to lender sent successfuly", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            });
                        }).catch(error => {
                            if (this.mounted) {
                                this.setState({ busy: false })
                            }
                            console.error(error)
                        })
                    }
                } else {
                    this.props.history.push('/buyers')
                    toast.error("Could not find lender", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

            }, error => {
                console.error(error)
            })
    }

    uploadGallery(id, gallery, photos, newOrder, key) {

        const { inputs: { galleryCheckbox } } = this.state;

        return new Promise((resolve, reject) => {
            const db = firebase.firestore();
            const storage = firebase.storage();
            const propertiesRef = storage.ref().child(`images/properties/${id}`)

            const added = photos.filter(p => p.change === 'added')
            const deleted = photos.filter(p => p.change === 'deleted' && p.url)

            let addDone = false
            let deleteDone = false

            if (added.length) {
                Promise.all(added.map(photo => {
                    const photoId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                    const sellerImageRef = propertiesRef.child(`${photoId}.jpg`)
                    return sellerImageRef.put(photo.image)
                })).then(snapshots => {
                    Promise.all(snapshots.map(snapshot => snapshot.ref.getDownloadURL())).then(urls => {
                        const photos = gallery || []
                        const newPhotos = urls.map((url, index) => {
                            const p = { url: url, path: snapshots[index].ref.location.path, order: index }
                            return p
                        })
                        let newOrderedPhotos = []
                        if (newOrder) {
                            newOrder.map((photo, i) => {
                                photo.order = i
                                return photo
                            })
                            newOrder.map(photo => {
                                photos.map(galleryPhoto => {
                                    if (galleryPhoto.url === photo.url) {
                                        newOrderedPhotos[photo.order] = galleryPhoto
                                    }
                                    return galleryPhoto
                                })
                                return photo
                            })
                            let addedCount = 0
                            newOrder.map((photo, index) => {
                                if (!photo.url) {
                                    newOrderedPhotos[index] = newPhotos[addedCount]
                                    addedCount++
                                }
                                return photo
                            })
                        } else {
                            newPhotos.forEach((photo, i) => {
                                newOrderedPhotos[i] = photo
                            })
                        }
                        const updates = {
                            [key]: newOrderedPhotos
                        }

                        let matchKey = 'gallery';

                        if (!galleryCheckbox) {
                            matchKey = 'homeGallery';
                        }

                        if (key === matchKey) {
                            console.log('matchKey', matchKey);
                            updates.featured_image = newOrderedPhotos[0];
                        }

                        db.collection("buyers").doc(id).update(updates).then(() => {
                            addDone = true
                            if (deleted.length === 0 || deleteDone) {
                                resolve();
                            }
                        })
                    })
                }).catch(reject)
            }

            if (deleted.length) {
                const deletedPaths = deleted.map(p => p.path)
                const newPhotos = gallery.filter(p => deletedPaths.indexOf(p.path) === -1)

                db.collection("buyers").doc(id).update({ [key]: newPhotos }).then(() => {
                    Promise.all(deletedPaths.map(path => storage.ref().child(path).delete()))
                        .then(() => {
                            if (!added.length || addDone) {
                                resolve();
                            }
                        })
                        .catch(error => {
                            if (!added.length || addDone) {
                                resolve();
                            }
                        })
                })
            }
        });
    }

    getCombinations = (chars) => {
        var result = [];
        var f = function (prefix, chars) {
            for (var i = 0; i < chars.length; i++) {
                result.push(prefix + ' ' + chars[i]);
                f(prefix + ' ' + chars[i], chars.slice(i + 1));
            }
        }
        f('', chars);
        return result.map(s => s.trim());
    }

    save = async (e, validate = true) => {
        if (e && validate) {
            e.target.className += ' submitted';
            e.preventDefault();
        }

        const db = firebase.firestore()
        const { inputs, location, location_2, location_3, location_4, location_5, buyerEmailChanged } = this.state;
        const tags = this.state.tags || [];

        const { user } = this.props;

        if (validate && (user.role !== 'realtor') && !inputs.represendtedBy) {
            return this.setState({ error: 'Represented By field is required' });
        }

        if (validate && (!this.validate(inputs))) {
            return this.setState({ error: 'Some fields are invalid. Please check the form and try again.' })
        }

        inputs.draft = (!validate);

        let realtorData = {};
        if (user.role === 'realtor') {
            const doc = await db.collection('realtors').doc(user.uid).get();
            realtorData = { ...doc.data() }
        }

        this.setState({ busy: true, savingDraft: !validate })
        const photo = inputs.photo
        delete inputs.photo
        inputs.buyer_id = user.uid;

        const galleryKeys = [
            'gallery',
            'homeGallery',
            'bedroomsGallery',
            'kitchenGallery',
            'bathroomGallery',
            'backyardGallery',
            'familyRoomGallery',
            'diningRoomGallery',
            'laundryRoomGallery',
            'mediaRoomGallery',
            'gameRoomGallery',
            'garageGallery',
        ];

        const updatedGalleriesObject = {};

        galleryKeys.forEach((key) => {
            updatedGalleriesObject[key] = inputs[key] || []
            delete inputs[key]
        });

        if (user.role === 'realtor') {
            inputs.represendtedBy = this.props.user.email;
        }

        let subdivision = inputs.subdivision.split(' ');
        subdivision = this.getCombinations(subdivision);

        let search = [
            ...subdivision,
            ...inputs.cities,
            ...inputs.states,
            ...inputs.zipcodes
        ];

        search = search.map(s => s.toLowerCase()).filter(s => s !== '');

        inputs.home_hmxid = (inputs.home_hmxid || '').toUpperCase();
        inputs.location = location || {};
        inputs.location_2 = location_2 || {};
        inputs.location_3 = location_3 || {};
        inputs.location_4 = location_4 || {};
        inputs.location_5 = location_5 || {};

        tags.forEach(({ value: t }) => {
            const chars = t.toLowerCase().split('');
            let string = '';
            chars.forEach(c => {
                string += c;
                search.push(string);
            });
        });

        inputs.search = search;
        inputs.tags = tags;

        inputs.secondOwner = this.props.user.uid;

        if (this.newRecord()) {
            inputs.removed = false;
            inputs.owner = this.props.user.uid;
            db.collection('buyers').add(inputs).then(buyerRef => {
                if (inputs.userInvited && validate && sendBuyerSellerEmail) {
                    sendBuyerSellerEmail({ ...this.props.user, ...realtorData }, 'buyer', {
                        id: buyerRef.id,
                        email: inputs.buyer_email,
                    });
                }

                const promieses = [];

                galleryKeys.forEach(key => {
                    const changed = updatedGalleriesObject[key].filter(p => p.change);
                    if (changed.length) {
                        const pr = this.uploadGallery(buyerRef.id, [], changed, undefined, key);
                        promieses.push(pr);
                    }
                });

                Promise.all(promieses).then(() => {
                    this.props.history.push('/buyers');
                }).catch(error => {
                    this.setState({ busy: false })
                    console.error(error);
                });

                if (typeof (photo) !== 'string') {
                    this.uploadPhoto(buyerRef.id, photo, inputs)
                } else {
                    inputs.photo = photo;
                    if (inputs.is_lender_available === 'Yes') {
                        this.searchLenderAndSendMail(inputs, buyerRef.id, false)
                    }
                    else {
                        this.props.history.push('/buyers')
                    }
                }
            }).catch(error => {
                if (this.mounted) {
                    this.setState({ busy: false })
                }
                console.error(error)
            })
        } else {
            db.collection("buyers").doc(this.id()).update(inputs).then(() => {
                if (inputs.userInvited && validate && buyerEmailChanged) {
                    sendBuyerSellerEmail({ ...this.props.user, ...realtorData }, 'buyer', {
                        id: this.id(),
                        email: inputs.buyer_email,
                    });
                }

                const promieses = [];

                galleryKeys.forEach(key => {
                    const newOrder = updatedGalleriesObject[key];
                    const changed = updatedGalleriesObject[key].filter(p => p.change);
                    if (changed.length) {
                        db.collection("buyers").doc(this.id()).get().then(buyerRef => {
                            const buyerData = buyerRef.data();
                            const pr = this.uploadGallery(buyerRef.id, buyerData[key], changed, newOrder, key);
                            promieses.push(pr);
                        })
                    } else if (newOrder.length) {
                        db.collection("buyers").doc(this.id()).get().then(buyerRef => {
                            const buyerData = buyerRef.data();
                            const pr = this.uploadSortedGallery(buyerRef.id, buyerData[key], newOrder, key);
                            promieses.push(pr);
                        })
                    }
                });

                Promise.all(promieses).then(() => {
                    this.props.history.push('/buyers');
                }).catch(error => {
                    this.setState({ busy: false })
                    console.error(error);
                });

                if (typeof (photo) !== 'string') {
                    this.uploadPhoto(this.id(), photo, inputs)
                } else {
                    inputs.photo = photo;
                    if (inputs.is_lender_available === 'Yes') {
                        this.searchLenderAndSendMail(inputs, this.id(), false)
                    }
                    else {
                        this.props.history.push('/buyers')
                    }
                }
            }).catch(error => {
                if (this.mounted) {
                    this.setState({ busy: false })
                }
                console.error(error)
            })
        }
    }

    uploadSortedGallery(id, gallery, photos, key) {

        const { inputs: { galleryCheckbox } } = this.state;

        return new Promise((resolve, reject) => {
            if (photos) {
                let gallery = photos.map((photo, i) => {
                    photo.featured = (i === 0);
                    return photo
                });

                const update = {
                    [key]: gallery
                };

                let matchKey = 'gallery';

                if (!galleryCheckbox) {
                    matchKey = 'homeGallery';
                }

                if (key === matchKey) {
                    console.log('matchKey', matchKey);
                    update.featured_image = gallery.length > 0 ? { ...gallery[0] } : {};
                }

                const db = firebase.firestore();
                db.collection("buyers").doc(id).update(update).then(resolve).catch(reject)
            } else {
                resolve();
            }
        });
    }

    error() {
        const { error } = this.state;

        if (!error) {
            return null;
        }

        return <div style={{ marginLeft: '25px', marginRight: '25px' }} className="alert alert-danger">{error}</div>
    }

    uploadPhoto(id, photo, inputs) {
        const storage = firebase.storage()
        const usersRef = storage.ref().child('images/users')

        const buyerImageRef = usersRef.child(`${id}.jpg`)

        if (photo) {
            buyerImageRef.putString(photo.preview, 'data_url')
                .then((imgRef) => { this.searchLenderAndSendMail(inputs, id, true) })
                .catch(error => {
                    if (this.mounted) {
                        this.setState({ busy: false })
                    }
                    console.error(error)
                })
        }
        else {
            buyerImageRef.delete()
                .then(() => this.props.history.push('/buyers'))
                .catch(error => this.props.history.push('/buyers'))
        }
    }

    toggleCheckbox = (e) => {
        const { inputs } = this.state
        inputs[e.target.name] = e.target.checked
    }

    setValue(inputId, value) {
        const { inputs } = this.state;
        const lenderChanged = inputId === 'lender_nmls_id' && (value !== inputs.lender_nmls_id);
        const buyerEmailChanged = inputId === 'buyer_email' && (value !== inputs.buyer_email);
        inputs[inputId] = value;
        if (this.mounted) {
            this.setState({ inputs, lenderChanged, buyerEmailChanged });
        }
    }

    buttons(size) {
        const { busy, savingDraft } = this.state
        const disabled = busy

        const buttonText = (draft, suffix = '', prefix = '') => {
            return busy && (savingDraft === draft) ? 'Saving' : this.newRecord() ? `${prefix || 'Create'} ${suffix}`.trim() : `${prefix || 'Save'} ${suffix}`.trim()
        }

        return <React.Fragment>

            {(() => {
                if (!this.newRecord()) {
                    const { inputs } = this.state;
                    const { draft } = inputs;
                    const prefix = draft ? 'Activate Profile' : 'Hide Profile';
                    const color = draft ? 'success' : 'danger';
                    return <React.Fragment>
                        <button
                            className={`btn btn-${size} btn-${color}`}
                            onClick={(e) => { this.save(e, draft) }}
                            disabled={disabled}>
                            {buttonText(true, '', prefix)}
                        </button>
                        &nbsp;
                    </React.Fragment>
                }
            })()}

            <button
                className={`btn btn-${size} btn-info`}
                onClick={(e) => { this.save(e, false) }}
                disabled={disabled}>
                {buttonText(true, 'draft')}
            </button>
            &nbsp;
            <button
                onClick={(e) => { this.mainForm.current.className += ' submitted'; this.save(e, true); }}
                className={`btn btn-${size} button-theme`}
                disabled={disabled}>
                {buttonText(false)}
            </button>
        </React.Fragment>
    }

    onLocationChange = (location) => {
        this.setState({
            location
        });
    }

    onTagsChange = (tags) => {
        this.setState({ tags });
    }

    render() {
        const { loading, showLoading, inputs, location, tags, location_2, location_3, location_4, location_5 } = this.state;

        const { user } = this.props;
        const emails = user.agent_emails || [];
        let formFields = {
            ...buyerFields,
            location: {
                type: 'custom',
                label: 'Select Primary Search Location',
                width: 12,
                jsx: (
                    <LocationModal
                        selectRadius
                        location={location}
                        title="Select Primary Search Location"
                        placeholder="Select Primary Search Location"
                        centerAroundCurrentLocation onLocationChange={this.onLocationChange}
                        style={{ width: '93%', height: '320px', marginTop: '10px' }}
                        modalStyle={{ width: '100%', height: '400px' }}
                    />
                ),
            },
            location_2: {
                type: 'custom',
                label: 'Select Second Search Location',
                width: 12,
                jsx: (
                    <LocationModal
                        selectRadius
                        location={location_2}
                        title="Select Second Search Location"
                        placeholder="Select Second Search Location"
                        centerAroundCurrentLocation onLocationChange={location => {
                            this.setState({
                                location_2: location
                            });
                        }}
                        style={{ width: '93%', height: '320px', marginTop: '10px' }}
                        modalStyle={{ width: '100%', height: '400px' }}
                    />
                ),
            },
            location_3: {
                type: 'custom',
                label: 'Select Third Search Location',
                width: 12,
                jsx: (
                    <LocationModal
                        selectRadius
                        location={location_3}
                        title="Select Third Search Location"
                        placeholder="Select Third Search Location"
                        centerAroundCurrentLocation onLocationChange={location => {
                            this.setState({
                                location_3: location
                            });
                        }}
                        style={{ width: '93%', height: '320px', marginTop: '10px' }}
                        modalStyle={{ width: '100%', height: '400px' }}
                    />
                ),
            },
            location_4: {
                type: 'custom',
                label: 'Select Fourth Search Location',
                width: 12,
                jsx: (
                    <LocationModal
                        selectRadius
                        location={location_4}
                        title="Select Fourth Search Location"
                        placeholder="Select Fourth Search Location"
                        centerAroundCurrentLocation onLocationChange={location => {
                            this.setState({
                                location_4: location
                            });
                        }}
                        style={{ width: '93%', height: '320px', marginTop: '10px' }}
                        modalStyle={{ width: '100%', height: '400px' }}
                    />
                ),
            },
            location_5: {
                type: 'custom',
                label: 'Select Fifth Search Location',
                width: 12,
                jsx: (
                    <LocationModal
                        selectRadius
                        location={location_5}
                        title="Select Fifth Search Location"
                        placeholder="Select Fifth Search Location"
                        centerAroundCurrentLocation onLocationChange={location => {
                            this.setState({
                                location_5: location
                            });
                        }}
                        style={{ width: '93%', height: '320px', marginTop: '10px' }}
                        modalStyle={{ width: '100%', height: '400px' }}
                    />
                ),
            },
            tags: {
                type: 'custom',
                label: 'Select Tags',
                width: 12,
                jsx: <TagSelect name="tags" defaultValue={tags} onChange={this.onTagsChange} />,
            },
        };

        if (user.role !== 'realtor') {
            formFields = {
                ...formFields,
                represendtedBy: {
                    label: 'Represented by',
                    required: true,
                    options: ['Self Represented', ...emails]
                }
            };
        }

        if (loading) {
            if (showLoading) {
                return <div className="content-area5 dashboard-content">
                    <div className="submit-address dashboard-list"><div className="col-lg-4 col-md-12 col-sm-12"><p>Loading</p></div></div>
                </div>
            }
            else {
                return null
            }
        }

        const quickSections = [
            'Basic Information',
            'Buyer Information',
            'Home Buyer Video',
            'Buyer Image',
            'Dream Board.',
            'Dream Board',
        ];

        return (
            <LoadingOverlay
                active={this.state.busy}
                spinner
                text='Saving...'
            >
                <div className="content-area5 dashboard-content">
                    <div className="dashboard-header clearfix">
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <h4>Buyer</h4>
                            </div>
                            <div className="col-sm-6" style={{ textAlign: 'right' }}>
                                {this.buttons('sm')}
                            </div>
                        </div>
                    </div>
                    <div className="submit-address dashboard-list">
                        <form onSubmit={() => { }} ref={this.mainForm}>
                            <PartitionedForm fields={formFields} sections={sections} quickSections={quickSections} setValue={this.setValue.bind(this)} values={this.state.inputs} />
                            <div className="row" style={{ paddingLeft: 25 }}>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label><input type="checkbox" name="dontDisplayContactInfo" defaultChecked={inputs.dontDisplayContactInfo} onChange={this.toggleCheckbox} /> Do not show Email/Phone Number</label><br />
                                    <label><input type="checkbox" name="userInvited" defaultChecked={inputs.userInvited} onChange={this.toggleCheckbox} /> Invite this user to homematchx</label><br />
                                    {this.newRecord() ? <label><input type="checkbox" required /> This person gave me permission to represent them on Homematchx.</label> : null}
                                </div>
                            </div>

                            {this.error()}
                        </form>

                        <div className="row" style={{ paddingLeft: 25 }}>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                {this.buttons('md')}
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        )
    }
}

export default BuyerProfile
