import React, { Component } from "react";

import {
  Label,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";

class ConfirmationModal extends Component {
  state = {
    isOpen: false,
    declineOthers: false,
    comment: "",
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      declineOthers: false,
    });
  };

  toggleCheckbox = () => {
    this.setState({ declineOthers: !this.state.declineOthers });
  };

  confirm = () => {
    this.props.action(this.state.declineOthers, this.state.comment);
    this.toggle();
  };

  renderBtnText = () => {
    const { children } = this.props;
    if (children) {
      return children;
    }
    return (
      <React.Fragment>
        <i className="fa fa-notes" />
      </React.Fragment>
    );
  };

  render() {
    let {
      title,
      message,
      modalColor,
      showCheckbox,
      checkLabel,
      showCommentBox,
    } = this.props;

    let { comment } = this.state;

    return (
      <React.Fragment>
        <span onClick={this.toggle}>{this.renderBtnText()}</span>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className={`modal-${modalColor || "primary"}`}
        >
          <ModalHeader toggle={this.toggle}> {title || ""}</ModalHeader>
          <ModalBody>
            <Label>{message || ""}</Label>
            {!!showCheckbox && (
              <Label>
                <input
                  type="checkbox"
                  name="declineOthers"
                  className="mr-2"
                  checked={this.state.declineOthers}
                  onChange={this.toggleCheckbox}
                />
                {checkLabel}
              </Label>
            )}
            {!!showCommentBox && (
              <div className="accept-comment-container">
                <Label className="mt-0">Reason for accepting the Offer</Label>
                <textarea
                  className="accept-comment"
                  name="acceptComment"
                  rows="3"
                  // placeholder="Reason for accepting the Offer"
                  value={comment}
                  onChange={(e) => this.setState({ comment: e.target.value })}
                />
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ comment: "Only one offer received" });
                  }}
                >
                  Only one offer received
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ comment: "Highest and best offer" });
                  }}
                >
                  Highest and best offer
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ comment: "Better closing date" });
                  }}
                >
                  Better closing date
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ comment: "Better contract terms" });
                  }}
                >
                  Better contract terms
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ comment: "No contingencies" });
                  }}
                >
                  No contingencies
                </button>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color={modalColor || "primary"} onClick={this.confirm}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ConfirmationModal;
