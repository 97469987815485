import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import moment from "moment";
import Replay from "../../assets/icons/replay_arrow.svg";
import ReplayWhite from "../../assets/icons/replay_arrow_white.svg";
import Tick from "../../assets/icons/tick.svg";
import Cross from "../../assets/icons/cross.svg";
import Person from "../../assets/icons/person.svg";
import Calendar from "../../assets/icons/calendar.svg";
import Flag from "../../assets/icons/flag-21.svg";
import VerifiedListing from "../../assets/icons/verified-icon.svg";
import AgentInformationModal from "../../components/Analytics/AgentInformationModal";

class OfferListItem extends Component {
  renderStatus = (status, isBuyer, viewed) => {
    let statusClass = "";
    let prependIcon = "";
    if (status === "In Review") {
      statusClass = "in-review";
      prependIcon = Replay;
    } else if (status === "Accepted") {
      statusClass = "accepted";
      prependIcon = Tick;
    } else if (status === "Not Accepted") {
      statusClass = "declined";
      prependIcon = Cross;
    } else if (status === "Deleted") {
      statusClass = "declined";
      prependIcon = Cross;
    } else if (status === "Executed") {
      statusClass = "executed";
      prependIcon = Tick;
    } else if (status === "Withdraw" || status === "Terminated") {
      statusClass = "withdrawn";
      prependIcon = Tick;
    } else if (status === "Seller Review") {
      statusClass = "seller-review";
      prependIcon = ReplayWhite;
    } else if (status === "Countered") {
      statusClass = "accepted";
      prependIcon = ReplayWhite;
    }

    if (status == "Executed") {
      return (
        <>
          <p className={`accepted align-items-center`}>
            <button
              style={{
                paddingTop: "4px",
                paddingBottom: "22px",
              }}
            >
              <img src={prependIcon} alt="prepend icon" />
              Accepted
            </button>

            <button
              className="ml-2"
              style={{
                paddingTop: "4px",
                paddingBottom: "22px",
              }}
            >
              <img src={prependIcon} alt="prepend icon" />
              Executed
            </button>

            <button
              className="ml-2"
              style={{
                backgroundColor: "#9C9D9E",
                color: "white",
                paddingTop: "4px",
                paddingBottom: "22px",
              }}
            >
              {isBuyer ? "Received" : "Sent"}
            </button>
            {viewed && <span className="is-viewed">Viewed</span>}
          </p>
        </>
      );
    }

    return (
      <p className={`${statusClass} align-items-center`}>
        <button
          style={{
            paddingTop: "4px",
            paddingBottom: "22px",
          }}
        >
          <img src={prependIcon} alt="prepend icon" />
          {status === "Withdraw" ? "Withdrawn" : status}
        </button>
        <button
          className="ml-2"
          style={{
            backgroundColor: "#9C9D9E",
            color: "white",
            paddingTop: "4px",
            paddingBottom: "22px",
          }}
        >
          {isBuyer ? "Recieved" : "Sent"}
        </button>
        {viewed && <span className="is-viewed">Viewed</span>}
      </p>
    );
  };

  render() {
    const {
      offer,
      user,
      curItem,
      totalItems,
      bestMatch,
      sellers,
      allOffers,
      isGlobal,
    } = this.props;

    const {
      status,
      id,
      viewed,
      address,
      brokerageName,
      createdAt,
      purchasePrice,
      viewedAt,
      viewedBy,
      verified,
    } = offer;
    const isBuyer = offer?.sellerEmail === user?.email;
    const originalPrice =
      sellers &&
      sellers.find((seller) => seller.id === offer.seller)?.current_value;
    const OfferValueInPercentage = (purchasePrice * 100) / originalPrice - 100;

    return (
      <Link
        to={
          isGlobal
            ? `/otherAgent/offer/${id}`
            : {
                pathname: `/offers/${id}`,
                search: `?curItem=${curItem}&totalItems=${totalItems}`,
                state: {
                  offer,
                  curItem,
                  totalItems,
                  allOffers,
                },
              }
        }
      >
        <Card
          className={
            bestMatch && bestMatch[0] && bestMatch[0].id === id
              ? "best-match-offers-card"
              : "custom-card"
          }
        >
          <CardBody>
            {bestMatch && bestMatch[0] && bestMatch[0].id === id && (
              <p className="best-match-tag ml--1">
                <i className="fa fa-heart"></i>BEST MATCH
              </p>
            )}
            <div
              className={
                bestMatch && bestMatch[0] && bestMatch[0].id === id
                  ? "row offers-thumbnail-view-padding"
                  : "row"
              }
            >
              <div className="col-xl-4 col-lg-4 col-md-12 house">
                <img
                  src={
                    offer.featured_image?.url || "/images/house-placeholder.jpg"
                  }
                  alt="house"
                />
              </div>
              <div className="col-xl-8 col-lg-8 col-md-12 homeoffer-details padd-lft">
                <ul>
                  <li>
                    <p className="offer">
                      Offer {curItem} of {totalItems}{" "}
                      {verified ? (
                        <img src={VerifiedListing} style={{ marginLeft: 10 }} />
                      ) : null}{" "}
                      <i
                        className={`${
                          status === "Executed" ? "ml-2" : "ml-20"
                        } new-offers-status-view`}
                      >
                        {this.renderStatus(status, isBuyer, viewed)}
                      </i>
                    </p>
                  </li>
                  <p className="new-offers-mobile-status-view">
                    {this.renderStatus(status, isBuyer, viewed)}
                  </p>
                  <li>
                    <h5>{address}</h5>
                  </li>
                  <li>
                    <img src={Person} />
                    <p>
                      buyers agent{" "}
                      <span>
                        <AgentInformationModal
                          comingFrom="offersPage"
                          key={id}
                          offer={this.props.offer}
                        />
                        , {brokerageName}
                      </span>
                    </p>
                  </li>
                  <li>
                    <img src={Calendar} />
                    <p>
                      <span>
                        {moment(createdAt * 1000).format(
                          "MMMM Do YYYY, [at] h:mm:ss a"
                        )}{" "}
                        CST ({moment(createdAt * 1000).fromNow()})
                      </span>
                    </p>
                  </li>
                  {viewedAt && (
                    <li>
                      <img src={Flag} />
                      <p>
                        The offer has been reviewed{" "}
                        {moment(viewedAt * 1000).format(
                          "MM.DD.YYYY [at] hh:mm A"
                        )}{" "}
                        CST by <span className="underline">{viewedBy}</span>
                      </p>
                    </li>
                  )}
                </ul>
                <div className="centerly offer-price-container">
                  <NumberFormat
                    className="price-tag"
                    value={purchasePrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    renderText={(value, props) => (
                      <p {...props}>
                        {value}{" "}
                        <span
                          style={{
                            color:
                              OfferValueInPercentage > 0
                                ? "#28a745"
                                : "#eb3e54",
                            fontSize: 18,
                            display:
                              OfferValueInPercentage === 0 ||
                              !OfferValueInPercentage
                                ? "none"
                                : "inline",
                          }}
                        >
                          ({OfferValueInPercentage.toFixed(2)}%)
                        </span>
                      </p>
                    )}
                  />
                  <button className="green-btn new-offers-green-btn">
                    Review Offer
                  </button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Link>
    );
  }
}

export default OfferListItem;
