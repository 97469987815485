import React, { Component } from 'react'
import SubBanner from 'components/SubBanner'

import 'firebase/firestore'

class Lenders extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    redirectLender = () => {
        this.props.history.push('/signup')
    }
    render() {
        
        return (
            <React.Fragment>
            <SubBanner
               title="Lenders"
               image="/images/mx4mSkK9zeo.jpg"
               {...this.props}
               />
            <div className="container">
               <div className="row">
                  <div className="col-sm-12">
                     <div className="align-items-center lenders-area-5">
                        <h1>Lenders</h1>
                        <button className="btn mr-3 ml-3 theme-btn" onClick={this.redirectLender}>Get started</button> 
                        <span>Already have an account? <a href="/login">Sign in</a>.</span>
                     </div>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                     <div className="Lenders-text">
                        <p>Lenders can display their approval on the buyer's profile to confirm the lending qualification. The right lender representation makes all the difference in a real estate transaction. And when a seller knows who is powering the buyer's loan approval you have gotten their attention. Displaying the lending approval allows sellers to gain the confidence they need to know a buyer is qualified.</p>
                     </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 qualified">
                     <h4>Qualified by</h4>
                     <div className="qualified-inner">
                        <span className="checkmark">
                        <img
                           src="/img/checkmark.jpg"
                           alt="checkmark"
                           />
                        </span>
                        <span className="qualified-logo">
                        <img
                           src="/img/logo-p.jpg"
                           alt="Guild mortgage"
                           />
                        </span>
                        <span className="profile-avatar">
                        <img
                           src="/img/avtar.jpg"
                           alt="Avatar"
                           />
                        </span>
                     </div>
                     <p>It is determined that the Prospective 
                        Applicant is eligible and qualified to 
                        meet the financial requirements of the loan.
                     </p>
                  </div>
               </div>
            </div>
            <div className=" lender-content-area">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="service-info">
                        {/* <div className="icon"><i className="flaticon-apartment-1"></i></div> */}
                        {/* <h3>Buyers</h3> */}
                           <p>
                              Buyers feel 
                              supported when 
                              they can count 
                              on their lender
                           </p>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="service-info">
                        {/* <div className="icon"><i className="flaticon-apartment-1"></i></div> */}
                        {/* <h3>Seller</h3> */}
                           <p>
                              Seller can gain 
                              the confidence 
                              needed to trust 
                              the process
                           </p>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="service-info">
                        {/* <div className="icon"><i className="flaticon-apartment-1"></i></div> */}
                        {/* <h3>Process</h3> */}
                           <p>
                              Be a part of the 
                              process every 
                              step of the way
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="container lenders-section">
               <h1>Benefits</h1>
               <p>Homematchx is helping shape how lender can make a substantial impact to the real estate transaction.</p>
               <p>
                  Lenders are connected to the Buyers profile and can share if their client has been qualified to purchase a home.
                  You can add your information to the profile to help make the selection process for a seller an easy choice. and 
                  with a lenders signature of approval it shows how prepared a buyers is when the right home comes along.
               </p>
               <h1>Stay Connected</h1>
               <p>A well planned process is a predictable one. with Homematchx you can keep track of your lead database and get 
                  notified when a buyers is using you or when they’ve decided to follow different path. Gaining and understanding of 
                  whose your actual client is critical to your follow up process. Never lose a customer without knowing if and when you’ve actually lost them.
               </p>
            </div>
         </React.Fragment>
        )
    }
}

export default Lenders
