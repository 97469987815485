import React, { Component } from 'react'
import { withRouter} from "react-router-dom";
import { Button } from 'reactstrap';

class HelpButton extends Component {
    helpClicked = () => {
        this.props.history.push('/help')
    }
    render() {
        return (
            <div style={{position: 'fixed',bottom: '24px',right: '65px'}}>
                <Button style={{float:'right',padding: '6px'}} color="danger" className="btn-theme" onClick={this.helpClicked}>Help</Button>
            </div>
        )
    }
}

export default withRouter(HelpButton)
