import React from "react";
import { Table } from 'reactstrap';
import {firebaseConfig} from './../Firebase/firebase';
const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const snakeCaseToTitle = str => {
  return camelToSnakeCase(str).replace(/_/g, ' ').replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

const OfferEmailTemplate = props => {
  const {
    addressLineOne,
    addressLineTwo,
    expires,
    heading,
    price,
    elements,
    offer
  } = props;

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Offer</title>
      </head>
      <body>
        <div className="email-container">
          <img
            src={`${firebaseConfig.host}/img/logos/logo.png`}
            className="logo"
            style={{ height: "40px" }}
            alt=""
          />

          {addressLineOne && (
            <p style={{ marginBottom: "2px" }}>{addressLineOne}</p>
          )}
          {addressLineTwo && (
            <p style={{ marginTop: "0px", marginBottom: "0px" }}>
              {addressLineTwo}
            </p>
          )}

          {expires && (
            <p style={{ marginTop: "5px", color: "grey" }}>{expires}</p>
          )}

          {heading && <h3 style={{ fontWeight: "200" }}>{heading}</h3>}

          {price && (
            <h2 style={{ fontWeight: "200", color: "#ff214f" }}>{price}</h2>
          )}

          {(elements || []).map((e, i) => {
            const { type } = e;
            if (type === "p") {
              return <p key={i} style={{ fontSize: "17px" }}>{e.text}</p>;
            }
            return null;
          })}

          <h3 style={{ fontWeight: "300" }}>Offer Details</h3>

          <Table>
            <tbody>
              {Object.keys(offer).map((k, i) => {
                let value = offer[k];
                value = value?.value || value;
                return <tr key={i}>
                  <th scope="row" style={{ float: 'left' }}>{snakeCaseToTitle(k)}</th>
                  <td style={{ paddingLeft: '50px' }}>{(JSON.stringify(value) || '-')}</td>
                </tr>
              })}
            </tbody>
          </Table>

        </div>
      </body>
    </html>
  );
};

export default OfferEmailTemplate;