import React, { Component } from "react";
import { Route } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import Loader from "react-loader-spinner";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

import { real } from "../utils/Functions";

import SinglePost from "../components/Blog/SinglePost";
import BlogPosts from "../components/Blog/BlogPosts";
import WriteReview from "../components/Review/WriteReview";
import { environment } from "../components/Firebase/firebase";

import AccountRoute from "./AccountRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";

import BetaAuthentication from "../pages/BetaAuthentication";
import About from "../pages/About";
import Blog from "../pages/Blog";
import Help from "../pages/Help";
import Index from "../pages/Index";
import Dashboard from "../pages/NewDashboard";
import Analytics from "../pages/Analytics";
import EmailPreview from "../pages/EmailPreview";
import OfferPreview from "../pages/OfferPreview";
import UserActivities from "../pages/UserActivities";
import AllUsers from "../pages/AllUsers";
import Favorites from "../pages/Favorites";
import Starred from "../pages/Starred";
import Network from "../pages/Network/index";
import LikedBuyers from "../pages/LikedBuyers";
import Matches from "../pages/Matches";
import Profile from "../pages/Profile";
import Streamings from "../pages/Streamings";
import Buyers from "../pages/Buyers";
import Offers from "../pages/Offers";
import OffersNew from "../pages/OffersNew";
import BuyerDetail from "../pages/BuyerDetail";
import OfferDetailsPage from "../pages/OfferDetailsPage";
import UpdateOfferPage from "../pages/UpdateOfferPage";
import PropertyPage from "../pages/PropertyPage";
import MakeOffer from "../pages/MakeOffer";
import Sellers from "../pages/Sellers";
import SoldHomes from "../pages/SoldHomes";
import Lenders from "../pages/Lenders";
import RealEstate from "../pages/RealEstate";
import FAQs from "../pages/FAQs";
import LenderApprovalForm from "../pages/LenderApprovalForm";
import SellerProfile from "../pages/SellerProfile";
import TermsOfUse from "../pages/TermsOfUse";
import BuyerProfile from "../pages/BuyerProfile";
import Login from "../pages/Login";
import PasswordReset from "../pages/PasswordReset";
import Home from "../pages/Home";
import Homes from "../pages/Homes";
import PublicBuyers from "../pages/PublicBuyers";
import Messenger from "../pages/Messenger";
import PricingPageV2 from "../pages/PricingPageV2";
import Signup from "../pages/Signup";
import Subscriptions from "pages/Subscriptions";
import LiveStreamPackages from "pages/LiveStreamPackages";
import InviteUsers from "../pages/InviteUsers";
import PublicSellersSharing from "../pages/publicSellersSharing";
import PublicBuyersSharing from "../pages/publicBuyersSharing";
import LiveBookings from "../pages/LiveBookings";
import LiveStreamChannel from "../pages/LiveStreamChannel";
import LiveStreamChannelJoin from "../pages/LiveStreamChannelJoin";
import LiveVistStream from "../pages/LiveVisitStream";
import BuyerSearchPage from "../pages/BuyerSearchPage";
import EmbedableCode from "../pages/EmbedableCode";
import MainRoute from "components/MainRoute";
import OtherAgentOffers from "pages/OtherAgentOffers";
import Reminder from "pages/Reminder";
import Subscription from "pages/Subscription";
import StepWizards from "pages/StepWizards";
import Main from "templates/Main";
import MySubscriptions from "pages/MySubscriptions";
import MySubscriptionAnalytics from "components/Analytics/MySubscriptionAnalytics";
import SendToTitlePreview from "pages/SendToTitlePreview";
import MyTransactions from "pages/MyTransactions";

toast.configure();

class Routes extends Component {
  constructor(props) {
    super(props);

    let betaEnabled = false;

    if (environment === "staging") {
      betaEnabled = localStorage.getItem("beta");
    }

    this.state = {
      error: "",
      betaEnabled,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.authStateChangedAsync(user);
      } else {
        this.setState({ user: undefined, authDone: true });
      }
    });
  }

  async authStateChangedAsync(user) {
    const db = firebase.firestore();

    const userRef = db.collection("users").doc(user.uid);

    const doc = await userRef.get();
    if (!doc.exists) {
      userRef.set({ favorites: [] }).then((doc) => {
        this.setState({ user, authDone: true });
      });
    } else {
      const data = doc.data();
      const storage = firebase.storage();
      const storageRef = storage.ref();

      const sizes = [64];
      const promises = sizes.map(() =>
        storageRef.child(`images/users/${user.uid}.jpg`).getDownloadURL()
      );
      user.profilePhoto = { placeholder: "/images/face-placeholder.png" };
      Object.assign(user, data);

      const wrappedPromises = promises.map((promise) => {
        return new Promise((resolve) => {
          promise.then(resolve).catch(() => resolve(null));
        });
      });

      let realtor = await db.collection("realtors").doc(user.uid).get();
      realtor = {
        id: realtor.id,
        ...realtor.data(),
      };

      Promise.all(wrappedPromises)
        .then((urls) => {
          for (const i in urls) {
            user.profilePhoto[sizes[i]] = urls[i];
          }
          const { role, uid } = user;

          if (!role || role === "user"|| role === "titleCompany") {
            return this.setState({ user, authDone: true });
          }

          const db = firebase.firestore();
          let ref = db.collection("realtors").doc(uid);

          if (role === "lender") {
            ref = db.collection("lenders").doc(uid);
          }

          if (role === "builder") {
            ref = db.collection("builders").doc(uid);
          }

          ref.get().then((refDocs) => {
            if (refDocs.exists) {
              this.setState({
                user: {
                  ...user,
                  ...refDocs.data(),
                  realtor,
                },
                authDone: true,
              });
            }
          });
        })
        .catch((e) => {
          this.setState({
            user: {
              ...user,
              realtor,
            },
            authDone: true,
          });
          console.error(e);
        });
    }
  }

  enableBeta() {
    if (environment === "staging") {
      localStorage.setItem("beta", true);
      this.setState({ betaEnabled: true });
    }
  }

  userUpdated(user) {
    this.setState({ user });
  }

  profileAdded(userProfile) {
    const { user } = this.state;

    Object.assign(user, userProfile);

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const sizes = [64, 128, 256];
    const promises = sizes.map((size) =>
      storageRef
        .child(`images/users/thumb@${size}_${user.uid}.jpg`)
        .getDownloadURL()
    );
    user.profilePhoto = { placeholder: "/images/face-placeholder.png" };

    Promise.all(promises)
      .then((urls) => {
        for (const i in urls) {
          user.profilePhoto[sizes[i]] = urls[i];
        }

        this.setState({ user, authDone: true });
      })
      .catch((e) => console.error(e));

    this.setState({ user });
  }
  render() {
    const functions = real;
    functions.profileAdded = (x) => this.profileAdded(x);
    functions.userUpdated = (user) => this.userUpdated(user);
    functions.logout = () => {
      // this.setState({profileState: 'loading'})
    };

    if (!this.state.authDone) {
      return (
        <Loader
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="Rings"
          color="#ff214f"
          height={200}
          width={200}
        />
      );
    } else {
      return (
        <>
          <Route
            exact
            path="/beta-authentication"
            render={(props) => (
              <BetaAuthentication
                {...props}
                enableBeta={this.enableBeta.bind(this)}
              />
            )}
          />

          {/* Main Routes */}

          <MainRoute
            exact
            path="/about"
            component={About}
            user={this.state.user}
            betaEnabled={this.state.betaEnabled}
          />
          <MainRoute
            exact
            path="/blog"
            component={Blog}
            user={this.state.user}
            betaEnabled={this.state.betaEnabled}
          />
          <MainRoute
            exact
            path="/blog/:id"
            component={SinglePost}
            user={this.state.user}
            betaEnabled={this.state.betaEnabled}
          />

          <MainRoute
            exact
            path="/buyerSearchPage"
            component={BuyerSearchPage}
            user={this.state.user}
            betaEnabled={this.state.betaEnabled}
          />
          <MainRoute
            exact
            path="/help"
            component={Help}
            user={this.state.user}
            betaEnabled={this.state.betaEnabled}
          />
          <MainRoute
            exact
            path="/terms_of_use"
            component={TermsOfUse}
            user={this.state.user}
            betaEnabled={this.state.betaEnabled}
          />
          <MainRoute
            exact
            path="/lenders"
            component={Lenders}
            user={this.state.user}
            betaEnabled={this.state.betaEnabled}
          />
          <MainRoute
            exact
            path="/realEstateServices"
            component={RealEstate}
            user={this.state.user}
            betaEnabled={this.state.betaEnabled}
          />
          <MainRoute
            exact
            path="/faqs"
            component={FAQs}
            user={this.state.user}
            betaEnabled={this.state.betaEnabled}
          />
          <MainRoute
            exact
            path="/write-review"
            component={WriteReview}
            user={this.state.user}
            betaEnabled={this.state.betaEnabled}
          />
          <MainRoute
            exact
            path="/"
            component={Index}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <MainRoute
            exact
            path="/pricing"
            component={PricingPageV2}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <MainRoute
            exact
            path="/pricingV2"
            component={PricingPageV2}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />

          <MainRoute
            exact
            path="/messenger/:id?"
            component={Messenger}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
            authenticated={true}
          />

          {/* <MainRoute
                        exact
                        path="/homes"
                        component={Homes}
                        user={this.state.user}
                        functions={functions}
                        betaEnabled={this.state.betaEnabled}
                        unauthedOnly={undefined}
                    /> */}
          <MainRoute
            exact
            path="/homes/:uid?"
            component={Homes}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <MainRoute
            exact
            path="/share/offer/:id"
            component={OfferPreview}
            user={this.state.user}
            functions={functions}
            // betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <MainRoute
            exact
            path="/otherAgent/offer/:id"
            component={OtherAgentOffers}
            user={this.state.user}
            functions={functions}
            // betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <MainRoute
            exact
            path="/search/buyers"
            component={PublicBuyers}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />

          {/* <MainRoute
                        exact
                        path="/homes/:id"
                        component={Home}
                        user={this.state.user}
                        functions={functions}
                        betaEnabled={this.state.betaEnabled}
                        unauthedOnly={undefined}
                    /> */}

          <MainRoute
            exact
            path="/buyers/:id"
            component={BuyerDetail}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />

          {this.state.user ? (
            <AccountRoute
              exact
              path="/sellers/:id"
              component={PropertyPage}
              user={this.state.user}
              functions={functions}
              betaEnabled={this.state.betaEnabled}
              unauthedOnly={undefined}
              hideSideBar={true}
            />
          ) : (
            <MainRoute
              exact
              path="/sellers/:id"
              component={PropertyPage}
              user={this.state.user}
              functions={functions}
              betaEnabled={this.state.betaEnabled}
              unauthedOnly={undefined}
            />
          )}

          <MainRoute
            exact
            path="/makeOffer/:id"
            component={MakeOffer}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <MainRoute
            exact
            path="/email-preview"
            component={EmailPreview}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <MainRoute
            exact
            path="/send-to-title-email-preview"
            component={SendToTitlePreview}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <MainRoute
            exact
            path="/offers/update/:id"
            component={UpdateOfferPage}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <MainRoute
            exact
            path="/lenders/:id/approval"
            component={LenderApprovalForm}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
            authenticated={true}
          />
          <MainRoute
            exact
            path="/share/sellers/:id"
            component={PublicSellersSharing}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={false}
          />
          <MainRoute
            exact
            path="/share/buyers/:id"
            component={PublicBuyersSharing}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={false}
          />
          <MainRoute
            exact
            path="/live-visit/:id/:sessionId/:token"
            component={LiveVistStream}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={false}
          />

          <MainRoute
            exact
            path="/LiveStreamChannel/:id/:name?"
            component={LiveStreamChannelJoin}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={false}
          />
          <MainRoute
            exact
            path="/select-plan"
            component={Subscription}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={false}
          />

          {/* Account Routes */}

          <AccountRoute
            exact
            path="/dashboard"
            component={Dashboard}
            user={this.state.user}
            pageName="Dashboard"
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/dashboard/:uid"
            component={Dashboard}
            user={this.state.user}
            pageName="Dashboard"
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/analytics"
            component={Analytics}
            user={this.state.user}
            pageName="Analytics"
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/my-analytics"
            component={MySubscriptionAnalytics}
            user={this.state.user}
            pageName="My Analytics"
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/analytics/old"
            component={Analytics}
            user={this.state.user}
            pageName="Analytics"
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/analytics/old/:uid"
            component={Analytics}
            user={this.state.user}
            pageName="Analytics"
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/user-activities"
            component={UserActivities}
            user={this.state.user}
            pageName="User Activities"
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/users"
            component={AllUsers}
            user={this.state.user}
            pageName="All Users"
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/account"
            component={Profile}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/my-subscriptions"
            component={MySubscriptions}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/embedable-code"
            component={EmbedableCode}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/account/:uid"
            component={Profile}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/streamings"
            component={Streamings}
            pageName="Streaming"
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/inviteUser"
            component={InviteUsers}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/matches"
            component={Matches}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/favorites"
            component={Favorites}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/likes"
            component={Starred}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/network"
            component={Network}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/offers"
            component={OffersNew}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/transactions"
            component={MyTransactions}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/posts"
            component={BlogPosts}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/likedBuyers"
            component={LikedBuyers}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/subscriptions"
            component={Subscriptions}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/liveStreamPackages"
            component={LiveStreamPackages}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/buyers"
            component={Buyers}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/sellers"
            component={Sellers}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/live-bookings/:id"
            component={LiveBookings}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/LiveStreamChannel"
            component={LiveStreamChannel}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/soldHomes"
            component={SoldHomes}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/profiles/sellers/:id"
            component={SellerProfile}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/profiles/buyers/:id"
            component={BuyerProfile}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />
          <AccountRoute
            exact
            path="/offers/:id"
            component={OfferDetailsPage}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={undefined}
          />

          {/* Authenticated Routes */}

          <AuthenticatedRoute
            exact
            path="/login"
            component={Login}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={true}
          />
          <AuthenticatedRoute
            exact
            path="/passwordReset"
            component={PasswordReset}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={true}
          />
          <AuthenticatedRoute
            exact
            path="/signup"
            component={StepWizards}
            user={this.state.user}
            functions={functions}
            betaEnabled={this.state.betaEnabled}
            unauthedOnly={true}
          />
        </>
      );
    }
  }
}

export default Routes;
