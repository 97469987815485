import React, { Component } from "react";
import LikedBuyerListItem from "./../components/Buyer/LikedBuyerListItem";

export default class Starred extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likedBuyers: this.props.user.likedBuyers || []
    };
  }

  createStarredTile = () => {
    const { likedBuyers } = this.state;

    if (likedBuyers && likedBuyers.length > 0) {
      return likedBuyers.filter(v => (!v.draft)).map((value, index) => (
        <LikedBuyerListItem key={index} {...this.props} buyer={value} onBuyerRemove={this.onBuyerRemove} />
      ));
    }

    return (
      <div>
        Only the liked buyer appear here, remember to like listings to shortlist
        them.
      </div>
    );
  };


  onBuyerRemove = (likedBuyers) => {
      this.setState({likedBuyers});
  }

  render() {
    return (
      <div className="content-area5 dashboard-content">
        <div className="dashboard-header clearfix">
          <div>
            <div className="col-sm-12 col-md-6">
              <h4>Saved Buyers</h4>
            </div>
            <br />
            <div className="container">{this.createStarredTile()}</div>
          </div>
        </div>
      </div>
    );
  }
}
