import moment from "moment"
import firebase from "firebase/app";
import "firebase/firestore";

export const saveActivity = async (activity) => {
  try {
    console.log({
      ...activity,
      createdAt: moment().utc().unix()
    });
    const db = firebase.firestore();
    return await db.collection("activities").add({
      ...activity,
      createdAt: moment().utc().unix()
    })
  } catch(err) {
    console.log('Testing error: ', err)
  }
}

export const getActivities = async (user) => {
  const db = firebase.firestore();
  const activities = await db.collection("activities").where("userId", "==", user.uid).orderBy("createdAt", "desc").get();

  return activities.docs
}