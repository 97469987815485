import React, { Component } from 'react'
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import 'react-notifications/lib/notifications.css';
import ReactDOMServer from 'react-dom/server'
import InviteUserEmail from '../components/emailTemplates/InviteUserEmail';
import { Button, ModalBody, ModalFooter,FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { NotificationManager, NotificationContainer } from 'react-notifications';

class InviteUsers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            loading: true,
            firstName: '',
            lastName: '',
            userEmail: '',
            sendingEmail: false
        }
    }

    componentDidMount = () => {
        this.mounted = true
    }

    componentWillUnmount = () => {
        this.mounted = false
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value  
        });
    }

    sendEmailToUser = async () => {
        this.setState({
            sendingEmail: true
        });

        const { userEmail } = this.state;
        const { user } = this.props;

        if(userEmail === '') {
            this.setState({
                sendingEmail: false
            });
            return NotificationManager.error('Email fied is empty.', 'Error!');
        }

        if(userEmail === user.email) {
            this.setState({
                sendingEmail: false
            });
            return NotificationManager.error(`You can't invite your self.`, 'Error!');
        }

        const subject = `You have been invited by ${user.full_name}`;
        const sendEmail = firebase.functions().httpsCallable('sendMatchEmail');
        const body = ReactDOMServer.renderToStaticMarkup(InviteUserEmail({
            user
        }));

        let res = await sendEmail({subject, email: userEmail, body});

        console.log('1: ', res);
        console.log({subject, email: userEmail, body});
 
        this.setState({
            sendingEmail: false,
            userEmail: '',
            firstName: '',
            lastName: ''
        });

        NotificationManager.success('Invitation has been sent successfully.', 'Success!');
    }

    render() {

        const {sendingEmail,userEmail,firstName, lastName} = this.state;

        return (
            <div className="content-area5">
                <div className="dashboard-content">
                    <div className="dashboard-header clearfix">
                        <div className="row">
                            <div className="col-sm-12 col-md-9">
                                <h4>Invite Users</h4>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-list">
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="firstName">First Name</Label>
                                    <Input type="text" name="firstName" id="firstName" value={firstName} onChange={this.onChange} placeholder="First Name" />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="lastName">Last Name</Label>
                                    <Input type="text" name="lastName" id="lastName" value={lastName} onChange={this.onChange} placeholder="Last Name" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label for="userEmail">Enter Email</Label>
                            <Input type="email" name="userEmail" id="userEmail" value={userEmail} onChange={this.onChange} placeholder="User Email" />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" disabled={sendingEmail} onClick={this.sendEmailToUser}>{sendingEmail ? 'Sending...':'Send'}</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                    </div>
                </div>

                <NotificationContainer/>
            </div>
        )
    }
}

export default InviteUsers
