import React from 'react';
import { Field } from 'formik';
import { FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';

const FormField = props => {
    const { touched, errors, label, type, name, value, component, showLabel, placeholder, fieldClasses, style } = props;
    return (
        <div style={style || {}} >
            { showLabel && <label htmlFor={name}>{label}</label> }
            <Field
                component={component || 'input'}
                name={name}
                type={type}
                value={value}
                placeholder={placeholder}
                className= { `form-control ${fieldClasses} ${errors[name] && touched[name] && 'is-invalid'}`}
            />
            {errors[name] && touched[name] && (
                <FormFeedback>{errors[name]}</FormFeedback>
            )}
        </div>
    );
};

FormField.propTypes = {
    touched: PropTypes.any.isRequired,
    errors: PropTypes.object.isRequired,
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    component: PropTypes.string,
    showLabel: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    fieldClasses: PropTypes.string,
};

export default FormField;
