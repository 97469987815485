import React from "react";
import { firebaseConfig } from "../Firebase/firebase";

const WelcomeEmailTemplate = props => {
  const { name } = props;
  return (
    <html lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <title>Document</title>

        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap"
          rel="stylesheet"
        />
      </head>
      <body style={{fontFamily: 'Open Sans, sans-serif', margin: '0 auto'}}>
        <div style={{float: 'left', width: '100%', 'box-sizing': 'border-box'}}>
          <div style={{maxWidth: '650px', width: '100%', margin: '0 auto', boxSizing: 'border-box'}}>
            <div style={{float: 'left', 'width': '100%', border: '1px solid #C8D0D8', boxSizing: 'border-box'}}>
              <div style={{padding: '60px 15px', textAlign: 'center', float: 'left', width: '100%', boxSizing: 'border-box'}}>
                <a href={firebaseConfig.host}>
                  <img
                    style={{maxWidth: '400px'}}
                    src={`${firebaseConfig.host}/img/black-logo.png`}
                    alt=""
                  />
                </a>
              </div>
              <div style={{borderTop: '2px solid #C8D0D8', padding: '15px 15px 50px', float: 'left', width: '100%', boxSizing: 'border-box'}}>
                <h2>Hi {name}</h2>
                <p>
                  Welcome to Homematchx! You're now part of a community of
                  buyers and sellers connecting together to plan their real
                  estate journey. Congrats on creating your account.
                </p>
              </div>
              <div style={{padding: '15px 15px', backgroundColor: '#EFF0F2', float: 'left', width: '100%', boxSizing: 'border-box'}}>
                <h2 style={{marginTop: '0px'}}>Next Steps</h2>
                <p style={{marginBottom: '20px'}}>
                  Choose a profile to help start your home buying or selling
                  process
                </p>
                <ul style={{listStyle: 'none', margin: 0, padding: 0, clear: 'both', marginBottom: '50px', float: 'left', width: '100%'}}>
                  <li style={{width: '45%', padding: 0, float: 'left', marginRight: '30px'}}>
                    <a
                      style={{textDecoration: 'none'}}
                      href="https://homematchx.com/buyers"
                    >
                      <img style={{width: '100%'}} src={`${firebaseConfig.host}/img/img01.png`} alt="" />
                      <div style={{backgroundColor: '#ffffff', marginTop: '-5px', padding: '15px'}}>
                        <h3 style={{margin: '0px 0px 0px', color: '#000'}}>
                          Buyers
                        </h3>
                        <p style={{color: '#828282', minHeight: '66px'}}>
                          Connect and match with sellers planning ahead.
                        </p>
                      </div>
                    </a>
                  </li>
                  <li style={{width: '45%', padding: 0, float: 'left'}}>
                    <a
                      style={{textDecoration: 'none'}}
                      href="https://homematchx.com/sellers"
                    >
                      <img
                        style={{width: '100%'}}
                        src={`${firebaseConfig.host}/img/seller-img.png`}
                        alt=""
                      />
                      <div style={{backgroundColor: '#ffffff', marginTop: '-5px', padding: '15px'}}>
                        <h3 style={{margin: '0px 0px 0px', color: '#000'}}>
                          Sellers
                        </h3>
                        <p style={{color: '#828282', minHeight: '66px'}}>
                          Understand how to prepare your home sale and market
                          strategically.
                        </p>
                      </div>
                    </a>
                  </li>
                </ul>
                <p style={{margin: '0px 0px 30px'}}>
                  Invite others to help grow and engage the community
                </p>
                <ul style={{listStyle: 'none', margin: '0', padding: 0, float: 'left', width: '100%', marginBottom: '30px'}}>
                  <li style={{width: '28%', padding: '0', float: 'left', 'margin-right': '20px'}}>
                    <a
                      style={{textDecoration: 'none'}}
                      href={`${firebaseConfig.host}/inviteUser`}
                    >
                      <img
                        style={{width: '100%'}}
                        src={`${firebaseConfig.host}/img/invite-img.png`}
                        alt=""
                      />
                      <div style={{backgroundColor: '#ffffff', marginTop: '-5px', padding: '15px', textAlign: 'center'}}>
                        <h3 style={{margin: '0px 0px 0px', color: '#000', minHeight: '66px'}}>
                          Invite Buyer and Seller
                        </h3>
                      </div>
                    </a>
                  </li>
                  <li style={{width: '28%', padding: '0', float: 'left', marginRight: '20px'}}>
                    <a
                      style={{textDecoration: 'none'}}
                      href={`${firebaseConfig.host}/inviteUser?role=realtor`}
                    >
                      <img
                        style={{width: '100%'}}
                        src={`${firebaseConfig.host}/img/invite-agent.png`}
                        alt=""
                      />
                      <div style={{backgroundColor: '#ffffff', marginTop: '-5px', padding: '15px', textAlign: 'center'}}>
                        <h3 style={{margin: '0px 0px 0px', color: '#000', minHeight: '66px'}}>
                          Invite Agent
                        </h3>
                      </div>
                    </a>
                  </li>
                  <li style={{width: '28%', padding: '0', float: 'left'}}>
                    <a
                      style={{textDecoration: 'none'}}
                      href={`${firebaseConfig.host}/inviteUser?role=lender`}
                    >
                      <img
                        style={{width: '100%'}}
                        src={`${firebaseConfig.host}/img/invite-lender.png`}
                        alt=""
                      />
                      <div style={{backgroundColor: '#ffffff', marginTop: '-5px', padding: '15px', textAlign: 'center'}}>
                        <h3 style={{margin: '0px 0px 0px', color: '#000', minHeight: '66px'}}>
                          Invite Lender
                        </h3>
                      </div>
                    </a>
                  </li>
                </ul>
                <div style={{float: 'left', width: '100%', marginBottom: '15px'}}>
                  <a
                    style={{fontWeight: '600', lineHeight: '50px', textDecoration: 'none', width: '222px', margin: '0 auto', textTransform: 'uppercase', height: '50px', borderRadius: '5px', textAlign: 'center', padding: '0px 15px', color: '#fff', fontSize: '16px', backgroundColor: '#f61141', display: 'block'}}
                    href="https://homematchx.com/dashboard"
                  >
                    Get started now
                  </a>
                </div>
              </div>
              <div style={{borderLeft: '2px solid #C8D0D8', borderRight: '2px solid #C8D0D8', padding: '50px 15px 30px', float: 'left', width: '100%', boxSizing: 'border-box'}}>
                <p style={{marginTop: '0px'}}>
                  We can't wait to see what you create!
                </p>
                <h3 style={{marginBottom: '30px'}}>The Homematchx Team</h3>
                <div style={{float: 'left', width: '100%', textAlign: 'center'}}>
                  <p style={{margin: '0px', padding: '15px 30px', fontSize: '14px', color: '#F8496A', backgroundColor: '#FDC5C5', display: 'inline-block', borderRadius: '5px'}}>
                    Questions? Send a message to chat with us and other
                    Homematchx developers.
                  </p>
                </div>
              </div>
              <div style={{textAlign: 'center', float: 'left', width: '100%', padding: '30px 15px', boxSizing: 'border-box', backgroundColor: '#C8D0D8'}}>
                <p style={{color: '#000', margin: '0px'}}>
                  This email was sent to{" "}
                  <a
                    style={{textDecoration: 'none', color: '#000'}}
                    href="mailto:inkrealtygroup@gmail.com"
                  >
                    inkrealtygroup@gmail.com
                  </a>{" "}
                  to confirm your Homematchx registration.
                </p>
              </div>
              <div style={{float: 'left', width: '100%', backgroundColor: '#656b66', padding: '30px 15px', boxSizing: 'border-box'}}>
                <div style={{width: '50%', float: 'left', padding: '20px 0px 0px'}}>
                  <h3 style={{fontWeight: '600', margin: '0', color: '#fff'}}>
                    Homematchx
                  </h3>
                </div>
                <div style={{width: '50%', float: 'left', textAlign: 'right'}}>
                  <p style={{margin: 0, color: '#fff'}}>Homematchx, LLC</p>
                  <p style={{margin: 0, color: '#fff'}}>1881 Sylvan Ave</p>
                  <p style={{margin: 0, color: '#fff'}}>Dallas, Tx 75208 USA</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
};

export default WelcomeEmailTemplate;
