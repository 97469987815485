import React, { Component } from "react";
import Banner from "components/Banner";
import SearchArea from "components/SearchArea";
import Services from "components/Services";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import GuideModal from "components/GuideModal/GuideModal";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
    };
  }

  render() {
    return (
      <React.Fragment>
        <Banner {...this.props} />
        <SearchArea {...this.props} />
        <Services {...this.props} />

        {/* <div id="banner" className="banner-main d-flex justify-content-end">
                    <div className="banner-img">
                        <img src="/images/banner-img.png" alt="" />
                        <div className="banner-logo">
                            <img src="/img/realtyCarteLogo.png" alt="" />
                        </div>
                    </div>
                    <div className="banner-overlay d-flex align-items-center">
                        <Container>
                            <div className="banner-overlay-text">
                                <h2>Hire Top Agent Pros</h2>
                                <p>Tackle your next purchase or sell with RealtyCarte, a world leader in agent on-demand limited real estate services.</p>
                                <Link className="search-button" to="/realEstateServices">Get Started</Link>
                                <GuideModal className="bd-example-modal-lg"/>
                            </div>
                        </Container>
                    </div>
                </div> */}

        {/* Hire Of Any work Section
                <div className="hire-section-main bg-grea-3">
                    <Container>
                        <div className="hire-section-innr d-flex align-items-center">
                            <div className="hire-section-text">
                                <p>Hire for any scope of work</p>
                            </div>
                            <div className="hire-section-list">
                                <Row>
                                    <Col md="4" className="border-right-card">
                                        <div className="hire-card">
                                            <h4>Short-term task</h4>
                                            <p>Find a subject matter expert for one-time real estate tasks</p>
                                        </div>
                                    </Col>
                                    <Col md="4" className="border-right-card">
                                        <div className="hire-card">
                                            <h4>Recurring projects</h4>
                                            <p>Have a go-to person or team with specialized skills</p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="hire-card">
                                            <h4>Full-service contract work</h4>
                                            <p>Solidify your investment with a dedicated professional</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                </div> */}
      </React.Fragment>
    );
  }
}

export default Index;
