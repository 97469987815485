import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Messenger from "../components/Messenger/Messenger";
import Loader from 'react-loader-spinner'

export default class extends Component {

  state = {
    loaded: false
  };

  componentDidMount() {
    window.Typekit.load({ async: false });
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 500); // let messenger style load properly and then load content
  }

  render() {
    const { loaded } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Messenger</title>

          <link
            href="/css/messenger-style.css"
            rel="stylesheet"
            id="bootstrap-css"
          />
        </Helmet>
        {loaded ? <Messenger {...this.props} /> : <Loader type="Rings" color="#ff214f" height="80%" width="100%" />}
      </React.Fragment>
    );
  }
}
