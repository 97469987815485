import React from 'react';


const LoadingComponent = () => {
    return (
        <div className="loading-wrapper">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default LoadingComponent;