import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Input, FormGroup } from "reactstrap";
import firebase from "firebase/app";
import ReactDOMServer from "react-dom/server";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      error: "",
      requestDemoModal: false,
      yourName: "",
      companyName: "",
      yourEmail: "",
      yourPhone: "",
      msgBody: "",
    };
  }

  requestDemoModalToggle = () => {
    this.setState({
      requestDemoModal: !this.state.requestDemoModal,
    });
  };

  handleFormSubmit = async (e) => {
    const { yourName, companyName, yourEmail, yourPhone, msgBody } = this.state;
    e.preventDefault();
    const emailRegex = new RegExp(
      /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
    );
    if (!yourName) return alert("Name is required");
    if (!yourEmail) return alert("Email is required");
    if (!yourEmail || !emailRegex.test(yourEmail))
      return alert("Please enter correct email.");
    if (!msgBody) return alert("Your message is required");
    let html = (
      <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <title>Email</title>
        </head>
        <body>
          <p>
            <b>Name: </b>
            {yourName}
          </p>
          <br></br>
          <p>
            <b>Company Name: </b>
            {companyName}
          </p>
          <br></br>
          <p>
            <b>Email: </b>
            {yourEmail}
          </p>
          <br></br>
          <p>
            <b>Phone Number: </b>
            {yourPhone}
          </p>
          <br></br>
          <p>
            <b>Messgae: </b>
            {msgBody}
          </p>
        </body>
      </html>
    );
    const email = "info@homematchx.com";
    try {
      const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
      const body = ReactDOMServer.renderToStaticMarkup(html);
      sendEmail({ subject: `Demo request from ${yourName}`, email, body });
      this.setState({
        requestDemoModal: false,
        yourName: "",
        companyName: "",
        yourEmail: "",
        yourPhone: "",
        msgBody: "",
      });
      alert("We have received your email. Our team will contact you shortly");
    } catch (err) {
      alert(err);
    }
  };

  onSearch = () => {
    const { search } = this.state;
    const { history } = this.props;
    localStorage.setItem("homeFilters", JSON.stringify({ searches: [search] }));
    // history.push(this.props.user ? 'sellers' : '/homes')
    history.push("/homes");
  };

  render() {
    console.log(this.props.user, "USER");

    return (
      <React.Fragment>
        <div className="main-banner">
          <div className="main-banner-img">
            <img src="/img/homeSellerImage.jpg" alt="Home Seller" />
          </div>
          <div className="main-banner-text d-flex align-items-center">
            <Container>
              <h1>WE TURN OFFERS INTO VALUABLE DATA</h1>
              <div className="main-banner-search d-flex"></div>
              <p style={{ fontWeight: "bold" }}>
                Build a strong real estate offer data model from property
                listings.
              </p>
              <p style={{ fontWeight: "bold" }}>
                Homematchx is a leading data management tool designed
                specifically to collect and analyze offer data.
              </p>
            </Container>
          </div>
        </div>

        {/* Agents Logo Section */}
        {/* <div className="agents-logo-section bg-grea-3">
          <Container>
            <div className="agents-logo-inner d-flex align-items-center justify-content-center">
              <div className="agents-logo-text">
                <p>Trusted by top agents and teams</p>
              </div>
              <div className="agents-logo-imgs">
                <ul className="d-flex align-items-center justify-content-around">
                  <li>
                    <a href="#top">
                      <img src="/images/agent-logo4.png" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </div> */}

        <div className="creat-cards-section d-flex flex-wrap justify-content-center bg-grea-3 mt-5">
          <Col lg="6" xl="4">
            <div className="creat-card">
              <div className="creat-card-img-list d-flex justify-content-center">
                <div className="creat-card-img">
                  <img src="/images/agents.jpg" alt="" />
                </div>
              </div>
              <div className="creat-card-text">
                <h3>Create</h3>
                <p>
                  Build your agent profile so you can start receiving and
                  managing offers on listings.
                </p>
              </div>
            </div>
          </Col>
          <Col lg="6" xl="4">
            <div className="creat-card">
              <div className="creat-card-img-list creat-card-img-list2 d-flex justify-content-center">
                <div className="creat-card-img">
                  <img src="/images/agents.jpg" alt="" />
                </div>
                <div className="creat-card-img">
                  <img src="/images/architecture-1867187_1920.jpg" alt="" />
                </div>
                <div className="devider-bg">
                  <p>100%</p>
                </div>
              </div>
              <div className="creat-card-text">
                <h3>Match</h3>
                <p>
                  Find the best offer quickly using filters, data, and
                  multi-offer spreadsheet.
                </p>
              </div>
            </div>
          </Col>
          <Col lg="6" xl="4">
            <div className="creat-card">
              <div className="creat-card-img-list creat-card-img-list3 d-flex justify-content-center">
                <div className="creat-card-img">
                  <img src="/images/agents.jpg" alt="" />
                </div>
                <div className="creat-card-img">
                  <img src="/images/architecture-1867187_1920.jpg" alt="" />
                </div>
                <div className="creat-card-img">
                  <img src="/images/key-2323278_1920.jpg" alt="" />
                </div>
                <div className="devider-bg"></div>
              </div>
              <div className="creat-card-text">
                <h3>Transact</h3>
                <p>
                  Reduce transact time by 90 percent and improve transparency in
                  the real estate offer process.
                </p>
              </div>
            </div>
          </Col>
        </div>

        <Container id="subscription-plans">
          <div className="row justify-content-center">
            <div className="col-12 my-5 text-center">
              <h2>Subscription Plans</h2>
            </div>
            {/* <div className="col-12 col-lg-3"></div> */}
            <div className="col-12 col-md-6 col-lg-4 mr-md-3 mr-0">
              <div className="pricing-card">
                <div className="pricing-card-text">
                  <div className="text-center">
                    <p className="pricing-card-green-text">
                      Free Plan. Try it out and build strong offer data on the
                      Automation Platform.
                    </p>
                    <h3>FREE</h3>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-check mr-2 check-icon"></i>Monetize
                      your listing activity data on Homematchx (Keep 80% of
                      revenue)
                    </li>
                    <li>
                      <i className="fa fa-check mr-2 check-icon"></i>Get paid
                      instantly
                    </li>
                    <li>
                      <i className="fa fa-times mr-2 times-icon"></i>Advertise
                      your global listing activity data across platforms
                    </li>
                    <li>
                      <i className="fa fa-times mr-2 times-icon"></i>Official
                      Sales Report
                    </li>
                    <li>
                      <i className="fa fa-times mr-2 times-icon"></i>Agent
                      Support (Standard)
                    </li>
                  </ul>
                </div>
                {!this.props.user?.subscriptionId && (
                  <div className="text-center mt-3">
                    <button
                      className="btn btn-outline-danger w-100 pricing-btn text-capitalize"
                      onClick={() => {
                        // if this.props.user is undefined then redirect to signup page
                        if (this.props.user) {
                          this.props.history.push("/my-subscriptions");
                        } else {
                          this.props.history.push("/signup");
                        }
                      }}
                    >
                      Get Started
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
              <div className="pricing-card">
                <div className="pricing-card-text">
                  <div className="text-center">
                    <p className="pricing-card-green-text">
                      The intentional plan. Access unlimited Global Offer Data
                      across the platform.
                    </p>
                    <h3>
                      $14.99 <span className="pricing-year">/year</span>
                    </h3>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-check mr-2 check-icon"></i>Monetize
                      your listing activity data on Homematchx (Keep 100% of
                      revenue)
                    </li>
                    <li>
                      <i className="fa fa-check mr-2 check-icon"></i>Get paid
                      instantly
                    </li>
                    <li>
                      <i className="fa fa-check mr-2 check-icon"></i>Advertise
                      your global listing activity data across platforms
                    </li>
                    <li>
                      <i className="fa fa-check mr-2 check-icon"></i>Official
                      Sales Report
                    </li>
                    <li>
                      <i className="fa fa-check mr-2 check-icon"></i>Agent
                      Support (Standard)
                    </li>
                  </ul>
                </div>
                {!this.props.user?.subscriptionId && (
                  <div className="text-center mt-3">
                    <button
                      className="btn btn-outline-danger w-100 pricing-btn text-capitalize"
                      onClick={() => {
                        if (this.props.user) {
                          this.props.history.push("/my-subscriptions");
                        } else {
                          this.props.history.push("/signup");
                        }
                      }}
                    >
                      Get Started
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="col-12 col-lg-3"></div> */}
          </div>
        </Container>

        <div className="connects-section bg-grea-3">
          <Container>
            <h2>Homematchx connects with listing services</h2>
            <div className="connects-section-inner d-flex align-items-center justify-content-center">
              <div className="connects-img">
                <img
                  style={{ height: "80px" }}
                  src="/img/code-imgPink.png"
                  alt=""
                />
              </div>
              <div className="connects-section-text">
                <p>
                  When you add a listing to the MLS or Zillow link it to your
                  account.
                </p>
              </div>
            </div>
          </Container>
        </div>

        {/* <div className="find-way-section">
                    <div className="find-section-inner">
                        <h2>Planning to sell soon</h2>
                        <div className="d-flex flex-wrap">
                            <Col lg="4" md="4">
                                <div className="find-card">
                                    <div className="find-img">
                                        <img src="/images/person2.png" alt="Person" />
                                    </div>
                                    <div className="find-text">
                                        <h3>Prepare in advance</h3>
                                        <p>Showcase your home next to active listings to share your expected time to sell.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4" md="4">
                                <div className="find-card">
                                    <div className="find-img">
                                        <img src="/images/person1.png" alt="Person" />
                                    </div>
                                    <div className="find-text">
                                        <h3>Build interest now</h3>
                                        <p>Share recent or expected home improvement updates, images, and videos for excitement</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4" md="4">
                                <div className="find-card">
                                    <div className="find-img">
                                        <img src="/images/person3.png" alt="Person" />
                                    </div>
                                    <div className="find-text">
                                        <h3>Gain confidence early</h3>
                                        <p>Connect with ready buyers and agents to discuss closing conveniently for comfort</p>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div> */}

        <div className="video-section">
          <div className="container">
            <div className="video-div-main">
              <Row>
                <Col md="5">
                  <div className="player-wrapper">
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      playing
                      playsInline
                      muted
                      controls
                      className="react-player"
                      url="https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/cdn%2Fvideos%2FHMX%20Offers%20Promo.mp4?alt=media&token=7260cb95-2157-492e-ae8d-40d53addedf7"
                      light="/img/pyari-larki-2.png"
                    />
                  </div>
                </Col>
                <Col md="7">
                  <div className="video-text" style={{ paddingTop: "0px" }}>
                    <h3
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "38px",
                      }}
                    >
                      Get more out of your real estate experience
                    </h3>
                    <p>
                      With Homematchx real estate is now a streamlined
                      transaction. From browsing and filtering real estate
                      offers to built-in multiple offer spreadsheet and data
                      analytics, you can be confident about the decisions you
                      make.
                    </p>
                    <a
                      onClick={this.requestDemoModalToggle}
                      style={{ width: "auto", color: "white" }}
                      className="search-button"
                    >
                      Request a demo
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.requestDemoModal}
          size="md"
          fade={false}
          toggle={this.requestDemoModalToggle}
          className="offers-modal"
        >
          <ModalHeader
            className="modal-header"
            toggle={this.requestDemoModalToggle}
          ></ModalHeader>
          <ModalBody className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="trend-modal-heading">
                    Request a personalized demo
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 overflow m-vertical-32 text-center">
                  <FormGroup className="form-group pt-14">
                    <Input
                      name="name"
                      value={this.state.yourName}
                      onChange={(e) =>
                        this.setState({ yourName: e.target.value })
                      }
                      className="input-text custom-input"
                      placeholder="Your Name"
                      required
                    />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Input
                      name="companyName"
                      value={this.state.companyName}
                      onChange={(e) =>
                        this.setState({ companyName: e.target.value })
                      }
                      className="input-text custom-input"
                      placeholder="Company Name"
                    />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Input
                      type="email"
                      name="yourEmail"
                      value={this.state.yourEmail}
                      onChange={(e) =>
                        this.setState({ yourEmail: e.target.value })
                      }
                      className="input-text custom-input"
                      placeholder="Your Email"
                      required
                    />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Input
                      name="thirdPhoneNumber"
                      value={this.state.yourPhone}
                      onChange={(e) =>
                        this.setState({ yourPhone: e.target.value })
                      }
                      className="input-text custom-input"
                      type="number"
                      maxLength="12"
                      placeholder="123-456-7890"
                    />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Input
                      type="textarea"
                      name="comments"
                      value={this.state.msgBody}
                      onChange={(e) =>
                        this.setState({ msgBody: e.target.value })
                      }
                      className="input-text input-area"
                      placeholder="Your Message"
                      required
                    />
                  </FormGroup>
                  <button
                    type="submit"
                    onClick={this.handleFormSubmit}
                    className="submit-offer"
                  >
                    Request a demo
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Banner;
