import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DoughnutLocationChart = ({ value, total, color }) => {

  console.log(value, total, color);

  const legend = {
    display: false
  }

  const remainder = total - value

  const data = {
    // labels: [color,'gray'],
    datasets: [
      {
        label: '# of Votes',
        data: [value, remainder],
        backgroundColor: [color],
        borderColor: [color],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{position: 'relative'}}>
      <div
        style={{
          position: 'absolute',
          top: '50%', left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
        <h3 style={{ color: color, fontFamily: 'Poppins', fontWeight: 600 }}>
          {`${value}%`}
        </h3>
      </div>
      <Doughnut data={data} width={140} height={140} legend={legend} options={{
        cutoutPercentage: 68,
        animation: {
          animationRotate: true,
          duration: 2000
        },
        borderRadius: 30,
        tooltips: { enabled: false },
        hover: { mode: null },
      }}/>
    </div>
  );
};

export default DoughnutLocationChart;
