// import React, { useEffect, useState } from 'react';
// import { Container } from 'reactstrap';
// import Loader from "react-loader-spinner";
// import 'firebase/firestore';
// import '../assets/css/analytics-page.css';
// import offerDetails from "../templates/email/offer-details-browser-version.js";
// import firebase from "firebase/app";
// import "firebase/firestore";

// const  OtherAgentOffers = props => {
//   const [offer, setOffer] = useState();
//   const [loading, setLoading] = useState(false);
//   const id = props?.match?.params?.id;
//   const sellerId = offer?.seller_id;
//   useEffect(() => {
//     getOfferDetails();
//   }, []);
//   const getOfferDetails = async () => {
//     setLoading(true);
//     const db = firebase.firestore();
//     db.collection("offers").doc(props?.match?.params?.id).get()
//       .then(snapshot => {setOffer(snapshot.data()); setLoading(false)});
//   }
//   const showLoader = loading && <Loader type="Rings" color="#ff214f" height="80%" width="100%" />
//   return (
//     <div className="analytics-main">
//       <Container className="dashboard-main">
//         {showLoader}
//         {!offer ? <p className="no-offer-found">There is no offer against this id</p> :
//         <div dangerouslySetInnerHTML={{ __html: offerDetails(sellerId,offer, id)}} />}
//       </Container>
//     </div>
//   );
// }

import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import {
  UncontrolledCollapse,
  Table,
  Card,
  CardBody,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import "../assets/css/offer-details.css";
import House from "../assets/icons/gallery/house-avatar.jpg";
import Person from "../assets/icons/person.svg";
import Calendar from "../assets/icons/calendar.svg";
import Flag from "../assets/icons/flag-21.svg";
import Download from "../assets/icons/Plus.svg";
import Plus from "../assets/icons/add.svg";
import Minus from "../assets/icons/minus.svg";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import { generateZipFromCloud } from "../../src/Services/generate-zip";
import OfferComments from "../../src/components/OfferComments";
import OfferSubmitComment from "../../src/components/OfferSubmitComment";
import BackArrow from "../assets/icons/back_arrow.svg";
import nextPrev from "../assets/icons/next-prev.png";
import AgentInformationModal from "components/Analytics/AgentInformationModal";

class OtherAgentOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      loading: true,
      isBuyer: false,
      isOpened1: false,
      isOpened2: false,
      isOpened3: false,
      isOpened4: false,
      isOpened5: false,
      isOpened6: false,
      isOpened7: false,
      isOpened8: false,
      isOpened9: false,
      isOpened10: false,
      isOpened11: false,
      isOpened12: false,
      isOpened13: false,
      isOpened14: false,
      isOpened15: false,
      isOpened16: false,
      isOpened17: false,
      isUploading: false,
      counterOfferModal: false,
      executionModal: false,
      offer: [],
      counterOfferData: {},
      executeOfferData: {},
      uploadingDocument: false,
      seller: null,
      deletingOffer: false,
      offerActions: false,
      agentModal: false,
      realtor: null,
      sellerStatus: "",
      sellerRecord: {},
      smsBlast: false,
      globalAgents: [],
      smsTxt:
        "You made an offer on one or more of %name% with Ink Realty Group listings. They have a new listing you may be interested in at %sellerAddress%: https://homematchx.com/sellers/%listingKey%",
    };
  }

  componentDidMount() {
    this.getOfferDetails();
    this.getGlobalAgents();
  }

  getGlobalAgents = () => {
    const db = firebase.firestore();
    db.collection("users")
      .doc(this.props.user?.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            globalAgents: doc.data().myGlobalAgents,
          });
          // setGlobalAgents(doc.data().myGlobalAgents);
        }
      });
  };

  getOfferDetails = async () => {
    const db = firebase.firestore();
    await db
      .collection("offers")
      .doc(this.props?.match?.params?.id)
      .get()
      .then((snapshot) =>
        this.setState({
          offer: snapshot.data(),
          loading: false,
        })
      );
    await db
      .collection("sellers")
      .doc(this.state.offer?.seller || this.props.location.state?.offer?.seller)
      .get()
      .then((snapshot) => {
        this.setState({
          sellerRecord: snapshot.data(),
          smsTxt: this.state.smsTxt
            .replace("%sellerAddress%", snapshot.data().UnparsedAddress)
            .replace("%listingKey%", snapshot.id)
            .replace("%name%", this.props.user.realtor_name),
        });
      });
  };

  downloadDocs = () => {
    const { offer } = this.state;
    const { executeOfferData, documents } = offer
      ? offer
      : this.props.location.state?.offer;
    if (
      executeOfferData &&
      executeOfferData.documents &&
      executeOfferData.documents.length
    )
      generateZipFromCloud(executeOfferData.documents, "execute");
    if (documents && documents.length)
      generateZipFromCloud(
        documents.map((doc) => doc.path),
        "docs"
      );
  };

  sendSMSToAll = async () => {
    const { allPropertyOffers } = this.props.location.state;
    const { user } = this.props;
    const seller =
      this.state.offer?.seller || this.props.location.state?.offer?.seller;

    let numbers = allPropertyOffers.map((o) =>
      o.phoneNumber.match(/\d/g)?.join("")
    );

    numbers = [...new Set(numbers)];
    const sendSMS = firebase.functions().httpsCallable("sendSMS");

    numbers.forEach(async (num) => {
      try {
        await sendSMS({
          to: `+1${num.replace("+1", "")}`,
          body: this.state.smsTxt
            .replace("%name", user.realtor_name)
            .replace("%sellerAddress", seller.UnparsedAddress)
            .replace("%listingKey", seller.id),
        });
      } catch (err) {
        console.log(err);
      }
    });

    this.setState({
      smsBlast: false,
    });
  };

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const prevURL = params.get("prevUrl");
    const { offer, loading } = this.state;
    const { user } = this.props;
    const allPropertyOffers = this.props.location.state?.allOffers;

    const id = this.props?.match?.params?.id;

    let numbers = allPropertyOffers?.map((offer) =>
      offer.phoneNumber?.match(/\d/g)?.join("")
    );
    const smsBlastAudience = [...new Set(numbers)].length;

    let {
      address,
      agentName,
      brokerageName,
      createdAt,
      viewedAt,
      viewedBy,
      purchasePrice,
      downPayment,
      financeAmount,
      financingType,
      sellerConcessions,
      earnestMoney,
      closingDate,
      leasebackDays,
      possession,
      optionFee,
      titlePolicyExpense,
      optionDays,
      nonRealtyItem,
      saleOfOtherProperty,
      rightToTerminate,
      residentialServiceContract,
      documents,
      comments,
    } = offer ? offer : {};

    const seller =
      this.state.offer?.seller || this.props.location.state?.offer?.seller;
    const offerId =
      this.state.offer?.id ||
      this.props.location.state?.offer?.id ||
      this.props?.match?.params?.id;
    const updateHistory = offer.updateHistory?.length
      ? offer.updateHistory
      : this.props.location.state?.offer?.updateHistory || [];

    if (!offer)
      return (
        <p className="no-offer-found">There is no offer against this id</p>
      );
    else
      return (
        // {user?.uid === offer?.seller_id && (
        //   <div className="row">
        //     <div className="col-xl-12 col-lg-12 d-flex justify-content-end">
        //       <Link to={`/offers/${id}`}>
        //       <button className="btn-lg red-btn btn-list-margin">More Actions</button>
        //       </Link>
        //     </div>
        //   </div>
        // )}
        <div className="content-area5 offer-details offer-preview-container">
          {/* {user.uid === offer.seller_id && (
            <div className="d-flex justify-content-end">
            <Link
              to={{
                pathname: `/offers/${id}`,
                // search: `?curItem=${this.props.curItem}&totalItems=${this.props.totalItems}`,
                // state: { offer, curItem, totalItems },
              }}
            >
              <button className="btn-lg red-btn btn-list-margin">More Actions</button>
            </Link>
          </div>
          )} */}
          <div className="dashboard-content bg-clr offer-preview-bg">
            {loading && (
              <Loader type="Rings" color="#ff214f" height="80%" width="100%" />
            )}
            <div className="dashboard-list dashboard-list-no-border bg-clr-white">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 mb-4">
                    <div className="otheragent-header-btns">
                      {user?.uid === offer.seller_id && (
                        <Link
                          to={{
                            pathname: `/offers/${offerId}`,
                            // search: `?curItem=${this.props.curItem}&totalItems=${this.props.totalItems}`,
                            // state: { offer, curItem, totalItems },
                          }}
                        >
                          <button className="other-agent-action-btn my-auto">
                            More Actions
                          </button>
                        </Link>
                      )}
                      <button
                        className="follow-my-listing-btn my-auto"
                        onClick={() =>
                          this.setState({
                            smsBlast: true,
                          })
                        }
                      >
                        <img
                          src={require("../../src/assets/icons/follow-listing.svg")}
                        />
                        Follow My Listing
                      </button>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12 house">
                    <Link to={`/sellers/${seller}`} className="link">
                      <img
                        src={
                          offer?.featured_image?.url
                            ? offer.featured_image.url
                            : House
                        }
                        alt="house"
                      />
                    </Link>
                  </div>
                  <div className="col-xl-5 col-lg-6 col-md-12 homeoffer-details">
                    <ul>
                      <li>
                        <h5>{address}</h5>
                      </li>
                      <li>
                        <img src={Person} />
                        <p>
                          buyers agent{" "}
                          <span className="">
                            <>
                              <AgentInformationModal
                                comingFrom={`${
                                  prevURL ? "globalOffers" : "otherAgents"
                                }`}
                                key={this.state.offer.id}
                                offer={this.state.offer}
                                sellerAgent={false}
                                user={this.props.user}
                                globalAgents={this.state.globalAgents}
                                getGlobalAgents={this.getGlobalAgents}
                              />
                              , {brokerageName}
                            </>

                            {/* <a onClick={this.agentModalToggle}> */}
                            {/* {brokerageName} */}
                            {/* </a> */}
                          </span>
                        </p>
                      </li>
                      <li>
                        <img src={Calendar} />
                        <p>
                          <span>
                            {moment(createdAt * 1000).format(
                              "MMMM Do YYYY, [at] h:mm:ss a"
                            )}{" "}
                            CST ({moment(createdAt * 1000).fromNow()})
                          </span>
                        </p>
                      </li>
                      {viewedAt && (
                        <li>
                          <img src={Flag} />
                          <p>
                            The offer has been reviewed{" "}
                            {moment(viewedAt * 1000).format(
                              "MM.DD.YYYY [at] hh:mm A"
                            )}{" "}
                            CST by{" "}
                            <span className="underline">
                              <AgentInformationModal
                                comingFrom="otherAgents"
                                key={this.state.offer.id}
                                offer={this.state.offer}
                                sellerAgent={true}
                              />
                            </span>
                          </p>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="col-xl-4 col-lg-12 col-md-12">
                    <div className="homeoffer">
                      <p>Purchase Price</p>
                      <NumberFormat
                        className="black-price-tag"
                        value={purchasePrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value, props) => <p {...props}>{value}</p>}
                      />
                      <p>Down Payment </p>
                      <NumberFormat
                        className="black-price-tag"
                        value={downPayment}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value, props) => <p {...props}>{value}</p>}
                      />
                      <p>Finance Amount</p>
                      <NumberFormat
                        className="red-price-tag"
                        value={financeAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value, props) => <p {...props}>{value}</p>}
                      />
                    </div>
                  </div>
                </div>
                {prevURL && (
                  <div className="row mt-95">
                    <div className="col-xl-12">
                      <div className="row btns-mobile-center padd-horizontal-15">
                        <Link to={`${`/${prevURL.split("/")[3]}`}`}>
                          <button className="back-btn">
                            <img
                              src={BackArrow}
                              className="backArrow"
                              alt="back arrow"
                            />
                            Back
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mt-2 text-center">
                  <div className="col-12">
                    {this.props.location.state?.curItem > 1 && (
                      <Link
                        to={{
                          pathname: `/otherAgent/offer/${
                            this.props.location.state?.allOffers[
                              this.props.location.state?.curItem - 2
                            ]?.id
                          }`,
                          search: `?curItem=${
                            this.props.location.state?.curItem - 1
                          }&totalItems=${
                            this.props.location.state?.totalItems
                          }${
                            prevURL
                              ? `&prevUrl=${encodeURIComponent(prevURL)}`
                              : ``
                          }`,
                          state: {
                            offer:
                              this.props.location.state?.allOffers[
                                this.props.location.state?.curItem - 2
                              ],
                            curItem: this.props.location.state?.curItem - 1,
                            totalItems: this.props.location.state?.totalItems,
                            allOffers: this.props.location.state?.allOffers,
                          },
                        }}
                      >
                        {" "}
                        <button className="next-prev-btn mr-2">
                          <img
                            src={nextPrev}
                            className="backArrow"
                            alt="back arrow"
                            style={{ marginBottom: "2px", height: "10px" }}
                          />
                          Previous
                        </button>
                      </Link>
                    )}
                    {this.props.location.state?.curItem <
                      this.props.location.state?.totalItems && (
                      <Link
                        to={{
                          pathname: `/otherAgent/offer/${
                            this.props.location.state?.allOffers[
                              this.props.location.state?.curItem
                            ]?.id
                          }`,
                          search: `?curItem=${
                            this.props.location.state?.curItem + 1
                          }&totalItems=${
                            this.props.location.state?.totalItems
                          }${
                            prevURL
                              ? `&prevUrl=${encodeURIComponent(prevURL)}`
                              : ""
                          }`,
                          state: {
                            offer:
                              this.props.location.state?.allOffers[
                                this.props.location.state?.curItem
                              ],
                            curItem: this.props.location.state?.curItem + 1,
                            totalItems: this.props.location.state?.totalItems,
                            allOffers: this.props.location.state?.allOffers,
                          },
                        }}
                      >
                        <button className="next-prev-btn">
                          Next
                          <img
                            src={nextPrev}
                            className="backArrow ml-2"
                            alt="back arrow"
                            style={{
                              transform: "rotate(180deg)",
                              marginBottom: "2px",
                              height: "10px",
                            }}
                          />
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="row mt-40 mb-13">
                  <div className="col-xl-6 col-lg-12 col-md-12 overflow">
                    <Table striped>
                      <tbody>
                        <tr>
                          <td className="bold-td">Finance Type</td>
                          <td className="normal-td">{financingType}</td>
                        </tr>
                        <tr>
                          <td className="bold-td">Down Payment</td>
                          <NumberFormat
                            className="normal-td"
                            value={downPayment}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value, props) => (
                              <td {...props}>{value}</td>
                            )}
                          />
                        </tr>
                        <tr>
                          <td className="bold-td">Finance Amount</td>
                          <NumberFormat
                            className="normal-td"
                            value={financeAmount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value, props) => (
                              <td {...props}>{value}</td>
                            )}
                          />
                        </tr>
                        <tr>
                          <td className="bold-td">Seller Concession</td>
                          <td className="normal-td">${sellerConcessions}</td>
                        </tr>
                        <tr>
                          <td className="bold-td">Earnest Money Deposit</td>
                          <NumberFormat
                            className="normal-td"
                            value={earnestMoney}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value, props) => (
                              <td {...props}>{value}</td>
                            )}
                          />
                        </tr>
                        <tr>
                          <td className="bold-td">Title Expense</td>
                          <td className="normal-td">{titlePolicyExpense}</td>
                        </tr>
                        <tr>
                          <td className="bold-td">Service Contract</td>
                          <td>${residentialServiceContract}</td>
                        </tr>
                        {user?.uid === offer?.seller_id &&
                          offer?.offer_expire_date && (
                            <>
                              <tr>
                                <td className="bold-td">Offer Expiry Date</td>
                                <td>{offer?.offer_expire_date}</td>
                              </tr>
                            </>
                          )}
                      </tbody>
                    </Table>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 overflow">
                    <Table striped>
                      <tbody>
                        <tr>
                          <td className="bold-td">Closing Date</td>
                          <td className="normal-td">{closingDate}</td>
                        </tr>
                        <tr>
                          <td className="bold-td">Leaseback Days</td>
                          <td className="normal-td">{leasebackDays} days</td>
                        </tr>
                        <tr>
                          <td className="bold-td">Possession</td>
                          <td className="normal-td">{possession}</td>
                        </tr>
                        <tr>
                          <td className="bold-td">
                            Option Fee and Option Days
                          </td>
                          <td className="normal-td">
                            <NumberFormat
                              value={optionFee}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              renderText={(value, props) => <>{value}</>}
                            />
                            , {optionDays} days
                          </td>
                        </tr>
                        <tr>
                          <td className="bold-td">Non Realty Item</td>
                          <td className="normal-td">{nonRealtyItem}</td>
                        </tr>
                        <tr>
                          <td className="bold-td">Sale of Other Property</td>
                          <td className="normal-td">{saleOfOtherProperty}</td>
                        </tr>
                        <tr>
                          <td className="bold-td">
                            Right To Terminate Due to Lender’s Appraisal
                          </td>
                          <td className="normal-td">{rightToTerminate}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </div>

            {user?.uid === offer?.seller_id && (
              <div className="row offer-package mb-30">
                <div className="col-xl-12 col-lg-12">
                  <h5 className="offersMian">Offer Package</h5>
                  {documents &&
                    documents.map((doc, key) => (
                      <FormGroup className="download-details" key={key}>
                        <Label>{doc.name}</Label>
                        <a
                          href={doc.path}
                          color="link"
                          target="_blank"
                          download={doc.name}
                        >
                          <img src={Download} alt="download" />
                          Download
                        </a>
                      </FormGroup>
                    ))}

                  <FormGroup>
                    <label
                      htmlFor={"file-picker"}
                      className="upload-btn-wrapper"
                    >
                      <div className="upload-btn">{"Add Documents"}</div>
                      <input
                        type="file"
                        multiple
                        id="file-picker"
                        onChange={this.uploadDocument}
                        name="myfile"
                      />
                    </label>

                    <label
                      className="ml-2 upload-btn-wrapper"
                      onClick={this.downloadDocs}
                    >
                      <div className="upload-btn">Download All Documents</div>
                    </label>
                  </FormGroup>
                </div>
              </div>
            )}
            {this.state.sellerRecord?.MlsStatus !== "Sold" && (
              <div className="row offer-package">
                <div className="col-xl-12 col-lg-12">
                  <h5 className="offersMian">Comments</h5>
                  <div className="offer-comment-setion">
                    <OfferComments
                      offer={offer}
                      offerId={offerId}
                      name={user?.displayName}
                      user={this.props.user}
                      match={this.props.match}
                      offerComment={comments}
                    />
                    {this.state.sellerRecord?.MlsStatus !== "Sold" && (
                      <OfferSubmitComment
                        sellerData={this.state.seller}
                        offer={offer}
                        offerId={offerId}
                        name={user?.displayName}
                        user={this.props.user}
                        match={this.props.match}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {user?.uid === offer?.seller_id && (
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <h5 className="history-of-changes">History of changes</h5>
                  <div className="history-changes">
                    {updateHistory?.length ? (
                      updateHistory.map((m, key) => <p key={key}>{m}</p>)
                    ) : (
                      <p>The offer has no history yet.</p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="row mb-50 mt-5">
              <div className="col-lg-12">
                <div className="p-lr-30 bg-clr-white">
                  <h5 className="summaries">Offer Summary Definition</h5>
                  <div>
                    <div id="toggler1">
                      {this.state.isOpened1 ? (
                        <div className="accordian">
                          <p>What is Purchase Price?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Purchase Price?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler1"
                      onEntered={() =>
                        this.setState({
                          isOpened1: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened1: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            This is the price offered for your home by the
                            buyer.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler2">
                      {this.state.isOpened2 ? (
                        <div className="accordian">
                          <p>What is Finance Type?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Finance Type?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler2"
                      onEntered={() =>
                        this.setState({
                          isOpened2: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened2: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Finance Type is the type of finance the buyer is
                            using to purchase the home. Finance Type can range
                            from Cash, Conventional, FHA, VA, USDA, and
                            sometimes Seller Finance.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler3">
                      {this.state.isOpened3 ? (
                        <div className="accordian">
                          <p>What is Down Payment?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Down Payment?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler3"
                      onEntered={() =>
                        this.setState({
                          isOpened3: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened3: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Down Payment is the amount the buyer is putting down
                            on the home as their initial up-front partial
                            payment to purchase. This is paid at the time of
                            finalizing the transaction.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler4">
                      {this.state.isOpened4 ? (
                        <div className="accordian">
                          <p>What is a Seller Concession?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is a Seller Concession?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler4"
                      onEntered={() =>
                        this.setState({
                          isOpened4: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened4: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Seller Concessions, also known as Seller
                            Contributions, is an amount the buyer is requesting
                            the seller contribute towards their transaction. The
                            buyer is required to pay for certain home financing
                            costs but anagreement can be made between the buyer
                            and seller where the seller pays for those e cost on
                            behalf ofthe buyer.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler5">
                      {this.state.isOpened5 ? (
                        <div className="accordian">
                          <p>What is a Earnest Money Deposit?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is a Earnest Money Deposit?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler5"
                      onEntered={() =>
                        this.setState({
                          isOpened5: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened5: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Earnest Money Deposit is a good-faith deposit into
                            an escrow account with the title company. It is
                            aspecific form of security deposit made as part of
                            entering into a real estate transaction. This amount
                            implies that the buyer is serious about purchasing
                            and is willing to demonstrate an amount as their
                            goodfaith to complete transaction. This amount can
                            be 1% - 5% of the agreed upon purchase price.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler6">
                      {this.state.isOpened6 ? (
                        <div className="accordian">
                          <p>What is Title Policy?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Title Policy?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler6"
                      onEntered={() =>
                        this.setState({
                          isOpened6: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened6: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            The title policy ensures that the property isn’t
                            encumbered by past liens or other problems. This
                            expenseis customarily paid by the buyer or seller
                            based on the state of where the transaction is taken
                            place. Theamount varies widely from state to state
                            and depends on the price of the home.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler7">
                      {this.state.isOpened7 ? (
                        <div className="accordian">
                          <p>What is a Survey?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is a Survey?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler7"
                      onEntered={() =>
                        this.setState({
                          isOpened7: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened7: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            The survey expense is the cost to hire a land
                            surveyor. The survey effectively gives you a legal
                            description of the exact boundaries of where your
                            property line begins and end. This cost can range
                            from $350 to $700.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler8">
                      {this.state.isOpened8 ? (
                        <div className="accordian">
                          <p>What is a Residential Service Contract?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is a Residential Service Contract?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler8"
                      onEntered={() =>
                        this.setState({
                          isOpened8: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened8: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            A Residential service contract, also known as Home
                            Warranty, covers the repair or replace of major
                            mechanical systems and appliances in the home. These
                            systems include heating, electrical, and
                            plumbing.The cost varies based on the type of
                            service coverage plan.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler9">
                      {this.state.isOpened9 ? (
                        <div className="accordian">
                          <p>What is Closing Date?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Closing Date?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler9"
                      onEntered={() =>
                        this.setState({
                          isOpened9: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened9: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            When it comes to selecting a closing date, you want
                            to be as comfortable as possible. Closing date
                            canvary based on your situation or need. If you are
                            purchasing a new construction your closing date may
                            be extended to accommodate the transaction the
                            closing. Choosing you own closing just might be the
                            control you need. You can even stay in your home
                            after closing with extended stay option. Be sure to
                            ask all the questions to make your process as
                            comfortable as possible.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler10">
                      {this.state.isOpened10 ? (
                        <div className="accordian">
                          <p>What is Leaseback Days?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Leaseback Days?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler10"
                      onEntered={() =>
                        this.setState({
                          isOpened10: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened10: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Leaseback allows a seller to stay in the house after
                            closing for an agreed upon period of time. The
                            seller becomes the tenant and the buyers becomes the
                            landlord. A leaseback can be negotiated at no cost
                            to thetenant up to the daily amount of the buyer’s
                            new monthly mortgage payment. There is a temporary
                            residential lease agreement that is signed, legally
                            binding, and enforced.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler11">
                      {this.state.isOpened11 ? (
                        <div className="accordian">
                          <p>About Possession</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>About Possession</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler11"
                      onEntered={() =>
                        this.setState({
                          isOpened11: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened11: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Possession can be taken by the buyer upon closing
                            and funding or after the temporary lease agreement.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler12">
                      {this.state.isOpened12 ? (
                        <div className="accordian">
                          <p>About Option Fee and Option Days</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>About Option Fee and Option Days</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler12"
                      onEntered={() =>
                        this.setState({
                          isOpened12: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened12: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            The Option Fee (paid to seller) and Option Day, also
                            known as Option Period, gives the buyer a time
                            period to do their inspections on the home. During
                            this process repairs will typically be asked for
                            items that may materially affect the purchase or
                            sell of the home. Items such as the roof, HVAC,
                            foundation,flooring, electrical, plumbing and
                            appliance could be requested for repair. If any
                            repairs are required youwill receive a copy of the
                            inspection report and a list of repairs. This option
                            period gives the buyer the unrestricted right to
                            terminate the contract if both parties cannot agree
                            on repair terms. Having this right allows the buyer
                            to retain their Earnest Money Deposit if the
                            contract is terminated.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler13">
                      {this.state.isOpened13 ? (
                        <div className="accordian">
                          <p>What is Credit Approval?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Credit Approval?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler13"
                      onEntered={() =>
                        this.setState({
                          isOpened13: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened13: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            If the Buyer cannot obtain Buyer Approval, Buyer may
                            give written notice to Seller within a specific
                            number of day after the effective date of the
                            contract and this contract will terminate and the
                            earnest money will be refunded to the Buyer. If the
                            Buyer does not terminate the contract under this
                            provision,the contract shall no longer be subject to
                            the Buyer obtaining Buyer Approval.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler14">
                      {this.state.isOpened14 ? (
                        <div className="accordian">
                          <p>What is Non-Realty Item?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Non-Realty Item?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler14"
                      onEntered={() =>
                        this.setState({
                          isOpened14: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened14: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Non-Realty Item is used if the parties need to add
                            additional items of personal property that are to
                            staywith the property. Items such refrigerator,
                            washer and dryer are transferred using a Non-Realty
                            Item addendum.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler15">
                      {this.state.isOpened15 ? (
                        <div className="accordian">
                          <p>What is Sale of Other Property?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Sale of Other Property?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler15"
                      onEntered={() =>
                        this.setState({
                          isOpened15: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened15: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            A Sale of Other Property addendum is used if the
                            Buyer will be unable to buy the new property unless
                            their existing property is sold and closed.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler16">
                      {this.state.isOpened16 ? (
                        <div className="accordian">
                          <p>What are Commission?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What are Commission?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler16"
                      onEntered={() =>
                        this.setState({
                          isOpened16: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened16: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Real estate commission are negotiated and variable
                            amount based on sales price. The commission is made
                            directly to the real estate brokers for services
                            rendered in the sale or purchase of real property.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler17">
                      {this.state.isOpened17 ? (
                        <div className="accordian">
                          <p>Estimated Net Offer</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian">
                          <p>Estimated Net Offer</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler17"
                      onEntered={() =>
                        this.setState({
                          isOpened17: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened17: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Other Fees to the transaction may include title
                            policy, escrow, estimated taxes, etc. Have
                            questions, need clarification, or would like a
                            seller net sheet for an itemized breakdown of fees
                            click here.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* sms blast modal */}
          <Modal
            isOpen={this.state.smsBlast}
            size="md"
            fade={false}
            toggle={() =>
              this.setState({
                smsBlast: !this.state.smsBlast,
              })
            }
          >
            <ModalHeader
              className="modal-header"
              toggle={() =>
                this.setState({
                  smsBlast: !this.state.smsBlast,
                })
              }
            ></ModalHeader>
            <ModalBody className="modal-body">
              <Container>
                <Row>
                  <Col>
                    <div className="form-content-box content-box-signupCheck">
                      <div className="details">
                        <h3>Select listings to send</h3>
                        <p>
                          Audience: <b>{smsBlastAudience}</b>
                        </p>
                        <form>
                          <FormGroup className="form-group pt-14">
                            <Label for="comments" className="label-font">
                              Type Message
                            </Label>
                            <Input
                              className="textarea-follow-listing"
                              type="textarea"
                              name="comments"
                              value={this.state.smsTxt}
                              onChange={(e) =>
                                this.setState({ smsTxt: e.target.value })
                              }
                              placeholder="Message"
                            />
                          </FormGroup>

                          <div className="form-group mb-0">
                            <button
                              type="button"
                              className="btn-md button-theme btn-block mb-20"
                              onClick={this.sendSMSToAll}
                            >
                              {this.state.sendingSMS ? "Sending..." : "Send"}
                            </button>
                            <a
                              onClick={() =>
                                this.setState({
                                  smsBlast: false,
                                })
                              }
                            >
                              No
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </div>
      );
  }
}

export default OtherAgentOffers;
