import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';


class RatingField extends Component {
    render () {
        const { rating, onChange, name, isEditable  } = this.props;
        return (
            <div className="rating-wrapper">
               { isEditable ? <StarRatings
                    rating={ rating }
                    starRatedColor="#ff214f"
                    numberOfStars={5}
                    isSelectable = { true }
                    changeRating = { (rating) => {
                        onChange(name, rating);
                    }}
                    
                />
                : 
                <StarRatings 
                    rating={ rating }
                    starRatedColor="#ff214f"
                    numberOfStars={5}
                />
                }
            </div>
        )
    }
}


export default RatingField;