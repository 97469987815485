import Step1 from "components/MyTransactions/Step1";
import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "firebase/firestore";
import firebase from "firebase/app";

function MyTransactions(props) {
  const [isAddTransaction, setIsAddTransaction] = useState(false);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection("offers")
      .where("seller_id", "==", props.user.uid)
      .where("status", "==", "Executed")
      .onSnapshot((snapshot) => {
        const offers = [];
        snapshot.forEach((doc) => {
          offers.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setOffers(offers);
      });
  }, []);
  console.log(offers, "offers");
  return (
    <div className="dashboard-main">
      <div className="dashboard-header">
        <div className="row">
          <div className="col-md-6">
            <h4 className="seller-main-heading">My Transactions</h4>
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-primary float-right"
              onClick={() => setIsAddTransaction(true)}
            >
              Add Transaction
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={isAddTransaction}
        toggle={() => setIsAddTransaction(!isAddTransaction)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader>Add Transaction</ModalHeader>
        <ModalBody className="py-5">
          <StepWizard>
            <Step1 user={props.user} offers={offers} />
          </StepWizard>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default MyTransactions;
