import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';

class ChangeStatus extends Component {
    state = {
        modal: false,
        status: this.props.status,
        isLoading: false,
        newStatus: this.props.newStatus,
    }

    toggle = () => {
        const { modal } = this.state;
        this.setState({
            modal: !modal
        });
    }

    onChange = async ({ target }) => {
        const { name: key, value } = target;
        this.setState({
            [key]: value
        });
    };

    submit = () => {
        const { status } = this.state;
        const { newStatus } = this.props;
        newStatus(status)
    }


    render() {
        const { modal, isLoading, status } = this.state;
        const { children, style, color, className } = this.props;
        const nonChangeAble = [
            'Sold',
            'Cancelled',
            'Withdraw'
        ];

        if (nonChangeAble.includes(this.props.status)) {
            return <></>
        }

        return (
            <Fragment>
                <Button
                    className={className}
                    color={color}
                    style={style}
                    onClick={this.toggle}
                >
                    {children}
                </Button>

                <Modal isOpen={modal} toggle={this.toggle} className="modal-danger modal-lg">

                    <ModalHeader toggle={this.toggle}>Change Status</ModalHeader>
                    <ModalBody style={{ padding: 16 }}>
                        <Form>
                            <FormGroup>
                                <Label for="status">Status</Label>
                                <select
                                    name="status"
                                    className="form-control custom-select"
                                    required
                                    value={status}
                                    onChange={this.onChange}

                                >
                                    <option value="">Select</option>
                                    <option value="Active">Active</option>
                                    <option value="Active Kick Out">Active Kick Out</option>
                                    <option value="Active Option Contract">Active Option Contract</option>
                                    <option value="Active Contingent">Active Contingent</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Sold">Closed</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="Temp Off Market">Temp Off Market</option>
                                    <option value="Expired">Expired</option>
                                    <option value="Leased">Leased</option>
                                    <option value="Withdraw">Withdraw</option>
                                </select>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.submit} disabled={isLoading}> {isLoading ? 'Updating...' : 'Update'} </Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>

        )
    }
}


export default ChangeStatus;