import React, { useEffect, useRef, useState } from "react";
import RightCaret from "../../assets/icons/email_caret_right.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import moment from "moment";
import { toast } from "react-toastify";
import { set } from "lodash";
import Axios from "axios";
import GoogleLogin from "react-google-login";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { google } from 'googleapis';

function Emails({ user, offer, ...props }) {
  const [userState, setUserState] = useState(user);
  const [gmailFiles, setGmailFiles] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState(user.signature || "");
  const [attachments, setAttachments] = useState([]);
  const [isComposing, setIsComposing] = useState(false);
  const [showTooltip, setShowTooltip] = useState({
    emailId: null,
    isViewing: false,
  });
  const [showReplies, setShowReplies] = useState({
    emailId: null,
    isViewing: false,
  });
  const [viewFullEmail, setViewFullEmail] = useState({
    emailId: null,
    isViewing: false,
  });
  const [showFullReply, setShowFullReply] = useState({
    emailId: null,
    isViewing: false,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [parentEmail, setParentEmail] = useState(null);
  const [allEmails, setAllEmails] = useState([]);
  const [emailToBeReplied, setEmailToBeReplied] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [emailTo, setEmailTo] = useState(
    offer.buyer === user.uid ||
      offer.buyerEmail === user.email ||
      offer.email === user.email
      ? offer.sellerEmail
      : offer.buyerEmail || offer.email
  );
  const [currTo, setCurrTo] = useState("");
  const [tos, setTos] = useState([
    offer.buyer === user.uid ||
    offer.buyerEmail === user.email ||
    offer.email === user.email
      ? offer.sellerEmail
      : offer.buyerEmail || offer.email,
  ]);
  const [isCC, setIsCC] = useState(false);
  const [cc, setCC] = useState([]);
  const [currCC, setCurrCC] = useState("");
  const [isEditEmailTo, setIsEditEmailTo] = useState(false);
  const quillRef = useRef(null);
  const composeEmailRef = useRef(null);

  useEffect(() => {
    const db = firebase.firestore();
    const emailsRef = db.collection("emails");
    const userEmail = user.email;
    const senderQuery = emailsRef.where(
      "senderEmail",
      "==",
      offer.seller_id === user.uid || offer.sellerEmail === user.email
        ? offer.sellerEmail
        : offer.buyerEmail || offer.email
    );
    const receiverQuery = emailsRef.where(
      "receiverEmail",
      "array-contains",
      offer.buyer === user.uid ||
        offer.buyerEmail === user.email ||
        offer.email === user.email
        ? offer.sellerEmail
        : offer.buyerEmail || offer.email
    );
    const emails = [];
    Promise.all([senderQuery.get(), receiverQuery.get()])
      .then((querySnapshots) => {
        querySnapshots.forEach((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            emails.push({ id: doc.id, ...doc.data() });
          });
        });
        setAllEmails(
          emails.filter((email) => email.offerId === offer.id).reverse()
        );
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  }, []);

  const emailBody =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

  const handleChange = (value) => {
    setContent(value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    if (!files.length) return;
    setIsUploading(true);
    const storage = firebase.storage();
    const offersRef = storage.ref().child(`images/offers`);
    let documents = [];
    for (const file of files) {
      const offerImageRef = offersRef.child(
        `${(file, moment().utc().unix())}_${file.name}`
      );
      let document = await offerImageRef.put(file);

      const filePath = await firebase
        .storage()
        .ref()
        .child(document.ref.location.path)
        .getDownloadURL();
      documents.push({
        path: filePath,
        name: file.name,
        owner: user.uid,
        timestamp: Date.now(),
      });
    }
    setAttachments((prevAttachments) => [...prevAttachments, ...documents]);
    setIsUploading(false);
  };

  const sendEmail = async () => {
    const db = firebase.firestore();
    const sendGmailMail = firebase.functions().httpsCallable("sendGmailMail");
    const replyGmailMail = firebase.functions().httpsCallable("replyGmailMail");
    setIsSending(true);
    let result;

    if (isReply) {
      const parentEmailId = emailToBeReplied.id;
      const threadId = emailToBeReplied.threadId;
      try {
        result = await replyGmailMail({
          refreshToken: userState.googleAccount.refreshToken,
          email: tos,
          subject: subject,
          body: content,
          cc: cc,
          attachments: attachments.map((attachment) => attachment.path),
          threadId,
        });
      } catch (error) {
        toast.error("Error sending email");
      }
      const email = {
        messageId: result.data.res.id,
        threadId: result.data.res.threadId,
        parentEmailId: isReply ? emailToBeReplied.id : null,
        subject,
        cc: cc.length ? cc : null,
        content,
        senderId:
          offer.seller_id === user.uid || offer.sellerEmail === user.email
            ? offer.seller_id
            : offer.buyer || null,
        receiverId:
          offer.buyer === user.uid ||
          offer.buyerEmail === user.email ||
          offer.email === user.email
            ? offer.seller_id
            : offer.buyer || null,
        senderEmail:
          offer.seller_id === user.uid || offer.sellerEmail === user.email
            ? offer.sellerEmail
            : offer.buyerEmail || offer.email || null,
        receiverEmail: tos,
        senderProfilePhoto: user.profilePhoto["64"],
        attachments,
        timestamp: Date.now(),
      };
      db.collection("emails")
        .doc(parentEmailId)
        .update({
          replies: firebase.firestore.FieldValue.arrayUnion(email),
        })
        .then(() => {
          console.log("Document successfully updated!");
          setIsComposing(false);
          setSubject("");
          setContent("");
          setCurrTo("");
          setTos([
            offer.buyer === user.uid ||
            offer.buyerEmail === user.email ||
            offer.email === user.email
              ? offer.sellerEmail
              : offer.buyerEmail || offer.email,
          ]);
          setCurrCC("");
          setCC([]);
          setAttachments([]);
          setIsReply(false);
          setParentEmail(null);
          setEmailToBeReplied(null);
          setAllEmails((prevEmails) =>
            prevEmails.map((prevEmail) =>
              prevEmail.id === parentEmailId
                ? { ...prevEmail, replies: [...prevEmail.replies, email] }
                : prevEmail
            )
          );
          toast.success("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
          toast.error("Error sending email");
        });
    } else {
      try {
        result = await sendGmailMail({
          refreshToken: userState.googleAccount.refreshToken,
          email: tos,
          subject: subject,
          body: content,
          cc: cc,
          attachments: attachments.map((attachment) => attachment.path),
        });
      } catch (error) {
        toast.error("Error sending email");
      }
      const email = {
        messageId: result.data.res.id,
        threadId: result.data.res.threadId,
        parentEmailId: isReply ? emailToBeReplied.id : null,
        subject,
        cc: cc.length ? cc : null,
        content,
        senderId:
          offer.seller_id === user.uid || offer.sellerEmail === user.email
            ? offer.seller_id
            : offer.buyer || null,
        receiverId:
          offer.buyer === user.uid ||
          offer.buyerEmail === user.email ||
          offer.email === user.email
            ? offer.seller_id
            : offer.buyer || null,
        senderEmail:
          offer.seller_id === user.uid || offer.sellerEmail === user.email
            ? offer.sellerEmail
            : offer.buyerEmail || offer.email || null,
        receiverEmail: tos,
        senderProfilePhoto: user.profilePhoto["64"],
        attachments,
        timestamp: Date.now(),
        offerId: offer.id,
      };
      db.collection("emails")
        .add({
          ...email,
          replies: [],
        })
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          setIsComposing(false);
          setSubject("");
          setContent("");
          setAttachments([]);
          setIsReply(false);
          setParentEmail(null);
          setEmailToBeReplied(null);
          setAllEmails((prevEmails) => [
            ...prevEmails,
            { id: docRef.id, ...email, replies: [] },
          ]);
          toast.success("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          toast.error("Error sending email");
        });
    }
    setIsSending(false);
  };

  const handleReply = (email) => {
    setIsComposing(true);
    setIsReply(true);
    setEmailToBeReplied(email);
    setSubject(`Re: ${email.subject}`);
    if (composeEmailRef.current) {
      composeEmailRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleForward = (email) => {
    setIsComposing(true);
    setIsReply(false);
    setSubject(`Fwd: ${email.subject}`);
    setContent(email.content);
    setAttachments(email.attachments);
    if (composeEmailRef.current) {
      composeEmailRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDelete = async (email, isReply) => {
    const db = firebase.firestore();
    const deleteGmailMail = firebase
      .functions()
      .httpsCallable("deleteGmailMail");
    try {
      await deleteGmailMail({
        refreshToken: userState.googleAccount.refreshToken,
        id: email.messageId,
      });

      if (isReply) {
        await db
          .collection("emails")
          .doc(email.parentEmailId)
          .update({
            replies: firebase.firestore.FieldValue.arrayRemove(email),
          })
          .then(() => {
            console.log("Document successfully updated!");
            setAllEmails((prevEmails) =>
              prevEmails.map((prevEmail) =>
                prevEmail.id === email.parentEmailId
                  ? {
                      ...prevEmail,
                      replies: prevEmail.replies.filter(
                        (reply) => reply.id !== email.id
                      ),
                    }
                  : prevEmail
              )
            );
            toast.success("Email deleted successfully");
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
            toast.error("Error deleting email");
          });
      } else {
        await db
          .collection("emails")
          .doc(email.id)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
            setAllEmails((prevEmails) =>
              prevEmails.filter((prevEmail) => prevEmail.id !== email.id)
            );
            toast.success("Email deleted successfully");
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
            toast.error("Error deleting email");
          });
      }
    } catch (error) {
      toast.error("Error deleting email");
    }
  };

  const responseGoogle = async (googleCode) => {
    const getRefreshToken = firebase
      .functions()
      .httpsCallable("getRefreshToken");
    const refreshToken = await getRefreshToken({
      code: googleCode.code,
    });
    const db = firebase.firestore();
    await db
      .collection("users")
      .doc(user.uid)
      .update({
        googleAccount: {
          refreshToken: refreshToken.data.refresh_token,
        },
      });

    setUserState((prevUser) => ({
      ...prevUser,
      googleAccount: {
        refreshToken: refreshToken.data.refresh_token,
      },
    }));
  };

  const failureGoogle = (response) => {
    console.log(response);
  };

  const toggleTooltip = (emailId) => {
    if (showTooltip.emailId === emailId) {
      setShowTooltip({
        emailId: null,
        isViewing: false,
      });
    } else {
      setShowTooltip({
        emailId,
        isViewing: true,
      });
    }
  };

  return (
    <div className="bg-clr-white px-3 py-5">
      {/* <button className="btn btn-primary" onClick={listEmails}>List Emails</button> */}
      <div className="row mb-4" id="composeEmail" ref={composeEmailRef}>
        {!userState.googleAccount && (
          <div className="col-12 p-0 text-center">
            <GoogleLogin
              // clientId="182635199829-g1eah9omgp0q47oprvoeaqlh1ncc7han.apps.googleusercontent.com"
              clientId="115691394769-u2kekie8ni2nqjf0bbhikbvp9h46pe74.apps.googleusercontent.com"
              scope="https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.insert https://www.googleapis.com/auth/gmail.labels"
              buttonText="Connect to Google"
              onSuccess={responseGoogle}
              onFailure={failureGoogle}
              accessType="offline"
              responseType="code"
              cookiePolicy="single_host_origin"
              className="mb-0"
            />
          </div>
        )}
        <div className="col-6 p-0">
          {userState.googleAccount && !allEmails.length && (
            <p>
              There are no emails to show. Please compose an email to start a
              thread.
            </p>
          )}
        </div>

        {userState.googleAccount && (
          <div className="col-6 text-right p-0">
            <button
              className="compose-email-btn pointer"
              onClick={() => {
                if (isComposing) {
                  setSubject("");
                  setContent("");
                  setAttachments([]);
                  setIsReply(false);
                  setParentEmail(null);
                  setEmailToBeReplied(null);
                  setIsCC(false);
                  setCC([]);
                  setTos([
                    offer.buyer === user.uid ||
                    offer.buyerEmail === user.email ||
                    offer.email === user.email
                      ? offer.sellerEmail
                      : offer.buyerEmail || offer.email,
                  ]);
                  setCurrCC("");
                  setCurrTo("");
                }
                setIsComposing(!isComposing);
              }}
            >
              <i
                class={`fa fa-${!isComposing ? "envelope" : "times"} mr-2`}
                aria-hidden="true"
              ></i>
              {isComposing ? "Cancel" : "Compose Email"}
            </button>
          </div>
        )}
        {isComposing && (
          <div className="col-12 p-3 compose-emai-section mt-3">
            <div>
              <>
                <label className="email-subject-label mb-0" htmlFor="to">
                  To:{" "}
                </label>
                <input
                  type="text"
                  className="email-subject-input ml-2"
                  value={currTo}
                  name="to"
                  id="to"
                  placeholder="Enter email address"
                  onChange={(e) => setCurrTo(e.target.value)}
                />
                <i
                  class="fa fa-check ml-2"
                  style={{
                    color: "#008000",
                  }}
                  aria-hidden="true"
                  onClick={() => {
                    setTos((prevTo) => [...prevTo, currTo]);
                    setCurrTo("");
                  }}
                ></i>
                <label
                  className="add-attachment-label float-right"
                  onClick={() => setIsCC(!isCC)}
                >
                  CC{" "}
                </label>
                {
                  <div className="attachments-conteiner my-2">
                    {tos.length
                      ? tos.map((email, index) => (
                          <span className="attachment-item mx-2">
                            <span className="attachment-name">{email}</span>
                            <i
                              class="fa fa-times ml-2"
                              aria-hidden="true"
                              onClick={() => {
                                setTos((prevTo) =>
                                  prevTo.filter((_, i) => i !== index)
                                );
                              }}
                            ></i>
                          </span>
                        ))
                      : null}
                  </div>
                }
              </>
              <hr className="mt-2 mb-4" />
              {isCC && (
                <>
                  <label className="email-subject-label mb-0" htmlFor="cc">
                    CC:{" "}
                  </label>
                  <input
                    type="text"
                    className="email-subject-input ml-2"
                    value={currCC}
                    name="cc"
                    id="cc"
                    placeholder="Enter email address"
                    onChange={(e) => setCurrCC(e.target.value)}
                  />
                  <i
                    class="fa fa-check ml-2"
                    style={{
                      color: "#008000",
                    }}
                    aria-hidden="true"
                    onClick={() => {
                      setCC((prevCC) => [...prevCC, currCC]);
                      setCurrCC("");
                    }}
                  ></i>
                  {
                    <div className="attachments-conteiner my-2">
                      {cc.length
                        ? cc.map((email, index) => (
                            <span className="attachment-item mx-2">
                              <span className="attachment-name">{email}</span>
                              <i
                                class="fa fa-times ml-2"
                                aria-hidden="true"
                                onClick={() => {
                                  setCC((prevCC) =>
                                    prevCC.filter((_, i) => i !== index)
                                  );
                                }}
                              ></i>
                            </span>
                          ))
                        : null}
                    </div>
                  }
                  <hr />
                </>
              )}

              <label
                className="email-subject-label mb-0"
                htmlFor="email-subject-input"
              >
                Subject:{" "}
              </label>
              <input
                type="text"
                className="email-subject-input ml-2"
                value={subject}
                onChange={handleSubjectChange}
              />
              <hr className="mt-2 mb-4" />
              <ReactQuill
                ref={quillRef}
                value={content}
                onChange={handleChange}
              />
              <div className="attachments-conteiner mt-2">
                {attachments.map((attachment, index) => (
                  <span className="attachment-item ml-2">
                    <span className="attachment-name">
                      {attachment.name.slice(0, 50) +
                        `${`${attachment.name}`.length > 50 ? "..." : ``}`}{" "}
                      <i
                        className="fa fa-times ml-2"
                        aria-hidden="true"
                        color="#000"
                        onClick={() => {
                          setAttachments((prevAttachments) =>
                            prevAttachments.filter(
                              (_, attachmentIndex) => attachmentIndex !== index
                            )
                          );
                        }}
                      ></i>
                    </span>
                  </span>
                ))}
              </div>
              <label className="add-attachment-label mt-3">
                <i class="fa fa-paperclip mr-2" aria-hidden="true"></i>
                {isUploading ? "Uploading..." : "Add Attachment"}
                <input
                  type="file"
                  hidden
                  multiple
                  name="attachments"
                  id="attachments"
                  accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                  onChange={handleFileUpload}
                />
              </label>
              <button
                disabled={isUploading || isSending}
                className="btn btn-success mt-3 float-right"
                onClick={sendEmail}
              >
                {isSending ? "Sending..." : "Send Email"}
              </button>
            </div>
          </div>
        )}
      </div>
      {allEmails.length
        ? allEmails.map((email, i) => (
            <div className="email-container pb-5 ml-3">
              <button className="email-divider-btn">
                <i
                  class="fa fa-envelope email-divider-icon mx-auto"
                  aria-hidden="true"
                ></i>
              </button>
              <div className="row">
                <div
                  className="col-12 col-lg-8 p-0"
                  style={{ position: "relative" }}
                >
                  <img
                    src={email.senderProfilePhoto}
                    alt=""
                    width={"25px"}
                    height={"25px"}
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                  <span className="ml-2 email-from">
                    {email.senderEmail === user.email
                      ? user.displayName || user.realtor_name
                      : offer.agentName || offer.buyerName}
                  </span>
                  <img
                    src={RightCaret}
                    className="ml-1 email-caret-right"
                    alt=""
                  />
                  <span className="ml-1 email-to">
                    {email.receiverEmail.map((e, index) => {
                      return (
                        e.split("@")[0] +
                        `${index < email.receiverEmail.length - 1 ? ", " : ""}`
                      );
                    })}
                  </span>
                  <img
                    src={RightCaret}
                    className="ml-1 email-caret-right"
                    alt=""
                    style={{
                      transform:
                        showTooltip.emailId === email.id &&
                        showTooltip.isViewing
                          ? "rotate(-90deg)"
                          : "rotate(90deg)",
                    }}
                    onClick={() => {
                      toggleTooltip(email.id);
                    }}
                  />
                  <OverlayTrigger
                    show={
                      showTooltip.emailId === email.id && showTooltip.isViewing
                    }
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip" className="email-tooltip">
                        <p className="font-12 m-0 my-2">
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            From:
                          </span>{" "}
                          {email.senderEmail}
                        </p>
                        <p className="font-12 m-0 my-2">
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            To:
                          </span>{" "}
                          {email.receiverEmail?.map(
                            (e, index) =>
                              e +
                              `${
                                index < email.receiverEmail.length - 1
                                  ? ", "
                                  : ""
                              }`
                          )}
                        </p>
                        {email.cc && (
                          <p className="font-12 m-0 my-2">
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              CC:
                            </span>{" "}
                            {email.cc?.map((e, index) => {
                              return (
                                e + `${index < email.cc.length - 1 ? ", " : ""}`
                              );
                            })}
                          </p>
                        )}
                        <p className="font-12 m-0 my-2">
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Subject:
                          </span>{" "}
                          {email.subject}
                        </p>
                        <p className="font-12 m-0 my-2">
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Date:
                          </span>{" "}
                          {moment(email.timestamp).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </p>
                      </Tooltip>
                    }
                  >
                    <span></span>
                  </OverlayTrigger>
                </div>
                <div className="col-12 col-lg-4 p-0 text-right mt-3 mt-lg-0">
                  <button
                    className="reply-btn pointer"
                    onClick={() => handleReply(email)}
                  >
                    <i
                      class="fa fa-reply mr-2"
                      aria-hidden="true"
                      style={{ color: "gray" }}
                    ></i>
                    Reply
                  </button>
                  <button
                    className="center-btn pointer"
                    onClick={() => {
                      handleForward(email);
                    }}
                  >
                    <i
                      class="fa fa-forward mr-2"
                      aria-hidden="true"
                      style={{ color: "gray" }}
                    ></i>
                    Forward
                  </button>
                  <button
                    className="forward-btn pointer"
                    onClick={() => {
                      handleDelete(email, false);
                    }}
                  >
                    <i
                      class="fa fa-trash-o"
                      aria-hidden="true"
                      style={{ color: "gray" }}
                    ></i>
                  </button>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 p-0 email-from">{email.subject}</div>
              </div>
              <hr />
              <div className="row mt-2">
                <div className="col-12 p-0 overflow-scroll">
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        viewFullEmail.emailId === email.id &&
                        viewFullEmail.isViewing
                          ? email.content.replace(
                              /<p>/g,
                              '<p style="margin:0px;">'
                            )
                          : email.content?.slice(0, 135) +
                            `${email.content.length > 135 ? "..." : ``}`,
                    }}
                  ></p>
                  <div className="attachments-conteiner my-2">
                    {email.attachments?.map((attachment, index) => (
                      <a href={attachment.path} target="_blank">
                        <span className="attachment-item mr-2">
                          <span className="attachment-name">
                            {attachment.name.slice(0, 50) +
                              `${
                                `${attachment.name}`.length > 50 ? "..." : ``
                              }`}{" "}
                          </span>
                        </span>
                      </a>
                    ))}
                  </div>
                  <button
                    className="view-email-btn p-0 pointer"
                    onClick={() => {
                      if (viewFullEmail.emailId === email.id) {
                        setViewFullEmail({
                          emailId: null,
                          isViewing: false,
                        });
                      } else {
                        setViewFullEmail({
                          emailId: email.id,
                          isViewing: true,
                        });
                      }
                    }}
                  >
                    {viewFullEmail.emailId === email.id &&
                    viewFullEmail.isViewing
                      ? "Hide Email"
                      : "View Email"}
                  </button>
                  {showReplies.emailId !== email.id && email.replies?.length ? (
                    <div
                      className="email-replies-container mt-2 pointer"
                      onClick={() => {
                        setShowReplies({
                          emailId: email.id,
                          isViewing: true,
                        });
                      }}
                    >
                      <p className="email-replies-text my-auto">
                        {email.replies.length} more emails in thread
                      </p>
                    </div>
                  ) : null}
                  {showReplies.emailId === email.id &&
                    showReplies.isViewing &&
                    email.replies.reverse().map((reply, i) => (
                      <>
                        {showFullReply.emailId !== `${email.id}_${i}` && (
                          <div
                            className="email-replies-container mt-2 pointer"
                            onClick={() => {
                              setShowFullReply({
                                emailId: `${email.id}_${i}`,
                                isViewing: true,
                              });
                            }}
                          >
                            <p className="email-replies-content my-auto">
                              <span className="email-from mr-4">
                                {reply.senderEmail === user.email
                                  ? user.displayName || user.realtor_name
                                  : offer.agentName || offer.buyerName}
                              </span>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    reply.content.slice(0, 135) +
                                    `${
                                      reply.content.length > 135 ? "..." : ``
                                    }`,
                                }}
                              ></span>
                            </p>
                          </div>
                        )}
                        {showFullReply.isViewing &&
                          showFullReply.emailId === `${email.id}_${i}` && (
                            <div className="email-replies-view-container pb-5">
                              <div className="row">
                                <div className="col-12 col-lg-8 p-0">
                                  <img
                                    src={user.profilePhoto["64"]}
                                    alt=""
                                    width={"25px"}
                                    height={"25px"}
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <span className="ml-2 email-from">
                                    {reply.senderEmail === user.email
                                      ? user.displayName || user.realtor_name
                                      : offer.agentName || offer.buyerName}
                                  </span>
                                  <img
                                    src={RightCaret}
                                    className="ml-1 email-caret-right"
                                    alt=""
                                  />

                                  <span className="ml-1 email-to">
                                    {reply.receiverEmail.map((e, index) => {
                                      return (
                                        e.split("@")[0] +
                                        `${
                                          index < reply.receiverEmail.length - 1
                                            ? ", "
                                            : ""
                                        }`
                                      );
                                    })}
                                  </span>
                                  <img
                                    src={RightCaret}
                                    className="ml-1 email-caret-right"
                                    alt=""
                                    style={{
                                      transform:
                                        showTooltip.emailId === reply.id &&
                                        showTooltip.isViewing
                                          ? "rotate(-90deg)"
                                          : "rotate(90deg)",
                                    }}
                                    onClick={() => {
                                      toggleTooltip(reply.id);
                                    }}
                                  />
                                  <OverlayTrigger
                                    show={
                                      showTooltip.emailId === reply.id &&
                                      showTooltip.isViewing
                                    }
                                    placement="bottom"
                                    overlay={
                                      <Tooltip
                                        id="tooltip"
                                        className="email-tooltip"
                                      >
                                        <p className="font-12 m-0 my-2">
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                            }}
                                          >
                                            From:
                                          </span>{" "}
                                          {reply.senderEmail}
                                        </p>
                                        <p className="font-12 m-0 my-2">
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                            }}
                                          >
                                            To:{" "}
                                          </span>
                                          {reply.receiverEmail?.map(
                                            (e, index) =>
                                              e +
                                              `${
                                                index <
                                                reply.receiverEmail.length - 1
                                                  ? ", "
                                                  : ""
                                              }`
                                          )}
                                        </p>
                                        {reply.cc && (
                                          <p className="font-12 m-0 my-2">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              CC:{" "}
                                            </span>
                                            {reply.cc?.map((e, index) => {
                                              return (
                                                e +
                                                `${
                                                  index < reply.cc.length - 1
                                                    ? ", "
                                                    : ""
                                                }`
                                              );
                                            })}
                                          </p>
                                        )}
                                        <p className="font-12 m-0 my-2">
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Subject:
                                          </span>{" "}
                                          {reply.subject}
                                        </p>
                                        <p className="font-12 m-0 my-2">
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Date:
                                          </span>{" "}
                                          {moment(reply.timestamp).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </p>
                                      </Tooltip>
                                    }
                                  >
                                    <span></span>
                                  </OverlayTrigger>
                                </div>
                                <div className="col-12 col-lg-4 p-0 text-right mt-3 mt-lg-0">
                                  <button
                                    className="reply-btn pointer"
                                    onClick={() =>
                                      handleReply({
                                        ...reply,
                                        id: email.id,
                                      })
                                    }
                                  >
                                    <i
                                      class="fa fa-reply mr-2"
                                      aria-hidden="true"
                                      style={{ color: "gray" }}
                                    ></i>
                                    Reply
                                  </button>
                                  <button
                                    className="center-btn pointer"
                                    onClick={() => {
                                      handleForward(reply);
                                    }}
                                  >
                                    <i
                                      class="fa fa-forward mr-2"
                                      aria-hidden="true"
                                      style={{ color: "gray" }}
                                    ></i>
                                    Forward
                                  </button>
                                  <button
                                    className="forward-btn pointer"
                                    onClick={() => {
                                      handleDelete(reply, true);
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash-o"
                                      aria-hidden="true"
                                      style={{ color: "gray" }}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-12 p-0 email-from">
                                  {reply.subject}
                                </div>
                              </div>
                              <hr />
                              <div className="row mt-2">
                                <div className="col-12 p-0 overflow-scroll">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reply.content.replace(
                                        /<p>/g,
                                        '<p style="margin:0px;">'
                                      ),
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  );
}

export default Emails;
