import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import loadImage from 'blueimp-load-image';

export default class Image extends Component {

    render() {
        const { src, className, alt } = this.props
        this.loaded = false
        loadImage(src, (img) => {
            img.className = className

            img.alt = alt
            if (!this.loaded) {
                const node = ReactDOM.findDOMNode(this.imageCanvas);

                if (!node) {
                    this.loaded = true
                    return
                }

                if (node.hasChildNodes()) {
                    node.firstChild.remove();
                }

                node.appendChild(img);
                this.loaded = true
            }
        }, { orientation: true })

        return <div style={{ alignItems: 'center', justifyContent: 'center' }} ref={(ref) => this.imageCanvas = ref} />
    }
}