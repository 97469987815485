import React, { Component } from 'react'
import SubBanner from 'components/SubBanner';
import qs from 'query-string';
import firebase from "firebase/app";
import { FormGroup, Label, Input } from 'reactstrap';
import RatingField  from './RatingField';
import "firebase/firestore";
import "firebase/functions";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class WriteReview extends Component {

    state = {
        resourceId: '',
        type: '',
        name: '',
        valueProvidedOptions: [
            'Sold me a property or lot/land',
            'Bought a property or lot/land from me',
            'Helped me buy a home or lot/land',
            'Helped me sell a home or lot/land',
        ],
        review: {
            overallRatting: 0,
            name: '',
            communicationRatting: 0,
            processRatting: 0,
            professionalRatting: 0,
            timelinessRatting: 0,
            valueProvided: '',
            address: '',
            summary: '',
        },
        inProgress: false,
    };

    onChange = async ({ target }) => {
        const { name: key, value } = target;
        const {review} = this.state;
        this.setState({
          review: {
              ...review,
              [key]: value
          }
        });
    };

    componentDidMount = () => {
        const {
            resourceId,
            type,
            name,
        } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        const { review } = this.state;

        this.setState({
            resourceId,type,name,
            review: {
                ...review,
            }
        });
    }

    submitReview = () => {
        const { resourceId, type, name, review} = this.state;
        this.setState({
            inProgress: true,
        });

        const db = firebase.firestore();

        db.collection("reviews").doc(resourceId).set({
            type, name,
            reviews: firebase.firestore.FieldValue.arrayUnion(review)
        },{ merge: true }).then(() => {
            this.setState({inProgress: false});
            toast.success('Your Review Submitted Successfully.');
        }).catch(() => {
            this.setState({inProgress: false});
            toast.error('Unable to submit review, please try again!');
        });
    }


    onRatingChange = (target, rating) => {
        const { review } = this.state;
        this.setState({
            review: {
                ...review,
                [target]: rating
            } 
        })
    }

    render() {

        const { type, name, review, valueProvidedOptions,  inProgress  } = this.state;

        const { overallRatting, communicationRatting, timelinessRatting, processRatting, professionalRatting, name: reviewerName } = review || {}

        

        return (
            <React.Fragment>
                <SubBanner
                    title="Write a Review"
                    image="/images/mx4mSkK9zeo.jpg"
                    {...this.props}
                />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="review-form-wrapper">
                                <div className="review-head">
                                    <h3>
                                    Write a Review for {type} {name}
                                    </h3>
                                </div>
                                <div className="review-body">
                                    <FormGroup>
                                        <Label>
                                           Your Name
                                        </Label>
                                        <Input type="text" name="name" value={ reviewerName } placeholder = "Your Name" onChange = { this.onChange }></Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                        Rate your overall experience
                                        </Label>
                                        <RatingField isEditable = { true } rating= { overallRatting } name="overallRatting" onChange = { this.onRatingChange }/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>
                                        Rate { name } based on Communication
                                        </Label>
                                        <RatingField isEditable = { true } rating = { communicationRatting } name="communicationRatting" onChange = { this.onRatingChange }/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>
                                        Rate { name } based on Process
                                        </Label>
                                        <RatingField isEditable = { true } rating = { processRatting } name="processRatting" onChange = { this.onRatingChange }/>
                                    </FormGroup>
                                    
                                    <FormGroup>
                                        <Label>
                                        Rate { name } based on Professional
                                        </Label>
                                        <RatingField isEditable = { true } rating = { professionalRatting } name="professionalRatting" onChange = { this.onRatingChange }/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>
                                        Rate { name } based on Timeliness
                                        </Label>
                                        <RatingField isEditable = { true } rating = { timelinessRatting } name="timelinessRatting" onChange = { this.onRatingChange }/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>
                                        Value provided
                                        </Label>
                                        <Input type="select" name="valueProvided" onChange= { this.onChange }>
                                            <option value="">Choose...</option>
                                            {(valueProvidedOptions || []).map((e) => {
                                                return <option>{ e }</option>
                                            })}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                        Street address
                                        </Label>
                                        <Input type="text" placeholder="Property Address" name="address" onChange={ this.onChange }></Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            Summary of your Experience (max 150 characters) 
                                        </Label>
                                        <Input type="textarea" maxLength="150" placeholder="Summary of Experience" name="summary" onChange = { this.onChange}></Input>
                                    </FormGroup>
                                    <div className="post-review-wrapper">
                                        <button onClick={this.submitReview} className="btn btn-primary">{inProgress ? 'Submitting your Review': 'Post Your Review'}</button>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default WriteReview
