import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { Button } from "reactstrap";
import BuyerNotesModal from "./BuyerNotesModal";

export default class LikedBuyerListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likedBuyers: this.props.user.likedBuyers || [],
      image: "/images/face-placeholder.gif"
    };
  }

  componentDidMount = () => {
    const { buyer } = this.props;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const buyerImageRef = storageRef.child(`images/users/${buyer.id}.jpg`);
    buyerImageRef
      .getDownloadURL()
      .then(url => {
        this.setState({ image: url });
      })
      .catch(error => {});
  };

  removeElement = async index => {
    let { likedBuyers } = this.state;
    const { buyer, user, onBuyerRemove } = this.props;

    likedBuyers = likedBuyers.filter(b => b.id !== buyer.id);

    const db = firebase.firestore();
    const userDoc = db.collection("builders").doc(user.uid);
    userDoc
      .set({ likedBuyers }, { merge: true })
      .then(() => {
        if (onBuyerRemove) {
          onBuyerRemove(likedBuyers);
        }
      })
      .catch(() => {
        alert("Something went wrong, please try again later");
      });
  };

  render() {
    const { buyer } = this.props;
    const { image } = this.state;

    return (
      <React.Fragment>
        <div
          className="row"
          onClick={() => {
            this.props.history.push(`/buyers/${buyer.id}`);
          }}
          style={{
            backgroundColor: "#dcdcdc",
            marginTop: "15px",
            cursor: "pointer"
          }}
        >
          <img
            alt=""
            src={image}
            height={"150px"}
            width={"150px"}
            style={{ padding: "15px", borderRadius: "25px" }}
          />
          <div style={{ padding: "15px" }}>
            <h5>
              <i className="flaticon-pin" /> {(buyer.cities || []).join(",")}
            </h5>
            <div>
              <i className="flaticon-financial" /> ${buyer.min_price} - $
              {buyer.max_price}
            </div>
            <div>
              <i className="flaticon-calendar" /> {buyer.purchase_month} - 
              {buyer.purchase_year}
            </div>
            <div>
              <i className="fa fa-bed" /> {buyer.bedrooms}{" "}
              <i className="fa fa-bath" />
              {buyer.bathrooms}  {buyer.sqft_min} - {buyer.sqft_max}
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            backgroundColor: "#dcdcdc",
            cursor: "pointer"
          }}
        >
          <div
            className="col-sm-4 offset-sm-8"
            style={{ paddingBottom: "10px" }}
          >
            <BuyerNotesModal {...this.props}/>
            <Button color="danger" onClick={this.removeElement}>
              Remove <i className="fa fa-times" />
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
