import React, { Component } from "react";
import { connect } from "react-redux";
import "firebase/functions";
// import CreateCardModal from "./../components/Payment/CreateCardModal";
// import CardsList from "./../components/Payment/CardsList";
import Package from "./../components/Payment/Package";
import firebase from "firebase/app";
import "firebase/functions";
import * as actions from "../Store/Actions/type";

class LiveStreamPakcages extends Component {


    componentDidMount() {
        const { uid } = this.props.user;
        firebase
            .firestore()
            .collection("stripe_customers")
            .doc(`${uid}`)
            .collection("sources")
            .onSnapshot(
                ({ docs }) => {
                    const sources = docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        };
                    });
                    this.dispatchSources(sources);
                },
                () => {
                    this.dispatchSources([]);
                }
            );
    }

    dispatchSources = sources => {
        const { dispatch } = this.props;
        dispatch({
            type: actions.SET_SOURCES,
            payload: sources
        });
    };

    renderPackageInfo = () => {

        const { liveStreamPackages, user } = this.props;

        if (liveStreamPackages === undefined) {
            return;
        }

        const offset = liveStreamPackages.length > 1 ? '' : 'offset-md-2';
        const payload = {
            type: 'LiveStreamPakcage'
        };
        return (
            <section className="pricing py-5">
                <div className="container">
                    <div className="row">
                        {liveStreamPackages.map((p, i) => {
                            return <Package key={i} p={p} offset={offset} user={user} payload={payload} />;
                        })}
                    </div>
                </div>
            </section>
        );
    };

    render() {
        return (
            <div className="content-area5 dashboard-content">
                <div className="dashboard-header clearfix">
                    {/* <div className="row">
                        <div className="col-md-12">
                            <h4>Your Cards</h4>
                            <CardsList user={user} />
                            <CreateCardModal user={user} />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-12">
                            <h4>Subscribe to package</h4>
                            {this.renderPackageInfo()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ PaymentReducer }) => {
    return {
        ...PaymentReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveStreamPakcages);
