import React, { Component } from "react";
import firebase from "components/Firebase/firebase";
import "firebase/firestore";
import LoadingOverlay from "react-loading-overlay";
import PartitionedForm from "components/PartitionedForm";
import { sendBuyerSellerEmail } from "../helpers/EmailHelper";
import LocationModal from "components/Location/LocationModal";
import StateSelect from "../components/Common/State/StateSelect";
import CitySelect from "components/Common/City/CitySelect";
import moment from "moment";
import { saveActivity } from "../Services/Activity";

export const sellerFields = {
  offerDeadline: {
    options: ["Yes", "No"],
  },

  ListingId: {
    label: "MLS #",
  },
  financing_type: {
    options: ["Cash", "Conventional", "FHA", "VA", "USDA", "Seller Finance"],
    multiselect: true,
  },
  exterior_features: {
    options: [
      "Arena",
      "Attached Grill",
      "Balcony",
      "Covered Deck",
      "Covered Porch(es)",
      "Deck",
      "Equestrian Center",
      "Gardens",
      "Gazebo/Pergola",
      "Greenhouse",
      "Guest Quarters",
      "Gutters",
      "Holding Pens",
      "Lighting System",
      "Mosquito Mist System",
      "Other",
      "Outdoor Fireplace/Pit",
      "Outdoor Living Center",
      "Patio Covered",
      "Patio Open",
      "Private Hanger",
      "Private Landing Strip",
      "Private Outdoor Space",
      "Roof Top Deck/Patio",
      "Round Pens",
      "RV/Boat Parking",
      "Satellite Dish",
      "Separate Entry Quarters",
      "Sport Court",
      "Sprinkler System",
      "Stable Barn",
      "Storage Building",
      "Storm Cellar",
      "Tennis Court(s)",
      "Workshop",
      "Workshop w/Electric",
    ],
    multiselect: true,
  },
  flooring: {
    width: 3,
    options: [
      "Brick/Adobe",
      "Carpet",
      "Ceramic Tile",
      "Concrete",
      "Laminate",
      "Luxury Vinyl Plank",
      "Marble",
      "Other",
      "Parquet",
      "Slate",
      "Stone",
      "Terrazzo",
      "Vinyl",
      "Wood",
      "Wood Under Carpet",
    ],
    multiselect: true,
  },
  sqft: {
    label: "Sq ft",
    placeholder: "e.g. 2400",
    type: "number",
  },
  sqft_source: {
    label: "Sq ft Source",
    options: ["Tax", "Building Plan", "Appraisal", "Other Documentation"],
  },
  sqft_source_documentation_type: {
    label: "Sq ft Documentation Type",
    placeholder: "Other documentation type",
    type: "text",
    required: true,
    isVisible: (state) => state.inputs.sqft_source === "Other Documentation",
  },
  listing_price: {
    type: "currency",
    required: true,
  },
  buyerToVerifyInformation: {
    type: "checkbox",
    label: `Buyers and Buyer's Agent to verify all information contained herein, including schools and square footage.`,
  },
  waterfront: {
    options: ["No", "Yes"],
    width: 2,
  },
  occupancy_type: {
    options: ["Homeowner", "Tenant", "Estate Sale", "Vacant"],
  },
  waterfront_features: {
    options: [
      "Boat Dock w/ Lift",
      "Boat Dock w/Slip",
      "Canal (Man Mad)",
      "Dock – Covered",
      "Dock – Enclosed",
      "Dock – Uncovered",
      "Lake Front",
      "Lake Front – Common Area",
      "Lake Front – Corps of Engineers",
      "Lake Front – Main Body",
      "Leasehold",
      "Personal Watercraft Lift",
      "Retaining Wall – Concrete",
      "Retaining Wall – Other",
      "Retaining Wall – Steel",
      "Retaining Wall – Wood",
      "River Front",
      "Water Board Authority – HOA",
      "Water Board Authority – Private",
    ],
    multiselect: true,
    isVisible: (state) => state.inputs.waterfront === "Yes",
  },
  type_of_fence: {
    options: [
      "Automatic Gate",
      "Barbed Wire",
      "Brick",
      "Chain Link",
      "Cross Fenced",
      "Dog Run",
      "Iron",
      "Metal",
      "Net",
      "None",
      "Other",
      "Partially Fenced",
      "Pipe",
      "Rail",
      "Rock/Stone",
      "Slick/Smooth Wire",
      "Vinyl",
      "Wood",
    ],
    multiselect: true,
  },
  year_built: {
    min: 1900,
    type: "number",
    width: 2,
  },
  bedrooms: {
    width: 2,
    required: true,
    type: "number",
  },
  full_baths: {
    width: 2,
    required: true,
    type: "number",
  },
  bathrooms: {
    width: 2,
    required: true,
    type: "number",
  },
  half_baths: {
    width: 2,
    required: true,
    type: "number",
  },
  stories: {
    label: "Story",
    width: 2,
    required: true,
    type: "number",
  },
  acres: {
    width: 2,
    type: "number",
    step: 0.001,
  },
  living_areas: {
    width: 2,
    required: true,
    type: "number",
  },
  dining_areas: {
    width: 2,
    required: true,
    type: "number",
  },
  subdivision: {
    width: 2,
  },
  style_of_house: {
    multiselect: true,
    options: [
      "A-Frame",
      "Barndominium",
      "Colonial",
      "Contemporary/Modern",
      "Craftsman",
      "Early American",
      "English",
      "French",
      "Geo/Dome",
      "Loft",
      "Mediterranean",
      "Mid-Century Modern",
      "Oriental",
      "Other",
      "Prairie",
      "Ranch",
      "Southwestern",
      "Spanish",
      "Split Level",
      "Studio",
      "Traditional",
      "Tudor",
      "Victorian",
    ],
  },
  heating_cooling: {
    multiselect: true,
    options: [
      "Additional Water Heater(s)",
      "Central Air-Elec",
      "Central Air-Gas",
      "Central Heat-Elec",
      "Central Heat-Gas",
      "Electrostatic Air Filter",
      "Evaporation",
      "Gas Jets",
      "Geotherm",
      "Heat Pump",
      "Humidifier",
      "No Air",
      "No Heat",
      "Other",
      "Panel/Floor/Wall",
      "Propane",
      "Solar",
      "Space Heater",
      "Window Unit",
      "Zoned",
    ],
    label: "Heating/Cooling",
  },
  parking_features: {
    options: [
      "Assigned Garage",
      "Assigned Spaces",
      "Attached",
      "Circle Drive",
      "Common Garage",
      "Common Lot",
      "Covered",
      "Detached",
      "Front",
      "Garage",
      "Garage Conversion",
      "Garage Door Opener",
      "Garage Under Building",
      "Golf Cart Garage",
      "Individual Carport",
      "None",
      "On Street",
      "Open",
      "Open and Unassigned Garage",
      "Other ",
      "Outside Entry",
      "Oversized",
      "Rear",
      "Shared Carport",
      "Shared Garage",
      "Side",
      "Swing Drive",
      "Tandem Style",
      "Unassigned Spaces",
      "Uncovered",
      "Valet",
    ],
    multiselect: true,
    isVisible: (state) => state.inputs.garage && state.inputs.garage > 0,
  },
  hoa: {
    options: ["None", "Mandatory", "Voluntary"],
    width: 2,
    label: "HOA",
  },
  hoa_dues: {
    type: "number",
    width: 2,
    label: "Dues",
    isVisible: (state) => state.inputs.hoa && state.inputs.hoa !== "None",
  },
  hoa_billing_frequency: {
    options: [
      "Annual",
      "Semi-Annually",
      "Monthly",
      "Quarterly",
      "Semi-monthly",
      "Other",
    ],
    width: 3,
    label: "Billing Frequency",
    isVisible: (state) => state.inputs.hoa && state.inputs.hoa !== "None",
  },
  hoa_includes: {
    width: 6,
    options: [
      "All Utilities",
      "Back Yard Maintenance",
      "Blanket Insurance",
      "Electric",
      "Exterior Maintenance",
      "Front Yard Maintenance",
      "Full Use of Facilities",
      "Gas",
      "Maintenance of Common Areas",
      "Management Fees",
      "None",
      "Other",
      "Partial Use of Facilities",
      "Reserves",
      "Security",
      "Sprinkler System",
      "Trash",
      "Water/Sewer",
    ],
    label: "Includes",
    multiselect: true,
    isVisible: (state) => state.inputs.hoa && state.inputs.hoa !== "None",
  },
  street_number: {
    required: true,
    width: 2,
    placeholder: "e.g. 33",
  },
  street_name: {
    required: true,
    width: 4,
    placeholder: "e.g. Main St",
  },
  unit_number: {
    width: 2,
    placeholder: "e.g. 2A",
  },
  zip_code: {
    required: true,
    type: "number",
    width: 2,
  },
  property_type: {
    options: [
      { value: "Attached", key: "Attached" },
      { value: "Attached or Half-duplex", key: "Attached or Half-duplex" },
      { value: "Condo/Townhome", key: "Condo/Townhome" },
      {
        value: "Designated Historical Home",
        key: "Designated Historical Home",
      },
      { value: "Doublewide Mobile w/Land", key: "Doublewide Mobile w/Land" },
      { value: "Farm/Ranch House", key: "Farm/Ranch House" },
      { value: "Garden/Zero Lot Line", key: "Garden/Zero Lot Line" },
      { value: "Hi Rise", key: "Hi Rise" },
      {
        value: "Historical/Conservation Dist.",
        key: "Historical/Conservation Dist.",
      },
      { value: "Interval Ownership", key: "Interval Ownership" },
      { value: "Lake House", key: "Lake House" },
      { value: "Log Cabin", key: "Log Cabin" },
      {
        value: "Manufactured (certificate exch)",
        key: "Manufactured (certificate exch)",
      },
      { value: "Resort Property", key: "Resort Property" },
      { value: "Single Detached", key: "Single Detached" },
      { value: "Singlewide Mobile w/Land", key: "Singlewide Mobile w/Land" },
      { value: "Underground", key: "Underground" },
      { value: "Vacation Home", key: "Vacation Home" },
      { value: "MUL-Apartment/5Plex+", key: "MUL-Apartment/5Plex+" },
      { value: "MUL-Fourplex", key: "MUL-Fourplex" },
      { value: "MUL-Full Duplex", key: "MUL-Full Duplex" },
      { value: "MUL-Multiple Single Units", key: "MUL-Multiple Single Units" },
      { value: "MUL-Triplex", key: "MUL-Triplex" },
      { value: "LND-Commercial", key: "LND-Commercial" },
      { value: "LND-Farm/Ranch", key: "LND-Farm/Ranch" },
      { value: "LND-Residential", key: "LND-Residential" },
    ],
    required: true,
    width: 3,
  },
  construction_status: {
    options: [
      { value: "New Construction - Incomplete", key: "Incomplete" },
      { value: "New Construction - Complete", key: "Complete" },
      { value: "Preowned", key: "Preowned" },
      { value: "Project Planning - Area Development", key: "Area Development" },
      { value: "Unknown", key: "Unknown" },
    ],
    width: 3,
  },
  pool: { options: ["No", "Yes"] },
  fireplace: {
    options: ["No", "Yes"],
  },
  fireplace_type: {
    required: true,
    options: ["Wood", "Gas"],
    isVisible: (state) => state.inputs.fireplace === "Yes",
  },
  patio: { options: ["Unknown", "Uncovered", "Covered"] },
  foundation: {
    options: [
      "Basement",
      "Bois DArc Post",
      "Other",
      "Pier & Beam",
      "Piered Beam Slab",
      "Pilings",
      "Slab",
    ],
  },
  mud_district: {
    label: "MUD District",
    options: ["No", "Yes"],
  },
  study: { required: false, options: ["No", "Yes"] },
  school_district: {
    width: 4,
  },
  elementary_school: {
    width: 4,
  },
  middle_school: {
    width: 4,
  },
  property_description: {
    type: "textblob",
    label: "",
    width: 12,
  },
  special_instructions: {
    type: "textblob",
    label: "",
    width: 12,
  },
  high_school: {
    width: 4,
  },
  division: {},
  garage: {
    type: "number",
    width: 2,
  },
  common_features: {
    options: [
      "Boat Ramp",
      "Campground",
      "Club House",
      "Comm. Sprinkler System",
      "Common Elevator",
      "Community Dock",
      "Community Pool",
      "Elec Car Charging Station",
      "Gated Entrance",
      "Golf",
      "Greenbelt",
      "Guarded Entrance",
      "Hangar",
      "Horse Facilities",
      "Jogging Path/Bike Path",
      "Landing Strip",
      "Laundry",
      "Marina",
      "Other",
      "Park",
      "Perimeter Fencing",
      "Playground",
      "Private Lake/Pond",
      "Public Hangar",
      "Racquet Ball",
      "RV Parking",
      "Sauna",
      "Spa",
      "Tennis",
    ],
    multiselect: true,
  },
  seller_email: { width: 4, required: true },
  seller_phone: { width: 4, required: true },
  seller_name: { width: 4, required: true },
  title_company: { width: 3, required: false },
  escrow_officer: { width: 3, required: false },
  escrow_officer_phone: { width: 3, required: false },
  escrow_officer_email: { width: 3, required: false },
  company_to_tc: { width: 3, required: false },
  transaction_coordinator: { width: 3, required: false },
  transaction_coordinator_phone: { width: 3, required: false },
  transaction_coordinator_email: { width: 3, required: false },
  vr_tours: {
    type: "textblob",
    label: "3D/VR Tours",
    placeholder: "Enter embed code here. Enter a tour per line.",
  },
  virtual_design_center: {
    type: "textblob",
    label: "Design Center Tour",
    placeholder: "Enter embed Videos URLs. One per line.",
  },
  home_videos: {
    type: "textblob",
    label: "Home Videos",
  },
  interactive_floor_plans: {
    type: "text",
    label: "Floor Plans",
    placeholder: "Pleas enter website URL here",
  },
  community_view: {
    type: "textblob",
    label: "Community View",
    placeholder: "Enter embed Videos URLs. One per line.",
  },
  // galleryCheckbox: {
  //   label: "Single Gallery",
  //   type: "checkbox",
  //   checked: "checked",
  // },
  gallery: {
    // title: "Other Gallery",
    label: null,
    type: "gallery",
  },
  bm_purchase_price: {
    label: "Purchase Price",
    type: "text",
  },
  bm_closing_date: {
    label: "Closing Date",
    type: "date",
    width: 4,
  },
  bm_finance_type: {
    label: "Finance Type",
    options: ["Cash", "Lease", "Conventional", "FHA", "VA", "Seller Finance"],
  },
  bm_pref_1: {
    label: "First Preference",
    options: ["Purchase Price", "Closing Date", "Finance Type"],
  },
  bm_pref_2: {
    label: "Second Preference",
    options: ["Purchase Price", "Closing Date", "Finance Type"],
  },
  bm_pref_3: {
    label: "Third Preference",
    options: ["Purchase Price", "Closing Date", "Finance Type"],
  },
};

const sections = [
  {
    name: "Address",
    fields: [
      "street_number",
      "street_name",
      "unit_number",
      "state",
      "zip_code",
      "city",
      "location",
    ],
  },
  {
    name: "Basic Information",
    fields: [
      "property_type",
      "listing_price",
      "subdivision",
      "acres",
      "mud_district",
      "sqft",
      "sqft_source",
      "sqft_source_documentation_type",
      "offerDeadlineTime",
    ],
  },
  {
    name: "Room Details",
    fields: [
      "bedrooms",
      "bathrooms",
      "full_baths",
      "half_baths",
      "stories",
      "living_areas",
      "dining_areas",
    ],
  },
  {
    name: "Property Details",
    fields: [
      "style_of_house",
      "heating_cooling",
      "construction_status",
      "year_built",
      "flooring",
      "waterfront",
      "waterfront_features",
      "type_of_fence",
      "garage",
      "parking_features",
      "school_district",
      "elementary_school",
      "middle_school",
      "high_school",
    ],
  },
  {
    name: "Property Description",
    fields: [
      "property_description",
      "special_instructions",
      "buyerToVerifyInformation",
    ],
  },
  {
    name: "HOA",
    fields: ["hoa", "hoa_dues", "hoa_billing_frequency", "hoa_includes"],
  },
  // {
  //   name: "Marketing Tools",
  //   fields: [
  //     "vr_tours",
  //     "interactive_floor_plans",
  //     "home_videos",
  //     "community_view",
  //     "virtual_design_center",
  //     "addToolButton",
  //   ],
  // },
  {
    name: "Seller Information",
    fields: [
      "seller_name",
      "seller_email",
      "seller_phone",
      "represendtedBy",
      "buyers_agent_commission_type",
      "buyers_agent_commission_amount",
      "tags",
      "is_wholesale",
    ],
  },
  {
    name: "Title Information",
    fields: [
      "title_company",
      "escrow_officer",
      "escrow_officer_phone",
      "escrow_officer_email",
    ],
  },
  {
    name: "Transaction Coordinator",
    fields: [
      "company_to_tc",
      "transaction_coordinator",
      "transaction_coordinator_phone",
      "transaction_coordinator_email",
    ],
  },
  {
    name: "Dream Board",
    isVisible: (state) => state.inputs.galleryCheckbox,
    description:
      "Upload home images below.  Use single gallery for upload or upload images to each category for the best result.",
    field: "galleryCheckbox",
    fields: ["gallery"],
  },
  {
    name: "Dream Board.",
    isVisible: (state) => !state.inputs.galleryCheckbox,
    description:
      "Upload home images below.  Use single gallery for upload or upload images to each category for the best result.",
    // tabs: true,
    field: "galleryCheckbox",
    fields: ["gallery"],
  },
  {
    name: "Best Match",
    fields: [
      "bm_purchase_price",
      "bm_closing_date",
      "bm_finance_type",
      "bm_pref_1",
      "bm_pref_2",
      "bm_pref_3",
    ],
  },
  {
    name: "Other Features",
    fields: [
      "common_features",
      "study",
      "pool",
      "fireplace",
      "fireplace_type",
      "patio",
      "foundation",
      "exterior_features",
      "financing_type",
      "occupancy_type",
    ],
  },
];

const db = firebase.firestore();

class SellerProfile extends Component {
  constructor(props) {
    super(props);

    const inputs = {};

    for (const field of sections.map((s) => s.fields).flat()) {
      inputs[field] = "";
    }

    this.state = {
      error: "",
      citiesYearlyGrowth: {},
      busy: false,
      loading: false,
      showLoading: false,
      inputs: {
        ...inputs,
        dontDisplayContactInfo: false,
        hideAddress: true,
        userInvited: false,
        hiddenProfile: false,
      },
      location: undefined,
      formsSubmitted: {},
      tags: [],
      offerDeadlineTime: null,
      dateFocused: false,
      offerDateFocused: false,
      marketingTools: [],
    };

    this.mainForm = React.createRef();

    const db = firebase.firestore();
    db.collection("scrapedData")
      .doc("citiesYearlyGrowth")
      .get()
      .then((doc) => {
        const citiesYearlyGrowth = doc.data();
        this.setState({ citiesYearlyGrowth });
      });
  }

  id() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    return id;
  }

  componentWillMount() {
    if (this.newRecord()) {
      return;
    }

    const id = this.id();

    if (!id) {
      this.props.history.push("/sellers");
      return;
    }

    const db = firebase.firestore();
    const sellerRef = db.collection("sellers").doc(id);
    this.setState({ loading: true });

    sellerRef
      .get()
      .then((doc) => {
        const { inputs } = this.state;
        const data = doc.data() || {};
        data.gallery = data.gallery || [];

        let matchKey = "gallery";

        for (let photo of data[matchKey]) {
          photo.featured = data.featured_image
            ? photo.url === data.featured_image.url
            : false;
        }

        Object.assign(inputs, data);
        if (data.agents) inputs.agents = data.agents;
        if (!inputs.listing_price) inputs.listing_price = data.current_value;
        if (!inputs.full_baths) inputs.full_baths = data.bathrooms;
        if (!inputs.half_baths) inputs.half_baths = 0;
        if (!inputs.living_areas) inputs.living_areas = data.sqft;
        if (!inputs.dining_areas) inputs.dining_areas = 0;
        if (inputs.bm_closing_date)
          inputs.bm_closing_date = data.bm_closing_date.seconds
            ? new Date(data.bm_closing_date.seconds * 1000)
            : new Date();

        let processedData = {};
        Object.keys(inputs).forEach((k) => {
          let v = inputs[k];
          if (typeof v == "number" && !v) {
            processedData[k] = 0;
          } else {
            processedData[k] = v;
          }
        });

        const { offerDeadlineTime: time } = processedData || {};
        const offerDeadlineTime = processedData.offerDeadlineTime
          ? processedData.offerDeadlineTime
          : "00:00";

        let marketingTools = [];

        let toolIndex = 0;

        while (processedData[`tool_${toolIndex}`]) {
          marketingTools.push(processedData[`tool_${toolIndex}`]);
          processedData[`tool_${toolIndex}`] =
            processedData[`tool_${toolIndex}`].value;
          toolIndex++;
        }

        this.setState({
          inputs: processedData,
          tags: inputs.tags || [],
          loading: false,
          location: processedData.location,
          offerDeadlineTime,
          marketingTools,
        });
      })
      .catch((error) => console.error(error));

    this.timer = setTimeout(() => {
      const { loading } = this.state;

      if (loading) {
        // if still loading, show loading indicator
        this.setState({ showLoading: true });
      }
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  newRecord() {
    return this.id() === "new";
  }

  generateHMXId = () => {
    let id = Math.floor(Date.now() * Math.random()) % 10000000000;
    if (id.toString().length === 10) {
      return id;
    } else {
      this.generateHMXId();
    }
  };

  getCombinations = (chars) => {
    var result = [];
    var f = function (prefix, chars) {
      for (var i = 0; i < chars.length; i++) {
        result.push(prefix + " " + chars[i]);
        f(prefix + " " + chars[i], chars.slice(i + 1));
      }
    };
    f("", chars);
    return result.map((s) => s.trim());
  };

  save = async (e, draft = false) => {
    e.preventDefault();

    if (!draft && !this.mainForm.current.checkValidity()) {
      return;
    }

    const {
      inputs,
      location,
      sellerEmailChanged,
      tags,
      offerDeadlineTime,
      marketingTools,
    } = this.state;

    this.setState({ busy: true, savingDraft: draft });

    const galleryKeys = ["gallery"];

    const updatedGalleriesObject = {};

    galleryKeys.forEach((key) => {
      updatedGalleriesObject[key] = inputs[key] || [];
      delete inputs[key];
    });

    for (const fieldName of sections.map((s) => s.fields).flat()) {
      const field = sellerFields[fieldName];

      if (!field) {
        continue;
      }

      if (field.isVisible && !field.isVisible(this.state)) {
        if (this.newRecord()) {
          delete inputs[fieldName];
        } else {
          inputs[fieldName] = firebase.firestore().FieldValue.delete();
        }
      }
    }

    inputs.draft = draft;

    marketingTools.forEach((v, index) => {
      if (inputs[`tool_${index}`]) {
        inputs[`tool_${index}`] = {
          ...v,
          value: inputs[`tool_${index}`],
          width: 12,
          type: v.type === "textarea" ? "textblob" : v.type,
        };
      }
    });

    const { user } = this.props;
    const { role } = user;
    let profile = {};
    const db = firebase.firestore();
    const realtorRef = db.collection("realtors").doc(user.uid);
    const doc = await realtorRef.get();
    profile = doc.data();

    inputs.realtor_name =
      role === "user"
        ? user.full_name
        : role === "realtor"
        ? profile.realtor_name
        : "";
    user.realtor_name = inputs.realtor_name;
    inputs.last_updated = new Date();
    inputs.last_updated = new Date();
    inputs.createdAt = new Date().toUTCString();
    inputs.createdAt_unix = moment().utc().unix();
    inputs.seller_id = user.uid;

    inputs.offerDeadlineTime = offerDeadlineTime;

    let featuredImageGalleryKey = "gallery";

    if (!inputs.galleryCheckbox) {
    }

    const featuredImage = updatedGalleriesObject[featuredImageGalleryKey].find(
      (p) => p.url && p.featured && p.change !== "deleted"
    );

    inputs.featured_image =
      featuredImage ||
      updatedGalleriesObject[featuredImageGalleryKey].find(
        (p) => p.url && p.change !== "deleted"
      ) ||
      {};

    if (inputs && inputs.HMX_Id) {
    } else {
      inputs.HMX_Id = "HMX" + this.generateHMXId();
    }

    if (role === "realtor") {
      inputs.represendtedBy = user.email;
    }

    let subdivision = inputs.subdivision.split(" ");
    subdivision = this.getCombinations(subdivision);

    let search = [
      ...subdivision,
      inputs.street_number,
      inputs.street_name,
      inputs.unit_number,
      inputs.city,
      inputs.state,
      inputs.zip_code,
      inputs.HMX_Id,
      inputs.ListingKey || "",
      inputs.ListingId || "",
      inputs.ListAgentMlsId || "",
      `${inputs.city} ${inputs.state}`,
      `${inputs.city}, ${inputs.state}`,
      `${inputs.street_number} ${inputs.street_name}`,
      `${inputs.street_number} ${inputs.street_name} ${inputs.unit_number}`,
    ];

    search = search.map((s) => s.toLowerCase()).filter((s) => s !== "");
    inputs.location = location || {};

    tags.forEach(({ value: t }) => {
      const chars = t.toLowerCase().split("");
      const words = t.toLowerCase().split(" ");
      words.forEach((word) => {
        search.push(word);
      });
      let string = "";
      chars.forEach((c) => {
        string += c;
        search.push(string);
      });
    });

    inputs.search = search;
    inputs.tags = tags;
    inputs.isBuilderMade = role === "builder";

    if (this.newRecord()) {
      inputs.MlsStatus = "Active";
      inputs.owner = user.uid;
      inputs.removed = false;
      db.collection("sellers")
        .add(inputs)
        .then((sellerRef) => {
          if (inputs.userInvited && !draft && sellerEmailChanged) {
            sendBuyerSellerEmail(user, "seller", {
              id: sellerRef.id,
              email: inputs.seller_email,
            });
          }

          saveActivity({
            text: `Seller has created a new listing.`,
            userName: user.full_name || user.realtor_name,
            userId: user.uid,
            type: "sellers",
            linkId: sellerRef.id,
            address: `${inputs.street_number} ${inputs.street_name} ${inputs.city} ${inputs.state} ${inputs.zip_code}`,
          });

          const promieses = [];

          galleryKeys.forEach((key) => {
            const changed = updatedGalleriesObject[key].filter((p) => p.change);
            if (changed.length) {
              const pr = this.uploadGallery(
                sellerRef.id,
                [],
                changed,
                undefined,
                key
              );
              promieses.push(pr);
            }
          });

          Promise.all(promieses)
            .then(() => {
              this.props.history.push("/sellers");
            })
            .catch((error) => {
              this.setState({ busy: false });
              console.error(error);
            });
        })
        .catch((error) => {
          this.setState({ busy: false });
          console.error(error);
        });
    } else {
      // return console.log(inputs.year_built);
      db.collection("sellers")
        .doc(this.id())
        .update(inputs)
        .then((sellerRef) => {
          if (inputs.userInvited && !draft && sellerEmailChanged) {
            sendBuyerSellerEmail(user, "seller", {
              id: this.id(),
              email: inputs.seller_email,
            });
          }

          saveActivity({
            text: `Seller has updated listing.`,
            userName: user.full_name || user.realtor_name,
            userId: user.uid,
            type: "sellers",
            linkId: this.id(),
            address: `${inputs.street_number} ${inputs.street_name} ${inputs.city} ${inputs.state} ${inputs.zip_code}`,
          });

          const promieses = [];

          galleryKeys.forEach((key) => {
            const newOrder = updatedGalleriesObject[key];
            const changed = updatedGalleriesObject[key].filter((p) => p.change);
            if (changed.length) {
              db.collection("sellers")
                .doc(this.id())
                .get()
                .then((sellerRef) => {
                  const data = sellerRef.data();
                  const pr = this.uploadGallery(
                    sellerRef.id,
                    data[key],
                    changed,
                    newOrder,
                    key
                  );
                  promieses.push(pr);
                });
            } else if (newOrder.length) {
              db.collection("sellers")
                .doc(this.id())
                .get()
                .then((sellerRef) => {
                  const data = sellerRef.data();
                  const pr = this.uploadSortedGallery(
                    sellerRef.id,
                    data[key],
                    newOrder,
                    key
                  );
                  promieses.push(pr);
                });
            } else {
              this.props.history.push("/sellers");
            }
          });

          Promise.all(promieses)
            .then(() => {
              this.props.history.push("/sellers");
            })
            .catch((error) => {
              this.setState({ busy: false });
              console.error(error);
            });
        })
        .catch((error) => {
          this.setState({ busy: false });
          console.error(error);
        });
    }
  };

  uploadSortedGallery(id, gallery, photos, key) {
    const {
      inputs: { galleryCheckbox },
    } = this.state;

    return new Promise((resolve, reject) => {
      if (photos) {
        let gallery = photos.map((photo, i) => {
          photo.featured = i === 0;
          return photo;
        });

        const update = {
          [key]: gallery,
        };

        let matchKey = "gallery";

        if (!galleryCheckbox) {
        }

        if (key === matchKey) {
          update.featured_image = gallery.length > 0 ? { ...gallery[0] } : {};
        }

        db.collection("sellers")
          .doc(id)
          .update(update)
          .then(resolve)
          .catch(reject);
      }
    });
  }

  uploadGallery(id, gallery, photos, newOrder, key) {
    const {
      inputs: { galleryCheckbox },
    } = this.state;

    return new Promise((resolve, reject) => {
      const storage = firebase.storage();
      const propertiesRef = storage.ref().child(`images/properties/${id}`);

      const added = photos.filter((p) => p.change === "added");
      const deleted = photos.filter((p) => p.change === "deleted" && p.url);

      let addDone = false;
      let deleteDone = false;

      if (added.length) {
        Promise.all(
          added.map((photo) => {
            const photoId =
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15);
            const sellerImageRef = propertiesRef.child(`${photoId}.jpg`);

            return sellerImageRef.put(photo.image);
          })
        )
          .then((snapshots) => {
            Promise.all(
              snapshots.map((snapshot) => snapshot.ref.getDownloadURL())
            ).then((urls) => {
              const photos = gallery || [];
              const newPhotos = urls.map((url, index) => {
                const p = {
                  url: url,
                  path: snapshots[index].ref.location.path,
                  order: index,
                };
                return p;
              });
              let newOrderedPhotos = [];
              if (newOrder) {
                newOrder.map((photo, i) => {
                  photo.order = i;
                  return photo;
                });
                newOrder.map((photo) => {
                  photos.map((galleryPhoto) => {
                    if (galleryPhoto.url === photo.url) {
                      newOrderedPhotos[photo.order] = galleryPhoto;
                    }
                    return galleryPhoto;
                  });
                  return photo;
                });
                let addedCount = 0;
                newOrder.map((photo, index) => {
                  if (!photo.url) {
                    newOrderedPhotos[index] = newPhotos[addedCount];
                    addedCount++;
                  }
                  return photo;
                });
              } else {
                newPhotos.forEach((photo, i) => {
                  newOrderedPhotos[i] = photo;
                });
              }
              const updates = { [key]: newOrderedPhotos };

              let matchKey = "gallery";

              if (!galleryCheckbox) {
              }

              if (key === matchKey) {
                updates.featured_image = newOrderedPhotos[0];
              }

              db.collection("sellers")
                .doc(id)
                .update(updates)
                .then(() => {
                  addDone = true;
                  if (deleted.length === 0 || deleteDone) {
                    resolve();
                  }
                });
            });
          })
          .catch(reject);
      }

      if (deleted.length) {
        const deletedPaths = deleted.map((p) => p.path);
        const newPhotos = gallery.filter(
          (p) => deletedPaths.indexOf(p.path) === -1
        );

        db.collection("sellers")
          .doc(id)
          .update({ [key]: newPhotos })
          .then(() => {
            Promise.all(
              deletedPaths.map((path) => storage.ref().child(path).delete())
            )
              .then(() => {
                if (!added.length || addDone) {
                  resolve();
                }
              })
              .catch(() => {
                if (!added.length || addDone) {
                  resolve();
                }
              });
          });
      }
    });
  }

  title() {
    const { user } = this.props;
    const { role } = user;

    const createSellerLabel =
      role === "builder" ? "Create Home" : "Create Seller";

    if (this.newRecord()) {
      return createSellerLabel;
    } else {
      return (
        "Edit " +
        (this.state.inputs.street_number && this.state.inputs.street_name
          ? this.state.inputs.street_number +
            " " +
            this.state.inputs.street_name
          : this.state.inputs.seller_name)
      );
    }
  }

  setValue(inputId, value) {
    const { inputs, citiesYearlyGrowth } = this.state;
    const sellerEmailChanged =
      inputId === "seller_email" && value !== inputs.seller_email;
    inputs[inputId] = value;
    if (inputs.city) {
      const cityGrowthData =
        citiesYearlyGrowth[inputs.city.toLowerCase().replace(" ", "-")];
      if (cityGrowthData) {
        let { growth } = cityGrowthData;
        growth = parseFloat(growth);

        let { listing_price } = inputs;
        listing_price = parseInt(listing_price);

        let currentYear = moment().format("YYYY");
        currentYear = parseInt(currentYear);
      }
    }
    this.setState({ inputs, sellerEmailChanged });
  }
  toggleCheckbox = (e) => {
    const { inputs } = this.state;
    inputs[e.target.name] = e.target.checked;
  };

  buttons(size) {
    const { busy, savingDraft } = this.state;
    const disabled = busy;

    const buttonText = (draft, suffix = "", prefix = "") => {
      return busy && savingDraft === draft
        ? "Saving"
        : this.newRecord()
        ? `${prefix || "Create"} ${suffix}`.trim()
        : `${prefix || "Save"} ${suffix}`.trim();
    };

    return (
      <React.Fragment>
        {(() => {
          if (!this.newRecord()) {
            const { inputs } = this.state;
            const { draft } = inputs;
            const prefix = draft ? "Activate Profile" : "Hide Profile";
            const color = draft ? "success" : "danger";
            return (
              <React.Fragment>
                <button
                  className={`btn btn-${size} btn-${color}`}
                  onClick={(e) => {
                    this.save(e, !draft);
                  }}
                  disabled={disabled}
                >
                  {buttonText(true, "", prefix)}
                </button>
                &nbsp;
              </React.Fragment>
            );
          }
        })()}
        <button
          className={`btn btn-${size} btn-info`}
          onClick={(e) => {
            this.save(e, true);
          }}
          disabled={disabled}
        >
          {buttonText(true, "draft")}
        </button>
        &nbsp;
        <button
          onClick={(e) => {
            this.mainForm.current.className += " submitted";
            this.save(e, false);
          }}
          className={`btn btn-${size} button-theme`}
          disabled={disabled}
        >
          {buttonText(false)}
        </button>
      </React.Fragment>
    );
  }

  onLocationChange = (location) => {
    this.setState({
      location,
    });
  };

  onTagsChange = (tags) => {
    this.setState({ tags });
  };

  onCityChange = (cities) => {
    let city = "";
    if (cities && cities.length > 0) {
      city = cities[0];
    }
    const { inputs } = this.state;
    this.setState(
      {
        inputs: {
          ...inputs,
          city,
        },
      },
      () => {}
    );
  };

  onStateChange = (states) => {
    let state = "";
    if (states && states.length > 0) {
      state = states[0].value.code;
    }
    const { inputs } = this.state;

    this.setState({
      inputs: {
        ...inputs,
        state,
      },
    });
  };

  onDateChange = (key, date) => {
    const { inputs } = this.state;
    this.setState({
      [key]: date,
      inputs: {
        ...inputs,
        [key]: date ? date.format("DD/MM/YYYY") : "",
      },
    });
  };

  addTool = (tool) => {
    const { marketingTools } = this.state;
    this.setState({
      marketingTools: [...marketingTools, tool],
    });
  };

  render() {
    let { loading, showLoading, location, marketingTools } = this.state;
    const { user } = this.props;

    let formFields = {
      ...sellerFields,
      label: {
        type: "custom",
        label:
          "upload your home images for each category below. Property Images are matched to buyer inspired  images by category.",
        width: 12,
        jsx: null,
      },
      location: {
        type: "custom",
        label: "Select Location",
        width: 12,
        jsx: (
          <LocationModal
            location={location}
            placeholder="Property Location"
            centerAroundCurrentLocation
            onLocationChange={this.onLocationChange}
            style={{ width: "93%", height: "320px", marginTop: "10px" }}
            modalStyle={{ width: "100%", height: "400px" }}
          />
        ),
      },
      state: {
        type: "custom",
        jsx: (
          <StateSelect
            states={[this.state.inputs.state]}
            onChange={this.onStateChange}
          />
        ),
      },
      city: {
        type: "custom",
        jsx: (
          <CitySelect
            cities={[this.state.inputs.city]}
            onChange={this.onCityChange}
          />
        ),
      },
    };

    marketingTools.forEach((v, index) => {
      formFields[`tool_${index}`] = {
        ...v,
        width: 12,
        type: v.type === "textarea" ? "textblob" : v.type,
      };
    });

    if (loading) {
      if (showLoading) {
        return (
          <div className="content-area5 dashboard-content">
            <div className="submit-address dashboard-list">
              <p>Loading</p>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
    const quickSections = [
      "Address",
      "Basic Information",
      "Room Details",
      "Room Details",
      "Property Description",
      "HOA",
      "Marketing Tools",
      "Seller Information",
      "Title Information",
      "Transaction Coordinator",
      "Dream Board",
      "Dream Board.",
    ];

    const formSection = [
      ...sections,
      {
        name: "Marketing Tools",
        fields: [
          ...marketingTools.map((v, index) => `tool_${index}`),
          "addToolButton",
        ],
      },
    ];

    return (
      <LoadingOverlay active={this.state.busy} spinner text="Saving...">
        <div className="content-area5 dashboard-content">
          <div className="dashboard-header clearfix">
            <div className="row">
              <div className="col-sm-6 col-md-6">
                <h4>{this.title()}</h4>
              </div>
              <div className="col-sm-6 col-md-6" style={{ textAlign: "right" }}>
                {this.buttons("sm")}
              </div>
            </div>
          </div>

          <div className="submit-address dashboard-list">
            <form onSubmit={this.save} ref={this.mainForm}>
              <PartitionedForm
                fields={formFields}
                sections={formSection}
                setValue={this.setValue.bind(this)}
                values={this.state.inputs}
                quickSections={quickSections}
              />

              <div className="row" style={{ paddingLeft: 25 }}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      name="userInvited"
                      defaultChecked={false}
                      onChange={this.toggleCheckbox}
                    />{" "}
                    Invite this user to homematchx
                  </label>
                  <br />
                </div>
              </div>
              <br />

              <div className="row" style={{ paddingLeft: 25 }}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  {this.buttons("md")}
                </div>
              </div>
            </form>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default SellerProfile;
