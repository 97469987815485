import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/firestore'
import SellerList from 'components/SellerList'
import {getUsersSellers} from './../Services/SellerService';
import "react-toastify/dist/ReactToastify.css";
import { Button } from 'reactstrap';
import qs from 'query-string';
import '../assets/css/sellers.css';
import Axios from 'axios';

class Sellers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            sellers: [],
            loading: true,
            showDetails: true,
            offerStatusFilter: '',
            statusFilterOptions: [
                'Active',
                'Active Option Contract',
                'Pending',
                'Active Contingent',
                'Active Kick Out',
                'Temporary Off Market',
                'Cancelled',
                'Withdrawn',
                'Closed',
            ],
        }
    }

    componentWillMount() {
        const { user } = this.props;
        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const { searchId } = query;
        let showDetails = true;
        if(searchId) showDetails = false;

        getUsersSellers(user, searchId).then(sellers => {
            if(this.mounted){
                const storage = firebase.storage()
                const storageRef = storage.ref()
                for (const seller of sellers) {
                    const sellerImageRef = storageRef.child(`images/properties/${seller.id}.jpg`)
                    sellerImageRef.getDownloadURL().then(url => {
                        seller.photo = url
                        if (this.mounted) {
                            this.setState({sellers})
                        }
                    }).catch(error => {})
                }
                if (this.mounted) {
                    this.setState({sellers, loading: false, showDetails});
                }
            }
        }).catch(error => {
            if (this.mounted) {
                console.log('Error: ', error)
                this.setState({loading: false, error: error})
                console.error(error)                    
            }
        });
    }

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
    }

    showOnSharePage = async (seller_id, value) => {
        const db = firebase.firestore();
        if(value === undefined)  value = false;
        value = !value;
        db.collection('sellers').doc(seller_id).update({
            show_on_share: value,
        }).then(() => {
            this.props.history.push('/sellers');
        });
    }

    copyToClipboard = async () => {
        // let { user } = this.props;
        // let url = firebaseConfig.host+'/share/sellers/'+user.uid;
        // copy(url, {
        //     debug: true
        //   });
        // toast.success('URL has been copied to your clipboard successfully.', {
        //     position: toast.POSITION.TOP_RIGHT
        // });

        alert(`Data will be updated in a few minutes.`)
        await Axios.get(`https://us-central1-homematchx-prod.cloudfunctions.net/updateSellersOnDemand`)
    }

    removeSeller = (id,data) => {
        if (window.confirm('Are you sure?')) {
            const db = firebase.firestore()
            const sellerRef = db.collection('sellers').doc(id);
            sellerRef.update({
                reason: {...data},
                removed: true,
            }).then(() => {
                const {sellers} = this.state
                const newSellers = []

                for (const seller of sellers) {
                    if (seller.id !== id) {
                        newSellers.push(seller)
                    }
                }
                if (this.mounted) {
                    this.setState({sellers: newSellers})
                }
            }).catch(error => console.error(error));
        }
    }

    filterByStatus = async (status) => {
        const { user } = this.props;
        this.setState({
            offerStatusFilter: status,
            loading: true
        })

        getUsersSellers(user, {
            status
        }).then(sellers => {
            if(this.mounted){
                const storage = firebase.storage()
                const storageRef = storage.ref()
                for (const seller of sellers) {
                    const sellerImageRef = storageRef.child(`images/properties/${seller.id}.jpg`)
                    sellerImageRef.getDownloadURL().then(url => {
                        seller.photo = url
                        if (this.mounted) {
                            this.setState({sellers})
                        }
                    }).catch(error => {})
                }
                if (this.mounted) {
                    this.setState({ sellers, loading: false });
                }
            }
        }).catch(error => {
            if (this.mounted) {
                console.log('Error: ', error)
                this.setState({loading: false, error: error})
                console.error(error)                    
            }
        });
    }


    render() {
        const {loading, error, showDetails, statusFilterOptions, offerStatusFilter} = this.state
        let sellerList;
        const {user} = this.props;
        const {role} = user;
        
        if (loading) {
            sellerList = 
                <table className="manage-table">
                    <tbody>
                        <tr className="responsive-table"><td><p>Loading</p></td></tr>
                    </tbody>
                </table>
        }
        else {
            if (error) {
                sellerList = <table className="manage-table">
                        <tbody>
                        <tr className="responsive-table"><td><p>{error}</p></td></tr>
                        </tbody>
                    </table>
            }
            else {
                sellerList = <SellerList
                    remove={this.removeSeller}
                    sellers={this.state.sellers}
                    user={user}
                    showOnSharePage={this.showOnSharePage}
                    showDetails = { showDetails }
                />
            }
        }

        const sellerLabel = role === 'builder' ? 'Builder Homes':'My Properties';
        const createSellerLabel = role === 'builder' ? 'Create Home':'Create Seller';
        return (
            <div className="content-area5">
               
                <div className="dashboard-content">
                    <div className="dashboard-header clearfix">
                        <div className="row">
                            <div className="col-sm-12 col-md-3">
                                <h4 className="seller-main-heading">{sellerLabel}</h4>
                            </div>
                            <div className="col-sm-12 col-md-9 centerly mobile-btn-view-container" style={{textAlign: 'right'}}>
                                <div className="d-inline-block">
                                    <span className="period-filter-label">Status:</span>
                                    <div className="d-inline-block">
                                        <select
                                        name="offerStatusFilter"
                                        value={offerStatusFilter}
                                        onChange={e => this.filterByStatus(e.target.value)}
                                        className="search-fields offers-search-fields mr-0 mr-md-4 sellers-status-filter">
                                        <option value="">Select status</option>
                                        {
                                            !!statusFilterOptions.length && statusFilterOptions.map((m, i) => (
                                            <option key={i} value={m}>{m}</option>
                                            ))
                                        }
                                        </select>
                                    </div>
                                </div>
                                <Button className="back-btn back-sellers margin-top-none mobile-responsive-back-btn">
                                    Back
                                </Button>
                                <Button
                                    className="share-btn mr-8 margin-top-none mobile-responsive-share-btn"
                                    onClick={this.copyToClipboard}
                                    >
                                    Update Sellers
                                </Button>
                                <Link to="/profiles/sellers/new" className="btn create-seller-btn">
                                    {createSellerLabel}
                                </Link>
                            </div>
                            <div className="col">

                            </div>
                        </div>
                    </div>
                    <div className="dashboard-list dashboard-list-single sellers-bg-transparent">
                        {/* <h3>{sellerLabel}</h3> */}
                        {sellerList}
                    </div>
                </div>
            </div>
        )
    }
}

export default Sellers
