import React, { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import "react-toastify/dist/ReactToastify.css";
import 'react-toastify/dist/ReactToastify.css';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import BookingComments from '../components/BookingComments';
import BookingSubmitComment from '../components/BookingSubmitComment';

class LiveStreamChannelJoin extends Component {
    constructor(props) {
        super(props)

        const { user } = this.props;
        const { full_name, realtor_name, lender_name, builder_name, uid } = user;

        this.state = {
            error: '',
            loading: true,
            liveStreamChannel: null,
            apiKey: '46494562',
            cameraPosition: localStorage.cameraPosition || 'environment',
            publisher: null,
            subscriber: null,
            speaker: true,
            mic: true,
            bookingId: uid,
            name: full_name || realtor_name || lender_name || builder_name
        }

        this.publisherProperties = {
            showControls: true,
            facingMode: this.state.cameraPosition,
            mirror: false
        };

        this.otPublisher = React.createRef();
        this.otSubscriber = React.createRef();
    }

    componentWillMount() {
        this.starAndStoreSession();
    }

    switchCamera = () => {
        const { cameraPosition } = this.state;
        const pos = cameraPosition === 'environment' ? 'user' : 'environment'
        this.setState({
            cameraPosition: pos
        });
        localStorage.cameraPosition = pos;
        window.location.reload();
    }

    toggleMic = () => {
        const { otPublisher } = this;
        if (otPublisher) {
            const { current } = otPublisher;
            if (current) {
                const publusher = current.getPublisher();
                if (publusher) {
                    const { mic } = this.state;
                    publusher.publishAudio(!mic);
                    this.setState({
                        mic: !mic
                    });
                }
            }
        }
    }

    toggleSpeaker = () => {
        const { otSubscriber } = this;
        if (otSubscriber) {
            const { current } = otSubscriber;
            if (current) {
                const subscriber = current.getSubscriber();
                if (subscriber) {
                    const { speaker } = this.state;
                    subscriber.subscribeToAudio(!speaker);
                    this.setState({
                        speaker: !speaker
                    });
                }
            }
        }
    }

    starAndStoreSession = async () => {

        const { match } = this.props
        const { params } = match
        const { id } = params;

        const db = firebase.firestore();

        const usersnap = await db.collection('users').doc(id).get();
        const { liveStreamChannel: { sessionId, token, isTwoWayVideo } } = usersnap.data();

        const liveStreamChannel = {
            sessionId,
            token,
            isTwoWayVideo
        };

        this.setState({
            liveStreamChannel
        });
    }

    render() {

        const { liveStreamChannel, apiKey, speaker, mic, cameraPosition } = this.state;

        let isTwoWayVideo = liveStreamChannel ? liveStreamChannel.isTwoWayVideo || false : false;
        let sessionId = liveStreamChannel ? liveStreamChannel.sessionId || '' : '';
        let token = liveStreamChannel ? liveStreamChannel.token || '' : '';
        return (
            <div className="content-area5">
                <div className="dashboard-content">
                    <div style={{ margin: '0 auto', padding: '20px', textAlign: 'center' }}>

                        {!liveStreamChannel && (
                            <div style={{ minHeight: '200px', marginTop: '50px', textAlign: 'center' }}>
                                <h4>Thank you for using Homematchx</h4>
                            </div>
                        )}

                        {liveStreamChannel && <>
                            <OTSession apiKey={apiKey} sessionId={sessionId} token={token}>

                                <div className="video-main">
                                    <div className="blank-div"></div>

                                    <OTStreams>
                                        <OTSubscriber ref={this.otSubscriber} properties={{
                                            showControls: false
                                        }} />
                                    </OTStreams>

                                    {(isTwoWayVideo) && (
                                        <OTPublisher
                                            properties={{
                                                showControls: false,
                                                facingMode: cameraPosition,
                                                mirror: false
                                            }}
                                            ref={this.otPublisher}
                                            className="custom-video"
                                        />
                                    )}

                                    <div className="live-comment-setion">
                                        <BookingComments bookingId={this.state.bookingId} name={this.state.name} match={this.props.match} />

                                        <div className="call-controls d-flex justify-content-between align-items-center">

                                            <div className="d-flex align-items-center flex-grow-1">
                                                <BookingSubmitComment bookingId={this.state.bookingId} name={this.state.name} match={this.props.match} />
                                            </div>


                                            <div className="flex-fill justify-content-end">
                                                <ul className="d-flex justify-content-center align-items-center cntroll-call-btns">
                                                    {(isTwoWayVideo) && (
                                                        <li>
                                                            <btn onClick={this.switchCamera}>
                                                                <i className="fa fa-camera"/>
                                                            </btn>
                                                        </li>
                                                    )}
                                                    <li>
                                                        <btn onClick={this.toggleSpeaker}>
                                                            {speaker && <i className="fa fa-volume-up"/>}
                                                            {!speaker && <i className="fa fa-volume-off"/>}
                                                        </btn>
                                                    </li>
                                                    {(isTwoWayVideo) && (
                                                        <li>
                                                            <btn onClick={this.toggleMic}>
                                                                {mic && <i className="fa fa-microphone"/>}
                                                                {!mic && <i className="fa fa-microphone-slash"/>}
                                                            </btn>
                                                        </li>
                                                    )}
                                                    <li className="end-call-btn">
                                                        <btn onClick={() => this.setState({ liveStreamChannel: null })} >
                                                            <i className="fa fa-phone"/>
                                                        </btn>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>


                                </div>

                            </OTSession>
                        </>}
                    </div>
                </div>
            </div>
        )
    }
}

export default LiveStreamChannelJoin;