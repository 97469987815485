import React, { Component } from "react";
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import Joyride from "react-joyride";

export default class SellerTile extends Component {
  state = {
    statusColors: {
      Active: "success",
      "Active Kick Out": "primary",
      "Active Option Contract": "primary",
      "Active Contingent": "primary",
      Pending: "warning",
      Sold: "danger",
      Cancelled: "dark",
      "Temp Off Market": "dark",
      Expired: "dark",
      Leased: "dark",
      Withdraw: "dark",
      joyRide: this.props.user?.hasOwnProperty("sellerTileJoyRide")
        ? this.props.user?.sellerTileJoyRide
        : true,
    },
  };

  renderExactLocationMatchIcon = () => {
    const { doc, filters } = this.props;
    const seller = doc.data();
    let exactLocationMatch = false;
    const { location } = seller;
    if (location && filters) {
      const { location: filteredLocation } = filters;
      const { lat, lng } = location;
      if (filteredLocation) {
        const { lat: fLat, lng: fLng } = filteredLocation;
        exactLocationMatch = fLat === lat && fLng === lng;
      }
    }

    if (exactLocationMatch) {
      return (
        <p>
          <span
            style={{
              color: "#ff214f",
              fontSize: 25,
              padding: "7px",
              float: "right",
            }}
            className="fa fa-heart"
          />
        </p>
      );
    }
  };

  renderNewIcon = () => {
    const { doc } = this.props;
    const seller = doc.data();
    const { construction_status } = seller;
    const newConstructions = ["Incomplete", "Complete", "Area Development"];
    if (newConstructions.includes(construction_status)) {
      return (
        <p
          style={{
            fontSize: "14px",
            margin: "5px",
            borderRadius: "20px",
            float: "left",
            color: "#fff",
            fontWeight: "700",
            backgroundColor: "#28a745",
            padding: "3px 7px",
          }}
        >
          New
        </p>
      );
    }
  };

  renderMLSIcon = () => {
    const { doc } = this.props;
    const { statusColors } = this.state;
    const seller = doc.data();
    let { type, MlsStatus } = seller;
    if (type !== "MLS") {
      type = "HMX";
    }

    return (
      <p
        className={`bg-${statusColors[MlsStatus] || "dark"}`}
        style={{
          fontSize: "14px",
          margin: "5px",
          borderRadius: "20px",
          float: "left",
          color: "#fff",
          fontWeight: "700",
          padding: "3px 7px",
        }}
      >
        {type}
      </p>
    );
  };
  copyToClipboard = (text) => {
    copy(text);
    toast.success("Link has been copied to your clipboard successfully.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  renderCopyIcon = () => {
    const { doc,id } = this.props;

    return (
      <i
        id={`copy-icon-${id}`}
        onClick={(e) => {
          e.preventDefault();
          this.copyToClipboard(`https://homematchx.com/sellers/${doc?.id}`);
        }}
        className={`fa fa-clone`}
        style={{
          fontSize: "18px",
          margin: "5px",
          borderRadius: "20px",
          float: "right",
          color: "#fff",
          fontWeight: "1000",
          padding: "3px 7px",
          position: "absolute",
          right: "0px",
          cursor: "pointer",
          zIndex: "1000",
        }}
      ></i>
    );
  };

  renderDeadlineIcon = () => {
    const { doc } = this.props;

    const seller = doc.data();
    let { offerDeadline } = seller;

    if (offerDeadline === "Yes") {
      return (
        <p
          className="bg-info"
          title="This Property has offer deadline."
          style={{
            fontSize: "14px",
            margin: "5px",
            borderRadius: "20px",
            float: "right",
            color: "#fff",
            fontWeight: "700",
            padding: "8px 9px",
          }}
        >
          <i class="fa fa-clock-o"></i>
        </p>
      );
    }
  };

  render() {

    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
    const { doc } = this.props;
    const seller = doc.data();
    const price = currencyFormatter.format(
      parseInt(seller.expected_sales_price || "0") ||
        seller.projectedPrice ||
        seller.current_value
    );

    return (
      <div className="col-lg-3" key={doc.id}>
        <Link to={`/sellers/${doc.id}`} style={{ zIndex: "1" }}>
          <div className="property-box-2" style={{ position: "relative" }}>
            <div
              className="property-thumbnail"
              style={{ height: 160, overflow: "hidden" }}
            >
              <div
                className="property-img"
                style={{
                  backgroundImage: `url('${
                    seller.featured_image && seller.featured_image.url
                      ? seller.featured_image.url
                      : "/images/house-placeholder.jpg"
                  }')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  height: 160,
                }}
              >
                {this.renderExactLocationMatchIcon()}
                {this.renderMLSIcon()}
                {this.renderCopyIcon()}
                {this.renderDeadlineIcon()}
                {price !== "$NaN" ? (
                  <div className="price-box">
                    <span>{price}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="detail">
              <div className="hdg">
                <p
                  className="location"
                  style={{ marginBottom: 0, fontSize: 13, lineHeight: "20px" }}
                >
                  <i className="flaticon-pin"></i>
                  {seller.street_number}, {seller.street_name}, {seller.city},{" "}
                  {seller.state} {seller.zip_code}
                </p>
                <p style={{ fontSize: 12, marginBottom: 0 }}>
                  <i className="fa fa-bed"></i>&nbsp;{seller.bedrooms}&nbsp;
                  {seller.type !== "MLS" && (
                    <i className="fa fa-bath">
                      &nbsp;
                      {parseInt(seller.full_baths) +
                        parseInt(seller.half_baths) * 0.5}
                    </i>
                  )}
                  {seller.type === "MLS" && (
                    <i className="fa fa-bath">&nbsp;{seller.bathrooms}</i>
                  )}
                  &nbsp;•&nbsp;{seller.sqft} {seller.sqft ? "sqft" : ""}
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
