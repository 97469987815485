import React, { Component } from "react";
import { connect } from "react-redux";
import "firebase/functions";
import CreateCardModal from "./../components/Payment/CreateCardModal";
import CardsList from "./../components/Payment/CardsList";
import Package from "./../components/Payment/Package";

class Subscription extends Component {
  state = {
    currentUser: null,
    sources: {},
    stripeCustomerInitialized: false,
    charges: {},
    newCharge: {
      source: null,
      amount: 2000
    }
  };

  renderPackageInfo = () => {
    
    const { packages,user } = this.props;

    if(packages === undefined ){
      return ;
    }
    const offset = packages.length > 1 ? '':'offset-md-2';
    return (
      <section className="pricing py-5">
      <div className="container">
        <div className="row">
          {packages.map((p,i) => <Package key={i} p={p} offset={offset} user={user} />)}
        </div>
      </div>
      </section>
    );
  };

  render() {
    const { user } = this.props;

    return (
      <div className="content-area5 dashboard-content">
        <div className="dashboard-header clearfix">
          <div className="row">
            <div className="col-md-7">
              <h4>Subscribe to package</h4>
              {this.renderPackageInfo()}
            </div>
            <div className="col-md-5">
              <h4>Your Cards</h4>
              <CardsList user={user} />
              <CreateCardModal user={user} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ PaymentReducer }) => {
  return {
    ...PaymentReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription);
