import React, { Component } from 'react'

class SearchArea extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            formsSubmitted: {}
        }
    }

    render() {
        return (
            <React.Fragment>
                <div
                    id="search-style-1"
                    className="search-section search-area pb-hediin-12 bg-grea animated fadeInDown">
                    <div className="container">
                        <div className="search-section-area">
                            <div className="search-area-inner">
                                <div className="search-contents">
                                    <form method="GET">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                                <div className="form-group">
                                                    <select
                                                        name="any-status"
                                                        className="selectpicker search-fields">
                                                        <option>
                                                            Any Status
                                                        </option>
                                                        <option>
                                                            For Rent
                                                        </option>
                                                        <option>
                                                            For Sale
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                                <div className="form-group">
                                                    <select
                                                        name="all-type"
                                                        className="selectpicker search-fields">
                                                        <option>
                                                            All Type
                                                        </option>
                                                        <option>
                                                            Apartments
                                                        </option>
                                                        <option>Shop</option>
                                                        <option>
                                                            Restaurant
                                                        </option>
                                                        <option>Villa</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                                <div className="form-group">
                                                    <select
                                                        name="bedrooms"
                                                        className="selectpicker search-fields">
                                                        <option>
                                                            Bedrooms
                                                        </option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                                <div className="form-group">
                                                    <select
                                                        name="bathrooms"
                                                        className="selectpicker search-fields">
                                                        <option>
                                                            Bathrooms
                                                        </option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                                <div className="form-group">
                                                    <select
                                                        name="location"
                                                        className="selectpicker search-fields">
                                                        <option>
                                                            location
                                                        </option>
                                                        <option>
                                                            United States
                                                        </option>
                                                        <option>
                                                            American Samoa
                                                        </option>
                                                        <option>Belgium</option>
                                                        <option>Canada</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                                                <div className="form-group">
                                                    <button className="search-button">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SearchArea
