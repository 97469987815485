import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth';
import 'firebase/storage';

const {Stripe} = window;

const productionConfig = {
  host:'https://homematchx.com',
  apiKey: "AIzaSyArN1jui5mPGdGydl89WaIXMULJhjD3VIw",
  authDomain: "homematchx-prod.firebaseapp.com",
  databaseURL: "https://homematchx-prod.firebaseio.com",
  projectId: "homematchx-prod",
  storageBucket: "homematchx-prod.appspot.com",
  messagingSenderId: "182635199829",
  measurementId: "G-6Z98DBM1LY",
  appId: "1:182635199829:web:bc8ce8e65a706e1b6983dc",
  cloudFunctionHost:'https://us-central1-homematchx-prod.cloudfunctions.net',
}

const localConfig = {
  host:'http://localhost:3000',
  apiKey: "AIzaSyBUr0-VNWAssAhxBR0jFqjWei_3TPI_auc",
  authDomain: "rugged-weft-281602.firebaseapp.com",
  databaseURL: "https://rugged-weft-281602.firebaseio.com",
  projectId: "rugged-weft-281602",
  storageBucket: "rugged-weft-281602.appspot.com",
  messagingSenderId: "538315879293",
  measurementId: "G-M8KECJJCSJ",
  appId: "1:538315879293:web:2273527261753a3b521214",
  cloudFunctionHost:'https://us-central1-rugged-weft-281602.cloudfunctions.net'
}

const stagingConfig = {
  host:'https://rugged-weft-281602.firebaseapp.com',
  apiKey: "AIzaSyBUr0-VNWAssAhxBR0jFqjWei_3TPI_auc",
  authDomain: "rugged-weft-281602.firebaseapp.com",
  databaseURL: "https://rugged-weft-281602.firebaseio.com",
  projectId: "rugged-weft-281602",
  storageBucket: "rugged-weft-281602.appspot.com",
  messagingSenderId: "538315879293",
  measurementId: "G-M8KECJJCSJ",
  appId: "1:538315879293:web:2273527261753a3b521214",
  cloudFunctionHost:'https://us-central1-rugged-weft-281602.cloudfunctions.net'
}

let env, config

const adminHosts = [
  'homematchx-prod.firebaseapp.com',
  'homematchx.com',
  'homematchx-prod.web.app'
];

if (adminHosts.includes(window.location.hostname)) {
  env = 'production'
} else if(window.location.hostname === 'localhost') {
  env = 'local'
} else {
  env = 'staging'
}

env = 'production';
// env = 'staging';

if (env === 'production') {
  config = productionConfig;
  Stripe.setPublishableKey('pk_live_txUDcEIQu5Ijd8jMpMIiSjPd00QKj9yMpL');
} else if(env === 'local') {
  config = localConfig;
  Stripe.setPublishableKey('pk_test_RO9Jp6dNdbOOLlxVx4sLOKB300KXRYY0gn');
} else {
  config = stagingConfig;
  Stripe.setPublishableKey('pk_test_RO9Jp6dNdbOOLlxVx4sLOKB300KXRYY0gn');
}

export const firebaseConfig = config;
export const environment = env;

export class Firebase {
    constructor() {
        // Copy and paste this into your JavaScript code to initialize the Firebase SDK.
        // You will also need to load the Firebase SDK.
        // See https://firebase.google.com/docs/web/setup for more details.

        firebase.initializeApp(config);

        this.db = new Database()
    }

    firestore() {
      return this.db
    }

    storage() {
      return firebase.storage()
    }
    database() {
      return firebase.database()
    }
}

class Database {
  constructor() {
    this.db = firebase.firestore()
    this.FieldValue = firebase.firestore.FieldValue
  }

  collection(collectionName) {
    return new Collection(collectionName, this.db.collection(collectionName))
  }
}

class Collection {
  constructor(collectionName, collection) {
    this.name = collectionName
    this.collection = collection
  }

  add(o) {
    this.applyHook(o)
    return this.collection.add(o)
  }

  set(o) {
    this.applyHook(o)
    return this.collection.set(o)
  }

  doc(id) {
    return new Doc(this, this.collection.doc(id))
  }

  applyHook(o) {
    const hook = hooks[this.name]
    
    if (hook) {
      const {beforeSave} = hook

      if (beforeSave) {
        o = beforeSave(o)
      }
    }

    return o
  }
}

class Doc {
  constructor(collection, doc) {
    this.collection = collection
    this.doc = doc
  }

  get() {
    return this.doc.get()
  }

  update(o) {
    this.collection.applyHook(o)
    return this.doc.update(o)
  }
}

const hooks = {
  sellers: {
    beforeSave: seller => {

      if(seller.full_baths && seller.half_baths){
        seller.bathrooms = parseInt(seller.full_baths) + parseInt(seller.half_baths)*.5
      }
      
      if (seller.bedrooms) {
        seller.bedrooms = parseInt(seller.bedrooms)
      }

      if(seller.year_built){
        seller.year_built = parseInt(seller.year_built)
      }
      
      if(seller.sqft){
        seller.sqft = parseInt(seller.sqft)
      }

      if (seller.expected_listing_year) {
        seller.expected_listing_year = parseInt(seller.expected_listing_year)
      }
    },
  },
  buyers: {
    beforeSave: buyer => {
      buyer.purchase_year = parseInt(buyer.purchase_year)
      buyer.min_price = parseInt(buyer.min_price)
      buyer.max_price = parseInt(buyer.max_price)
      buyer.sqft_min = parseInt(buyer.sqft_min)
      buyer.sqft_max = parseInt(buyer.sqft_max)
      buyer.year_built_min = parseInt(buyer.year_built_min)
      buyer.year_built_max = parseInt(buyer.year_built_max)
    }
  },
  users: {
    beforeSave: user => {
      user.displayName = user.full_name
    }
  }
}

export default new Firebase()

