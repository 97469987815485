import React, { Component } from 'react'
import SubBanner from 'components/SubBanner'
import FAQ from '../components/FAQ';

class FAQs extends Component {

    render() {

        const faqs = [
            {
                section: 'General information',
                questions: [
                    {
                        term: 'Where does Homematchx get property information from?',
                        defination: 'The homes added to Homematchx are added by real sellers or their agent representative and  home  builders.  We  do  not  take  any  information  from  automated  systems  or  MLS. These  entries  are  self-inputted,  and  data  driven  from  specific  property  information  sites and plans. If the profile is created by the agent, it means they’ve been given permission from the seller to complete the property information.',
                    },
                    {
                        term: 'Where does Homematchx get buyer information from?',
                        defination: 'The buyers added to Homematchx are added by real buyers or their agent representative. Profile  are  either  created  by  the  buyer  or  the  agent  has  been  given  permission  from  the buyer to be their representing agent.',
                    },
                    {
                        term: 'How do I remove my profile from Homematchx?',
                        defination: 'You  can  remove  a  profile  from  Homematchx  by  going  to  the  details  page  and  select remove. It will give you a reason for the removal. Select the appropriate removal type and its  removed.  When  a  property  has  been  sold  you  can  close  out  the  profile  and  it  will  be saved in the platform database. Only agents have access to see Sold or Removed profiles.',
                    },
                    {
                        term: 'What if I forgot my password?',
                        defination: 'On  the  Login  page  click  Forgot  Password,  enter  your  email  address,  and  follow  the instructions in the email. Once completely reset you can now login.',
                    },
                    {
                        term: 'How do I deactivate my Homematchx profile?',
                        defination: 'To  deactivate  your  account  while  you  are  an  active  user,  select  edit  to  edit  your  profile. Within the edit profile you have a Hide Profile option. Select Hide Profile to remove your account from active to inactive status and Homematchx will hide your profile.',
                    },
                    {
                        term: 'How do I search for homes?',
                        defination: 'On  Homematchx  you  can  search  for  homes  by  using  search  bar  City,  Zip,  State, Subdivision,  and  HMXID  or  you  can  search  by  a  specific  location  using  our  interactive Google Map Search feature. Add a specific address to the Map Search and locate a buyer or home. If a buyer or seller profile has a heart it means you have an exact match to the address. You can narrow your home search by utilizing the filters options to find the right buyer match.',
                    },
                    {
                        term: 'What does it mean if a buyer profile has a heart?',
                        defination: 'A heart on a buyer profile means the buyer wants to purchase your specific property based on the address you entered. Its stands for a perfect match. You will truly know if a buyer wants to purchase your specific home by the heart.',
                    },
                    {
                        term: 'How do I search for buyers?',
                        defination: 'On  Homematchx  you  can  search  for  buyers  by  using  search  bar  City,  Zip,  State, Subdivision,  or  you  can  search  by  a  specific  location  using  our  interactive  Google  Map Search feature. You can narrow your buyer search by utilizing the filter options to find the right match for your home.',
                    },
                    {
                        term: 'How do I add a lender to a buyer profile?',
                        defination: 'A lender on Homematchx can be added using their NMLS ID. The lender must first create a  profile  on  Homematchx  and  their  lender  contact  information  and  upload  their  profile image and lender logo. When creating a buyer profile or editing, select Yes for “Is Lender Available” the system will prompt you to input the NMLS ID. Once the ID is added and all  other  information,  click  Save.  An  email  will  be  sent  to  the  lender  to  confirm  your qualification  and  complete  a  buyer  questioner.  Once  qualification  has  been  confirmed, you’ll receive an email stating the lender has been added to your buyer profile.',
                    },
                    {
                        term: 'Do you have to create an account in order to be added as a lender?',
                        defination: 'Yes,  the  lender  must  first  create  a  profile  on  Homematchx  with  their  lender  contact information. profile image, and lender logo.',
                    },
                    {
                        term: 'How do I invite a realtor to a buyer or seller profile?',
                        defination: 'To invite a realtor to Homematchx go to My Account in the Dashboard and under Agent Information  answer  the  questions  that  apply.  Add  the  Agent  email  under  “Enter  Your Agent’s Emails” and if your realtor has a Homematchx account their email will atomically populate as you type their email. If the agent does not have an account a request will be sent on behalf of the buyer and or seller. Once the agent creates the profile you can select your representing agent in the bottom drop down of the Create or Edit profile page.',
                    },
                    {
                        term: 'As a Realtor how do I invite a buyer or seller to Homematchx?',
                        defination: 'A buyer or seller is invited to Homematchx when you Create or Edit Buyer/Seller Profile. Under Buyer or Seller Information you will add their contact information and check the box Invite this user to Homematchx and click Save.',
                    },
                    {
                        term: 'Can a Buyer or Seller represent themselves on Homematchx?',
                        defination: 'Yes, a Buyer or Seller can represent themselves on Homematchx. In My Account under Agent Information answer the appropriate questions if you choose to represent yourself. A non-agent  representing  title  will  be  “no  agent,  self-represented.”  We  recommend  you review and discuss your options with a licensed real estate agent.',
                    }
                ]
            },
            {
                section: 'Homematchx Offers',
                questions: [
                    {
                        term: 'What is Make an Offer?',
                        defination: 'Make an Offer allows a buyer to instantly outline terms of the Purchase Agreement to the seller with the intent to purchase the home. This signifies a serious commitment to the seller and is an intent to purchase real estate.',
                    },
                    {
                        term: 'How do I make my home available for offers?',
                        defination: 'To make your home available for Offer on Homematchx go to edit profile or Create Seller Profile and select yes for Offers Allowed. Once you select yes your home will have a Make an Offer button.',
                    },
                    {
                        term: 'What types of Offer can I receive on my home?',
                        defination: 'The type of offers you can receive are Traditional Offer, Instant Offer, Investor Offer, or Seller Finance Offer.',
                    },
                    {
                        term: 'What is a Traditional Offer?',
                        defination: 'A Traditional Offer is an offer from a Buyer who intends to purchase your home as their primary residence, secondary home/vacation, or rental home.',
                    },
                    {
                        term: 'What is an Instant Offer?',
                        defination: 'An Instant Offer is an offer that allows you to close as quickly as you need and often accepts the home as-is. This service is for sellers who are in a situation where they need to sell a house fast or want a simpler, more convenient alternative to a traditional real estate process. Sellers should do their due diligence and be aware of the pros and cons of going with such an option.',
                    },
                    {
                        term: 'What is an Investor Offer?',
                        defination: 'An Investor Offer is an offer from an investor who intends to purchase the home to flip for a profit. The investors buy below market homes to fix up/remodel then puts it back on the market for sale.',
                    },
                    {
                        term: 'What is a Seller Finance Offer?',
                        defination: 'Seller Finance Offer is when the buyer purchases the home directly from the seller, who acts as the bank, with negotiated terms and conditions. Seller handles the mortgage process instead of a financial institution. Instead of applying for a traditional bank mortgage, the buyer signs a mortgage with the seller. Seller financing is another name for owner financing.',
                    },
                    {
                        term: 'Can a buyer use Make an Offer if they do not a real estate agent?',
                        defination: 'Yes, the buyer can make an offer without a real estate agent, but it is highly recommended that the buyer has agent representation. The buyer has 24 hours to send an offer in writing on a TREC contract with all applicable documents once the seller accepts the offer. If the written offer is not completed in a timely manner the buyer offer is considered withdrawn. If assistance is needed to complete the offer you can contact the Homematchx Offer Team.',
                    },
                    {
                        term: 'Is Homematchx buying houses?',
                        defination: 'No Homematchx does not buy houses.',
                    },
                    {
                        term: 'Is there a fee to request an offer?',
                        defination: 'No, there is no fee to Homematchx to request an offer.',
                    },
                    {
                        term: 'Is there a fee to use Make an Offer?',
                        defination: 'No there is no fee to use Make an Offer.',
                    },
                    {
                        term: 'I want to select my own closing date – can I do that? ',
                        defination: 'Yes, Homematchx is created to give buyers and sellers control over their moving process. As long as both parties agree to a specific closing date you can close at the designated time. Closing date does not have to be the traditional 30 to 45 day process. Closing can be as long as 3 to 9 months from the executed contract date or as fast as 11 days if allowed given the circumstances. The beauty of Homematchx is you choose when you want to close.',
                    },
                    {
                        term: 'Do I need to use an agent?',
                        defination: 'You do not need an agent to use Homematchx. You can create an account and represent yourself on the platform.',
                    },
                    {
                        term: 'What is a Buyer Agent Commission?',
                        defination: 'Buyer Agent Commission on Homematchx is the percentage or amount paid to the Buyer’s Agent who brings an offer. The amount is paid by the seller to the listing brokerage. The listing brokerage divides the commission in some fashion with the broker of the agent who brings an offer.',
                    },
                    {
                        term: 'Can I get an offer on behalf of a family member?',
                        defination: 'In order to participate in Homematchx Offer, you must be the owner of the home or have the legal right to represent the owner.',
                    },
                ]
            },
            {
                section: 'Receiving Offers',
                questions: [
                    {
                        term: 'What information do I need to provide? ',
                        defination: 'You will provide the home’s address, confirm some basic home details (year built, square footage, etc.) and answer a few questions about recent or future updates or upgrades to the home,  as  well  as  other  information  pertinent  to  the  overall  home  valuation.  Uploaded photos are highly encouraged and should include the interior and exterior of the home. It takes about three to five minutes to answer the question. A complete seller profile provides a better assessment of your home when receiving offers.',
                    },
                    {
                        term: 'How long does it take to get an offer back?',
                        defination: 'After  submitting  the  offer  to  purchase  the  home,  the  seller  has  three  calendar  days  to respond  before  the  offer  expires.  If  your  offer  has  expired  and  you’re  still  interested  in purchasing the home, we will contact the seller to provide you with an update.',
                    },
                    {
                        term: 'Can I negotiate my offer?',
                        defination: 'An  offer  can  be  negotiated  as  long  as  it  is  within  three  calendar  days  from  when  it  was submitted and received. If you wish to negotiate the offer after it has expired, you may do so. Please remember when provided with the three-day requirement any offer negotiated after  the  expiration  date  can  be  considered  withdrawn  and  is  at  the  sellers  and  buyers discretion.',
                    },
                    {
                        term: 'Do I need to include photos of my home?',
                        defination: 'Photos are not required, but they are encouraged as they give the buyer more information about your home. Professional photos are not needed but are suggested if you’re looking to attract buyers. Photos should include the interior and exterior of the home.',
                    },
                    {
                        term: 'How are my photos and contact information shared?',
                        defination: 'Your  photos  will  only  be  viewed  by  Homematchx  users  and  viewers  and  helps  buyers prepare  offers.  We  do  not  share  your  contact  information  unless  you  request  to  be connected with an agent.',
                    },
                    {
                        term: 'Can I request to receive an Instant Offer?',
                        defination: 'You can request to receive an Instant Offer in your profile. In the profile details you must select yes for Offer Allowed and yes if you would like to receive instant offers.',
                    },
                    {
                        term: 'Can I receive multiple offers?',
                        defination: 'You  can  receive  multiple  offers  to  purchase  your  home.  If  you  have  received  multiple offers at one time you can encourage the buyers to submit their best and final before you accept.',
                    }
                ]
            },
            {
                section: 'Choosing an Offer',
                questions: [
                    {
                        term: 'I received an offer – what do I do next?',
                        defination: 'You agent will call you to go through your offer. If you don’t have an agent a Homematchx Offer Advisor will call you to discuss the offer details, and we’ll email you a copy so you can review the details at your convenience.',
                    },
                    {
                        term: 'What is a Homematchx Offer Advisor?',
                        defination: 'Once you’ve received an offer, a member of our Homematchx Offer Advisory team will reach out to you to confirm your home details and answer any questions you may have. Your  dedicated  advisor  will  be  your  point  of  contact  through  the  Homematchx  Offer process.  They’ll  clearly  explain  each  step  and  be  available  to  address  your  questions  or concerns.  If  any  issues  arise,  you  can  contact  your  advisor  directly  via  phone,  email,  or text. ',
                    },
                    {
                        term: 'What if I don’t like my offer?',
                        defination: 'You have the option to accept, counter, or reject the offer to sell your home. You can list with an agent or can do nothing. It’s completely up to you. If you have any concerns with the  offer,  please  reach  out  to  your  Homematchx  Offer  Advisor.  We  also  encourage  all homeowners to discuss their options with a real estate professional.',
                    },
                    {
                        term: 'How long do I have to respond to an offer?',
                        defination: 'You have until the expiration date to respond to the offer. The expiration date is typically three days from when the offer was submitted and received.',
                    },
                    {
                        term: 'If I express interest in Make an Offer, am I obligated to sell my home?',
                        defination: 'Receiving or participating in Make an Offer doesn’t obligate you to sell your home. Even if  you  accept  the  offer,  you  are  under  no  obligation  until  you’ve  signed  a  purchase agreement.  If  the  offer  amount  or  anything  change  within  the  offer,  you  can  decline  the offer. We recommend you review and discuss your options with a licensed real estate agent.',
                    },
                    {
                        term: 'How long can I allow offers to submitted?',
                        defination: 'You can allow offers to be submitted until accepted. To remove your profile from receiving additional offer you must move your profile from active to off market by selecting Hide Profile in edit profile.',
                    },
                    {
                        term: 'What happens after I accept an offer?',
                        defination: 'When you accept an offer the buyer is required to submit a signed purchase agreement with all addendums included. We recommend you review all documents carefully and discuss your contract with a licensed real estate agent or Homematchx Offer Advisor.',
                    },
                ]
            },
            {
                section: 'Evaluation and Closing',
                questions: [
                    {
                        term: 'Can someone request to see my home in person?',
                        defination: 'Yes,  someone  can  request  to  see  your  home  in  person  prior  to  signing  an  agreement  or within the inspection period. A time will be scheduled for inspection to visit your home and determine whether any repairs are needed. Based on their assessment, you may receive an amendment for repairs or best and final offer.',
                    },
                    {
                        term: 'Who can I talk to if I have questions? ',
                        defination: 'At Homematchx, we have a team of real estate advisors focused on making sure you have a  great  experience  for  sellers.  Sellers  using  Homematchx  Offer  will  have  a  dedicated Homematchx  Offer  Advisor  who  will  accompany  them  throughout  the  process,  answer questions and ensure smooth progress towards closing.',
                    },
                    {
                        term: 'What if I’m dissatisfied with the process of selling my home to the Buyer?',
                        defination: 'If you have any issues with the sale, you should contact your real estate agent or reach out to your dedicated Homematchx Offer Advisor who will help you find a resolution, if the issue is within Homematchx control, or connect you to other resources. If the issue is regarding the contract Homematchx can work directly with you or your representative.',
                    },
                ]
            },
        ];

        return (
            <React.Fragment>
                <SubBanner
                    title="FAQs"
                    image="/images/mx4mSkK9zeo.jpg"
                    {...this.props}
                />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 style={{ marginTop: '20px' }}>What is Homematchx?</h3>
                            <hr />
                            <p>Homematchx  is  a  match-making  platform  connecting  buyers  and  sellers  together  for  a future real estate transaction. Homematchx helps you identify buyers and seller who are not yet in the real estate market but have an expected target market date up to three years out. Homematchx connects you with buyers that closely fits your home specification and expected market timeline. Unlike the traditional real estate market where you’re forced to close within 30 to 45 days, with Homematchx you have the option to select a closing date that’s meets your expectation and comfort level. Homematchx connects you to real buyers who wants to make an offer on your home from traditional financing buyers to instant cash offers helping you make the right financial decision. Homematchx bring agents, builders, and   lenders   to   the   process   giving   you   full   disclosure   of   representation,   product development, and financial security. This a one stop shop where you know exactly who’s a part of the transaction and feel comfortable about closing months down the road.</p>
                            {faqs.map((f) => {
                                return (
                                    <>
                                        <h4 style={{marginTop:'30px',marginBottom:'10px'}}>{f.section}</h4>
                                        {f.questions.map((q,i) => <FAQ key={i} {...q}/>)}
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default FAQs
