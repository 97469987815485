import firebase from "firebase/app";
import "firebase/firestore";

export const saveCount = async id => {
    let key = localStorage['userCountKey'];
    if(!localStorage['userCountKey']) {
        key = new Date().getTime() +'_'+ localStorage['userName'];
        localStorage['userCountKey'] = key;
    }
    let ref = firebase.database().ref(`/LiveUsersOnBooking/${id}`);
    ref.on('value', sn => {
        ref.off();
        let count = {};
        count[key] = 1;
        ref.update(count);
    });

    let count = {};
    count[key] = null;
    ref.onDisconnect().update(count);
}

export const getCount = (id, updateCount) => {
    firebase.database().ref(`/LiveUsersOnBooking/${id}`).on('value', snapshot => {
        let liveCount = (snapshot.val()) ? Object.values(snapshot.val()).length : 0;
        updateCount(liveCount);
    })
}