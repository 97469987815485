import React, { useState } from "react";
import { Collapse, Button } from "reactstrap";

const OfferDefinationCollapse = ({ term, defination, open, list, children }) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggle = () => setIsOpen(!isOpen);
  const icon = isOpen ? 'up':'down'
  return (
    <div style={{marginTop:''}}>
        <hr style={{margin:'0rem'}}/>
      <Button
        onClick={toggle}
        style={{
          backgroundColor: "#FFFFFF",
          color: "black",
          borderColor: "transparent",
          width: "100%",
          minHeight:'50px',
          padding: '0px',
          whiteSpace : 'normal',
          textAlign: 'left',
        }}
      >
        {term}
        <i style={{paddingTop: '0px',paddingLeft: '2px',fontSize: '20px'}} className={`fa fa-angle-${icon}`}/>
      </Button>
      <Collapse isOpen={isOpen}>
        {defination && <p>{defination}</p>}
        {(list || []).map(l => <p>{l}</p>)}
        {children}
      </Collapse>
    </div>
  );
};

export default OfferDefinationCollapse;
