import React, { Component } from 'react'

class AboutRealEstate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: ''
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="about-real-estate content-area-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                <img
                                    alt="about"
                                    src="/images/Dl6H4_nzBpE.jpg"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                                <div className="about-text">
                                    <h3>Welcome to Homematchx</h3>
                                    <p>Homematchx is a leading data management tool designed specifically to collect and analyze offer data. HMX leverages automation to create a digital offer experience giving more insight into each property, neighborhood, and buyer. It’s tracks buyer behavior when making offers in a specific area and property type.</p>
                                    <p>Homematchx platform identifies critical offer terms and conditions on each property, helping agents eliminate client risk and uncertainty in their business. Homematchx help agents drive higher sales, and reduce time and operating cost. It’s a simplified process that focus on offer data, speed, delivery, and transparency for a competitive advantage.</p>
                                    <p>By digitizing the offer process, Homematchx gives sellers and buyers powerful analytics data to make a better and more informed decisions, quickly. Homematchx is the premier digital platform for gathering and analyzing data from collected offers while ensuring no offer is overlooked.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default AboutRealEstate
