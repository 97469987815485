import React from "react";
import {firebaseConfig} from './../Firebase/firebase';

const OfferEmailTemplate = props => {
  const {
    addressLineOne,
    addressLineTwo,
    expires,
    heading,
    price,
    elements
  } = props;
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Offer</title>
      </head>
      <body>
        <div className="email-container">
          <img
            src={`${firebaseConfig.host}/img/logos/logo.png`}
            className="logo"
            style={{ height: "40px" }}
            alt=""
          />

          {addressLineOne && (
            <p style={{ marginBottom: "2px" }}>{addressLineOne}</p>
          )}
          {addressLineTwo && (
            <p style={{ marginTop: "0px", marginBottom: "0px" }}>
              {addressLineTwo}
            </p>
          )}

          {expires && (
            <p style={{ marginTop: "5px", color: "grey" }}>{expires}</p>
          )}

          {heading && <h3 style={{ fontWeight: "200" }}>{heading}</h3>}

          {price && (
            <h2 style={{ fontWeight: "200", color: "#ff214f" }}>{price}</h2>
          )}

          {elements.map((e,i) => {
            const { type } = e;
            if (type === "p") {
              return <p key={i}  style={{ fontSize: "17px" }}>{e.text}</p>;
            }

            if (type === "buttons") {
              const jsx = e.buttons.map((b,index) => {
                return (
                  <a
                    key={index}
                    style={{
                      backgroundColor: "#ff214f",
                      color: "white",
                      padding: "10px",
                      marginRight: "30px",
                      borderRadius: "5px",
                      textDecoration: "none"
                    }}
                    href={b.link}
                  >
                    {b.text}
                  </a>
                );
              });

              return (
                <div key={i} style={{ marginTop: "40px", marginBottom: "40px" }}>
                  {jsx}
                </div>
              );
            }

            return null;
          })}

          <div
            style={{
              border: "1px solid gray",
              borderRadius: "5px",
              padding: "5px",
              height: "150px"
            }}
          >
            <div style={{ float: "left", width: "50%" }}>
              <img
                src={`${firebaseConfig}/images/steve.jpg`}
                alt="user"
                className="img-fluid"
                style={{
                  borderRadius: "50%",
                  width: "130px",
                  height: "130px",
                  marginTop: "10px",
                  float: "left",
                  marginRight: "10px"
                }}
              />
              <div>
                <h3 style={{ margin: "0px", marginTop: "45px" }}>
                  Stephen Lewis
                </h3>
                <p style={{ margin: "0px", marginTop: "10px" }}>
                  Homematchx Offer Advisor
                </p>
              </div>
            </div>
            <div style={{ float: "right", width: "50%" }}>
              <div style={{ width: "49%", float: "left", marginRight: "2%" }}>
                <p
                  style={{
                    backgroundColor: "#8080801a",
                    padding: "10px",
                    color: "black",
                    textAlign: "center"
                  }}
                >
                  Direct:
                  <span
                    style={{
                      color: "#5481b2",
                      fontWeight: "600",
                      fontSize: "15px"
                    }}
                  >214-991-4147</span>
                </p>
              </div>
              <div style={{ width: "49%", float: "right" }}>
                <p
                  style={{
                    backgroundColor: "#8080801a",
                    padding: "10px",
                    color: "black",
                    textAlign: "center"
                  }}
                >
                  Offce:
                  <span
                    style={{
                      color: "#5481b2",
                      fontWeight: "600",
                      fontSize: "15px"
                    }}
                  >469-912-0465</span>
                </p>
              </div>
              <div style={{ marginTop: "66px" }}>
                <p
                  style={{
                    backgroundColor: "#8080801a",
                    padding: "10px",
                    textAlign: "center",
                    color: "#5481b2",
                    fontWeight: "600",
                    fontSize: "15px"
                  }}
                >info@homematchx.com</p>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
};

export default OfferEmailTemplate;
