import React, { Component } from "react";
import "firebase/firestore";
import "firebase/functions";
import { Link } from "react-router-dom";

export default class SubscriptionExpiredPage extends Component {
  render() {
    return (
      <div className="content-area5 dashboard-content">
        <div className="dashboard-header clearfix">
          <div>
            <div className="col-sm-12">
              <h4>Subscription Expired</h4>
            </div>
            <div className="col-sm-12">
              <p>Your Subscription has expired, please renew your subscription to get full access.</p>
            </div>
            <div className="col-sm-6">
              <Link to="/subscriptions" className="btn btn-success">
                Go to Subscription page
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}