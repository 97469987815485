import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';
import firebase from "firebase/app";
import * as _ from "lodash";
import ReviewsModal from './ReviewsModal';

class UserReviewsCount extends Component {

  constructor(props) {
    super(props)

    this.complete = props.complete;

    this.state = {
      loading: true,
      reviews: [],
      averageRating: 0,
    }
  }
        

    async componentDidMount() {

        const { resourceId } = this.props;
        const db = firebase.firestore();

        await  db.collection("reviews").doc(resourceId).get().then(doc => {
            const data = doc.data();
            const { reviews } = data || {};
           this.setState({
               reviews: reviews || []
           })
        });
    }

    calculateRating = () => {
        const { reviews } = this.state;

        if((reviews || []).length === 0) {
            return 0;
        }

        const totalReviews = (reviews || []).length;

        const totalRatings = _.sumBy(reviews, function(o) { return o.overallRatting; }); 
        
        return  parseFloat(totalRatings / totalReviews);

    }
    render() {
        const { reviews } = this.state;
        return (
            <div className={"user-reviews-count " + (this.complete && "user-reviews-count-complete")}>
                {this.complete && <h3>{this.calculateRating()}</h3>}
                <StarRatings
                    rating={this.calculateRating()}
                    starRatedColor="#FFD400"
                    numberOfStars={5}
                />
                {this.complete && <ReviewsModal reviews={reviews}/>}                
            </div>
        )
    }
}


export default UserReviewsCount;