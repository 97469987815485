import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { getOffersByAgent } from 'Services/SellerService';
import _ from 'lodash';
import firebase from 'firebase/app'
import 'firebase/firestore';

class OtherAgentOffer extends Component {

    state = {
        email: this.props.email,
        licenseNo: this.props.licenseNo,
        offers: [],
        otherAgentsOffers: false,
    }

    async componentDidMount() {
        const { licenseNo } = this.state;
        const { propertyId } = this.props;
        let agent = null;
        if (licenseNo) {
            const db = firebase.firestore();
            await db.collection('realtors').where('realtor_license', '==', licenseNo).get().then(snap => {
                if (snap.docs.length > 0) {
                    const doc = snap.docs[0];
                    agent = doc.data();
                } else {
                    console.error('No Agent information found. using given number. this should not happen.');
                }
            });
        }
        let offers = await getOffersByAgent(null, licenseNo, agent ? agent.realtor_license : null);
        offers = offers.filter(offer => offer.seller != propertyId)

        this.setState({ offers });
    }

    render() {
        const { otherAgentsOffers, statusMeta } = this.props;
        const { offers } = this.state;
        if (otherAgentsOffers) {
            return (
                <>
                    {offers.map(offer => (
                        <tr className="all-offers-table-row analytics-find-buyer-table white-border">
                            <td><p className="analytics-lined-td"></p></td>

                            <td className="address-content-width">
                                <p className="table-normal-text">{offer.address}</p>
                                <a className="analytics-offer-details-btn" target="_blank" href={`/offers/${offer.id}?curItem=1&totalItems=1`}>Offer details page</a>
                            </td>
                            <td>
                                <span className={`badge badge-${statusMeta[offer.status].className} status-badge`}>
                                    <img src={statusMeta[offer.status].icon} alt="prepend icon" /> {offer.status}
                                </span>
                            </td>
                            <td>{offer.financingType}</td>
                            <td>${offer.purchasePrice}</td>
                            <td></td>
                        </tr>
                    ))}
                </>
            );
        }
        return null;
    }
}


export default OtherAgentOffer;

