import React, { Component, Fragment } from 'react';
import RatingField from './RatingField';

class Review extends Component {
    state = {
        showMore: false
    }

    toggle = () => {
        const { showMore } = this.state;
        this.setState({
            showMore: !showMore
        })
    }
    render () {
        const { showMore } = this.state;
        const { review } = this.props;
        const { summary, name, overallRatting, address, valueProvided, communicationRatting, processRatting, professionalRatting, timelinessRatting } = review || {};
        return (
            <div className="review">
                <div class="top-section">
                    <h4>
                        <strong>{ name }</strong>
                    </h4>
                    <RatingField isEditable = { false } rating = { overallRatting } />
                </div>

                <p>
                    { summary }
                </p>
                
                { showMore && <Fragment>
                    <div className="additionalReviewRating">
                        <ul>
                        
                            <li>
                                <span>
                                    Communication
                                </span>
                                <RatingField isEditable = { false } rating = { communicationRatting } />
                            </li>
                            <li>
                                <span>
                                    Process
                                </span>
                                <RatingField isEditable = { false } rating = { processRatting } />
                            </li>
                            <li>
                                <span>
                                    Professional
                                </span>
                                <RatingField isEditable = { false } rating = { professionalRatting } />
                            </li>
                            <li>
                                <span>
                                    Timeline
                                </span>
                                <RatingField isEditable = { false } rating = { timelinessRatting } />
                            </li>
                        </ul>
                    </div> 

                
                    <p>
                        Provided Value: 
                        <span>
                            { valueProvided }
                        </span>
                    </p>
                    <p>
                        Address:
                        <span>{ address }</span>
                    </p>
                </Fragment>
                }
 
                <span onClick = { this.toggle }>{ showMore ? 'Show Less' : 'Show More' }</span>
            </div>
        )
    }
}


export default Review;