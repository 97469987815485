import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { all } from "underscore";
import moment from "moment";
import { saveActivity } from "../Services/Activity";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set } from "lodash/fp";

function Reminder({ user }) {
  const [allSellers, setAllSellers] = useState([]);
  const [allOffers, setAllOffers] = useState([]);
  const [modal, setModal] = useState(true);
  const [inProgress, setInProgress] = useState(false);

  const toggle = () => setModal(!modal);

  const updateOfferStatus = async (
    offer,
    offerStatus,
    actionTaken,
    declineOthers = false
  ) => {
    const seller = allSellers.find(
      (seller) => seller.ListingKey === offer.seller
    );
    const id = offer.id;
    try {
      setInProgress(true);
      const db = firebase.firestore();
      const updateHistory = `${moment().format(
        "MM/DD/YYYY"
      )} The agent has ${actionTaken} an offer.`;
      await db
        .collection("offers")
        .doc(id)
        .update({
          status: offerStatus,
          statusChangedAt: moment().utc().unix(),
          updateHistory:
            firebase.firestore.FieldValue.arrayUnion(updateHistory),
        });

      const sendSMS = firebase.functions().httpsCallable("sendSMS");

      if (offerStatus !== "Withdraw") {
        await db.collection("notifications").add({
          user_id: offer.buyer,
          property_id: offer.seller,
          text: `Your offer has been ${actionTaken} by the seller.`,
          type: `offer`,
          isRead: 0,
          createdAt: moment().utc().unix(),
        });
        // if (this.state.sendMessage) {
        await sendSMS({
          to: `+1${offer.phoneNumber}`,
          body: `OFFER ${offerStatus} at ${moment().format(
            "YYYY-MM-DD hh:mmA"
          )}, ${offer.address}.
${user.full_name || user.realtor_name},
Finance: ${offer.financingType}
Price: ${offer.purchasePrice}
Closing: ${offer.closingDate}
Details: https://homematchx.com/offers/${offer.id}`,
        });
        // }
      } else {
        await db.collection("notifications").add({
          user_id: offer.seller_id,
          property_id: offer.seller,
          text: `An offer has been withdrawn from the property.`,
          type: `offer`,
          isRead: 0,
          createdAt: moment().utc().unix(),
        });

        // if (this.state.sendMessage) {
        await sendSMS({
          to: `+1${seller.seller_phone}`,
          body: `OFFER WITHDRAWN at ${moment().format("YYYY-MM-DD hh:mmA")}, ${
            offer.address
          }.
${offer.buyerName},
Finance: ${offer.financingType}
Price: ${offer.purchasePrice}
Closing: ${offer.closingDate}
Details: https://homematchx.com/offers/${offer.id}`,
        });
        // }
      }
    } catch (error) {
      console.log(error);
    }
    toast.success(`Offer has been ${actionTaken} successfully.`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    setInProgress(false);
    saveActivity({
      text: `Offer status has been changed to ${offerStatus}.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid,
      type: "offers",
      linkId: id,
      offerAddress: offer.address,
    });
  };

  useEffect(() => {
    const db = firebase.firestore();
    db.collection("sellers")
      .where("seller_id", "==", user.uid)
      .where("MlsStatus", "==", "Sold")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let obj = {
            ...doc.data(),
            ListingKey: doc.id,
          };
          setAllSellers((prev) => [...prev, obj]);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    //   get offers for each seller
    db.collection("offers")
      .where("seller_id", "==", user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let obj = {
            ...doc.data(),
            id: doc.id,
          };
          setAllOffers((prev) => [...prev, obj]);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }, [user]);

  //  filter offers by sellers icluding offer.seller in allSellers
  let closedPropertyOffers = allOffers.filter((offer) => {
    return allSellers.some((seller) => {
      return seller.ListingKey === offer.seller;
    });
  });

  const inReviewClosedPropertyOffers = closedPropertyOffers.filter((offer) => {
    return offer.status === "In Review";
  });

  return (
    <Modal
      isOpen={inReviewClosedPropertyOffers?.length > 0 ? modal : false}
      size="lg"
      fade={inProgress ? true : false}
      toggle={toggle}
      className="offers-modal"
    >
      <ModalHeader className="modal-header" toggle={toggle}></ModalHeader>
      <ModalBody className="modal-body">
        <Container>
          <Row>
            <Col>
              <div className="row-space-between">
                <h5 className="modal-title">
                  In Review Offers for Closed Properties
                </h5>
              </div>
            </Col>
          </Row>
          {inReviewClosedPropertyOffers?.length > 0 ? (
            <Row className="mb-30">
              <Col>
                <div className="table-responsive">
                  <table className="offers-table table-customized">
                    <tr className="modal-tr-header">
                      <th>Property Address</th>
                      <th>Offer Sender</th>
                      <th>Offer Price</th>
                      <th>Status</th>
                    </tr>

                    {inReviewClosedPropertyOffers.map((offer) => (
                      <tr className="table-row">
                        <td>
                          {allSellers.find(
                            (seller) => seller.ListingKey === offer.seller
                          )?.city +
                            ", " +
                            allSellers.find(
                              (seller) => seller.ListingKey === offer.seller
                            )?.state +
                            ", " +
                            allSellers.find(
                              (seller) => seller.ListingKey === offer.seller
                            )?.zip_code}
                        </td>
                        <td>
                          {offer.agentName || offer.name || offer.buyerName}
                        </td>
                        <td>{offer.actual_price}</td>
                        <td>
                          {inProgress ? (
                            <i className="fa fa-spinner fa-spin fa-3x fa-fw progress-icon"></i>
                          ) : (
                            <select
                              name="offerStatus"
                              onChange={(e) => {
                                updateOfferStatus(
                                  offer,
                                  e.target.value,
                                  e.target.value
                                );
                              }}
                              className="search-fields offers-search-fields mr-0 mr-md-4 sellers-status-filter px-2"
                            >
                              <option selected value="In Review">
                                In Review
                              </option>
                              <option value="Accepted">Accepted</option>
                              <option value="Not Accepted">Not Accepted</option>
                              <option value="Executed">Executed</option>
                              {/* {!!statusFilterOptions.length &&
                              statusFilterOptions.map((m, i) => (
                                <option key={i} value={m}>
                                  {m}
                                </option>
                              ))} */}
                            </select>
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </Col>
            </Row>
          ) : (
            <div>No offers in review for closed properties</div>
          )}
          {/* {Object.keys(groupedInReviewClosedPropertyOffers).map((seller) => {
            return (
              <Row className="mb-30">
                <Col>
                  <h1>{seller}</h1>

                  <div className="table-responsive">
                    <table className="offers-table table-customized">
                      <tr className="modal-tr-header">
                        <th>Offer Sender</th>
                        <th>Offer Price</th>
                        <th>Status</th>
                      </tr>

                      {Object.values(
                        groupedInReviewClosedPropertyOffers[seller]
                      ).map((offer) => (
                        <tr className="table-row">
                          <td>
                            {offer.agentName || offer.name || offer.buyerName}
                          </td>
                          <td>{offer.actual_price}</td>
                          <td>{offer.status}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </Col>
              </Row>
            );
          })} */}
        </Container>
      </ModalBody>
    </Modal>
  );
}

export default Reminder;
