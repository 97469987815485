import React from 'react';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table/react-table.css';

import './assets/css/property-page.css';

import Routes from './routes/Routes';

function App() {
  return <Routes />;
}

export default App;