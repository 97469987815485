import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import PartitionedForm from "components/PartitionedForm";
import UserInviteModal from "components/Modals/UserInviteEmailModal";
import { firebaseConfig } from "components/Firebase/firebase";
import { Button, Modal, ModalBody } from "reactstrap";
import moment from "moment";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import { func } from "prop-types";

export const realtorFields = {
  photo: {
    type: "image",
  },
  logo: {
    type: "image",
    label: "Company logo",
  },
  signature: {
    type: "signature",
    label: "Email signature",
  },
  brokerage_name: { width: 3, placeholder: "e.g. Ink Realty", required: true },
  broker_phone: { type: "phone", width: 3, required: true },
  broker_name: { placeholder: "e.g. John Smith", width: 3, required: true },
  office_supervisor_name: { width: 3, required: true },
  office_supervisor_phone: { type: "phone", width: 3, required: true },
  broker_license: { label: "Brokerage License #", width: 3, required: true },
  realtor_email: { width: 4, readonly: true, type: "email", label: "Email" },
  realtor_website: { width: 4, label: "Website" },
  realtor_phone: { width: 4, required: true, label: "Phone number" },
  realtor_name: { width: 4, required: true, label: "Full name" },
  realtor_license: { label: "License #", width: 4, required: true },
  company_name: { label: "Company Name", width: 12, required: false },
  services: {
    multiselect: true,
    options: [
      "Contract to Close",
      "Offer Writing",
      "Full Residential Buyer",
      "Full Residential Seller",
      "iBuyer",
      "Investor",
      " Comparative Market Analysis",
    ],
  },
  street_number: {
    required: true,
    width: 2,
    placeholder: "e.g. 33",
  },
  street_name: {
    required: true,
    width: 4,
    placeholder: "e.g. Main St",
  },
  unit_number: {
    width: 2,
    placeholder: "e.g. 2A",
  },
  city: {
    required: true,
    width: 3,
    placeholder: "e.g. New York",
  },
  state: {
    required: true,
    reviewModal: false,
    inReviewModal: false,
    options: [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ],
  },
  zip_code: {
    required: true,
    type: "number",
    width: 2,
    placeholder: "e.g. 10010",
  },
  realtor_description: {
    required: true,
    placeholder: "Enter a few words about yourself (max 500 characters).",
    type: "textblob",
    label: "About you",
    width: 12,
    maxLength: 500,
  },
  authRole: {
    options: ["Agent", "Owner", "Admin"],
  },
};

class RealtorProfile extends Component {
  constructor(props) {
    super(props);

    const inputs = {};
    const secs =
      this.props.user.role === "titleCompany"
        ? [
            {
              name: "Your Information",
              fields: ["company_name"],
            },
          ]
        : [
            {
              name: "Your Information",
              fields: [
                this.props.match.params.uid ? "authRole" : undefined,
                "realtor_license",
                "realtor_name",
                "realtor_email",
                "realtor_phone",
              ],
            },
            {
              name: "Brokerage Information",
              fields: [
                "broker_license",
                "brokerage_name",
                "broker_name",
                "broker_phone",
                "street_number",
                "street_name",
                "unit_number",
                "city",
                "state",
                "zip_code",
              ],
            },
            {
              name: "Profile Picture",
              fields: ["photo", "logo"],
            },
            {
              name: "Email Signature",
              fields: ["signature"],
            },
          ];
    this.state = {
      error: "",
      busy: false,
      loading: false,
      showLoading: false,
      inputs,
      formsSubmitted: {},
      isChecked: true,
      sections: secs,
    };

    for (const field of secs.map((s) => s.fields).flat()) {
      inputs[field] = "";
    }
  }

  id() {
    return this.props.match.params.uid || this.props.user.uid;
  }
  responseGoogle = async (googleCode) => {
    const getRefreshToken = firebase
      .functions()
      .httpsCallable("getRefreshToken");
    const refreshToken = await getRefreshToken({
      code: googleCode.code,
    });
    const db = firebase.firestore();
    await db
      .collection("users")
      .doc(this.id())
      .update({
        googleAccount: {
          refreshToken: refreshToken.data.refresh_token,
        },
      });
  };

  failureGoogle = (response) => {
    console.log(response);
  };

  async componentWillMount() {
    console.log(this.props.user);
    if (this.props.user) {
      if (
        this.props.user.hasOwnProperty("verifiedUser") &&
        !this.props.user.verifiedUser
      ) {
        this.setState({
          inReviewModal: true,
        });
      }
    }

    const id = this.id();
    const db = firebase.firestore();

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const realtorRef = db.collection("realtors").doc(id);
    const realtorImageRef = storageRef.child(`images/users/${id}.jpg`);
    const realtorLogoRef = storageRef.child(`images/logos/${id}.jpg`);
    let logoUrl = await realtorLogoRef.getDownloadURL();
    let photoUrl = await realtorImageRef.getDownloadURL();
    this.setState({ loading: true });

    realtorRef
      .get()
      .then((doc) => {
        const { inputs } = this.state;
        inputs.realtor_email = this.props.user.email;
        if (this.props.user.role === "titleCompany")
          inputs.company_name = this.props.user.companyName;
        if (doc.exists) {
          const data = doc.data();
          realtorImageRef
            .getDownloadURL()
            .then((url) => {
              inputs.photo = url;
              this.setState({ inputs });
            })
            .catch((error) => {});
          realtorLogoRef.getDownloadURL().then((url) => {
            inputs.logo = url;
            this.setState({ inputs });
          });
          Object.assign(inputs, data);
          this.setState({ inputs: inputs, loading: false });
          this.newRecord = false;
        } else {
          this.setState({ inputs: inputs, loading: false });
          this.newRecord = true;
        }
      })
      .catch((error) => {
        console.error(error);
        this.newRecord = true;
        this.setState({ loading: false });
      });

    this.timer = setTimeout(() => {
      const { loading } = this.state;

      if (loading) {
        // if still loading, show loading indicator
        this.setState({ showLoading: true });
      }
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  save(e) {
    e.preventDefault();

    // check if user object has key verified which is a boolean
    const db = firebase.firestore();
    db.collection("realtors")
      .doc(this.id())
      .onSnapshot((doc) => {
        if (doc.exists) {
          this.saveAsync({ is_reviewed: false });
        } else {
          this.setState({
            reviewModal: true,
          });
        }
      });

    // if (this.props.user.hasOwnProperty("verifiedUser")) {
    //   if (!this.props.user.verifiedUser) {
    //     this.setState({
    //       reviewModal: true,
    //     });
    //     return;
    //   } else {
    //     this.saveAsync();
    //   }
    // } else {
    //   this.saveAsync();
    // }

    // this.saveAsync();
  }

  async saveAsync({ is_reviewed }) {
    const db = firebase.firestore();
    const { inputs } = this.state;
    this.setState({ busy: true });
    const photo = inputs.photo;
    const logo = inputs.logo;

    for (const fieldName of this.state.sections.map((s) => s.fields).flat()) {
      const field = realtorFields[fieldName];

      if (!field) continue;
    }

    try {
      const batch = db.batch();
      const userProfile = { role: "realtor", profileComplete: true };
      const profileDoc = db.collection("realtors").doc(this.id());
      const userDoc = db.collection("users").doc(this.id());
      const publicProfileDoc = db.collection("public_profiles").doc(this.id());
      if (typeof photo !== "string") {
        delete inputs.photo;
        await this.uploadPhoto(this.id(), photo, "users");
      }
      if (typeof logo !== "string") {
        delete inputs.logo;
        await this.uploadPhoto(this.id(), logo, "logos");
      }
      inputs.displayContactInfo = this.state.isChecked;
      if (is_reviewed) {
        inputs.verifiedUser = false;
        inputs.created_at = this.props.user?.created_at || moment().unix();
      }
      let publicData;
      if (this.state.isChecked) {
        publicData = {
          name: inputs.realtor_name,
          email: inputs.realtor_email,
          realtor_license: inputs.realtor_license,
          brokerage_name: inputs.brokerage_name,
          phone: inputs.broker_phone,
          city: inputs.city,
          state: inputs.state,
          // about: inputs.realtor_description,
          // services: inputs.services,
          displayContactInfo: this.state.isChecked,
          ...userProfile,
        };
      } else {
        publicData = {
          name: inputs.realtor_name,
          email: "",
          realtor_license: inputs.realtor_license,
          brokerage_name: inputs.brokerage_name,
          phone: "",
          city: inputs.city,
          state: inputs.state,
          // about: inputs.realtor_description,
          // services: inputs.services,
          displayContactInfo: this.state.isChecked,
          ...userProfile,
        };
      }
      // if (is_reviewed) {
      //   publicData = {
      //     ...publicData,
      //     verifiedUser: false,
      //   };
      // }

      inputs.selectedRole = "realtor";
      batch.set(profileDoc, inputs);
      batch.set(publicProfileDoc, publicData);
      batch.update(userDoc, userProfile);
      await batch.commit();

      const { full_name } = inputs;
      const { sendWelcomeEmail } = this.props;

      if (sendWelcomeEmail) {
        sendWelcomeEmail(full_name);
      }

      this.props.functions.profileAdded(userProfile);
      this.props.history.push("/dashboard");
    } catch (e) {
      console.error(e);
      this.setState({ busy: false });
    }
  }

  async uploadPhoto(id, photo, subPath) {
    const path = `images/${subPath}/${id}.jpg`;
    const realtorImageRef = firebase.storage().ref().child(path);

    if (photo) {
      await realtorImageRef.put(photo);
    } else {
      await realtorImageRef.delete();
    }

    return path;
  }

  title() {
    return this.props.user.role === "titleCompany"
      ? "Company Profile"
      : "Agent Profile";
  }

  setValue(inputId, value) {
    const { inputs } = this.state;
    inputs[inputId] = value;
    this.setState({ inputs });
  }

  toggleContactDisplay = (data) => {
    this.setState({
      isChecked: data.target.checked,
    });
  };

  render() {
    const { loading, showLoading, inputs, isChecked, sections } = this.state;
    if (loading) {
      if (showLoading) {
        return (
          <div className="content-area5 dashboard-content">
            <div className="submit-address dashboard-list">
              <p>Loading</p>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    const full_name = inputs.realtor_name;
    let host = firebaseConfig.host;
    const subject = `${full_name} has requested you on Homematchx`;
    const emailData = {
      heading: `Exciting news! ${full_name} would like to represent you on Homematchx as your preferred real estate agent.`,
      headingLink: {
        label: "Create an account",
        href: `${host}/signup`,
      },
      profilePicture: inputs.photo || `${host}/images/face-placeholder.gif`,
      companyPicture: inputs.logo || `${host}/images/house-placeholder.jpg`,
      profileDescription:
        "Having a real estate agent represent you on Homematchx means you are ready to take the next step in your home buying journey.",
      profileDescriptionTwo: "So what can Homematchx do for buyers...",
      list: [
        `Gain access to MLS properties and future listings up to 3 years out.`,
        `Identify homes that best matches your buying specifications up to 100% match score`,
        `Access personalized live stream channel for virtual home tours and face-to-face chat with sellers and/or agents.`,
        `Negotiate a home sale before it is even listed`,
        `Identify other competing buyers who have similar home interest like you`,
        `and so much more!`,
      ],
      listDetails: `Once you create an account link me as your real estate agent to confirm your representation. Lets get started!`,
    };

    const _sellerEmailData = {
      ...emailData,
      profileDescriptionTwo: "So what can Homematchx do for sellers...",
      list: [
        `Gain access to real home buyer specifications and their interest in your current MLS or future listing.`,
        `Identify buyers that best matches your home specifications up to 100% match score.`,
        `Access personalized live stream channel for virtual home tours and face-to-face chat with buyers and/or agents.`,
        `Identify other competing sellers who have similar buyer interest like you.`,
        `and so much more!`,
      ],
    };

    return (
      <div className="content-area5 dashboard-content">
        <div className="dashboard-header clearfix">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <h4>{this.title()}</h4>
            </div>
            {/* <div className="col-sm-12 col-md-12">
              {!this.props.user.googleAccount && (
                <GoogleLogin
                  // clientId="182635199829-g1eah9omgp0q47oprvoeaqlh1ncc7han.apps.googleusercontent.com"
                  clientId="115691394769-u2kekie8ni2nqjf0bbhikbvp9h46pe74.apps.googleusercontent.com"
                  scope="https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.insert https://www.googleapis.com/auth/gmail.labels"
                  buttonText="Connect to Google"
                  onSuccess={this.responseGoogle}
                  onFailure={this.failureGoogle}
                  accessType="offline"
                  responseType="code"
                />
              )}
              {this.props.user.googleAccount && (
                <button className="btn btn-success">Google Connected</button>
              )}
            </div> */}

            {/* <div className="col-sm-12 col-md-5">
              <UserInviteModal
                {...this.props}
                emailData={emailData}
                subject={subject}
                buttonText={"Invite Buyer"}
              />
              <UserInviteModal
                {...this.props}
                emailData={_sellerEmailData}
                subject={subject}
                buttonText={"Invite Seller"}
              />
            </div> */}
          </div>
        </div>
        <div className="submit-address dashboard-list">
          <form onSubmit={this.save.bind(this)}>
            <PartitionedForm
              fields={realtorFields}
              sections={sections}
              setValue={this.setValue.bind(this)}
              values={this.state.inputs}
              user={this.props.user}
            />
            {this.props.user.role !== "titleCompany" && (
              <div className="row pad-20">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <label>
                    <input
                      type="checkbox"
                      name="is_contact_public"
                      defaultChecked={
                        Object.keys(inputs).length > 0 &&
                        inputs.hasOwnProperty("displayContactInfo")
                          ? inputs.displayContactInfo
                          : isChecked
                      }
                      onChange={(e) => this.toggleContactDisplay(e)}
                    />{" "}
                    Display my contact details to all platform users
                  </label>
                  <br />
                  <br />
                </div>
                <br></br>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <button
                    type="submit"
                    className="btn btn-md button-theme"
                    disabled={this.state.busy}
                  >
                    {this.state.busy ? "Saving" : "Save"}
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>

        {/* modal for review */}

        <Modal
          isOpen={this.state.reviewModal}
          toggle={() => this.setState({ reviewModal: false })}
          size="md"
          backdrop="static"
        >
          <ModalBody className="px-4 py-5 text-center">
            <img
              src="/img/logos/black-logo.png"
              alt="black-logo"
              className="cm-logo"
              width={"250px"}
            />
            <p className="mt-4 dashboard-modal-text">
              Submit your profile for review. Once approved, you will be able to
              access the full features of the platform. It will take 24-48 hours
              to review your profile.
            </p>
            <div className="mt-4">
              {/* <Button
                style={{ width: "100px" }}
                className="btn btn-danger mx-2"
                onClick={(e) => {
                  this.setState({ reviewModal: false });
                }}
              >
                Close
              </Button> */}

              <Button
                style={{ width: "auto" }}
                className="btn btn-success mx-2"
                onClick={(e) => {
                  this.saveAsync({ is_reviewed: true });
                }}
              >
                Submit for Review
              </Button>
            </div>
          </ModalBody>
        </Modal>

        {/* In review Modal */}

        <Modal
          isOpen={this.state.inReviewModal}
          toggle={() => this.setState({ inReviewModal: false })}
          size="md"
        >
          <ModalBody className="px-4 py-4 text-center">
            <img
              src="/img/logos/black-logo.png"
              alt="black-logo"
              className="cm-logo"
              width={"250px"}
            />
            <p className="mt-4 dashboard-modal-text">
              Your profile is in review. Once approved, you will be able to
              access the full features of the platform. It will take 24-48 hours
              to review your profile.
            </p>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default RealtorProfile;
