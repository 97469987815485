import React, { Component } from "react";
import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  Row,
  Col,
  Input
} from "reactstrap";

import LocationSelector from "./LocationSelector";
import GoogleAutoComplete from "./GoogleAutoComplete";

class LocationModal extends Component {

  constructor(props){
    super(props);
    let radius = 1;
    const {location} = this.props;
    if(location && location.radius){
      radius = location.radius;
    }
    this.state = {
      isOpen: false,
      radius
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  onChange = ({target}) => {
    const {name,value} = target;
    const {onLocationChange,location} = this.props;
    this.setState({
      [name]:value
    });
    location.radius = value;
    onLocationChange(location);
  }

  renderInputFields = () => {
    const { selectRadius, addSearch } = this.props;
    const {radius} = this.state;

    const jsx = <GoogleAutoComplete {...this.props} radius={radius} addSearch={addSearch} />;

    if (selectRadius) {
      return (
        <Row>
          <Col sm="9">{jsx}</Col>
          <Col sm="3">
            <Input placeholder="Radius" type="text" onChange={this.onChange} name="radius" value={radius} />
          </Col>
        </Row>
      );
    }

    return jsx;
  };

  render() {
    const { modalStyle, location, placeholder, title } = this.props;
    
    const {radius} = this.state;

    let name = "";
    if (location && location.name) {
      name = location.name;
    }

    return (
      <React.Fragment>
        <input
          className="form-control input-text"
          value={name}
          onClick={this.toggle}
          readOnly={true}
          placeholder={placeholder || ""}
          style={{backgroundColor: '#fff'}}
        />

        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className="modal-success modal-md"
        >
          <ModalHeader toggle={this.toggle}>
            {title || "Select Location"}
          </ModalHeader>
          <ModalBody style={modalStyle}
            className="maps-modal-body"
          >
            {this.renderInputFields()}
            <LocationSelector radius={radius} {...this.props} />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ marginTop: "24px", cursor: "pointer" }}
              color="success"
              onClick={this.toggle}
            >
              Done
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default LocationModal;
