import React, { Component } from "react";
import { buyerFields } from "../pages/BuyerProfile";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";


let visibleItemCount = 5;

class MatchDetails extends Component {
  constructor(props) {
    super(props);

    if (props.view === "email") {
      visibleItemCount = 999;
    }

    this.state = {
      error: "",
      expanded: false,
      imagesItems: [],
    };
  }

  async componentWillMount() {
    const { type, items } = this.props;

    if (type !== "image") {
      return;
    }

    const storage = firebase.storage();
    const storageRef = storage.ref();

    const promies = [];

    (items || []).forEach((item, index) => {
      promies.push(
        new Promise((resolve) => {
          storageRef
            .child(item.buyerImage)
            .getDownloadURL()
            .then((url) => {
              resolve({
                buyerImage: url,
                index,
              });
            })
            .catch((error) => {
              console.log(JSON.stringify(error));
              resolve({
                buyerImage: null,
                index,
              });
            });
        })
      );
      promies.push(
        new Promise((resolve) => {
          storageRef
            .child(item.sellerImage)
            .getDownloadURL()
            .then((url) => {
              resolve({
                sellerImage: url,
                index,
              });
            })
            .catch((error) => {
              console.log(JSON.stringify(error));
              resolve({
                sellerImage: null,
                index,
              });
            });
        })
      );
    });

    const data = await Promise.all(promies);

    data.forEach((d) => {
      const { index, buyerImage, sellerImage } = d;
      items[index] = {
        ...items[index],
        buyerImage: buyerImage ? buyerImage : items[index].buyerImage,
        sellerImage: sellerImage ? sellerImage : items[index].sellerImage,
      };
    });

    console.log(items);

    this.setState({
      imagesItems: items,
    });
  }

  render() {
    const { type } = this.props;
    return (
      <React.Fragment>
        <style>{`.match-details { border-spacing: 0.2em; border-collapse: separate; }
            .match-details th { border: 0; padding: 5px 10px 5px 0; text-align: center }
            .match-details th { text-align: left; }
            .match-details td.def { font-weight: 600; }
            .match-details td { border: 0; padding-right: 30px; }`}</style>
        <table style={{ fontSize: " 13px" }} className="match-details">
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Requested</th>
              <th>Actual</th>
              {type === "image" && <th>Score</th>}
              <th> </th>
            </tr>
          </thead>
          <tbody>{this.rows()}</tbody>
        </table>
      </React.Fragment>
    );
  }

  process(value, name) {
    if (!value) {
      return null;
    }

    if (value.constructor === Array) {
      return value.join(", ");
    }
    let returnValue = value;
    if (name === "Acres") {
      buyerFields.acres.options.forEach(({ key, value: label }) => {
        if (key === returnValue) {
          returnValue = label;
          return;
        }
      });
    }

    if (
      returnValue.includes &&
      (returnValue.includes("NaN") || returnValue.includes("undefined"))
    ) {
      return null;
    }

    return returnValue;
  }

  rows() {
    const { items: propsItems, type } = this.props;
    const { imagesItems, expanded } = this.state;

    let items = propsItems;
    if (type === "image") {
      items = imagesItems;
    }

    let visibleItems = [];
    if (this.needsExpansion()) {
      visibleItems = items.slice(0, visibleItemCount);
    } else {
      visibleItems = items;
    }

    const rows = (visibleItems || []).map((item, i) => {
      const {
        name,
        buyerValue,
        buyerImage,
        sellerValue,
        sellerImage,
        isMatch,
        score,
      } = item;

      const requestedValue = this.process(buyerValue, name);
      const icon = isMatch || !requestedValue ? "check" : "remove";
      return (
        <tr key={i}>
          <td className="def capitalize">{name}</td>
          {type !== "image" && (
            <>
              <td>{requestedValue || "-"}</td>
              <td>{this.process(sellerValue, name) || "-"}</td>
            </>
          )}
          {type === "image" && (
            <>
              <td>
                <a href={buyerImage} target="_blank" rel="noopener noreferrer">
                  <img width="66px" src={buyerImage} alt="Buyer" />
                </a>
              </td>
              <td>
                <a href={sellerImage} target="_blank" rel="noopener noreferrer">
                  <img width="66px" src={sellerImage} alt="Seller" />
                </a>
              </td>
              <td>{`${parseInt(score * 100)}%` || "-"}</td>
            </>
          )}
          <td>
            <i className={`fa-${icon} fa`} />
          </td>
        </tr>
      );
    });

    rows.push(
      <tr key="_expand_">
        <td colSpan="2">
          <button style={{background: '#ff214f', cursor: 'pointer', color: '#fff', border: 'none', marginTop: '10px', padding: '4px'}} onClick={this.expand}>
            {expanded ? 'See less ': 'See more '}
            {(expanded) ? <i className="fa fa-arrow-up"/> : <i className="fa fa-arrow-down"/>}
          </button>
        </td>
      </tr>
    );

    return rows;
  }

  expand = () =>  {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  needsExpansion() {
    const { expanded } = this.state;
    const { items } = this.props;
    return items && !expanded && items.length > visibleItemCount;
  }
}

export default MatchDetails;
