import React from "react";
import { firebaseConfig } from "../Firebase/firebase";

const InviteUserEmailTemplate = props => {
  const { uid } = props.user;
  return (
    <html lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />

        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap"
          rel="stylesheet"
        />
      </head>
        <body style={{fontFamily: 'Open Sans, sans-serif', margin: '0 auto'}}>
            <div style={{float: 'left', width: '100%', boxSizing: 'border-box'}}>
                <div style={{maxWidth: '450px', width: '100%', margin: '0 auto', boxSizing: 'border-box'}}>
                    <div style={{float: 'left', width: '100%', boxSizing: 'border-box', backgroundColor: '#C8D0D8', padding: '30px 30px'}}>
                        <div style={{float: 'left', width: '100%', marginBottom: '15px'}}>
                            <a href={firebaseConfig.host}>
                                <img style={{maxWidth: '200px'}} src={`${firebaseConfig.host}/img/black-logo.png`} alt="homematchx" />
                            </a>
                        </div>
                        <div style={{float: 'left', width: '100%', boxSizing: 'border-box', backgroundColor: '#F60F41', padding: '15px 15px'}}>
                            <p style={{color: '#fff', fontSize: '16px', margin: '0px'}}>Great news! John Smith has created an account on Homematchx and invited you to check it out.</p>
                        </div>
                        <div style={{float: 'left', width: '100%', boxSizing: 'border-box', backgroundColor: '#fff', padding: '30px 50px'}}>
                            <img style={{width: '100%', marginBottom: '15px'}} src={`${firebaseConfig.host}/img/img01.png`} alt="" />
                            <div style={{float: 'left', width: '100%', boxSizing: 'border-box', backgroundColor: '#C8D0D8', padding: '15px 15px'}}>
                                <p style={{marginTop: '0px', fontSize: '14px'}}>Visit <a style={{textDecoration: 'none'}} href={firebaseConfig.host}>Homematchx</a> and join a community of buyers and sellers connecting together to plan their real estate journey.</p>
                                <a style={{fontSize: '14px', textDecoration: 'none'}} href={`${firebaseConfig.host}/profiles/buyers/${ uid }`}>View profile</a>
                            </div>
                        </div>
                        <div style={{float: 'left', width: '100%', boxSizing: 'border-box', padding: '15px 15px'}}>
                            <p style={{margin: 0, fontSize: '12px'}}>By receiving this email and/or utilizing the contents, you agree to our <a style={{textDecoration: 'none'}} href={`${firebaseConfig.host}/terms_of_use`}>Terms of Service</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    </html>
  );
};

export default InviteUserEmailTemplate;
