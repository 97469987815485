import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { drawCustomBorderRadius } from "utils/Functions.js";
import Offers from "../Offers/Offers.js";
import greenDot from "../../../assets/icons/green-dot.png";
import redDot from "../../../assets/icons/red-dot.png";
import yellowDot from "../../../assets/icons/yellow-dot.png";
import closeBtn from "../../../assets/icons/Close.svg";

import styles from "./globalPriceRatio.module.css";
import { Modal, ModalBody } from "reactstrap";
import { set, slice } from "lodash";

const GlobalOffers = ({
  mobile,
  offers,
  setSelectedBrokarage,
  selectedBrokarage,
}) => {
  const [info, setInfo] = useState({});
  const [loader, setLoader] = useState(false);
  const [legendFilter, setLegendFilter] = useState([]);
  const chartRef = useRef(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [lowerSliceLimit, setLowerSliceLimit] = useState(0);
  const [upperSliceLimit, setUpperSliceLimit] = useState(20);
  const [slicedInfo, setSlicedInfo] = useState({});
  const [sortedFilteredOffers, setSortedFilteredOffers] = useState({});

  const chartContainerRef = useRef(null);

  const datasetLabels = ["Accepted", "Not Accepted", "Withdrawn"];

  const drawCustomLabels = (chart) => {
    const ctx = chart.ctx;
    const labelTotals = {};
    const labels = chart.data.labels;
    let indexCount = 0;
    for (let i = 0; i < chart.data.datasets.length; i++) {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        indexCount = i;
      }
    }
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;
      dataset.data.forEach((value, index) => {
        const label = labels[index];
        labelTotals[label] =
          (labelTotals[label] || 0) + (!meta.hidden ? value : 0);
      });
      if (
        (!meta.hidden && datasetIndex == indexCount) ||
        (indexCount == 0 && datasetIndex == 0)
      )
        elements.forEach((element, elementIndex) => {
          drawCustomBorderRadius(element, ctx);

          const label = labels[elementIndex];
          const total = labelTotals[label];
          ctx.save();
          ctx.textBaseline = "middle";
          ctx.fillStyle = "black";
          ctx.font = "bold 14px Arial";
          const labelX = element._view.x;
          const labelY = total == 0 ? chart.height - 40 : element._view.y;
          const val = (total < 1000 ? total : total / 1000).toString();
          ctx.fillText(total, labelX - val.length * 4, labelY - 10);
          ctx.restore();
        });
    });
  };

  const plugin = {
    id: "increase-legend-spacing",
    beforeInit(chart) {
      const originalFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 20;
      };
    },
  };

  useEffect(() => {
    let filteredOffers = {};
    setTimeout(() => {
      setLoader(false);
      if (offers) {
        for (let i = 0; i < offers.length; i++) {
          const singleOffer = offers[i];
          if (filteredOffers[singleOffer.brokerageName]) {
            if (
              singleOffer.status === "Accepted" ||
              singleOffer.status === "Executed"
            ) {
              filteredOffers[singleOffer.brokerageName]["Accepted"]++;
            } else if (singleOffer.status === "Not Accepted") {
              filteredOffers[singleOffer.brokerageName]["Not Accepted"]++;
            } else if (singleOffer.status === "Withdraw") {
              filteredOffers[singleOffer.brokerageName]["Withdrawn"]++;
            }
            if (singleOffer.status === "Verified") {
              filteredOffers[singleOffer.brokerageName].verified++;
            }

            filteredOffers[singleOffer.brokerageName].offersCount++;
          } else {
            if (
              singleOffer.status === "Accepted" ||
              singleOffer.status === "Executed"
            ) {
              filteredOffers[singleOffer.brokerageName] = {
                "Not Accepted": 0,
                Accepted: 1,
                Withdrawn: 0,
                name: singleOffer.brokerageName,
                verified: singleOffer.verified ? 1 : 0,
                offersCount: 1,
              };
            } else if (singleOffer.status === "Not Accepted") {
              filteredOffers[singleOffer.brokerageName] = {
                "Not Accepted": 1,
                Accepted: 0,
                Withdrawn: 0,
                name: singleOffer.brokerageName,
                verified: singleOffer.verified ? 1 : 0,
                offersCount: 1,
              };
            } else if (singleOffer.status === "Withdraw") {
              filteredOffers[singleOffer.brokerageName] = {
                "Not Accepted": 0,
                Accepted: 0,
                Withdrawn: 1,
                name: singleOffer.brokerageName,
                verified: singleOffer.verified ? 1 : 0,
                offersCount: 1,
              };
            }
          }
        }

        if (legendFilter?.length > 0) {
          for (let i = 0; i < legendFilter.length; i++) {
            for (let j = 0; j < Object.entries(filteredOffers).length; j++) {
              Object.entries(filteredOffers)[j][1][legendFilter[i]] = 0;
            }
          }
        }

        let sortedFilteredOffers = {};
        Object.keys(filteredOffers)
          .sort()
          .forEach((key) => {
            sortedFilteredOffers[key] = filteredOffers[key];
          });
        setSortedFilteredOffers(sortedFilteredOffers);
        setInfo(
          Object.fromEntries(
            Object.entries(sortedFilteredOffers).slice(
              lowerSliceLimit,
              upperSliceLimit
            )
          )
        );
      }
      setLoader(true);
    }, 200);

    if (!selectedBrokarage.length) {
      setShowDetails(false);
    }
  }, [offers, selectedBrokarage, legendFilter]);

  useEffect(() => {
    if (selectedBrokarage.length) setShowDetails(true);
  }, []);

  useEffect(() => {
    const slicedData = Object.fromEntries(
      Object.entries(sortedFilteredOffers).slice(
        lowerSliceLimit,
        upperSliceLimit
      )
    );
    setInfo(slicedData);
  }, [lowerSliceLimit, upperSliceLimit]);

  const labels = Object.keys(info);

  const drawCustom = (event, element, offersCount) => {
    if (selectedBrokarage?.length === 1) return;

    const el = Object.values(info)[element._index];
    let x;
    const y = element._model.y;
    const ctx = event.ctx;
    if (element._index + 2 < Object.values(info).length) {
      x = element._model.x + 30;
    } else {
      x = element._model.x - 215;
    }

    const circleRadius = 6;
    const fontSize = 12;
    const parentBlockX = x;
    const parentBlockY = y / 4;
    const parentBlockWidth = 170;
    const parentBlockHeight = 130;
    const parentBlockBackgroundColor = "#fff";

    const parentBlockShadowOffsetX = 0;
    const parentBlockShadowOffsetY = 0;
    const parentBlockShadowBlur = 5;
    const parentBlockShadowColor = "silver";

    ctx.shadowOffsetX = parentBlockShadowOffsetX;
    ctx.shadowOffsetY = parentBlockShadowOffsetY;
    ctx.shadowBlur = parentBlockShadowBlur;
    ctx.shadowColor = parentBlockShadowColor;

    ctx.fillStyle = parentBlockBackgroundColor;
    ctx.fillRect(
      parentBlockX,
      parentBlockY,
      parentBlockWidth,
      parentBlockHeight
    );
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = 0;
    ctx.shadowColor = "transparent";

    const elements = [
      {
        x: parentBlockX + 25,
        y: parentBlockY + 25,
        text: `${el.Accepted} Accepted`,
        color: "#28A745",
        backgroundColor: "#28A745",
      },
      {
        x: parentBlockX + 25,
        y: parentBlockY + 50,
        text: `${el["Not Accepted"]} Not Accepted`,
        color: "#EB3E54",
        backgroundColor: "#EB3E54",
      },
      {
        x: parentBlockX + 25,
        y: parentBlockY + 75,
        text: `${el.Withdrawn} Withdrawn`,
        color: "#FFC107",
        backgroundColor: "#FFC107",
      },
    ];

    for (const element of elements) {
      const { x, y, text, color } = element;

      ctx.beginPath();
      ctx.arc(x, y, circleRadius, 0, 2 * Math.PI);
      ctx.fillStyle = color;
      ctx.fill();
      ctx.closePath();
      ctx.font = `${fontSize}px Arial`;
      ctx.fillStyle = "black";
      ctx.fillText(text, x + circleRadius + 10, y + circleRadius / 2);
    }

    const verifiedBlockX = parentBlockX + 25;
    const verifiedBlockY = parentBlockY + 100;
    const verifiedBlockWidth = 123;
    const verifiedBlockHeight = 15;
    const verifiedBlockFillColor = "#e6f4ff";
    const verifiedBlockBorderColor = "#cdeafd";
    const verifiedBlockBorderRadius = 5;
    const verifiedTitle = `${el?.verified || 0} verified (${(
      ((el?.verified || 0) / +offersCount[0]) *
      100
    ).toFixed(2)}%)`;

    ctx.fillStyle = verifiedBlockFillColor;
    ctx.beginPath();
    ctx.moveTo(verifiedBlockX + verifiedBlockBorderRadius, verifiedBlockY);
    ctx.lineTo(
      verifiedBlockX + verifiedBlockWidth - verifiedBlockBorderRadius,
      verifiedBlockY
    );
    ctx.arcTo(
      verifiedBlockX + verifiedBlockWidth,
      verifiedBlockY,
      verifiedBlockX + verifiedBlockWidth,
      verifiedBlockY + verifiedBlockBorderRadius,
      verifiedBlockBorderRadius
    );
    ctx.lineTo(
      verifiedBlockX + verifiedBlockWidth,
      verifiedBlockY + verifiedBlockHeight - verifiedBlockBorderRadius
    );
    ctx.arcTo(
      verifiedBlockX + verifiedBlockWidth,
      verifiedBlockY + verifiedBlockHeight,
      verifiedBlockX + verifiedBlockWidth - verifiedBlockBorderRadius,
      verifiedBlockY + verifiedBlockHeight,
      verifiedBlockBorderRadius
    );
    ctx.lineTo(
      verifiedBlockX + verifiedBlockBorderRadius,
      verifiedBlockY + verifiedBlockHeight
    );
    ctx.arcTo(
      verifiedBlockX,
      verifiedBlockY + verifiedBlockHeight,
      verifiedBlockX,
      verifiedBlockY + verifiedBlockHeight - verifiedBlockBorderRadius,
      verifiedBlockBorderRadius
    );
    ctx.lineTo(verifiedBlockX, verifiedBlockY + verifiedBlockBorderRadius);
    ctx.arcTo(
      verifiedBlockX,
      verifiedBlockY,
      verifiedBlockX + verifiedBlockBorderRadius,
      verifiedBlockY,
      verifiedBlockBorderRadius
    );
    ctx.closePath();
    ctx.fill();
    ctx.fillRect(
      verifiedBlockX,
      verifiedBlockY,
      verifiedBlockWidth,
      verifiedBlockHeight
    );

    ctx.lineWidth = 1;
    ctx.strokeStyle = verifiedBlockBorderColor;
    ctx.strokeRect(
      verifiedBlockX,
      verifiedBlockY,
      verifiedBlockWidth,
      verifiedBlockHeight
    );

    ctx.fillStyle = parentBlockBackgroundColor;
    ctx.font = `${fontSize}px Arial`;
    ctx.fillStyle = "#109CF1";
    ctx.fillText(
      verifiedTitle,
      verifiedBlockX + 25,
      verifiedBlockY + verifiedBlockHeight / 2 + 4
    );

    let blockX;
    const blockY = parentBlockHeight / 1.3;
    const blockWidth = 16;
    const blockHeight = 16;
    const blockColor = "white";
    const rotationAngle = 45;

    let shadowOffsetX = 2;
    let shadowOffsetY = 0;
    const shadowBlur = 3;
    const shadowColor = "silver";

    if (element._index + 2 < Object.values(info).length) {
      blockX = parentBlockX;
      shadowOffsetX = -2;
      shadowOffsetY = 0;
    } else {
      blockX = parentBlockX + parentBlockWidth;
    }

    ctx.fillStyle = blockColor;
    ctx.shadowOffsetX = shadowOffsetX;
    ctx.shadowOffsetY = shadowOffsetY;
    ctx.shadowBlur = shadowBlur;
    ctx.shadowColor = shadowColor;

    ctx.translate(blockX, blockY);
    ctx.rotate((rotationAngle * Math.PI) / 180);
    ctx.fillRect(0, 0, blockWidth, blockHeight);
    ctx.rotate((-rotationAngle * Math.PI) / 180);
    ctx.translate(-blockX, -blockY);
    ctx.restore();

    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = 0;
    ctx.shadowColor = "transparent";
  };

  const options = {
    tooltips: {
      enabled: false,
    },
    onHover: (_, elements) => {
      if (elements.length > 0 && selectedBrokarage?.length !== 1) {
        let offersCount = Object.values(
          Object.values(info)[elements[0]._index]
        ).reduce((a, b) => a + b, 0);
        drawCustom(chartRef.current.chartInstance, elements[0], offersCount);
      }
    },
    onClick: (_, elements) => {
      if (elements?.length > 0) {
        const newBrokarage = Object.values(info)[elements[0]._index].name;
        const res = [...selectedBrokarage];
        if (!res.includes(newBrokarage)) {
          res.push(newBrokarage);
          setSelectedBrokarage(res);
          if (mobile) setShowDetailsModal(true);
        }
      }
    },
    legend: {
      display: false,
      position: "top",
      align: "start",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: !!labels?.length,
            borderDash: [6, 5],
            lineWidth: 2,
            borderDashOffset: 20,
            color: "rgba(235, 235, 235, 1)",
            drawBorder: false,
            drawTicks: false,
          },
          ticks: {
            beginAtZero: true,
            crossAlign: "start",
            padding: 15,
            suggestedMin: 0,
            suggestedMax: 10,
            callback: (value, index) => {
              if (!!labels?.length) {
                if (index % 2 == 0) {
                  return value;
                } else {
                  return null;
                }
              }
              return "";
            },
          },
        },
      ],
      xAxes: [
        {
          offsetGridLines: true,
          gridLines: {
            display: true,
            color: "#fff",
            drawBorder: false,
            drawTicks: false,
            zeroLineWidth: 0.5,
            drawOnChartArea: function (context) {
              return context.tick.index === 0;
            },
          },
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            fontSize: 10,
            padding: 20,
            callback: (value) => {
              if (value.length > 10) {
                return value.slice(0, 10) + "...";
              }
              return value;
            },
          },
        },
      ],
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Accepted",
        data: Object.values(info).map((el) => el["Accepted"]),
        backgroundColor: "#28A745",
        stack: 1,
        barThickness: 32,
        hitRadius: 10,
      },
      {
        label: "Not Accepted",
        data: Object.values(info).map((el) => el["Not Accepted"]),
        backgroundColor: "#EB3E54",
        stack: 1,
        barThickness: 32,
      },
      {
        label: "Withdrawn",
        data: Object.values(info).map((el) => el["Withdrawn"]),
        backgroundColor: "#FFC107",
        stack: 1,
        barThickness: 32,
      },
    ],
  };

  const plugins = [
    plugin,
    {
      id: "customLabels",
      afterDatasetsDraw: (chart) => drawCustomLabels(chart),
    },
  ];

  return (
    <>
      {mobile ? (
        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            background: "#FFFFFF",
            position: "relative",
          }}
        >
          {loader ? (
            <>
              {!showDetails && !selectedBrokarage?.length && (
                <div
                  className={styles.labels}
                  style={{
                    marginLeft: "6px",
                    marginBottom: "20px",
                  }}
                >
                  {datasetLabels.map((label) => (
                    <React.Fragment key={label}>
                      <span
                        className={styles.circle3}
                        onClick={() => {
                          if (legendFilter.includes(label)) {
                            setLegendFilter(
                              legendFilter.filter((e) => e !== label)
                            );
                          } else {
                            setLegendFilter([...legendFilter, label]);
                          }
                        }}
                      ></span>
                      <span
                        className={
                          legendFilter.includes(label)
                            ? styles["text-decoration-line-through"]
                            : ""
                        }
                      >
                        {label}
                      </span>
                    </React.Fragment>
                  ))}
                </div>
              )}
              <div
                ref={chartContainerRef}
                className={
                  !selectedBrokarage?.length && !!labels?.length
                    ? "chartWrapper-scroll chartWrapper-scroll-global"
                    : ""
                }
                onScroll={(e) => {
                  const container = chartContainerRef.current;
                  const scrollEndPosition =
                    container.scrollWidth - container.clientWidth;
                  const center = scrollEndPosition / 2;
                  if (
                    Object.keys(sortedFilteredOffers).length ===
                      upperSliceLimit &&
                    Math.ceil(container.scrollLeft) >= scrollEndPosition
                  ) {
                    return;
                  }
                  if (Math.ceil(container.scrollLeft) >= scrollEndPosition) {
                    if (
                      Object.keys(sortedFilteredOffers).length -
                        upperSliceLimit <
                      10
                    ) {
                      setLowerSliceLimit(
                        Object.keys(sortedFilteredOffers).length - 20
                      );
                      setUpperSliceLimit(
                        Object.keys(sortedFilteredOffers).length
                      );
                    } else {
                      setLowerSliceLimit((prev) => prev + 10);
                      setUpperSliceLimit((prev) => prev + 10);
                      container.scrollTo(center, 0);
                    }
                  } else if (Math.ceil(container.scrollLeft) === 0) {
                    if (lowerSliceLimit >= 20) {
                      setLowerSliceLimit((prev) => prev - 10);
                      setUpperSliceLimit((prev) => prev - 10);
                      container.scrollTo(center, 0);
                    } else {
                      setLowerSliceLimit(0);
                      setUpperSliceLimit(20);
                    }
                  }
                }}
              >
                <div
                  style={{
                    width: `${
                      !selectedBrokarage?.length
                        ? (labels.length * 80 < 1000
                            ? 1000
                            : labels.length * 80) + "px"
                        : "auto"
                    }`,
                    minHeight: "320px",
                    cursor: "pointer",
                  }}
                >
                  {selectedBrokarage?.length === 1 ? (
                    showDetails && (
                      <div className={`${styles.roundDiagrams}`}>
                        <Offers
                          accepted={info[selectedBrokarage[0]]?.Accepted || 0}
                          notAccepted={
                            info[selectedBrokarage[0]]
                              ? info[selectedBrokarage[0]]["Not Accepted"]
                              : 0
                          }
                          withdrawn={info[selectedBrokarage[0]]?.Withdrawn || 0}
                          verified={info[selectedBrokarage[0]]?.verified || 0}
                          isGlobal={true}
                          offersCount={
                            info[selectedBrokarage[0]]?.offersCount || 0
                          }
                        />
                      </div>
                    )
                  ) : (
                    <>
                      {/* <div className="scroll-cut" /> */}
                      {!labels?.length && (
                        <span
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            // transform: "translate(320%, 450%)",
                          }}
                        >
                          There is no data...
                        </span>
                      )}
                      <Bar
                        ref={chartRef}
                        height="0"
                        options={options}
                        data={data}
                        plugins={plugins}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        // desktop view
        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            background: "#FFFFFF",
            position: "relative",
          }}
        >
          {loader ? (
            <>
              {!selectedBrokarage?.length && (
                <div
                  className={styles.labels}
                  style={{
                    marginLeft: "6px",
                    marginBottom: "20px",
                  }}
                >
                  {datasetLabels.map((label) => (
                    <React.Fragment key={label}>
                      <span
                        className={styles.circle3}
                        onClick={() => {
                          if (legendFilter.includes(label)) {
                            setLegendFilter(
                              legendFilter.filter((e) => e !== label)
                            );
                          } else {
                            setLegendFilter([...legendFilter, label]);
                          }
                        }}
                      ></span>
                      <span
                        className={
                          legendFilter.includes(label)
                            ? styles["text-decoration-line-through"]
                            : ""
                        }
                      >
                        {label}
                      </span>
                    </React.Fragment>
                  ))}
                </div>
              )}
              <div
                ref={chartContainerRef}
                className={
                  !selectedBrokarage?.length && !!labels?.length
                    ? "chartWrapper-scroll chartWrapper-scroll-global"
                    : ""
                }
                onScroll={(e) => {
                  const container = chartContainerRef.current;
                  const scrollEndPosition =
                    container.scrollWidth - container.clientWidth;
                  const center = scrollEndPosition / 2;
                  if (
                    Object.keys(sortedFilteredOffers).length ===
                      upperSliceLimit &&
                    Math.ceil(container.scrollLeft) >= scrollEndPosition
                  ) {
                    return;
                  }
                  if (Math.ceil(container.scrollLeft) >= scrollEndPosition) {
                    if (
                      Object.keys(sortedFilteredOffers).length -
                        upperSliceLimit <
                      10
                    ) {
                      setLowerSliceLimit(
                        Object.keys(sortedFilteredOffers).length - 20
                      );
                      setUpperSliceLimit(
                        Object.keys(sortedFilteredOffers).length
                      );
                    } else {
                      setLowerSliceLimit((prev) => prev + 10);
                      setUpperSliceLimit((prev) => prev + 10);
                      container.scrollTo(center, 0);
                    }
                  } else if (Math.ceil(container.scrollLeft) === 0) {
                    if (lowerSliceLimit >= 20) {
                      setLowerSliceLimit((prev) => prev - 10);
                      setUpperSliceLimit((prev) => prev - 10);
                      container.scrollTo(center, 0);
                    } else {
                      setLowerSliceLimit(0);
                      setUpperSliceLimit(20);
                    }
                  }
                }}
              >
                <div
                  style={{
                    width: `${
                      !selectedBrokarage?.length
                        ? (labels.length * 80 < 1000
                            ? 1000
                            : labels.length * 80) + "px"
                        : "auto"
                    }`,
                    minHeight: "320px",
                    cursor: "pointer",
                  }}
                >
                  {selectedBrokarage?.length === 1 ? (
                    <div className={`${styles.roundDiagrams}`}>
                      <Offers
                        accepted={info[selectedBrokarage[0]]?.Accepted || 0}
                        notAccepted={
                          info[selectedBrokarage[0]]
                            ? info[selectedBrokarage[0]]["Not Accepted"]
                            : 0
                        }
                        withdrawn={info[selectedBrokarage[0]]?.Withdrawn || 0}
                        verified={info[selectedBrokarage[0]]?.verified || 0}
                        isGlobal={true}
                        offersCount={
                          info[selectedBrokarage[0]]?.offersCount || 0
                        }
                      />
                      {/* <span
                        style={{
                          color: "#109CF1",
                          fontSize: "10px",
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          backgroundColor: "rgba(16, 156, 241, 0.1)",
                          border: "1px solid rgba(16, 156, 241, 0.2)",
                          borderRadius: "2px",
                          padding: "0 4px ",
                          left: "112px",
                          position: " relative",
                          bottom: "-10px",
                        }}
                      >
                        {info[selectedBrokarage[0]]?.verified || 0} verified{" "}
                        {info[selectedBrokarage[0]]?.verified
                          ? (info[selectedBrokarage[0]]?.verified /
                              info[selectedBrokarage[0]]?.offersCount) *
                            100
                          : 0}
                        %
                      </span> */}
                    </div>
                  ) : (
                    <>
                      {/* <div className="scroll-cut" /> */}
                      {!labels?.length && (
                        <span
                          style={{
                            position: "absolute",
                            transform: "translate(320%, 450%)",
                          }}
                        >
                          There is no data...
                        </span>
                      )}
                      <Bar
                        height="0"
                        ref={chartRef}
                        options={options}
                        data={data}
                        plugins={plugins}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      )}

      {/* details modal mobile */}
      <Modal
        isOpen={showDetailsModal}
        toggle={() => {
          setSelectedBrokarage([]);
          setShowDetailsModal(false);
        }}
        size="sm"
        centered
      >
        <ModalBody className="modal-body py-3">
          <div className="row mob-global-offers-modal-container">
            <div className="col-10 my-auto">
              <h6>{selectedBrokarage.slice(0, 25) + "..."}</h6>
            </div>
            <div className="col-2">
              <img
                src={closeBtn}
                onClick={() => {
                  setSelectedBrokarage([]);
                  setShowDetailsModal(false);
                }}
              />
            </div>

            <div className="col-12">
              <img src={greenDot} className="d-inline" />{" "}
              <p className="d-inline ml-1">
                <span style={{ fontWeight: "700" }}>
                  {info[selectedBrokarage[0]]?.Accepted}
                </span>{" "}
                Accepted
              </p>
            </div>
            <div className="col-12">
              <img src={redDot} className="d-inline" />{" "}
              <p className="d-inline ml-1">
                <span style={{ fontWeight: "700" }}>
                  {info[selectedBrokarage[0]] &&
                    info[selectedBrokarage[0]]["Not Accepted"]}
                </span>{" "}
                Not Accepted
              </p>
            </div>
            <div className="col-12">
              <img src={yellowDot} className="d-inline" />{" "}
              <p className="d-inline ml-1">
                <span style={{ fontWeight: "700" }}>
                  {info[selectedBrokarage[0]]?.Withdrawn}
                </span>{" "}
                Withdrawn
              </p>
            </div>
            <div className="col-12">
              <span
                style={{
                  color: "#109CF1",
                  fontSize: "10px",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  backgroundColor: "rgba(16, 156, 241, 0.1)",
                  border: "1px solid rgba(16, 156, 241, 0.2)",
                  borderRadius: "2px",
                  padding: "0 4px ",
                  // left: "112px",
                  // position: " relative",
                  // bottom: "-10px",
                }}
              >
                {info[selectedBrokarage[0]]?.verified || 0} verified{" "}
                {info[selectedBrokarage[0]]?.verified
                  ? (
                      (info[selectedBrokarage[0]]?.verified /
                        info[selectedBrokarage[0]]?.offersCount) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </span>
            </div>
            <div className="col-12">
              <button
                className="mobile-view-offer-btn"
                onClick={() => {
                  setShowDetails(true);
                  setShowDetailsModal(false);
                }}
              >
                View Details
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default GlobalOffers;
