import React from "react";
import moment from 'moment';
import { firebaseConfig } from "../../components/Firebase/firebase";

function tourEmail(requestInfo, sellerDetails, sellerId) {
	const host = firebaseConfig.host;

	const homePlaceholder = `${host}/images/house-placeholder.jpg`;
	const profilePlaceholder = `${host}/images/face-placeholder.gif`;

	const { buyer } = requestInfo;

	const propertyImage =
		sellerDetails.featured_image && sellerDetails.featured_image.url
			? sellerDetails.featured_image.url
			: homePlaceholder;
	const userImage = requestInfo.buyer.photo || profilePlaceholder;

	return (
		<html>
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="x-apple-disable-message-reformatting" />
				<style>
					{`
            /* What it does: Remove spaces around the email design added by some email clients. */
            /* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
            html,
            body {
                margin: 0 auto !important;
                padding: 0 !important;
                height: 100% !important;
                width: 100% !important;
            }
            
            /* What it does: Stops email clients resizing small text. */
            * {
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
            }
            
            /* What it does: Centers email on Android 4.4 */
            div[style*="margin: 16px 0"] {
                margin:0 !important;
            }
            
            /* What it does: Stops Outlook from adding extra spacing to tables. */
            table,
            td {
                mso-table-lspace: 0pt !important;
                mso-table-rspace: 0pt !important;
            }
                    
            /* What it does: Fixes webkit padding issue. Fix for Yahoo mail table alignment bug. Applies table-layout to the first 2 tables then removes for anything nested deeper. */
            table {
                border-spacing: 0 !important;
                border-collapse: collapse !important;
                table-layout: fixed !important;
                margin: 0 auto !important;
            }
            table table table {
                table-layout: auto; 
            }
            
            /* What it does: Uses a better rendering method when resizing images in IE. */
            img {
                -ms-interpolation-mode:bicubic;
            }
            
            /* What it does: A work-around for iOS meddling in triggered links. */
            .mobile-link--footer a,
            a[x-apple-data-detectors] {
                color:inherit !important;
                text-decoration: underline !important;
            }
          `}
				</style>
				<style>
					{`
            /* What it does: Hover styles for buttons */
            .button-td,
            .button-a {
                transition: all 100ms ease-in;
            }
            .button-td:hover,
            .button-a:hover {
                background: #da8d10 !important;
                border-color: #da8d10 !important;
            }

            /* Media Queries */
            @media screen and (max-width: 480px) {
                body {
                    overflow-x: hidden !important;
                }
                #row-1 {
                text-align:center;
                }
                /* What it does: Forces elements to resize to the full width of their container. Useful for resizing images beyond their max-width. */
                .fluid,
                .fluid-centered {
                    width: 100% !important;
                    max-width: 100% !important;
                    height: auto !important;
                    margin-left: auto !important;
                    margin-right: auto !important;
                }
                /* And center justify these ones. */
                .fluid-centered {
                    margin-left: auto !important;
                    margin-right: auto !important;
                }

                /* What it does: Forces table cells into full-width rows. */
                .stack-column, 
                .stack-column-center {
                    display: block !important;
                    width: 100% !important;
                    max-width: 100% !important;
                    direction: ltr !important;
                    padding: 10px 0px !important;
                    height: auto !important;
                    border: 0 !important;
                }
                /* And center justify these ones. */
                .stack-column-center {
                    text-align: center !important;
                }
            
                /* What it does: Generic utility class for centering. Useful for images, buttons, and nested tables. */
                .center-on-narrow {
                    text-align: center !important;
                    display: block !important;
                    margin-left: auto !important;
                    margin-right: auto !important;
                    float: none !important;
                }
                table.center-on-narrow {
                    display: inline-block !important;
                }
                    
                .table-tobenone {
                display: none;
                }
            }
          `}
				</style>
			</head>
			<body width="100%" bgcolor="#e7e7e7" style={{ margin: "0" }}>
				<center style={{ width: "100%", background: "#ccc" }}>
					<div
						style={{
							display: "none",
							fontSize: "1px",
							lineHeight: "1px",
							maxHeight: "0px",
							maxWidth: "0px",
							opacity: "0",
							overflow: "hidden",
							msoHide: "all",
							fontFamily: "Calibri,Arial,sans-serif",
						}}
					>
						{" "}
						(Optional) This text will appear in the inbox preview, but not the
						email body.{" "}
					</div>

					<div style={{ maxWidth: "700px", margin: "auto" }}>
						<table
							role="presentation"
							cellSpacing="0"
							cellPadding="0"
							border="0"
							align="center"
							width="100%"
							style={{ maxWidth: "700px" }}
						>
							<tr>
								<td
									bgcolor="#fff"
									align="center"
									height="100%"
									valign="top"
									width="100%"
								>
									<table
										role="presentation"
										border="0"
										cellPadding="0"
										cellSpacing="0"
										align="center"
										width="100%"
										style={{ maxWidth: "760px" }}
									>
										<tr>
											<td
												id="row-1"
												align="center"
												valign="top"
												style={{ fontSize: "0", padding: "10px 0" }}
											>
												<div
													style={{
														display: "inline-block",
														margin: "0 -2px",
														width: "100%",
														minWidth: "200px",
														maxWidth: "400px",
														verticalAlign: "top",
													}}
													className="stack-column"
												>
													<table
														role="presentation"
														cellSpacing="0"
														cellPadding="0"
														border="0"
														width="100%"
													>
														<tr>
															<td style={{ padding: "0px" }}>
																<table
																	role="presentation"
																	cellSpacing="0"
																	cellPadding="0"
																	border="0"
																	width="100%"
																	style={{ fontSize: "14px" }}
																>
																	<tr>
																		<td
																			bgcolor="#fff"
																			style={{
																				fontFamily: "Calibri,Arial,sans-serif",
																				fontSize: "11px",
																				msoHeightRule: "exactly",
																				lineHeight: "20px",
																				textAlign: "right",
																				color: "#666666",
																				padding: "0 20px",
																			}}
																			className="stack-column-center"
																		></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
												</div>
											</td>
										</tr>
									</table>
								</td>
							</tr>

							<tr>
								<td bgcolor="#ffffff">
									<table
										role="presentation"
										cellSpacing="0"
										cellPadding="0"
										border="0"
										width="100%"
									>
										<tr>
											<td
												style={{
													padding: "0 10px",
													textAlign: "left",
													fontFamily: "Calibri,Arial,sans-serif",
													fontSize: "17px",
													msoHeightRule: "exactly",
													lineHeight: "26px",
													color: "#313031",
												}}
											>
												<div
													style={{
														border: "4px solid #c8cfd7",
														padding: "15px",
														background: "#eff0f2",
													}}
												>
													<table
														role="presentation"
														border="0"
														cellPadding="0"
														cellSpacing="0"
														align="center"
														width="100%"
														style={{ maxWidth: "660px" }}
													>
														<tr>
															<td
																id="row-1"
																align="center"
																valign="top"
																style={{ fontSize: "0", padding: "0" }}
															>
																<div
																	style={{
																		display: "inline-block",
																		margin: "0 -2px",
																		width: "100%",
																		minWidth: "200px",
																		maxWidth: "400px",
																		verticalAlign: "top",
																	}}
																	className="stack-column"
																>
																	<table
																		role="presentation"
																		cellSpacing="0"
																		cellPadding="0"
																		border="0"
																		width="100%"
																	>
																		<tr>
																			<td style={{ padding: "0" }}>
																				<table
																					role="presentation"
																					cellSpacing="0"
																					cellPadding="0"
																					border="0"
																					width="100%"
																					style={{
																						fontSize: "14px",
																						textAlign: "left",
																					}}
																				>
																					<tr>
																						<td
																							style={{
																								fontFamily:
																									"Calibri,Arial,sans-serif",
																								fontSize: "17px",
																								msoHeightRule: "exactly",
																								lineHeight: "22px",
																								textAlign: "left",
																								color: "#010101",
																								padding: "0",
																							}}
																							className="stack-column-center"
																						>
																							Property and Request Information{" "}
																							<br />
																							<div
																								style={{ paddingTop: "10px" }}
																							>
																								<table
																									role="presentation"
																									border="0"
																									cellPadding="0"
																									cellSpacing="0"
																									align="center"
																									width="100%"
																									style={{ maxWidth: "660px" }}
																								>
																									<tr>
																										<td
																											id="row-1"
																											valign="top"
																											style={{
																												fontSize: "0",
																												padding: "0",
																											}}
																										>
																											<div
																												style={{
																													display:
																														"inline-block",
																													margin: "0 -2px",
																													width: "100%",
																													minWidth: "160px",
																													maxWidth: "168px",
																													verticalAlign: "top",
																													float: "right",
																												}}
																												className="stack-column"
																											>
																												<table
																													role="presentation"
																													cellSpacing="0"
																													cellPadding="0"
																													border="0"
																													width="100%"
																												>
																													<tr>
																														<td>
																															<table
																																role="presentation"
																																cellSpacing="0"
																																cellPadding="0"
																																border="0"
																																width="100%"
																																style={{
																																	fontSize:
																																		"14px",
																																}}
																															>
																																<tr>
																																	<td
																																		style={{
																																			fontFamily:
																																				"Calibri,Arial,sans-serif",
																																			height:
																																				"50px",
																																			fontSize:
																																				"11px",
																																			msoHeightRule:
																																				"exactly",
																																			lineHeight:
																																				"20px",
																																			textAlign:
																																				"right",
																																			color:
																																				"#666666",
																																			padding:
																																				"0 20px",
																																		}}
																																		className="stack-column-center"
																																	>
																																		<a
																																			href={`${host}/sellers/${sellerId}`}
																																		>
																																			<img
																																				src={
																																					propertyImage
																																				}
																																				alt="Property"
																																				style={{
																																					width:
																																						"100%",
																																					maxWidth:
																																						"200px",
																																				}}
																																			/>
																																		</a>
																																	</td>
																																</tr>
																															</table>
																														</td>
																													</tr>
																												</table>
																											</div>
																											<div
																												style={{
																													display:
																														"inline-block",
																													margin: "0 -2px",
																													width: "100%",
																													minWidth: "135px",
																													maxWidth: "140px",
																													verticalAlign: "top",
																												}}
																												className="stack-column"
																											>
																												<table
																													role="presentation"
																													cellSpacing="0"
																													cellPadding="0"
																													border="0"
																													width="100%"
																												>
																													<tr>
																														<td>
																															<table
																																role="presentation"
																																cellSpacing="0"
																																cellPadding="0"
																																border="0"
																																width="100%"
																																style={{
																																	fontSize:
																																		"14px",
																																	textAlign:
																																		"left",
																																}}
																															>
																																<tr>
																																	<td
																																		style={{
																																			fontFamily:
																																				"Calibri,Arial,sans-serif",
																																			fontSize:
																																				"13.5px",
																																			msoHeightRule:
																																				"exactly",
																																			lineHeight:
																																				"17px",
																																			textAlign:
																																				"left",
																																			color:
																																				"#010101",
																																			padding:
																																				"0",
																																		}}
																																		className="stack-column-center"
																																	>
																																		{
																																			sellerDetails.HMX_Id
																																		}{" "}
																																		<br />
																																		{
																																			sellerDetails.street_number
																																		}{" "}
																																		{
																																			sellerDetails.street_name
																																		}
																																		<br />
																																		{
																																			sellerDetails.city
																																		}{" "}
																																		{
																																			sellerDetails.state
																																		}
																																		,{" "}
																																		{
																																			sellerDetails.zip_code
																																		}{" "}
																																		<br />
																																		Price:{" "}
																																		{
																																			sellerDetails.expected_sales_price
																																		}
																																		<br />
																																		<br />
																																		Showing
																																		Request
																																		<br />
																																		Lender
																																		Approval or
																																		Proof of
																																		funds
																																		available :{" "}
																																		{
																																			requestInfo.lender_approval_available
																																		}
																																		<br />
																																		When:{" "}
																																		{moment(
																																			requestInfo.tour_date
																																		).format(
																																			"MMMM Do YYYY"
																																		)}
																																		<br />
																																		Time:{" "}
																																		{
																																			requestInfo.tour_time
																																		}
																																		<br />
																																		<br />
																																		Buyer:{" "}
																																		<a
																																			href={`${host}/buyers/${buyer.id}`}
																																		>
																																			{
																																				buyer.buyer_name
																																			}
																																		</a>
																																		<br />
																																		Represented
																																		By:
																																	</td>
																																</tr>
																															</table>
																														</td>
																													</tr>
																												</table>
																											</div>
																										</td>
																									</tr>
																									<tr>
																										<td
																											id="row-1"
																											valign="top"
																											style={{
																												fontSize: "0",
																												paddingTop: "10px",
																											}}
																										>
																											<div
																												style={{
																													display:
																														"inline-block",
																													margin: "0 -2px",
																													width: "100%",
																													minWidth: "100px",
																													maxWidth: "100px",
																													verticalAlign:
																														"middle",
																												}}
																												className="stack-column"
																											>
																												<table
																													role="presentation"
																													cellSpacing="0"
																													cellPadding="0"
																													border="0"
																													width="100%"
																												>
																													<tr>
																														<td>
																															<table
																																role="presentation"
																																cellSpacing="0"
																																cellPadding="0"
																																border="0"
																																width="100%"
																																style={{
																																	fontSize:
																																		"14px",
																																	textAlign:
																																		"left",
																																}}
																															>
																																<tr>
																																	<td
																																		style={{
																																			fontFamily:
																																				"Calibri,Arial,sans-serif",
																																			fontSize:
																																				"14px",
																																			msoHeightRule:
																																				"exactly",
																																			lineHeight:
																																				"17px",
																																			textAlign:
																																				"left",
																																			color:
																																				"#010101",
																																			padding:
																																				"0",
																																		}}
																																		className="stack-column-center"
																																	>
																																		<img
																																			src={
																																				userImage
																																			}
																																			height=""
																																			border="0"
																																			alt="Person"
																																			className="center-on-narrow"
																																			style={{
																																				width:
																																					"100%",
																																				maxWidth:
																																					"200px",
																																				fontFamily:
																																					"Calibri,Arial,sans-serif",
																																				fontSize:
																																					"15px",
																																				msoHeightRule:
																																					"exactly",
																																				lineHeight:
																																					"20px",
																																				color:
																																					"#555555",
																																				borderRadius:
																																					"50%",
																																			}}
																																		/>
																																	</td>
																																</tr>
																															</table>
																														</td>
																													</tr>
																												</table>
																											</div>
																											<div
																												style={{
																													display:
																														"inline-block",
																													margin: "0 -2px",
																													width: "100%",
																													minWidth: "180px",
																													maxWidth: "180px",
																													verticalAlign:
																														"middle",
																													paddingLeft: "20px",
																												}}
																												className="stack-column"
																											>
																												<table
																													role="presentation"
																													cellSpacing="0"
																													cellPadding="0"
																													border="0"
																													width="100%"
																												>
																													<tr>
																														<td>
																															<table
																																role="presentation"
																																cellSpacing="0"
																																cellPadding="0"
																																border="0"
																																width="100%"
																																style={{
																																	fontSize:
																																		"14px",
																																}}
																															>
																																<tr>
																																	<td
																																		style={{
																																			fontFamily:
																																				"Calibri,Arial,sans-serif",
																																			fontSize:
																																				"13.5px",
																																			msoHeightRule:
																																				"exactly",
																																			lineHeight:
																																				"17px",
																																			textAlign:
																																				"left",
																																			color:
																																				"#010101",
																																			padding:
																																				"0",
																																		}}
																																		className="stack-column-center"
																																	>
																																		{
																																			requestInfo.requestorName
																																		}
																																		<br />{" "}
																																		{requestInfo.requestorRole ===
																																		"user"
																																			? "Non Agent, Self-Represented"
																																			: "Agent, Ink Realty Group"}
																																		<br />
																																		{
																																			requestInfo.requestorContact
																																		}
																																		<br />
																																		{
																																			requestInfo.requestorEmail
																																		}
																																	</td>
																																</tr>
																															</table>
																														</td>
																													</tr>
																												</table>
																											</div>
																										</td>
																									</tr>
																								</table>
																							</div>
																						</td>
																					</tr>
																				</table>
																			</td>
																		</tr>
																	</table>
																</div>
																<div
																	style={{
																		display: "inline-block",
																		margin: "0 -2px",
																		width: "100%",
																		minWidth: "130px",
																		maxWidth: "230px",
																		verticalAlign: "top",
																	}}
																	className="stack-column"
																>
																	<table
																		role="presentation"
																		cellSpacing="0"
																		cellPadding="0"
																		border="0"
																		width="100%"
																	>
																		<tr>
																			<td
																				style={{
																					padding: "0px",
																					paddingTop: "20px",
																				}}
																			>
																				<table
																					role="presentation"
																					cellSpacing="0"
																					cellPadding="0"
																					border="0"
																					width="100%"
																					style={{ fontSize: "14px" }}
																				>
																					<tr>
																						<td
																							style={{
																								fontFamily:
																									"Calibri,Arial,sans-serif",
																								fontSize: "16px",
																								msoHeightRule: "exactly",
																								lineHeight: "20px",
																								textAlign: "left",
																								color: "#010101",
																								padding: "12px 0 0 15px",
																								borderLeft: "5px solid #010101",
																							}}
																							className="stack-column-center"
																						>
																							A showing request has been sent to
																							tour your property. To Accept,
																							Decline or reschedule request,
																							please reply{" "}
																							<a
																								href={`mailto:${requestInfo.requestorEmail}`}
																								style={{
																									color: "#676ea4",
																									textDecoration: "none",
																								}}
																							>
																								here.
																							</a>
																							<br />
																							<br />
																							<br />
																							<br />
																							<br />
																							<br />
																							<br />
																							<span
																								style={{
																									fontSize: "11px",
																									lineHeight: "14px",
																								}}
																							>
																								To unsubscribe from Showing
																								Request emails login to your
																								account and change the settings
																								for auto notification.
																							</span>
																							<br />
																							<br />
																							<span
																								style={{
																									fontSize: "11px",
																									lineHeight: "14px",
																								}}
																							>
																								By recieving this email and/or
																								utilising the contents, you
																								agree to our{" "}
																								<a
																									href={`${host}/terms_of_use`}
																									style={{
																										color: "#676ea4",
																										textDecoration: "none",
																									}}
																								>
																									Terms of Service
																								</a>
																								.
																							</span>
																						</td>
																					</tr>
																				</table>
																			</td>
																		</tr>
																	</table>
																</div>
															</td>
														</tr>
													</table>
												</div>
											</td>
										</tr>
									</table>
								</td>
							</tr>

							<tr>
								<td height="30" bgcolor="#fff"></td>
							</tr>
						</table>
					</div>
				</center>
			</body>
		</html>
	);
}

export default tourEmail;
