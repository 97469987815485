import React, { Component } from "react";
import { InlineShareButtons } from "sharethis-reactjs";

import {
  Row,
  Col,
  Container,
  Label,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

class ShareButtonsModal extends Component {
  state = {
    isOpen: false,
    declineOthers: false,
    includeOfferPackage: false,
    includeComments: false,
    sharedOfferId: "",
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      declineOthers: false,
    });
  };

  toggleCheckbox = () => {
    this.setState({ declineOthers: !this.state.declineOthers });
  };

  confirm = () => {
    this.props.action(this.state.declineOthers);
    this.toggle();
  };

  renderBtnText = () => {
    const { children } = this.props;
    if (children) {
      return children;
    }
    return (
      <React.Fragment>
        <i className="fa fa-notes" />
      </React.Fragment>
    );
  };

  async componentDidMount() {
    const db = firebase.firestore();
    await db
      .collection("sharedOffers")
      .add({
        offerId: this.props.offer.id,
      })
      .then((docRef) => {
        this.setState({
          sharedOfferId: docRef.id,
        });
      });
  }

  render() {
    let { title, offer, modalColor } = this.props;

    return (
      <React.Fragment>
        <span onClick={this.toggle}>{this.renderBtnText()}</span>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className={`modal-${modalColor || "primary"}`}
        >
          <ModalHeader toggle={this.toggle}> {title || ""}</ModalHeader>
          <ModalBody>
            <ModalBody className="modal-body pb-4 pl-2 ml-0 pl-md-0">
              <Container className="p-0">
                <Row className="my-5">
                  <div className="col-12 p-0 m-0">
                    <Label className="shared-offer-labels m-0 p-0">
                      <input
                        className="mr-2"
                        type="checkbox"
                        checked={this.state.includeOfferPackage}
                        onChange={async (e) => {
                          this.setState({
                            includeOfferPackage: e.target.checked,
                          });
                          const db = firebase.firestore();
                          await db
                            .collection("sharedOffers")
                            .doc(this.state.sharedOfferId)
                            .update({
                              includeOfferPackage: e.target.checked,
                              includeComments: this.state.includeComments,
                            });
                        }}
                        // onBlur={() => {
                        //   this.setState({
                        //     includeOfferPackage: false,
                        //   });
                        // }}
                      />
                      Include Offer Package
                    </Label>
                  </div>
                  <div className="col-12 p-0 m-0">
                    <Label className="shared-offer-labels mt-2 mb-0">
                      <input
                        className="mr-2"
                        type="checkbox"
                        checked={this.state.includeComments}
                        onChange={async (e) => {
                          this.setState({ includeComments: e.target.checked });
                          const db = firebase.firestore();
                          await db
                            .collection("sharedOffers")
                            .doc(this.state.sharedOfferId)
                            .update({
                              includeOfferPackage:
                                this.state.includeOfferPackage,
                              includeComments: e.target.checked,
                            });
                        }}
                        // onBlur={() => {
                        //   this.setState({
                        //     includeComments: false,
                        //   });
                        // }}
                      />
                      Include Comments
                    </Label>
                  </div>
                </Row>
                <Row className="">
                  <Col className="p-0">
                    <InlineShareButtons
                      config={{
                        alignment: "center", // alignment of buttons (left, center, right)
                        color: "social", // set the color of buttons (social, white)
                        enabled: true, // show/hide buttons (true, false)
                        font_size: 16, // font size for the buttons
                        labels: "cta", // button labels (cta, counts, null)
                        language: "en", // which language to use (see LANGUAGES)
                        networks: [
                          // which networks to include (see SHARING NETWORKS)
                          "whatsapp",
                          "facebook",
                          "twitter",
                          "sms",
                          "email",
                        ],
                        padding: 5, // padding within buttons (INTEGER)
                        radius: 8, // the corner radius on each button (INTEGER)
                        show_total: true,
                        size: 30, // the size of each button (INTEGER)

                        // OPTIONAL PARAMETERS
                        // add offerpackage and comments to the url as query params
                        url: `I would like to share the offer details with you for ${offer.address} https://homematchx.com/share/offer/${this.state.sharedOfferId}`, // (defaults to current url)
                        image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
                        description: `I would like to share the offer details with you for ${offer.address} https://homematchx.com/share/offer/${this.state.sharedOfferId}`, // (defaults to og:description or twitter:description)
                        title: `I would like to share the offer details with you for ${offer.address} https://homematchx.com/share/offer/${this.state.sharedOfferId}`, // (defaults to og:title or twitter:title)
                        message: `I would like to share the offer details with you for ${offer.address} https://homematchx.com/share/offer/${this.state.sharedOfferId}`, // (only for email sharing)
                        subject: `I would like to share the offer details with you for ${offer.address}`, // (only for email sharing)
                        username: `I would like to share the offer details with you for ${offer.address} https://homematchx.com/share/offer/${this.state.sharedOfferId}`, // (only for twitter sharing)
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ShareButtonsModal;
