import React, { useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { drawCustomBorderRadiusHorizontal } from "utils/Functions";

import styles from "./index.module.css";

const RatioSummary = ({ totalValues }) => {
  const datasetLabels = ["Benchmark (other brokerages)", "Your average homes price", "Your average offers price"];
  const [legendFilter, setLegendFilter] = useState([]);

  const drawCustomLabels = (chart) => {
    const ctx = chart.ctx;
    const x = 15;
    const yStart = 50;
    ctx.save();
    ctx.beginPath();
    ctx.setLineDash([5, 5]);
    ctx.moveTo(x, yStart);
    ctx.lineTo(x, chart.height - 33);
    ctx.stroke();
    ctx.restore();

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;

      if (meta.hidden) return;

      elements.forEach((element, elementIndex) => {
        const startX1 = element._view.x - element._yScale.width / 8;
        const startY1 = element._view.y - element._yScale.width / 0.9;
        const startY2 = element._view.y + element._yScale.width / 1.2;
        const startX2 = element._view.x + element._yScale.width / 6.3;
        drawCustomBorderRadiusHorizontal(
          ctx,
          startX1,
          startY1,
          startY2,
          startX2
        );
        const value = dataset.data[elementIndex];
        ctx.save();
        ctx.textBaseline = "middle";
        ctx.fillStyle = "black";
        ctx.font = "bold 14px Arial";

        const labelX = element._view.x;
        const labelY = element._view.y;

        if (value > 0 && value < 1000) {
          ctx.fillText(value, labelX + 25, labelY);
        } else if (value >= 1000 && value < 1000000) {
          ctx.fillText((value / 1000).toFixed(0) + "K", labelX + 25, labelY);
        } else if (value >= 1000000 && value < 1000000000) {
          ctx.fillText((value / 1000000).toFixed(0) + "M", labelX + 25, labelY);
        } else if (value >= 1000000000 && value < 1000000000000) {
          ctx.fillText(
            (value / 1000000000).toFixed(0) + "B",
            labelX + 25.75,
            labelY
          );
        } else if (value >= 1000000000000) {
          ctx.fillText(
            (value / 1000000000000).toFixed(0) + "T",
            labelX + 25,
            labelY
          );
        }
        ctx.restore();
      });
    });
  };

  const options = {
    responsive: true,
    scales: {
      ticks: {
        backdropPaddingY: 0.1,
        backdropPaddingX: 0.1,
        precision: 0.1,
      },
      xAxes: [
        {
          afterBuildTicks: (scale) => {
            return (scale.ticks = scale.ticks.filter((tick) => tick !== 0));
          },
          gridLines: {
            display: !Object.values(totalValues).every((e) => e === 0),
            offsetGridLines: false,
            borderDash: [5, 5],
            lineWidth: 2,
            borderDashOffset: 4,
            color: "rgba(235, 235, 235, 1)",
            zeroLineColor: "rgba(235, 235, 235, 1)",
            zeroLineWidth: 2,
            zeroLineBorderDash: [5, 5],
            drawTicks: false,
          },
          ticks: {
            padding: 15,
            beginAtZero: true,
            maxTicksLimit: 5,
            callback: (value) => {
              if (Object.values(totalValues).every((e) => e === 0)) {
                return "";
              }
              if (Math.floor(value) == 0) {
                return 0;
              } else if (value < 1000) {
                return value + "K";
              } else if (value >= 1000 && value < 1000000) {
                return (value / 1000).toFixed(0) + "K";
              } else if (value >= 1000000 && value < 1000000000) {
                return (value / 1000000).toFixed(0) + "M";
              } else if (value >= 1000000000 && value < 1000000000000) {
                return (value / 1000000000).toFixed(0) + "B";
              } else if (value >= 1000000000000) {
                return (value / 1000000000000).toFixed(2) + "T";
              } else {
                return null;
              }
            },
            backdropPaddingX: 0.1,
            backdropPaddingY: 0.1,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            backdropPaddingX: 0.1,
            backdropPaddingY: 0.1,
          },
        },
      ],
    },
    tooltips: {
      mode: "nearest",
      intersect: true,
    },
    legend: {
      display: false,
      position: "top",
      align: "start",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
      },
    },
  };

  const data = {
    labels: ["", ""],
    datasets: [
      {
        label: "Your average offers price",
        data: [null, null],
        borderColor: ["#28A745"],
        backgroundColor: ["#28A745"],
        barThickness: [32, 0],
        barPercentage: 10,
      },
      {
        label: "Your average homes price",
        data: [legendFilter.includes("Your average homes price") ? null : Math.round(totalValues?.yourActualPrice).toFixed(0), legendFilter.includes("Your average offers price") ? null : Math.round(totalValues?.yourPurchasePrices).toFixed(0)],
        borderColor: ["#28A745", "#109CF1"],
        backgroundColor: ["#28A745", "#109CF1"],
        barThickness: [0, 32],
      },
      {
        label: "Benchmark (other brokerages)",
        data: !legendFilter.includes("Benchmark (other brokerages)") ? [
          Math.round(totalValues?.otherActual).toFixed(0),
          Math.round(totalValues?.otherPurchasePrices).toFixed(0),
        ] : [],
        backgroundColor: ["#EBEBEB"],
        barThickness: 32,
        barPercentage: 11,
      },
    ],
  };

  data.datasets.forEach((dataset) => {
    dataset.barThickness = dataset.data.map((value) => (!value ? 0 : 32));
  });

  return (
    <div style={{ position: "relative", minHeight: "400px" }}>
      {Object.values(totalValues).every((e) => e === 0) ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <span>There is no data...</span>
        </div>
      ) : (<>
          <div className={styles.labels} style={{
              marginLeft: "6px",
              marginBottom: "20px",
            }}>
            {datasetLabels.map((label) => (
              <React.Fragment key={label}>
                <span className={styles.circle4} onClick={() => {
                  if (legendFilter.includes(label)) {
                    setLegendFilter(legendFilter.filter(e => e !== label))
                  } else {
                    setLegendFilter([...legendFilter, label])
                  }
                }} />
                <span className={legendFilter.includes(label) ? styles['text-decoration-line-through'] : ""}>{label}</span>
              </React.Fragment>
            ))}
          </div>
        <HorizontalBar
          plugins={[
            {
              id: "customLabels",
              afterDatasetsDraw: (chart) => drawCustomLabels(chart),
            },
          ]}
          data={data}
          options={options}
        />
          </>
      )}
    </div>
  );
};

export default RatioSummary;
