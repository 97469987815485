import React from 'react';
import {  Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ value, total, color, title  }) => {

  const legend = {
    display: false
  }

  const options = {
    cutoutPercentage: 70,
    animation: {
      animationRotate: true,
      duration: 2000
    },
    borderRadius: 30,
    tooltips: {enabled: false},
    hover: {mode: null},
  }

  const remainder = total - value

  const data = {
   // labels: [color,'gray'],
    datasets: [
      {
        label: '# of Votes',
        data: [value, remainder],
        backgroundColor: [color],
        borderColor: [color],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div style={{padding:'5px', position: 'relative', minWidth: "170px"}}>
      <div
        style={{
          position: 'absolute',
          top: '50%', left: '50%',
          transform: 'translate(-50%, -50%)',
          display:'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop:'5px',
        }}>
        <p
        style={{
          textAlign:'center',
          lineHeight:'14px',
          fontSize:'12px',
          fontStyle:'normal',
          margin:0
        }}>{title}</p>
        <span style={{margin: 'o auto', 
        fontFamily:"Poppins",
        fontSize: '32px',
        fontWeight:'600',
        color: '#222222',
        lineHeight:'38px',
        letterSpacing:'0.01rem'}}>{value}</span>
      </div>
      <Doughnut data={data} width={160} height={160} legend={legend} options={options}/>
    </div>
  );
};

export default DoughnutChart;
