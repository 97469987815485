import React, { Component } from "react";
import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  FormGroup,
  Input,
  Label,
  Alert,
  Row,
  Col
} from "reactstrap";

import firebase from "firebase/app";
import "firebase/functions";
const { Stripe } = window;

class CreateCardModal extends Component {
  initState = {
    card: {
      number: "",
      cvc: "",
      exp_month: "",
      exp_year: "",
      address_zip: ""
    },
    saving: false,
    isOpen: false,
    error: ""
  };

  state = {
    ...this.initState
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    let { card } = this.state;
    card = {
      ...card,
      [name]: value
    };
    this.setState({ card });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  add = () => {
    const { card } = this.state;
    const { uid } = this.props.user;

    this.setState({
      saving: true,
      error: ""
    });

    Stripe.card.createToken(card, async (status, response) => {
      const { error, id } = response;

      if (error) {
        return this.setState({
          error: error.message,
          saving: false
        });
      }

      try {
        await firebase
          .firestore()
          .collection("stripe_customers")
          .doc(uid)
          .collection("tokens")
          .add({ token: id });

        this.setState({
          ...this.initState
        });

      } catch (error) {
        this.setState({
          error: error.message,
          saving: false
        })
      }
    });
  };
  render() {
    const { isOpen, saving, card, error } = this.state;
    const { number, cvc, exp_month, exp_year, address_zip } = card;

    return (
      <React.Fragment>
        <Button block color="success" onClick={this.toggle} className="mr-1">
          <i className="fa fa-plus" /> Add Card
        </Button>

        <Modal
          size="lg"
          isOpen={isOpen}
          toggle={this.toggle}
          className="modal-default"
        >
          <ModalHeader toggle={this.toggle}>
            <i className="fa fa-plus" /> Add Card
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="number">Card Number</Label>
              <Input
                type="text"
                name="number"
                placeholder="e.g 4242424242424242"
                value={number}
                onChange={this.onChange}
              />
            </FormGroup>

            <FormGroup>
              <Row>
                <Col xs="6" sm="3">
                  <Input
                    type="text"
                    name="exp_month"
                    placeholder="Expiry Month"
                    value={exp_month}
                    onChange={this.onChange}
                  />
                </Col>

                <Col xs="6" sm="3">
                  <Input
                    type="text"
                    name="exp_year"
                    placeholder="Expiry Year"
                    value={exp_year}
                    onChange={this.onChange}
                  />
                </Col>

                <Col xs="6" sm="3">
                  <Input
                    type="text"
                    name="cvc"
                    placeholder="CVC"
                    value={cvc}
                    onChange={this.onChange}
                  />
                </Col>

                <Col xs="6" sm="3">
                  <Input
                    type="text"
                    name="address_zip"
                    placeholder="Zip Code"
                    value={address_zip}
                    onChange={this.onChange}
                  />
                </Col>
              </Row>
            </FormGroup>

            {error && <Alert color="danger">{error}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.add}>
              {saving ? "Saving..." : "Save"}
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CreateCardModal;
