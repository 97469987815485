import React, { Component } from 'react'
import firebase from 'firebase/app'
import { toast } from 'react-toastify'
import ReactDOMServer from 'react-dom/server'
import 'firebase/firestore';
import 'firebase/analytics';
import {firebaseConfig} from './../components/Firebase/firebase';
let {logEvent} = firebase.analytics();

class LenderApprovalForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {
                credit_reviwed: false,
                income_reviwed: false,
                docs_reviewed: false,
                debts_reviewed: false,
                assets_reviewed: false,
                requestor_qualified: false
            },
            lenderImageUrl: ''
            }
        }

    componentWillMount() {
    }

    componentDidMount() {
        const db = firebase.firestore()
        const storage = firebase.storage()
        const storageRef = storage.ref()
        db.collection("lenderleads").doc(this.props.match.params.id).get().then(leadRef => {
            const data = leadRef.data();
            this.setState({lenderLeadInfo: data})
            const logoImageRef = storageRef.child(`images/logos/${data.lender_id}.jpg`)
            logoImageRef.getDownloadURL()
            .then(url => {
                    this.setState({logoUrl: url})
            }).catch(error => {})
            const lenderImageRef = storageRef.child(`images/users/${data.lender_id}.jpg`)
            lenderImageRef.getDownloadURL()
            .then(url => {
                    this.setState({lenderImageUrl: url})
            }).catch(error => {})
        })
    }

    componentWillUnmount() {
    }

    redirectLender = () => {
        this.props.history.push('/signup')
    }

    requestorsEmail = (dataObject) => {
        const {host,storageBucket} = firebaseConfig;
        return <html>
        <head>
        <meta content="text/html; charset=utf-8" httpEquiv="Content-Type"/>
        <meta content="width=device-width" name="viewport"/>
        <meta content="IE=edge" httpEquiv="X-UA-Compatible"/>
            <style>
                {`
                body {
                    margin: 0;
                    padding: 0;
                    }
                    table,
                    td,
                    tr {
                    vertical-align: top;
                    border-collapse: collapse;
                    }
                    * {
                    line-height: inherit;
                    }
                    a[x-apple-data-detectors=true] {
                    color: inherit !important;
                    text-decoration: none !important;
                    }`}
            </style>
            <style id="media-query" type="text/css">{`
                @media (max-Width: 500px) {
                .block-grid,
                .col {
                min-width: 320px !important;
                max-width: 100% !important;
                display: block !important;
                }
                .block-grid {
                width: 100% !important;
                }
                .col {
                width: 100% !important;
                }
                .col>div {
                margin: 0 auto;
                }
                img.fullwidth,
                img.fullwidthOnMobile {
                max-width: 100% !important;
                }
                .no-stack .col {
                min-width: 0 !important;
                display: table-cell !important;
                }
                .no-stack.two-up .col {
                width: 50% !important;
                }
                .no-stack .col.num4 {
                width: 33% !important;
                }
                .no-stack .col.num8 {
                width: 66% !important;
                }
                .no-stack .col.num4 {
                width: 33% !important;
                }
                .no-stack .col.num3 {
                width: 25% !important;
                }
                .no-stack .col.num6 {
                width: 50% !important;
                }
                .no-stack .col.num9 {
                width: 75% !important;
                }
                .video-block {
                max-width: none !important;
                }
                .mobile_hide {
                min-height: 0px;
                max-height: 0px;
                max-width: 0px;
                display: none;
                overflow: hidden;
                font-size: 0px;
                }
                .desktop_hide {
                display: block !important;
                max-height: none !important;
                }
                }`}
  </style>
        </head>
        <body className="clean-body" style={{"margin": "0", "padding": "0", "-webkitTextSizeAdjust": "100%", "backgroundColor": "#d9d9d9"}}>
        <center bgcolor="black" > 
        <table bgcolor="#d9d9d9" cellPadding="0" cellSpacing="0" className="nl-container" role="presentation" style={{"tableLayout": "fixed", "verticalAlign": "top", "minWidth": "320px", "Margin": "0 auto", "borderSpacing": "0", "borderCollapse": "collapse", "msoTableLspace": "0pt", "msoTableRspace": "0pt", "backgroundColor": "#d9d9d9", "width": "490px", "max-width": "540px", "margin": "auto"}} valign="top">
     <tbody>
        <tr style={{"verticalAlign": "top"}} valign="top">
           <td style={{"wordBreak": "breakWord", "verticalAlign": "top"}} valign="top"> 
                       <div style={{"backgroundColor":"transparent"}}>
                          <div className="block-grid" style={{"Margin": "0 auto", "minWidth": "320px", "maxWidth": "490px", "overflowWrap": "break-word", "wordWrap": "break-word", "wordBreak": "breakWord", "backgroundColor": "#F6F6F4"}}>
                             <div style={{"borderCollapse": "collapse", "display": "table", "width": "100%","backgroundColor":"#F6F6F4"}}>

                                    <div className="col num12" style={{"minWidth": "320px", "maxWidth": "490px", "display": "table-cell", "verticalAlign": "top", "width": "490px"}}>
                                        <div style={{"width":"100% !important"}}>
                                            <div style={{"borderTop":"0px solid transparent",  "background": "#d9d9d9", "borderLeft":"0px solid transparent", "borderBottom":"0px solid transparent", "borderRight":"0px solid transparent", "paddingTop":"5px", "paddingBottom":"5px", "paddingRight": "0px", "paddingLeft": "0px"}}>
                                            <div align="left" className="img-container left fixedwidth" style={{"paddingRight": "0px","paddingLeft": "0px"}}>
                                                <a href={`${host}/`} target="_blank" rel="noopener noreferrer"> <img alt="logo" border="0" className="left fixedwidth" src={`https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/images%2Flogos%2Flogo.png?alt=media&token=c078ea4a-100a-47af-9356-eaebac6346c3`} style={{"textDecoration": "none", "-msInterpolationMode": "bicubic", "height": "auto", "border": "none", "width": "100%", "maxWidth": "208px", "display": "block"}} title="Image" width="208"/></a>
                                                        
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                        
                             </div>
                          </div>
                       </div>
                       <div style={{"backgroundColor":"transparent"}}>
                          <div className="block-grid" style={{"Margin": "0 auto", "minWidth": "320px", "maxWidth": "490px", "overflowWrap": "break-word", "wordWrap": "break-word", "wordBreak": "break-word", "backgroundColor": "#F6F6F4"}}>
                             <div style={{"borderCollapse": "collapse", "display": "table", "width": "100%", "backgroundColor":"#F6F6F4"}}>
                               
                                    <div className="col num12" style={{"minWidth": "320px", "maxWidth": "490px", "display": "table-cell", "verticalAlign": "top", "width": "490px"}}>
                                        <div style={{"backgroundColor":"#ed635e", "width":"100% !important"}}>
                                        
                                            <div style={{"borderTop":"0px solid transparent", "borderLeft":"0px solid transparent", "borderBottom":"0px solid transparent", "borderRight":"0px solid transparent", "paddingTop":"5px", "paddingBottom":"5px", "paddingRight": "0px", "paddingLeft": "0px"}}>
                                            
                                                        <div style={{"color":"#555555", "fontFamily":"Arial, 'Helvetica Neue', Helvetica, sans-serif", "lineHeight":"150%", "paddingTop":"10px", "paddingRight":"10px","paddingBottom":"10px","paddingLeft":"10px"}}>
                                                        <div style={{"fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "fontSize": "12px", "lineHeight": "18px", "color": "#555555"}}>
                                                            <p style={{"fontSize": "14px", "lineHeight": "21px", "margin": "0"}}><strong><span style={{"color": "#ffffff", "fontSize": "18px", "lineHeight": "27px"}}>Great news! {dataObject.lender_name} with {dataObject.lender_company} has confirmed your qualification and is added to your buyer profile.</span></strong></p>
                                                        </div>
                                                        </div>
                                                    
                                            </div>
                                            
                                        </div>
                                    </div>               
                             </div>
                          </div>
                       </div>
                       <div style={{"backgroundColor":"transparent"}}>
                          <div className="block-grid two-up" style={{"Margin": "0 auto",  "minWidth": "320px", "maxWidth": "490px", "overflowWrap": "break-word", "wordWrap": "break-word", "wordBreak": "break-word", "backgroundColor": "#FFFFFF"}}>
                             <div style={{"borderCollapse": "collapse", "display": "table", "width": "100%", "backgroundColor":"#FFFFFF"}}>
                             <table>
                                 <tr>
                                     <td>
                                        <div className="col num6" style={{"maxWidth": "320px",  "minWidth": "240px", "display": "table-cell", "verticalAlign": "top", "width": "240px"}}>
                                            <div style={{"width":"100% !important"}}>
                                                
                                                <div style={{"borderTop":"0px solid transparent", "borderLeft":"0px solid transparent", "borderBottom":"0px solid transparent", "borderRight":"0px solid transparent", "paddingTop":"55px", "paddingBottom":"55px", "paddingRight": "0px", "paddingLeft": "0px"}}>
                                                
                                                <div align="center" className="img-container center autowidth fullwidth" style={{"paddingRight": "0px", "paddingLeft": "0px"}}>
                                                    <img src={this.state.lenderImageUrl !== '' ? this.state.lenderImageUrl : `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/images%2Fusers%2Fface-placeholder.gif?alt=media&token=2adbb35c-806c-4cd3-b15a-5e306e5b824b`} align="center" alt="lender" border="0" className="center autowidth fullwidth" style={{"textDecoration": "none", "-msInterpolationMode": "bicubic", "border": "0", "height": "auto", "width":" 100%", "maxWidth": "194px", "display": "block"}} title="Image" width="194"/>
                                                </div>
                                                
                                                <div style={{"color":"#555555", "fontFamily":"Arial, 'Helvetica Neue', Helvetica, sans-serif", "lineHeight":"180%", "paddingTop":"10px", "paddingRight":"10px", "paddingBottom":"10px", "paddingLeft":"22px"}}>
                                                <div style={{"fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "fontSize": "12px", "lineHeight": "21px", "marginTop": "10px", "color": "#555555"}}>
                                                    <p style={{"fontSize": "14px", "lineHeight": "32px", "margin": "0"}}>
                                                    {this.state.logoUrl && this.state.logoUrl !== '' && <img src={this.state.logoUrl} height="100px" width="130px" alt="lender logo" style={{"paddingLeft": "30px"}}/>}
                                                    </p>
                                                </div>
                                                </div>
                                                            
                                                </div>
                                                
                                            </div>
                                        </div>
                                     </td>
                                     <td>
                                        <div className="col num6" style={{"maxWidth": "320px", "minWidth": "240px", "display": "table-cell", "verticalAlign": "top", "width": "240px"}}>
                                            <div style={{"backgroundColor":"#ffffff", "width":"100% !important"}}>
                                                <div style={{"borderTop":"0px solid transparent", "borderLeft":"0px solid transparent", "borderBottom":"0px solid transparent", "borderRight":"0px solid transparent", "paddingTop":"25px", "paddingBottom":"25px", "paddingRight": "5px", "paddingLeft": "5px"}}>
                                                
                                                            <div style={{"color":"#555555", "fontFamily":"Arial, 'Helvetica Neue', Helvetica, sans-serif", "lineHeight":"150%", "paddingTop":"10px", "paddingRight":"10px", "paddingBottom":"10px", "paddingLeft":"10px"}}>
                                                            <div style={{"fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "fontSize": "12px", "lineHeight": "18px", "color": "#555555"}}>
                                                                <div style={{"background": "#d9d9d9", "padding": "25px 10px"}}>
                                                                    <p style={{"fontSize": "14px", "lineHeight": "27px", "margin": "0"}}><span style={{"color": "#000000",  "fontSize": "21px"}}>You now have lender<br/>representation on<br/>your profile<br/><br/>With your qualification<br/>sellers can gain<br/>the confidence needed <br/>to trust the <br/>lending process <br/></span></p>
                                                                </div>
                                                            </div>
                                                </div>
                                                
                                                </div>
                                            </div>                  
                                        </div>
                                     </td>
                                 </tr>
                                 <tr > 
                                    <td colSpan={2}>   
                                     A lenders signature of approval shows how prepared a buyer is when the right home comes along.
                                    </td>
                                </tr>    
                             </table>                     
                         </div>
                          </div>
                          <div style={{"backgroundColor":"transparent"}}>
                             <div className="block-grid" style={{"Margin": "0 auto", "minWidth": "320px", "maxWidth": "490px", "overflowWrap": "break-word", "wordWrap": "break-word", "wordBreak": "break-word", "backgroundColor": "#F6F6F4"}}>
                                <div style={{"borderCollapse": "collapse", "display": "table", "width": "100%", "backgroundColor":"#F6F6F4"}}>
                                   
                                                              <div className="col num12" style={{"minWidth": "320px",  "maxWidth": "490px", "display": "table-cell", "verticalAlign": "top", "width": "490px"}}>
                                                                 <div style={{"width":"100% !important"}}>
                                                                    
                                                                    <div style={{"borderTop":"0px solid transparent", "borderLeft":"0px solid transparent", "borderBottom":"0px solid transparent", "borderRight":"0px solid transparent", "paddingTop":"5px", "paddingBottom":"5px", "paddingRight": "0px", "paddingLeft": "0px"}}>
                                                                       
                                                                                <div style={{"color":"#555555", "fontFamily":"Arial, 'Helvetica Neue', Helvetica, sans-serif", "lineHeight":"120%", "paddingTop":"22px", "paddingRight":"10px", "paddingBottom":"22px", "background": "#d9d9d9", "paddingLeft":"10px"}}>
                                                                                   <div style={{"fontFamily": "Arial, 'Helvetica Neue', Helvetica, sans-serif", "fontSize": "18px", "lineHeight": "14px", "color": "#555555"}}>
                                                                                      <p style={{"fontSize": "14px", "color": "#000000", "lineHeight": "22px", "fontWeight": "bold", "margin": "0"}}>
                                                                                        To unsubscribe from Lender Request emails, login to your
                                                                                        account and change the setting for Auto Notification.
                                                                                        </p>
                                                                                        <p style={{"fontSize": "14px", "color": "#000000", "lineHeight": "22px", "fontWeight": "bold", "margin":"11px 0"}}>
                                                                                        By receiving this email and/or utilizing the contents. you 
                                                                                        agree to our <a href={`${host}/terms_of_use`} style={{"color": "#103fd6", "textDecoration": "none"}}> Terms of Services.</a>


                                                                                      </p>
                                                                                   </div>
                                                                                </div>  
                                                                          </div>
                                                                    </div>
                                                                 </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </center>
                                    </body>
                            </html>
    }

    toggleRadio = (evt) => {
        const {formData} = this.state
        formData[evt.target.name] = evt.target.value
        this.setState({formData})
    }

    handleChangeChk = (evt) => {
        const {formData} = this.state
        formData[evt.target.name] = evt.target.checked
        this.setState({formData})
    }

    sendLendersFeedback = (e) => {
        e.preventDefault()
        const {formData, lenderLeadInfo} = this.state
        const db = firebase.firestore()
        let updateDataObject = {...lenderLeadInfo,
            has_lender_responded: true,
            lender_feedback: formData}
        db.collection('lenderleads').doc(this.props.match.params.id).update(updateDataObject)
        db.collection('buyers').doc(lenderLeadInfo.requestor_id).get().then(buyerRef => {
            const buyerData = buyerRef.data()
            let updatedBuyerInfo = {...buyerData,
                                    lender_feedback: formData,
                                    lender_company: lenderLeadInfo.lender_company,
                                    lender_name: lenderLeadInfo.lender_name,
                                }

                if(formData.requestor_qualified){
                    updatedBuyerInfo = {
                        ...updatedBuyerInfo,
                        lender_email:lenderLeadInfo.lender_email
                    };
                    const sendEmailToBuyer = firebase.functions().httpsCallable('sendEmailToBuyer');
                    
                    logEvent('lender_approval_confirmation_email', {
                        lenderName:lenderLeadInfo.lender_name,
                        lenderCompany:lenderLeadInfo.lender_company,
                        email: lenderLeadInfo.requestor_email
                    });
                    
                    const body = ReactDOMServer.renderToStaticMarkup(this.requestorsEmail(lenderLeadInfo));
                    sendEmailToBuyer({email: lenderLeadInfo.requestor_email, body}).then((result) => {
                        if(this.props.user){
                            this.props.history.push('/dashboard')
                        }else {
                            this.props.history.push('/login')
                        }
                        toast.success("Mail to requestor sent successfuly", {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    })
                }else {
                    if(this.props.user){
                        this.props.history.push('/dashboard')
                    }else {
                        this.props.history.push('/login')
                    }
                }
            db.collection('buyers').doc(lenderLeadInfo.requestor_id).update(updatedBuyerInfo)

        })
    }
    
    render() {
        
        return (
            <React.Fragment>
                <div className="container py-5">
                    <h4>Lender approval form</h4>
                    <form onSubmit={this.sendLendersFeedback} className="LendersFeedback pad-20">
                    <h6>Have you received a signed application</h6>
                    <div className="pl-2 mb-3">
                        <label>
                            <input type="radio" name="signed_application" value={'yes'} onChange={(e) => this.toggleRadio(e)} required/> Yes 
                        </label>
                        <label>
                            <input type="radio" name="signed_application" value={'no'} onChange={(e) => this.toggleRadio(e)} /> No 
                        </label>
                    </div>
                    
                    <h6>The Prospective Applicant has provided the following information </h6>
                    <div className="pl-2 mb-3">
                        <label>
                            <input type="radio" name="application_format" value={'verbally'} onChange={(e) => this.toggleRadio(e)} required/> Verbally 
                        </label> 
                        <label>
                            <input type="radio" name="application_format" value={'inwriting'} onChange={(e) => this.toggleRadio(e)} /> Writting
                        </label>
                    </div>
                    <h6>Check All that Apply. </h6>
                    <div className="pl-2 mb-3">
                        <label>
                            <input type="checkbox" defaultChecked={this.state.chkbox} name='credit_reviwed' onChange={(e) => this.handleChangeChk(e)} />reviewed credit
                        </label><br />
                        <label>
                            <input type="checkbox" defaultChecked={this.state.chkbox} name='income_reviwed' onChange={(e) => this.handleChangeChk(e)} />reviewed income
                        </label><br />
                        <label>
                            <input type="checkbox" defaultChecked={this.state.chkbox} name='docs_reviewed' onChange={(e) => this.handleChangeChk(e)} />reviewed financial documents and cash to close
                        </label><br />
                        <label>
                            <input type="checkbox" defaultChecked={this.state.chkbox} name='debts_reviewed' onChange={(e) => this.handleChangeChk(e)} />reviewed debts
                        </label><br />
                        <label>
                            <input type="checkbox" defaultChecked={this.state.chkbox} name='assets_reviewed' onChange={(e) => this.handleChangeChk(e)} />reviewed assets
                        </label><br />
                        <label>
                            <input type="checkbox" name='requestor_qualified' onChange={(e) => this.handleChangeChk(e)} />
                            Based on the information that was provided, it is determined that the Prospective Applicant is eligible and qualified to meet the financial requirements of the loan.
                        </label>
                    </div>
                    <button type='submit' className="btn btn-md button-theme"> Send Feedback </button>
                        </form>
                    </div>
         </React.Fragment>
        )
    }
}

export default LenderApprovalForm
