import React, { Component } from "react";
import { connect } from "react-redux";
import ContactsSidePanel from "./ContactsSidePanel";
import ChatView from "./ChatView";

class Messenger extends Component {
  
  render() {
    return (
      <div className="container-fluid">
        <div id="frame" className="row">
          <ContactsSidePanel {...this.props} />
          <ChatView {...this.props} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ MessengerReducer }) => {
  return {
    ...MessengerReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messenger);
