import moment from "moment";
import firebase from "firebase/app";
import "firebase/firestore";
import _ from "underscore";
import { geohashQueryBounds, distanceBetween } from "geofire-common";

export const getUsersSellers = (user, opts) => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    const { uid, email, show_on_share } = user;
    const { allData, location, radius } = opts || {};
    let query = db.collection("sellers").where("removed", "==", false);

    let allBrockerEmails = null;
    let allBrockerUID = null;
    if (allData && user.broker_license) {
      const resp = await db
        .collection("realtors")
        .where("broker_license", "==", user.broker_license)
        .get();
      allBrockerEmails = resp.docs.map((u) => u.data().realtor_email);
      allBrockerUID = resp.docs.map((u) => u.id);
    }

    if (opts) {
      if (opts.status && opts.status.toLowerCase() !== "select status")
        query = query.where("MlsStatus", "==", opts.status);
      if (opts.property && opts.property.toLowerCase() !== "select property")
        query = query.where("property_type", "==", opts.property);
      if (opts.date) query = query.where("createdAt_unix", ">=", opts.date);
    }
    if (show_on_share) query = query.where("show_on_share", "==", true);

    let queries = [];
    if (allData && user.broker_license) {
      for (let i = 0; i <= allBrockerEmails.length; i += 10) {
        queries = [
          query.where("seller_email", "in", allBrockerEmails.slice(i, i + 9)),
          query.where("represendtedBy", "in", allBrockerEmails.slice(i, i + 9)),
          query.where("lender_email", "in", allBrockerEmails.slice(i, i + 9)),
          query.where("owner", "in", allBrockerUID.slice(i, i + 9)),
          query.where("seller_id", "in", allBrockerUID.slice(i, i + 9)),
          //query.orderBy("createdAt_unix", "desc")
        ];
      }
    } else {
      queries = [
        query.where("seller_email", "==", email),
        query.where("represendtedBy", "==", email),
        query.where("lender_email", "==", email),
        query.where("owner", "==", uid),
        query.where("seller_id", "==", uid),
        //query.orderBy("createdAt_unix", "desc")
      ];
    }

    let updateQueries = [];
    if (location) {
      const bounds = geohashQueryBounds(
        [location.lat, location.lng],
        (radius || 1) * 1000
      );

      for (const b of bounds) {
        queries.map((q) => {
          const uq = q.orderBy("geohash").startAt(b[0]).endAt(b[1]);

          updateQueries.push(uq);
        });
      }
    }

    const promises = (updateQueries.length ? updateQueries : queries).map((q) =>
      q.get()
    );
    Promise.all(promises)
      .then((snapshots) => {
        const docSets = snapshots.map((s) => s.docs);
        let docs = [];
        for (const docSet of docSets) {
          for (const doc of docSet) {
            if (!docs.some((d) => d.id === doc.id)) {
              const docData = doc.data();

              if (location && location.lat && location.lng) {
                const distanceInKm = distanceBetween(
                  [docData.Latitude || 0, docData.Longitude || 0],
                  [location.lat || 0, location.lng || 0]
                );

                if (distanceInKm <= radius) docs.push(doc);
              } else {
                docs.push(doc);
              }
            }
          }
        }
        resolve(docs);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSellersById = async (ids) => {
  return new Promise((resolve, reject) => {
    const db = firebase.firestore();
    let query = db.collection("sellers");
    let queries = [];
    ids.forEach((id) => {
      queries.push(
        query.where(firebase.firestore.FieldPath.documentId(), "==", id)
      );
    });

    const promises = queries.map((q) => q.get());
    Promise.all(promises)
      .then((snapshots) => {
        const docSets = snapshots.map((s) => s.docs);
        let docs = [];
        for (const docSet of docSets) {
          for (const doc of docSet) {
            if (!docs.some((d) => d.id === doc.id)) {
              docs.push(doc);
            }
          }
        }
        resolve(docs);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getOffersByAgent = async (
  agentEmail,
  agentLicenseNo,
  realtor_license
) => {
  try {
    const db = firebase.firestore();
    let query = db.collection("offers");
    const promises = [];

    if (agentEmail) {
      promises.push(query.where("email", "==", agentEmail).get());
    }

    if (agentLicenseNo) {
      promises.push(
        query.where("licenseNo", "==", realtor_license || agentLicenseNo).get()
      );
    }

    const snapshots = await Promise.all(promises);

    let docs = [];
    snapshots.forEach((s) => {
      s.docs.forEach((doc) => {
        if (!docs.some((d) => d.id === doc.id)) {
          docs.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
    });

    return docs;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getOffers = (user, search_id = null, options = {}) => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    const { email } = user;
    const { allData } = options || {};

    let allBrockerEmails = null;
    if (allData && user.broker_license) {
      const resp = await db
        .collection("realtors")
        .where("broker_license", "==", user.broker_license)
        .get();
      allBrockerEmails = resp.docs.map((u) => u.data().realtor_email);
    }

    let query = db.collection("offers");
    if (!!options.status && options.status !== "Select status")
      query = query.where("status", "==", options.status);
    if (!!options.financeType && options.financeType !== "Select type")
      query = query.where("financingType", "==", options.financeType);
    if (options.date) query = query.where("createdAt", ">=", options.date);
    if (options.endDate)
      query = query.where("createdAt", "<=", options.endDate);
    if (
      !!options.propertyAddress &&
      options.propertyAddress !== "Select property"
    )
      query = query.where("address", "==", options.propertyAddress);
    if (!!options.agentName && options.agentName !== "Select agent")
      query = query.where("agentName", "==", options.agentName);
    if (!!options.sellerId)
      query = query.where("seller", "==", options.sellerId);

    let queries = null;
    if (allData && user.broker_license) {
      for (let i = 0; i <= allBrockerEmails.length; i += 10) {
        queries = [
          query.where("sellerEmail", "in", allBrockerEmails.slice(i, i + 9)),
          query.where("buyerEmail", "in", allBrockerEmails.slice(i, i + 9)),
          query.where("email", "in", allBrockerEmails.slice(i, i + 9)),
          // query.orderBy("createdAt", "desc")
        ];
      }
    } else {
      queries = [
        query.where("sellerEmail", "==", email),
        query.where("buyerEmail", "==", email),
        query.where("email", "==", email),
        // query.orderBy("createdAt", "desc")
      ];
    }

    const promises = queries.map((q) => q.get());

    Promise.all(promises)
      .then((snapshots) => {
        const docSets = snapshots.map((s) => s.docs);
        let docs = [];
        for (const docSet of docSets) {
          for (const doc of docSet) {
            if (!docs.some((d) => d.id === doc.id)) {
              docs.push(doc);
            }
          }
        }

        docs = docs.map((o) => {
          const data = {
            id: o.id,
            ...o.data(),
          };

          if (data.deletedFromList) return null;

          return data;
        });
        docs = docs.filter((doc) => doc !== null);
        if (options.purchasePrice && options.purchasePrice !== "Highest")
          docs = _.sortBy(docs, "purchasePrice");
        else {
          docs = _.sortBy(docs, "purchasePrice");
          docs.reverse();
        }
        resolve(docs);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllOffers = (user, search_id = null, options = {}) => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();

    const { email } = user;

    let query = db.collection("offers");
    if (!!email) query = query.where("sellerEmail", "!=", null);

    const promises = [query.get()];

    Promise.all(promises)
      .then((snapshots) => {
        const docSets = snapshots.map((s) => s.docs);
        let docs = [];
        for (const docSet of docSets) {
          for (const doc of docSet) {
            if (!docs.some((d) => d.id === doc.id)) {
              docs.push(doc);
            }
          }
        }

        docs = docs.map((o) => {
          const data = {
            id: o.id,
            ...o.data(),
          };
          if (options.getDeleted && !options.getDeleted) {
            if (data.deletedFromList) return null;
          }

          return data;
        });
        docs = docs.filter((doc) => doc !== null);
        if (options.purchasePrice && options.purchasePrice !== "Highest")
          docs = _.sortBy(docs, "purchasePrice");
        else {
          docs = _.sortBy(docs, "purchasePrice");
          docs.reverse();
        }
        resolve(docs);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDashboardCounts = (user, period) => {
  let creataedAfter = 1;
  if (period === "Last Week") creataedAfter = 7;
  if (period === "Last Month") creataedAfter = 30;
  if (period === "6 Months") creataedAfter = 180;
  if (period === "1 Year") creataedAfter = 365;

  const filterDate = moment().subtract(creataedAfter, "d").unix();

  return new Promise((resolve, reject) => {
    const db = firebase.firestore();

    Promise.all([
      getUsersSellers(user, {
        status: "Active",
      }),
      getOffers(user, {
        date: filterDate,
      }),
    ])
      .then((snapshots) => {
        const sellersCount = snapshots[0].length;
        const offersCount = snapshots[1].length;
        let offersAccepted = 0;
        let offersSubmitted = 0;
        let offersDeclined = 0;
        let offersWithdrawn = 0;

        snapshots[1].forEach((offer) => {
          if (offer.status === "Executed" || offer.status === "Accepted") {
            offersAccepted++;
          }
          if (offer.buyerEmail === user.email) offersSubmitted++;
          if (offer.status === "Not Accepted") offersDeclined++;
          if (offer.status === "Withdraw") offersWithdrawn++;
        });

        resolve({
          homesListed: sellersCount,
          offersReceived: offersCount,
          offersAccepted,
          offersSubmitted,
          offersDeclined,
          offersWithdrawn,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const bestMatchOffers = (offers, sellers) => {
  if (!sellers || !offers) return {};

  let offersArray = [];
  for (const seller of sellers) {
    offersArray = Object.entries(offers)
      .map((offer) => (offer[1].seller == seller.id ? offer[1] : null))
      .filter((o) => o);
    if (seller.bm_pref_1 && seller.bm_pref_1 === "Purchase Price") {
      offersArray = purchasePriceSorting(offersArray, seller.bm_purchase_price);

      if (seller.bm_pref_1 && seller.bm_pref_2 === "Closing Date") {
        offersArray = closingDateSorting(offersArray);
        offersArray = financeTypeSorting(offersArray);
      } else if (seller.bm_pref_1 && seller.bm_pref_3 === "Finance Type") {
        offersArray = financeTypeSorting(offersArray);
        offersArray = closingDateSorting(offersArray);
      }
    }

    if (seller.bm_pref_1 && seller.bm_pref_1 === "Closing Date") {
      offersArray = closingDateSorting(offersArray);
      offersArray = purchasePriceSorting(offersArray, seller.bm_purchase_price);

      if (seller.bm_pref_1 && seller.bm_pref_2 === "Purchase Price") {
        offersArray = financeTypeSorting(offersArray);
      } else if (seller.bm_pref_1 && seller.bm_pref_3 === "Finance Type") {
        offersArray = financeTypeSorting(offersArray);
        offersArray = closingDateSorting(offersArray);
      }
    }

    if (seller.bm_pref_1 && seller.bm_pref_1 === "Purchase Price") {
      offersArray = purchasePriceSorting(offersArray, seller.bm_purchase_price);

      if (seller.bm_pref_1 && seller.bm_pref_2 === "Closing Date") {
        offersArray = closingDateSorting(offersArray);
        offersArray = financeTypeSorting(offersArray);
      } else if (seller.bm_pref_1 && seller.bm_pref_3 === "Finance Type") {
        offersArray = financeTypeSorting(offersArray);
        offersArray = closingDateSorting(offersArray);
      }
    }

    return offersArray;
  }
};

const purchasePriceSorting = (offersArray, bm_purchase_price = null) => {
  offersArray = _.sortBy(offersArray, "purchasePrice").reverse();
  if (bm_purchase_price)
    return offersArray.filter(
      (offer) =>
        offer.purchasePrice === offersArray[0].purchasePrice &&
        offer.purchasePrice >= bm_purchase_price - 10000
    );
  else
    return offersArray.filter(
      (offer) => offer.purchasePrice === offersArray[0].purchasePrice
    );
};

const closingDateSorting = (offersArray, bm_closing_date = null) => {
  offersArray.sort(function compare(a, b) {
    const dateA = new Date(a.closingDate);
    const dateB = new Date(b.closingDate);
    return dateA - dateB;
  });

  return offersArray.filter((offer) => {
    if (bm_closing_date)
      return (
        offer.closingDate === offersArray[0].closingDate &&
        moment(offer.closingDate) >=
          moment(bm_closing_date * 1000).subtract(3, "days") &&
        moment(offer.closingDate) <=
          moment(bm_closing_date * 1000).add(3, "days")
      );
    else return offer.closingDate === offersArray[0].closingDate;
  });
};

const financeTypeSorting = (offersArray) => {
  return offersArray.filter(
    (offer) => offer.financingType === offersArray[0].financingType
  );
};
