import React, { Component } from "react";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
class AudioRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioURL: null,
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0
        }
      }
    };
  }

  handleAudioStop = (data) => {
    this.setState({ audioDetails: data });
  }

  handleAudioUpload = (file) => {
    this.props.getAudioSource(this.state.audioDetails, file);
    this.props.modalToggle();
  }

  handleReset = () => {
    let reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0
      }
    };
    this.setState({
      audioDetails: reset
    })
  }

  render() {
    return (
      <div className="App">
        <Recorder
          record={true}
          audioURL={this.state.audioDetails.url}
          showUIAudio
          handleAudioStop={(data) => this.handleAudioStop(data)}
          handleAudioUpload={(data) => this.handleAudioUpload(data)}
          handleReset={this.handleReset}
        />
      </div>
    );
  }
}
export default AudioRecord;