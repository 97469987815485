import React from 'react';

const InfoCard = ({ infoName, infoValue, infoMini }) => {
  return (
    <div
    className='responsive-height'
      style={{
        width: '100%',
        padding: '20px 20px 10px 20px',
        border: '1px solid #EBEBEB',
        borderRadius: '4px',
        backgroundColor: '#fff',
        minHeight: '82px',
      }}
    >
      <span
        style={{
          fontWeight: '500',
          fontSize: '12px !important',
          lineHeight: '14px',
          color: '#727272',
          display: 'block',
          fontSize: '12px',
        }}
      >
        {infoName}
      </span>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <h4
          style={{
            color: 'rgba(34, 34, 34, 1)',
            fontFamily: 'Poppins',
            fontSize: '24px',
            fontWeight: 600,
            marginTop: '10px',
          }}
        >
          {infoValue}
        </h4>

        {infoMini ? (
          <span
            style={{
              color: '#109CF1',
              fontSize: '10px',
              fontWeight: 500,
              fontFamily: 'Poppins',
              backgroundColor: 'rgba(16, 156, 241, 0.1)',
              border: '1px solid rgba(16, 156, 241, 0.2)',
              borderRadius: '2px',
              padding: '0 4px ',
            }}
          >
            {infoMini} verified ({((infoMini / infoValue) * 100).toFixed(2)}%)
          </span>
        ) : <></>}
      </div>
    </div>
  );
};

export default InfoCard;
