import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { getOffers, getUsersSellers } from "Services/SellerService";
import moment from "moment";

import styles from "./style.module.css";
import { set } from "lodash";
import firebase from "firebase/app";
import ArrowDown from "assets/icons/arrowDownBlack.svg";

const LineChart = ({ offers, allData, user }) => {
  const canvasChart = useRef(null);

  const current = moment().endOf("day").unix();

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [totalListingDaysFilter, setTotalListingDaysFilter] = useState({});
  const [propertyFilter, setPropertyFilter] = useState("Select property");
  const [propertyFilterArr, setPropertyFilterArr] = useState([]);
  const [filteredSellers, setFilteredSellers] = useState([]);
  const [allSellers, setAllSellers] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [views, setViews] = useState([]);
  const [processedViews, setProcessedViews] = useState([]);

  const totalListingDaysFilterOptions = [
    {
      label: "Last 7 days",
      startDate: moment().subtract(7, "days").startOf("day").unix(),
      endDate: current,
    },
    {
      label: "Last 30 days",
      startDate: moment().subtract(30, "days").startOf("day").unix(),
      endDate: current,
    },
    {
      label: "Last 60 days",
      startDate: moment().subtract(60, "days").startOf("day").unix(),
      endDate: current,
    },
    {
      label: "Last 90 days",
      startDate: moment().subtract(90, "days").startOf("day").unix(),
      endDate: current,
    },
  ];

  const [offerSellers, setOfferSellers] = useState({});

  const formatDate = (dateString) => {
    const dateParts = dateString.split(" ");
    const day = parseInt(dateParts[0], 10);
    const month = dateParts[1];
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const monthIndex = currentDate.toLocaleString("default", { month: "long" });
    const formattedDate = new Date(year, monthIndex.indexOf(month), day);
    const formattedDateString = formattedDate.toLocaleString("default", {
      day: "numeric",
      month: "long",
    });

    return `${formattedDateString.split(" ")[1]} of ${
      formattedDateString.split(" ")[0]
    }`;
  };

  const getFilteredViews = (start, end) => {
    return views.filter((view) => view >= start && view <= end);
  };

  const data = {
    labels: Object.keys(processedViews),
    datasets: [
      {
        label: "",
        data: Object.values(processedViews),
        fill: true,
        responsive: true,

        opacity: "0",
        borderColor: "#109CF1",
        backgroundColor: (context) => {
          const { chart } = context;
          const { ctx } = chart;
          const gradient = ctx.createLinearGradient(0, 100, 0, 300);
          gradient.addColorStop(0, "#7bd4fa");
          gradient.addColorStop(1, "#fff");
          return gradient;
        },
        hoverRadius: 8,
        hoverBackgroundColor: "#ffffff",
        hoverBorderColor: "#109CF1",
        hoverBorderWidth: 4,
      },
    ],
  };

  const options = {
    onHover: (_, event) => {
      if (event?.length) {
        drawCustomLabels(canvasChart.current.chartInstance, event);
      }
    },
    tooltips: {
      enabled: true,
      backgroundColor: "#646464",
      titleAlign: "center",
      bodyAlign: "center",
      borderDash: 5,
      cornerRadius: 4,
      xPadding: 18,
      yPadding: 8,
      mode: "x",
      callbacks: {
        title: (e) => `${e[0].value} Views,`,
        // beforeLabel: (e) => formatDate(e.label),
        label: () => "",
      },
    },
    layout: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [4, 4],
            lineWidth: 2,
            zeroLineColor: "rgba(235, 235, 235, 1)",
            zeroLineWidth: 2,
            zeroLineBorderDash: [0, 2],
            zeroLineBorderDashOffset: 2,
            zeroLineColor: "#ffffff",
            drawBorder: false,
            z: 1,
          },
          ticks: {
            padding: 20,
            crossAlign: "start",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            zeroLineColor: "rgba(235, 235, 235, 1)",
            zeroLineWidth: 2,
            zeroLineBorderDash: [0, 2],
            zeroLineBorderDashOffset: 2,
            zeroLineColor: "#ffffff",
          },
        },
      ],
    },
  };

  const drawCustomLabels = (chart, event) => {
    setTimeout(() => {
      const ctx = chart.ctx;
      ctx.closePath();
      ctx.stroke();
      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([0, 0]);
      const labelX = event[0]._view.x;
      const labelY = event[0]._view.y;
      ctx.moveTo(labelX, labelY);
      ctx.lineTo(labelX, chart.height - 30);
      ctx.strokeStyle = "#b4c8e2";
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    }, 400);
  };

  if (isFirstRender) {
    if ((offers || []).length !== 0) {
      let filter = offers[0].seller;
      setIsFirstRender(false);
      setPropertyFilter(filter);
      setPropertyFilterArr([filter]);
    }
  }

  const filterSellers = () => {
    const filteredSellers = Object.keys(offerSellers).filter((seller) => {
      return seller.seller === propertyFilter;
    });
    setFilteredSellers(filteredSellers);
  };

  const handleAddressChangeFilter = (e) => {
    const value = e.target.value;
    const filtered = [];
    if (value === "") {
      setFilteredSellers(allSellers);
      return;
    }
    allSellers.forEach((seller) => {
      if (
        seller.UnparsedAddress.toLowerCase().includes(value.toLowerCase()) ||
        seller.street_number.toLowerCase().includes(value.toLowerCase()) ||
        seller.street_name.toLowerCase().includes(value.toLowerCase()) ||
        seller.city.toLowerCase().includes(value.toLowerCase()) ||
        seller.state.toLowerCase().includes(value.toLowerCase()) ||
        seller.zip_code.toLowerCase().includes(value.toLowerCase())
      ) {
        filtered.push(seller);
      }
    });
    setFilteredSellers(filtered);
  };

  useEffect(() => {
    // const sellers = {};
    // offers.forEach((offer) => {
    //   sellers[offer.seller] = offer;
    //   console.log(sellers, "sellers");
    //   setOfferSellers(sellers);
    // });

    // filterSellers();
    // const getSellers = async () => {
    //   const db = firebase.firestore();

    //   const sellerRefByEmail = db
    //     .collection("sellers")
    //     .where("owner", "==", user.email);
    //   const sellerRefByUid = db
    //     .collection("sellers")
    //     .where("owner", "==", user.uid);

    //   try {
    //     const [snapshotByEmail, snapshotByUid] = await Promise.all([
    //       sellerRefByEmail.get(),
    //       sellerRefByUid.get(),
    //     ]);

    //     const allSellers = [];
    //     const filteredSellers = [];

    //     snapshotByEmail.forEach((doc) => {
    //       allSellers.push(doc.data());
    //       filteredSellers.push(doc.data());
    //     });

    //     snapshotByUid.forEach((doc) => {
    //       if (
    //         !allSellers.some((seller) => seller.ListingKey === doc.ListingKey)
    //       ) {
    //         allSellers.push(doc.data());
    //         filteredSellers.push(doc.data());
    //       }
    //     });

    //     // Now you have the combined data in 'allSellers' and 'filteredSellers'.
    //     // You can use them as needed.

    //     // Assuming you have state variables like this:
    //     // const [allSellers, setAllSellers] = useState([]);
    //     // const [filteredSellers, setFilteredSellers] = useState([]);

    //     setAllSellers(allSellers);
    //     setFilteredSellers(filteredSellers);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };

    const getSellers = async () => {
      const db = firebase.firestore();

      const sellerRef = db
        .collection("sellers")
        .where("owner", "==", user.email);
      const snapshot = await sellerRef.get();
      if (snapshot.empty) {
        console.log("No matching documents.");
        return;
      }

      snapshot.forEach((doc) => {
        setAllSellers((prev) => [...prev, doc.data()]);
        setFilteredSellers((prev) => [...prev, doc.data()]);
      });
    };
    getSellers();
  }, []);

  useEffect(() => {
    const viewsResult = getFilteredViews(
      totalListingDaysFilter.startDate,
      totalListingDaysFilter.endDate
    );
    const processedViews = {};
    viewsResult.forEach((view) => {
      const date = moment.unix(view).format("D MMM");
      if (processedViews[date]) {
        processedViews[date] = processedViews[date] + 1;
      } else {
        processedViews[date] = 1;
      }
    });
    setProcessedViews(processedViews);
  }, [totalListingDaysFilter, selectedSeller, views]);

  return (
    <div
      className={`${styles.container}`}
      style={{
        height: "100%",
      }}
    >
      <h3
        style={{
          fontWeight: " 600",
          fontSize: " 16px",
          color: "#222222",
          marginLeft: "6px",
          marginBottom: "0",
        }}
      >
        Property Listing Engagement
      </h3>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* <UncontrolledButtonDropdown
          direction="down"
          background="white"
          className={styles.dropdown}
        >
          <DropdownToggle caret color="light">
            {selectedSeller
              ? selectedSeller.UnparsedAddress.substring(0, 25) + "..."
              : "Select a property"}
            <img
              src="/images/arrow-dropdown.svg"
              style={{
                filter: "brightness(0%)",
                width: "8px",
              }}
            />
          </DropdownToggle> */}
        <div>
          <button
            className="analytics-price-dropdown-btn"
            type="button"
            id="offerStatusFilter"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div className="analytics-row center-me">
              <p className="analytics-property-address-label">
                {selectedSeller
                  ? selectedSeller.UnparsedAddress
                    ? selectedSeller.UnparsedAddress.substring(0, 25) + "..."
                    : `${selectedSeller.street_number},${selectedSeller.street_name},${selectedSeller.city},${selectedSeller.state}`.substring(
                        0,
                        25
                      ) + "..."
                  : "Select a property"}
              </p>
              <img alt="ArrowDown" src={ArrowDown} className="down-arrow" />
            </div>
          </button>
          <div
            className="custom-address-options-list dropdown-menu"
            aria-labelledby="listingPriceFilter"
          >
            <div className="analtics-space-between padd-6">
              <input
                type="text"
                className="analytics-search-input"
                placeholder="Search property"
                onChange={(e) => handleAddressChangeFilter(e)}
              />
            </div>
            {filteredSellers.length ? (
              filteredSellers.map((seller, i) => {
                return (
                  <button
                    className={`analytics-price-dropdown-inner-btn ${
                      selectedSeller == seller ? "active-bg" : ""
                    }`}
                    key={i}
                    // value={seller}
                    onClick={async (e) => {
                      e.preventDefault();
                      setSelectedSeller(seller);
                      const db = firebase.firestore();
                      const doc = await db
                        .collection("meta")
                        .doc(seller.ListingKey)
                        .get();
                      if (doc.exists) {
                        const { views } = doc.data();
                        return setViews(views);
                      }
                      setViews([]);
                    }}
                  >
                    <p>{`${seller.street_number} ${seller.street_name}, ${seller.city}, ${seller.state}, ${seller.zip_code}`}</p>
                  </button>
                );
              })
            ) : (
              <button
                className="
              analytics-price-dropdown-inner-btn
              "
              >
                <p>No Property Found</p>
              </button>
            )}
          </div>
        </div>
        {/* <DropdownMenu>
            {allSellers?.map((item, index) => (
              <DropdownItem
                key={index}
                // active={selectedSeller?.UnparsedAddress === item.UnparsedAddress}
                onClick={async (e) => {
                  e.preventDefault();
                  setSelectedSeller(item);
                  const db = firebase.firestore();
                  const doc = await db
                    .collection("meta")
                    .doc(item.ListingKey)
                    .get();
                  if (doc.exists) {
                    const { views } = doc.data();
                    console.log(doc.data(), "doc.data()");
                    return setViews(views);
                  }
                  setViews([]);
                }}
              >{`${item.street_number} ${item.street_name}, ${item.city}, ${item.state}, ${item.zip_code}`}</DropdownItem>
            ))}
          </DropdownMenu> */}
        {/* </UncontrolledButtonDropdown> */}
        <UncontrolledButtonDropdown
          direction="down"
          background="white"
          className={styles.dropdown}
        >
          <DropdownToggle caret color="light">
            {totalListingDaysFilter.label || "Total Listing Days"}
            <img
              src="/images/arrow-dropdown.svg"
              style={{
                filter: "brightness(0%)",
                width: "8px",
              }}
            />
          </DropdownToggle>
          <DropdownMenu>
            {totalListingDaysFilterOptions.map((item, index) => (
              <DropdownItem
                key={index}
                onClick={() => setTotalListingDaysFilter(item)}
              >
                {item.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
      <div
        style={{
          color: "#4C5862",
          fontWeight: "400",
          fontSize: "12px",
          marginLeft: "6px",
        }}
      >
        Views
      </div>
      <div style={{ width: "100%", overflowX: "auto", paddingBottom: "100px" }}>
        <div style={{ width: "3000px", height: 300, cursor: "pointer" }}>
          <Line
            height={300}
            width={0}
            data={data}
            ref={canvasChart}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default LineChart;
