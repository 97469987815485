'use strict'

const TinyURL = require('tinyurl');

exports.shortURL = (url) => {
  return new Promise((resolve, reject) => {
    TinyURL.shorten(url, function(res, err) {
      if (err) return reject(err)
      return resolve(res)
    });
  })
}
