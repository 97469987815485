import React, { Component } from "react";
import firebase from "firebase/app";
import moment from "moment";
import { shortURL } from "../helpers/url-shortner";
import "firebase/firestore";
import { saveActivity } from "../Services/Activity";

class OfferSubmitComment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      dateTime: "",
      commentText: "",
      savingComment: false,
    };
  }

  saveComment = async () => {
    if (!this.state.commentText || this.state.savingComment) return;
    const { user, offer, sellerData } = this.props;
    let offerId = this.props.offerId;
    let name =
      this.props.name ||
      user.full_name ||
      user.realtor_name ||
      user.companyName;
    let comment = {
      text: this.state.commentText,
      name,
      commentedAt: new Date().getTime(),
    };

    this.setState({
      savingComment: true,
    });

    firebase
      .database()
      .ref(`/OfferComments/${offerId}`)
      .push(comment, (err) => {
        if (!err) this.setState({ commentText: "", savingComment: false });
        else
          alert(
            "Unexpected error occurred while saving comment. Please try again later."
          );
      });
    const db = firebase.firestore();
    await db.collection("notifications").add({
      user_id: offer.buyer,
      property_id: offer.id,
      text: `Comment has been posted`,
      type: `offer`,
      isRead: 0,
      createdAt: moment().utc().unix(),
    });
    saveActivity({
      text: `A new comment has been posted.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid,
    });

    if (!offer.phoneNumber || !sellerData.seller_phone) return;
    try {
      console.log(
        "Sending SMS",
        `${
          sellerData.seller_id == user.uid
            ? offer.phoneNumber
            : sellerData.seller_phone
        }`
      );
      const sendSMS = firebase.functions().httpsCallable("sendSMS");
      await sendSMS({
        to: `+1${
          sellerData.seller_id === user.uid
            ? offer.phoneNumber
            : sellerData.seller_phone
        }`,
        body: `You've got a new comment on your offer, at ${moment().format(
          "YYYY-MM-DD hh:mmA"
        )}, ${this.props.user.full_name || this.props.user.realtor_name}, ${
          offer.address
        }
Details: https://homematchx.com/offers/${offer.id}`,
      });
    } catch (err) {
      console.log("Comments Send SMS Error: ", err);
    }
  };

  //      Details: ${await shortURL(`https://homematchx.com/offers/${offer.id}`)}`,

  render() {
    const { savingComment } = this.state;

    return (
      <div className="d-flex comment-container">
        <textarea
          rows={2}
          className="form-control flex-fill mr-2 mt-8"
          type="text"
          name=""
          placeholder="Comment"
          onChange={(e) => {
            const { target } = e;
            this.setState({
              commentText: target.value,
            });
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") this.saveComment();
          }}
          value={this.state.commentText}
        />
        <button
          className="upload-btn mt-8 mb-auto"
          onClick={this.saveComment}
          disabled={savingComment ? true : false}
        >
          Comment
        </button>
      </div>
    );
  }
}

export default OfferSubmitComment;
