import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import TimeAgo from "react-timeago";
import { saveActivity } from "../Services/Activity";

class OfferComments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      dateTime: "",
      commentText: "",
      comments: [
        {
          text: this.props.offerComment,
        },
      ],
      isLoading: true,
      savingComment: false,
    };
  }

  componentDidMount = async () => {
    let offerId = this.props.offerId;

    let ref = firebase.database().ref(`/OfferComments/${offerId}`);
    ref.on("value", (snap) => {
      let comments = [];
      snap.forEach((s) => {
        comments.push(s.val());
      });
      comments.reverse();
      this.setState({
        comments,
        isLoading: false,
      });
    });
  };

  saveComment = async () => {
    if (!this.state.commentText || this.state.savingComment) return;
    const { user } = this.props;
    let offerId = this.props.offerId;
    let name = this.props.name || user.realtor_name;
    let comment = {
      text: this.state.commentText,
      name,
      commentedAt: new Date().getTime(),
    };

    this.setState({
      savingComment: true,
    });
    firebase
      .database()
      .ref(`/OfferComments/${offerId}`)
      .push(comment, (err) => {
        if (!err) this.setState({ commentText: "", savingComment: false });
        else
          alert(
            "Unexpected error occurred while saving comment. Please try again later."
          );
      });

    saveActivity({
      text: `A new comment has been posted.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid,
    });
  };

  render() {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const { isLoading, comments } = this.state;
    const { name } = this.props;
    const height = comments.length * 47 < 188 ? comments.length * 47 : 188;
    let ListComments = [];

    comments.forEach((c, i) => {
      const commentParts = c?.text?.split(urlRegex);

      const commentWithLinks = commentParts?.map((part, index) => {
        if (part.match(urlRegex)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </a>
          );
        } else {
          // If the part is plain text, return it as-is
          return <span key={index}>{part}</span>;
        }
      });
      ListComments.push(
        <li key={i}>
          <div className="comment-div d-flex">
            <div className="profile-img-user">
              <a>
                <img src="/images/face-placeholder.png" alt="" />
              </a>
            </div>
            <div className="comment-user-name-dark">
              <h5 style={c.name === name ? { color: "#ff214f" } : null}>
                {c.name} - <TimeAgo date={c.commentedAt} />
              </h5>
              <p>{commentWithLinks}</p>
            </div>
          </div>
        </li>
      );
    });
    let renderComments = isLoading ? (
      <>Loading Comments...</>
    ) : (
      <>
        {comments.length > 0 ? (
          ListComments
        ) : (
          <>No comment has been added yet.</>
        )}
      </>
    );

    return (
      <ul style={{ maxHeight: "250px" }}>
        {renderComments}
        {this.props.children}
      </ul>
    );
  }
}

export default OfferComments;
