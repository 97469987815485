import React, {Component} from 'react';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/analytics';

import EditBlogPost from './EditBlogPost';

import DeleteModal from './../Common/Modals/DeleteModal';

import {deleteBlogPost, getBlogPostsById} from './../../Store/Actions/BlogActions'

import {
  Card,
  CardBody,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  Form
} from "reactstrap";

class BlogPosts extends Component{


  state = {
    posts: [],
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  };


  componentWillMount = async () => {
    const { resourceId } = this.props;
    
    const db = firebase.firestore();
    db.collection('blogMessage').where("resourceId", "==", resourceId).onSnapshot((snap) => {
      const docs = (snap.docs || []).map(doc => {
        return {
          id: doc.id,
          ...(doc.data())
        }
      });
      this.setState({posts:docs});
    });
    
  }



  onDelete = (id) => {
    const { deleteBlogPost } = this.props;
    deleteBlogPost({
      id,
      onSuccess: () => toast.success('Post deleted Successfully'),
      onError: toast.error,
    });
  }

  renderPublishedCell = (props) => {
    return (
      <div className="text-center">
        {
          props.original.isDraft ?
            <i className="icon-exclamation bg-warning p-1 rounded" />
            :
            <i className="icon-check bg-success p-1 rounded" />
        }
      </div>
    );
  }

  renderActionsCell = (props) => {
    return (
      <>
        <EditBlogPost blogPost={props.original} {...this.props} />
        <DeleteModal delete={() => this.onDelete(props.original.id)} />
      </>
    );
  }

  render (){
    const { isOpen, posts } = this.state;
    const {children, style, color, className} = this.props;

    
    const columns = [
      {
        Header: "Id",
        accessor: "id"
      },
      {
        Header: "Slug",
        accessor: "slug"
      },
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Actions",
        Cell: this.renderActionsCell,
        sortable: false,
        filterable: false,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      }
    ];
  
    return (

      <>
      <Button
      className={className}
      color={color}
      style={style}
      onClick={this.toggle}
      >
        {children}
    </Button>
    <Modal size='xl' isOpen={isOpen} toggle={this.toggle} className="modal-xl modal-success">
      <Form onSubmit={this.onCreate}>
        <ModalHeader toggle={this.toggle}>Messages </ModalHeader>
        <ModalBody>
        <div className="animated fadeIn">
          <Card>
            <CardBody>
              <ReactTable data={posts} columns={columns} filterable defaultPageSize={10} />
            </CardBody>
          </Card>
        </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={this.toggle}>Close</Button>
        </ModalFooter>
      </Form>
    </Modal>
    </>
    );
  }
}

export default connect(null, {
  getBlogPostsById, deleteBlogPost
})(BlogPosts);