import React from "react";
import "firebase/auth";
import { Redirect, Route } from "react-router-dom";

export default function AuthenticatedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.user) {
          if (
            rest.user.role === "titleCompany" &&
            rest.path !== "/account" &&
            rest.path !== "/offers" &&
            rest.path !== "/offers/:id"
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/account",
                  state: { from: props.location },
                }}
              />
            );
          } else if (rest.unauthedOnly && rest.path !== "/signup") {
            return (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                }}
              />
            );
          } else {
            if (
              !rest.user.profileComplete &&
              rest.path !== "/account" &&
              rest.path !== "/signup"
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/account",
                    state: { from: props.location },
                  }}
                />
              );
            } else if (
              rest.user.hasOwnProperty("verifiedUser") &&
              !rest.user.verifiedUser &&
              rest.path !== "/account"
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/account",
                    state: { from: props.location },
                  }}
                />
              );
            } else {
              return (
                <Component
                  {...props}
                  user={rest.user}
                  functions={rest.functions}
                  hideSideBar={rest.hideSideBar}
                />
              );
            }
          }
        } else {
          if (
            rest.path !== "/login" &&
            rest.path !== "/signup" &&
            rest.path !== "/passwordReset"
          ) {
            localStorage.redirect = JSON.stringify(props.location);

            if (props.location.pathname.indexOf("/offers/") >= 0)
              return (
                <Redirect
                  to={{
                    pathname: "/login?offer",
                    state: { from: props.location },
                  }}
                />
              );

            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            );
          } else {
            return (
              <Component
                {...props}
                user={rest.user}
                pageName={rest.pageName}
                functions={rest.functions}
              />
            );
          }
        }
      }}
    />
  );
}
