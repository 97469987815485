import React, { Component } from "react";

import {
  Label,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal
} from "reactstrap";

class DeleteModal extends Component {
  state = {
    isOpen: false
  };

  toggle = () => {
    const state = !this.state.isOpen;

    this.setState({
      isOpen: state
    });

    this.props.onModalStateChange(state);
  };

  delete = () => {
    this.props.onUpdate('delete',"deleted", true);
    this.toggle();
  };

  render() {
    let { model } = this.props;

    return (
      <React.Fragment>
        <Button
          color="link"
          onClick={this.toggle}
          className="mr-1"
          style={{ margin: "0px", padding: "0px" }}
          {...this.props}
        ><span style={{ color: "#ff214f" }} className="fa fa-trash"></span></Button>

        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className="modal-danger"
        >
          <ModalHeader toggle={this.toggle}> Confirmation</ModalHeader>
          <ModalBody>
            <Label>Are you sure to delete {model} ?</Label>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              Yes
            </Button>{" "}
            <Button color="success" onClick={this.toggle}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default DeleteModal;
