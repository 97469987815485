import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ReactDOMServer from 'react-dom/server'
import buyerInviteEmail from 'components/emailTemplates/BuyerInviteEmailFromLender';

class UserInviteModal extends Component{

    state = {
        modal: false,
        sendingEmail: false,
        email:'',
    }

    toggle = () => {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
    }

    sendEmailToBuyer = () => {
        const {email} = this.state;
        if(!email){
            return alert('Email is invalid.');
        }

        this.setState({
            sendingEmail:true
        });

        const { emailData, subject } = this.props;

        const sendEmail = firebase.functions().httpsCallable('sendMatchEmail');

        const body = ReactDOMServer.renderToStaticMarkup(buyerInviteEmail(emailData));
        
        sendEmail({subject,email,body})
        .then(() => {
            this.setState({
                sendingEmail:false,
                email:'',
                modal:false
            }, () => {
                toast.success("Email Sent Successfully", {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
        })
        .catch((e) => {
            this.setState({
                sendingEmail:false
            });
        });
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value  
        });
    }

    render = () => {

        const {sendingEmail, email, modal} = this.state;
        const {buttonText} = this.props;

        return (
            <>
                <Button style={{float:'right', marginLeft:'10px'}} color="danger" className="btn-theme" onClick={this.toggle}>{buttonText}</Button>
                <Modal isOpen={modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>{buttonText} to Homematchx</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="email">Enter Email</Label>
                            <Input type="email" name="email" id="email" value={email} onChange={this.onChange} placeholder="Email" />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" disabled={sendingEmail} onClick={this.sendEmailToBuyer}>{sendingEmail ? 'Sending...':'Send'}</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

export default UserInviteModal;