import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SubBanner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: ''
        }
    }

    render() {

        const {children, image, title, heading, description, hideNav} = this.props;

        if(children){
            return (children);
        }

        return (
            <React.Fragment>
                <div
                    style={{ backgroundImage: `url(${image})` }}
                    className={`sub-banner ${!hideNav ? 'overview-bgi': ''} sub-banner-real-estate`}>
                    <div className="container">
                        <div className="breadcrumb-area">
                            {(title) && <h1>{title}</h1>}
                            {(heading) && <h1>{heading}</h1>}
                            {(description) && <p style={{color:'#fff'}}>{description}</p>}
                            {(!hideNav) && <ul className="breadcrumbs">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="active">{title}</li>
                            </ul>}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SubBanner
