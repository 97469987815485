import React from "react";
import RealtorProfile from "./RealtorProfile";
import Helpbutton from "components/HelpButton";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import ReactDOMServer from "react-dom/server";
import { sendBuyerAddedEmailToBuilder } from "./../helpers/EmailHelper";
import { firebaseConfig } from "./../components/Firebase/firebase";
import { subscribe } from "./../Store/Actions/ConvertKitActions";
import welcomeEmail from "../components/emailTemplates/welcomeEmail";

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readyToLoad: false,
      user: this.props.user,
      selectedRole: this.props.user.role,
    };
  }

  componentWillMount() {
    const { user } = this.state;

    if (this.shouldLoadRoleSelector()) {
      const db = firebase.firestore();
      console.log(user);
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          const data = doc.data();
          console.log(data);
        });
      const queries = [
        db
          .collection("buyers")
          .where("buyer_email", "==", user.email)
          .where("userInvited", "==", true),
        db
          .collection("sellers")
          .where("seller_email", "==", user.email)
          .where("userInvited", "==", true),
      ];

      const promises = queries.map((q) => q.get());

      Promise.all(promises).then(
        (snapshots) => {
          const docSets = snapshots.map((s) => s.docs);
          let docs = [];

          let userData = null;

          let sellerOrBuyerId = null;
          let community = null;

          for (const docSet of docSets) {
            for (const doc of docSet) {
              if (!docs.some((d) => d.id === doc.id)) {
                sellerOrBuyerId = doc.id;
                const sellerOrBuyer = doc.data();
                const userDefaultFields = {
                  agent_available: "No",
                  agent_email: "",
                  agent_needed: "",
                  displayContactInfo: false,
                  role: "user",
                  profileComplete: true,
                };
                if (
                  sellerOrBuyer.seller_email &&
                  sellerOrBuyer.seller_email === user.email
                ) {
                  userData = {
                    ...userDefaultFields,
                    full_name: sellerOrBuyer.seller_name,
                    phone: sellerOrBuyer.seller_phone,
                    purpose: "Sell a house",
                  };
                }

                if (
                  sellerOrBuyer.buyer_email &&
                  sellerOrBuyer.buyer_email === user.email
                ) {
                  userData = {
                    ...userDefaultFields,
                    full_name: sellerOrBuyer.buyer_name,
                    phone: sellerOrBuyer.buyer_phone,
                    builder_email: sellerOrBuyer.builder_email,
                    purpose: "Buy a house",
                  };
                  community = sellerOrBuyer.community;
                }

                docs.push(sellerOrBuyer);
              }
            }
          }

          if (userData) {
            db.collection("users")
              .doc(this.props.user.uid)
              .set(userData)
              .then((r) => {
                setTimeout(() => {
                  this.setState(
                    {
                      readyToLoad: true,
                      user: { ...this.props.user, ...userData },
                    },
                    () => {
                      window.location.href = "/account";
                    }
                  );
                }, 1000);
              });

            if (userData.builder_email && sellerOrBuyerId) {
              const updateBuilderOnUserCreate = firebase
                .functions()
                .httpsCallable("updateBuilderOnUserCreate");
              updateBuilderOnUserCreate({
                buyer_id: sellerOrBuyerId,
                builder_email: userData.builder_email,
              });
              sendBuyerAddedEmailToBuilder(
                userData.builder_email,
                userData.full_name,
                sellerOrBuyerId,
                community || ""
              );
            }
          } else {
            this.setState({
              readyToLoad: true,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      this.setState({ readyToLoad: true });
    }
  }

  roleSelected = (role) => {
    this.setState({ selectedRole: role });
  };

  shouldLoadRoleSelector = () => {
    const { selectedRole, user } = this.state;
    let { role, profileComplete } = user;

    if (!role || !profileComplete) {
      if (!selectedRole) {
        return true;
      }
    }

    return false;
  };

  sendWelcomeEmail = async (name) => {
    const { user, selectedRole } = this.state;
    const host = firebaseConfig.host;
    const subject = `Welcome to Homematchx!`;
    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
    const body = ReactDOMServer.renderToStaticMarkup(
      welcomeEmail({
        name,
        host,
      })
    );
    sendEmail({ subject, email: user.email, body });

    let packageID = localStorage.packageID;

    const { cloudFunctionHost } = firebaseConfig;
    const response = await fetch(
      `${cloudFunctionHost}/createFreeTrailSubscription?id=${packageID}&userId=${this.props.user.uid}`
    );
    let { message } = await response.json();
    console.log(message);

    setTimeout(() => {
      if (packageID) {
        window.location.href = "/liveStreamPackages";
      }
    }, 1000);

    subscribe({
      data: {
        email: user.email,
        first_name: name,
        role: selectedRole,
      },
      onError: (error) => console.log(error),
      onSuccess: (message) => console.log(message),
    });
  };

  render() {
    const { selectedRole, readyToLoad } = this.state;

    if (!readyToLoad) {
      return <React.Fragment />;
    }

    // if (this.shouldLoadRoleSelector()) {
    //   return <RoleSelector onRoleSelect={this.roleSelected} />;
    // }

    // if (selectedRole === "realtor") {
    return (
      <>
        <RealtorProfile
          {...this.props}
          sendWelcomeEmail={this.sendWelcomeEmail}
        />
        <Helpbutton />
      </>
    );
    // }
    // else if (selectedRole === 'lender') {
    //   return <><LenderProfile {...this.props} sendWelcomeEmail={this.sendWelcomeEmail} /><Helpbutton /></>
    // }
    // else if (selectedRole === 'builder') {
    //   return (<><BuilderProfile {...this.props} sendWelcomeEmail={this.sendWelcomeEmail} /><Helpbutton /></>);
    // }
    // else {
    //   return (<><UserProfile {...this.props} sendWelcomeEmail={this.sendWelcomeEmail} /><Helpbutton /></>);
    // }
  }
}
