import React, { Component } from 'react';
import Highcharts from 'highcharts';
import ListArrowUp from 'assets/icons/list-arrow-up.svg';
import ArrowDown from 'assets/icons/list-arrow-down.svg';
import MapChart from '../HighChartMap';
import { Row, Col, Table } from 'reactstrap';
import _ from 'lodash';
 
class OfferMapLocation extends Component {

    state = {
        advanceFilter: false,
        statesFilter: 'TX',
        statesFilterOption: [
            {
                "id": 3919,
                "name": "Alabama",
                "code":"AL",
                "country_id": 231
            },
            {
                "id": 3920,
                "name": "Alaska",
                "code":"AK",
                "country_id": 231
            },
            {
                "id": 3921,
                "name": "Arizona",
                "code":"AZ",
                "country_id": 231
            },
            {
                "id": 3922,
                "name": "Arkansas",
                "code":"AR",
                "country_id": 231
            },
            {
                "id": 3924,
                "name": "California",
                "code":"CA",
                "country_id": 231
            },
            {
                "id": 3926,
                "name": "Colorado",
                "code":"CO",
                "country_id": 231
            },
            {
                "id": 3927,
                "name": "Connecticut",
                "code":"CT",
                "country_id": 231
            },
            {
                "id": 3928,
                "name": "Delaware",
                "code":"DE",
                "country_id": 231
            },
            {
                "id": 3930,
                "name": "Florida",
                "code":"FL",
                "country_id": 231
            },
            {
                "id": 3931,
                "name": "Georgia",
                "code":"GA",
                "country_id": 231
            },
            {
                "id": 3932,
                "name": "Hawaii",
                "code":"HI",
                "country_id": 231
            },
            {
                "id": 3933,
                "name": "Idaho",
                "code":"ID",
                "country_id": 231
            },
            {
                "id": 3934,
                "name": "Illinois",
                "code":"IL",
                "country_id": 231
            },
            {
                "id": 3935,
                "name": "Indiana",
                "code":"IN",
                "country_id": 231
            },
            {
                "id": 3936,
                "name": "Iowa",
                "code":"IA",
                "country_id": 231
            },
            {
                "id": 3937,
                "name": "Kansas",
                "code":"KS",
                "country_id": 231
            },
            {
                "id": 3938,
                "name": "Kentucky",
                "code":"KY",
                "country_id": 231
            },
            {
                "id": 3939,
                "name": "Louisiana",
                "code":"LA",
                "country_id": 231
            },
            {
                "id": 3941,
                "name": "Maine",
                "code":"ME",
                "country_id": 231
            },
            {
                "id": 3942,
                "name": "Maryland",
                "code":"MD",
                "country_id": 231
            },
            {
                "id": 3943,
                "name": "Massachusetts",
                "code":"MA",
                "country_id": 231
            },
            {
                "id": 3945,
                "name": "Michigan",
                "code":"MI",
                "country_id": 231
            },
            {
                "id": 3946,
                "name": "Minnesota",
                "code":"MN",
                "country_id": 231
            },
            {
                "id": 3947,
                "name": "Mississippi",
                "code":"MS",
                "country_id": 231
            },
            {
                "id": 3948,
                "name": "Missouri",
                "code":"MO",
                "country_id": 231
            },
            {
                "id": 3949,
                "name": "Montana",
                "code":"MT",
                "country_id": 231
            },
            {
                "id": 3950,
                "name": "Nebraska",
                "code":"NE",
                "country_id": 231
            },
            {
                "id": 3951,
                "name": "Nevada",
                "code":"NV",
                "country_id": 231
            },
            {
                "id": 3952,
                "name": "New Hampshire",
                "code":"NH",
                "country_id": 231
            },
            {
                "id": 3953,
                "name": "New Jersey",
                "code":"NJ",
                "country_id": 231
            },
            {
                "id": 3955,
                "name": "New Mexico",
                "code":"NM",
                "country_id": 231
            },
            {
                "id": 3956,
                "name": "New York",
                "code":"NY",
                "country_id": 231
            },
            {
                "id": 3957,
                "name": "North Carolina",
                "code":"NC",
                "country_id": 231
            },
            {
                "id": 3958,
                "name": "North Dakota",
                "code":"ND",
                "country_id": 231
            },
            {
                "id": 3959,
                "name": "Ohio",
                "code":"OH",
                "country_id": 231
            },
            {
                "id": 3960,
                "name": "Oklahoma",
                "code":"OK",
                "country_id": 231
            },
            {
                "id": 3962,
                "name": "Oregon",
                "code":"OR",
                "country_id": 231
            },
            {
                "id": 3963,
                "name": "Pennsylvania",
                "code":"PA",
                "country_id": 231
            },
            {
                "id": 3965,
                "name": "Rhode Island",
                "code":"RI",
                "country_id": 231
            },
            {
                "id": 3966,
                "name": "South Carolina",
                "code":"SC",
                "country_id": 231
            },
            {
                "id": 3967,
                "name": "South Dakota",
                "code":"SD",
                "country_id": 231
            },
            {
                "id": 3969,
                "name": "Tennessee",
                "code":"TN",
                "country_id": 231
            },
            {
                "id": 3970,
                "name": "Texas",
                "code":"TX",
                "country_id": 231
            },
            {
                "id": 3972,
                "name": "Utah",
                "code":"UT",
                "country_id": 231
            },
            {
                "id": 3973,
                "name": "Vermont",
                "code":"VT",
                "country_id": 231
            },
            {
                "id": 3974,
                "name": "Virginia",
                "code":"VA",
                "country_id": 231
            },
            {
                "id": 3975,
                "name": "Washington",
                "code":"WA",
                "country_id": 231
            },
            {
                "id": 3976,
                "name": "West Virginia",
                "code":"WV",
                "country_id": 231
            },
            {
                "id": 3977,
                "name": "Wisconsin",
                "code":"WI",
                "country_id": 231
            },
            {
                "id": 3978,
                "name": "Wyoming",
                "code":"WY",
                "country_id": 231
            }
        ],
        city: '',
        zip: 0,
        radius: 0,
        subdivision: '',
        bedroomFilter: '',
        priceMin: '',
        priceMax: '',
        bedMin: '',
        bedMax: '',
        bathMin: '',
        bathMax: '',
        squareFootageMin: '',
        squareFootageMax: '',
        priceFilter: '',
        financeTypeFilter: 'Select Finance Type',
        financeTypes: [
          {value: 'Cash', label: 'Cash'},
          {value: 'Lease', label: 'Lease'},
          {value: 'Conventional', label: 'Conventional'},
          {value: 'FHA', label: 'FHA'},
          {value: 'VA', label: 'VA'},
          {value: 'Seller Finance', label: 'Seller Finance'},
        ],
        priceFilterOptions: [
            {min: 0, max: 100000},
            {min: 100001, max: 200000},
            {min: 200001, max: 300000},
            {min: 300001, max: 400000},
            {min: 400001, max: 500000},
            {min: 500001, max: 600000},
            {min: 600001, max: 700000},
            {min: 700001, max: 800000},
            {min: 800001, max: 900000},
            {min: 900001, max: 1000000}
        ],
        bedroomFilterOptions: [2,4],
        bathsFilter: '',
        bathsFilterOptions: [2,4],
        squareFootageFilter: '',
        squareFootageFilterOptions: [
                    250,
                    2500,
                    3000,
                    3500,
                    4000,
                    4500,
                    5000,
                    5500,
                    6000,
        ],
    }

    toggleAdvanceFilterSelection = () => {
      const {advanceFilter} = this.state;
      this.setState({
        advanceFilter: !advanceFilter,
      });
    }

    onInputChange = (e, keyToNull) => {
        const {target : {name, value}} = e;
        this.setState({
            [name]: value,
            [keyToNull]: ''
        })
    }

    onMapStateChange = (st) => {
        this.setState({statesFilter: st});
    }
    
    render () {
      Highcharts.seriesTypes.line.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline;

      const { offers: unfilteredOffers } = this.props;

      const { statesFilterOption, statesFilter, bedroomFilter, bedroomFilterOptions, bathsFilter,
        bathsFilterOptions, squareFootageFilter, squareFootageFilterOptions, city, subdivision, radius, zip,
        priceMin, priceMax, bedMin, bedMax, bathMin, bathMax, squareFootageMin, squareFootageMax,
        priceFilterOptions, priceFilter, financeTypeFilter, financeTypes, advanceFilter
      } = this.state;

      const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
      });

      const offers = unfilteredOffers.filter((offer) => {
          if(statesFilter){
            return (offer.address || '').includes(statesFilter);
          }
          return true;
      }).filter(offer => {
          if(city){
            return (offer.address || '').includes(city);
          }
          return true;
      }).filter(offer => {
        if(zip){
          return (offer.address || '').includes(zip);
        }
        return true;
      }).filter(offer => {
        if(subdivision){
          return (offer.address || '').includes(subdivision);
        }
        return true;
      }).filter(offer => {
          let min = parseInt(priceFilter ? priceFilter.min : priceMin);
          return min ? (offer.actual_price || 0) >= min : true;
      }).filter(offer => {
        let max = parseInt(priceFilter ? priceFilter.max : priceMax);
        return max ? (offer.actual_price || 0) <= max : true;
      }).filter(offer => {
        let min = parseInt(bedroomFilter ? bedroomFilter : bedMin);
          return min ? (offer.bedrooms || 0) >= min : true;
      }).filter(offer => {
        let max = parseInt(bedMax);
        return max ? (offer.bedrooms || 0) <= max : true;
      }).filter(offer => {
        let min = parseInt(bathsFilter ? bathsFilter : bathMin);
          return min ? (offer.bathrooms || 0) >= min : true;
      }).filter(offer => {
        let max = parseInt(bathMax);
        return max ? (offer.bathrooms || 0) <= max : true;
      }).filter(offer => {
        let min = parseInt(squareFootageFilter ? squareFootageFilter : squareFootageMin);
        return min ? (offer.sqft || 0) >= min : true;
      }).filter(offer => {
        let max = parseInt(squareFootageMax);
        return max ? (offer.sqft || 0) <= max : true;
      }).filter(offer => {
        if(financeTypeFilter !== 'Select Finance Type'){
          return offer.financingType === financeTypeFilter;
        }
        return true;
      });

      //actual_price

      const groupedBySeller = _.groupBy(offers, 'seller');
      const numberOfSellers = Object.keys(groupedBySeller).length;
      const averageOffers = numberOfSellers ? Math.round(offers.length / numberOfSellers) : 0;


      let selectedPrice = '';
      if(priceFilter){
          selectedPrice = `${currencyFormatter.format(priceFilter.min)} to ${currencyFormatter.format(priceFilter.max)}`
      }else if(priceMin || priceMax){
        selectedPrice = `${currencyFormatter.format(priceMin)} to ${currencyFormatter.format(priceMax)}`
      }else{
        selectedPrice = 'Select Price'
      }

      let selectedBed = ``;
      if(bedroomFilter){
        selectedBed = `${bedroomFilter} beds`;
      }else if(bedMin || bedMax){
        selectedBed = `${bedMin} beds to ${bedMax} beds`
      }else{
        selectedBed = 'Select bed'
      }

      let selectedBath = ``;
      if(bathsFilter){
        selectedBath = `${bathsFilter} baths`;
      }else if(bathMin || bathMax){
        selectedBath = `${bathMin} baths to ${bathMax} baths`
      }else{
        selectedBath = 'Select bath'
      }

      let selectedSqr = ``;
      if(squareFootageFilter){
        selectedSqr = `${squareFootageFilter} sqr`;
      }else if(squareFootageMin || squareFootageMax){
        selectedSqr = `${squareFootageMin} sqr to ${squareFootageMax} sqr`
      }else{
        selectedSqr = 'Select square Footage'
      }

      let averageCount = {
        optionFee: 0,  
        earnestMoney: 0,
        optionDays: 0,
        financeAmount: 0,
        purchasePrice: 0,
        downPayment: 0,
        sellerConcessions: 0,
        residentialServiceContract: 0,
        numberOfDays: 0,
        leasebackDays: 0,
      }

      let itemsCount = {
        titlePolicyExpense: {},
        financingType: {},
        possession: {},
        saleOfOtherProperty: {},
        rightToTerminate: {},
      }

      offers.forEach(offer => {
        Object.keys(averageCount).forEach(key => {
            averageCount[key] += parseInt(offer[key] || 0);
        });
        Object.keys(itemsCount).forEach(key => {
            itemsCount[key][offer[key]] = itemsCount[key][offer[key]] ? itemsCount[key][offer[key]] + 1: 0
        });
      });

      const itemAverage = {};

      Object.keys(averageCount).forEach(key => {
          if(offers.length){
            itemAverage[key] = Math.round(averageCount[key] / offers.length);
          }else{
            itemAverage[key] = 0;
          }
      });

      let keyItemValues = {};
      let keyItemIndex = {};
      let keyItemValue = {}

      Object.keys(itemsCount).forEach(key => {
        keyItemValues[key] = Object.values(itemsCount[key]);
        keyItemIndex[key] = keyItemValues[key].indexOf(Math.max(...keyItemValues[key]));
        keyItemValue[key] = Object.keys(itemsCount[key])[keyItemIndex[key]];
      });

      let selectState = '';

      statesFilterOption.forEach(s => {
          if(s.code === statesFilter){
            selectState = s.name;
            return;
          }
      });

        return (
            <div className="maps-container">
            <Row>
              <Col lg={5} md={12} sm={12}>
                <div className="padding-20">
                  <p className="font-18-title">Offer Map Location</p> 
                  <div className="mobile-responsive-map">
                    <MapChart selectedState="TX" onMapStateChange={this.onMapStateChange}  offers={unfilteredOffers}/>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6}>
                <div className="maps-filters-container">
                  <div>
                    <button className="analytics-price-dropdown-btn"  type="button" id="listingstateFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="analytics-row center-me">
                        <div className="green-circle"></div>
                        <p className="analytics-input-label">{selectState}</p>
                        <img alt="ArrowDown" src={ArrowDown} className="down-arrow"/>
                      </div>
                    </button>
                    <div className="custom-price-options-list dropdown-menu" aria-labelledby="listingstateFilter">
                      {statesFilterOption.map((value ,index) => ( 
                        <div className="analytics-price-dropdown-inner-btn" key={index} onClick={() => this.setState({statesFilter: value.code})} >
                          <p>{value.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="analytics-row center-me price-input-container tab-responsive-flex-start">
                    <div className="analytics-row center-me">
                      <div className="green-circle"></div>
                      <p className="analytics-input-label">City</p>
                    </div>
                    <input type="text" className="analytics-price-input" id="city" name="city" value={city} placeholder="Dallas" onChange={this.onInputChange}/>
                  </div>
                  <div className="analytics-row center-me price-input-container tab-responsive-flex-start">
                    <div className="analytics-row center-me">
                      <div className="green-circle"></div>
                      <p className="analytics-input-label">Zip</p>
                    </div>
                    <input type="text" className="analytics-price-input" id="zip" name="zip" value={zip} placeholder="77084" onChange={this.onInputChange}/>
                  </div>
                  <div className="analytics-row center-me price-input-container tab-responsive-flex-start">
                    <div className="analytics-row center-me">
                      <div className="green-circle"></div>
                      <p className="analytics-input-label">Subdivision</p>
                    </div>
                    <input type="text" className="analytics-price-input" id="subdivision" name="subdivision" value={subdivision} placeholder="Collin County" onChange={this.onInputChange}/>
                  </div>
                  <div className="analytics-row center-me price-input-container tab-responsive-flex-start">
                    <div className="analytics-row center-me">
                      <div className="green-circle"></div>
                      <p className="analytics-input-label">Radius</p>
                    </div>
                    <input type="text" className="analytics-price-input" id="radius" name="radius" placeholder="10 mil" value={radius} onChange={this.onInputChange}/>
                  </div>

                  {(advanceFilter && (
                    <>
                      <div>
                        <button className="analytics-price-dropdown-btn"  type="button" id="listingPriceFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <div className="analytics-row center-me">
                            <div className="green-circle"></div>
                            <p className="analytics-input-label">{financeTypeFilter}</p>
                            <img alt="ArrowDown" src={ArrowDown} className="down-arrow"/>
                          </div>
                        </button>
                          <div className="custom-price-options-list dropdown-menu" aria-labelledby="listingPriceFilter">
                          {financeTypes.map((type, index) => (
                              <button onClick={() => this.setState({financeTypeFilter: type.value})} key={index} className="analytics-price-dropdown-inner-btn">
                                <p>{type.label}</p>
                              </button>
                          ))}
                        </div>
                      </div>

                      <div>
                        <button className="analytics-price-dropdown-btn"  type="button" id="listingPriceFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <div className="analytics-row center-me">
                            <div className="green-circle"></div>
                            <p className="analytics-input-label">{selectedPrice}</p>
                            <img alt="ArrowDown" src={ArrowDown} className="down-arrow"/>
                          </div>
                        </button>
                          <div className="custom-price-options-list dropdown-menu" aria-labelledby="listingPriceFilter">
                          <div className="analtics-space-between padd-6">
                            <input type="text" className="price-inputs" id="priceMin" name="priceMin" value={priceMin} onChange={(e) => this.onInputChange(e, 'priceFilter')} placeholder="Min"/>
                            <input type="text" className="price-inputs" id="priceMax" name="priceMax" value={priceMax} onChange={(e) => this.onInputChange(e, 'priceFilter')} placeholder="Max"/>
                          </div>
                          {priceFilterOptions.map((price, index) => (
                            <button onClick={() => this.setState({priceFilter: price, priceMin: '', priceMax: ''})} key={index} className="analytics-price-dropdown-inner-btn">
                              <p>{currencyFormatter.format(price.min)}</p>
                              <p>to</p>
                              <p>{currencyFormatter.format(price.max)}</p>
                          </button>
                          ))}
                        </div>
                      </div>

                      <div>
                        <button className="analytics-price-dropdown-btn"  type="button" id="listingBedroomsFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <div className="analytics-row center-me">
                            <div className="green-circle"></div>
                            <p className="analytics-input-label">{selectedBed}</p>
                            <img alt="ArrowDown" src={ArrowDown} className="down-arrow"/>
                          </div>
                        </button>
                        <div className="custom-price-options-list dropdown-menu" aria-labelledby="listingBedroomsFilter">
                          <div className="analtics-space-between padd-6">
                            <input type="text" className="price-inputs" id="bedMin" name="bedMin" value={bedMin} onChange={(e) => this.onInputChange(e, 'bedroomFilter')} placeholder="Min"/>
                            <input type="text" className="price-inputs" id="bedMax" name="bedMax" value={bedMax} onChange={(e) => this.onInputChange(e, 'bedroomFilter')} placeholder="Max"/>
                          </div>
                          {bedroomFilterOptions.map(e => ( 
                            <div className="analytics-price-dropdown-inner-btn" key={e} onClick={() => {
                            this.setState({
                                bedroomFilter: e, bedMin: '', bedMax: '',
                            });
                          }}><p>{e}</p>
                            </div>
                            ))}
                        </div>
                      </div>

                      <div>
                        <button className="analytics-price-dropdown-btn"  type="button" id="listingBathsFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <div className="analytics-row center-me">
                            <div className="green-circle"></div>
                            <p className="analytics-input-label">{selectedBath}</p>
                            <img alt="ArrowDown" src={ArrowDown} className="down-arrow"/>
                          </div>
                        </button>
                        <div className="custom-price-options-list dropdown-menu" aria-labelledby="listingBathsFilter">
                          <div className="analtics-space-between padd-6">
                            <input type="text" className="price-inputs" id="bathMin" name="bathMin" value={bathMin} onChange={(e) => this.onInputChange(e, 'bathsFilter')} placeholder="Min"/>
                            <input type="text" className="price-inputs" id="bathMax" name="bathMax" value={bathMax} onChange={(e) => this.onInputChange(e, 'bathsFilter')} placeholder="Max"/>
                          </div>
                          {bathsFilterOptions.map(e => ( 
                            <div className="analytics-price-dropdown-inner-btn" key={e} onClick={() => {
                                this.setState({
                                    bathsFilter: e, bathMin: '', bathMax: '',
                                });
                          }}>
                              <p>{e}</p>
                            </div>
                            ))}
                        </div>
                      </div>

                      <div>
                        <button className="analytics-price-dropdown-btn"  type="button" id="listingAreaFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <div className="analytics-row center-me">
                            <div className="green-circle"></div>
                            <p className="analytics-input-label">{selectedSqr}</p>
                            <img alt="ArrowDown" src={ArrowDown} className="down-arrow"/>
                          </div>
                        </button>
                        <div className="custom-price-options-list dropdown-menu" aria-labelledby="listingAreaFilter">
                          <div className="analtics-space-between padd-6">
                            <input type="text" className="price-inputs" id="squareFootageMin" name="squareFootageMin" value={squareFootageMin} onChange={(e) => this.onInputChange(e, 'squareFootageFilter')} placeholder="Min"/>
                            <input type="text" className="price-inputs" id="squareFootageMax" name="squareFootageMax" value={squareFootageMax} onChange={(e) => this.onInputChange(e, 'squareFootageFilter')} placeholder="Max"/>
                          </div>
                          {squareFootageFilterOptions.map(e => ( 
                            <div className="analytics-price-dropdown-inner-btn" key={e} onClick={() => {
                                this.setState({
                                    squareFootageFilter: e, squareFootageMin: '', squareFootageMax: '',
                                });
                          }}>
                              <p>{e}</p>
                            </div>
                            ))}
                        </div>
                      </div>
                    </>
                  ))}

                  <button onClick={this.toggleAdvanceFilterSelection} className="analytics-price-dropdown-btn"  type="button" id="listingPriceFilters">
                    <div className="analytics-row center-me">
                      <p className="analytics-input-label">{advanceFilter ? 'Hide Advance Filter': 'Show Advance Filter'}</p>
                      <img alt="ArrowDown" src={advanceFilter ? ListArrowUp : ArrowDown} className="down-arrow"/>
                    </div>
                  </button>

                </div>
              </Col>
              <Col lg={4} md={6} sm={6}>
                <div className="average-offers-per-listing-container">
                  <p className="average-offers-text">Average number of offers received per listing</p>
                  <p className="average-offers">{averageOffers}</p>
                  <p className="average-offers-text">Offers</p>
                  <p className="average-offers font-40">{currencyFormatter.format(itemAverage['purchasePrice'])}</p>
                  <p className="average-offers-text">Avg Purchase Price</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <p className="font-18-title avg-offers-terms">Average Offer Terms include</p>                 
              </Col>
              <div className="col-xl-6 col-lg-6 col-md-12 overflow">
                <div className="analytics-table-left">
                  <Table striped>
                    <tbody>
                      <tr>
                        <td className="analytics-table-td">Finance Type</td>
                        <td className="analytics-table-td text-right">{keyItemValue['financingType']}</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Down Payment</td>
                        <td className="analytics-table-td text-right">{currencyFormatter.format(itemAverage['downPayment'])}</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Finance Amount</td>
                        <td className="analytics-table-td text-right">{currencyFormatter.format(itemAverage['financeAmount'])}</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Seller Concessions</td>
                        <td className="analytics-table-td text-right">{currencyFormatter.format(itemAverage['sellerConcessions'])}</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Earnest Money Deposit</td>
                        <td className="analytics-table-td text-right">{currencyFormatter.format(itemAverage['earnestMoney'])}</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Title Expense</td>
                        <td className="analytics-table-td text-right">{keyItemValue['titlePolicyExpense']}</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Service Contract</td>
                        <td className="analytics-table-td text-right">{itemAverage['residentialServiceContract']}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 overflow">
                <div className="analytics-table-right">
                  <Table striped>
                    <tbody>
                      <tr>
                        <td className="analytics-table-td">Closing Date</td>
                        <td className="analytics-table-td text-right">{itemAverage['numberOfDays']} Days</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Leaseback Days</td>
                        <td className="analytics-table-td text-right">{itemAverage['leasebackDays']}</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Possession</td>
                        <td className="analytics-table-td text-right">{keyItemValue['possession']}</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Option Fee and Days</td>
                        <td className="analytics-table-td text-right">{currencyFormatter.format(itemAverage['optionFee'])}, {itemAverage['optionDays']} Days</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Non Realty Item</td>
                        <td className="analytics-table-td text-right">n/a</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Sale of Other Property</td>
                        <td className="analytics-table-td text-right">{keyItemValue['saleOfOtherProperty']}</td>
                      </tr>
                      <tr>
                        <td className="analytics-table-td">Right to Terminate Due to Lender’s Appraisal</td>
                        <td className="analytics-table-td text-right">{keyItemValue['rightToTerminate']}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Row>  
          </div>
        
        )
    }
}


export default OfferMapLocation;

