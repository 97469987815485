import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip, Badge } from "reactstrap";
import DeleteMessageModal from "./DeleteMessageModal";
import EditMessageModal from "./EditMessageModal";
import EditHistoryModal from "./EditHistoryModal";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/database";

class MessagesListItem extends Component {
  state = {
    tooltipOpen: false,
    modalIsInOpenState: false
  };

  onModalStateChange = state => {
    this.setState({
      tooltipOpen: state,
      modalIsInOpenState: state
    });
  };

  onUpdate = (type, key, value) => {
    let { message, messages, user } = this.props;

    let update = { key, value };
    if (type === "edit") {
      const timeStamp = Math.floor(Date.now() / 1000);
      update = {
        text: value,
        updatedAt: timeStamp,
        history: [{ text: value, updatedAt: timeStamp }, ...message.history]
      };
    }

    if (type === "delete") {
      update = {
        deleted: value,
        deletedAt: Math.floor(Date.now() / 1000)
      };
    }

    const { id, receiverID } = message;
    // first remove messages of other user.
    messages = messages.filter(m => m.senderID === user.uid);

    messages = messages.map(m => {
      let result = { ...m };
      delete result.senderID;
      delete result.receiverID;
      if (m.id === id) {
        return { ...result, ...update };
      }
      return { ...result };
    });

    const db = firebase.firestore();
    db.collection("conversations")
      .doc(`${user.uid}-${receiverID}`)
      .update({ messages });
  };

  toggle = () => {
    const { modalIsInOpenState } = this.state;
    if (!modalIsInOpenState) {
      this.setState({
        tooltipOpen: !this.state.tooltipOpen
      });
    }
  };

  renderToolTip = isOwner => {
    const { text, deleted } = this.props.message;

    if (isOwner && !deleted) {
      return (
        <React.Fragment>
          <DeleteMessageModal
            onModalStateChange={this.onModalStateChange}
            onUpdate={this.onUpdate}
          />
          <EditMessageModal
            onModalStateChange={this.onModalStateChange}
            onUpdate={this.onUpdate}
            text={text}
          />
        </React.Fragment>
      );
    }
  };

  render() {
    const { tooltipOpen } = this.state;
    const { message, user, profile } = this.props;
    const { senderID, text, deleted, id, history, timestamp } = message;
    const { uid, profilePhoto } = user;

    const isOwner = senderID === uid;

    const profileImage =
      profilePhoto["64"] ||
      profilePhoto["128"] ||
      profilePhoto["256"] ||
      profilePhoto["placeholder"];

    const messageType = isOwner ? "replies" : "sent";
    const img = isOwner ? profileImage : profile;
    const messageText = deleted ? "Deleted message" : text;
    const pos = isOwner ? "right" : "left";

    return (
      <React.Fragment>
        <li className={`${messageType}`}>
          <img src={img} alt="" />
          <p id={`actions-${id}`}>
            {messageText} <br />
            <Badge
              style={{
                float: pos,
                borderRadius: "1.25rem",
                marginTop: "5px",
                backgroundColor:'transparent',
                color:'#dadada'
              }}
            >
              {moment.unix(timestamp).format("Do MMM [at] h:mm a")}
            </Badge>
          </p>
        </li>
        <Tooltip
          placement="auto"
          isOpen={tooltipOpen}
          target={`actions-${id}`}
          toggle={this.toggle}
          style={{ backgroundColor: "transparent" }}
          hideArrow={true}
          delay={{ show: 0, hide: 3000 }}
        >
          {this.renderToolTip(isOwner)}

          {(() => {
            if (history.length > 1 && !deleted) {
              return (
                <EditHistoryModal
                  onModalStateChange={this.onModalStateChange}
                  history={history}
                />
              );
            }
          })()}
        </Tooltip>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ MessengerReducer }) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesListItem);
