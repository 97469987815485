import React, { useEffect, useMemo, useRef, useState } from "react";
import { Bar, HorizontalBar } from "react-chartjs-2";
import {
  customizeLabels,
  drawCustomBorderRadius,
  drawCustomBorderRadiusHorizontal,
  drawWhiteLine,
} from "utils/Functions";

import styles from "./globalPriceRatio.module.css";

const GlobalPriceRatio = ({
  mobile,
  brockerageInfo,
  setSelectedBrokarage,
  selectedBrokarage,
}) => {
  const sliceLimit = 50;
  const [loader, setLoader] = useState(false);
  const [legendFilter, setLegendFilter] = useState([]);
  const [lowerSliceLimit, setLowerSliceLimit] = useState(0);
  const [upperSliceLimit, setUpperSliceLimit] = useState(20);

  const chartContainerRef = useRef(null);

  const datasetLabels = ["Average homes price", "Average offers price"];

  const getChartData = () => {
    const categories = [];
    const totalSumArrayOffers = [];
    const totalSumArrayHomes = [];

    // const dataKeys = mobile
    //   ? Object.keys(brockerageInfo).slice(lowerSliceLimit, upperSliceLimit)
    //   : Object.keys(brockerageInfo);

    const dataKeys = Object.keys(brockerageInfo).slice(
      lowerSliceLimit,
      upperSliceLimit
    );

    for (let key of dataKeys) {
      categories.push(key);
      !legendFilter.includes("Average offers price")
        ? totalSumArrayOffers.push(
            Math.round(
              brockerageInfo[key].totalSum / brockerageInfo[key].offersCount
            )
          )
        : totalSumArrayOffers.push(0);
      !legendFilter.includes("Average homes price")
        ? totalSumArrayHomes.push(
            Math.round(
              brockerageInfo[key].actual_price / brockerageInfo[key].offersCount
            )
          )
        : totalSumArrayHomes.push(0);
    }

    return {
      categories,
      series: [
        {
          name: "Average homes price",
          data: totalSumArrayHomes,
          color: "#28A745",
        },
        {
          name: "Average offers price",
          data: totalSumArrayOffers,
          color: "#109CF1",
        },
      ],
    };
  };
  const drawCustom = (chart) => {
    drawWhiteLine(chart);
    const ctx = chart.ctx;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;
      if (!meta.hidden) {
        const maxValue = Math.max(...dataset.data);

        elements.forEach((element, elementIndex) => {
          if (maxValue / dataset.data[elementIndex] <= 40) {
            drawCustomBorderRadius(element, ctx, true);
          }
        });
      }
    });
  };
  const dataValues = getChartData();
  const plugin = {
    id: "increase-legend-spacing",
    beforeInit(chart) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
      };
    },
    afterDatasetsDraw: (chart) => drawCustom(chart),
  };

  const plugin2 = {
    id: "increase-legend-spacing",
    beforeInit(chart) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
      };
    },
  };

  const categoriesLabels = useMemo(
    () => customizeLabels(dataValues.categories),
    [dataValues.categories]
  );

  const drawCustomLabels = (chart) => {
    const ctx = chart.ctx;
    ctx.strokeStyle = "#fff";
    ctx.lineWidth = 30;
    ctx.beginPath();
    ctx.moveTo(0, 40);
    ctx.lineTo(chart.width, 40);
    ctx.stroke();

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;
      if (!meta.hidden)
        elements.forEach((element, elementIndex) => {
          const startX1 = element._view.x - element._model.height / 12;
          const startY1 = element._view.y - element._model.height / 1.7;

          const startY2 = element._view.y + element._model.height / 2.4;
          const startX2 = element._view.x + element._model.height / 10;
          drawCustomBorderRadiusHorizontal(
            ctx,
            startX1,
            startY1,
            startY2,
            startX2
          );
          const value = dataset.data[elementIndex];
          ctx.save();
          ctx.textBaseline = "middle";
          ctx.fillStyle = "black";
          ctx.font = "bold 14px Arial";
          const labelX = element._view.x;
          const labelY = element._view.y;

          if (value === 0) {
            ctx.fillText(value + "K", labelX + 25, labelY);
          } else if (value < 1000) {
            ctx.fillText(value, labelX + 25, labelY);
          } else if (value >= 1000 && value < 1000000) {
            ctx.fillText((value / 1000).toFixed(0) + "K", labelX + 25, labelY);
          } else if (value >= 1000000 && value < 1000000000) {
            ctx.fillText(
              (value / 1000000).toFixed(0) + "M",
              labelX + 25,
              labelY
            );
          } else if (value >= 1000000000 && value < 1000000000000) {
            ctx.fillText(
              (value / 1000000000).toFixed(0) + "B",
              labelX + 25.75,
              labelY
            );
          } else if (value >= 1000000000000) {
            ctx.fillText(
              (value / 1000000000000).toFixed(0) + "T",
              labelX + 25,
              labelY
            );
          }
          ctx.restore();
        });
    });
  };

  useEffect(() => {
    setLoader(false);
    if (brockerageInfo) {
      setTimeout(() => {
        setLoader(true);
      }, 200);
    }
  }, [brockerageInfo]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {dataValues?.series[0]?.data?.length === 0 && (
        <div
          style={{
            width: "41px",
            height: "16px",
            background: "#fff",
            position: "absolute",
            bottom: "0px",
          }}
        />
      )}
      {!loader && (
        <img
          src="/img/loader.gif"
          style={{
            position: "absolute",
            transform: "translate(1200%, 440%)",
          }}
        />
      )}
      {loader ? (
        selectedBrokarage?.length !== 1 ? (
          <>
            <div
              className={styles.labels}
              style={{
                marginBottom: "28px",
                marginLeft: "6px",
              }}
            >
              {datasetLabels.map((label) => (
                <React.Fragment key={label}>
                  <span
                    className={styles.circle}
                    onClick={() => {
                      if (legendFilter.includes(label)) {
                        setLegendFilter(
                          legendFilter.filter((e) => e !== label)
                        );
                      } else {
                        setLegendFilter([...legendFilter, label]);
                      }
                    }}
                  ></span>
                  <span
                    className={
                      legendFilter.includes(label)
                        ? styles["text-decoration-line-through"]
                        : ""
                    }
                  >
                    {label}
                  </span>
                </React.Fragment>
              ))}
            </div>

            <div
              className={styles.globalPriceRatio}
              ref={chartContainerRef}
              onScroll={(e) => {
                const container = chartContainerRef.current;
                const scrollEndPosition =
                  container.scrollWidth - container.clientWidth;
                const center = scrollEndPosition / 2;

                if (Object.keys(brockerageInfo).length === upperSliceLimit && Math.ceil(container.scrollLeft) >= scrollEndPosition) {
                  return;
                }
                if (Math.ceil(container.scrollLeft) >= scrollEndPosition) {
                  container.scrollTo(center, 0);
                  if (
                    Object.keys(brockerageInfo).length - upperSliceLimit <
                    10
                  ) {
                    setLowerSliceLimit(Object.keys(brockerageInfo).length - 20);
                    setUpperSliceLimit(Object.keys(brockerageInfo).length);
                  } else {
                    setLowerSliceLimit((prev) => prev + 10);
                    setUpperSliceLimit((prev) => prev + 10);
                  }
                } else if (Math.ceil(container.scrollLeft) === 0) {

                  if (lowerSliceLimit >= 20) {
                    setLowerSliceLimit((prev) => prev - 10);
                    setUpperSliceLimit((prev) => prev - 10);
                    container.scrollTo(center, 0);
                  } else {
                    setLowerSliceLimit(0);
                    setUpperSliceLimit(20);
                  }
                }
              }}
            >
              <div
                style={{
                  width: `${
                    dataValues?.series[0]?.data?.length * 100 > 950
                      ? dataValues?.series[0]?.data?.length * 100
                      : 950
                  }px`,
                  marginTop: "-30px",
                  minHeight: "400px",
                }}
              >
                {dataValues?.series[0]?.data?.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <span>There is no data...</span>
                  </div>
                ) : (
                  <Bar
                    data={{
                      labels: categoriesLabels,
                      datasets: [
                        {
                          label: dataValues?.series[0]?.name,
                          data: dataValues?.series[0]?.data
                            ? dataValues?.series[0]?.data?.map((el) =>
                                !el ? 0 : el
                              )
                            : 0,
                          backgroundColor: dataValues.series[0].color,
                          categoryPercentage: 0.5,
                          barPercentage: 0.6,
                          barThickness: 12,
                        },
                        {
                          label: "",
                          data: [0],
                          backgroundColor: "#fff",
                          categoryPercentage: 0.5,
                          barPercentage: 0.6,
                          barThickness: 4,
                        },
                        {
                          label: dataValues?.series[1]?.name,
                          data: dataValues?.series[1]?.data
                            ? dataValues?.series[1]?.data?.map((el) =>
                                !el ? 0 : el
                              )
                            : 0,
                          backgroundColor: dataValues.series[1].color,
                          categoryPercentage: 0.5,
                          barPercentage: 0.6,
                          barThickness: 12,
                        },
                      ],
                    }}
                    options={{
                      tooltips: {
                        callbacks: {
                          label: (e) => {
                            const value = Number(e.value);

                            if (value === 0) {
                              return value;
                            } else if (value < 1000) {
                              return value + "K";
                            } else if (value >= 1000 && value < 1000000) {
                              return (value / 1000).toFixed(0) + "K";
                            } else if (value >= 1000000 && value < 1000000000) {
                              return (value / 1000000).toFixed(0) + "M";
                            } else if (
                              value >= 1000000000 &&
                              value < 1000000000000
                            ) {
                              return (value / 1000000000).toFixed(0) + "B";
                            } else if (value >= 1000000000000) {
                              return (value / 1000000000000).toFixed(2) + "T";
                            }
                          },
                        },
                      },
                      onClick: (_, elements) => {
                        if (elements.length > 0) {
                          const newBrokarage =
                            dataValues.categories[elements[0]?._index];
                          const res = [...selectedBrokarage];
                          if (!res.includes(newBrokarage)) {
                            res.push(newBrokarage);
                            setSelectedBrokarage(res);
                          }
                        }
                      },
                      interaction: {
                        mode: "index",
                        intersect: false,
                      },
                      scales: {
                        ticks: {
                          backdropPaddingY: 0.1,
                          backdropPaddingX: 0.1,
                          precision: 0.1,
                        },
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                            ticks: {
                              autoSkip: false,
                              maxRotation: 45,
                              minRotation: 45,
                              beginAtZero: false,
                              backdropPaddingX: 0.1,
                              backdropPaddingY: 0.1,
                              maxRotation: 0,
                              showLabelBackdrop: true,
                              mirror: true,
                              fontSize: 12,
                              suggestedMax: 2,
                              padding: 15,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            gridLines: {
                              borderDash: [5, 5],
                              lineWidth: 2,
                              borderDashOffset: 4,
                              color: "rgba(235, 235, 235, 1)",
                              zeroLineColor: "rgba(235, 235, 235, 1)",
                              zeroLineWidth: 2,
                              drawTicks: false,
                              tickMarkLength: 12,
                            },
                            ticks: {
                              fontFamily: "Raleway",
                              beginAtZero: true,
                              maxTicksLimit: 5,
                              callback: (value) => {
                                if (value === 0) {
                                  return value;
                                } else if (value < 1000) {
                                  return value + "K";
                                } else if (value >= 1000 && value < 1000000) {
                                  return (value / 1000).toFixed(0) + "K";
                                } else if (
                                  value >= 1000000 &&
                                  value < 1000000000
                                ) {
                                  return (value / 1000000).toFixed(0) + "M";
                                } else if (
                                  value >= 1000000000 &&
                                  value < 1000000000000
                                ) {
                                  return (value / 1000000000).toFixed(0) + "B";
                                } else if (value >= 1000000000000) {
                                  return (
                                    (value / 1000000000000).toFixed(2) + "T"
                                  );
                                }
                              },
                              backdropPaddingX: 0.1,
                              backdropPaddingY: 0.1,
                              padding: 15,
                            },
                          },
                        ],
                      },
                      responsive: true,
                      legend: {
                        display: false,
                        position: "top",
                        align: "start",
                        labels: {
                          usePointStyle: true,
                          fontSize: 12,
                          padding: 30,
                        },
                      },
                      title: {
                        display: true,
                      },
                    }}
                    height={
                      (4500 /
                        (dataValues?.series[0]?.data?.length * 100 > 950
                          ? dataValues?.series[0]?.data?.length * 100
                          : 950)) *
                      30
                    }
                    plugins={[plugin]}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <HorizontalBar
            height={400}
            plugins={[
              {
                id: "customLabels",
                afterDatasetsDraw: (chart) => drawCustomLabels(chart),
              },
              plugin2,
            ]}
            data={{
              labels: [""],
              datasets: [
                {
                  label: "Average homes price",
                  data: [
                    Math.round(
                      brockerageInfo[selectedBrokarage[0]]?.actual_price /
                        brockerageInfo[selectedBrokarage[0]]?.offersCount
                    ).toFixed(0),
                  ],
                  borderColor: "#28A745",
                  backgroundColor: "#28A745",
                  categoryPercentage: 0.6,
                  barPercentage: 0.6,
                  barThickness: 30,
                },
                {
                  label: "Average offers price",
                  data: [
                    Math.round(
                      brockerageInfo[selectedBrokarage[0]]?.totalSum /
                        brockerageInfo[selectedBrokarage[0]]?.offersCount
                    ).toFixed(0),
                  ],
                  borderColor: "#109CF1",
                  backgroundColor: "#109CF1",
                  categoryPercentage: 0.6,
                  barPercentage: 0.6,
                  barThickness: 30,
                },
              ],
            }}
            options={{
              width: 900,
              maintainAspectRatio: false,
              elements: {
                bar: {
                  borderWidth: 2,
                },
              },
              legend: {
                display: true,
                position: "top",
                align: "start",
                labels: {
                  usePointStyle: true,
                  pointStyle: "circle",
                },
              },
              responsive: true,
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      callback: (value) => {
                        if (value === 0) {
                          return value;
                        } else if (value < 1000) {
                          return value + "K";
                        } else if (value >= 1000 && value < 1000000) {
                          return (value / 1000).toFixed(0) + "K";
                        } else if (value >= 1000000 && value < 1000000000) {
                          return (value / 1000000).toFixed(0) + "M";
                        } else if (
                          value >= 1000000000 &&
                          value < 1000000000000
                        ) {
                          return (value / 1000000000).toFixed(0) + "B";
                        } else if (value >= 1000000000000) {
                          return (value / 1000000000000).toFixed(2) + "T";
                        }
                      },
                      maxTicksLimit: 5,
                      padding: 15,
                    },
                    gridLines: {
                      borderDash: [4, 4],
                      lineWidth: 2,
                      zeroLineColor: "rgba(235, 235, 235, 1)",
                      zeroLineWidth: 2,
                      zeroLineBorderDash: [5, 5],
                      drawTicks: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: false,
                      max: 8,
                      min: -3,
                    },
                    gridLines: {
                      display: false,
                      drawTicks: false,
                    },
                  },
                ],
              },
              plugins: {
                customLabels: {
                  labels: {
                    render: "value",
                    fontColor: "black",
                    position: "end",
                    offset: -4,
                  },
                },
                legend: {
                  itemSpacing: 40,
                },
              },
            }}
          />
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default GlobalPriceRatio;
