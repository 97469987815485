import React, { Component } from 'react';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
//import { firebaseConfig } from '../components/Firebase/firebase';
import firebase from 'firebase/app'
import 'firebase/firestore'
import { saveCount } from '../Services/Comments-Count';
import BookingComments from '../components/BookingComments';
import BookingSubmitComment from '../components/BookingSubmitComment';
const apiKey = '46494562';

class LiveVistStream extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: '',
            error: '',
            liveBookings: [],
            loading: true,
            sellerId: null,
            city: '',
            address: '',
            userName: '',
            speaker: true,
            mic: true,
            publisher: null,
            subscriber: null,
            cameraPosition: localStorage.cameraPosition || 'environment',
            startStream: true,
        }

        this.subscriberProperties = {
            preferredFrameRate: 15,
            showControls: false,
            facingMode: this.state.cameraPosition,
            style: {
                margin: '0 auto'
            }
        };

        this.subscriberEventHandlers = {
            videoDisabled: event => {
                console.log(event);
                console.log('Subscriber video disabled!');
            },
            videoEnabled: event => {
                console.log(event);
                console.log('Subscriber video enabled!');
            }
        };
    }

    id() {
        const { match } = this.props;
        const { params } = match;
        const { id } = params;
        return id;
    }

    componentWillMount = async () => {
        const id = this.id()
        if (!id) {
            this.props.history.goBack()
            return
        }
        else {
            this.setState({ id })
        }
        this.otPublisher = React.createRef();
        this.otSubscriber = React.createRef();
    }

    componentDidMount = async () => {
        this.mounted = true;

        if (!localStorage['userName']) {
            let userName = window.prompt('Please enter your name.');
            userName = userName || 'Anonymous';
            this.setState({
                userName
            });
            localStorage['userName'] = userName;
        } else {
            this.setState({
                userName: localStorage['userName']
            });
        }

        const db = firebase.firestore();
        let booking = await db.collection('liveVisitBookings').doc(this.id()).get();
        booking = booking.data();

        let seller = await db.collection('sellers').doc(booking.seller).get();
        seller = seller.data();

        await saveCount(this.id());

        this.setState({
            address: seller.location.name,
            city: seller.city
        });
    }

    componentWillUnmount = () => {
        this.mounted = false;
    }

    toggleMic = () => {

        const { otPublisher } = this;
        if (otPublisher) {
            const { current } = otPublisher;
            if (current) {
                const publusher = current.getPublisher();
                if (publusher) {
                    const { mic } = this.state;
                    publusher.publishAudio(!mic);
                    this.setState({
                        mic: !mic
                    });
                }
            }
        }
    }

    toggleSpeaker = () => {
        const { otSubscriber } = this;
        if (otSubscriber) {
            const { current } = otSubscriber;
            if (current) {
                const subscriber = current.getSubscriber();
                if (subscriber) {
                    const { speaker } = this.state;
                    subscriber.subscribeToAudio(!speaker);
                    this.setState({
                        speaker: !speaker
                    });
                }
            }
        }
    }

    switchCamera = () => {
        const { cameraPosition } = this.state;
        const pos = cameraPosition === 'environment' ? 'user' : 'environment'
        this.setState({
            cameraPosition: pos
        });
        localStorage.cameraPosition = pos;
        window.location.reload();
    }

    render() {
        const { sessionId, token } = this.props.match.params;
        const { mic, speaker, startStream, cameraPosition } = this.state;

        return (
            <div className="content-area5">
                <div className="dashboard-content">
                    <div className="dashboard-header clearfix">
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                {startStream && (
                                    <>
                                        <h4>Home Tour Stream</h4>
                                        <p>{this.state.address || this.state.city}</p>
                                    </>
                                )}

                                {!startStream && (
                                    <div style={{ minHeight: '200px', marginTop: '50px', textAlign: 'center' }}>
                                        <h4>Thank you for using Homematchx</h4>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    {startStream && (
                        <div className="dashboard-list">
                            <OTSession apiKey={apiKey} sessionId={sessionId} token={token}
                                onStreamsUpdated={stream => {
                                    console.log(stream);
                                }}
                                onError={err => {
                                    console.log(err);
                                }}
                                onConnect={(res, r) => {
                                    console.log(res, r);
                                }}
                            >

                                <div className="video-main">

                                    <div className="blank-div"></div>

                                    <OTStreams
                                        onError={err => {
                                            console.log(err);
                                        }}
                                        onConnect={(res, r) => {
                                            console.log(res, r);
                                        }}
                                    >
                                        <OTSubscriber
                                            properties={this.subscriberProperties}
                                            eventHandlers={this.subscriberEventHandlers}
                                            onError={err => {
                                                console.log(err);
                                            }}
                                            onConnect={(res, r) => {
                                                console.log(res, r);
                                            }}
                                            ref={this.otSubscriber}
                                        />
                                    </OTStreams>

                                    <OTPublisher
                                        properties={{
                                            showControls: false,
                                            facingMode: cameraPosition,
                                            mirror: false
                                        }}
                                        className="custom-video"
                                        ref={this.otPublisher}
                                    />

                                    <div className="live-comment-setion">
                                        <BookingComments bookingId={this.state.bookingId} name={this.state.userName} match={this.props.match} />
                                        <div className="call-controls d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center flex-grow-1">
                                                <BookingSubmitComment bookingId={this.state.bookingId} name={this.state.userName} match={this.props.match} />
                                            </div>


                                            <div className="flex-fill justify-content-end">
                                                <ul className="d-flex justify-content-center align-items-center cntroll-call-btns">
                                                    <li>
                                                        <btn onClick={this.switchCamera}>
                                                            {speaker && <i className="fa fa-camera"></i>}
                                                            {!speaker && <i className="fa fa-camera"></i>}
                                                        </btn>
                                                    </li>
                                                    <li>
                                                        <btn onClick={this.toggleSpeaker}>
                                                            {speaker && <i class="fa fa-volume-up"></i>}
                                                            {!speaker && <i class="fa fa-volume-off"></i>}
                                                        </btn>
                                                    </li>
                                                    <li className="end-call-btn">
                                                        <btn onClick={() => this.setState({ startStream: false })} >
                                                            <i class="fa fa-phone"></i>
                                                        </btn>
                                                    </li>
                                                    <li>
                                                        <btn onClick={this.toggleMic}>
                                                            {mic && <i class="fa fa-microphone"></i>}
                                                            {!mic && <i class="fa fa-microphone-slash"></i>}
                                                        </btn>
                                                    </li>
                                                </ul>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </OTSession>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default LiveVistStream