import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { drawCustomBorderRadius, drawWhiteLine } from "utils/Functions";
import vector from "../../assets/icons/vector.svg";

import styles from "../Analytics/Analytic.module.css";

const AverageQualityChart = ({ offers, verified }) => {

  const [info, setInfo] = useState({});
  const [legendFilter, setLegendFilter] = useState([]);

  const datasetLabels = ["Accepted", "Not Accepted", "Withdrawn"];

  const chartRef = useRef(null);
  useEffect(() => {
    const chartInstance = chartRef.current?.chartInstance;
    if (chartInstance) {
      chartInstance.generateLegend();
    }
  }, []);

  const drawCustomLabels = (chart) => {
    drawWhiteLine(chart);
    const ctx = chart.ctx;
    const labelTotals = {};
    const labels = chart.data.labels;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;
      dataset.data.forEach((value, index) => {
        const label = labels[index];
        labelTotals[label] = (labelTotals[label] || 0) + value;
      });
      if (!meta.hidden && datasetIndex == 2)
        elements.forEach((element, elementIndex) => {
          drawCustomBorderRadius(element, ctx);

          const label = labels[elementIndex];
          const total = labelTotals[label];
          ctx.save();
          ctx.textBaseline = "middle";
          ctx.fillStyle = "black";
          ctx.font = "bold 14px Arial";
          const labelX = element._view.x;
          const labelY = element._view.y;
          const val = (total < 1000 ? total : total / 1000).toString();
          ctx.fillText(total, labelX - val.length * 4, labelY - 10);
          ctx.restore();
        });
    });
  };

  const plugin = {
    id: "increase-legend-spacing",
    beforeInit(chart) {
      const originalFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 20;
      };
    },
  };

  useEffect(() => {
    const filteredOffers = {};
    if (offers) {
      for (let i = 0; i < offers.length; i++) {
        const singleOffer = offers[i];
        if (filteredOffers[singleOffer.brokerageName]) {
          if (
            singleOffer.status === "Accepted" ||
            singleOffer.status === "Executed"
          ) {
            filteredOffers[singleOffer.brokerageName]["Accepted"]++;
          } else if (singleOffer.status === "Not Accepted") {
            filteredOffers[singleOffer.brokerageName]["Not Accepted"]++;
          } else if (singleOffer.status === "Withdraw") {
            filteredOffers[singleOffer.brokerageName]["Withdrawn"]++;
          }
        } else {
          if (
            singleOffer.status === "Accepted" ||
            singleOffer.status === "Executed"
          ) {
            filteredOffers[singleOffer.brokerageName] = {
              "Not Accepted": 0,
              Accepted: 1,
              Withdrawn: 0,
            };
          } else if (singleOffer.status === "Not Accepted") {
            filteredOffers[singleOffer.brokerageName] = {
              "Not Accepted": 1,
              Accepted: 0,
              Withdrawn: 0,
            };
          } else if(singleOffer.status === "Withdraw"){
            filteredOffers[singleOffer.brokerageName] = {
              "Not Accepted": 0,
              Accepted: 0,
              Withdrawn: 1,
            };
          }
        }
      }

      if (legendFilter?.length > 0) {
        for (let i = 0; i < legendFilter.length; i++) {
          for (let j = 0; j < Object.entries(filteredOffers).length; j++) {
            Object.entries(filteredOffers)[j][1][legendFilter[i]] = 0;
          }
        }
      }

      // sort filtered offers alphabetically
      const sortedFilteredOffers = {};
      Object.keys(filteredOffers)
        .sort()
        .forEach((key) => {
          sortedFilteredOffers[key] = filteredOffers[key];
        });

      setInfo(sortedFilteredOffers);
    }
  }, [offers, legendFilter]);

  const labels = Object.keys(info);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        background: "#FFFFFF",
        flexWrap: "wrap",
      }}
    >
      <div
        className={styles.labels}
        style={{
          marginBottom: "20px",
          marginLeft: "6px",
          width: "100%",
        }}
      >
        {datasetLabels.map((label) => (
          <React.Fragment key={label}>
            <span
              className={styles.circle}
              onClick={() => {
                if (legendFilter.includes(label)) {
                  setLegendFilter(legendFilter.filter((e) => e !== label));
                } else {
                  setLegendFilter([...legendFilter, label]);
                }
              }}
            ></span>
            <span
              className={
                legendFilter.includes(label)
                  ? styles["text-decoration-line-through"]
                  : ""
              }
            >
              {label}
            </span>
          </React.Fragment>
        ))}
      </div>
      <div
        className="chartWrapper-scroll"
        style={{
          width: "76%",
          overflowX: "auto",
        }}
      >
        <div
          style={{
            width: `${labels.length * 80}px`,
            minWidth: "100%",
            height: "250px",
            paddingLeft: "10px",
            position: "relative",
          }}
        >
          {offers?.length === 0 ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <span>There is no data...</span>
            </div>
          ) : (
            <Bar
              ref={chartRef}
              height="0"
              options={{
                legend: {
                  display: false,
                  position: "top",
                  align: "start",
                  labels: {
                    usePointStyle: true,
                    pointStyle: "circle",
                    padding: 20,
                  },
                },
                plugins: {
                  title: {
                    display: true,
                    text: "Chart.js Bar Chart - Stacked",
                  },
                },
                responsive: true,
                interaction: {
                  mode: "index",
                  intersect: false,
                },
                scales: {
                  yAxes: [
                    {
                      gridLines: {
                        borderDash: [10, 5],
                        lineWidth: 2,
                        borderDashOffset: 20,
                        color: "rgba(235, 235, 235, 1)",
                        drawBorder: false,
                        drawTicks: false,
                      },
                      ticks: {
                        beginAtZero: true,
                        crossAlign: "start",
                        padding: 15,
                        suggestedMin: 0,
                        suggestedMax: 8,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      offsetGridLines: true,
                      gridLines: {
                        display: true,
                        color: "#fff",
                        drawBorder: false,
                        drawTicks: false,
                        zeroLineWidth: 0.5,
                        drawOnChartArea: function (context) {
                          return context.tick.index === 0;
                        },
                      },
                      ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                        fontSize: 10,
                        padding: 5,
                        callback: (value) => {
                          if (value.length > 10) {
                            return value.slice(0, 10) + "...";
                          }
                          return value;
                        },
                      },
                    },
                  ],
                },
              }}
              data={{
                labels,
                datasets: [
                  {
                    label: "Accepted",
                    data: Object.values(info).map((el) => el["Accepted"]),
                    backgroundColor: "#28A745",
                    stack: 1,
                    barThickness: 32,
                    hitRadius: 10,
                  },
                  {
                    label: "Not Accepted",
                    data: Object.values(info).map((el) => el["Not Accepted"]),
                    backgroundColor: "#EB3E54",
                    stack: 1,
                    barThickness: 32,
                  },
                  {
                    label: "Withdrawn",
                    data: Object.values(info).map((el) => el["Withdrawn"]),
                    backgroundColor: "#FFC107",
                    stack: 1,
                    barThickness: 32,
                  },
                ],
              }}
              plugins={[
                plugin,
                {
                  id: "customLabels",
                  afterDatasetsDraw: (chart) => drawCustomLabels(chart),
                },
              ]}
            />
          )}
        </div>
      </div>
      <div className={`d-none d-md-block mt-4 mt-md-0 mx-auto ${styles.verifyOffers}`}>
        <div className={styles.minWrap}>
          <img src={vector} className={styles.vector} />
          You have{" "}
          <span style={{ color: "#109CF1" }}>
            {" "}
            {verified.unVerifiedCount} unverified
          </span>{" "}
          offers. Please click the button below to verify each offer.
        </div>
        <div>
          <Link
            to="/offers"
            style={{
              color: "#FFFFFF",
            }}
          >
            <button
              style={{
                backgroundColor: "#109CF1",
                color: "#FFFFFF",
                border: "none",
                borderRadius: "4px",
                fontSize: "12px",
                padding: "3px 41px",
              }}
            >
              Verify Offers
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AverageQualityChart;
