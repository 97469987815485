import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import firebase from "firebase/app";
import "firebase/firestore";
import "../assets/css/analytics-page.css";
import "../assets/css/analytics-mobile.css";
import { getAllOffers } from "./../Services/SellerService";
import { getUsersSellers } from "../Services/SellerService";
import Analytic from "components/Analytics/Analytic";

function Analytics({ match, user, history }) {
  const [offers, setOffers] = useState([]);
  const [allOffers, setAllOffers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [switchAanlytics, setSwitchAnalytics] = useState(match.params.uid);
  const [agent, setAgent] = useState(null);
  const [totalUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(0);
  const [mobile, setMobile] = useState(false);
  const [allClosedGlobalSellers, setAllClosedGlobalSellers] = useState([]);

  useEffect(() => {
    // Check if the window width is less than 768 during initial render
    setMobile(window.innerWidth < 576);

    const handleResize = () => {
      setMobile(window.innerWidth < 576);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (user.broker_license) {
      const db = firebase.firestore();
      db.collection("realtors")
        .where("broker_license", "==", user.broker_license)
        .get()
        .then((user) => {
          setAllUsers(user.docs);
        });
    }

    const db = firebase.firestore();
    db.collection("sellers")
      .where("MlsStatus", "==", "Closed")
      .get()
      .then((sellers) => {
        sellers = sellers.docs.map((seller) => {
          return {
            id: seller?.id,
            ...seller.data(),
          };
        });
        setAllClosedGlobalSellers(sellers);
      });

    getAllData();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  const getAllData = async () => {
    let otherUser = null;

    if (match.params?.uid && match.params?.uid !== "all") {
      const db = firebase.firestore();
      otherUser = await db.collection("realtors").doc(match.params.uid).get();
      const userData = otherUser.data();
      otherUser = {
        ...userData,
        email: userData.realtor_email,
        uid: otherUser.id,
      };

      setAgent(otherUser);
      setLoading(false);
    } else {
      setLoading(false);
    }

    const userData = otherUser || user;

    const db = firebase.firestore();
    let query = db.collection("offers");

    // if (location.search.indexOf("status") > 0)
    // query = db
    //   .collection("offers")
    // .where("status", "==", location.search.split("=")[1]);
    // const isBuyer = user.uid === this.props.location.state?.offer?.sender;
    // this.setState({ isBuyer });

    const promises = [
      query
        .where("search", "array-contains", user?.email)
        .orderBy("createdAt", "desc")
        .get(),
      query
        .where("search", "array-contains", user?.uid)
        .orderBy("createdAt", "desc")
        .get(),
      query.where("sender", "==", user?.uid).orderBy("createdAt", "desc").get(),
    ];

    const snaps = await Promise.all(promises);
    const docs = {};
    [snaps[0], snaps[1]].forEach((s) => {
      s.docs.forEach((d) => {
        docs[d?.id] = {
          id: d?.id,
          ...d.data(),
        };
      });
    });

    const snap = snaps[2];
    const sentOffers = snap.docs.map((d) => ({ id: d?.id, ...d.data() }));
    const receivedOffers = Object.keys(docs).map((key) => docs[key]);
    const offers = [...new Set([...sentOffers, ...receivedOffers])];

    setOffers(receivedOffers);
    // setAllOffers(offers);

    getAllOffers(userData, null, {
      allData: match.params?.uid === "all",
    }).then((offers) => {
      const myOffers = [];
      const allOffers = [];
      for (let i = 0; i < offers.length; i++) {
        const element = offers[i];
        if (user.uid === element.seller_id) {
          myOffers.push(element);
        } else {
          allOffers.push(element);
        }
      }
      setAllOffers(allOffers);
      // setOffers(myOffers);
    });

    let sellers = await getUsersSellers(user);
    sellers = sellers.map((seller) => {
      return {
        id: seller?.id,
        ...seller.data(),
      };
    });

    setSellers(sellers);
  };

  if (loading)
    return (
      <div
        style={{
          marginTop: 30,
          textAlign: "center",
        }}
      >
        Loading Data...
      </div>
    );

  return (
    <div className="dashboard-main">
      {/* <div className="dashboard-content"> */}
      {/* <div className="container"> */}
      <Analytic
        mobile={mobile}
        offersData={offers}
        allOffersData={allOffers}
        sellers={sellers}
        match={match}
        agent={agent}
        user={user}
        width={width}
        allClosedGlobalSellers={allClosedGlobalSellers}
      />
      {["Admin", "Owner"].includes(user.authRole) && (
        <Row>
          <Col className="analtics-space-between">
            {switchAanlytics ? (
              <div>
                <button
                  className="analytics-price-dropdown-btn"
                  type="button"
                  onClick={() => {
                    setSwitchAnalytics(!switchAanlytics);
                    history.push("/analytics");
                  }}
                >
                  <div className="analytics-row center-me">
                    <div className="green-circle"></div>
                    <p className="analytics-input-label font-15">
                      My analytics
                    </p>
                  </div>
                </button>
              </div>
            ) : (
              <div>
                {/* <button className="analytics-price-dropdown-btn" type="button" onClick={() => history.push('/analytics/all')}>
                  <div className="analytics-row center-me">
                    <div className="green-circle"></div>
                    <p className="analytics-input-label font-15">All Users Analytics</p>
                  </div>
                </button> */}
              </div>
            )}
            <div className="d-inline-block">
              <div>
                <button
                  className="analytics-price-dropdown-btn"
                  type="button"
                  id="offerStatusFilter"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="analytics-row center-me">
                    {/* {agent?.realtor_name && '(' + agent?.realtor_name + ')'} &nbsp;
                    <div className="green-circle"></div>
                    <p className="analytics-input-label font-15">Get Analytics by user</p>
                    <img alt="ArrowDown" src={ArrowDown} className="down-arrow" /> */}
                  </div>
                </button>
                <div
                  className="custom-users-filter-list dropdown-menu"
                  aria-labelledby="listingPriceFilter"
                >
                  <div className="analtics-space-between padd-6">
                    <input
                      type="text"
                      className="analytics-search-input"
                      placeholder="Search user"
                    />
                  </div>
                  {totalUsers.map((u) => {
                    const user = u.data();
                    return (
                      <a
                        href={`/analytics/${u.id}`}
                        key={u.id}
                        className="analytics-price-dropdown-inner-btn"
                      >
                        <p>{user.realtor_name}</p>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}

export default Analytics;
