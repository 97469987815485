import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import MatchDetails from "components/MatchDetails";
import VisibilitySensor from "react-visibility-sensor";
import { Label, Input } from 'reactstrap';

class MatchUpdateSeller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      isChecked: this.props.isChecked,
      notIntrested: this.props.match.notIntrested || [],
      userUpdated: this.props.user,
      ownUpdate: false
    };
  }

  toHex(d) {
    return ("0" + Number(d).toString(16)).slice(-2).toUpperCase();
  }

  favoriteButton(id) {
    const { user } = this.props;
    const favorites = user.favorites || [];

    if (favorites.indexOf(id) > -1) {
      return (
        <button
          style={{
            cursor: "pointer",
            backgroundColor: "#ff214f",
            color: "white",
            fontWeight: 600,
            border: "1px solid #ff214f",
            fontSize: 12,
            borderRadius: 16,
            padding: 6,
            paddingRight: 15,
            paddingLeft: 15,
          }}
          onClick={(e) => {
            e.preventDefault();
            this.removeFromFavorites(id);
          }}
        >
          <i className="flaticon-heart" />
          &nbsp;&nbsp;Remove from favorites
        </button>
      );
    } else {
      return (
        <button
          style={{
            cursor: "pointer",
            color: "#ff214f",
            border: "1px solid #ff214f",
            fontSize: 12,
            borderRadius: 16,
            padding: 6,
            paddingRight: 15,
            paddingLeft: 15,
          }}
          onClick={(e) => {
            e.preventDefault();
            this.addToFavorites(id);
          }}
        >
          <i className="flaticon-heart" />
          &nbsp;&nbsp;Add to Favorites
        </button>
      );
    }
  }

  interestButton(id) {
    const notIntrested = this.state.notIntrested || [];
    const { role } = this.props.user || {};
    if (role && role === "lender") {
      return;
    }

    if (!notIntrested.includes(id)) {
      return (
        <button
          style={{
            cursor: "pointer",
            color: "#ff214f",
            border: "1px solid red",
            fontSize: 12,
            borderRadius: 16,
            padding: 6,
            paddingRight: 15,
            paddingLeft: 15,
          }}
          onClick={(e) => {
            e.preventDefault();
            this.notInterestedMatch(id);
          }}
        >
          &nbsp;&nbsp;Not interested
        </button>
      );
    } else {
      return (
        <button
          style={{
            cursor: "pointer",
            color: "#ff214f",
            border: "1px solid red",
            fontSize: 12,
            borderRadius: 16,
            padding: 6,
            paddingRight: 15,
            paddingLeft: 15,
          }}
          onClick={(e) => {
            e.preventDefault();
            this.undoInterestedMatch(id);
          }}
        >
          &nbsp;&nbsp;Undo
        </button>
      );
    }
  }

  addToFavorites(id) {
    const { user } = this.props;
    let favorites = user.favorites || [];
    const index = favorites.indexOf(id);

    if (index === -1) {
      favorites.push(id);
      this.props.functions
        .updateFields("users", user.uid, { favorites: favorites })
        .then((d) => {
          user.favorites = favorites;
          this.props.functions.userUpdated(user);
        });
    }
  }
  // Function call to undo not interested marked matches
  undoInterestedMatch(id) {
    alert("TODO");
  }
  // Function call on click of not interested for a match
  async notInterestedMatch(id) {
    const { user, decreaseMatchCount, collection } = this.props;

    const db = firebase.firestore();
    const matchRef = db.collection(collection || 'matches').doc(id);
    let { search, notIntrested } = (await matchRef.get()).data();

    search = search.filter((s) => s !== user.uid);
    notIntrested = notIntrested || [];
    notIntrested = notIntrested.filter((n) => n !== user.uid);
    notIntrested = [...notIntrested, user.uid];

    matchRef.set(
      {
        search,
        notIntrested,
      },
      {
        merge: true,
      }
    );

    if (decreaseMatchCount) {
      decreaseMatchCount();
    }

    this.setState({ notIntrested });
  }

  removeFromFavorites(id) {
    const { user } = this.props;
    let favorites = user.favorites || [];
    const index = favorites.indexOf(id);

    if (index > -1) {
      favorites.splice(index, 1);
      this.props.functions
        .updateFields("users", user.uid, { favorites: favorites })
        .then((d) => {
          user.favorites = favorites;
          this.props.functions.userUpdated(user);
        });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    const { match, onBuyerImageChange, collection } = this.props;

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const buyerImageRef = storageRef.child(`images/users/${match.buyerId}.jpg`);

    if(collection || 'matches'){
      const buyerTwoImageRef = storageRef.child(`images/users/${match.buyerIdTwo}.jpg`);
      
      buyerTwoImageRef
      .getDownloadURL()
      .then((url) => {
        console.log('buyerTwoImageRef',url);
        if (this.mounted) {
          if(onBuyerImageChange){
            onBuyerImageChange(match.buyerIdTwo,url)
          }
          this.setState({ buyerTwoImage: url });
        }
      })
      .catch((error) => {
      });

    }

    this.mounted = true;

    buyerImageRef
      .getDownloadURL()
      .then((url) => {
        if (this.mounted) {
          if(onBuyerImageChange){
            onBuyerImageChange(match.buyerId,url)
          }
          this.setState({ buyerImage: url });
        }
      })
      .catch((error) => {});
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextPropData = JSON.stringify({
      isChecked: nextProps.isChecked,
      match: nextProps.match,
    });
    
    const prevStateData = JSON.stringify({
      isChecked: prevState.isChecked,
      match: prevState.match,
    });
    
   if (nextPropData !== prevStateData) {
      return {
          isChecked: nextProps.isChecked,
          match: nextProps.match,
          notIntrested: nextProps.match.notIntrested,
      };
  }
    return null;
  }


  render() {
    const { match, view, user, collection } = this.props;
    const { sellerImage } = match;
    const { buyerImage,buyerTwoImage, notIntrested, isChecked } = this.state;

    const {
      id,
      buyerId,
      buyerIdTwo,
      sellerId,
      buyerName,
      buyerNameTwo,
      sellerAddress,
      parameters,
      imagesParameters,
    } = match;
    let { score } = match;

    let sellerImageMarkup = null;
    let buyerImageMarkup = null;

    if (sellerImage || buyerTwoImage) {
      sellerImageMarkup = (
        <div
          style={{
            marginBottom: 10,
            height: 200,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage: `url('${sellerImage || buyerTwoImage}')`,
          }}
        />
      );
    } else {
      if (view === "email") {
        sellerImageMarkup = (
          <div style={{ height: 200, backgroundColor: "#eee" }}></div>
        );
      } else {
        sellerImageMarkup = (
          <div>
            <p
              style={{
                padding: 50,
                fontSize: 96,
                backgroundColor: "#eee",
                color: "#999",
                textAlign: "center",
              }}
            >
              <i className={(collection === 'matches') ? 'fa fa-home': 'fa fa-user'} />
            </p>
          </div>
        );
      }
    }

    if (buyerImage) {
      buyerImageMarkup = (
        <div
          style={{
            height: 200,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage: `url('${buyerImage}')`,
          }}
        />
      );
    } else {
      if (view === "email") {
        buyerImageMarkup = (
          <div style={{ height: 200, backgroundColor: "#eee" }}></div>
        );
      } else {
        buyerImageMarkup = (
          <p
            style={{
              padding: 50,
              fontSize: 100,
              backgroundColor: "#eee",
              color: "#999",
              textAlign: "center",
            }}
          >
            <i className="fa fa-user" />
          </p>
        );
      }
    }

    const max = 180;
    let red = 0;
    let green = 0;
    let blue = 0;

    if (score >= 7.6 && score <= 10) {
      green = max;
    } else if (score >= 5.1 && score < 7.6) {
      green = 220;
      red = 220;
    } else if (score >= 2.6 && score < 5.1) {
      green = 113;
      red = max;
    } else {
      red = max;
    }

    let color = `#${this.toHex(red)}${this.toHex(green)}${this.toHex(blue)}`;

    let images = (
      <div className="row">
        <div className="offset-sm-1 col-sm-4">{buyerImageMarkup}</div>
        <div className="col-sm-1">
          <p style={{ textAlign: "center", fontSize: 24, padding: "88px 0" }}>
            <i className="fa fa-arrows-h"></i>
          </p>
        </div>
        <div className="col-sm-4">{sellerImageMarkup}</div>
      </div>
    );

    if (view === "email") {
      images = (
        <table style={{ width: "100%" }}>
          <tr>
            <td style={{ width: "50%" }}>{buyerImageMarkup}</td>
            <td style={{ width: "50%" }}>{sellerImageMarkup}</td>
          </tr>
        </table>
      );
    }

    if ((notIntrested || []).includes(user.uid)) {
      return <React.Fragment />;
    }

    return (
      <React.Fragment>
        <div className="comment">
          <div className="row">
            <div className="col-sm-12" style={{marginLeft: "20px",marginBottom: "20px"}}>
              <Label check >
                      <Input checked={isChecked} style={{transform: 'scale(1.5)'}} type="checkbox" id={`checked-${id}`} onChange={({target})=>{
                        if(target.checked){
                          if(this.props.addID){
                            this.props.addID(id);
                          }
                        }else{
                          if(this.props.removeID){
                            this.props.removeID(id);
                          }
                        }
                      }}/> Select
              </Label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {images}
              <div className="comment-meta">
                <div className="matchActionBtnGroup">
                  {view !== "email" ? (
                    <div style={{ float: "right" }}>
                      {this.favoriteButton(id)}
                    </div>
                  ) : null}
                  <div style={{ float: "right" }}>
                    {this.interestButton(id)}
                  </div>
                </div>
                <h5 style={{ fontSize: 15 }}>
                  Buyer&nbsp;
                  <Link to={`/buyers/${buyerId}`}>
                    <span style={{ color: "#ff214f" }}>{buyerName}</span>
                  </Link>
                  &nbsp; matched with&nbsp;
                  <Link to={(sellerId) ? `/sellers/${sellerId}`: `/buyers/${buyerIdTwo}`}>
                    <span style={{ color: "#ff214f" }}>{sellerAddress || buyerNameTwo}</span>
                  </Link>
                </h5>
              </div>
              <div style={{ paddingBottom: " 10px" }} className="match-score">
                <span style={{ fontSize: " 13px" }}>MATCH SCORE&nbsp;</span>
                <div>
                  <ProgressRing
                    view={view}
                    text={Math.round(10 * score) / 10}
                    radius={30}
                    stroke={4}
                    color={color}
                    progress={score * 10}
                  />
                </div>
              </div>

              {imagesParameters && imagesParameters.length > 0 && (
                <>
                  <span style={{ fontSize: " 13px" }}>
                    MATCHED IMAGES DETAILS
                  </span>
                  <MatchDetails
                    view={view}
                    type="image"
                    items={imagesParameters}
                  />
                  <br />
                </>
              )}

              {parameters && parameters.length > 0 && (
                <>
                  <span style={{ fontSize: " 13px", marginTop: "200px" }}>
                    MATCH DETAILS
                  </span>
                  <MatchDetails view={view} type="data" items={parameters} />
                </>
              )}

            </div>
          </div>
          <hr></hr>
        </div>
      </React.Fragment>
    );
  }
}

export default MatchUpdateSeller;

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;

    this.state = {
      progress: 0,
    };
  }

  handleVisibilityChange(visible) {
    if (visible) {
      setTimeout(() => {
        this.setState({ progress: this.props.progress });
      }, 100);
    }
  }

  render() {
    const { radius, stroke, text, color, view } = this.props;
    const { progress } = this.state;

    const strokeDashoffset =
      this.circumference - (progress / 100) * this.circumference;
    const bar = (
      <React.Fragment>
        <svg height={radius * 2} width={radius * 2}>
          <circle
            stroke={color}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={this.circumference + " " + this.circumference}
            style={{
              strokeDashoffset,
              transition: "stroke-dashoffset 0.35s",
              transform: "rotate(-90deg)",
              transformOrigin: "50% 50%",
            }}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <text
            style={{ fill: color, fontWeight: "bold" }}
            x="30"
            y="35"
            textAnchor="middle"
          >
            {text}
          </text>
        </svg>
      </React.Fragment>
    );

    if (view !== "email") {
      return (
        <VisibilitySensor onChange={this.handleVisibilityChange.bind(this)}>
          {bar}
        </VisibilitySensor>
      );
    } else {
      return bar;
    }
  }
}
