import React, {Component} from "react";
import {
  Col,
  Row,
  ListGroup, ListGroupItem,
  FormGroup,
  Button,
  Progress
} from 'reactstrap';

import './CreateMedia.css';

import firebase from "components/Firebase/firebase";
import "firebase/firestore";

var storageRef = firebase.storage().ref();

class CreateMedia extends Component {

  state = {
    images: [],
  };

  onChange = async e => {

    let files = Object.values(e.target.files);

    this.setState({
      images:files.map(file => {
        const path = URL.createObjectURL(file);
        return {file,path,progress:0};
      })
    });

    const {uid} = this.props.user;

    (files || []).forEach((file,i) => {
      const id = Math.random().toString(36).substring(7);
      const fileRef = storageRef.child(`postsMedia/${uid}/${id}_${file.name}`);

      let progress  = 0;

      fileRef.put(file).on('state_changed', (snapshot) => {
        progress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        let constFiles = this.state.images.map((v,index) => (i === index)?{...v,progress}:{...v});
        this.setState({
          images:constFiles
        })
      });
      
    });
    
  };

  renderProgress = f => {

    const { progress } = f || {};
    const style = {top:'50%', position: 'relative',marginTop: '-10px',};
    if(f.progress === 100){
      return <p style={style}>Uploaded Successfully.</p>
    }
    return <Progress style={style} animated color="success" value={progress}>{`${progress} %`}</Progress>
  };

  inputClick = () => {
    this.fInput.click();
  };

  render(){
    const {images} = this.state;
    const {upBtnSize} = this.props;

    const style = {margin:'2px',padding:'5px'};

    return (
      <React.Fragment>
        <FormGroup>
          <Row className='uploadArea' onClick={this.inputClick}>
            <input style={{display:'none'}} type="file" onChange={this.onChange} multiple ref={fInput => this.fInput = fInput}/>
            <Col  sm={{ size: 4, offset: 4 }} className="text-center">
              <Button type="button" block color="success" size={upBtnSize}  className="btn-pill mt-3"> <i className="fa fa-upload"/> Upload</Button>
            </Col>
          </Row>
        </FormGroup>

        <ListGroup >
         
          <div style={{overflowY:'scroll',overflowX:'hidden',...this.props.style}}>
            {images.map((f,i) => {

              return (
                <ListGroupItem style={style} key={i}>
                  <Row>
                    <Col xs="4">
                      <img className="img img-responsive" style={{width:'100%'}} src={f.path} alt="Item" />
                    </Col>
                    <Col xs="8">
                      {this.renderProgress(f)}
                    </Col>
                  </Row>
                </ListGroupItem>
              )
            })}
          </div>
        </ListGroup>

      </React.Fragment>
    );
  }
}

export default CreateMedia;
