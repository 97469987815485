import React, { useEffect, useState } from "react";
import firebase from "components/Firebase/firebase";
import { Link, withRouter } from "react-router-dom";
import AveragePropertiesOfferTerms from "components/Analytics/AveragePropertiesOfferTerms";
import OfferMapLocation from "components/Analytics/OfferMapLocation";
import OldOfferMapLocation from "components/Analytics/OldOfferMapLocation";
import OfferPriceListPrice from "components/Analytics/OfferPriceListPrice";
import OffersTable from "components/Analytics/OffersTable";
import LineChart from "components/Charts/LineChart";
import TotalOverListingsChart from "components/Charts/TotalOverListingsChart";
import TotalOverTimeChart from "components/Charts/TotalOverTimeChart";
import * as PropTypes from "prop-types";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  Col,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import line from "../../assets/icons/line.png";
import AverageDetailsChart from "../Charts/AverageDetailsChart";
import AverageQualityChart from "../Charts/AverageQualityChart";
import AverageSummaryChart from "../Charts/AverageSummaryChart";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import styles from "./Analytic.module.css";
import GlobalAverage from "./GlobalData/GlobalAverage";
import GlobalOffers from "./GlobalData/GlobalOffers";
import GlobalPriceRatio from "./GlobalData/GlobalPriceRatio";
import GlobalLocation from "./GlobalData/Location/GlobalLocation";
import InfoCard from "./infoCard/InfoCard";
import LocationDetails from "./Location/LocationDetails";
import LocationSummary from "./Location/LocationSummary";
import Offers from "./Offers/Offers.js";
import RatioDetails from "./PriceRatio/RatioDetails";
import RatioSummary from "./PriceRatio/RatioSummary";
import { stateCodeMap, stateMapping } from "./info";
import CommonCloseButton from "components/commonCloseButton/CommonCloseButton";
import resize from "../../assets/icons/resize.svg";
import filter from "../../assets/icons/Filter.png";
import threedots from "../../assets/icons/three-dots.png";
import arrowdown from "../../assets/icons/arrow-down-filters-mobile.png";
import backButton from "../../assets/icons/back_button.png";
import { set } from "lodash";
import PropertyListingViewsChart from "./PropertyListingViewsChart";
import Joyride from "react-joyride";
import firebaseApp from "firebase/app";

const priceOptions = [
  { value: 50000, label: "Less than $50,000" },
  { value: 100000, label: "$50,000 - $150,000" },
  { value: 150000, label: "$100,000 - $150,000" },
  { value: 200000, label: "$150,000 - $200,000" },
  { value: 250000, label: "$200,000 - $250,000" },
  { value: 300000, label: "$250,000 - $300,000" },
  { value: 350000, label: "$300,000 - $350,000" },
  { value: 400000, label: "$350,000 - $400,000" },
  { value: 450000, label: "$400,000 - $450,000" },
  { value: 500000, label: "$450,000 - $500,000" },
  { value: "max", label: "More than $500,000" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  index: PropTypes.number,
  children: PropTypes.node,
};

const Analytic = ({
  mobile,
  offersData,
  allOffersData,
  sellers,
  match,
  agent,
  user,
  width,
  history,
  location,
  allClosedGlobalSellers,
}) => {
  const [pathQueries, setPathqueries] = useState({});

  const queryParams = new URLSearchParams(location.search);
  const paramTab = queryParams.get("analyticsTab");
  const paramData = queryParams.get("data");
  const paramBrokarage = queryParams.get("brokarage");
  const paramLoction = queryParams.get("location");
  const paramCity = queryParams.get("city");
  const paramPrice = queryParams.get("price");
  const paramDate = queryParams.get("date");
  const paramInfo = queryParams.get("info");
  const [legendFilter, setLegendFilter] = useState([]);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [showGlobalDataModal, setShowGlobalDataModal] = useState(false);
  const [showTippingInfoModal, setShowTippingInfoModal] = useState(true);
  const [joyRide1, setJoyRide1] = useState(
    user?.hasOwnProperty("analyticJoyRide") ? user?.analyticJoyRide : true
  );
  const [globalAgents, setGlobalAgents] = useState([]);

  const datasetLabels = ["Average homes price", "Average offers price"];

  const setQueryParam = (paramName, paramValue) => {
    const queryParams = new URLSearchParams(location.search);
    const newPaths = {
      ...pathQueries,
      [paramName]: paramValue,
    };
    const pathArray = Object.entries(newPaths);
    for (let i = 0; i < pathArray.length; i++) {
      const name = pathArray[i][0];
      const value = pathArray[i][1];
      if (value.length || typeof value === "number") {
        queryParams.set(name, value);
      } else {
        queryParams.delete(name);
        delete newPaths[name];
      }
    }
    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    setPathqueries(newPaths);
  };

  const [value, setValue] = useState(Number(paramTab) || 0);
  const [info, setInfo] = useState(paramInfo || "SUMMARY");
  const [globalData, setGlobalData] = useState(
    paramData != "global-data" ? false : true
  );
  const [accepted, setAccepted] = useState(0);
  const [notAccepted, setNotAccepted] = useState(0);
  const [withdrawn, setWithdrawn] = useState(0);
  const [verified, setVerified] = useState(0);
  const [acceptedData, setAcceptedData] = useState([]);
  const [notAcceptedData, setNotAcceptedData] = useState([]);
  const [withdrawnData, setWithdrawnData] = useState([]);
  const [brockerageInfo, setBrockerageInfo] = useState({});
  const [allBrockerageInfo, setAllBrockerageInfo] = useState({});
  const [unfilteredOffersCoord, setUnfilteredOffersCoord] = useState([]);
  const [allUnfilteredOffersCoord, setAllUnfilteredOffersCoord] = useState([]);
  const [dateValue, setDateValue] = useState(paramDate?.split(",") || "");
  const [selectLocation, setSelectLocation] = useState(
    paramLoction?.split(",") || []
  );
  const [selectLocationCity, setSelectLocationCity] = useState(
    paramCity?.split(",") || []
  );
  const [locations, setLocations] = useState({});
  const [totalValues, setTotalValues] = useState();
  const [locationsOffers, setLocationsOffers] = useState({});
  const [offers, setOffers] = useState(offersData);
  const [allOffers, setAllOffers] = useState(allOffersData);
  const [priceFilter, setPriceFilter] = useState(
    paramPrice?.split("%3A").map((el) => (el == "max" ? el : Number(el))) || []
  );

  const [priceFilterLabel, setPriceFilterLabel] = useState([]);
  const [allBrokarage, setAllBrokarage] = useState({});
  const [selectedBrokarage, setSelectedBrokarage] = useState(
    paramBrokarage?.split(",") || []
  );
  // const [mobile, setMobile] = useState(false);
  // useEffect(() => {
  //   if (window.innerWidth < 576) {
  //     setMobile(true);
  //   } else {
  //     setMobile(false);
  //   }
  // }, [window.innerWidth, window]);

  useEffect(() => {
    getGlobalAgents();
  }, [user]);

  const getGlobalAgents = () => {
    const db = firebaseApp.firestore();
    db.collection("users")
      .doc(user?.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setGlobalAgents(doc.data().myGlobalAgents);
        }
      });
  };

  console.log("globalAgents", globalAgents);


  useEffect(() => {
    if (allOffersData && allOffersData.length) {
      setAllOffers(allOffersData);
    }
    if (offersData && offersData.length) {
      setOffers(offersData);
      setAllOffers(allOffersData);
    }
  }, [allOffersData, offersData]);

  const [steps] = useState([
    {
      target: "#step1",
      content: (
        <>
          <h5>My Data</h5>
          <p>Here you can see detailed analytics of your offers.</p>
        </>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: "#step2",
      content: (
        <>
          <h5>Global Data</h5>
          <p>Here you can see detailed analytics of all offers globally.</p>
        </>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: "#controlled-tab-example-tab-0",
      content: (
        <>
          <h5>Offers</h5>
          <p>
            Here you can see all your offers. You can filter them by date,
            location, price, brokerage, and status. You can also see the map of
            your offers and the list of all offers.
          </p>
        </>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: "#controlled-tab-example-tab-1",
      content: (
        <>
          <h5>Average Property Quality</h5>
          <p>
            Here you can see the average property quality of your offers. You
            can filter them by date, location, price, brokerage, and status. You
            can also see the map of your offers and the list of all offers.
          </p>
        </>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: "#controlled-tab-example-tab-2",
      content: (
        <>
          <h5>Location</h5>
          <p>
            Here you can see the location of your offers. You can filter them by
            date, location, price, brokerage, and status. You can also see the
            map of your offers and the list of all offers.
          </p>
        </>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: "#controlled-tab-example-tab-3",
      content: (
        <>
          <h5>Price Ratio</h5>
          <p>
            Here you can see the price ratio of your offers. You can filter them
            by date, location, price, brokerage, and status. You can also see
            the map of your offers and the list of all offers.
          </p>
        </>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
  ]);

  const getMonth = (epochTime) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthIndex = new Date(+`${epochTime}000`).getMonth();

    return months[monthIndex];
  };

  const geocodeAddress = async (offerSellersValues) => {
    const coord = [...offerSellersValues];
    for (let i = 0; i < coord.length; i++) {
      const offer = coord[i][0];
      if (!offer?.latitude || !offer?.longitude) {
        const address = offer.address;
        const geocoder = new window.google.maps.Geocoder();
        const coordinatesValues = await geocoder.geocode(
          { address },
          (results, status) => {
            if (
              status === window.google.maps.GeocoderStatus.OK &&
              results.length > 0
            ) {
              const location = results[0].geometry.location;
              const latitude = location.lat();
              const longitude = location.lng();
              return { latitude, longitude };
            } else {
              return { latitude: 0, longitude: 0 };
            }
          }
        );
        if (
          coordinatesValues?.results?.length &&
          coordinatesValues?.results[0]
        ) {
          const location = coordinatesValues.results[0].geometry.location;
          const aa = coordinatesValues.results[0].address_components.find(
            (el) => el.types[0] === "administrative_area_level_1"
          );
          const city = coordinatesValues.results[0].address_components.find(
            (el) => el.types[0] === "locality"
          );
          const lat = location.lat();
          const lng = location.lng();
          if (lat && lng) {
            coord[i][0]["longitude"] = lng;
            coord[i][0]["latitude"] = lat;
            coord[i][0]["state"] = aa.long_name;
            coord[i][0]["city"] = city?.long_name;
            for (let j = 1; j < coord[i].length; j++) {
              coord[i][j]["longitude"] = lng;
              coord[i][j]["latitude"] = lat;
              coord[i][j]["state"] = aa.long_name;
              coord[i][j]["city"] = city?.long_name;
            }
          }
        }
      }
    }
    return coord;
  };

  const offerSellers = {};
  offers.forEach((offer) => {
    if (offerSellers[offer.seller]) {
      offerSellers[offer.seller].push(offer);
    } else {
      offerSellers[offer.seller] = [offer];
    }
  });

  const offerSellersValues = Object.values(offerSellers);

  const allOfferSellers = {};
  allOffers.forEach((offer) => {
    if (allOfferSellers[offer.seller]) {
      allOfferSellers[offer.seller].push(offer);
    } else {
      allOfferSellers[offer.seller] = [offer];
    }
  });

  const allOfferSellersValues = Object.values(allOfferSellers);
  const getFFFFF = async () => {
    const coord = await geocodeAddress(offerSellersValues);
    setUnfilteredOffersCoord(coord);
  };
  const getAAAAFFFFF = async () => {
    const coord = await geocodeAddress(allOfferSellersValues);
    setAllUnfilteredOffersCoord(coord);
  };
  useEffect(() => {
    if (offerSellersValues && offerSellersValues.length) {
      getFFFFF();
    }
  }, [offers]);

  useEffect(() => {
    if (allOfferSellersValues && allOfferSellersValues.length) {
      getAAAAFFFFF();
    }
  }, [allOffers]);

  useEffect(() => {
    const brokerage_info = {};
    let locations = {};

    if ((allOffers && allOffers.length) || (offers && offers.length)) {
      [...(offers || []), ...(globalData ? allOffers || [] : [])].forEach(
        (singleOffer) => {
          if (locations[singleOffer.address]) {
            locations[singleOffer.address]++;
          } else {
            locations[singleOffer.address] = 1;
          }
          const nameB = singleOffer.brokerageName;
          if (!brokerage_info[nameB]) {
            brokerage_info[nameB] = {
              totalSum: +(singleOffer.purchasePrice || 0),
              offersCount: 1,
              actual_price: +(singleOffer.actual_price || 0),
              name: nameB,
            };
          } else {
            brokerage_info[nameB].totalSum += +(singleOffer.purchasePrice || 0);
            brokerage_info[nameB].offersCount++;
            brokerage_info[nameB].actual_price += +(
              singleOffer.actual_price || 0
            );
          }
        }
      );
    }
    if (!selectedBrokarage?.length) setAllBrokarage(brokerage_info);

    setAllBrockerageInfo(brokerage_info);
  }, [offers, allOffers, globalData]);

  useEffect(() => {
    const filteredDataMy = [];
    const filteredDataAll = [];
    const convert1 = dateValue
      ? new Date(dateValue[0]).getTime() / 1000
      : false;
    const convert2 = dateValue
      ? new Date(dateValue[1]).getTime() / 1000
      : false;

    const offers_all_data = allOffersData.filter((el) => {
      const addressParts = el.address.split(",");
      const city = addressParts[1].trim();
      const state = stateMapping[addressParts[2].split(" ")[1]];
      if (
        (!dateValue || (el.createdAt > convert1 && el.createdAt < convert2)) &&
        (!selectedBrokarage?.length ||
          selectedBrokarage.includes(el.brokerageName)) &&
        (selectLocation?.length === 0 || selectLocation.includes(state)) &&
        (selectLocationCity?.length === 0 || selectLocationCity.includes(city))
      ) {
        return el;
      }
    });

    const offers_data = offersData.filter((el) => {
      const addressParts = el.address.split(",");
      const city = addressParts[1].trim();
      const state = stateMapping[addressParts[2].split(" ")[1]];
      if (
        (!dateValue || (el.createdAt > convert1 && el.createdAt < convert2)) &&
        (!selectedBrokarage?.length ||
          selectedBrokarage.includes(el.brokerageName)) &&
        (selectLocation?.length === 0 || selectLocation.includes(state)) &&
        (selectLocationCity?.length === 0 || selectLocationCity.includes(city))
      ) {
        return el;
      }
    });

    if (priceFilter.length > 0) {
      for (let j = 0; j < priceFilter.length; j++) {
        let max = Number(priceFilter[j]);
        let min = max - 50000;
        if (max == "max") {
          min = 500000;
          max = 0;
        }

        for (let i = 0; i < offers_data.length; i++) {
          const price = Number(offers_data[i].actual_price) || 0;
          if (price >= min && (price <= max || !max)) {
            filteredDataMy.push(offers_data[i]);
          }
        }
        for (let i = 0; i < offers_all_data.length; i++) {
          const price = Number(offers_all_data[i].actual_price) || 0;
          if (price >= min && (price <= max || !max)) {
            filteredDataAll.push(offers_all_data[i]);
          }
        }
      }
      setAllOffers(filteredDataAll);
      setOffers(filteredDataMy);
    } else {
      setAllOffers(offers_all_data);
      setOffers(offers_data);
    }
  }, [
    dateValue,
    selectedBrokarage,
    priceFilter,
    selectLocation,
    selectLocationCity,
    globalData,
    offersData,
    allOffersData,
  ]);

  useEffect(() => {
    let acceptedCount = 0;
    let notAcceptedCount = 0;
    let withdrawnCount = 0;
    let verifiedCount = 0;
    let unVerifiedCount = 0;

    let accempedData = [];
    let notAcceptedData = [];
    let withdrawnData = [];
    let brockerageInfo = {};

    let months = {
      January: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      February: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      March: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      April: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      May: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      June: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      July: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      August: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      September: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      October: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      November: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
      December: {
        accepted: 0,
        notAccepted: 0,
        withdrawn: 0,
        executed: 0,
      },
    };

    let otherPurchasePrices = 0;
    let otherActualPrice = 0;
    let count = 0;
    let yourPurchasePrices = 0;
    let yourActualPrice = 0;
    let locations = {};
    let locationsOffers = {};

    if (!globalData) {
      offers.forEach((singleOffer) => {
        if (singleOffer.verified) {
          verifiedCount++;
        } else {
          unVerifiedCount++;
        }
        if (
          singleOffer.status === "Accepted" ||
          singleOffer.status === "Executed"
        ) {
          acceptedCount++;
          let month = getMonth(singleOffer.createdAt);
          months[month].accepted++;
        } else if (singleOffer.status === "Not Accepted") {
          notAcceptedCount++;

          let month = getMonth(singleOffer.createdAt);
          months[month].notAccepted++;
        } else if (singleOffer.status === "Withdraw") {
          withdrawnCount++;
          let month = getMonth(singleOffer.createdAt);
          months[month].withdrawn++;
        }
      });
    } else {
      [...offers, ...allOffers].forEach((singleOffer) => {
        if (singleOffer.verified) {
          verifiedCount++;
        } else {
          unVerifiedCount++;
        }
        if (
          singleOffer.status === "Accepted" ||
          singleOffer.status === "Executed"
        ) {
          acceptedCount++;
          let month = getMonth(singleOffer.createdAt);
          months[month].accepted++;
        }
        if (singleOffer.status === "Not Accepted") {
          notAcceptedCount++;

          let month = getMonth(singleOffer.createdAt);
          months[month].notAccepted++;
        }
        if (singleOffer.status === "Withdraw") {
          withdrawnCount++;
          let month = getMonth(singleOffer.createdAt);
          months[month].withdrawn++;
        }
      });
    }

    [...(offers || []), ...(globalData ? allOffers || [] : [])].forEach(
      (singleOffer) => {
        if ((!globalData && user.uid === singleOffer.seller_id) || globalData) {
          // if (
          //   singleOffer.status === "Accepted" ||
          //   singleOffer.status === "Executed"
          // ) {
          //   acceptedCount++;
          //   let month = getMonth(singleOffer.createdAt);
          //   months[month].accepted++;
          // } else if (singleOffer.status === "Not Accepted") {
          //   notAcceptedCount++;
          //   let month = getMonth(singleOffer.createdAt);
          //   months[month].notAccepted++;
          // } else if (singleOffer.status === "Withdraw") {
          //   withdrawnCount++;
          //   let month = getMonth(singleOffer.createdAt);
          //   months[month].withdrawn++;
          // }
        }
        // for location
        if (locations[singleOffer.address]) {
          locations[singleOffer.address]++;
        } else {
          locations[singleOffer.address] = 1;
        }
        if (!locationsOffers[singleOffer.address]) {
          locationsOffers[singleOffer.address] = singleOffer;
        }

        //for Average Property quality
        // let kkkk = user.agent_emails.map(el=>{})
        const nameB =
          user.uid === singleOffer.seller_id
            ? "You"
            : singleOffer.brokerageName;
        if (!brockerageInfo[nameB]) {
          brockerageInfo[nameB] = {
            totalSum: +(singleOffer.purchasePrice || 0),
            offersCount: 1,
            actual_price: +(singleOffer.actual_price || 0),
          };
        } else {
          brockerageInfo[nameB].totalSum += +(singleOffer.purchasePrice || 0);
          brockerageInfo[nameB].offersCount++;
          brockerageInfo[nameB].actual_price += +(
            singleOffer.actual_price || 0
          );
        }

        Object.entries(brockerageInfo).map((el) => {
          if (el[0] != "You") {
            otherPurchasePrices += el[1].totalSum;
            otherActualPrice += el[1].actual_price;
            count += el[1].offersCount;
          } else {
            yourPurchasePrices = el[1].totalSum / el[1].offersCount;
            yourActualPrice = el[1].actual_price / el[1].offersCount;
          }
        });
        accempedData = Object.entries(months).map(([_, data]) => data.accepted);
        notAcceptedData = Object.entries(months).map(
          ([_, data]) => data.notAccepted
        );
        withdrawnData = Object.entries(months).map(
          ([_, data]) => data.withdrawn
        );
      }
    );
    setTotalValues({
      yourPurchasePrices,
      yourActualPrice,
      otherActual: count ? otherActualPrice / count : 0,
      otherPurchasePrices: count ? otherPurchasePrices / count : 0,
    });
    setBrockerageInfo(brockerageInfo);
    setLocationsOffers(locationsOffers);
    setLocations(locations);
    setWithdrawnData(withdrawnData);
    setNotAcceptedData(notAcceptedData);
    setAcceptedData(accempedData);
    setAccepted(acceptedCount);
    setNotAccepted(notAcceptedCount);
    setWithdrawn(withdrawnCount);
    setVerified({ verifiedCount, unVerifiedCount });
  }, [offers, allOffers, globalData, priceFilter]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Joyride
        size={30}
        steps={steps}
        run={joyRide1}
        callback={(data) => {
          const { action, status } = data;
          if (action === "close") {
            setJoyRide1(false);
          }
          if (status === "finished") {
            const db = firebaseApp.firestore();
            db.collection("users").doc(user?.uid).update({
              analyticJoyRide: false,
            });
          }
        }}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        styles={{
          buttonNext: {
            color: "#ffffff",
            lineHeight: "0.9",
            padding: "10px",
          },
        }}
      />

      <div className={`${mobile ? "" : styles.analyticOne}`}>
        {!mobile ? (
          <div
            style={{
              boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.06)",
            }}
          >
            <div className={`row ${styles.analyticTitle}`}>
              <div
                className={`navbarBlock col-xl-9 col-lg-7 col-12 ${styles.tabs} 
            ${value === 0 && styles.customTabActive}
            ${value === 1 && styles.customTabActive1}
            ${value === 2 && styles.customTabActive2}
            ${value === 3 && styles.customTabActive3}
          `}
              >
                <h3 className={`d-none d-md-block ${styles.title}`}>
                  Analytics Overview
                </h3>
                <div className="text-center">
                  <h3 className={`d-block d-md-none ${styles.title}`}>
                    Analytics Overview
                  </h3>
                </div>
                <div className={`d-none d-md-block ${styles.tabsParent}`}>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={value}
                    onSelect={(k) => {
                      setValue(+k);
                      setQueryParam("analyticsTab", +k);
                    }}
                    className="mb-3"
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      eventKey={0}
                      title={"Offers"}
                      {...a11yProps(0)}
                      className=""
                    />
                    <Tab
                      eventKey={1}
                      title={"Average Property Quality"}
                      {...a11yProps(1)}
                      className=""
                    />
                    <Tab
                      eventKey={2}
                      title={"Location"}
                      {...a11yProps(2)}
                      className=""
                    />
                    <Tab
                      eventKey={3}
                      title={"Price Ratio"}
                      {...a11yProps(3)}
                      className=""
                    />
                  </Tabs>
                </div>
                {/* <div className="row mx-auto"> */}
                <Tabs
                  id="controlled-tab-example"
                  activeKey={value}
                  onSelect={(k) => {
                    setValue(+k);
                    setQueryParam("analyticsTab", +k);
                  }}
                  className="mb-3 row mx-auto d-md-none d-flex analytics-tabs-mobile"
                  value={value}
                  onChange={handleChange}
                >
                  {/* <div className={`col-12`}> */}
                  <Tab
                    eventKey={0}
                    title={"Offers"}
                    {...a11yProps(0)}
                    className="col-6"
                  />
                  <Tab
                    eventKey={1}
                    title={"Average Property Quality"}
                    {...a11yProps(1)}
                    className="col-6"
                  />
                  <Tab
                    eventKey={2}
                    title={"Location"}
                    {...a11yProps(2)}
                    className="col-6"
                  />
                  <Tab
                    eventKey={3}
                    title={"Price Ratio"}
                    {...a11yProps(3)}
                    className="col-6"
                  />
                  {/* </div> */}
                </Tabs>
                {/* </div> */}
              </div>
              <div
                className={`col-xl-3 col-lg-5 col-12 d-none d-md-flex ${styles.wrapper}`}
              >
                <div className="row ml-auto">
                  <div className="col-12 col-md-12">
                    <div className={styles.summaryDetails}>
                      <button
                        id="step1"
                        onClick={() => {
                          setQueryParam("data", "my-data");
                          setGlobalData(false);
                        }}
                        className={
                          !globalData
                            ? styles.activeDetails
                            : styles.deActiveDetails
                        }
                      >
                        My Data
                      </button>
                      <button
                        id="step2"
                        onClick={() => {
                          setQueryParam("data", "global-data");
                          setGlobalData(true);
                        }}
                        className={`${
                          globalData
                            ? styles.activeDetails
                            : styles.deActiveDetails
                        } ${styles.globalData}`}
                      >
                        Global Data
                        {!globalData && <img src="/images/lock.svg" />}
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                <button className={`d-none d-md-flex ${styles.btnImg}`}>
                  <img src={resize} />
                </button>
              </div> */}
                </div>
              </div>
              <div
                className={`col-12 d-md-none d-block ${styles.wrapper} my-auto mx-auto text-center`}
              >
                <div className="mb-4 my-data-global-data-mobile-btn">
                  <button
                    id="step1"
                    onClick={() => {
                      setQueryParam("data", "my-data");
                      setGlobalData(false);
                    }}
                    className={`${
                      !globalData
                        ? styles.activeDetails
                        : styles.deActiveDetails
                    } my-auto ${!globalData && "gd-active"}`}
                  >
                    My Data
                  </button>
                  <button
                    id="step2"
                    onClick={() => {
                      setQueryParam("data", "global-data");
                      setGlobalData(true);
                    }}
                    className={`${
                      globalData ? styles.activeDetails : styles.deActiveDetails
                    } ${styles.globalData} my-auto mx-auto ${
                      globalData && "gd-active"
                    } `}
                  >
                    Global Data
                    {!globalData && <img src="/images/lock.svg" />}
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#F8F8F8",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  // width: "100%",
                  // display: "flex",
                  // justifyContent: "space-between",
                  padding: "10px 20px 0 20px",
                  // alignItems: "center",
                  // gap: "10px",
                  // flexWrap: "wrap",
                }}
                className={`row ${styles.InfoCard}`}
              >
                <div className="col-lg-3 col-md-6 col-12 p-0 p-md-3 mt-4 mt-md-2 mt-lg-0">
                  <InfoCard
                    className="responsive-height"
                    infoName={"Total Offers"}
                    infoValue={
                      [...offers, ...(globalData ? allOffers : [])].length ||
                      "0"
                    }
                    infoMini={verified?.verifiedCount}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-12 p-0 p-md-3 mt-4 mt-md-2 mt-lg-0">
                  <InfoCard
                    className="responsive-height"
                    infoName={"Total Offers Accepted"}
                    infoValue={accepted}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-12 p-0 p-md-3 mt-4 mt-md-2 mt-lg-0">
                  <InfoCard
                    className="responsive-height"
                    infoName={"Total Offers Not Accepted"}
                    infoValue={notAccepted}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-12 p-0 p-md-3 mt-4 mt-md-2 mt-lg-0">
                  <InfoCard
                    className="responsive-height"
                    infoName={"Total Offers Withdrawn"}
                    infoValue={withdrawn}
                  />
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  gap: "20px",
                  padding: "0 20px",
                }}
              >
                <div className="summary-detail-container">
                  {!globalData && (
                    <div
                      className={`${styles.summaryDetails} ${styles.summaryDetailsBlock}`}
                    >
                      <button
                        onClick={() => {
                          setQueryParam("info", "SUMMARY");
                          setInfo("SUMMARY");
                        }}
                        className={
                          info === "SUMMARY"
                            ? styles.activeDetails
                            : styles.deActiveDetails
                        }
                      >
                        Summary
                      </button>
                      <button
                        onClick={() => {
                          setQueryParam("info", "DETAILS");
                          setInfo("DETAILS");
                        }}
                        className={
                          info === "DETAILS"
                            ? styles.activeDetails
                            : styles.deActiveDetails
                        }
                      >
                        Details
                      </button>
                    </div>
                  )}
                  {!globalData && (
                    <img
                      className="d-none d-xl-flex"
                      src={line}
                      style={{
                        background: "#E5E5E5",
                        width: "0.9px",
                        height: "32px",
                      }}
                    />
                  )}
                  <div
                    className={`${styles.width15} ${
                      globalData && styles.width180
                    } mob-dropdown-style-width-100-perc`}
                  >
                    <UncontrolledButtonDropdown
                      direction="down"
                      background="white"
                      className={`me-3 ${styles.dropdownStyle} mob-dropdown-style`}
                    >
                      <DropdownToggle
                        caret
                        color="light"
                        style={{
                          columnGap: "0",
                        }}
                      >
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {"Brokerage"}
                        </span>
                        <img src="/images/arrow-dropdown.svg" />
                      </DropdownToggle>
                      <DropdownMenu className="drop">
                        {Object.keys(allBrokarage)?.length > 0 ? (
                          Object.keys(allBrokarage)
                            ?.sort((a, b) => a.localeCompare(b))
                            .map((name, i) => {
                              return (
                                <div
                                  className=""
                                  key={i}
                                  style={{
                                    width: "max-content",
                                    display: "flex",
                                    columnGap: "10px",
                                  }}
                                >
                                  <input
                                    className=""
                                    type="checkbox"
                                    checked={selectedBrokarage.includes(name)}
                                    onClick={(e) => {
                                      let test = [...selectedBrokarage];
                                      if (e.target.checked) {
                                        test.push(name);
                                      } else {
                                        test = selectedBrokarage.filter(
                                          (val) => val !== name
                                        );
                                      }
                                      setQueryParam("brokarage", test);
                                      setSelectedBrokarage(test);
                                    }}
                                    id={i}
                                    name={i}
                                    value={name}
                                  />
                                  <span>{name}</span>
                                </div>
                              );
                            })
                        ) : (
                          <></>
                        )}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                  <div
                    className={`mt-3 mt-md-0 ${styles.width15} ${
                      globalData && styles.width180
                    } mob-dropdown-style-width-100-perc`}
                  >
                    <UncontrolledButtonDropdown
                      direction="down"
                      background="white"
                      className={`me-2 ${styles.dropdownStyle} mob-dropdown-style`}
                    >
                      <DropdownToggle
                        caret
                        color="light"
                        style={{
                          columnGap: "0",
                        }}
                      >
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {"Location"}
                        </span>
                        <img src="/images/location.svg" />
                      </DropdownToggle>
                      <DropdownMenu className="drop">
                        {Object.keys(stateCodeMap)?.length > 0 ? (
                          Object.keys(stateCodeMap)?.map((name, i) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  width: "max-content",
                                  display: "flex",
                                  columnGap: "10px",
                                }}
                              >
                                <input
                                  className=""
                                  type="checkbox"
                                  checked={selectLocation.includes(name)}
                                  onClick={(e) => {
                                    let test = [...selectLocation];
                                    if (e.target.checked) {
                                      test.push(name);
                                    } else {
                                      test = selectLocation.filter(
                                        (val) => val !== name
                                      );
                                    }
                                    setQueryParam("location", test);
                                    setSelectLocation(test);
                                  }}
                                  id={i}
                                  name={i}
                                  value={name}
                                />
                                <span htmlFor={i}>{name}</span>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>

                  <div
                    className={`mt-3 mt-md-0 ${styles.width15} ${
                      globalData && styles.width180
                    } mob-dropdown-style-width-100-perc`}
                  >
                    <UncontrolledButtonDropdown
                      direction="down"
                      background="white"
                      className={`me-2 ${styles.dropdownStyle} mob-dropdown-style`}
                    >
                      <DropdownToggle caret color="light">
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Price {priceFilterLabel}
                        </span>
                        <img src="/images/arrow-dropdown.svg" />
                      </DropdownToggle>
                      <DropdownMenu className="drop">
                        {priceOptions.map((m, i) => {
                          return (
                            <div
                              className=""
                              key={i}
                              style={{
                                width: "max-content",
                                display: "flex",
                                columnGap: "10px",
                              }}
                            >
                              <input
                                className=""
                                type="checkbox"
                                checked={priceFilter.includes(m.value)}
                                onClick={(e) => {
                                  let test = [...priceFilter];
                                  let label = [...priceFilterLabel];
                                  if (e.target.checked) {
                                    test.push(m.value);
                                    label.push(m.label);
                                  } else {
                                    test = priceFilter.filter(
                                      (val) => val !== m.value
                                    );
                                    label = priceFilterLabel.filter(
                                      (val) => val !== m.label
                                    );
                                  }
                                  setQueryParam("price", test.join("%3A"));
                                  setPriceFilter(test);
                                  setPriceFilterLabel(label);
                                }}
                              />
                              <span>{m.label}</span>
                            </div>
                          );
                        })}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>

                  <div
                    className={`mt-3 mt-md-0 ${styles.width17} ${
                      styles.width15dateRange
                    } ${
                      globalData && styles.width17
                    } mob-dropdown-style-width-100-perc`}
                  >
                    <span
                      className={dateValue ? styles.range : styles.rangeDisplay}
                    >
                      Date range
                    </span>
                    <DateRangePicker
                      className={`${
                        dateValue ? "calendar" : "withoutValue"
                      } mob-dropdown-style-width-100-perc`}
                      // style={{
                      //   minWidth: "205px",
                      // }}
                      onChange={(val) => {
                        setDateValue(val);
                        if (val === null) {
                          setQueryParam("date", "");
                        } else {
                          setQueryParam("date", val);
                        }
                      }}
                      monthAriaLabel={false}
                      value={dateValue}
                      format={"dd.MM.y"}
                      maxDate={new Date()}
                      calendarIcon={
                        <img
                          src="/images/calendar.svg"
                          style={{
                            paddingRight: "0",
                            paddingBottom: dateValue && "2px",
                            width: !dateValue && "18px",
                            paddingLeft: "0",
                          }}
                        />
                      }
                      {...(!dateValue ? { clearIcon: null } : {})}
                    />
                  </div>
                  <img
                    className="d-none d-xl-flex"
                    src={line}
                    style={{
                      background: "#E5E5E5",
                      width: "0.9px",
                      height: "32px",
                    }}
                  />
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      height: "51px",
                    }}
                    className="mt-3 mt-md-0 mob-dropdown-style-width-100-perc"
                  >
                    <button className={styles.exportButton} disabled={true}>
                      <img src="/images/arrow.svg" />
                      Export
                    </button>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      height: "51px",
                    }}
                    className="mt-3 mt-md-0 mob-dropdown-style-width-100-perc"
                  >
                    <Link
                      to="/offers"
                      style={{
                        color: "#FFFFFF",
                      }}
                    >
                      <button className={styles.viewOffers}>View offers</button>
                    </Link>
                  </div>
                </div>
                {selectLocationCity?.length ||
                selectedBrokarage?.length ||
                selectLocation?.length ? (
                  <div
                    style={{
                      background: "#F8F8F8",
                      width: "100%",
                      display: "flex",
                      columnGap: "10px",
                      marginBottom: "10px",
                      flexWrap: "wrap",
                      rowGap: "10px",
                    }}
                  >
                    {selectedBrokarage?.length ? (
                      selectedBrokarage?.map((e) => (
                        <CommonCloseButton
                          buttonText={e}
                          onClick={(event) => {
                            setSelectedBrokarage(
                              selectedBrokarage.filter((e) => e !== event)
                            );
                            setQueryParam(
                              "brokarage",
                              selectedBrokarage.filter((e) => e !== event)
                            );
                          }}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                    {selectLocation?.length ? (
                      selectLocation?.map((e) => (
                        <CommonCloseButton
                          buttonText={e}
                          onClick={(event) => {
                            setSelectLocation(
                              selectLocation.filter((e) => e !== event)
                            );
                            setQueryParam(
                              "location",
                              selectLocation.filter((e) => e !== event)
                            );
                          }}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                    {selectLocationCity?.length ? (
                      selectLocationCity?.map((e) => (
                        <CommonCloseButton
                          buttonText={e}
                          onClick={(event) => {
                            setSelectLocationCity(
                              selectLocationCity.filter((e) => e !== event)
                            );
                            setQueryParam(
                              "city",
                              selectLocationCity.filter((e) => e !== event)
                            );
                          }}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          // mobile view
          <>
            <div className={`main-container`}>
              <div
                className={`${styles.tabs} 
             ${value === 0 && styles.customTabActive}
             ${value === 1 && styles.customTabActive1}
             ${value === 2 && styles.customTabActive2}
             ${value === 3 && styles.customTabActive3} row`}
              >
                <div className="col-10 p-0 m-0 my-auto">
                  <h1 className="h6">Analytics Overview</h1>
                  {globalData && (
                    <button
                      className={"mobile-view-offer-btn global-data-heading"}
                      onClick={() => {
                        setShowGlobalDataModal(true);
                      }}
                    >
                      Global Data
                    </button>
                  )}
                </div>
                <div className="col-2 m-0 p-0 my-auto">
                  <img
                    src={threedots}
                    className="float-right"
                    onClick={() => setShowGlobalDataModal(true)}
                  />
                </div>
                <div className={`col-12 ${styles.tabsParent} p-0 m-0`}>
                  <div className="row mobile-tabs">
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={value}
                      onSelect={(k) => {
                        setValue(+k);
                        setQueryParam("analyticsTab", +k);
                      }}
                      className="mb-3"
                      value={value}
                      onChange={handleChange}
                    >
                      <Tab
                        eventKey={0}
                        title={"Offers"}
                        {...a11yProps(0)}
                        className=""
                      />
                      <Tab
                        eventKey={1}
                        title={"Average Property Quality"}
                        {...a11yProps(1)}
                        className=""
                      />
                      <Tab
                        eventKey={2}
                        title={"Location"}
                        {...a11yProps(2)}
                        className=""
                      />
                      <Tab
                        eventKey={3}
                        title={"Price Ratio"}
                        {...a11yProps(3)}
                        className=""
                      />
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="row secondary-container">
              <div className="col-12 p-0 m-0 mobile-btns-container">
                <button
                  className={`mobile-export-btn`}
                  onClick={() => {
                    setShowFiltersModal(true);
                  }}
                >
                  <img src={filter} className="mr-2 mobile-icons" />
                  Filters
                </button>{" "}
                <img
                  className=""
                  src={line}
                  style={{
                    background: "#E5E5E5",
                    width: "0.9px",
                    height: "32px",
                  }}
                />
                <button className={`mobile-export-btn`} disabled={true}>
                  <img src="/images/arrow.svg" className="mr-2" />
                  Export
                </button>
                <Link
                  to="/offers"
                  style={{
                    color: "#FFFFFF",
                    width: "27%",
                  }}
                >
                  <button className={"mobile-view-offer-btn"}>
                    View offers
                  </button>
                </Link>
              </div>
              {selectLocationCity?.length ||
              selectedBrokarage?.length ||
              selectLocation?.length ? (
                <div className="col-12 p-0 m-0 mt-3">
                  {selectLocationCity?.length ||
                  selectedBrokarage?.length ||
                  selectLocation?.length ? (
                    <div
                      className="mob-common-close-btn-container"
                      style={{
                        background: "#F8F8F8",
                        width: "100%",
                        display: "flex",
                        columnGap: "10px",
                        flexWrap: "wrap",
                        rowGap: "10px",
                      }}
                    >
                      {selectedBrokarage?.length ? (
                        selectedBrokarage?.map((e) => (
                          <CommonCloseButton
                            buttonText={e}
                            onClick={(event) => {
                              setSelectedBrokarage(
                                selectedBrokarage.filter((e) => e !== event)
                              );
                              setQueryParam(
                                "brokarage",
                                selectedBrokarage.filter((e) => e !== event)
                              );
                            }}
                          />
                        ))
                      ) : (
                        <></>
                      )}
                      {selectLocation?.length ? (
                        selectLocation?.map((e) => (
                          <CommonCloseButton
                            buttonText={e}
                            onClick={(event) => {
                              setSelectLocation(
                                selectLocation.filter((e) => e !== event)
                              );
                              setQueryParam(
                                "location",
                                selectLocation.filter((e) => e !== event)
                              );
                            }}
                          />
                        ))
                      ) : (
                        <></>
                      )}
                      {selectLocationCity?.length ? (
                        selectLocationCity?.map((e) => (
                          <CommonCloseButton
                            buttonText={e}
                            onClick={(event) => {
                              setSelectLocationCity(
                                selectLocationCity.filter((e) => e !== event)
                              );
                            }}
                          />
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              {!globalData && (
                <div className="col-12 p-0 m-0">
                  <div className="summary-detail-container">
                    <div
                      className={`${styles.summaryDetails} ${styles.summaryDetailsBlock} mobile-summary-details`}
                    >
                      <button
                        onClick={() => {
                          setQueryParam("info", "SUMMARY");
                          setInfo("SUMMARY");
                        }}
                        className={
                          info === "SUMMARY"
                            ? `mobile-active-details ${styles.activeDetails}`
                            : styles.deActiveDetails
                        }
                      >
                        Summary
                      </button>
                      <button
                        style={{
                          marginBottom: "0px !important",
                        }}
                        onClick={() => {
                          setQueryParam("info", "DETAILS");
                          setInfo("DETAILS");
                        }}
                        className={
                          info === "DETAILS"
                            ? `mobile-active-details ${styles.activeDetails}`
                            : styles.deActiveDetails
                        }
                      >
                        Details
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <TabPanel value={0} index={0}>
          <div
            style={{
              width: "100%",
              boxShadow: "0px 5px 18px rgb(0 0 0 / 6%)",
              padding: mobile ? "10px" : "17px",
              background: "rgb(255, 255, 255)",
            }}
          >
            {!globalData ? (
              value === 0 ? (
                info === "SUMMARY" ? (
                  <Offers
                    accepted={accepted}
                    notAccepted={notAccepted}
                    withdrawn={withdrawn}
                    verified={verified}
                  />
                ) : (
                  <AverageQualityChart
                    acceptedData={acceptedData}
                    notAcceptedData={notAcceptedData}
                    withdrawnData={withdrawnData}
                    verified={verified}
                    offers={offers}
                  />
                )
              ) : value === 1 ? (
                <TabPanel value={value} index={1}>
                  {info === "SUMMARY" ? (
                    <AverageSummaryChart
                      brockerageInfo={brockerageInfo}
                      totalValues={totalValues}
                      isData={!!offers?.length}
                    />
                  ) : (
                    <AverageDetailsChart
                      brockerageInfo={brockerageInfo}
                      isData={!!offers?.length}
                    />
                  )}
                </TabPanel>
              ) : value === 2 ? (
                <TabPanel value={value} index={2}>
                  {info === "SUMMARY" ? (
                    <LocationSummary
                      mobile={mobile}
                      locations={locations}
                      allOffers={offers.length}
                    />
                  ) : (
                    <LocationDetails
                      locations={locations}
                      google={window.google}
                      locationsOffers={locationsOffers}
                      mobile={mobile}
                    />
                  )}
                </TabPanel>
              ) : (
                <TabPanel value={value} index={3}>
                  {info !== "SUMMARY" && (
                    <div
                      className={styles.labels}
                      style={{
                        background: "#fff",
                        zIndex: 999,
                        position: "absolute",
                      }}
                    >
                      {datasetLabels.map((label) => (
                        <React.Fragment key={label}>
                          <span
                            className={styles.circle1}
                            onClick={() => {
                              if (legendFilter.includes(label)) {
                                setLegendFilter(
                                  legendFilter.filter((e) => e !== label)
                                );
                              } else {
                                setLegendFilter([...legendFilter, label]);
                              }
                            }}
                          ></span>
                          <span
                            className={
                              legendFilter.includes(label)
                                ? styles["text-decoration-line-through"]
                                : ""
                            }
                          >
                            {label}
                          </span>
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                  {totalValues ? (
                    <div
                      style={{
                        width: "100%",
                        overflowX: "auto",
                        background: "#ffffff",
                      }}
                      className={styles.ratioDetails}
                    >
                      {info === "SUMMARY" ? (
                        <RatioSummary totalValues={totalValues} />
                      ) : (
                        <RatioDetails
                          brockerageInfo={allBrockerageInfo}
                          totalValues={totalValues}
                          legendFilter={legendFilter}
                        />
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </TabPanel>
              )
            ) : value === 0 ? (
              <GlobalOffers
                offers={
                  [...allOffers, ...offers]
                  //   .filter(
                  //   (offer) => offer.sellerEmail === user.email
                  // )
                }
                setSelectedBrokarage={setSelectedBrokarage}
                selectedBrokarage={selectedBrokarage}
                mobile={mobile}
              />
            ) : value === 1 ? (
              <GlobalAverage
                allOffer={allBrockerageInfo}
                selectedBrokarage={selectedBrokarage}
                priceFilterLabel={priceFilterLabel}
                setSelectedBrokarage={setSelectedBrokarage}
                mobile={mobile}
              />
            ) : value === 2 ? (
              <GlobalLocation
                user={user}
                offers={[...allOffers, ...offers]}
                locations={locations}
                google={window.google}
                locationsOffers={[...offers, ...allOffers]}
                selectedLocation={selectLocation}
                selectLocationCity={selectLocationCity}
                unfilteredOffers={[
                  ...allUnfilteredOffersCoord,
                  ...unfilteredOffersCoord,
                ]}
                setSelectLocation={setSelectLocation}
                setSelectLocationCity={setSelectLocationCity}
                mobile={mobile}
                setQueryParam={setQueryParam}
                getGlobalAgents={getGlobalAgents}
                globalAgents={globalAgents}
                allClosedGlobalSellers={allClosedGlobalSellers}
              />
            ) : (
              <div
                className={`${styles.ratioDetails} ${
                  selectedBrokarage.length !== 1 && styles.globalRatioDetails
                }`}
              >
                <GlobalPriceRatio
                  mobile={mobile}
                  brockerageInfo={allBrockerageInfo}
                  setSelectedBrokarage={setSelectedBrokarage}
                  selectedBrokarage={selectedBrokarage}
                />
              </div>
            )}
          </div>

          <Row
            style={{
              display: "flex",
              paddingTop: "30px",
              justifyContent: "space-between",
              backgroundColor: "#F6F7FA",
              columnGap: "28px",
            }}
          >
            <Col
              sm={12}
              xl={8}
              lg={8}
              className="p-0 charts line-chart-width col-12 col-lg-8 col-xl-8"
              style={
                {
                  // maxWidth: "380px",
                }
              }
            >
              <LineChart
                sellers={sellers}
                allData={match.params?.uid === "all"}
                user={agent || user}
              />
            </Col>
            {/* <PropertyListingViewsChart sellers={sellers} allData={match.params?.uid === 'all'} user={agent || user} /> */}
            <Col
              sm={12}
              xl={4}
              lg={4}
              className="p-0 total-over-time-chart mt-4 mt-lg-0 col-12 col-lg-4 col-xl-4"
              style={{
                flex: "auto",
                maxWidth: "inherit",
                boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.06)",
                borderRadius: "4px",
                background: "#FFFFFF",
              }}
            >
              <TotalOverTimeChart
                user={agent || user}
                allData={match.params?.uid === "all"}
                offersValues={offersData}
              />
            </Col>
            {/* <TotalOffersByDay user={agent || user} allData={match.params?.uid === 'all'} /> */}
          </Row>

          <Row>
            <Col
              lg={12}
              style={{
                padding: 0,
              }}
            >
              {/* <TotalOffersByListing user={agent || user} allData={match.params?.uid === 'all'} /> */}
              <TotalOverListingsChart offers={offersData} />
            </Col>
          </Row>

          {offersData && (
            <Row>
              <Col
                lg={12}
                style={{
                  padding: 0,
                }}
              >
                <OfferMapLocation
                  style={{ marginTop: "7px" }}
                  history={history}
                  offers={offersData}
                  windowsWidth={width}
                  user={agent || user}
                  allData={match.params?.uid === "all"}
                  offerSellersValues={offerSellersValues}
                  unfilteredOffersCoord={unfilteredOffersCoord}
                />
              </Col>
            </Row>
          )}
          <Row
            className="average"
            style={{
              marginBottom: "30px",
            }}
          >
            <Col lg={6} className="p-0">
              {/* <OfferPriceListPrice
              offers={offersData}
              user={agent || user}
              allData={match.params?.uid === "all"}
            /> */}
              <OfferPriceListPrice
                sellers={sellers}
                offers={offers}
                user={agent || user}
                allData={match.params?.uid === "all"}
              />
            </Col>
            <Col lg={6} className="p-0">
              <AveragePropertiesOfferTerms
                offers={offersData}
                user={agent || user}
                allData={match.params?.uid === "all"}
              />
            </Col>
          </Row>
          <Row>
            <Col
              lg={12}
              style={{
                padding: 0,
              }}
            >
              {offersData.length > 0 && (
                <OffersTable
                  offers={offersData}
                  user={user}
                  locations={locations}
                  globalAgents={globalAgents}
                  getGlobalAgents={getGlobalAgents}
                />
              )}
            </Col>
          </Row>
        </TabPanel>
      </div>

      <Modal
        className="mobile-analytics-filters-modal m-0 p-0"
        isOpen={showFiltersModal}
        toggle={() => {
          setShowFiltersModal(!showFiltersModal);
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <ModalBody className="secondary-container m-0 p-0">
          <div className="row main-container">
            <div className="col-12 m-0 p-0">
              <img
                src={backButton}
                className="d-inline"
                onClick={() => {
                  setShowFiltersModal(!showFiltersModal);
                }}
                alt="imfdj"
              />
              <h5 className="ml-3 d-inline">Filters</h5>
            </div>
          </div>

          <div className="row secondary-container mob-filters-container">
            <div
              className={`${styles.width15} ${
                globalData && styles.width180
              } mob-dropdown-style-width-100-perc`}
            >
              <UncontrolledButtonDropdown
                direction="down"
                background="white"
                className={`me-3 ${styles.dropdownStyle} mob-dropdown-style`}
              >
                <DropdownToggle
                  caret
                  color="light"
                  style={{
                    columnGap: "0",
                  }}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {"Brokerage"}
                  </span>
                  <img src={arrowdown} className="mobile-icons" />
                </DropdownToggle>
                <DropdownMenu className="drop mob-dropdown-brokerage">
                  {Object.keys(allBrokarage)?.length > 0 ? (
                    Object.keys(allBrokarage)
                      ?.sort((a, b) => a.localeCompare(b))
                      .map((name, i) => {
                        return (
                          <div
                            className=""
                            key={i}
                            style={{
                              width: "max-content",
                              display: "flex",
                              columnGap: "10px",
                            }}
                          >
                            <input
                              className=""
                              type="checkbox"
                              checked={selectedBrokarage.includes(name)}
                              onClick={(e) => {
                                let test = [...selectedBrokarage];
                                if (e.target.checked) {
                                  test.push(name);
                                } else {
                                  test = selectedBrokarage.filter(
                                    (val) => val !== name
                                  );
                                }
                                setQueryParam("brokarage", test);
                                setSelectedBrokarage(test);
                              }}
                              id={i}
                              name={i}
                              value={name}
                            />
                            <span>{name}</span>
                          </div>
                        );
                      })
                  ) : (
                    <></>
                  )}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div
              className={`mt-3 mt-md-0 ${styles.width15} ${
                globalData && styles.width180
              } mob-dropdown-style-width-100-perc`}
            >
              <UncontrolledButtonDropdown
                direction="down"
                background="white"
                className={`me-2 ${styles.dropdownStyle} mob-dropdown-style`}
              >
                <DropdownToggle
                  caret
                  color="light"
                  style={{
                    columnGap: "0",
                  }}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {"Location"}
                  </span>
                  <img src="/images/location.svg" className="mobile-icons" />
                </DropdownToggle>
                <DropdownMenu className="drop">
                  {Object.keys(stateCodeMap)?.length > 0 ? (
                    Object.keys(stateCodeMap)?.map((name, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            width: "max-content",
                            display: "flex",
                            columnGap: "10px",
                          }}
                        >
                          <input
                            className=""
                            type="checkbox"
                            checked={selectLocation.includes(name)}
                            onClick={(e) => {
                              let test = [...selectLocation];
                              if (e.target.checked) {
                                test.push(name);
                              } else {
                                test = selectLocation.filter(
                                  (val) => val !== name
                                );
                              }
                              setQueryParam("location", test);
                              setSelectLocation(test);
                            }}
                            id={i}
                            name={i}
                            value={name}
                          />
                          <span htmlFor={i}>{name}</span>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>

            <div
              className={`mt-3 mt-md-0 ${styles.width15} ${
                globalData && styles.width180
              } mob-dropdown-style-width-100-perc`}
            >
              <UncontrolledButtonDropdown
                direction="down"
                background="white"
                className={`me-2 ${styles.dropdownStyle} mob-dropdown-style`}
              >
                <DropdownToggle caret color="light">
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Price {priceFilterLabel}
                  </span>
                  <img src={arrowdown} className="mobile-icons" />
                </DropdownToggle>
                <DropdownMenu className="drop">
                  {priceOptions.map((m, i) => {
                    return (
                      <div
                        className=""
                        key={i}
                        style={{
                          width: "max-content",
                          display: "flex",
                          columnGap: "10px",
                        }}
                      >
                        <input
                          className=""
                          type="checkbox"
                          checked={priceFilter.includes(m.value)}
                          onClick={(e) => {
                            let test = [...priceFilter];
                            let label = [...priceFilterLabel];
                            if (e.target.checked) {
                              test.push(m.value);
                              label.push(m.label);
                            } else {
                              test = priceFilter.filter(
                                (val) => val !== m.value
                              );
                              label = priceFilterLabel.filter(
                                (val) => val !== m.label
                              );
                            }
                            setQueryParam("price", test.join("%3A"));
                            setPriceFilter(test);
                            setPriceFilterLabel(label);
                          }}
                        />
                        <span>{m.label}</span>
                      </div>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>

            <div
              className={`mt-3 mt-md-0 ${styles.width17} ${
                styles.width15dateRange
              } ${
                globalData && styles.width17
              } mob-dropdown-style-width-100-perc`}
            >
              <span className={dateValue ? styles.range : styles.rangeDisplay}>
                Date range
              </span>
              <DateRangePicker
                className={`${
                  dateValue ? "calendar" : "withoutValue"
                } mob-dropdown-style-width-100-perc`}
                // style={{
                //   minWidth: "205px",
                // }}
                onChange={(val) => {
                  setDateValue(val);
                  if (val === null) {
                    setQueryParam("date", "");
                  } else {
                    setQueryParam("date", val);
                  }
                }}
                monthAriaLabel={false}
                value={dateValue}
                format={"dd.MM.y"}
                maxDate={new Date()}
                calendarIcon={
                  <img
                    src="/images/calendar.svg"
                    className="mobile-icons mob-filter-date-picker-icon"
                    style={{
                      paddingRight: "0",
                      paddingBottom: dateValue && "2px",
                      width: !dateValue && "18px",
                      paddingLeft: "0",
                    }}
                  />
                }
                {...(!dateValue ? { clearIcon: null } : {})}
              />
              {selectLocationCity?.length ||
              selectedBrokarage?.length ||
              selectLocation?.length ? (
                <div
                  style={{
                    background: "#F8F8F8",
                    width: "100%",
                    display: "flex",
                    columnGap: "10px",
                    marginBottom: "10px",
                    flexWrap: "wrap",
                    rowGap: "10px",
                  }}
                >
                  {selectedBrokarage?.length ? (
                    selectedBrokarage?.map((e) => (
                      <CommonCloseButton
                        buttonText={e}
                        onClick={(event) => {
                          setSelectedBrokarage(
                            selectedBrokarage.filter((e) => e !== event)
                          );
                          setQueryParam(
                            "brokarage",
                            selectedBrokarage.filter((e) => e !== event)
                          );
                        }}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                  {selectLocation?.length ? (
                    selectLocation?.map((e) => (
                      <CommonCloseButton
                        buttonText={e}
                        onClick={(event) => {
                          setSelectLocation(
                            selectLocation.filter((e) => e !== event)
                          );
                          setQueryParam(
                            "location",
                            selectLocation.filter((e) => e !== event)
                          );
                        }}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                  {selectLocationCity?.length ? (
                    selectLocationCity?.map((e) => (
                      <CommonCloseButton
                        buttonText={e}
                        onClick={(event) => {
                          setSelectLocationCity(
                            selectLocationCity.filter((e) => e !== event)
                          );
                        }}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            className="row mobile-filters-footer-container"
            style={{
              marginTop: "129% !important",
            }}
          >
            <div className="col-3 m-0 p-0 pr-2">
              <button
                className={`mobile-filters-cancel-btn`}
                onClick={() => {
                  setShowFiltersModal(false);
                }}
              >
                Cancel
              </button>
            </div>
            <div className="col-9 m-0 p-0">
              <button
                className={"mobile-view-offer-btn"}
                onClick={() => {
                  setShowFiltersModal(false);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* global my data modal mobile */}
      <Modal
        className="global-data-modal-mobile"
        isOpen={showGlobalDataModal}
        toggle={() => {
          setShowGlobalDataModal(!showGlobalDataModal);
        }}
        size="sm"
        centered
      >
        <ModalBody className="modal-body py-4">
          <div className="row">
            <div className="col-12">
              <button
                // className={
                //   !globalData
                //     ? "mobile-view-offer-btn py-4"
                //     : "mobile-global-data-btn py-4"
                // }
                className={`global-my-data-btn ${
                  !globalData && "global-my-active-btn"
                } py-4`}
                onClick={() => {
                  setQueryParam("data", "my-data");
                  setGlobalData(false);
                  setShowGlobalDataModal(!showGlobalDataModal);
                }}
              >
                My Data
              </button>
            </div>
            <div className="col-12">
              <button
                // className={
                //   globalData
                //     ? "mobile-view-offer-btn py-4"
                //     : "mobile-global-data-btn py-4"
                // }
                className={`global-global-btn ${
                  globalData && "global-my-active-btn"
                } py-4`}
                onClick={() => {
                  setQueryParam("data", "global-data");
                  setGlobalData(true);
                  setShowGlobalDataModal(!showGlobalDataModal);
                }}
              >
                Global Data
              </button>
            </div>
            <div className="col-12 my-1">
              <button
                // className={"mobile-global-data-cancel-btn py-4"}
                className="global-cancel-btn py-4"
                onClick={() => {
                  setShowGlobalDataModal(!showGlobalDataModal);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withRouter(Analytic);
