import React from "react";
import { Link } from "react-router-dom";
import DoughnutChart from "../../Charts/DoughnutChart";
import styles from "../Analytic.module.css";
import vector from "../../../assets/icons/vector.svg";

import "./Offers.css";

const Offers = (props) => {
  return (
    <div>
      <div
        className={
          !props.isGlobal
            ? "d-flex justify-content-between flex-wrap row"
            : "d-flex justify-content-between flex-wrap row"
        }
        // style={{ background: "#ffffff", columnGap: "40px", paddingLeft: "44px", }}
      >
        <div
          style={{
            // padding: "15px",
            // gap: "25px",
            // display: "flex",
            // justifyContent: "space-around",
            alignItems: "center",

            // verticalAlign: "middle",

            // maxWidth: `${!props.isGlobal ? "670px": "auto"}`,
            // flexWrap: 'wrap'
          }}
          className={`${
            props.isGlobal
              ? "col-xl-12 col-lg-12 col-12 p-0 m-0 p-md-2"
              : "col-xl-9 col-lg-12 col-12 m-0 p-md-2 p-0"
          } my-auto`}
        >
          <div className="row">
            {props?.isGlobal && (
              <div className="col-xl-3 col-lg-6 col-6 p-0 m-0 p-md-2 text-center">
                <DoughnutChart
                  className="charts"
                  value={props.offersCount}
                  total={100}
                  color={"#109CF1"}
                  title={"Offers"}
                />
                <span
                  style={{
                    color: "#109CF1",
                    fontSize: "10px",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    backgroundColor: "rgba(16, 156, 241, 0.1)",
                    border: "1px solid rgba(16, 156, 241, 0.2)",
                    borderRadius: "2px",
                    padding: "0 4px ",
                    // left: "49px",
                    // position: " relative",
                    // bottom: "200px",
                  }}
                >
                  
                  {props.verified || 0} verified{" "}
                  {props.verified
                    ? ((props.verified /
                        props.offersCount) *
                      100).toFixed(2)
                    : 0}
                  %
                </span>
              </div>
            )}
            <div className="col-xl-3 col-lg-6 col-6 p-0 m-0 p-md-2">
              <DoughnutChart
                className="charts"
                value={props.accepted}
                total={100}
                color={"green"}
                title={"Accepted"}
              />
            </div>
            <div
              className={`${
                props.isGlobal
                  ? "mt-3 mt-md-0 col-xl-3 col-lg-6 col-6 p-0 m-0 p-md-2"
                  : "col-xl-3 col-lg-6 col-6 p-0 m-0 p-md-2"
              }`}
            >
              <DoughnutChart
                className="charts"
                value={props.notAccepted}
                total={100}
                color={"#EB3E54"}
                title={"Not Accepted"}
              />
            </div>
            <div className="mt-3 mt-md-0 col-xl-3 col-lg-6 col-6 p-0 m-0 p-md-2">
              <DoughnutChart
                className="charts"
                value={props.withdrawn}
                total={100}
                color={"#FFC107"}
                title={"Withdrawn"}
              />
            </div>

            {!props?.isGlobal && (
              <div className="mt-3 mt-md-0 col-xl-3 col-lg-6 col-6 p-0 m-0 p-md-2">
                <DoughnutChart
                  className="charts"
                  value={props.verified.verifiedCount || 0}
                  total={100}
                  color={"#109CF1"}
                  title={"Verified"}
                />
              </div>
            )}
          </div>
        </div>
        {!props?.isGlobal && (
          <>
            <div
              className={`d-none d-md-block col-xl-3 col-lg-6 col-12 pl-0 mt-4 mt-xl-0 mx-auto ${styles.verifyOffers}`}
            >
              <div className={styles.minWrap}>
                <img src={vector} className={styles.vector} />
                You have{" "}
                <span style={{ color: "#109CF1" }}>
                  {props.verified.unVerifiedCount} unverified
                </span>{" "}
                offers. Please click the button below to verify each offer.
              </div>
              <div>
                <Link
                  to="/offers"
                  style={{
                    color: "#FFFFFF",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "#109CF1",
                      color: "#FFFFFF",
                      border: "none",
                      borderRadius: "4px",
                      fontSize: "12px",
                      padding: "3px 43px",
                    }}
                  >
                    Verify Offers
                  </button>
                </Link>
              </div>
            </div>

            {/* for mobile */}

            <div
              className={`mt-3 d-block d-md-none col-12 m-0 p-0 mobile-verified-container`}
            >
              <div className="row">
                <div className={`col-8 m-0 ${styles.minWrap}`}>
                  You have{" "}
                  <span style={{ color: "#109CF1" }}>
                    {props.verified.unVerifiedCount} unverified
                  </span>{" "}
                  offers. Please click the button below to verify each offer.
                </div>
                <div className="col-4 m-0 p-0 my-auto">
                  <Link
                    to="/offers"
                    style={{
                      color: "#FFFFFF",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#109CF1",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "4px",
                        fontSize: "12px",
                        padding: "3px 43px",
                      }}
                    >
                      Verify Offers
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* <div className={styles.chartBlock}>
        <div className={styles.oneChart}>
          <LineChart/>
          <TotalOverTimeChart/>
        </div>
      </div>
      <div className={styles.totalOffersListings}>
        <TotalOverListingsChart/>
      </div> */}
    </div>
  );
};

export default Offers;
