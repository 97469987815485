import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getChatUsers } from "../Services/MessengerService";
import * as actions from "../Store/Actions/type";
import firebase from "firebase/app";
import { Badge } from "reactstrap";
import "firebase/firestore";
import "firebase/database";
import { Modal, ModalHeader, ModalBody, Input, FormGroup } from "reactstrap";
import ReactDOMServer from 'react-dom/server';
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      requestDemoModal: false,
      yourName: '',
      companyName: '',
      yourEmail: '',
      yourPhone: '',
      msgBody: ''
    };
  }

  setUpOnlineOfflineStatus = () => {

    if (!firebase.auth().currentUser) {
      return;
    }

    const uid = firebase.auth().currentUser.uid;
    const userStatusDatabaseRef = firebase.database().ref("/status/" + uid);

    const isOfflineForDatabase = {
      status: "offline",
      lastSeen: firebase.database.ServerValue.TIMESTAMP
    };

    const isOnlineForDatabase = {
      status: "online",
      lastSeen: firebase.database.ServerValue.TIMESTAMP
    };

    firebase
      .database()
      .ref(".info/connected")
      .on("value", snapshot => {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) {
          return;
        }

        userStatusDatabaseRef
          .onDisconnect()
          .update(isOfflineForDatabase)
          .then(() => {
            userStatusDatabaseRef.update(isOnlineForDatabase);
          });
      });
  };

  requestDemoModalToggle = () => {
    this.setState({
      requestDemoModal: !this.state.requestDemoModal
    })
  }

  async componentWillMount() {
    const { user, dispatch } = this.props;

    if (!user) {
      return;
    }

    const users = await getChatUsers(user);

    const storage = firebase.storage();
    const db = firebase.firestore();
    const storageRef = storage.ref();

    const promises = [];
    users.forEach(u => {
      const p = db
        .collection("conversations")
        .doc(`${user.uid}-${u.id}`)
        .set(
          {
            senderID: user.uid,
            receiverID: u.id
          },
          { merge: true }
        );
      promises.push(p);
      const p2 = db
        .collection("conversations")
        .doc(`${u.id}-${user.uid}`)
        .set(
          {
            senderID: u.id,
            receiverID: user.uid
          },
          { merge: true }
        );
      promises.push(p2);
    });

    await new Promise(async (resolve, reject) => {
      Promise.all(promises)
        .catch(e => { })
        .finally(resolve);
    });

    users.forEach(u => {
      var userStatusDatabaseRef = firebase.database().ref("/status/" + u.id);

      // incomming messages
      db.collection("conversations")
        .doc(`${u.id}-${user.uid}`)
        .onSnapshot(this.handleOnConversationChange);

      // sending message
      db.collection("conversations")
        .doc(`${user.uid}-${u.id}`)
        .onSnapshot(this.handleOnConversationChange);

      userStatusDatabaseRef.on("value", function (snapshot) {
        dispatch({
          type: actions.UPDATE_USER_STATUS,
          payload: {
            user_id: u.id,
            snapshot: snapshot.val()
          }
        });
      });

      storageRef
        .child(`images/users/${u.id}.jpg`)
        .getDownloadURL()
        .then(profile => {
          dispatch({
            type: actions.UPDATE_USER_PROFILE,
            payload: {
              user_id: u.id,
              profile
            }
          });
        })
        .catch(error => { });
    });

    dispatch({
      type: actions.SET_CONVERSATIONS,
      payload: users
    });

    let userID = '';
    if (users.length > 0) {
      userID = users[0].id;
    }
    const { match } = this.props;
    dispatch({
      type: actions.SELECT_CONVERSATION,
      payload: match.params.id || userID
    });

    this.setUpOnlineOfflineStatus();
  }

  handleOnConversationChange = snapshot => {
    const { dispatch, user } = this.props;
    const conversation = snapshot.data();

    if (!conversation) {
      return;
    }

    let { senderID, receiverID, lastSeenAt, messages } = conversation;
    const key = user.uid === receiverID ? "senderLastSeen" : "receiverLastSeen";

    messages = (messages || []).map(m => {
      return { ...m, senderID, receiverID };
    });

    const payload = {
      senderID,
      receiverID,
      [key]: lastSeenAt,
      messages,
      user
    };

    dispatch({
      type: actions.SET_CONVERSATION_MESSAGES,
      payload
    });
  };

  renderUndreamMessagesCount = () => {
    const { unreadMessages } = this.props;
    if (unreadMessages > 0) {
      return (
        <Badge
          pill
          color="danger"
          style={{
            position: "inherit",
            backgroundColor: "#dc3545",
            height: "auto",
            width: "auto",
          }}
        >
          {unreadMessages}
        </Badge>
      );
    }
  };

  handleFormSubmit = async (e) => {
    const { yourName, companyName, yourEmail, yourPhone, msgBody } = this.state;
    e.preventDefault();
    const emailRegex = new RegExp(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/)
    if (!yourName) return alert('Name is required');
    if (!yourEmail) return alert('Email is required');
    if (!yourEmail || !emailRegex.test(yourEmail)) return alert('Please enter correct email.');
    if (!msgBody) return alert('Your message is required');
    let html = <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Email</title>
      </head>
      <body>
        <p><b>Name: </b>{yourName}</p>
        <br></br>
        <p><b>Company Name: </b>{companyName}</p>
        <br></br>
        <p><b>Email: </b>{yourEmail}</p>
        <br></br>
        <p><b>Phone Number: </b>{yourPhone}</p>
        <br></br>
        <p><b>Messgae: </b>{msgBody}</p>
      </body>
    </html>
    const email = 'info@homematchx.com';

    try {
      const sendEmail = firebase.functions().httpsCallable('sendMatchEmail');
      const body = ReactDOMServer.renderToStaticMarkup(html);
      sendEmail({ subject: `Demo request from ${yourName}`, email, body });
      this.setState({
        requestDemoModal: false,
        yourName: '',
        companyName: '',
        yourEmail: '',
        yourPhone: '',
        msgBody: ''
      })
      alert('We have received your email. Our team will contact you shortly')
    } catch (err) {
      alert(err);
    }
  }

  render() {
    let account;

    if (!this.props.user) {
      account = (
        <Link className="nav-link dropdown-toggle" to="/login">
          Login
        </Link>
      );
    } else {
      account = (
        <Link className="nav-link dropdown-toggle" to="/dashboard">
          Dashboard
        </Link>
      );
    }

    let messenger;
    if (this.props.user) {
      messenger = (<li className="nav-item dropdown">
        <Link className="nav-link" to="/messenger">
          Messenger {this.renderUndreamMessagesCount()}
        </Link>
      </li>);
    }


    return (
      <React.Fragment>
        <div className="container header-max-width ">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand logo" to="/">
              <img src="/img/logos/black-logo.png" alt="logo" />
            </Link>
            <button
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              className="navbar-toggler margin-0"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              id="navbarSupportedContent"
              className="collapse navbar-collapse"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <Link className="nav-link" to="/homes">
                    Search Homes
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/search/buyers">
                    Search Buyers                   
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/buyerSearchPage">
                    Be a Buyer                   
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/realEstateServices">Limited Real Estate Services</Link>
                </li> */}
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <Link className="nav-link" to="/blog">
                    Blog
                  </Link>
                </li>
                {messenger} */}
                <li className="nav-item dropdown">{account}</li>
                <li className="nav-item dropdown">
                  <a className="nav-link" onClick={this.requestDemoModalToggle}>Request a Demo</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <Modal isOpen={this.state.requestDemoModal} size="md" fade={false} toggle={this.requestDemoModalToggle} className="offers-modal">
          <ModalHeader className="modal-header" toggle={this.requestDemoModalToggle}></ModalHeader>
          <ModalBody className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="trend-modal-heading">
                    Request a personalized demo
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 overflow m-vertical-32 text-center">
                  <FormGroup className="form-group pt-14">
                    <Input
                      name="name"
                      value={this.state.yourName}
                      onChange={e => this.setState({ yourName: e.target.value })}
                      className="input-text custom-input"
                      placeholder="Your Name"
                      required
                    />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Input
                      name="companyName"
                      value={this.state.companyName}
                      onChange={e => this.setState({ companyName: e.target.value })}
                      className="input-text custom-input"
                      placeholder="Company Name"
                    />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Input
                      type="email"
                      name="yourEmail"
                      value={this.state.yourEmail}
                      onChange={e => this.setState({ yourEmail: e.target.value })}
                      className="input-text custom-input"
                      placeholder="Your Email"
                      required
                    />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Input
                      name="thirdPhoneNumber"
                      value={this.state.yourPhone}
                      onChange={e => this.setState({ yourPhone: e.target.value })}
                      className="input-text custom-input"
                      type="number"
                      maxLength="12"
                      placeholder="123-456-7890"
                    />
                  </FormGroup>
                  <FormGroup className="form-group pt-14">
                    <Input
                      type="textarea"
                      name="comments"
                      value={this.state.msgBody}
                      onChange={e => this.setState({ msgBody: e.target.value })}
                      className="input-text input-area"
                      placeholder="Your Message"
                      required
                    />
                  </FormGroup>
                  <button
                    type="submit"
                    onClick={this.handleFormSubmit}
                    className="submit-offer"
                  >
                    Request a demo
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ MessengerReducer }) => {
  return {
    ...MessengerReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
