import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import TimeAgo from 'react-timeago';

class BookingComments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            dateTime: '',
            commentText: '',
            comments: [],
            isLoading: true,
            savingComment: false
        }
    }

    id = () => {
        const { match } = this.props;
        const { params } = match;
        const { id } = params;
        return id;
    }

    componentDidMount = async () => {
        let bookingId = this.props.bookingId || this.id();
        console.log('bookingId',bookingId);
        let ref = firebase.database().ref(`/BookingComments/${bookingId}`);
        ref.on('value', snap => {
            let comments = [];
            snap.forEach(s => {
                comments.push(s.val());
            });
            comments.reverse();
            this.setState({
                comments,
                isLoading: false
            });
        });
    }

    saveComment = async () => {
        if (!this.state.commentText || this.state.savingComment) return;
        let bookingId = this.props.bookingId || this.id();
        let name = this.props.name;
        let comment = {
            text: this.state.commentText,
            name,
            commentedAt: new Date().getTime()
        }

        this.setState({
            savingComment: true
        })
        firebase.database().ref(`/BookingComments/${bookingId}`)
            .push(comment, err => {
                if (!err) this.setState({ commentText: '', savingComment: false });
                else alert('Unexpected error occurred while saving comment. Please try again later.');
            })
    }

    render() {
        const { isLoading, comments } = this.state;
        const { name } = this.props;

        let ListComments = [];
        comments.forEach((c, i) => {
            ListComments.push(
                <li key={i}>
                    <div className="comment-div d-flex">
                        <div className="profile-img-user">
                            <div>
                                <img src="/images/face-placeholder.png" alt="" />
                            </div>
                        </div>
                        <div className="comment-user-name">
                            <h5 style={(c.name === name) ? { color: '#ff214f' } : null}>{c.name} - <TimeAgo date={c.commentedAt} /></h5>
                            <p>{c.text}</p>
                        </div>
                    </div>
                </li>
            );
        })
        let renderComments =
            isLoading ?
                <>Loading Comments...</>
                :
                <>
                    {
                        comments.length > 0 ? ListComments : <>No comment has been added yet.</>
                    }
                </>

        return (
            <ul>
                {renderComments}
            </ul>
        )
    }
}

export default BookingComments;