import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import Loader from "react-loader-spinner";
import "firebase/firestore";
import 'firebase/analytics';
import SubBanner from "components/SubBanner";
import { Label, Input, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-date-picker";
import OfferEmailTemplate from "../components/emailTemplates/OfferEmailTemplate";
import ReactDOMServer from "react-dom/server";
import {firebaseConfig} from './../components/Firebase/firebase';
let {logEvent} = firebase.analytics();

class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      inputs: {},
      loading: false,
      id: null,
      inProgress: false
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    const db = firebase.firestore();
    try {
      const doc = await db.collection("offers").doc(id).get()
      const offer = { id: doc.id, ...doc.data() };
      this.setState({
        inputs: {
          ...offer,
          closingDate: new Date(offer.closingDate),
          offerExpires: new Date(offer.offerExpires),
        },
        loading: false,
        id,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message || "Something went wrong, please try again !"
      });
    }
  }

  renderInstantOfferFields = (col = 1) => {
    const { inputs } = this.state;
    const { offerType } = inputs;

    if (!offerType) {
      return;
    }

    const { value } = offerType;

    if (col === 1 && value === "InstantOffer") {
      return (
        <React.Fragment>
          <FormGroup className="inline">
            <Label>Service Charge Percent</Label>
            <Input
              name="serviceChargePercent"
              type="number"
              onChange={this.onChange}
              className="input-text"
              value={inputs.serviceChargePercent}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Offer Expires</Label>
            <DatePicker
              required
              name="offerExpires"
              className="inline-date-picker"
              onChange={d => this.handleChange(d, "offerExpires")}
              value={inputs.offerExpires}
            />
          </FormGroup>

          <FormGroup className="inline">
            <Label>Is Offer Subject to property inspection?</Label>
            <select
              className="form-control custom-select"
              value={inputs.isSubjectToInspection}
              name="isSubjectToInspection"
              onChange={this.onChange}
            >
              <option>Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </FormGroup>

          <FormGroup className="inline">
            <Label>
              Do you offer any special services like free move extented closing
              date, or executed stay ? if Yes, please explain.
            </Label>
            <Input
              type="textarea"
              name="specialServices"
              className="input-text"
              onChange={this.onChange}
              value={inputs.specialServices}
            />
          </FormGroup>
        </React.Fragment>
      );
    }
  };

  renderFinanceFields = (col = 1) => {
    const { inputs } = this.state;
    const { offerType, financeType, saleOfOtherProperty } = inputs;

    if (!offerType) {
      return;
    }

    const { value } = offerType;

    const types = ["TraditionalFinance", "Investor", "SellerFinance"];
    if (col === 1 && types.includes(value)) {
      return (
        <React.Fragment>
          {financeType !== "Cash" && (
            <FormGroup className="inline">
              <Label>Down Payment:</Label>
              <Input
                required
                type="number"
                value={inputs.downPayment}
                onChange={this.onChange}
                name="downPayment"
                className="input-text"
              />
            </FormGroup>
          )}

          {financeType !== "Cash" && (
            <FormGroup className="inline">
              <Label>Finance Amount:</Label>
              <Input
                name="financeAmount"
                className="input-text"
                value={inputs.financeAmount}
                readOnly
              />
            </FormGroup>
          )}

          <FormGroup className="inline">
            <Label>Seller Contribution:</Label>
            <Input
              name="finance"
              className="input-text"
              type="number"
              value={inputs.finance}
              onChange={this.onChange}
              required
            />
          </FormGroup>
          <FormGroup className="inline">
            <Label>Earnest Money:</Label>
            <Input
              name="earnestMoney"
              className="input-text"
              type="number"
              value={inputs.earnestMoney}
              onChange={this.onChange}
              required
            />
          </FormGroup>
          <FormGroup className="inline">
            <Label>Title Policy Expense:</Label>
            <select
              className="form-control custom-select"
              value={inputs.titlePolicyExpense}
              name="titlePolicyExpense"
              onChange={this.onChange}
              required
            >
              <option>Select</option>
              <option value="Seller">Seller</option>
              <option value="Buyer">Buyer</option>
            </select>
          </FormGroup>
          <FormGroup className="inline">
            <Label>Survey Expense:</Label>
            <select
              className="form-control custom-select"
              value={inputs.surveyExpense}
              name="surveyExpense"
              onChange={this.onChange}
              required
            >
              <option>Select</option>
              <option value="Seller">Seller</option>
              <option value="Buyer">Buyer</option>
            </select>
          </FormGroup>
          <FormGroup className="inline">
            <Label>Residential Service Contract:</Label>
            <Input
              name="serviceContract"
              value={inputs.serviceContract}
              className="input-text"
              type="number"
              onChange={this.onChange}
              required
            />
          </FormGroup>
          <FormGroup className="inline">
            <Label>Possession:</Label>
            <select
              className="form-control custom-select"
              value={inputs.possession}
              name="possession"
              onChange={this.onChange}
              required
            >
              <option>Select</option>
              <option value="Upon Close & Fund">Upon Close & Fund</option>
              <option value="Leaseback">Leaseback</option>
            </select>
          </FormGroup>
          <FormGroup className="inline">
            <Label>If Leaseback, how many days:</Label>
            <Input
              name="possessionDays"
              value={inputs.possessionDays}
              className="input-text"
              type="number"
              onChange={this.onChange}
              required
            />
          </FormGroup>
          <FormGroup className="inline">
            <Label>Option Fee:</Label>
            <Input
              name="optionFee"
              className="input-text"
              value={inputs.optionFee}
              type="number"
              onChange={this.onChange}
              required
            />
          </FormGroup>
          <FormGroup className="inline">
            <Label>Option Days:</Label>
            <Input
              name="optionDays"
              className="input-text"
              value={inputs.optionDays}
              type="number"
              onChange={this.onChange}
              required
            />
          </FormGroup>
        </React.Fragment>
      );
    }

    if (col === 2 && types.includes(value)) {
      return (
        <React.Fragment>
          {financeType !== "Cash" && (
            <FormGroup className="inline">
              <Label>Number of Days for credit Approval:</Label>
              <Input
                name="creditApprovalDays"
                type="number"
                value={inputs.creditApprovalDays}
                onChange={this.onChange}
                className="input-text"
                required
              />
            </FormGroup>
          )}
          <FormGroup className="inline">
            <Label>Non-Realty Item:</Label>
            <Input
              name="nonRealtyItems"
              value={inputs.nonRealtyItems}
              onChange={this.onChange}
              className="input-text"
              required
            />
          </FormGroup>
          <FormGroup className="inline">
            <Label>Sale of Other Property:</Label>
            <select
              className="form-control custom-select"
              value={inputs.saleOfOtherProperty}
              name="saleOfOtherProperty"
              onChange={this.onChange}
              required
            >
              <option>Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </FormGroup>

          {saleOfOtherProperty === "Yes" && (
            <FormGroup className="inline">
              <Label>Other Property Address:</Label>
              <Input
                name="otherPropertyAddress"
                value={inputs.otherPropertyAddress}
                onChange={this.onChange}
                className="input-text"
                required
              />
            </FormGroup>
          )}
        </React.Fragment>
      );
    }
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    const { inputs } = this.state;

    let { price, downPayment, financeAmount } = inputs;
    price = parseInt(price || "0");
    downPayment = parseInt(downPayment || "0");

    if (name === "downPayment") {
      financeAmount = price - value;
    }

    if (name === "price") {
      financeAmount = value - downPayment;
    }

    this.setState({
      inputs: {
        ...inputs,
        [name]: value,
        financeAmount
      }
    });
  };

  sendOfferEmail = options => {

    const { offerExpires, buyerName, price, id, address, offerType, sellerEmail, buyerEmail } = options;
    const type = offerType.value === "InstantOffer" ? "Instant" : "";

    const { sender } = this.state.inputs;
    const { uid } = this.props.user;

    const email = sender === uid ? sellerEmail : buyerEmail;

    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

    logEvent('send_offer_email', {
      offerId:id,
      buyerName,
      price,
      address,
      offerType:type,
      offerExpires
    });

    let body = ReactDOMServer.renderToStaticMarkup(
      OfferEmailTemplate({
        addressLineOne: `${address}`,
        expires: `This offer expires on ${offerExpires}`,
        heading: `${buyerName} would like to purchase your home for`,
        price: `$${price}`,
        elements: [
          {
            type: "p",
            text: `Congratulation, there is someone that’s interested in purchasing your home. In order to help you make the right decision in selling of your home, we have outlined the purchase details for your review in the ${type} Offer Summary.`
          },
          {
            type: "buttons",
            buttons: [
              {
                text: `View ${type} Offer Summary`,
                link: `${firebaseConfig.host}/offers/${id}`
              }
            ]
          },
          {
            type: "p",
            text:
              "This process is designed to help protect your equity when selling your home. Providing you the necessary peace of mine and ensuring you feel confident about your decision is important to Homematchx."
          },
          {
            type: "p",
            text:
              "Should you have any quesitons or would like to speak to a Homematchx Offer Team member, please contact me below. We're here to help!"
          }
        ]
      })
    );

    if(sender === uid){
      return sendEmail({ subject: address, email, body });
    }


    body = ReactDOMServer.renderToStaticMarkup(
      OfferEmailTemplate({
        heading: `${buyerName}`,
        elements: [
          {
            type: "p",
            text: `Thank you for submitting your offer. The seller has countered the terms of your offer forthe following reasons below:`
          },
          {
            type: "p",
            text: `The acceptance of this offer is contingent upon receipt of a fully written offer. By acceptingthis request, you agree to send or complete a fully written offer to the recipient. If youwish to counter this counter offer, please specify below.`
          },
          {
            type: "buttons",
            buttons: [
              {
                text: `Accept Request`,
                link: `${firebaseConfig.host}/offers/${id}`
              },
              {
                text: `Counter Offer`,
                link: `${firebaseConfig.host}/offers/update/${id}`
              }
            ]
          },
          {
            type: "p",
            text:
              "This offer expires on Wednesday October 23, 2019 at 8pm CST, If this request is notacknowledged within the specified time your offer is considered withdrawn and will have tobe resubmitted."
          },
          {
            type: "p",
            text:
              "Should you have any questions or would like to speak to a Homematchx Offer Team member, please contact us below. We're here to help!"
          }
        ]
      })
    );

    sendEmail({ subject: address, email, body });
  };

  onSubmit = async e => {
    e.preventDefault();
    const { inputs, id } = this.state;
    const newInputs = { ...inputs };

    if (newInputs.closingDate) {
      newInputs.closingDate = newInputs.closingDate.toDateString();
    }
    if (newInputs.offerExpires) {
      newInputs.offerExpires = newInputs.offerExpires.toDateString();
    }

    this.setState({
      inProgress: true
    });

    const timestamp = Math.floor(Date.now() / 1000);
    newInputs.updatedAt = timestamp;

    try {
      const db = firebase.firestore();
      await db.collection("offers").doc(id).set(newInputs);
      const successMessage = "Your offer request has been submitted";
      toast.success(successMessage, {
        position: toast.POSITION.TOP_RIGHT
      });

      this.sendOfferEmail(newInputs);

      return this.setState({ inProgress: false });
    } catch (error) {
      this.setState({ inProgress: false });
      console.log("Error", error);
    }
  };
  renderPurchasingDetailsContent = () => {
    const { inputs, inProgress } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <h3 style={{ marginTop: "10px" }}>Purchase Details</h3>
          <div className="row" style={{ marginBottom: "50px" }}>
            <div className="col-lg-5">
              <FormGroup className="inline">
                <Label>Name of Buyer(s)</Label>
                <Input
                  name="buyerName"
                  required
                  onChange={this.onChange}
                  className="input-text"
                  value={inputs.buyerName}
                />
              </FormGroup>
              <FormGroup className="inline">
                <Label>Finance Type</Label>
                <select
                  name="financeType"
                  className="form-control custom-select"
                  required
                  onChange={this.onChange}
                  value={inputs.financeType}
                >
                  <option>Select Finance Type:</option>
                  <option value="Cash">Cash</option>
                  <option value="Conventional">Conventional</option>
                  <option value="FHA">FHA</option>
                  <option value="VA">VA</option>
                  <option value="Seller Finance">Seller Finance</option>
                </select>
              </FormGroup>
              <FormGroup>
                <Label>Estimate Closing Date:</Label>
                <DatePicker
                  required
                  name="closingDate"
                  onChange={d => this.handleChange(d, "closingDate")}
                  value={inputs.closingDate}
                  className="inline-date-picker"
                ></DatePicker>
              </FormGroup>

              <FormGroup className="inline">
                <Label>Purchase Price:</Label>
                <Input
                  name="price"
                  type="number"
                  required
                  onChange={this.onChange}
                  className="input-text"
                  value={inputs.price}
                />
              </FormGroup>

              {this.renderFinanceFields()}
              {this.renderInstantOfferFields()}
            </div>
            <div className="offset-lg-1 col-lg-6">
              {this.renderFinanceFields(2)}
              {this.renderInstantOfferFields(2)}

              <FormGroup className="inline">
                <Label>Other Comments Relevant to the offer:</Label>
                <Input
                  type="textarea"
                  name="comments"
                  className="input-text"
                  onChange={this.onChange}
                  value={inputs.comments}
                />
              </FormGroup>
              <button
                type="submit"
                disabled={inProgress}
                className="search-button"
              >
                {inProgress ? "Submiting..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  };

  handleChange = (value, name) => {
    const { inputs } = this.state;
    this.setState({
      inputs: {
        ...inputs,
        [name]: value
      }
    });
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loader type="Rings" color="#ff214f" height="80%" width="100%" />;
    }

    const { sender } = this.state.inputs;
    const { uid } = this.props.user;

    const title = sender === uid ? 'Resubmit Offer' : 'Counter Offer';

    return (
      <React.Fragment>
        <SubBanner
          title={title}
          image="/images/mx4mSkK9zeo.jpg"
          {...this.props}
        />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {this.renderPurchasingDetailsContent()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Offers;
