import React, { Component } from "react";
import BuyerListItem from "./BuyerListItem";

class BuyerList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      error: ""
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="manage-table">{this.rows()}</div>
      </React.Fragment>
    );
  }

  rows() {

    const { buyers, remove, user, showOnSharePage, showDetails } = this.props;
    if (buyers.length === 0) {
      return (
        <tr className="responsive-table">
          <td>No records found.</td>
        </tr>
      );
    }
    return buyers.map((buyer, i) => {
      const data = buyer.data();
      return (
        <BuyerListItem
          key={i}
          user={user}
          remove={remove}
          buyer={buyer}
          data={data}
          showOnSharePage={showOnSharePage}
          showDetails = { showDetails }
        />
      );
    });
  }
}

export default BuyerList;
