import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { reportPurchase } from './../../Store/Actions/ConvertKitActions';
import 'react-toastify/dist/ReactToastify.css';

import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import Select from "react-select";

import firebase from "firebase/app";
import "firebase/functions";
import CreateCardModal from "./CreateCardModal";


class PackageSubscriber extends Component {
  initState = {
    processing: false,
    card: "",
    duration: "",
    isOpen: false,
    error: ""
  };

  state = {
    ...this.initState
  };

  async componentDidMount() {
    const { p } = this.props;
    const packageID = localStorage.getItem('packageID');
    localStorage.removeItem('packageID');
    const isOpen = packageID === p.id;
    this.setState({
      isOpen,
    });
  }

  handleCardChange = card => {
    this.setState({ card });
  };

  handleDurationChange = duration => {
    this.setState({ duration });
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value
    });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  subscribe = async () => {

    const { card, duration } = this.state;

    if (!card || !duration) {
      return alert("Please Select Card and package duration");
    }

    const { user, p, payload } = this.props;
    const { uid, role, email } = user;

    const charge = {
      source: card.value,
      duration: duration.value,
      packageID: p.id,
      role,
      ...(payload || {})
    };

    let randomString = Math.random().toString(36).substring(2);

    let amount = 0;
    p.period.forEach(period => {
      if(period.duration === duration.value){
        amount = period.price
      }
    });
    
    reportPurchase({
      data: {
        transaction_id: `tr_${randomString}`,
        email_address: email,
        total: amount,
        package: p,
      },
      onError: (error) => console.log(error),
      onSuccess: (message) => console.log(message)
    });

    this.setState({
      processing: true,
      error: '',
    });

    try {
      await firebase
        .firestore()
        .collection("stripe_customers")
        .doc(uid)
        .collection("charges")
        .add(charge);

      this.setState({
        ...this.initState
      });

      toast.success("Package Subscribed Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });

    } catch (error) {
      this.setState({
        processing: false,
        error: error.message
      });
    }
  };

  renderCards = () => {
    const { card } = this.state;
    const { sources, user } = this.props;
    if (sources.length === 0) {
      return (
        <React.Fragment>
          <p>No Card found</p>
          <CreateCardModal user={user} />
        </React.Fragment>
      );
    }
    const cards = sources.map(({ brand, last4, id }) => {
      return { value: id, label: `${brand}*************${last4}` };
    });

    return (
      <React.Fragment>
        <Label htmlFor="card">Select Card</Label>
        <Select
          name="card"
          value={card}
          onChange={this.handleCardChange}
          options={cards}
        />
      </React.Fragment>
    );
  };

  render() {
    const { isOpen, processing, error, duration } = this.state;
    const { p, name } = this.props;
    const periods = p.period.map(p => {
      return {
        value: p.duration,
        label: `$${p.price}/${p.duration} - ${p.discount}% off`
      };
    });

    return (
      <React.Fragment>
        <Button
          block
          color=""
          onClick={this.toggle}
          className="btn btn-block btn-primary text-uppercase"
        >
          Subscribe
        </Button>

        <Modal
          size="lg"
          isOpen={isOpen}
          toggle={this.toggle}
          className="modal-default"
        >
          <ModalHeader toggle={this.toggle}>
            {" "}
            Subscribe to {name} package
          </ModalHeader>
          <ModalBody>
            <FormGroup>{this.renderCards()}</FormGroup>
            <FormGroup>
              <Label htmlFor="duration">Select Package Duration</Label>
              <Select
                name="duration"
                value={duration}
                onChange={this.handleDurationChange}
                options={periods}
              />
            </FormGroup>

            {error && <Alert color="danger">{error}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.subscribe}>
              {processing ? "Subscribing..." : "Subscribe"}
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ PaymentReducer }) => {
  return {
    ...PaymentReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageSubscriber);
